/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {apiUrl, defaultClientId, defaultClientSecret} from '../../environment';
import axios from 'axios';
import {userConstants} from '../constants/user.constants';
import {jwt} from '../utils/jwt';
import store from '../../store';
import {userActions} from '../actions/user.actions';
import {messageActions} from '../actions/message.actions';

export const axiosService = axios.create({
  baseURL: apiUrl,
});

axiosService.interceptors.request.use(
  config => {
    let originalRequest = config;
    const token = localStorage.getItem(userConstants.ACCESS_TOKEN_KEY);
    if (token) {
      if (isTokenExpired(token)) {
        const refreshToken = localStorage.getItem(
          userConstants.REFRESH_TOKEN_KEY
        );
        return !isTokenExpired(refreshToken)
          ? refreshTokenRequest(refreshToken).then(newToken => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              return Promise.resolve(originalRequest);
            })
          : logout('login.tokenExpired');
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => logout('login.errorMsg')
);

function isTokenExpired(token) {
  const expiresMillis = jwt.decode(token).exp * 1000;
  const nowMillis = Date.now();
  return expiresMillis < nowMillis;
}

function logout(message) {
  store.dispatch(messageActions.errorMessage(message));
  store.dispatch(userActions.logout());
  return Promise.reject();
}

export function refreshTokenRequest(refreshToken) {
  const body =
    `grant_type=refresh_token` +
    `&refresh_token=${refreshToken}` +
    `&client_id=${defaultClientId}` +
    `&client_secret=${defaultClientSecret}`;

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(`${apiUrl}/user/oauth/token`, body, config)
    .then(response => {
      localStorage.setItem(
        userConstants.ACCESS_TOKEN_KEY,
        response.data.access_token
      );
      localStorage.setItem(
        userConstants.REFRESH_TOKEN_KEY,
        response.data.refresh_token
      );
      return response.data.access_token;
    });
}
