/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, FieldArray} from 'redux-form';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import '../valuation-inquiry-form.css';
import {RenderServiceSection} from './RenderServiceSection';

export default class ValuationInquiryFormSchemaPage1 extends React.Component {
  componentDidMount() {
    this.props.setPageTitle('valuationInquiry.serviceDataTitle');
  }

  render() {
    const {
      handleSubmit,
      handleCancel,
      invalid,
      categories,
      handleCreateDraft,
      valuationForm,
      isValuationPublic,
    } = this.props;
    const fixedBtn = {width: '7rem'};

    return (
      <form className='valuation-inquiry-form' onSubmit={handleSubmit}>
        <FieldArray
          name='valuationInquiryServices'
          component={RenderServiceSection}
          valuationForm={valuationForm}
          rerenderOnEveryChange={true}
          categories={categories}
          dispatch={this.props.dispatch}
          isValuationPublic={isValuationPublic}
        />
        <Field
          name='otherServices'
          type='text'
          component={RenderTextArea}
          required={false}
          label={I18n.t('valuationInquiry.otherServicesLabel')}
        />

        <div className='text-sm-right text-center mt-3'>
          <Button className='main-btn' type='submit' disabled={invalid}>
            <Translate value='valuationInquiry.nextPageBtn' />
          </Button>
        </div>
        <div className='text-sm-right text-center mt-3'>
          {handleCreateDraft && (
            <Button className='main-btn' onClick={handleCreateDraft}>
              <Translate value='valuationInquiry.createDraftBtn' />
            </Button>
          )}
          <Button className='main-btn' onClick={handleCancel}>
            <Translate value='valuationInquiry.cancelBtn' />
          </Button>
        </div>
      </form>
    );
  }
}
