/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import { settingsConstants } from '../constants/settings.constants';
import { messageActions } from './message.actions';
import { settingsService } from '../services/settings.service';

export const settingsActions = {
  findAllSystemSettings,
  updateSystemSettings,
  findAllUserSettingsByUserId,
  updateUserSettingsByUserId,
  getWorkshopLoyaltyProgramEditor,
  updateWorkshopLoyaltyProgramEditor,
  getWorkshopLoyaltyProgramEditorAsWorkshop
};

function findAllSystemSettings() {
  return dispatch => {
    dispatch({ type: settingsConstants.SETTINGS_REQUEST_START });

    return settingsService.findAllSystemSettings().then(
      response => {
        dispatch({ type: settingsConstants.LOAD_SETTINGS, payload: response });
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
      },
      error => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch(messageActions.errorMessage('settings.fetchErrorMsg'));
      }
    );
  };
}

function updateSystemSettings(toUpdate) {
  return dispatch => {
    dispatch({ type: settingsConstants.SETTINGS_REQUEST_START });

    return settingsService.updateSystemSettings(toUpdate).then(
      response => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch({ type: settingsConstants.REFRESH_SETTINGS_DATA });
        dispatch(messageActions.successMessage('settings.updateSuccessMsg'));
      },
      error => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch(messageActions.errorMessage('settings.updateErrorMsg'));
      }
    );
  };
}

function findAllUserSettingsByUserId(userId) {
  return dispatch => {
    dispatch({ type: settingsConstants.SETTINGS_REQUEST_START });

    return settingsService.findAllUserSettingsByUserId(userId).then(
      response => {
        dispatch({ type: settingsConstants.LOAD_SETTINGS, payload: response });
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
      },
      error => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch(messageActions.errorMessage('settings.fetchErrorMsg'));
      }
    );
  };
}

function updateUserSettingsByUserId(userId, toUpdate) {
  return dispatch => {
    dispatch({ type: settingsConstants.SETTINGS_REQUEST_START });

    return settingsService.updateUserSettingsByUserId(userId, toUpdate).then(
      response => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch({ type: settingsConstants.REFRESH_SETTINGS_DATA });
        dispatch(messageActions.successMessage('settings.updateSuccessMsg'));
      },
      error => {
        dispatch({ type: settingsConstants.SETTINGS_REQUEST_END });
        dispatch(messageActions.errorMessage('settings.updateErrorMsg'));
      }
    );
  };
}

function getWorkshopLoyaltyProgramEditor() {
  return dispatch => {
    dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START });

    return settingsService.getWorkshopLoyaltyProgramEditor().then(
      response => {
        dispatch({ type: settingsConstants.LOAD_WORKSHOP_LOYALTY_PROGRAM_EDITOR, payload: response });
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
      },
      error => {
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
        dispatch(messageActions.errorMessage('workshopLoyaltyProgram.cannotLoad'));
      }
    );
  };
}

function getWorkshopLoyaltyProgramEditorAsWorkshop() {
  return dispatch => {
    dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START });

    return settingsService.getWorkshopLoyaltyProgramEditorAsWorkshop().then(
      response => {
        dispatch({ type: settingsConstants.LOAD_WORKSHOP_LOYALTY_PROGRAM_EDITOR, payload: response });
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
      },
      error => {
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
        dispatch(messageActions.errorMessage('workshopLoyaltyProgram.cannotLoad'));
      }
    );
  };
}

function updateWorkshopLoyaltyProgramEditor(value) {
  return dispatch => {
    dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START });

    return settingsService.updateWorkshopLoyaltyProgramEditor(value).then(
      response => {
        dispatch(getWorkshopLoyaltyProgramEditor())
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
        dispatch(
          messageActions.successMessage('workshopLoyaltyProgram.editorSavingWasSucceful')
        );
      },
      error => {
        dispatch({ type: settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END });
        dispatch(messageActions.errorMessage('workshopLoyaltyProgram.cannotLoad'));
      }
    );
  };
}