/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const SyncState = {
  UP_TO_DATE: 'UP_TO_DATE',
  OBSOLETE: 'OBSOLETE',
};

export function becameObsolete(previousSyncState, currentSyncState) {
  return (
    previousSyncState === SyncState.UP_TO_DATE &&
    currentSyncState === SyncState.OBSOLETE
  );
}
