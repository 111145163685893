/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import { messageActions } from './message.actions';
import { statuteConstants } from '../constants/statute.constants';
import { statuteService } from '../services/statute.service';
import { getErrorMessage } from '../utils/utils';
import { updateDataSideBarConstants } from '../constants/update-data-side-bar.constants';

export const statuteActions = {
  createStatute,
  findAllStatutes,
  findAllStatutesByCountry,
  findStatuteById,
  markStatuteValid,
  deleteStatute,
  findLoyaltyProgramStatutes,
  uploadNewLoyaltyProgramStatute,
  checkValidStatuteAcceptation,
  acceptNewStatute
};

function createStatute(data, country = null) {
  return dispatch => {
    statuteService.createStatute(data, country).then(
      statute => {
        dispatch(
          messageActions.successMessage('statutes.statuteCreateSuccessMsg')
        );
        dispatch({ type: statuteConstants.REFRESH_STATUTES_DATA });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statutes', 'statuteCreateErrorMsg')
          )
        );
      }
    );
  };
}

function uploadNewLoyaltyProgramStatute(data, country = null) {
  return dispatch => {
    dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_START });
    statuteService.uploadNewLoyaltyProgramStatute(data, country).then(
      statute => {
        dispatch(
          messageActions.successMessage('statutes.statuteCreateSuccessMsg')
        );
        statuteService.findLoyaltyStatutes().then(
          statutes => {
            dispatch({ type: statuteConstants.LOYALTY_STATUTE, payload: statutes });
            dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
          },
          error => {
            dispatch(messageActions.errorMessage('statutes.fetchError'));
            dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
          }
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statutes', 'statuteCreateErrorMsg')
          )
        );
      }
    );
  };
}

function findAllStatutes() {
  return dispatch => {
    dispatch({ type: statuteConstants.STATUTE_REQUEST_START });
    statuteService.findAllStatutes().then(
      statutes => {
        dispatch({ type: statuteConstants.LOAD_STATUTES, payload: statutes });
        dispatch({ type: statuteConstants.STATUTE_REQUEST_END });
      },
      error => {
        dispatch(messageActions.errorMessage('statutes.fetchError'));
        dispatch({ type: statuteConstants.STATUTE_REQUEST_END });
      }
    );
  };
}

function findLoyaltyProgramStatutes() {
  return dispatch => {
    dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_START });
    statuteService.findLoyaltyStatutes().then(
      statutes => {
        dispatch({ type: statuteConstants.LOYALTY_STATUTE, payload: statutes });
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
      },
      error => {
        dispatch(messageActions.errorMessage('statutes.fetchError'));
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
      }
    );
  };
}

function checkValidStatuteAcceptation(workshopId) {
  return dispatch => {
    dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_START });
    statuteService.checkValidStatuteAcceptation(workshopId).then(
      res => {
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
        if (!res.data) {
          dispatch({ type: statuteConstants.LOYALTY_STATUTE_MODAL_OPEN, payload: true })
        }
      },
      error => {
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
      }
    );
  };
}

function acceptNewStatute(workshopId) {
  return dispatch => {
    dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_START });
    statuteService.acceptNewStatute(workshopId).then(
      res => {
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
        dispatch(messageActions.successMessage('workshopLoyaltyProgram.newStatuteWasAccepted'));
      },
      error => {
        dispatch(messageActions.errorMessage('workshopLoyaltyProgram.newStatuteWasNotAccepted'));
        dispatch({ type: statuteConstants.LOYALTY_STATUTE_REQUEST_END });
      }
    );
  };
}

function findAllStatutesByCountry(country) {
  return dispatch => {
    dispatch({ type: statuteConstants.STATUTE_REQUEST_START });
    statuteService.findAllStatutesByCountry(country).then(
      statutes => {
        dispatch({ type: statuteConstants.LOAD_STATUTES, payload: statutes });
        dispatch({ type: statuteConstants.STATUTE_REQUEST_END });
      },
      error => {
        dispatch(messageActions.errorMessage('statutes.fetchError'));
        dispatch({ type: statuteConstants.STATUTE_REQUEST_END });
      }
    );
  };
}

function findStatuteById(statuteId) {
  return dispatch => {
    statuteService.findStatuteById(statuteId).then(statute => {
      dispatch({ type: statuteConstants.LOAD_STATUTE, payload: statute });
    });
  };
}

function markStatuteValid(statuteId) {
  return dispatch => {
    statuteService.markStatuteValid(statuteId).then(
      statute => {
        dispatch(
          messageActions.successMessage('statutes.statuteMarkValidSuccessMsg')
        );
        dispatch({ type: updateDataSideBarConstants.HIDE });
        dispatch({ type: statuteConstants.REFRESH_STATUTES_DATA });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statutes', 'statuteMarkValidErrorMsg')
          )
        );
      }
    );
  };
}

function deleteStatute(statuteId) {
  return dispatch => {
    statuteService.deleteStatute(statuteId).then(
      statute => {
        dispatch(
          messageActions.successMessage('statutes.statuteDeleteSuccessMsg')
        );
        dispatch({ type: updateDataSideBarConstants.HIDE });
        dispatch({ type: statuteConstants.REFRESH_STATUTES_DATA });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statutes', 'statuteDeleteErrorMsg')
          )
        );
      }
    );
  };
}
