/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
  validateRequiredNumberWithRange,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.name = validateRequiredFieldsWithMaxLength(values.name, 60);
  errors.brand = validateRequiredFields(values.brand);
  errors.model = validateRequiredFields(values.model);
  errors.modelOther = validateCarModelOther(values);
  errors.productionYear = validateRequiredFields(values.productionYear);
  errors.fuelType = validateRequiredFields(values.fuelType);
  errors.engineCapacityCm3 =
    values.fuelType !== 'ELECTRICITY'
      ? validateRequiredNumberWithRange(values.engineCapacityCm3, 1, 20000)
      : '';
  errors.enginePowerKw = validateRequiredNumberWithRange(
    values.enginePowerKw,
    1,
    9999
  );
  errors.vin = validateRequiredFieldsWithMaxLength(values.vin, 17);
  errors.registrationNumber = validateRequiredFieldsWithMaxLength(
    values.registrationNumber,
    20
  );
  return errors;
};

const validateCarModelOther = values => {
  if (values.model === 'other')
    return validateRequiredFieldsWithMaxLength(values.modelOther, 60);
};
