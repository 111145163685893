/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {distributorConstants} from '../constants/distributor.constants';
import {SyncState} from '../utils/sync';

export function distributorReducer(state = {}, action) {
  switch (action.type) {
    case distributorConstants.DISTRIBUTOR_REQUEST_START:
      return {...state, isFetching: true};
    case distributorConstants.DISTRIBUTOR_REQUEST_END:
      return {...state, isFetching: false};
    case distributorConstants.LOAD_DISTRIBUTORS_DATA:
      return {
        ...state,
        distributors: action.payload.data,
        distributorsDataSyncState: SyncState.UP_TO_DATE,
      };
    case distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA:
      return {...state, selectedDistributor: action.payload.data};
    case distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA:
      return {...state, selectedDistributor: null};
    case distributorConstants.REFRESH_DISTRIBUTORS_DATA:
      return {...state, distributorsDataSyncState: SyncState.OBSOLETE};
    default:
      return state;
  }
}
