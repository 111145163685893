import React, {useRef, useState, useEffect, useMemo} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Paginator} from 'primereact/components/paginator/Paginator';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import {Dropdown} from 'primereact/dropdown';
import styles from './BarcodesTable.module.css';
import {Input} from 'reactstrap';
import {Column} from 'primereact/column';
import {useDispatch, useSelector} from 'react-redux';
import {workshopActions} from 'shared/actions/workshop.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import BarcodesDetails from './barcodes-details/BarcodesDetails';
import {dateUtils} from 'shared/utils/date.utils';

const BarcodesTable = () => {
  const dataTableRef = useRef();
  const [rowsNumber, setRowsNumber] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [globalFilter, setGlobalFilter] = useState();
  const [stateCheck, setStateCheck] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [first, setFirst] = useState(0)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      workshopActions.getAllBarcodes({
        page: page,
        pageLimit: rowsNumber,
        searchPhrase: globalFilter,
        invalid: invalid,
        sortField: sortField,
        sortDirection: sortDirection,
        isSuspicious: stateCheck
      })
    );
  }, [globalFilter, invalid, rowsNumber, page, sortField, sortDirection, stateCheck]);

  const barcodes = useSelector(state => state.workshop.barcodesData.content);
  const pageInfo = useSelector(state => state.workshop.barcodesData);

  const showMoreData = rowData => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <BarcodesDetails data={rowData} />,
      title: I18n.t('barcodes.detailsPrimary'),
    });
  };

  const mapRoles = role => {
    switch (role) {
      case 'ROLE_WORKSHOP':
        return I18n.t('barcodes.workshop');
      case 'ROLE_USER':
        return I18n.t('barcodes.user');
      default:
        return 'UNKNOWN ROLE';
    }
  };
  return (
    <div className={styles.Container}>
      <DataTable
        style={{minWidth: '700px'}}
        value={barcodes}
        header={
          <div className={styles.Header}>
            <div className={styles.FilterContainer}>
              <label htmlFor='invalidCheck' style={{marginBottom: -1}}>
                <Translate value={'barcodes.invalid'} />:
              </label>
              <Input
                id='invalidCheck'
                type='checkbox'
                checked={invalid}
                className={styles.Check}
                onChange={() => {
                  setPage(1)
                  setFirst(0)
                  setInvalid(!invalid)
                }}
              />
            </div>
            <div className={styles.FilterContainer}>
              <label htmlFor='stateCheck' className={styles.StateLabel} style={{marginBottom: -1}}>
                <Translate value={'barcodes.status'} />:
              </label>
              <Input
                id='stateCheck'
                type='checkbox'
                checked={stateCheck}
                className={styles.Check}
                onChange={() => setStateCheck(!stateCheck)}
              />
            </div>
            <div className={styles.GlobalFilter}>
              <Input
                type='search'
                placeholder={I18n.t('workshops.searchPlaceholder')}
                onInput={e => {
                  setFirst(0)
                  setPage(1)
                  setGlobalFilter(e.target.value)}}
                className='global-filter'
              />
            </div>
          </div>
        }
        rows={rowsNumber}
        globalFilter={globalFilter}
        responsive={true}
        className='data-table-padding-class'
        emptyMessage={'Brak danych'}
        responsive={true}
        pageLinkSize={3}
        onSort={e=> {
          setPage(1)
          setFirst(0)
          e.sortField=='creator.role'? null: setSortField(e.sortField) 
          setSortDirection(sortDirection => sortDirection == 'ASC'? 'DESC' : 'ASC')}}
        ref={dataTableRef}>
        <Column
          field={'workshopEmail'}
          sortable
          header={I18n.t('userProfileForm.emailLabel')}
          body={rowData => (
            <div
              className={styles.ClicableField}
              onClick={() => showMoreData(rowData)}>
              {rowData.creator.email}
            </div>
          )}
        />
        <Column
          field={'creator.role'}
          header={I18n.t('barcodes.role')}
          sortable = {false}
          body={rowData => (
            <div
              className={styles.ClicableField}
              onClick={() => showMoreData(rowData)}>
              {mapRoles(rowData.creator.role)}
            </div>
          )}
        />
        <Column
          field='createdDate'
          header={I18n.t('barcodes.scanDate')}
          sortable
          body={rowData => (
            <div>
              {dateUtils.formatDate(rowData.createdDate) &&
                dateUtils.formatDate(rowData.createdDate, "YYYY-MM-DD")}
            </div>
          )}
        />
        <Column field='barcode' header={I18n.t('barcodes.barcode')} sortable />
        <Column
          field='occurrenceCount'
          header={I18n.t('barcodes.scansQuantity')}
          sortable
        />
        <Column
          field={'isSuspicious'}
          header={I18n.t('barcodes.status')}
          sortable
          body={rowData => (
            <div
              className={rowData.isSuspicious ? styles.RedDot : styles.GreenDot}
            />
          )}
        />
      </DataTable>
      <Paginator 
        first={first}
        rows={rowsNumber}
        totalRecords={pageInfo.totalElements}
        pageLinkSize = {5} 
        onPageChange={(e)=>{
          setPage(e.page + 1)
          setFirst(e.first)
        }} 
        leftContent = {
          <Dropdown
            options={rowsQuantityToDisplay}
            className='data-table-rows-dropdown'
            value={rowsNumber}
            onChange={e => {setPage(1), setRowsNumber(e.value)}}
         />
        }></Paginator>
    </div>
  );
};

export default BarcodesTable;
