/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateCity,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';
import ValidationMessageEnum, {ReqexpValidationEnum} from 'shared/utils/utils';

export const validate = (values, props) => {
  let errors = {};
  errors.firstName = validateFirstName(values.firstName);
  errors.lastName = validateLastName(values.lastName);
  errors.city = validateCity(values.city);
  errors.state = validateRequiredFieldsWithMaxLength(values.state, 255);
  errors.phoneNumber = validatePhoneNumber(values.phoneNumber, true);
  errors.country = validateRequiredFields(values.country);
  if (props.isAdminForm || props.data.socialUserOnly) {
    errors.newPassword = validateNewPasswordAdminForm(values);
    errors.confirmPassword = validateConfirmPasswordAdminForm(values);
  } else {
    errors.currentPassword = validateCurrentPassword(values);
    errors.newPassword = validateNewPassword(values);
    errors.confirmPassword = validateConfirmPassword(values);
  }

  return errors;
};

const validateCurrentPassword = values => {
  if (!values.currentPassword && (values.newPassword || values.confirmPassword))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
};

const validateNewPassword = values => {
  if (!values.newPassword && values.currentPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.currentPassword && values.newPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.currentPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(values.newPassword)
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (values.currentPassword && values.newPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

const validateConfirmPassword = values => {
  if (!values.confirmPassword && values.currentPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.currentPassword && values.confirmPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.currentPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
      values.confirmPassword
    )
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (
    values.currentPassword &&
    values.newPassword !== values.confirmPassword
  )
    return {key: ValidationMessageEnum.CONFIRM_PASSWORD, params: {}};
  else if (values.currentPassword && values.confirmPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

const validateNewPasswordAdminForm = values => {
  if (values.confirmPassword && !values.newPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.newPassword && values.newPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.newPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(values.newPassword)
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (values.newPassword && values.newPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

const validateConfirmPasswordAdminForm = values => {
  if (values.newPassword && !values.confirmPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.confirmPassword && values.confirmPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.confirmPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
      values.confirmPassword
    )
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (values.confirmPassword && values.confirmPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
  else if (
    values.confirmPassword &&
    values.newPassword !== values.confirmPassword
  )
    return {key: ValidationMessageEnum.CONFIRM_PASSWORD, params: {}};
};
