import WarrantyBookReadonlyMode from "./readonly-mode/WarrantyBookReadonlyMode";
import WarrantyBookEditMode from "./edit-mode/WarrantyBookEditMode";
import React from "react";
import styles from "./WarrantyBook.module.css";

const WarrantyBook = (props) => {
  const {
    editMode,
    initializedBook,
    setCarDetailsJSON,
    isCarDetailsJSONHasErrors,
    setIsCarDetailsJSONHasErrors,
    setWarrantyNumberJSON
  } = props;
  
  return (
    <>
      {editMode ? (
        <WarrantyBookEditMode
          initializedBook={initializedBook}
          setCarDetailsJSON = {setCarDetailsJSON}
          isCarDetailsJSONHasErrors = {isCarDetailsJSONHasErrors}
          setIsCarDetailsJSONHasErrors = {setIsCarDetailsJSONHasErrors}
          setWarrantyNumberJSON = {setWarrantyNumberJSON}
        />
      ) : (
        <WarrantyBookReadonlyMode />
      )}
    </>
  );
};

export default WarrantyBook;
