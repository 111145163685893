/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {loyaltyProgramActions} from 'shared/actions/loyalty-program.actions';
import {orderActions} from 'shared/actions/order.actions';
import FileDownload from 'shared/components/form/file-download/FileDownload';
import {loyaltyProgramConstants} from 'shared/constants/loyalty-program.constants';
import {orderConstants} from 'shared/constants/order.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {history} from 'shared/utils/history';
import {getOrEmptyObj} from 'shared/utils/utils';
import {BeforeChooseValuationModal} from '../../../user/valuations/before-choose/BeforeChooseValuationModal';
import WorkshopInactiveStatusAlert from '../../utils/WorkshopInactiveStatusAlert';
import {ValuationInquiryStatusEnum} from '../utils/utils';
import RenderValuationInquiryDetails from '../valuation-inquiry-details/render-valuation-inquiry-details/RenderValuationInquiryDetails';
import {mapToDetailsObject} from '../valuation-inquiry-details/render-valuation-inquiry-details/utils';
import RenderValuationServicesDetails from './render-valuations-details/render-services-details/RenderValuationServicesDetails';
import {RenderValuationSummaryDetails} from './render-valuations-details/render-summary-details/RenderValuationSummaryDetails';
import RenderValuationWorkshopInfoDetails from './render-valuations-details/render-workshop-info-details/RenderValuationWorkshopInfoDetails';

class ValuationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beforeChooseValuationModalOpen: false,
    };

    this.onChooseValuationSuccess = this.onChooseValuationSuccess.bind(this);
    this.generatePdfById = this.generatePdfById.bind(this);
    this.onCreateAnotherValuationBtnPress =
      this.onCreateAnotherValuationBtnPress.bind(this);
    this.closeAndRefreshParent = this.closeAndRefreshParent.bind(this);
    this.renderBeforeChooseValuationModal =
      this.renderBeforeChooseValuationModal.bind(this);
    this.chooseSelectedValuation = this.chooseSelectedValuation.bind(this);
    this.onChooseValuationClick = this.onChooseValuationClick.bind(this);
    this.shouldShowCreateAnotherValuationBtn =
      this.shouldShowCreateAnotherValuationBtn.bind(this);
  }

  componentWillUnmount() {
    this.props.clearSelectedValuation();
    this.props.clearSelectedValuationInquiry();
    this.props.clearLoyaltyProgramAccount();
  }

  generatePdfById() {
    const {valuation} = this.props;
    if (valuation) this.props.generateValuationPdfById(valuation.id);
  }

  closeAndRefreshParent() {
    this.props.hideUpdateDataSideBar();
    this.props.refreshParent();
  }

  onChooseValuationClick() {
    const {findLoyaltyProgramAccountByParams, valuation} = this.props;
    findLoyaltyProgramAccountByParams(
      valuation.senderUserId,
      userService.getUserId()
    );
    this.setState({beforeChooseValuationModalOpen: true});
  }

  chooseSelectedValuation(beforeChooseValuationModalState) {
    const {valuation, chooseValuation} = this.props;
    const body = {
      chosenLoyaltyProgramId:
        beforeChooseValuationModalState.activeLoyaltyProgramId,
      appointmentDate: beforeChooseValuationModalState.appointmentDate,
    };
    chooseValuation(valuation.id, body, this.onChooseValuationSuccess);
  }

  renderBeforeChooseValuationModal() {
    const {valuation, valuationInquiry, activeLoyaltyProgramAccount} =
      this.props;
    const showConfirmChooseValuationAlert =
      valuation &&
      valuationInquiry &&
      (valuationInquiry.status === ValuationInquiryStatusEnum.NEW ||
        valuationInquiry.status === ValuationInquiryStatusEnum.EXTENDED_PERIOD);

    return (
      <BeforeChooseValuationModal
        isOpen={this.state.beforeChooseValuationModalOpen}
        handleConfirm={this.chooseSelectedValuation}
        handleCancel={() =>
          this.setState({beforeChooseValuationModalOpen: false})
        }
        showConfirmChooseValuationAlert={showConfirmChooseValuationAlert}
        loyaltyProgramAccount={activeLoyaltyProgramAccount}
        workshopUserId={getOrEmptyObj(valuation).senderUserId}
      />
    );
  }

  onChooseValuationSuccess() {
    const {valuationInquiry} = this.props;
    this.props.hideUpdateDataSideBar();
    this.props.findAllValuationsByInquiryNumber(
      valuationInquiry.inquiryNumber.origin,
      valuationInquiry.inquiryNumber.number
    );
  }

  isAnyValuationChosenForCurrentInquiry() {
    const {valuations} = this.props;
    const chosenValuation = valuations
      ? valuations.find(valuation => valuation.chosen === true)
      : {};
    return chosenValuation ? true : false;
  }

  onCreateAnotherValuationBtnPress() {
    const {inquiryNumber} = this.props.valuation;
    history.push(
      `/workshop/valuations/inquiry/${inquiryNumber.origin}/${inquiryNumber.number}`
    );
    this.props.hideUpdateDataSideBar();
  }

  hasCorrectStatus() {
    if (this.props.valuation) {
      const status = this.props.valuation.valuationInquiryStatus;
      return (
        status === ValuationInquiryStatusEnum.NEW ||
        status === ValuationInquiryStatusEnum.EXTENDED_PERIOD
      );
    }
  }

  shouldShowCreateAnotherValuationBtn() {
    return (
      this.props.shouldShowCreateAnotherValuationBtn &&
      this.props.valuation &&
      !this.props.valuation.directClient &&
      this.hasCorrectStatus()
    );
  }

  shouldShowCustomerRatings() {
    const {valuationInquiry} = this.props;
    return (
      userService.isWorkshop() &&
      valuationInquiry &&
      valuationInquiry.senderUserId
    );
  }

  renderButtons() {
    return (
      <div className='row my-3 text-sm-right text-center'>
        <div className='col'>
          {this.renderBeforeChooseValuationModal()}
          {userService.isUser() &&
            !this.isAnyValuationChosenForCurrentInquiry() && (
              <Button
                id='choose-valuation'
                className='main-btn fixed-width-big-btn'
                onClick={this.onChooseValuationClick}>
                <Translate value='valuationDetails.chooseValuationBtn' />
              </Button>
            )}
          <Button
            id='generate-pdf'
            className='main-btn fixed-width-big-btn'
            onClick={this.generatePdfById}>
            <Translate value='valuationDetails.generatePdfBtn' />
          </Button>
          {this.shouldShowCreateAnotherValuationBtn() && (
            <Button
              id='create-another-valuation-btn'
              className='main-btn'
              onClick={this.onCreateAnotherValuationBtnPress}
              disabled={this.props.shouldShowWorkshopStatusAlert}>
              <Translate value='valuationDetails.createAnotherValuation' />
            </Button>
          )}
        </div>
      </div>
    );
  }

  renderValuationNumber() {
    const {valuation} = this.props;
    return (
      valuation &&
      valuation.valuationNumber &&
      valuation.valuationNumber.valuationNumberString
    );
  }

  renderCarAndUserDetails() {
    const {valuationInquiry} = this.props;
    return (
      <div className='row'>
        <div className='col-12'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'carDataTitle'}
            detailsData={
              valuationInquiry && mapToDetailsObject('car', valuationInquiry)
            }
          />
        </div>
        <div className='col-12'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'userDataTitle'}
            detailsData={
              valuationInquiry && mapToDetailsObject('user', valuationInquiry)
            }
            customerUserId={
              this.shouldShowCustomerRatings() && valuationInquiry.senderUserId
            }
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      shouldShowWorkshopInfoSection,
      shouldShowWorkshopStatusAlert,
      valuation,
      isFetching,
    } = this.props;
    return (
      <div className='m-auto' style={{width: '90%'}}>
        <Loadable active={isFetching} spinner>
          <WorkshopInactiveStatusAlert show={shouldShowWorkshopStatusAlert} />
          <RenderValuationServicesDetails valuation={valuation} isValuation = {true} />
          {this.renderCarAndUserDetails()}
          <RenderValuationSummaryDetails valuation={valuation} />
          {shouldShowWorkshopInfoSection && (
            <RenderValuationWorkshopInfoDetails valuation={valuation} />
          )}
          <div className='m-auto'>
            {valuation && valuation.attachment && (
              <FileDownload attachment={valuation.attachment} />
            )}
            {this.renderButtons()}
          </div>
        </Loadable>
      </div>
    );
  }
}

ValuationDetails.propTypes = {
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
  shouldShowCreateAnotherValuationBtn: PropTypes.bool.isRequired,
  shouldShowWorkshopInfoSection: PropTypes.bool.isRequired,
  refreshParent: PropTypes.func,
};

ValuationDetails.defaultProps = {
  shouldShowCreateAnotherValuationBtn: true,
  refreshParent: () => {},
};

function mapStateToProps(state) {
  const {i18n, order, loyaltyProgram} = state;
  const {valuation, isFetching, valuationInquiry, valuations} = order;
  const {activeLoyaltyProgramAccount} = loyaltyProgram;

  return {
    i18n,
    valuation,
    isFetching,
    valuationInquiry,
    valuations,
    activeLoyaltyProgramAccount,
  };
}

const mapDispatchToProps = {
  generateValuationPdfById: orderActions.generateValuationPdfById,
  chooseValuation: orderActions.chooseValuation,
  findAllValuationsByInquiryNumber:
    orderActions.findAllValuationsByInquiryNumberWithWorkshopInfos,
  findLoyaltyProgramAccountByParams:
    loyaltyProgramActions.findLoyaltyProgramAccountByParams,
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
  clearSelectedValuation: () => ({type: orderConstants.CLEAR_VALUATION}),
  clearSelectedValuationInquiry: () => ({
    type: orderConstants.CLEAR_VALUATION_INQUIRY,
  }),
  clearLoyaltyProgramAccount: () => ({
    type: loyaltyProgramConstants.CLEAR_LOYALTY_PROGRAM_ACCOUNT,
  }),
};

const connectedValuationDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationDetails);
export {connectedValuationDetails as ValuationDetails};
