/*Backend specific properties*/
export const apiUrl = process.env.REACT_APP_API_URL || 'apiUrlNotSet';
export const defaultClientId =
  process.env.REACT_APP_DEFAULT_CLIENT_ID || 'defaultClientIdNotSet';
export const defaultClientSecret =
  process.env.REACT_APP_DEFAULT_CLIENT_SECRET || 'defaultClientSecretNotSet';

/*Web specific properties*/
export const webBaseUrl =
  process.env.REACT_APP_WEB_BASE_URL || 'webBaseUrlNotSet';
export const linkToResetPassword = webBaseUrl + '/reset-password?token=';
export const linkToUserActivation = webBaseUrl + '/user-activation?token=';
export const languageVersion = 'WEB';
export const valuationInquiryRefreshIntervalMillis = 120000;
export const userNotificationsRefreshIntervalMillis = 60000;
export const loyaltyProgramSystemNotificationsRefreshIntervalMillis = 60000;
export const enableReduxLoggers = process.env.REACT_APP_ENABLE_REDUX_LOGGERS || 'enableReduxLoggersNotSet';

/*3rd party specific properties*/
export const fbAppId = process.env.REACT_APP_FB_APP_ID || 'fbAppIdNotSet';
export const googleAppId =
  process.env.REACT_APP_GOOGLE_APP_ID || 'googleAppIdNotSet';
export const googleReCaptchaSiteKey =
  process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ||
  'googleReCaptchaSiteKeyNotSet';

// AC environments
export const acCompany = process.env.REACT_APP_AC_COMPANY || 'acCompanyNotSet';
export const acCity = process.env.REACT_APP_AC_CITY || 'acCityNotSet';
export const acAddress = process.env.REACT_APP_AC_ADDRESS || 'acAddressNotSet';
export const aCStreet = process.env.REACT_APP_AC_STREET || 'aCStreetNotSet';
export const aCContact = process.env.REACT_APP_AC_CONTACT || 'aCContactNotSet';
export const aCEmail = process.env.REACT_APP_AC_EMAIL || 'aCEmailNotSet';
