/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {ResetPasswordForm} from './ResetPasswordForm';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetToken: '',
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);
    this.setState({resetToken: searchParams.get('token')});
  }

  onSubmitHandler(password, event) {
    event.preventDefault();
    const {dispatch} = this.props;
    let data = {password: password, resetToken: this.state.resetToken};
    dispatch(userActions.resetPassword(data));
  }

  render() {
    const {isFetching} = this.props;
    return (
      <ContentWrapper
        title='resetPassword.cardTitle'
        containerClass='sign-up-container'>
        <Loadable active={isFetching} spinner>
          <ResetPasswordForm onSubmit={this.onSubmitHandler} />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps({user}) {
  const {isFetching} = user;
  return {isFetching};
}

export default connect(mapStateToProps)(ResetPassword);
