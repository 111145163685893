/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';

export const formatAddress = address => {
  const {streetAddress, city, postalCity, postalCode, country, state} = address;
  const firstGroup = streetAddress + (city === postalCity ? '' : ', ' + city);
  const secondGroup = postalCode + ' ' + postalCity + ', ' + state;

  return (
    firstGroup +
    ', ' +
    secondGroup +
    ', ' +
    I18n.t(`country.${country.toLowerCase()}`)
  );
};

export const formatCityAddress = address => {
  const {streetAddress, city, postalCode, state} = address;
  return streetAddress + ', ' + city + ' ' + postalCode + ', ' + state;
};
