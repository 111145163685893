/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, useState} from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {Translate} from 'react-redux-i18n';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import styles from './Reports.module.css';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {workshopLoyaltyProgramActions} from 'shared/actions/workshopLoyaltyProgram.actions';
import {useForm, Controller} from 'react-hook-form';
import RenderDatePicker from 'shared/components/form/date-picker/hook-form-version/RenderDatePicker';
import {
  generateReportWithPrizes,
  generateWorkshopsBlockingHistoryReport,
} from 'shared/utils/xlsx/xlsx-utils';

const GenerateReportEnum = {
  PRIZES: 'PRIZES',
  WORKSHOPS_BLOCKING_HISTORY: 'WORKSHOPS_BLOCKING_HISTORY',
};

export const Reports = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: {errors},
  } = useForm({
    mode: 'onSubmit',
  });

  const [generateReportFlag, setGenerateReportFlag] = useState(null);
  const workshops = useSelector(
    state => state.workshopLoyaltyProgram.workshopsWithHistory
  );
  const isFetchingWorkshops = useSelector(
    state => state.workshopLoyaltyProgram.workshopsWithHistoryFetching
  );
  const workshopsParticipationBlockHistory = useSelector(
    state => state.workshopLoyaltyProgram.workshopsParticipationBlockHistory
  );
  const workshopsParticipationBlockHistoryFetching = useSelector(
    state =>
      state.workshopLoyaltyProgram.workshopsParticipationBlockHistoryFetching
  );

  useEffect(() => {
    const {startDate, stopDate} = getValues();
    if (
      workshops &&
      !isFetchingWorkshops &&
      generateReportFlag === GenerateReportEnum.PRIZES
    ) {
      generateReportWithPrizes(workshops, startDate, stopDate);
      setGenerateReportFlag(null);
    } else if (
      !!(
        workshopsParticipationBlockHistory &&
        workshopsParticipationBlockHistory.content &&
        !workshopsParticipationBlockHistoryFetching
      ) &&
      generateReportFlag === GenerateReportEnum.WORKSHOPS_BLOCKING_HISTORY
    ) {
      generateWorkshopsBlockingHistoryReport(
        workshopsParticipationBlockHistory.content,
        startDate,
        stopDate
      );
      setGenerateReportFlag(null);
    }
  }, [
    workshops,
    isFetchingWorkshops,
    generateReportFlag,
    workshopsParticipationBlockHistory,
    workshopsParticipationBlockHistoryFetching,
  ]);

  const generateReport = reportType => {
    if (reportType === GenerateReportEnum.WORKSHOPS_BLOCKING_HISTORY) {
      dispatch(
        workshopLoyaltyProgramActions.loyaltyParticipationLockHistory({
          page: 0,
          size: 100000,
          sort: `date,asc`,
        })
      );
    } else {
      dispatch(workshopLoyaltyProgramActions.getWorkshopsWithHistory());
    }
    setGenerateReportFlag(reportType);
  };

  const startDateField = 'startDate';
  const stopDateField = 'stopDate';

  return (
    <ContentWrapper title='workshopLoyaltyProgram.reports.header'>
      <Loadable active={isFetchingWorkshops} spinner>
        <form onSubmit={() => {}}>
          <div
            className={styles.Header}
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '2rem 1rem',
            }}
          >
            <Translate
              value={'workshopLoyaltyProgram.reports.selectStartAndEndDate'}
            />
          </div>
          <div className={styles.WorkshopLoyaltyFlexReport}>
            <div style={{width: 440, minWidth: 230}}>
              <Controller
                control={control}
                name={startDateField}
                rules={{required: true}}
                render={({field: {value, onChange}}) => (
                  <RenderDatePicker
                    selected={value}
                    onChange={onChange}
                    label={I18n.t('workshopLoyaltyProgram.reports.startDate')}
                    error={errors[startDateField]}
                    maxDate={
                      watch(stopDateField)
                        ? moment(watch(stopDateField))
                        : undefined
                    }
                    showClearIcon={true}
                  />
                )}
              />
            </div>
            <div style={{width: 440, minWidth: 230}}>
              <Controller
                control={control}
                name={stopDateField}
                rules={{required: true}}
                render={({field: {value, onChange}}) => (
                  <RenderDatePicker
                    selected={value}
                    onChange={onChange}
                    label={I18n.t('workshopLoyaltyProgram.reports.endDate')}
                    error={errors[stopDateField]}
                    minDate={
                      watch(startDateField)
                        ? moment(watch(startDateField))
                        : undefined
                    }
                    showClearIcon={true}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.ButtonSection}>
            <Button
              className='main-btn'
              style={{width: '18.7rem', minWidth: 230}}
              onClick={() => generateReport(GenerateReportEnum.PRIZES)}
            >
              <Translate
                value={'workshopLoyaltyProgram.reports.generateReport'}
              />
            </Button>
            <Button
              className='main-btn'
              style={{width: '21rem', minWidth: 230, whiteSpace: 'normal'}}
              onClick={() =>
                generateReport(GenerateReportEnum.WORKSHOPS_BLOCKING_HISTORY)
              }
            >
              <Translate
                value={
                  'workshopLoyaltyProgram.reports.generateWorkshopsBlockingReport'
                }
              />
            </Button>
          </div>
        </form>
      </Loadable>
    </ContentWrapper>
  );
};
