/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';
import {pdfRequestConfig} from '../utils/pdf/pdf-utils';

export const statisticService = {
  fetchValuationInquiryStatistics,
  fetchFilteredValuationInquiryStatistics,
  fetchValuationStatistics,
  fetchFilteredValuationStatistics,
  fetchLpgStatistics,
  fetchFilteredLpgStatistics,
  findAllCities,
  findAllStates,
  findAllWorkshopOptions,
  generateStatisticsPdf,
  generateLpgStatisticsPdf,
  fetchOrderStatistics,
  fetchFilteredOrderStatistics,
};

function fetchValuationInquiryStatistics() {
  return axiosService
    .get('/order/statistics/valuationInquiry')
    .then(response => {
      return response;
    });
}

function fetchFilteredValuationInquiryStatistics(data) {
  return axiosService
    .get(
      '/order/statistics/valuationInquiry/filter',
      composeLocalizationFilterParams(data)
    )
    .then(response => {
      return response;
    });
}

function fetchValuationStatistics() {
  return axiosService.get('/order/statistics/valuation').then(response => {
    return response;
  });
}

function fetchFilteredValuationStatistics(data) {
  return axiosService
    .get(
      '/order/statistics/valuation/filter',
      composeLocalizationFilterParams(data)
    )
    .then(response => {
      return response;
    });
}

function fetchLpgStatistics() {
  return axiosService.get('/order/statistics/lpg').then(response => {
    return response;
  });
}

function fetchFilteredLpgStatistics(data) {
  return axiosService
    .get('/order/statistics/lpg/filter', composePartsFilterParams(data))
    .then(response => {
      return response;
    });
}

function findAllCities() {
  return axiosService.get('/order/statistics/cities').then(response => {
    return response;
  });
}

function findAllStates() {
  return axiosService.get('/order/statistics/states').then(response => {
    return response;
  });
}

function findAllWorkshopOptions() {
  return axiosService.get('/order/statistics/workshops').then(response => {
    return response;
  });
}

function generateStatisticsPdf(data, resourcePath) {
  const filterData = data ? data : composeLocalizationFilterParams(data);
  return axiosService
    .post(`/order/statistics/${resourcePath}/pdf`, filterData, pdfRequestConfig)
    .then(response => {
      return response;
    });
}

function generateLpgStatisticsPdf(data) {
  const filterData = data ? data : composePartsFilterParams(data);
  return axiosService
    .post('/order/statistics/lpg/pdf', filterData, pdfRequestConfig)
    .then(response => {
      return response;
    });
}

function composeLocalizationFilterParams(data) {
  return {
    params: {
      country: !data ? null : data.country,
      workshopId: !data ? null : data.workshopId,
      city: !data ? null : data.city,
      state: !data ? null : data.state,
      startDate: !data ? null : data.startDate,
      endDate: !data ? null : data.endDate,
      serviceId: !data ? null : data.serviceId,
    },
  };
}

function composePartsFilterParams(data) {
  return {
    params: {
      partIds: !data ? null : data.partIds,
      startDate: !data ? null : data.startDate,
      endDate: !data ? null : data.endDate,
    },
  };
}

function fetchOrderStatistics() {
  return axiosService.get('/order/statistics/order').then(response => {
    return response;
  });
}

function fetchFilteredOrderStatistics(data) {
  return axiosService
    .get(
      '/order/statistics/order/filter',
      composeLocalizationFilterParams(data)
    )
    .then(response => {
      return response;
    });
}
