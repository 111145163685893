/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {orderActions} from 'shared/actions/order.actions';
import {orderConstants} from 'shared/constants/order.constants';
import {isEmptyArray} from 'shared/utils/utils';
import {RenderValuationInquiryDetails} from './RenderValuationInquiryDetails';

class RenderValuationInquiryServicesDetails extends RenderValuationInquiryDetails {
  constructor(props) {
    super(props);
    this.state = {isFirstFetch: true};
  }

  componentDidUpdate() {
    const {servicesIds} = this.props;
    if (!isEmptyArray(servicesIds) && this.state.isFirstFetch) {
      this.setState({isFirstFetch: false});
      this.props.findAllValuationInquiryServicesByIds(servicesIds);
    }
  }

  componentWillUnmount() {
    this.props.clearValuationInquiryServices();
  }

  renderDetails(service) {
    return (
      <div className='col' key={service.name}>
        <div className='row'>
          <div className='col-12'>
            <Translate
              value={`valuationInquiryDetails.serviceCategoryLabel`}
              className='mr-1 font-bold'
            />
            {service.serviceCategoryName}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Translate
              value={`valuationInquiryDetails.serviceNameLabel`}
              className='mr-1 font-bold'
            />
            {service.name}
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Translate
              value={`valuationInquiryDetails.serviceDescriptionLabel`}
              className='mr-1 font-bold'
            />
            {service.description}
          </div>
        </div>
      </div>
    );
  }

  renderOtherServices(otherServices) {
    return (
      <div className='col mt-3' key='other-services'>
        <div className='row'>
          <div className='col-12'>
            <Translate
              value={`valuationInquiryDetails.otherServices`}
              className='mr-1 font-bold'
            />
            {otherServices}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const {valuationInquiryServices, valuationInquiry} = this.props;
    return (
      <Collapse isOpen={this.state.collapse}>
        <div className='row valuation-inquiry-details-content'>
          <div className='col'>
            {valuationInquiryServices &&
              valuationInquiryServices.map(service =>
                this.renderDetails(service)
              )}
            {valuationInquiry &&
              valuationInquiry.otherServices &&
              this.renderOtherServices(valuationInquiry.otherServices)}
          </div>
        </div>
      </Collapse>
    );
  }

  render() {
    return (
      <div className='details-border mt-1'>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}
RenderValuationInquiryDetails.propTypes = {
  servicesIds: PropTypes.array,
};

RenderValuationInquiryServicesDetails.defaultPops = {
  servicesIds: [],
};

const mapStateToProps = state => {
  const {i18n} = state;
  const {order} = state;
  const {valuationInquiryServices, valuationInquiry} = order;

  return {i18n, valuationInquiryServices, valuationInquiry};
};

const mapDispatchToProps = {
  findAllValuationInquiryServicesByIds:
    orderActions.findAllValuationInquiryServicesByIds,
  clearValuationInquiryServices: () => ({
    type: orderConstants.CLEAR_VALUATION_INQUIRY_SERVICES,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderValuationInquiryServicesDetails);
