import React from 'react';
import styles from './Popup.module.css';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

const Popup = props => {
  const {message, variant, children, states} = props;

  const prepareMessages = states => {
    const messages = [];

    switch (states.BOX.result) {
      case 'VALID':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${states.BOX.code}: ${I18n.t(
            'barcodes.addBarCodeSuccess'
          )}`,
          style: {color: '#408140'},
        });
        break;
      case 'CREATOR_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${states.BOX.code}: ${I18n.t(
            'barcodes.scanCreatorDuplicate'
          )}`,
          style: {color: '#d39012'},
        });
        break;
      case 'USER_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${states.BOX.code}: ${I18n.t(
            'barcodes.scanUserDuplicate'
          )}`,
          style: {color: '#983a37'},
        });
        break;

      default:
        messages.push({message: null, style: null});
        break;
    }

    switch (states.CONTROLLER.result) {
      case 'VALID':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.CONTROLLER.code
          }: ${I18n.t('barcodes.addBarCodeSuccess')}`,
          style: {color: '#408140'},
        });
        break;
      case 'CREATOR_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.CONTROLLER.code
          }: ${I18n.t('barcodes.scanCreatorDuplicate')}`,
          style: {color: '#d39012'},
        });
        break;
      case 'USER_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.CONTROLLER.code
          }: ${I18n.t('barcodes.scanUserDuplicate')}`,
          style: {color: '#983a37'},
        });
        break;
      default:
        messages.push({message: null, style: null});
        break;
    }
    switch (states.INJECTOR.result) {
      case 'VALID':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.INJECTOR.code
          }: ${I18n.t('barcodes.addBarCodeSuccess')}`,
          style: {color: '#408140'},
        });
        break;
      case 'CREATOR_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.INJECTOR.code
          }: ${I18n.t('barcodes.scanCreatorDuplicate')}`,
          style: {color: '#d39012'},
        });
        break;
      case 'USER_DUPLICATE':
        messages.push({
          message: `${I18n.t('barcodes.barcode')} ${
            states.INJECTOR.code
          }: ${I18n.t('barcodes.scanUserDuplicate')}`,
          style: {color: '#983a37'},
        });
        break;
      default:
        messages.push({message: null, style: null});
        break;
    }

    return messages;
  };

  const renderPopup = () => {
    switch (variant) {
      case 'ACCEPT': {
        return (
          <div className={styles.ContainerAccept}>
            <p className={styles.MessageAccept}>{message}</p>
            {children}
          </div>
        );
      }
      case 'WARNING': {
        return (
          <div className={styles.ContainerWarning}>
            <p className={styles.MessageWarning}>{message}</p>
            {children}
          </div>
        );
      }
      case 'BARCODE_INFO': {
        const [boxMessage, controllerMessage, injectorMessage] =
          prepareMessages(states);

        return (
          <div className={styles.ContainerDescription}>
            {boxMessage.message && (
              <p style={boxMessage.style}>{boxMessage.message} </p>
            )}
            {controllerMessage.message && (
              <p style={controllerMessage.style}>{controllerMessage.message}</p>
            )}
            {injectorMessage.message && (
              <p style={injectorMessage.style}>{injectorMessage.message} </p>
            )}
            {children}
          </div>
        );
      }
      default:
        return null;
    }
  };

  return renderPopup();
};

Popup.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.oneOf(['ACCEPT', 'WARNING', 'BARCODE_INFO']),
};

export default Popup;
