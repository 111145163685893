/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredFieldsWithMaxLength,
  validateRequiredNumberWithRange,
} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.title = validateRequiredFieldsWithMaxLength(values.title, 120);
  errors.points = validateRequiredNumberWithRange(values.points, 1);
  errors.description = validateByLengthOnly(values.description, 255);
  return errors;
};
