/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Rating} from 'primereact/rating';
import React from 'react';

export class RenderRating extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    const {input} = this.props;
    input.onChange(event.value);
  }

  render() {
    const {input, label, required} = this.props;

    return (
      <div className='row mt-3'>
        <div
          className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0 read-only-label'
          style={{whiteSpace: 'nowrap'}}>
          {label}
          {required && <span style={{color: 'red'}}>*</span>}
        </div>
        <div className='col-lg-9 col-12 text-lg-left'>
          <Rating
            value={input.value}
            cancel={false}
            onChange={this.handleOnChange}
          />
        </div>
      </div>
    );
  }
}
