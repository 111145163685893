/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reducer as reduxFormReducer} from 'redux-form';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {userReducer} from './shared/reducers/user.reducer';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {messageReducer} from './shared/reducers/message.reducer';
import {i18nReducer} from 'react-redux-i18n';
import {initI18n} from './shared/utils/i18n';
import ReduxPromise from 'redux-promise';
import {updateDataSideBarReducer} from './shared/reducers/update-data-side-bar.reducer';
import {workshopReducer} from './shared/reducers/workshop.reducer';
import {serviceReducer} from './shared/reducers/service.reducer';
import {partReducer} from './shared/reducers/part.reducer';
import {statuteReducer} from './shared/reducers/statute.reducer';
import {carReducer} from './shared/reducers/car.reducer';
import {orderReducer} from './shared/reducers/order.reducer';
import {userConstants} from './shared/constants/user.constants';
import {userNotificationReducer} from './shared/reducers/user-notification.reducer';
import {enableReduxLoggers} from './environment';
import {distributorReducer} from './shared/reducers/distributor.reducer';
import {countryReducer} from './shared/reducers/country.reducer';
import {promotionReducer} from './shared/reducers/promotion.reducer';
import {statisticReducer} from './shared/reducers/statistic.reducer';
import {settingsReducer} from './shared/reducers/settings.reducer';
import {orderRatingReducer} from './shared/reducers/order-rating.reducer';
import {loyaltyProgramReducer} from './shared/reducers/loyalty-program.reducer';
import {languageReducer} from './shared/reducers/language.reducer';
import {subscriptionReducer} from './shared/reducers/subscription.reducer';
import {subscriptionPlanReducer} from './shared/reducers/subscription-plan.reducer';
import {workshopWorkerReducer} from './shared/reducers/workshop-worker.reducer';
import {warrantyReducer} from './shared/reducers/warranty.reducer';
import {workshopLoyaltyProgramReducer} from './shared/reducers/workshopLoyaltyProgram.reducer';

const reducer = combineReducers({
  form: reduxFormReducer,
  user: userReducer,
  message: messageReducer,
  i18n: i18nReducer,
  updateSideBar: updateDataSideBarReducer,
  workshop: workshopReducer,
  service: serviceReducer,
  part: partReducer,
  statute: statuteReducer,
  car: carReducer,
  order: orderReducer,
  userNotification: userNotificationReducer,
  distributor: distributorReducer,
  country: countryReducer,
  promotion: promotionReducer,
  statistic: statisticReducer,
  settings: settingsReducer,
  orderRating: orderRatingReducer,
  loyaltyProgram: loyaltyProgramReducer,
  language: languageReducer,
  subscription: subscriptionReducer,
  subscriptionPlan: subscriptionPlanReducer,
  workshopWorker: workshopWorkerReducer,
  warrantyBook: warrantyReducer,
  workshopLoyaltyProgram: workshopLoyaltyProgramReducer,
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) state = {i18n: state.i18n};
  return reducer(state, action);
};

let middleware = [thunkMiddleware, ReduxPromise];

if (enableReduxLoggers === 'true') {
  const loggerMiddleware = createLogger();
  middleware = [...middleware, loggerMiddleware];
}

const store = createStore(rootReducer, applyMiddleware(...middleware));

initI18n(store);

export default store;
