/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {userService} from 'shared/services/user.service';
import AccessDenied from '../../access-dnied/AccessDenied';
import {Cars} from './cars/Cars';
import {CodeScanner} from './code-scanner/CodeScanner';
import {UserLoyaltyPrograms} from './loyalty-program/UserLoyaltyPrograms';
import {CreateOrderVendorRating} from './order-rating/CreateOrderVendorRating';
import {Orders} from './orders/Orders';
import {Valuations} from './valuations/Valuations';
import WarrantyBookList from '../../warranty/warranty-books-list/WarrantyBookList';
import WarrantyEdit from '../../warranty/warranty-edit/WarrantyEdit';

export default class UserContainer extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route
            path='/user/valuations'
            component={userService.isThinUser() ? AccessDenied : Valuations}
          />
          <Route
            path='/user/orders'
            component={userService.isThinUser() ? AccessDenied : Orders}
          />
          <Route exact path='/user/cars' component={Cars} />
          <Route
            exact
            path='/user/rate-order-vendor'
            component={CreateOrderVendorRating}
          />
          <Route
            exact
            path='/user/loyalty-programs'
            component={UserLoyaltyPrograms}
          />
          <Route exact path='/user/code-scanner' component={CodeScanner} />

          <Route exact path='/user/warranty' component={WarrantyBookList} />
          <Route exact path='/user/warranty/:id' component={WarrantyEdit} />

          <Redirect
            to={userService.isThinUser() ? '/user/profile' : '/user/valuations'}
          />
        </Switch>
      </div>
    );
  }
}
