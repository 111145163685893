/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import Steps, {Step} from 'rc-steps';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {formValueSelector, getFormValues} from 'redux-form';
import {carActions} from 'shared/actions/car.actions';
import {countryActions} from 'shared/actions/country.actions';
import {orderActions} from 'shared/actions/order.actions';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {DURATION_UNITS} from 'shared/components/form/duration/RenderDuration';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {ValuationInquiryModel} from 'shared/model/ValuationInquiryModel';
import {ValuationInquirySummaryModel} from 'shared/model/ValuationInquirySummaryModel';
import {ValuationModel} from 'shared/model/ValuationModel';
import {userService} from 'shared/services/user.service';
import {history} from 'shared/utils/history';
import {getOrEmptyObj, ValuationInquiryOriginEnum} from 'shared/utils/utils';
import {CarDataTypes} from '../../../valuation-inquiry/valuation-inquiry-form/page-2/ValuationInquiryFormSchemaPage2';
import WorkshopInactiveStatusAlert from '../utils/WorkshopInactiveStatusAlert';
import {setAdditionalCost, setLpgService} from '../valuations/utils/utils';
import {ValuationInquirySummary} from '../valuations/valuation-inquiry-response/valuation-inquiry-summary/ValuationInquirySummary';
import ValuationDirectClientsFormPage1 from './valuation-direct-clients-form/page-1/ValuationDirectClientsFormPage1';
import ValuationDirectClientsFormPage2 from './valuation-direct-clients-form/page-2/ValuationDirectClientsFormPage2';
import ValuationDirectClientsFormPage3 from './valuation-direct-clients-form/page-3/ValuationDirectClientsFormPage3';
import ValuationDirectClientsFormPage4 from './valuation-direct-clients-form/page-4/ValuationDirectClientsFormPage4';
import ValuationDirectClientsFormPage5 from './valuation-direct-clients-form/page-5/ValuationDirectClientsFormPage5';
import ValuationDirectClientsFormPage6 from './valuation-direct-clients-form/page-6/ValuationDirectClientsFormPage6';

class ValuationDirectClients extends React.Component {
  valuationDirectClientsVariants;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      valuationInquirySummary: null,
      confirmDraftModalOpen: false,
      valuationDirectClientDraft: null,
      isFirstLoad: true,
      pageTitle: '',
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.create = this.create.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderPagesForLpgOnly = this.renderPagesForLpgOnly.bind(this);
    this.renderPagesForServicesOnly =
      this.renderPagesForServicesOnly.bind(this);
    this.renderPagesForMixed = this.renderPagesForMixed.bind(this);
    this.renderValuationInquirySummary =
      this.renderValuationInquirySummary.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.load = this.load.bind(this);
    this.setPageTitle = this.setPageTitle.bind(this);

    this.valuationDirectClientsVariants = new Map();
    this.valuationDirectClientsVariants.set('LPG_ONLY', {
      render: this.renderPagesForLpgOnly,
      totalPages: 5,
    });
    this.valuationDirectClientsVariants.set('SERVICES_ONLY', {
      render: this.renderPagesForServicesOnly,
      totalPages: 5,
    });
    this.valuationDirectClientsVariants.set('MIXED', {
      render: this.renderPagesForMixed,
      totalPages: 6,
    });
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const {loggedWorkshopId} = this.props;
    if (this.isDraftLoaded(prevProps))
      this.setState({confirmDraftModalOpen: true});

    const shouldLoad = loggedWorkshopId && this.state.isFirstLoad;

    if (shouldLoad) {
      this.setState({isFirstLoad: false});
      this.load();
    }
  }

  componentWillUnmount() {
    this.props.resetForm();
  }

  load() {
    const {loggedWorkshopId} = this.props;
    this.props.findAllServiceCategoriesByWorkshopId(
      this.props.loggedWorkshopId
    );
    this.props.findFuelTypes();
    this.props.findCarBrands();
    this.props.findLpgServiceCategoryId();
    this.props.findAllPartCategories();
    this.props.findValuationDirectClientDraftByUserId(userService.getUserId());
    this.props.findAllCountries();
  }

  setPageTitle(pageTitle) {
    this.setState({pageTitle});
  }

  isDraftLoaded(prevProps) {
    return (
      prevProps.valuationDirectClientDraft !==
        this.props.valuationDirectClientDraft &&
      this.props.valuationDirectClientDraft
    );
  }

  nextPage() {
    this.setState({page: this.state.page + 1});
  }

  previousPage() {
    this.setState({page: this.state.page - 1});
  }

  create(values) {
    this.props.createValuation(
      this.toCorrectJson({...values}),
      this.onCreateSuccess
    );
  }

  createDraft() {
    const {formValues} = this.props;
    this.props.createValuationDirectClientDraft(formValues);
  }

  onCreateSuccess() {
    history.push('/');
  }

  toCorrectJson(formValues) {
    const {
      lpgServiceCategoryId,
      serviceCategories,
      initialValues,
      loggedWorkshopId,
      currency,
    } = this.props;
    if (initialValues.valuationInquiryType !== 'SERVICES_ONLY')
      setLpgService(
        formValues,
        serviceCategories,
        lpgServiceCategoryId,
        currency
      );
    formValues.userVoivodeship = formValues.state;
    delete formValues.state;
    formValues.origin = ValuationInquiryOriginEnum.KM;
    formValues.workshopsIds = [loggedWorkshopId];
    formValues.valuationInquiry = new ValuationInquiryModel(formValues);
    formValues.valuation = new ValuationModel(formValues);
    Object.keys(formValues.valuationInquiry).forEach(
      key => delete formValues[`${key}`]
    );
    Object.keys(formValues.valuation).forEach(
      key => delete formValues[`${key}`]
    );
    setAdditionalCost(formValues.valuation, currency);

    return formValues;
  }

  generatePdf() {
    this.props.generateValuationDirectClientsPdf(
      this.toCorrectJson({...this.props.formValues})
    );
  }

  cancel() {
    history.push('/');
    setTimeout(() => this.props.resetForm(), 100);
  }

  resolveInitialValues() {
    const {valuationDirectClientDraft} = this.state;
    const {initialValues} = this.props;
    return valuationDirectClientDraft
      ? {
          ...valuationDirectClientDraft,
          valuationInquiryType: initialValues.valuationInquiryType,
        }
      : initialValues;
  }

  renderPagesForLpgOnly() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <Fragment>
        {page === 4 && (
          <ValuationDirectClientsFormPage5
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 5 && (
          <ValuationDirectClientsFormPage6
            previousPage={this.previousPage}
            handleSubmit={this.create}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleGeneratePdf={this.generatePdf}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}
      </Fragment>
    );
  }

  renderPagesForServicesOnly() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <Fragment>
        {page === 4 && (
          <ValuationDirectClientsFormPage4
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 5 && (
          <ValuationDirectClientsFormPage6
            previousPage={this.previousPage}
            onSubmit={this.create}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleGeneratePdf={this.generatePdf}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}
      </Fragment>
    );
  }

  renderPagesForMixed() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <Fragment>
        {page === 4 && (
          <ValuationDirectClientsFormPage4
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 5 && (
          <ValuationDirectClientsFormPage5
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 6 && (
          <ValuationDirectClientsFormPage6
            previousPage={this.previousPage}
            onSubmit={this.create}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleGeneratePdf={this.generatePdf}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}
      </Fragment>
    );
  }

  renderSteps(stepsCount) {
    return (
      <div>
        <Steps
          className='valuation-inquiry-response-steps'
          labelPlacement='vertical'
          current={this.state.page - 1}>
          {[...new Array(stepsCount)].map((object, index) => (
            <Step key={index} />
          ))}
        </Steps>
        <Steps
          className='valuation-inquiry-response-steps-mobile'
          direction='vertical'
          labelPlacement='horizontal'
          current={this.state.page - 1}>
          {[...new Array(stepsCount)].map((object, index) => (
            <Step key={index} />
          ))}
        </Steps>
      </div>
    );
  }

  setValuationInquirySummary() {
    if (this.state.page === 4) {
      const valuationInquirySummary = new ValuationInquirySummaryModel(
        this.props.formValues,
        this.props.fuelTypes
      );
      !valuationInquirySummary.equals(this.state.valuationInquirySummary) &&
        this.setState({valuationInquirySummary: valuationInquirySummary});
    }
  }

  renderValuationInquirySummary() {
    this.setValuationInquirySummary();
    if (this.state.valuationInquirySummary && this.state.page >= 4) {
      return (
        <div className='my-4'>
          <ValuationInquirySummary
            valuationInquiry={this.state.valuationInquirySummary}
          />
        </div>
      );
    }
  }

  renderConfirmDraftModal() {
    const {
      deleteValuationDirectClientDraftByUserId,
      valuationDirectClientDraft,
    } = this.props;

    return (
      <ConfirmModal
        title='valuationDirectClients.existingDraftQuestion'
        isOpen={this.state.confirmDraftModalOpen}
        notShowAgainText='valuationDirectClients.deleteDraftCheckboxLabel'
        action={notShowAgain => {
          if (notShowAgain)
            deleteValuationDirectClientDraftByUserId(userService.getUserId());
          this.setState({confirmDraftModalOpen: false});
          this.setState({
            valuationDirectClientDraft: valuationDirectClientDraft,
          });
        }}
        handleCancel={notShowAgain => {
          if (notShowAgain)
            deleteValuationDirectClientDraftByUserId(userService.getUserId());
          this.setState({confirmDraftModalOpen: false});
          this.setState({valuationDirectClientDraft: null});
        }}
      />
    );
  }

  renderForm() {
    const {page, pageTitle} = this.state;
    const {user, loggedWorkshopCountry, countries, formValues} = this.props;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <ContentWrapper
        title={pageTitle}
        contentClass='valuation-inquiry-content'
        containerClass='valuation-inquiry-container'>
        {this.renderConfirmDraftModal()}
        {this.renderValuationInquirySummary()}

        {page === 1 && (
          <ValuationDirectClientsFormPage1
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 2 && (
          <ValuationDirectClientsFormPage2
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            user={user}
            handleCreateDraft={this.createDraft}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 3 && (
          <ValuationDirectClientsFormPage3
            formName={'valuationDirectClientsForm'}
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            loggedWorkshopCountry={loggedWorkshopCountry}
            countries={countries}
            formValues={formValues}
            setPageTitle={this.setPageTitle}
          />
        )}

        {this.valuationDirectClientsVariants
          .get(resolvedInitialValues.valuationInquiryType)
          .render()}
        {this.renderSteps(
          this.valuationDirectClientsVariants.get(
            resolvedInitialValues.valuationInquiryType
          ).totalPages
        )}
      </ContentWrapper>
    );
  }

  render() {
    const {shouldShowWorkshopStatusAlert} = this.props;

    return shouldShowWorkshopStatusAlert ? (
      <WorkshopInactiveStatusAlert show={true} />
    ) : (
      this.renderForm()
    );
  }
}

ValuationDirectClients.propTypes = {
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  findAllServiceCategoriesByWorkshopId:
    serviceCategoryActions.findAllServiceCategoriesByWorkshopId,
  findFuelTypes: carActions.findFuelTypes,
  findCarBrands: carActions.findCarBrands,
  resetForm: orderActions.resetValuationDirectClientsForm,
  findLpgServiceCategoryId: serviceCategoryActions.findLpgServiceCategoryId,
  findAllPartCategories: partCategoryActions.findAllPartCategories,
  createValuation: orderActions.createValuationForDirectClient,
  generateValuationDirectClientsPdf:
    orderActions.generateValuationDirectClientsPdf,
  findValuationDirectClientDraftByUserId:
    orderActions.findValuationDirectClientDraftByUserId,
  createValuationDirectClientDraft:
    orderActions.createValuationDirectClientDraft,
  deleteValuationDirectClientDraftByUserId:
    orderActions.deleteValuationDirectClientDraftByUserId,
  findAllCountries: countryActions.findAll,
};

const mapStateToProps = state => {
  const {i18n, service, workshop, car, user, order, country} = state;
  const {countries} = country;
  const {data} = workshop;
  const {fuelTypes} = car;
  const dataObj = getOrEmptyObj(data);
  const initialValues = {
    valuationInquiryServices: [{}],
    carDataType: CarDataTypes.NEW,
    valuationInquiryType: resolveValuationInquiryType(state),
    country: getOrEmptyObj(dataObj.address).country,
    durationUnit: DURATION_UNITS.HOURS,
  };
  const loggedWorkshopId = data ? data.workshopId : null;
  const loggedWorkshopCountry =
    data && data.address ? data.address.country : null;
  const currency =
    data && data.workshopDetails ? data.workshopDetails.currency : null;
  const {lpgServiceCategoryId} = service;
  const serviceCategories = service.workshopServiceCategories;
  const formValues = getFormValues('valuationDirectClientsForm')(state);
  const {valuationDirectClientDraft} = order;

  return {
    i18n,
    initialValues,
    lpgServiceCategoryId,
    serviceCategories,
    loggedWorkshopId,
    formValues,
    fuelTypes,
    user,
    valuationDirectClientDraft,
    currency,
    loggedWorkshopCountry,
    countries,
  };
};

const resolveValuationInquiryType = state => {
  const selector = formValueSelector('valuationDirectClientsForm');
  const valuationInquiryServices = selector(state, 'valuationInquiryServices');

  if (valuationInquiryServices) {
    if (
      valuationInquiryServices.length === 1 &&
      valuationInquiryServices.find(
        id => id.serviceCategoryId === state.service.lpgServiceCategoryId
      )
    )
      return 'LPG_ONLY';
    else if (
      valuationInquiryServices.length > 0 &&
      !valuationInquiryServices.find(
        id => id.serviceCategoryId === state.service.lpgServiceCategoryId
      )
    )
      return 'SERVICES_ONLY';
    else return 'MIXED';
  } else return 'MIXED';
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationDirectClients);
