import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Translate } from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { warrantyConstants } from "shared/constants/warranty.constants";
import { RenderField } from "shared/components/form/text-field/hook-form-version/RenderField";
import styles from "./WarrantyModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { warrantyActions } from "shared/actions/warranty.actions";
import { I18n } from "react-redux-i18n";
import useWarrantyBookLocalStorageGetValue from "shared/hooks/useWarrantyBookLocalStorageGetValue";

const WarrantyModal = (props) => {
  const {
    wannahCreateStep,
    createStep,
    leaveStep,
    enterStep,
    errorStep,
    setConfirmedNavigation,
    setEditMode,
    transferObject,
    deactivateStep,
    cancelStep
  } = props;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const vinField = "vin";
  const dispatch = useDispatch();

  const [linkProps, setLinkProps] = useState(null);
  const [vinNumber, setVinNumber] = useState(null);
  const warrantyBooks = useSelector((state) => state.warrantyBook.warranties);

  useEffect(() => {
    if (transferObject) {
      setLinkProps(transferObject);
    }
  }, [transferObject]);

  useEffect(() => {
    if(createStep){
      dispatch(warrantyActions.getAllWarranties());
    }
  }, [createStep]);

  const warrantyBookId = useWarrantyBookLocalStorageGetValue("id");
  const warrantyBook = useWarrantyBookLocalStorageGetValue();

  const onSubmit = ({ vin }) => {
    if (warrantyBooks.some((warranty) => warranty.vin === vin)) {
      invokeSelf(
        { deactivateStep: true },
        I18n.t("warranty.deactivateOldWarrantyBook")
      );
      setVinNumber(vin);
    } else {
      dispatch(warrantyActions.initNewWarrantyBook(vin, linkProps));
      closeModal();
    }
  };

  const deactivateOldWarrantyBook = () => {
    dispatch(warrantyActions.initNewWarrantyBook(vinNumber, linkProps));
    setVinNumber(null);
    closeModal();
  };
  const invokeSelf = (step, title) => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WarrantyModal {...step} />,
      title: title ?? I18n.t("warranty.createNewWarrantyBook"),
    });
  };

  const closeModal = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
  };

  const onSave = () => {
    dispatch(warrantyActions.editWarrantyBook(warrantyBookId, warrantyBook, setEditMode));
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
  };

  const onLeaveWithDataSave = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    dispatch(warrantyActions.editWarrantyBook(warrantyBookId, warrantyBook));
    setConfirmedNavigation(true);
    localStorage.removeItem("editMode");
    localStorage.removeItem("warrantyBook");
  };

  const onLeaveWithoutDataSave = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    dispatch({
      type: warrantyConstants.DELETE_INITIALIZED_WARRANTY,
    });
    setConfirmedNavigation(true);
    localStorage.removeItem("warrantyBook");
    localStorage.removeItem("editMode");
  };

  const onCancelEditMode = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    setEditMode(false);
  }

  return (
    <div className={styles.Container} style={{ padding: "25px" }}>
      {wannahCreateStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("warranty.wannahCreateWarrantyBookModalBody")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("warranty.wannahCreateWarrantyBookModalBodyBold")}
          </p>
          <div className={styles.buttonPanel}>
            <Button
              className="main-btn"
              onClick={() =>
                invokeSelf(
                  { createStep: true },
                  I18n.t("warranty.addNewWarrantyBook")
                )
              }
            >
              <Translate value={"warranty.createWarrantyBook"} />
            </Button>
            <Button className="main-btn" onClick={closeModal}>
              <Translate value={"warranty.cancel"} />
            </Button>
          </div>
        </>
      )}
      {deactivateStep && (
        <>
          <p className={styles.paragraphBold}>
            {I18n.t("warranty.deactivateOldWarrantyBookModalBody")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={deactivateOldWarrantyBook}>
              <Translate value={"warranty.yes"} />
            </Button>
            <Button className="main-btn" onClick={closeModal}>
              <Translate value={"warranty.no"} />
            </Button>
          </div>
        </>
      )}
      {createStep && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <RenderField
            {...register(vinField, {
              required: true,
              maxLength: 17
            })}
            label={"Numer VIN pojazdu"}
            error={errors[vinField]}
          />
          <div className={styles.buttonPanel}>
            <Button className="main-btn" type="submit">
              <Translate value={"warranty.create"} />
            </Button>
          </div>
        </form>
      )}

      {enterStep && (
        <>
          <p className={styles.paragraphBold}>
            {I18n.t("warranty.onEditChangesApproveModalBody")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSave}>
              <Translate value={"warranty.confirm"} />
            </Button>
            <Button className="main-btn" onClick={closeModal}>
              <Translate value={"warranty.cancel"} />
            </Button>
          </div>
        </>
      )}

      {cancelStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("warranty.leaveStepDescription")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("warranty.leaveStepQuestion")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSave}>
              <Translate value={"warranty.confirm"} />
            </Button>
            <Button className="main-btn" onClick={onCancelEditMode}>
              <Translate value={"warranty.cancel"} />
            </Button>
          </div>
        </>
      )}

      {leaveStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("warranty.leaveStepDescription")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("warranty.leaveStepQuestion")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onLeaveWithDataSave}>
              <Translate value={"warranty.confirm"} />
            </Button>
            <Button className="main-btn" onClick={onLeaveWithoutDataSave}>
              <Translate value={"warranty.cancel"} />
            </Button>
          </div>
        </>
      )}

      {errorStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("warranty.hasErrorsModalBody")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={closeModal}>
              <Translate value={"warranty.backModal"} />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default WarrantyModal;
