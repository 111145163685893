import PeriodicInspectionsReadOnly from '../PeriodicInspectionsReadOnly';
import PeriodicInspectionNotStarted from '../periodic-inspection-not-started/PeriodicInspectionNotStarted';
import React, {useState} from 'react';
import PeriodicInspectionsEdit from '../periodic-inspections-edit-mode/PeriodicInspectionsEdit';

const PeriodicInspectionEntry = props => {
  const [editMode, setEditMode] = useState(false);
  const [saved, setSaved] = useState(false);

  const renderMode = () => {
    if (!props.inspectionDate && !editMode && !saved) {
      return (
        <PeriodicInspectionNotStarted setEditMode={() => setEditMode(true)} />
      );
    }

    if ((props.inspectionDate || saved) && !editMode) {
      return (
        <PeriodicInspectionsReadOnly
          inspectionData={props}
          setEditMode={() => setEditMode(true)}
        />
      );
    }

    if (editMode) {
      return (
        <PeriodicInspectionsEdit
          inspectionDraft={props}
          onSave={() => {
            setEditMode(false);
            setSaved(true);
          }}
          onCancel={() => {
            setEditMode(false);
            setSaved(false);
          }}
        />
      );
    }
  };

  return <div>{renderMode()}</div>;
};

export default PeriodicInspectionEntry;
