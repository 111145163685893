/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

/*global google*/
import {GMap} from 'primereact/gmap';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {change, getFormValues} from 'redux-form';
import {messageActions} from '../../../actions/message.actions';

const componentsForm = {
  street_number: {name: 'long_name', formFieldName: 'streetAddress'},
  route: {name: 'long_name', formFieldName: 'streetAddress'},
  locality: {name: 'long_name', formFieldName: 'city'},
  postal_code: {name: 'long_name', formFieldName: 'postalCode'},
};

export class RenderGmap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overlays: null,
      searchBox: null,
      bounds: null,
      isFirstCenter: true,
    };

    this.placeChangedHandler = this.placeChangedHandler.bind(this);
    this.initAutocomplete = this.initAutocomplete.bind(this);
  }

  componentDidMount() {
    this.initAutocomplete();
  }

  componentDidUpdate(prevProps) {
    const {formValues} = this.props;
    if (
      this.state.isFirstCenter &&
      formValues &&
      formValues.latitude &&
      formValues.longitude
    ) {
      this.centerOnMarkerOnInit(this.state.bounds);
      this.setState({isFirstCenter: false});
    }
  }

  initAutocomplete() {
    const searchBox = new google.maps.places.SearchBox(this.refs.searchBox);
    const bounds = new google.maps.LatLngBounds();
    searchBox.addListener('places_changed', this.placeChangedHandler);
    this.map
      .getMap()
      .controls[google.maps.ControlPosition.TOP_LEFT].push(this.refs.searchBox);
    this.setState({searchBox: searchBox, bounds: bounds});
  }

  centerOnMarkerOnInit(bounds) {
    const {formValues} = this.props;
    if (!formValues) return;

    this.setState({
      overlays: [
        new google.maps.Marker({
          position: {
            lat: parseFloat(formValues.latitude),
            lng: parseFloat(formValues.longitude),
          },
        }),
      ],
    });
    const myLatlng = new google.maps.LatLng(
      parseFloat(formValues.latitude),
      parseFloat(formValues.longitude)
    );
    bounds.extend(myLatlng);
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    this.map.getMap().fitBounds(bounds);
  }

  placeChangedHandler() {
    const places = this.state.searchBox.getPlaces();
    if (places.length === 0) {
      this.props.dispatch(
        messageActions.errorMessage('renderField.placesNotFound')
      );
      return;
    }

    const place = places[0];

    if (!place.geometry) {
      this.props.dispatch(
        messageActions.errorMessage('renderField.placesNotFound')
      );
      return;
    }

    this.setState({
      overlays: [new google.maps.Marker({position: place.geometry.location})],
    });

    if (place.geometry.viewport)
      this.state.bounds.union(place.geometry.viewport);
    else this.state.bounds.extend(place.geometry.location);

    this.map.getMap().fitBounds(this.state.bounds);
    const data = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      googleAddressComponents: place.address_components
        ? place.address_components.reverse()
        : null,
    };
    this.fillForm(data);
  }

  fillForm(data) {
    this.props.dispatch(
      change(this.props.formName, 'latitude', data.latitude.toString())
    );
    this.props.dispatch(
      change(this.props.formName, 'longitude', data.longitude.toString())
    );
    this.props.dispatch(change(this.props.formName, 'streetAddress', ''));
    if (data.googleAddressComponents)
      data.googleAddressComponents.forEach(googleComponent => {
        const addressType = googleComponent.types[0];
        const component = componentsForm[addressType];
        if (componentsForm.hasOwnProperty(addressType))
          this.props.dispatch(
            change(
              this.props.formName,
              component.formFieldName,
              component.formFieldName === 'streetAddress' &&
                this.props.formValues[component.formFieldName]
                ? this.props.formValues[component.formFieldName] +
                    ' ' +
                    googleComponent[component.name]
                : googleComponent[component.name]
            )
          );
      });
    this.props.dispatch(messageActions.successMessage('renderField.checkData'));
  }

  render() {
    const {searchBoxPlaceholderKey, mapMinHeight, inputDisabled} = this.props;
    const options = {
      center: {lat: 52.237049, lng: 21.017532},
      zoom: 5,
      gestureHandling: 'cooperative',
      streetViewControl: this.props.streetViewControl,
    };

    return (
      <div className='mt-3 mb-3'>
        <input
          id='search-box'
          type='text'
          ref='searchBox'
          disabled={inputDisabled}
          style={inputDisabled ? {display: 'none'} : {}}
          placeholder={I18n.t(
            searchBoxPlaceholderKey
              ? searchBoxPlaceholderKey
              : 'renderField.searchBoxPlaceholder'
          )}
        />

        <GMap
          ref={ref => (this.map = ref)}
          overlays={this.state.overlays}
          options={options}
          style={{width: '100%', minHeight: `${mapMinHeight}px`}}
        />
      </div>
    );
  }
}

RenderGmap.propType = {
  formName: PropTypes.string.isRequired,
  mapMinHeight: PropTypes.number,
  inputDisabled: PropTypes.bool,
  streetViewControl: PropTypes.bool.isRequired,
};

RenderGmap.defaultProps = {
  mapMinHeight: 320,
  streetViewControl: true,
};

function mapStateToProps(state, componentProps) {
  return {
    formValues: getFormValues(componentProps.formName)(state),
    i18n: state.i18n,
  };
}

export default connect(mapStateToProps)(RenderGmap);
