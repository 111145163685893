/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {carService} from 'shared/services/car.service';
import {userService} from 'shared/services/user.service';
import {validate} from './car-form-validators';
import {CarForm} from './CarForm';

export const asyncValidate = values =>
  carService
    .carExistsForUser(values.name, userService.getUserId())
    .then(response => {
      if (response.data)
        return {name: {key: 'car.nameExistsForUserError', params: {}}};
    });

const formName = 'carCreateForm';

const CarCreateForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
})(CarForm);

const selector = formValueSelector(formName);

function mapStateToProps(state) {
  const {i18n, car} = state;
  const brandFieldValue = selector(state, 'brand');
  const modelFieldValue = selector(state, 'model');
  const fuelTypeFieldValue = selector(state, 'fuelType');
  const {carBrands, fuelTypes} = car;

  return {
    i18n,
    formName,
    brandFieldValue,
    modelFieldValue,
    carBrands,
    fuelTypes,
    fuelTypeFieldValue,
  };
}

const mapDispatchToProps = {
  handleCancel: () => ({type: updateDataSideBarConstants.HIDE}),
};

export default connect(mapStateToProps, mapDispatchToProps)(CarCreateForm);
