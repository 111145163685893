/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const languageConstants = {
  LANGUAGE_SYNC_LOGS_REQUEST_START: 'LANGUAGE_SYNC_LOGS_REQUEST_START',
  LANGUAGE_SYNC_LOGS_REQUEST_END: 'LANGUAGE_SYNC_LOGS_REQUEST_END',

  LANGUAGE_REQUEST_START: 'LANGUAGE_REQUEST_START',
  LANGUAGE_REQUEST_END: 'LANGUAGE_REQUEST_END',

  LANGUAGES_REQUEST_START: 'LANGUAGES_REQUEST_START',
  LANGUAGES_REQUEST_END: 'LANGUAGES_REQUEST_END',

  LOAD_LANGUAGE_SYNC_LOGS: 'LOAD_LANGUAGE_SYNC_LOGS',
  CLEAR_LANGUAGE_SYNC_LOGS: 'CLEAR_LANGUAGE_SYNC_LOGS',

  LOAD_LANGUAGES: 'LOAD_LANGUAGES',
  CLEAR_LANGUAGES: 'CLEAR_LANGUAGES',
  REFRESH_LANGUAGES: 'REFRESH_LANGUAGES',

  LOAD_LANGUAGE: 'LOAD_LANGUAGE',
  CLEAR_LANGUAGE: 'CLEAR_LANGUAGE',
};
