/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';
import CodeScannerForm from './code-scanner-form/CodeScannerForm';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import Popup from 'shared/components/popups/Popup';
import {userActions} from 'shared/actions/user.actions';
import CodeIssueForm from './code-issue-form/CodeIssueForm';
import {userConstants} from 'shared/constants/user.constants';
import {Button} from 'reactstrap';
import {capitalize} from 'shared/utils/utils';
import {byCountry} from 'country-code-lookup';
import {workshopConstants} from 'shared/constants/workshop.constants';
import {
  acAddress,
  acCity,
  acCompany,
  aCContact,
  aCEmail,
  aCStreet,
} from 'environment';
import {
  formatLandlinePhoneNumber,
  formatPhoneNumberField,
} from '../../../shared/components/form/formatter/PhoneNumberFormatter';

const CodeScannerBox = props => {
  const dispatch = useDispatch();
  const barcodeInfo = useSelector(state => state.user.infoBarcode);
  const sendReportStatus = useSelector(state => state.user.reportIssueSuccess);

  const createCode = values => {
    dispatch({type: updateDataSideBarConstants.HIDE});
    const {
      workshop,
      newWorkshop,
      address,
      city,
      postCode,
      country,
      state,
      phone,
      email,
      carMake,
      carModel,
      engineCapacity,
      productionYear,
      barcodeBox,
      barcodeController,
      barcodeInjector,
    } = values;

    const barcodes = [
      {barcodeValue: barcodeBox, barcodeType: 'BOX'},
      {barcodeValue: barcodeController, barcodeType: 'CONTROLLER'},
      {barcodeValue: barcodeInjector, barcodeType: 'INJECTOR'},
    ].filter(code => !!code.barcodeValue);

    let phoneNumber = null;
    if (phone && phone.phoneNumber) {
      let phoneNumberFormatted = formatPhoneNumberField(phone);
      phoneNumber = `${phoneNumberFormatted.phoneNumberPrefix} ${phoneNumberFormatted.phoneNumber}`;
    }

    const workshopData = {
      workshopName: workshop.workshopName ?? newWorkshop,
      workshopAddress: address,
      workshopCity: city,
      workshopZipcode: postCode,
      workshopCountry: byCountry(capitalize(country))?.iso2 ?? country,
      workshopState: state,
      workshopPhoneNumber: phoneNumber,
      workshopEmail: email && email !== '' ? email : null,
    };

    dispatch({
      type: workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_DATA,
      payload: {...workshopData},
    });

    dispatch(
      userActions.addNewBarcode({
        barcodes,
        ...workshopData,
        carBrand: carMake === '' ? null : carMake,
        carModel: carModel === '' ? null : carModel,
        carProductionYear:
          Number(productionYear) === 0 ? null : Number(productionYear),
        carEngineCapacityCm3:
          Number(engineCapacity) === 0 ? null : Number(engineCapacity),
      })
    );
  };

  const createIssue = values => {
    dispatch({type: updateDataSideBarConstants.HIDE});

    const {
      address,
      assemblyDate,
      city,
      country,
      description,
      email,
      lastname,
      name,
      postcode,
      state,
      workshop,
    } = values;

    const barcodes = [
      barcodeInfo?.BOX,
      barcodeInfo?.CONTROLLER,
      barcodeInfo?.INJECTOR,
    ]
      .filter(barcode => !!barcode.code)
      .map(barcode => ({
        barcodeValue: barcode.code,
        barcodeType: barcode.barcodeType,
      }));

    dispatch(
      userActions.sendBarcodeIssue({
        barcodes,
        workshopName: workshop,
        workshopZipcode: postcode,
        workshopCountry: country,
        workshopAddress: address,
        workshopState: state,
        workshopCity: city,
        email: email,
        firstName: name,
        lastName: lastname,
        assemblyDate: assemblyDate,
        description: description,
      })
    );
  };

  const cancelForm = () => {
    dispatch({type: updateDataSideBarConstants.HIDE});
  };

  const doNotSendIssue = () => {
    dispatch({type: userConstants.USER_BARCODE_INFO_CLEAR});
    dispatch({
      type: userConstants.USER_BARCODE_INFO_CLEAR,
    });
  };

  const openCreateNewBarcode = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: (
        <CodeScannerForm onSubmit={createCode} handleCancel={cancelForm} />
      ),
      title: I18n.t('barcodes.addBarCodeTitle'),
    });
  };

  const openIssueForm = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: (
        <CodeIssueForm
          onSubmit={createIssue}
          handleCancel={() => {
            cancelForm();
            doNotSendIssue();
          }}
        />
      ),
      title: I18n.t('barcodes.reportForm'),
    });
  };

  return (
    <>
      <div
        className='subsection-tile shadow col-8'
        onClick={openCreateNewBarcode}>
        <i className={'fa fa-barcode fa-3x'} />
        <Translate value={'barcodes.addNewBarcode'} />
      </div>
      {barcodeInfo && (
        <Popup variant='BARCODE_INFO' states={barcodeInfo}>
          {barcodeInfo?.showForm && (
            <>
              <p className='mb-1'>{acCompany}</p>
              <p className='mb-1'>{aCStreet}</p>
              <p className='mb-1'>{acCity}</p>
              <a style={{color: '#000'}} href={`tel:${aCContact}`}>
                tel.: {formatLandlinePhoneNumber(aCContact)}
              </a>
              <a style={{color: '#000'}} href={`mailto:${aCEmail}`}>
                {aCEmail}
              </a>
              <button className='btn btn-danger' onClick={openIssueForm}>
                <Translate value='barcodes.reportProblem' />
              </button>
              <Button className='main-btn' onClick={doNotSendIssue}>
                <Translate value='barcodes.cancel' />
              </Button>
            </>
          )}
        </Popup>
      )}
      {sendReportStatus && (
        <Popup variant='ACCEPT' message={I18n.t('barcodes.sendIssue')} />
      )}
    </>
  );
};

export default CodeScannerBox;
