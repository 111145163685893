/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {serviceActions} from 'shared/actions/service.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {validate} from './service-form-validators';
import {ServiceForm} from './ServiceForm';

const ServiceEditForm = reduxForm({
  form: 'serviceEditForm',
  validate,
  enableReinitialize: true,
})(ServiceForm);

function mapStateToProps(state) {
  const {i18n, service} = state;
  const categoryOptions = service.categories
    .filter(category => !category.locked)
    .map(category => ({label: category.name, value: category.id}));
  const initialValues = service.service || {};
  const selectedServiceId = service.service ? service.service.id : null;

  return {i18n, categoryOptions, initialValues, selectedServiceId};
}

const mapDispatchToProps = {
  handleCancel: () => ({type: updateDataSideBarConstants.HIDE}),
  handleDelete: serviceActions.deleteService,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEditForm);
