/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const userNotificationService = {
  findRecent,
  countUnread,
  readAll,
  deleteAll,
  deleteById,
};

function findRecent(userId) {
  return axiosService
    .get(`/notification/user-notifications?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function countUnread(userId) {
  return axiosService
    .get(`/notification/user-notifications/unread/count?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function readAll(userId) {
  return axiosService
    .put(`/notification/user-notifications?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function deleteAll(userId) {
  return axiosService
    .delete(`/notification/user-notifications?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function deleteById(userId, notificationId) {
  return axiosService
    .delete(
      `/notification/user-notifications/${notificationId}?userId=${userId}`
    )
    .then(response => {
      return response;
    });
}
