/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const partCategoryService = {
  findAllPartCategories,
};

function findAllPartCategories() {
  return axiosService.get('/workshop/part-categories').then(response => {
    return response;
  });
}
