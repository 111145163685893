/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Users from './users/Users';
import Parts from './parts/Parts';
import Statutes from './statutes/Statutes';
import Statistics from './statistics/Statistics';
import {Workshops} from './workshops/Workshops';
import DistributorProfile from './distributor-profile/DistributorProfile';
import {Services} from '../admin/services/Services';

export default class DistributorContainer extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/distributor/users' component={Users} />
          <Route exact path='/distributor/parts/:id' component={Parts} />
          <Route
            exact
            path='/distributor/profile'
            component={DistributorProfile}
          />
          <Route exact path='/distributor/workshops' component={Workshops} />
          <Route
            exact
            path='/distributor/services'
            render={() => <Services isEditable={false} />}
          />
          <Route exact path='/distributor/statutes' component={Statutes} />
          <Route exact path='/distributor/statistics' component={Statistics} />
          <Redirect to={'/distributor/users'} />
        </Switch>
      </div>
    );
  }
}
