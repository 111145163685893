/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field} from 'redux-form';
import RenderCheckbox from 'shared/components/form/checkbox/RenderCheckbox';
import RenderCurrency from 'shared/components/form/currency/RenderCurrency';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import renderField from 'shared/components/form/text-field/RenderField';
import {subcategory} from 'shared/utils/icons';
import {getGuaranteePeriods} from '../../../utils/utils';
import '../valuation-inquiry-response-form.css';

const partCategories = {
  DRIVERS: {key: 'DRIVERS', fieldName: 'lpgDriver'},
  BG_SWITCHES: {key: 'BG_SWITCHES', fieldName: 'lpgBgSwitch'},
  REDUCERS: {key: 'REDUCERS', fieldName: 'lpgReducer'},
  INJECTORS: {key: 'INJECTORS', fieldName: 'lpgInjectors'},
  TANKS: {key: 'TANKS', fieldName: 'lpgTank'},
  REFUELING_VALVES: {key: 'REFUELING_VALVES', fieldName: 'lpgRefuelingValve'},
};

export default class ValuationInquiryResponseFormSchemaPage2 extends React.Component {
  constructor(props) {
    super(props);
    this.isOtherPart = this.isOtherPart.bind(this);
  }

  componentDidMount() {
    this.props.setPageTitle('valuationInquiryResponse.valuateLpg');
  }

  getParts(partCategory) {
    const {partCategories} = this.props;
    const key = partCategory.key;
    const foundCategory = partCategories
      ? partCategories.find(partCategory => partCategory.key === key)
      : null;
    const parts = foundCategory ? foundCategory.parts : [];
    const partsOptions = parts
      ? parts.map(part => ({label: part.name, value: part.partId}))
      : [];
    partsOptions.push({label: I18n.t('parts.otherPart'), value: 'other'});
    return partsOptions;
  }

  isOtherPart(fieldName) {
    return (
      this.props.formValues && this.props.formValues[fieldName] === 'other'
    );
  }

  renderPartsCategorySelect(partCategory) {
    const fieldName = partCategory.fieldName;
    return (
      <Fragment key={partCategory.key + '_FIELDS'}>
        <Field
          name={fieldName}
          component={RenderDropdown}
          options={this.getParts(partCategory)}
          required={true}
          placeholder={I18n.t(
            'valuationInquiryResponse.' + fieldName + 'Placeholder'
          )}
          label={I18n.t('valuationInquiryResponse.' + fieldName + 'Label')}
          key={partCategory.key}
        />
        {this.isOtherPart(fieldName) && (
          <Field
            name={fieldName + 'Other'}
            component={renderField}
            prefixIcon={subcategory}
            label={I18n.t('services.nameLabel')}
            key={partCategory.key + '_OTHER'}
          />
        )}
      </Fragment>
    );
  }

  renderPreviousPageBtn() {
    const {previousPage} = this.props;

    return (
      <Button className='main-btn float-left' onClick={previousPage}>
        <Translate value='valuationInquiryResponse.previousPageBtn' />
      </Button>
    );
  }

  render() {
    const {
      previousPage,
      handleSubmit,
      handleCancel,
      invalid,
      handleCreateDraft,
      currency,
    } = this.props;
    return (
      <div className='pb-5'>
        <form
          className='valuation-inquiry-response-form'
          onSubmit={handleSubmit}>
          {Object.keys(partCategories).map(key =>
            this.renderPartsCategorySelect(partCategories[key])
          )}
          <Field
            name='lpgAdditionalInspection'
            component={RenderCheckbox}
            label={I18n.t(
              'valuationInquiryResponse.lpgAdditionalInspectionCheckbox'
            )}
          />
          <Field
            name='lpgGuaranteePeriodMonths'
            component={RenderDropdown}
            options={getGuaranteePeriods()}
            placeholder={I18n.t(
              'valuationInquiryResponse.guaranteePeriodMonthsPlaceholder'
            )}
            required={true}
            label={I18n.t(
              'valuationInquiryResponse.guaranteePeriodMonthsLabel'
            )}
          />
          <Field
            name='lpgTotalCost'
            component={RenderCurrency}
            required={true}
            suffix={currency}
            changeCurrencyInfo={true}
            label={I18n.t('valuationInquiryResponse.lpgTotalCostLabel')}
            icon='fa fa-money'
          />

          <div className='text-lg-right text-center mt-3'>
            {previousPage && this.renderPreviousPageBtn()}
            <Button className='main-btn' type='submit' disabled={invalid}>
              <Translate value='valuationInquiryResponse.nextPageBtn' />
            </Button>
          </div>

          <div className='text-lg-right text-center mt-3'>
            {handleCreateDraft && (
              <Button className='main-btn' onClick={handleCreateDraft}>
                <Translate value='valuationInquiryResponse.createDraftBtn' />
              </Button>
            )}
            <Button className='main-btn' onClick={handleCancel}>
              <Translate value='valuationInquiryResponse.cancelBtn' />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ValuationInquiryResponseFormSchemaPage2.propTypes = {
  previousPage: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};
