/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validate} from './service-form-validators';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {ServiceForm} from './ServiceForm';

const ServiceCreateForm = reduxForm({
  form: 'serviceCreateForm',
  validate,
  enableReinitialize: true,
})(ServiceForm);

function mapStateToProps(state) {
  const {i18n, service} = state;
  const categoryOptions = service.categories
    .filter(category => !category.locked)
    .map(category => ({label: category.name, value: category.id}));
  const initialValues = service.category
    ? {serviceCategoryId: service.category.id}
    : {};

  return {i18n, categoryOptions, initialValues};
}

export default connect(mapStateToProps)(ServiceCreateForm);
