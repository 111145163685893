/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {isEmptyArray, isUUIDFormat} from 'shared/utils/utils';
import {RenderDetailsCore} from '../RenderDetailsCore';

const partCategoryKeys = {
  lpgDriver: {key: 'DRIVERS'},
  lpgBgSwitch: {key: 'BG_SWITCHES'},
  lpgReducer: {key: 'REDUCERS'},
  lpgInjectors: {key: 'INJECTORS'},
  lpgTank: {key: 'TANKS'},
  lpgRefuelingValve: {key: 'REFUELING_VALVES'},
};

class RenderService extends RenderDetailsCore {
  constructor(props) {
    super(props);
    this.state = {toggle: false};
  }

  renderServiceDetails() {
    const {service, isValuation} = this.props;
    const guaranteeString = this.resolveGuaranteeValue(
      service.guaranteePeriodMonths
    );

    return (
      <div className='row valuation-inquiry-details-content px-3'>
        {service && service.serviceDescription && (
          <div className='col-12'>
            <Translate
              value={'valuationInquiryDetails.serviceDescriptionLabel'}
              className='mr-1 font-bold'
            />
            {service.serviceDescription}
          </div>
        )}
        <div className='col-12'>
          <Translate
            value={'valuationDetails.guaranteePeriodMonthsLabel'}
            className='mr-1 font-bold'
          />
          {guaranteeString}
        </div>
        <div className='col-12'>
          {isValuation?
          <Translate
            value={'valuationDetails.serviceEstimatedCostLabel'}
            className='mr-1 font-bold'
          /> :
          <Translate
            value={'valuationDetails.serviceCostLabel'}
            className='mr-1 font-bold'
          />}
          {`${service.serviceCost.value} ${service.serviceCost.currency}`}
        </div>
        {this.props.isLpgService && this.renderLpgParams(service.params)}
      </div>
    );
  }

  resolveGuaranteeValue(guaranteePeriodMonths) {
    return guaranteePeriodMonths === 0
      ? I18n.t('valuationDetails.noGuarantee')
      : `${guaranteePeriodMonths} ${I18n.t(
          'valuationDetails.monthShortLabel'
        )}`;
  }

  renderLpgParams(params) {
    const {categories} = this.props;
    const additionalInspection = params.find(
      param => param.id === 'lpgAdditionalInspection'
    ).value;
    const paramsWithoutAdditionalInspection = params.filter(
      param => param.id !== 'lpgAdditionalInspection'
    );
    if (!isEmptyArray(categories)) {
      paramsWithoutAdditionalInspection.forEach(param => {
        if (param.value === 'other') {
          param.name = 'other';
        } else if (!isUUIDFormat(param.value)) {
          param.name = param.value;
        } else {
          param.name = categories
            .find(cat => cat.key === partCategoryKeys[param.id].key)
            .parts.find(part => part.partId === param.value).name;
        }
      });
    }

    return (
      <Fragment>
        {!isEmptyArray(categories) ? (
          paramsWithoutAdditionalInspection.map(param =>
            this.renderLpgParam(param)
          )
        ) : (
          <Translate value={'valuationDetails.noPartsData'} />
        )}
        <div className='col-12'>
          <Translate
            value={'valuationDetails.lpgAdditionalInspectionLabel'}
            className='mr-1 font-bold'
          />
          <Translate value={`boolean.${additionalInspection}`} />
        </div>
      </Fragment>
    );
  }

  renderLpgParam(param) {
    return (
      <div className='col-12' key={param.id}>
        <Translate
          value={`valuationDetails.${param.id}Label`}
          className='mr-1 font-bold'
        />
        {param.name === 'other'
          ? I18n.t('valuationDetails.otherPartLabel')
          : param.name}
      </div>
    );
  }

  render() {
    const {service} = this.props;
    return (
      <div className='details-border mt-1'>
        {super.renderHeader(service.serviceName, true, service.categoryName)}
        <Collapse isOpen={this.state.collapse}>
          {this.renderServiceDetails()}
        </Collapse>
      </div>
    );
  }
}

RenderService.propTypes = {
  service: PropTypes.object.isRequired,
  isLpgService: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {i18n, part} = state;
  const {categories} = part;

  return {i18n, categories};
};

export default connect(mapStateToProps)(RenderService);
