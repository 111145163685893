/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {RatingForm} from './RatingForm';
import {validate} from './rating-form-validators';

const RatingCreateForm = reduxForm({
  form: 'ratingCreateForm',
  validate,
  enableReinitialize: true,
})(RatingForm);

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

export default connect(mapStateToProps)(RatingCreateForm);
