/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Field} from 'redux-form';
import renderField from '../../form/text-field/RenderField';
import {Button} from 'reactstrap';
import {ConfirmModal} from '../../modal/ConfirmModal';
import {RenderTextArea} from '../../form/text-area/RenderTextArea';
import RenderDropdown from '../../form/dropdown/RenderDropdown';
import RenderCurrency from '../../form/currency/RenderCurrency';
import CountryField from '../../form/country-field/CountryField';

const periods = ['MONTHS', 'YEARS'];
const currencies = ['PLN', 'EUR', 'USD', 'RUB'];

export class SubscriptionPlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteModalOpen: false,
      confirmActivateModalOpen: false,
    };
  }

  renderFields() {
    const {disableMainFields} = this.props;
    const periodsOptions = periods.map(period => ({
      label: I18n.t(`subscriptionPlan.period${period}`),
      value: period,
    }));
    const currenciesOptions = currencies.map(currency => ({
      label: currency,
      value: currency,
    }));

    return (
      <Fragment>
        <Field
          name='label'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('subscriptionPlan.label')}
          icon='fa fa-star'
          inputDisabled={disableMainFields}
        />

        <Field
          name='period'
          component={RenderDropdown}
          required={true}
          label={I18n.t('subscriptionPlan.period')}
          icon='fa fa-calendar'
          options={periodsOptions}
          disabled={disableMainFields}
        />

        <Field
          name='price.currency'
          component={RenderDropdown}
          required={true}
          label={I18n.t('distributors.currencyLabel')}
          icon='fa fa-globe'
          options={currenciesOptions}
          disabled={disableMainFields}
        />

        <Field
          name='price.value'
          component={RenderCurrency}
          required={true}
          label={I18n.t('subscriptionPlan.price')}
          icon='fa fa-money'
          disabled={disableMainFields}
        />

        <CountryField disabled={disableMainFields} />

        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          label={I18n.t('subscriptionPlan.description')}
          inputDisabled={disableMainFields}
        />
      </Fragment>
    );
  }

  renderButtons() {
    const {disableMainFields, invalid, handleCancel} = this.props;

    return (
      <Fragment>
        {!disableMainFields && (
          <Button
            id='subscription-plan-form-submit-btn'
            className='main-btn'
            type='submit'
            disabled={invalid}>
            <Translate value={'subscriptionPlan.saveBtn'} />
          </Button>
        )}

        {!disableMainFields && this.renderActivateSection()}

        <Button
          id='subscription-plan-form-cancel-btn'
          className='main-btn'
          onClick={handleCancel}>
          <Translate value={'subscriptionPlan.cancelBtn'} />
        </Button>

        {this.renderDeleteSection()}
      </Fragment>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete()}
        bodyText='subscriptionPlan.confirmDeleteText'
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      this.props.handleDelete && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmDeleteModal()}
          <Button
            id='subscription-plan-form-delete-btn'
            className='btn-danger'
            onClick={() => this.setState({confirmDeleteModalOpen: true})}>
            <Translate value={'subscriptionPlan.deleteBtn'} />
          </Button>
        </div>
      )
    );
  }

  renderConfirmActivateModal() {
    return (
      <ConfirmModal
        title='confirmModal.activate'
        isOpen={this.state.confirmActivateModalOpen}
        action={() => this.props.handleActivate()}
        bodyText='subscriptionPlan.confirmActivateText'
        handleCancel={() => this.setState({confirmActivateModalOpen: false})}
      />
    );
  }

  renderActivateSection() {
    return (
      this.props.handleActivate && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmActivateModal()}
          <Button
            id='subscription-plan-form-activate-btn'
            className='main-btn'
            onClick={() => this.setState({confirmActivateModalOpen: true})}>
            <Translate value={'subscriptionPlan.activateBtn'} />
          </Button>
        </div>
      )
    );
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className='subscription-plan-form p-5'>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          <div className='text-sm-right text-center mt-2'>
            {this.renderButtons()}
          </div>
        </form>
      </div>
    );
  }
}

SubscriptionPlanForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  handleActivate: PropTypes.func,
  invalid: PropTypes.bool,
  disableMainFields: PropTypes.bool,
};
