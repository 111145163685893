/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import {Button, Input} from 'reactstrap';
import {partActions} from 'shared/actions/part.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {sortByCustomField} from 'shared/utils/sort-utils/sort.utils';
import {becameObsolete} from 'shared/utils/sync';
import {
  getOrEmptyArray,
  getOrEmptyObj,
  indexArray,
  rowsQuantityToDisplay,
} from 'shared/utils/utils';
import {NeededPartsInquiryStatusEnum} from '../../workshop/utils/utils';
import PartCreateForm from './part-form/PartCreateForm';
import './parts.css';

class NeededParts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: '',
      rows: 10,
      dataTableKey: 0,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.acceptNeededPartsInquiry = this.acceptNeededPartsInquiry.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.resolveWorkshopCountry = this.resolveWorkshopCountry.bind(this);
  }

  componentWillMount() {
    this.props.findAllWorkshops();
    this.props.findAllNeededPartsInquiries();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.neededPartsDataSyncState,
        this.props.neededPartsDataSyncState
      )
    )
      this.props.findAllNeededPartsInquiries();
  }

  onChangeHandler(event) {
    this.setState({rows: event.value});
    this.setState({dataTableKey: Math.random()});
  }

  onClickEditNeededPartBtn(rowData) {
    const form = (
      <PartCreateForm
        onSubmit={e => this.acceptNeededPartsInquiry(e, rowData)}
        categoryId={rowData.partCategoryId}
        partName={rowData.partName}
        partDescription={rowData.partDescription}
        headerTitle={'parts.partEditHeader'}
        isNeededPart={true}
        neededPartsInquiryId={rowData.id}
        countries={rowData.countries}
      />
    );
    this.props.showUpdateDataSideBar(form);
  }

  acceptNeededPartsInquiry(values, rowData) {
    this.props.createPart(values);
    this.props.changeNeededPartsInquiryStatus(
      rowData.id,
      NeededPartsInquiryStatusEnum.ACCEPTED
    );
  }

  declineNeededPartsInquiry(neededPartsInquiryId) {
    this.props.changeNeededPartsInquiryStatus(
      neededPartsInquiryId,
      NeededPartsInquiryStatusEnum.DECLINED
    );
  }

  handleStatusChange(rowData, newStatus) {
    if (newStatus === NeededPartsInquiryStatusEnum.ACCEPTED) {
      const values = {
        partCategoryId: rowData.partCategoryId,
        name: rowData.partName,
        description: rowData.partDescription,
        countries: rowData.countries,
      };
      this.acceptNeededPartsInquiry(values, rowData);
    } else {
      this.declineNeededPartsInquiry(rowData.id);
    }
  }

  renderRowsDropdown() {
    return (
      <div className='col-sm-1 col-1 px-1 mr-auto order-1'>
        <Dropdown
          options={rowsQuantityToDisplay}
          value={this.state.rows}
          style={{height: '100%'}}
          onChange={e => this.setState({rows: e.value})}
        />
      </div>
    );
  }

  resolvePartCategory(categoryId) {
    const {part} = this.props;
    const partCategories = part.categories;
    if (partCategories) {
      const currentCategory = partCategories.filter(
        category => category.id === categoryId
      );
      const currentCategoryName = currentCategory[0].name;
      return currentCategoryName;
    } else {
      return '';
    }
  }

  resolveWorkshopById(workshopId) {
    const {workshops} = this.props;
    if (workshops) {
      const currentWorkshop = workshops.filter(
        workshop => workshop.workshopId === workshopId
      );
      return (
        currentWorkshop[0].workshopName +
        ' (' +
        currentWorkshop[0].address.city +
        ' ' +
        currentWorkshop[0].address.postalCode +
        ', ' +
        currentWorkshop[0].address.streetAddress +
        ')'
      );
    } else {
      return '';
    }
  }

  resolveWorkshopCountry(workshopId) {
    const {workshops} = this.props;
    const workshop = getOrEmptyObj(
      getOrEmptyArray(workshops).filter(
        workshop => workshop.workshopId === workshopId
      )[0]
    );
    return workshop.country;
  }

  translateNeededPartStatus(status) {
    switch (status) {
      case NeededPartsInquiryStatusEnum.PENDING:
        return I18n.t('neededPartsInquiry.PENDING');
      case NeededPartsInquiryStatusEnum.ACCEPTED:
        return I18n.t('neededPartsInquiry.ACCEPTED');
      case NeededPartsInquiryStatusEnum.DECLINED:
        return I18n.t('neededPartsInquiry.DECLINED');
      default:
        return '';
    }
  }

  onRowsChange(e) {
    this.setState({rows: e.value});
    this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  renderStatusChange(rowData) {
    let statusOptions = [
      {
        label: I18n.t('neededPartsInquiry.chooseAccept'),
        value: NeededPartsInquiryStatusEnum.ACCEPTED,
      },
      {
        label: I18n.t('neededPartsInquiry.chooseDecline'),
        value: NeededPartsInquiryStatusEnum.DECLINED,
      },
    ];
    if (rowData.rawStatus === NeededPartsInquiryStatusEnum.PENDING) {
      return (
        <Dropdown
          options={statusOptions}
          value={statusOptions.value}
          placeholder={this.translateNeededPartStatus(rowData.rawStatus)}
          onChange={e => this.handleStatusChange(rowData, e.value)}
        />
      );
    } else {
      return this.translateNeededPartStatus(rowData.rawStatus);
    }
  }

  renderEditButton(rowData) {
    if (rowData.rawStatus === NeededPartsInquiryStatusEnum.PENDING) {
      return (
        <div>
          <Button
            className='main-btn needed-parts-edit-btn'
            onClick={e => this.onClickEditNeededPartBtn(rowData)}>
            <Translate value={'parts.editBtn'} />
          </Button>
        </div>
      );
    } else {
      return '';
    }
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        className='data-table-rows-dropdown'
        value={this.state.rows}
        onChange={e => this.onRowsChange(e)}
      />
    );
  }

  render() {
    const {neededParts} = this.props;
    const header = (
      <div className='row'>
        <div className='col-sm-1 col-1 px-1 mr-auto order-1' />
        <div className='col-sm-4 col-6 px-1 order-3'>
          <Input
            type='search'
            placeholder={I18n.t('users.searchPlaceholder')}
            onInput={e => this.setState({globalFilter: e.target.value})}
          />
        </div>
      </div>
    );

    const neededPartsList = neededParts
      ? neededParts.map(neededPart => ({
          id: neededPart.id,
          partCategoryId: neededPart.partCategoryId,
          partCategory: this.resolvePartCategory(neededPart.partCategoryId),
          partName: neededPart.partName,
          partDescription: neededPart.partDescription,
          workshop: this.resolveWorkshopById(neededPart.workshopId),
          status: this.translateNeededPartStatus(neededPart.status),
          rawStatus: neededPart.status,
          countries: [this.resolveWorkshopCountry(neededPart.workshopId)],
        }))
      : [];

    return (
      <ContentWrapper title='sideBarNav.neededParts'>
        <div className='row'>
          <Loadable active={this.props.isFetching} spinner>
            <ReactTooltip
              id='workshopName'
              className='form-tooltip'
              effect='solid'
              globalEventOff='click'
              place='top'
            />
            <div className='col'>
              <DataTable
                value={indexArray(neededPartsList)}
                paginator={true}
                rows={this.state.rows}
                header={header}
                className='data-table-padding-class'
                responsive={true}
                sortField='status'
                sortOrder={1}
                emptyMessage={I18n.t(
                  'neededPartsInquiry.emptyRequestedPartsPlaceholder'
                )}
                globalFilter={this.state.globalFilter}
                ref={this.setDatatableRef}
                paginatorLeft={this.renderRowsDropdown()}>
                <Column
                  field='index'
                  className='lp-col'
                  header={I18n.t('neededPartsInquiry.lp')}
                  sortable={true}
                />
                <Column
                  field='partCategory'
                  header={I18n.t('neededPartsInquiry.partCategory')}
                  sortable={true}
                />
                <Column
                  field='partName'
                  header={I18n.t('neededPartsInquiry.partName')}
                  sortable={true}
                />
                <Column
                  field='partDescription'
                  header={I18n.t('neededPartsInquiry.partDescription')}
                  sortable={true}
                />
                <Column
                  field='workshop'
                  header={I18n.t('neededPartsInquiry.workshop')}
                  sortable={true}
                />
                <Column
                  field='statusChange'
                  className='needed-parts-custom-column'
                  body={this.renderStatusChange.bind(this)}
                  header={I18n.t('neededPartsInquiry.status')}
                  sortable='custom'
                  sortFunction={e =>
                    sortByCustomField(e, neededPartsList, 'status')
                  }
                />
                <Column
                  field='editNeededPartsInquiry'
                  className='needed-parts-custom-column'
                  body={this.renderEditButton.bind(this)}
                />
              </DataTable>
            </div>
          </Loadable>
        </div>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, workshop, part} = state;
  const {neededParts, workshops, neededPartsDataSyncState} = workshop;

  return {i18n, neededParts, part, workshops, neededPartsDataSyncState};
}

const mapDispatchToProps = {
  findAllNeededPartsInquiries: workshopActions.findAllNeededPartsInquiries,
  findAllWorkshops: workshopActions.findAllWorkshops,
  changeNeededPartsInquiryStatus:
    workshopActions.changeNeededPartsInquiryStatus,
  createPart: partActions.createPart,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(NeededParts);
