/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import React, {Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {privilegeConstants} from 'shared/constants/privilege.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import 'shared/styles/datatables.css';
import {becameObsolete} from 'shared/utils/sync';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import CreateWorkshopForm from './create-workshop/CreateWorkshopForm';
import SelectedWorkshop from './selected-workshop/SelectedWorkshop';
import {WorkshopStatusEnum} from './utils/utils';
import './workshops.css';

class Workshops extends React.Component {
  constructor() {
    super();
    this.state = {
      globalFilter: '',
      rows: 10,
      addSectionDisplay: false,
      workshopStatus: WorkshopStatusEnum.ACTIVE,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onClickAddWorkshopButton = this.onClickAddWorkshopButton.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onInputHandler = this.onInputHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.renderStatusCell = this.renderStatusCell.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.closeAddSection = this.closeAddSection.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      workshopActions.findAllWorkshopsByStatus(this.state.workshopStatus)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.workshopStatusChanged(prevState) ||
      becameObsolete(
        prevProps.workshopDataSyncState,
        this.props.workshopDataSyncState
      )
    ) {
      this.props.dispatch(
        workshopActions.findAllWorkshopsByStatus(this.state.workshopStatus)
      );

      if (this.dataTable)
        this.dataTable.onPageChange({first: 0, rows: this.state.rows});
    }
  }

  workshopStatusChanged(prevState) {
    return prevState.workshopStatus !== this.state.workshopStatus;
  }

  onChangeHandler(event) {
    this.setState({rows: event.value});

    if (this.dataTable)
      this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  onInputHandler(event) {
    this.setState({globalFilter: event.target.value});
  }

  onRowClick(event) {
    const {dispatch} = this.props;
    const fetchDetailsAction = this.isDeleteStatusActive()
      ? workshopActions.findDeletedWorkshopById(event.data.workshopId)
      : workshopActions.findWorkshopByUserId(event.data.userId);
    const details = (
      <SelectedWorkshop
        isReadOnly={
          this.isDeleteStatusActive() || this.isDistributorWithoutPriviliges()
        }
      />
    );

    dispatch(fetchDetailsAction);
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: details,
      title: event.data.email,
    });
  }

  isDistributorWithoutPriviliges() {
    return (
      userService.isDistributor() &&
      !userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT)
    );
  }

  onClickAddWorkshopButton() {
    this.setState({addSectionDisplay: !this.state.addSectionDisplay});
  }

  renderAddSection() {
    if (this.state.addSectionDisplay)
      return (
        <ContentWrapper title='workshops.addBtn'>
          <CreateWorkshopForm
            onSubmit={this.onSubmitHandler}
            handleCancel={this.handleCancel}
          />
        </ContentWrapper>
      );
  }

  onSubmitHandler(values) {
    this.props.dispatch(
      workshopActions.createWorkshop({...values}, this.closeAddSection)
    );
  }

  closeAddSection() {
    this.setState({addSectionDisplay: !this.state.addSectionDisplay});
  }

  renderStatusCell(data) {
    return <Translate value={`workshops.${data.status.toLowerCase()}`} />;
  }

  handleCancel() {
    this.setState({addSectionDisplay: false});
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        <div
          id='workshops-active-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
                 ${
                   this.state.workshopStatus === WorkshopStatusEnum.ACTIVE &&
                   'subsection-tile-active'
                 } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({workshopStatus: WorkshopStatusEnum.ACTIVE})
          }>
          <i className='fa fa-3x fa-check-circle' />
          <Translate value='workshops.activeTile' />
        </div>
        <div
          id='workshops-inactive-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
                 ${
                   this.state.workshopStatus === WorkshopStatusEnum.INACTIVE &&
                   'subsection-tile-active'
                 } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({workshopStatus: WorkshopStatusEnum.INACTIVE})
          }>
          <i className='fa fa-3x fa-question-circle' />
          <Translate value='workshops.inActiveTile' />
        </div>
        <div
          id='workshops-blocked-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
                 ${
                   this.state.workshopStatus === WorkshopStatusEnum.BLOCKED &&
                   'subsection-tile-active'
                 } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({workshopStatus: WorkshopStatusEnum.BLOCKED})
          }>
          <i className='fa fa-3x fa-minus-circle' />
          <Translate value='workshops.blockedTile' />
        </div>
        <div
          id='workshops-deleted-tile'
          className={`subsection-tile shadow col-sm col-12
                 ${
                   this.isDeleteStatusActive() && 'subsection-tile-active'
                 } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({workshopStatus: WorkshopStatusEnum.DELETED})
          }>
          <i className='fa fa-3x fa-times-circle' />
          <Translate value='workshops.deletedTile' />
        </div>
      </div>
    );
  }

  renderAddBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={this.onClickAddWorkshopButton}>
        <Translate value='workshops.addBtn' />
      </Button>
    );
  }

  renderLastColumn() {
    const status = (
      <Column
        field='status'
        header={I18n.t('workshops.statusHeader')}
        sortable={true}
        body={this.renderStatusCell}
      />
    );
    const id = (
      <Column
        field='workshopId'
        header={I18n.t('workshops.workshopId')}
        sortable={true}
      />
    );
    return this.isDeleteStatusActive() ? id : status;
  }

  isDeleteStatusActive() {
    return this.state.workshopStatus === WorkshopStatusEnum.DELETED;
  }

  shouldRenderAddBtn() {
    const hasCorrectRoleAndPrivilege =
      userService.isAdmin() ||
      (userService.isDistributor() &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT));
    return (
      this.state.workshopStatus === WorkshopStatusEnum.ACTIVE &&
      hasCorrectRoleAndPrivilege
    );
  }

  renderCountryColumn(data) {
    return (
      <div>
        <Translate value={`country.${data.address.country.toLowerCase()}`} />
      </div>
    );
  }

  renderWorkshopTypeCell(data) {
    return <Translate value={`workshops.${data.type.toLowerCase()}`} />;
  }

  getSectionLabel() {
    switch (this.state.workshopStatus) {
      case WorkshopStatusEnum.ACTIVE:
        return 'workshops.activeTile';
        break;
      case WorkshopStatusEnum.INACTIVE:
        return 'workshops.inActiveTile';
        break;
      case WorkshopStatusEnum.BLOCKED:
        return 'workshops.blockedTile';
        break;
      case WorkshopStatusEnum.DELETED:
        return 'workshops.deletedTile';
        break;
    }
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        onChange={this.onChangeHandler}
      />
    );
  }

  render() {
    const header = (
      <div className='row'>
        <div className='col-sm-1 col-1 mr-auto order-1' />
        <div
          className='col-sm-4 col-6 px-1 order-3 order-last'
          style={{minWidth: '150px'}}>
          <Input
            type='search'
            placeholder={I18n.t('workshops.searchPlaceholder')}
            onInput={this.onInputHandler}
            className='global-filter m-1'
          />
        </div>
      </div>
    );
    return (
      <div>
        {this.renderAddSection()}
        {!this.state.addSectionDisplay && (
          <Fragment>
            {this.renderTiles()}
            <ContentWrapper title={this.getSectionLabel()}>
              <Loadable active={this.props.isFetching} spinner>
                <div className='col'>
                  <DataTable
                    value={this.props.workshops}
                    paginator={true}
                    rows={this.state.rows}
                    header={header}
                    className='data-table-padding-class'
                    responsive={true}
                    ref={this.setDatatableRef}
                    globalFilter={this.state.globalFilter}
                    paginatorLeft={this.renderRowsDropdown()}
                    onRowClick={this.onRowClick}
                    emptyMessage={I18n.t('workshops.emptyPlaceholder')}
                    paginatorRight={
                      this.shouldRenderAddBtn() ? this.renderAddBtn() : null
                    }>
                    <Column
                      field='index'
                      className='lp-col'
                      header={I18n.t('workshops.lpHeader')}
                      sortable={true}
                    />
                    <Column
                      field='country'
                      header={I18n.t('workshops.countryHeader')}
                      sortable={true}
                      body={this.renderCountryColumn}
                    />
                    <Column
                      field='email'
                      className='email-col'
                      header={I18n.t('workshops.emailHeader')}
                      sortable={true}
                    />
                    <Column
                      field='workshopName'
                      header={I18n.t('workshops.workshopNameHeader')}
                      sortable={true}
                    />
                    <Column
                      field='type'
                      header={I18n.t('workshops.workshopTypeHeader')}
                      sortable={true}
                      body={this.renderWorkshopTypeCell}
                    />
                    {this.renderLastColumn()}
                  </DataTable>
                </div>
              </Loadable>
            </ContentWrapper>
          </Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, workshop} = state;
  const {workshops, workshopDataSyncState, isFetching} = workshop;

  return {i18n, isFetching, workshops, workshopDataSyncState};
}
const connectedWorkshops = connect(mapStateToProps)(Workshops);

export {connectedWorkshops as Workshops};
