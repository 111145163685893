import React, { useEffect, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import "./Jodit.css";
import { useDispatch, useSelector } from 'react-redux';
import Loadable from 'react-loading-overlay';
import { settingsActions } from 'shared/actions/settings.actions';
import { DATE_SHORT_FORMAT, dateUtils } from "../../../../shared/utils/date.utils";
import { Button } from "reactstrap";
import JoditEditor from 'jodit-react';
import {workshopLoyaltyProgramActions} from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import {stringUtils} from "../../../../shared/utils/string.utils";

export const WorkshopLoyaltyProgramEditForm = () => {
  const [editorState, setEditorState] = useState("");
  const dispatch = useDispatch();
  const content = useSelector(state => state.settings.content);
  const isFetching = useSelector(state => state.settings.isFetching);
  const registartionFetching = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);
  const workshopLoyaltyProgramPointsData = useSelector(state => state.workshopLoyaltyProgram.pointsData);
  const workshopLoyaltyProgramData = useSelector(state => state.workshopLoyaltyProgram.registrationData);

  useEffect(() => {
    dispatch(settingsActions.getWorkshopLoyaltyProgramEditorAsWorkshop());
  }, [])

  useEffect(() => {
    content && setEditorState(JSON.parse(content))
  }, [content])

  const getDataFromKey = (key, data) => {
    if (data[key] || data[key] === 0) {
      if (key === 'lastPointsProcessDate') {
        return dateUtils.formatDate(data[key], DATE_SHORT_FORMAT);
      }
      else return data[key];
    }
    return `${I18n.t('userProfileData.noData')}`;
  }

  const config = {
    readonly: true,
    toolbar: false,
    statusbar: false
  }

  const renderDetailsRow = (key, data) => {
    return (
      <div className='row user-data-container' key={key.field}>
        <div className='col-md-6 col-12 text-md-right user-data-label'>
          <Translate value={`workshopLoyaltyProgram.${key.translation}`} />:
        </div>
        <div className='col-md-6 col-12 mb-2 pl-md-0'>
          {getDataFromKey(key.field, data)}
        </div>
      </div>
    );
  }

  const renderPointsData = () => {
    const pointsDataToDisplay = [
      {
        field: 'totalPoints',
        translation: 'yourPoints',
      },
      {
        field: 'lastPointsProcessDate',
        translation: 'pointsByDate',
      },
    ];

    const installationPoints =
      !!workshopLoyaltyProgramPointsData &&
      (workshopLoyaltyProgramPointsData.lastInstallationPoints || workshopLoyaltyProgramPointsData.lastInstallationPoints === 0)
        ? workshopLoyaltyProgramPointsData.lastInstallationPoints
        : 0;

    return (
      <div>
        {!!workshopLoyaltyProgramPointsData && (
          <div className='points-section'>
            <div className={'points-container'}>
              <div className='workshop-details-section-title'>
                <Translate value='workshopLoyaltyProgram.yourPoints' />
              </div>
              {pointsDataToDisplay.map(key =>
                renderDetailsRow(key, workshopLoyaltyProgramPointsData)
              )}
              <div className={'user-data-container text-md-center'}>
                <Translate value='workshopLoyaltyProgram.lastInstallationPoints.message1' />
                {` ${installationPoints} `}
                <Translate value={`workshopLoyaltyProgram.lastInstallationPoints.${stringUtils.getPointsString(installationPoints)}`} />
                {` `}
                <Translate value='workshopLoyaltyProgram.lastInstallationPoints.message2' />
              </div>
              {workshopLoyaltyProgramPointsData?.pointsSpentEndDate &&
              <div className={'user-data-container text-md-center'} style={{marginTop: 8}}>
                <Translate value='workshopLoyaltyProgram.canUsePointsUntil' />
                {` ${workshopLoyaltyProgramPointsData?.pointsSpentEndDate} `}
              </div>}
            </div>
            <div className='workshop-profile-buttons'>
              <Button id='change-points-for-products' className='main-btn' style={{whiteSpace: 'normal'}} onClick={() => {
                dispatch(workshopLoyaltyProgramActions.setActiveWorkshopLoyaltyProgramTile('ProductsForPoints'));
              }}>
                <div style={{overflowWrap: 'break-word', maxWidth: '100%'}}>
                  {I18n.t('workshopLoyaltyProgram.changePointsForProducts')}
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <ContentWrapper title='workshopLoyaltyProgram.workshopLoyaltyProgram'>
      <Loadable active={isFetching || registartionFetching} spinner>
        <div style={{ padding: '1rem' }}>
          {workshopLoyaltyProgramData && renderPointsData()}
          {content ?
            <JoditEditor
              config={config}
              value={editorState}
              tabIndex={1}
            /> :
            <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>
              <b><Translate value={"workshopLoyaltyProgram.editorEmptyPlaceholder"} /></b>
            </div>
          }
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
