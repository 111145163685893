/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Redirect, Route, Switch} from 'react-router-dom';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import 'shared/styles/subsection-tiles.css';
import {history} from 'shared/utils/history';
import {WorkshopStatusEnum} from '../../admin/workshops/utils/utils';
import PreparedValuations from './prepared-valuations/PreparedValuations';
import ValuationInquiriesNoResponse from './valuation-inquiries-no-response/ValuationInquiriesNoResponse';
import {ValuationInquiryResponse} from './valuation-inquiry-response/ValuationInquiryResponse';
import {WaitingValuations} from './waiting-valuations/WaitingValuations';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {workshopWorkerActions} from 'shared/actions/workshop-worker.actions';

export const ValuationsSubsection = {
  WAITING_VALUATIONS: {
    key: 'WAITING_VALUATIONS',
    divId: 'waiting-valuations-tile',
    translateValue: 'valuations.waitingValuationsBtn',
  },
  NO_RESPONSE_VALUATIONS: {
    key: 'NO_RESPONSE_VALUATIONS',
    divId: 'no-response-valuations-tile',
    translateValue: 'valuations.noResponseValuationsBtn',
  },
  PREPARED_VALUATIONS: {
    key: 'PREPARED_VALUATIONS',
    divId: 'prepared-valuations-tile',
    translateValue: 'valuations.preparedValuationsBtn',
  },
};

class Valuations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valuationsSubsectionKeyActive:
        ValuationsSubsection.WAITING_VALUATIONS.key,
      isFetching: true,
      isAddWorkshopWorkerModalOpen: false,
    };

    this.isActive = this.isActive.bind(this);
    this.setActive = this.setActive.bind(this);
    this.prepareValuation = this.prepareValuation.bind(this);
    this.redirectAndSetActiveSubsection =
      this.redirectAndSetActiveSubsection.bind(this);
    this.handleOnTileClick = this.handleOnTileClick.bind(this);
    this.redirectToAddWorkshopWorkers =
      this.redirectToAddWorkshopWorkers.bind(this);
  }

  componentDidMount() {
    this.props.findAllServiceCategories();
    this.props.findLpgServiceCategoryId();
    this.props.findAllPartCategories();
    setTimeout(() => {
      this.setState({isFetching: false});
      this.props.findAllWorkersByWorkshopId(this.props.loggedWorkshopId);
    }, 500);
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(ValuationsSubsection.WAITING_VALUATIONS, 'fa-clock-o')}
        {this.renderTile(
          ValuationsSubsection.NO_RESPONSE_VALUATIONS,
          'fa-question-circle'
        )}
        {this.renderTile(
          ValuationsSubsection.PREPARED_VALUATIONS,
          'fa-paste',
          false
        )}
      </div>
    );
  }

  renderTile(valuationsSubsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        key={valuationsSubsection.divId}
        id={valuationsSubsection.divId}
        className={
          commonClasses + spacingClasses + this.isActive(valuationsSubsection)
        }
        onClick={this.handleOnTileClick(valuationsSubsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={valuationsSubsection.translateValue} />
      </div>
    );
  }

  handleOnTileClick(valuationSubsection) {
    return () => {
      this.setActive(valuationSubsection);
      if (!this.props.match.isExact) history.push(this.props.match.url);
    };
  }

  isActive(valuationsSubsection) {
    return this.state.valuationsSubsectionKeyActive === valuationsSubsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(valuationsSubsection) {
    this.setState({valuationsSubsectionKeyActive: valuationsSubsection.key});
  }

  prepareValuation(valuationInquiry) {
    if (!this.props.workshopWorkers || this.props.workshopWorkers.length === 0)
      return () => this.setState({isAddWorkshopWorkerModalOpen: true});

    return () => {
      history.push(
        `${this.props.match.url}/inquiry/${valuationInquiry.inquiryNumber.origin}/${valuationInquiry.inquiryNumber.number}`
      );
      this.props.hideUpdateDataSideBar();
    };
  }

  redirectToAddWorkshopWorkers() {
    history.push(`/workshop/workers`);
    this.props.hideUpdateDataSideBar();
  }

  redirectAndSetActiveSubsection(valuationSubsection) {
    return () => {
      history.push(this.props.match.url);
      this.setActive(valuationSubsection);
    };
  }

  isWorkshopActive() {
    return this.props.workshopStatus === WorkshopStatusEnum.ACTIVE;
  }

  renderSubsection() {
    switch (this.state.valuationsSubsectionKeyActive) {
      case ValuationsSubsection.WAITING_VALUATIONS.key:
        return (
          <WaitingValuations
            prepareValuation={this.prepareValuation}
            shouldShowWorkshopStatusAlert={!this.isWorkshopActive()}
            isFetching={this.state.isFetching || this.props.isFetching}
          />
        );
      case ValuationsSubsection.NO_RESPONSE_VALUATIONS.key:
        return (
          <ValuationInquiriesNoResponse
            isFetching={this.state.isFetching || this.props.isFetching}
          />
        );
      case ValuationsSubsection.PREPARED_VALUATIONS.key:
        return (
          <PreparedValuations
            shouldShowWorkshopStatusAlert={!this.isWorkshopActive()}
            isFetching={this.state.isFetching || this.props.isFetching}
          />
        );
    }
  }

  render() {
    return (
      <div>
        <ConfirmModal
          title='confirmModal.addWorkshopWorker'
          isOpen={this.state.isAddWorkshopWorkerModalOpen}
          action={this.redirectToAddWorkshopWorkers}
          handleCancel={() =>
            this.setState({isAddWorkshopWorkerModalOpen: false})
          }
        />
        {this.renderTiles()}
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/inquiry/:origin/:inquiryNumber`}
            render={props => (
              <ValuationInquiryResponse
                {...props}
                onCreateSuccess={this.redirectAndSetActiveSubsection}
                shouldShowWorkshopStatusAlert={!this.isWorkshopActive()}
              />
            )}
          />
          <Route
            exact
            path={this.props.match.url}
            render={() => this.renderSubsection()}
          />
          <Redirect
            to={{
              pathname: '/not-found',
              state: {from: this.props.location},
            }}
          />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, order, workshop, workshopWorker} = state;
  const {isFetching} = order;
  const workshopStatus = workshop.data ? workshop.data.status : null;
  const loggedWorkshopId = workshop.data ? workshop.data.workshopId : null;
  const {workshopWorkers} = workshopWorker;

  return {i18n, isFetching, workshopStatus, workshopWorkers, loggedWorkshopId};
}

const mapDispatchToProps = {
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  findLpgServiceCategoryId: serviceCategoryActions.findLpgServiceCategoryId,
  findAllPartCategories: partCategoryActions.findAllPartCategories,
  findAllWorkersByWorkshopId: workshopWorkerActions.findAllWorkersByWorkshopId,
};

const connectedValuations = connect(
  mapStateToProps,
  mapDispatchToProps
)(Valuations);
export {connectedValuations as Valuations};
