/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {distributorActions} from 'shared/actions/distributor.actions';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {distributorConstants} from 'shared/constants/distributor.constants';
import EditDistributorForm from '../form/EditDistributorForm';
import {formValuesToCorrectJson} from '../utils/utils';

class SelectedDistributor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmEnableDistributorModalOpen: false,
      languageInfo: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onActiveUserButtonClick = this.onActiveUserButtonClick.bind(this);
    this.tryEnableUser = this.tryEnableUser.bind(this);
    this.enableUserConfirmed = this.enableUserConfirmed.bind(this);
  }

  componentDidMount() {
    const {findOne, distributorId} = this.props;
    findOne(distributorId);
  }

  componentWillUnmount() {
    this.props.clearSelectedDistributor();
  }

  handleSubmit(values) {
    formValuesToCorrectJson(values);
    this.props.update(values, this.props.distributorId);
  }

  renderConfirmModal() {
    return (
      <ConfirmModal
        title='distributors.confirmActivateDistributor'
        isOpen={this.state.confirmEnableDistributorModalOpen}
        action={this.enableUserConfirmed}
        handleCancel={() =>
          this.setState({confirmEnableDistributorModalOpen: false})
        }
      />
    );
  }

  enableUserConfirmed() {
    const {enableUser, selectedDistributor, distributorId} = this.props;
    this.setState({confirmEnableDistributorModalOpen: false});
    enableUser(
      selectedDistributor.userId,
      distributorId,
      this.state.languageInfo
    );
  }

  tryEnableUser(selectedDistributor, languageInfo) {
    const {enableUser, distributorId} = this.props;
    if (this.isAnotherDistributorActiveInCountry(selectedDistributor))
      this.setState({
        confirmEnableDistributorModalOpen: true,
        languageInfo: languageInfo,
      });
    else enableUser(selectedDistributor.userId, distributorId, languageInfo);
  }

  onActiveUserButtonClick() {
    const {
      selectedDistributor,
      disableUser,
      i18n,
      distributorId,
      distributors,
    } = this.props;
    const languageInfo = {
      languageVersion: 'WEB',
      languageSymbol: i18n.locale,
    };
    if (selectedDistributor.userDto.enabled)
      disableUser(selectedDistributor.userId, distributorId, languageInfo);
    else this.tryEnableUser(selectedDistributor, languageInfo);
  }

  isAnotherDistributorActiveInCountry(selectedDistributor) {
    const {distributors} = this.props;
    return (
      distributors.filter(
        dist =>
          dist.userDto.enabled &&
          dist.address.country == selectedDistributor.address.country
      ).length > 0
    );
  }

  renderButtons() {
    const {selectedDistributor} = this.props;
    if (selectedDistributor)
      return (
        <div className='text-right'>
          <Button className='main-btn' onClick={this.onActiveUserButtonClick}>
            <Translate
              value={`users.${
                selectedDistributor.userDto.enabled ? 'disable' : 'enable'
              }`}
            />
          </Button>
        </div>
      );
  }

  render() {
    return (
      <Fragment>
        {this.renderConfirmModal()}
        <Loadable active={this.props.isFetching} spinner>
          <div className='px-5 py-3'>
            {this.renderButtons()}
            <EditDistributorForm onSubmit={this.handleSubmit} />
          </div>
        </Loadable>
      </Fragment>
    );
  }
}

SelectedDistributor.propTypes = {
  distributorId: PropTypes.string.isRequired,
};

const mapStatToProps = state => {
  const {i18n, distributor} = state;
  const {isFetching, selectedDistributor, distributors} = distributor;

  return {i18n, isFetching, selectedDistributor, distributors};
};

const mapDispatchToProps = {
  findOne: distributorActions.findOne,
  clearSelectedDistributor: () => ({
    type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
  }),
  update: distributorActions.update,
  enableUser: distributorActions.enable,
  disableUser: distributorActions.disable,
};

export default connect(mapStatToProps, mapDispatchToProps)(SelectedDistributor);
