/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateNumberWithRange,
  validateRequiredFields,
  validateRequiredNumberWithRange,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {additionalCost: {}};
  errors.additionalInfo = validateByLengthOnly(values.additionalInfo, 255);
  errors.additionalCost.value = validateNumberWithRange(
    values.additionalCost ? values.additionalCost.value : null,
    0.01,
    99999.99
  );
  errors.durationValue = validateRequiredNumberWithRange(
    values.durationValue,
    0.01,
    999
  );
  errors.workshopWorkerId = validateRequiredFields(values.workshopWorkerId);
  errors.expiryDate = validateRequiredFields(values.expiryDate);
  errors.durationUnit = validateRequiredFields(values.durationUnit);
  return errors;
};
