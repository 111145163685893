/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {workshopWorkerActions} from 'shared/actions/workshop-worker.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import CalendarEventCreateForm from 'shared/components/schedule/calendar-event-form/CalendarEventCreateForm';
import CalendarEventEditForm from 'shared/components/schedule/calendar-event-form/CalendarEventEditForm';
import {ScheduleMain} from 'shared/components/schedule/schedule-main/ScheduleMain';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';
import {getOrEmptyArray, getOrEmptyObj} from 'shared/utils/utils';
import './schedule.css';

class Schedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFirstLoad: true,
    };

    this.load = this.load.bind(this);
    this.onEventDrop = this.onEventDrop.bind(this);
    this.onEventResize = this.onEventResize.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
    this.onCreateClick = this.onCreateClick.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId, workshopCalendarEventsSyncState} = this.props;
    const shouldLoad =
      (loggedWorkshopId && this.state.isFirstLoad) ||
      becameObsolete(
        prevProps.workshopCalendarEventsSyncState,
        workshopCalendarEventsSyncState
      );

    if (shouldLoad) {
      this.setState({isFirstLoad: false});
      this.load();
    }
  }

  load() {
    const {loggedWorkshopId} = this.props;
    this.props.findWorkshopCalendarEvents(loggedWorkshopId);
    this.props.findAllWorkersByWorkshopId(loggedWorkshopId);
    this.props.findWorkingDaysByWorkshopId(loggedWorkshopId);
  }

  onEventDrop(event, deltaInMinutes, revert) {
    this.props.moveCalendarEvent(event.id, deltaInMinutes, revert);
  }

  onEventResize(event, deltaInMinutes, revert) {
    this.props.resizeCalendarEvent(event.id, deltaInMinutes, revert);
  }

  onEventClick(event) {
    if (event.editable === false) return false;
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <CalendarEventEditForm
        event={event}
        onDeleteSuccess={hideUpdateDataSideBar}
        handleCancel={hideUpdateDataSideBar}
        onUpdateSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(
      details,
      I18n.t('workshopCalendarEvent.editFormTitle')
    );
  }

  onCreateClick() {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <CalendarEventCreateForm
        onCreateSuccess={hideUpdateDataSideBar}
        handleCancel={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(
      details,
      I18n.t('workshopCalendarEvent.createFormTitle')
    );
  }

  render() {
    const {events, i18n, workshopWorkingDays, workshopFreeDays} = this.props;

    return (
      <ContentWrapper title='sideBarNav.schedule'>
        <div className='p-5 schedule-container'>
          <ScheduleMain
            events={events}
            locale={i18n.locale}
            onEventDrop={this.onEventDrop}
            onEventResize={this.onEventResize}
            onEventClick={this.onEventClick}
            onCreateClick={this.onCreateClick}
            workingDays={workshopWorkingDays}
            freeDays={workshopFreeDays}
          />
        </div>
      </ContentWrapper>
    );
  }
}

Schedule.propTypes = {};

const asEvent = event => ({
  ...event,
  start: moment(event.start).format(),
  end: moment(event.end).format(),
});

function mapStateToProps(state) {
  const {i18n, workshop} = state;
  const {data, workshopCalendarEventsSyncState} = workshop;

  const events = [].concat(
    ...getOrEmptyArray(workshop.workshopCalendarEvents).map(asEvent)
  );

  const loggedWorkshopId = data ? data.workshopId : null;
  const workshopWorkingDays = getOrEmptyObj(
    workshop.workshopWorkingDays
  ).workingDays;
  const workshopFreeDays = getOrEmptyObj(workshop.workshopWorkingDays).freeDays;

  return {
    i18n,
    events,
    loggedWorkshopId,
    workshopWorkingDays,
    workshopCalendarEventsSyncState,
    workshopFreeDays,
  };
}

const mapDispatchToProps = {
  moveCalendarEvent: workshopActions.moveCalendarEvent,
  resizeCalendarEvent: workshopActions.resizeCalendarEvent,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
  findWorkshopCalendarEvents: workshopActions.findWorkshopCalendarEvents,
  findAllWorkersByWorkshopId: workshopWorkerActions.findAllWorkersByWorkshopId,
  findWorkingDaysByWorkshopId: workshopActions.findWorkingDaysByWorkshopId,
};

const connectedSchedule = connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedule);
export {connectedSchedule as Schedule};
