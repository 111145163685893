/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useState, createRef, useEffect} from "react";
import {Translate} from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import styles from "./WorkshopLoyaltyProgramSelectedNotification.module.css";
import { useDispatch } from "react-redux";
import EditNotificationDataForm from "./edit-notification-data-form/EditNotificationDataForm";
import {Prompt} from "react-router-dom";
import {ConfirmModal} from "../../../../shared/components/modal/ConfirmModal";
import {history} from "../../../../shared/utils/history";

const WorkshopLoyaltyProgramSelectedNotification = (props) => {
  const formRef = createRef();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [nextLocation, setNextLocation] = useState(null);

  const {
    notification
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [lastLocation]);


  const onSave = () => {
    formRef.current.onSave();
  };

  const onLeaveWithoutDataSave = () => {
    setShowCancelModal(true);
  };

  const renderConfirmCancelModal = () => {
    return (
      <ConfirmModal
        title={'workshopLoyaltyProgram.notifications.onLeaveMessage'}
        bodyText={'workshopLoyaltyProgram.notifications.leaveStepDescription'}
        isOpen={showCancelModal}
        action={() => {
          dispatch({
            type: updateDataSideBarConstants.HIDE,
          });
        }}
        handleCancel={() => setShowCancelModal(false)}
      />
    );
  }

  const renderConfirmLeaveModal = () => {
    return (
      <ConfirmModal
        title={'workshopLoyaltyProgram.notifications.onLeaveMessage'}
        bodyText={'workshopLoyaltyProgram.notifications.leaveStepDescription'}
        isOpen={showLeaveModal}
        action={() => {
          dispatch({
            type: updateDataSideBarConstants.HIDE,
          });
          setLastLocation(nextLocation);
        }}
        handleCancel={() => setShowLeaveModal(false)}
      />
    );
  }

  const handleBlockedNavigation = (nextLocation) => {
    setShowLeaveModal(true);
    setNextLocation(nextLocation);
    return false;
  };

  return (
    <>
      <Prompt when={true} message={handleBlockedNavigation} />
      <div className={styles.Container} style={{ padding: "25px" }}>
        <>
          <EditNotificationDataForm notification={notification} ref={formRef} />
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSave}>
              <Translate value={"workshopLoyaltyProgram.confirm"} />
            </Button>
            <Button className="main-btn" onClick={onLeaveWithoutDataSave}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
          {renderConfirmCancelModal()}
          {renderConfirmLeaveModal()}
        </>
      </div>
    </>
  );
};

export default WorkshopLoyaltyProgramSelectedNotification;
