/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import TextInput from 'shared/components/input/text-input/TextInput';
import ValidationMessageEnum, {ReqexpValidationEnum} from 'shared/utils/utils';
import {languageVersion, linkToResetPassword} from '../../environment';

class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      email: '',
      valid: false,
      dirty: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClickEmailInput = this.handleOnClickEmailInput.bind(this);
    this.handleOnClickSendBtn = this.handleOnClickSendBtn.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleOnChange(event) {
    let emailInputValue = event.target.value;
    this.setState({email: emailInputValue, dirty: true});
    if (
      ReqexpValidationEnum.EMAIl.test(emailInputValue) &&
      emailInputValue.length <= 60
    )
      this.setState({valid: true});
    else this.setState({valid: false});
  }

  handleOnClickEmailInput() {
    this.setState({dirty: true});
  }

  validMessageRender() {
    return (
      <div className='text-center text-danger'>
        <Translate value={ValidationMessageEnum.EMAIl} />
      </div>
    );
  }

  handleOnClickSendBtn() {
    const {dispatch, i18n} = this.props;
    this.toggle();
    let languageInfoDto = {
      languageSymbol: i18n.locale,
      languageVersion: languageVersion,
    };
    let data = {
      resetLink: linkToResetPassword,
      email: this.state.email,
      languageInfoDto: languageInfoDto,
    };
    dispatch(userActions.sendLinkToResetPassword(data));
  }

  render() {
    return (
      <div>
        <Button
          className='w-100'
          type='button'
          size='sm'
          color='link'
          onClick={this.toggle}
          id='open-modal-btn'>
          <Translate value='forgotPasswordModal.openBtn' />
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          style={{border: 'none'}}>
          <ModalHeader toggle={this.toggle}>
            <Translate value='forgotPasswordModal.headerTitle' />
          </ModalHeader>
          <ModalBody>
            <div className='ui-inputgroup px-4 py-3'>
              <TextInput
                label={I18n.t('forgotPasswordModal.emailPlaceholder')}
                type='email'
                value={this.state.email}
                id='email-input'
                hasErrors={!this.state.valid && this.state.dirty}
                onChange={this.handleOnChange}
                onClick={this.handleOnClickEmailInput}
              />
            </div>
            <div className='error-info'>
              {!this.state.valid && this.state.dirty
                ? this.validMessageRender()
                : ''}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.valid}
              onClick={this.handleOnClickSendBtn}
              id='send-btn'
              className='main-btn'>
              <Translate value='forgotPasswordModal.sendBtn' />
            </Button>
            <Button
              onClick={this.toggle}
              id='close-modal-btn'
              className='main-btn'>
              <Translate value='forgotPasswordModal.closeBtn' />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const addonWidth = {
  width: '3em',
};

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

export default connect(mapStateToProps)(ForgotPasswordModal);
