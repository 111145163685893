import React, { useEffect, useState } from 'react'
import Loadable from 'react-loading-overlay';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import { Translate } from 'react-redux-i18n';
import { PointsAccuralPeriodForm } from './PointsAccuralPeriodForm';
import { RadioButton } from 'primereact/radiobutton';
import styles from './PointsAccuralPeriod.module.css';
import { Button } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { workshopLoyaltyProgramActions } from 'shared/actions/workshopLoyaltyProgram.actions';
import { dateUtils } from 'shared/utils/date.utils';

export const PointsAccrualPeriodType = {
    REAL_TIME: 'REAL_TIME',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR'
};

export const PointsAccrualPeriod = () => {
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const loyaltyConfig = useSelector(state => state.workshopLoyaltyProgram.loyaltyConfig);
    const loyaltyConfigFetching = useSelector(state => state.workshopLoyaltyProgram.loyaltyConfigFetching);

    useEffect(() => {
        dispatch(workshopLoyaltyProgramActions.getLoyaltyConfig())
    }, []);

    const renderPointsAccuralPeriod = () => {
        if (loyaltyConfig) {
            if (loyaltyConfig.expirationDate) {
                return (
                    <div style={{display: 'flex', flexWrap: 'no-wrap', marginLeft: '10%', marginRight: '10%'}}>
                        <Translate value={"workshopLoyaltyProgram.workshopLoyaltyProgramEndDate"} />
                        <span className={loyaltyConfig && loyaltyConfig.expirationDate ? styles.LabelValueWithSpace : styles.LabelValue} style={{whiteSpace: 'no-wrap', minWidth: 100}}>
                            {(loyaltyConfig && loyaltyConfig.expirationDate) ? dateUtils.formatDate(loyaltyConfig.expirationDate, 'YYYY-MM-DD') : ''}
                        </span>
                    </div>
                )
            } else return <Translate value={"workshopLoyaltyProgram.accrualPeriodWithoutEnd"} />
        } else return <Translate value={"workshopLoyaltyProgram.noPointsAccrualPeriod"} />
    }

    const pointsAccuralPeriods = [
        { value: PointsAccrualPeriodType.REAL_TIME, name: "workshopLoyaltyProgram.pointsAccrualPeriodTypes.realtime" },
        { value: PointsAccrualPeriodType.MONTH, name: "workshopLoyaltyProgram.pointsAccrualPeriodTypes.month" },
        { value: PointsAccrualPeriodType.QUARTER, name: "workshopLoyaltyProgram.pointsAccrualPeriodTypes.quarter" },
        { value: PointsAccrualPeriodType.YEAR, name: "workshopLoyaltyProgram.pointsAccrualPeriodTypes.year" },
    ];

    return (
        <ContentWrapper title='workshopLoyaltyProgram.pointsAccrualPeriod'>
            <Loadable active={loyaltyConfigFetching} spinner>
                {editMode ? <PointsAccuralPeriodForm pointsAccuralPeriods={pointsAccuralPeriods} setEditMode={setEditMode} loyaltyConfig={loyaltyConfig} />
                    : <>
                        {loyaltyConfig &&
                            <div style={{ marginLeft: '10%', marginTop: '2rem', marginRight: '10%' }}>
                                <div className={styles.LabelContainer}>
                                    <div className={styles.Header}>
                                        <Translate value={"workshopLoyaltyProgram.pointsAccrualPeriodStartDate"} />
                                    </div>
                                    <span className={styles.LabelValue}>{(loyaltyConfig && loyaltyConfig.startDate) ?
                                        dateUtils.formatDate(loyaltyConfig.startDate, 'YYYY-MM-DD') : ''}
                                    </span>
                                </div>
                                <div style={{ marginBottom: '1rem' }}>
                                    <div className={styles.Header}>
                                        <Translate value={'workshopLoyaltyProgram.pointsAccrualPeriod'} />
                                    </div>
                                    {pointsAccuralPeriods.map((option) => (
                                        <div style={{ paddingBottom: '0.5rem', cursor: 'default' }} key={option.value}>
                                            <RadioButton disabled value={option.value} name={option.name} checked={loyaltyConfig && (option.value === loyaltyConfig.repeatability)} />
                                            <span style={{ marginLeft: 9 }}><Translate value={option.name} /></span>
                                        </div>
                                    ))}
                                </div>
                                {loyaltyConfig?.stopDate &&
                                    <div className={styles.LabelContainer}>
                                        <div className={styles.Header}>
                                            <Translate value={"workshopLoyaltyProgram.pointsAccrualPeriodEndDate"} />
                                        </div>
                                        <span className={styles.LabelValue}>{(loyaltyConfig && loyaltyConfig.stopDate) ?
                                            dateUtils.formatDate(loyaltyConfig.stopDate, 'YYYY-MM-DD') : ''}
                                        </span>
                                    </div>}
                                {loyaltyConfig?.expirationDate && <div className={styles.LabelContainer}>
                                    <div className={styles.Header}>
                                        <Translate value={"workshopLoyaltyProgram.workshopLoyaltyProgramEndDateField"} />
                                    </div>
                                    <span className={styles.LabelValue}>{(loyaltyConfig && loyaltyConfig.expirationDate) ?
                                        dateUtils.formatDate(loyaltyConfig.expirationDate, 'YYYY-MM-DD') : ''}</span>
                                </div>}
                                {loyaltyConfig?.spentPointsEndDate && <div className={styles.LabelContainer} style={{display: 'flex', flexWrap: 'wrap'}}>
                                    <div className={styles.Header}>
                                        <Translate value={"workshopLoyaltyProgram.workshopStopDateAccrualPeriod"} />
                                    </div>
                                    <span className={styles.LabelValue}>{(loyaltyConfig && loyaltyConfig.spentPointsEndDate) ?
                                        dateUtils.formatDate(loyaltyConfig.spentPointsEndDate, 'YYYY-MM-DD') : ''}
                                    </span>
                                </div>}
                            </div>
                        }
                        <div className={styles.RenderPointsAccuralPeriod}>
                            {renderPointsAccuralPeriod()}
                        </div>
                        <div className={styles.ButtonSection}>
                            <Button
                                className="main-btn"
                                style={{ width: "14.7rem" }}
                                onClick={() => setEditMode(true)}
                                type='submit'
                            >
                                <Translate value={"workshopLoyaltyProgram.edit"} />
                            </Button>
                        </div>
                    </>
                }
            </Loadable>
        </ContentWrapper>
    )
}
