/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export function composeServicesInCategories(servicesIds, serviceCategories) {
  if (!serviceCategories || serviceCategories.length <= 0) return [];

  const services = servicesIds.map(serviceId =>
    getService(serviceId, serviceCategories)
  );
  const categoriesIds = services.map(service =>
    service ? service.serviceCategoryId : null
  );
  let categoriesIdsNoDupes = [];
  categoriesIds.forEach(catId => {
    if (categoriesIdsNoDupes.indexOf(catId) < 0)
      categoriesIdsNoDupes.push(catId);
  });
  return categoriesIdsNoDupes.map(serviceCategoryId => ({
    serviceCategoryName: serviceCategories.find(
      cat => cat.id === serviceCategoryId
    ).name,
    services: services.filter(
      service => service.serviceCategoryId === serviceCategoryId
    ),
  }));
}

function getService(serviceId, serviceCategories) {
  return serviceCategories
    ? serviceCategories
        .find(category =>
          category.services.some(service => service.id === serviceId)
        )
        .services.find(s => s.id === serviceId)
    : null;
}
