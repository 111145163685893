/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm, initialize} from 'redux-form';
import {LanguageValuesForm} from './LanguageValuesForm';
import {languageActions} from '../../../actions/language.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {languageConstants} from '../../../constants/language.constants';
import {validate} from './language-values-form-validators';
import {getOrEmptyArray} from '../../../utils/utils';

const formName = 'languageValuesEditForm';

export const LanguageValuesFormReduxFormWrapper = reduxForm({
  form: formName,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(LanguageValuesForm);

class LanguageValuesEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showValues: false};

    this.update = this.update.bind(this);
  }

  componentDidMount() {
    const {findLanguageById, languageId} = this.props;
    findLanguageById(languageId);
    setTimeout(() => this.setState({showValues: true}), 100);
  }

  componentWillUnmount() {
    this.props.clearLanguage();
    this.props.resetForm();
  }

  update(data) {
    const {updateLanguageValues, languageId} = this.props;
    updateLanguageValues(languageId, {
      flatValues: getOrEmptyArray(data.flatValues),
    });
  }

  render() {
    const {isFetchingLanguage} = this.props;
    const {showValues} = this.state;

    return (
      <Loadable active={isFetchingLanguage || !showValues} spinner>
        <LanguageValuesFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          showValues={showValues}
        />
      </Loadable>
    );
  }
}

LanguageValuesEditForm.propTypes = {
  languageId: PropTypes.string.isRequired,
  findLanguageById: PropTypes.func.isRequired,
  clearLanguage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {i18n, language} = state;
  const initialValues = language.language || {};
  const {isFetchingLanguage} = language;
  const titleKey = 'languages.editValuesFormTitle';

  return {i18n, initialValues, isFetchingLanguage, titleKey};
}

const mapDispatchToProps = {
  findLanguageById: languageActions.findLanguageById,
  updateLanguageValues: languageActions.updateLanguageValues,
  clearLanguage: () => ({type: languageConstants.CLEAR_LANGUAGE}),
  resetForm: () => initialize(formName, {}),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageValuesEditForm);
