/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from "react";
import { I18n, Translate } from "react-redux-i18n";
import "./datePickerRadioButtonGroup.css";
import { useDispatch, useSelector } from "react-redux";
import CustomRadioButtonGroup from "shared/components/customRadioButton/CustomRadioButtonGroup";
import RenderDatePicker from "shared/components/form/date-picker/hook-form-version/RenderDatePicker";
import { workshopLoyaltyProgramActions } from "shared/actions/workshopLoyaltyProgram.actions";
import moment from "moment";
import { DateType } from 'shared/constants/filter.constants';

const DatePickerRadioButtonGroup = (props) => {
  const {
    specificDate,
    setSpecificDate,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = props;
  const dispatch = useDispatch();
  const selectedDateType = useSelector(
    (state) => state.workshopLoyaltyProgram.selectedDateType
  );

  const renderSingleDateInput = () => (
    <div>
      <RenderDatePicker
        disabled={selectedDateType !== DateType.SPECIFIC_DATE}
        selected={specificDate}
        onChange={setSpecificDate}
        showClearIcon={true}
      />
    </div>
  );

  const renderFromToDateInput = () => (
    <div className="fromToDatePicker">
      <RenderDatePicker
        disabled={selectedDateType !== DateType.FROM_TO}
        selected={fromDate}
        onChange={setFromDate}
        maxDate={toDate ? moment(toDate) : undefined}
        showClearIcon={true}
      />
      <span className="dash">{"—"}</span>
      <RenderDatePicker
        disabled={selectedDateType !== DateType.FROM_TO}
        selected={toDate}
        onChange={setToDate}
        minDate={fromDate ? moment(fromDate) : undefined}
        showClearIcon={true}
      />
    </div>
  );

  const dateTypeFilter = [
    {
      value: DateType.ALL,
      name: I18n.t("workshopLoyaltyProgram.workshops.all"),
    },
    {
      value: DateType.SPECIFIC_DATE,
      name: I18n.t("workshopLoyaltyProgram.workshops.specificDate"),
      component: renderSingleDateInput(),
    },
    {
      value: DateType.FROM_TO,
      name: I18n.t("workshopLoyaltyProgram.workshops.fromToDate"),
      component: renderFromToDateInput(),
    },
  ];

  return (
    <div className={"datePickerRadioButtonGroupWrapper"}>
      <b>
        <Translate value={"workshopLoyaltyProgram.historyPoints.filterBy"} />
      </b>
      <CustomRadioButtonGroup
        options={dateTypeFilter}
        value={selectedDateType}
        onChange={(e) =>
          dispatch(workshopLoyaltyProgramActions.setSelectedDateType(e.value))
        }
      />
    </div>
  );
};

export default DatePickerRadioButtonGroup;
