/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from 'react';
import led_300 from '../../assets/images/warranties/led-300/led-300.png';
import led_500 from '../../assets/images/warranties/led-500/led-500.png';
import led_600 from '../../assets/images/warranties/led-600/led-600.png';
import led_300_401_b_led_401 from '../../assets/images/warranties/led-300-401-b-led-401/led-300-401-b-led-401.png';
import go_fast from '../../assets/images/warranties/go-fast/go-fast.png';
import styles from './WarrantyStaticContainer.module.css';
import {I18n} from 'react-redux-i18n';
import ReactHtmlParser from 'react-html-parser';
import {Checkbox} from 'primereact/checkbox';
import {userService} from 'shared/services/user.service';
import './checkbox.css';

const WarrantyStaticContentContainer = props => {
  const {staticContent, imageListSection, termsField, editMode, setTermsAcceptedJSON, termsAcceptedJSON} = props;
  
  const setCheckBoxValueHandler = () => {
    setTermsAcceptedJSON((termsAcceptedJSON)=> !termsAcceptedJSON)
  }

  const images = [
    {
      id: 1,
      image: led_600,
      name: I18n.t('warranty.sectionIII.led600'),
    },
    {
      id: 2,
      image: go_fast,
      name: I18n.t('warranty.sectionIII.ledGoFast'),
    },
    {
      id: 3,
      image: led_500,
      name: I18n.t('warranty.sectionIII.led500'),
    },
    {
      id: 4,
      image: led_300_401_b_led_401,
      name: I18n.t('warranty.sectionIII.led300_401b'),
    },
    {
      id: 5,
      image: led_300,
      name: I18n.t('warranty.sectionIII.led300'),
    },
  ];

  return (
    <div className={styles.StaticContent}>
      {imageListSection ? (
        <div className={styles.ImageList}>
          {images.map(img => (
            <div className={styles.ImageContainer}>
              <img key={img.id} src={img.image} alt={img.name} />
              <span className={styles.ImageTitle}>{img.name}</span>
            </div>
          ))}
        </div>
      ) : null}
      <div className={styles.StyledTextContent}>
        {ReactHtmlParser(staticContent)}
        {termsField &&
        <div className="termsAccepted">
          <Checkbox
            classname='mr-1'
            onChange={()=> setCheckBoxValueHandler()}
            value={termsAcceptedJSON}
            checked={termsAcceptedJSON}
            readOnly={!userService.isWorkshop() || !editMode}
            disabled={!userService.isWorkshop() || !editMode}
          />
          <span style={{paddingLeft: 9}}>{I18n.t('warranty.sectionVI.termsAcceptedText')}</span>
        </div>}
      </div>
    </div>
  );
};

export default WarrantyStaticContentContainer;
