/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {getFormValues, reduxForm} from 'redux-form';
import SubmitButton from 'shared/components/form/submit-button/SubmitButton';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import DistributorDetailsSection from '../../../admin/distributors/form/sections/distributor-details-section/DistributorDetailsSection';
import {validateDetailsForm} from './validators/validators';

class DistributorDetailsForm extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, selectedDistributor, form} = this.props;
    if (this.shouldInitializePhonePrefixes(prevProps))
      this.props.initializePhonePrefixesByCountry(
        form,
        selectedDistributor.address.country
      );
  }

  shouldInitializePhonePrefixes(prevProps) {
    const {countries, formValues, selectedDistributor} = this.props;
    return (
      countries &&
      selectedDistributor &&
      formValues &&
      !prevProps.formValues &&
      (!formValues.phoneNumber || !formValues.phoneNumber.phoneNumber) &&
      (!formValues.mobilePhoneNumber ||
        !formValues.mobilePhoneNumber.phoneNumber) &&
      (!formValues.faxNumber || !formValues.faxNumber.phoneNumber)
    );
  }

  render() {
    const {handleSubmit, invalid, pristine} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributorProfile.editDetailsHeader' />
        </h3>
        <DistributorDetailsSection />
        <SubmitButton disabled={invalid || pristine} />
      </form>
    );
  }
}

DistributorDetailsForm = reduxForm({
  form: 'distributorDetailsForm',
  validate: validateDetailsForm,
  enableReinitialize: true,
})(DistributorDetailsForm);

function mapStateToProps(state) {
  const {distributor, country} = state;
  const {countries} = country;
  const {selectedDistributor} = distributor;
  const initialValues = selectedDistributor ? selectedDistributor.details : {};
  return {
    i18n: state.i18n,
    initialValues,
    selectedDistributor,
    countries,
    formValues: getFormValues('distributorDetailsForm')(state),
  };
}

//export for tests
export const DistributorDetailsFormConnectedWithRedux = connect(
  mapStateToProps
)(DistributorDetailsForm);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(DistributorDetailsFormConnectedWithRedux)
);
