/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import ValuationInquiryResponseFormSchemaPage3 from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-3/ValuationInquiryResponseFormSchemaPage3';
import {reduxForm} from 'redux-form';
import {calculateTotalSum} from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-3/ValuationInquiryResponseFormPage3';
import {connect} from 'react-redux';
import {validate} from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-3/validators';
import {workshopWorkerActions} from 'shared/actions/workshop-worker.actions';

const ValuationDirectClientsFormPage6 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage3);

function mapStateToProps(state, ownProps) {
  const {i18n, workshop, workshopWorker} = state;
  const {data} = workshop;
  const currency =
    data && data.workshopDetails ? data.workshopDetails.currency : null;
  const loggedWorkshopId = data ? data.workshopId : null;
  const {isFetching, workshopWorkers} = workshopWorker;
  const totalSum = calculateTotalSum(
    state,
    'valuationDirectClientsForm',
    i18n.locale
  );

  return {
    i18n,
    totalSum,
    currency,
    loggedWorkshopId,
    workshopWorkers,
    isFetching,
  };
}

const mapDispatchToProps = {
  findAllWorkersByWorkshopId: workshopWorkerActions.findAllWorkersByWorkshopId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationDirectClientsFormPage6);
