/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';

const RenderReadonly = ({label, element, required}) => (
  <div className='row mt-3'>
    <div
      className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0 read-only-label'
      style={{whiteSpace: 'break-word'}}>
      {label}
      {required && <span style={{color: 'red'}}>*</span>}
    </div>
    <div
      className='col-lg-9 col-12 my-auto'
      style={{fontFamily: 'Montserrat-Regular'}}>
      {element}
    </div>
  </div>
);

export default RenderReadonly;
