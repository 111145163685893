/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import TextInput from 'shared/components/input/text-input/TextInput';
import ValidationMessageEnum, {ReqexpValidationEnum} from 'shared/utils/utils';

class ResetPasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {
      password: '',
      confirmPassword: '',
      isValid: false,
    };

    this.handleOnChangePasswordInput =
      this.handleOnChangePasswordInput.bind(this);
    this.handleOnChangeConfirmPasswordInput =
      this.handleOnChangeConfirmPasswordInput.bind(this);
    this.isInvalidForm = this.isInvalidForm.bind(this);
  }

  handleOnChangePasswordInput(event) {
    this.setState({password: event.target.value});
  }

  handleOnChangeConfirmPasswordInput(event) {
    this.setState({confirmPassword: event.target.value});
  }

  isInvalidForm() {
    return (
      this.state.password !== this.state.confirmPassword ||
      this.state.password.length < 8 ||
      this.state.password.length > 20 ||
      !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
        this.state.password
      )
    );
  }

  getValidationMessage() {
    if (this.state.password.length < 8)
      return {
        key: ValidationMessageEnum.MIN_LENGTH,
        params: {minLength: 8},
      };
    else if (this.state.password.length > 20)
      return {
        key: ValidationMessageEnum.MAX_LENGTH,
        params: {maxLength: 20},
      };
    else if (
      !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
        this.state.password
      )
    )
      return {key: ValidationMessageEnum.PASSWORD, params: {}};
    else if (this.state.password !== this.state.confirmPassword)
      return {key: ValidationMessageEnum.CONFIRM_PASSWORD, params: {}};
  }

  validMessageRender() {
    const error = this.getValidationMessage();

    return (
      <div className='text-center text-danger mb-3'>
        {I18n.t(error.key, error.params)}
      </div>
    );
  }

  render() {
    const {onSubmit} = this.props;
    return (
      <form
        className='text-center py-5'
        onSubmit={e => onSubmit(this.state.password, e)}>
        <TextInput
          label={I18n.t('resetPassword.passwordPlaceholder')}
          type='password'
          style={{marginBottom: '2rem'}}
          value={this.state.password}
          onChange={this.handleOnChangePasswordInput}
        />
        <TextInput
          label={I18n.t('resetPassword.repeatPasswordPlaceholder')}
          type='password'
          value={this.state.confirmPassword}
          onChange={this.handleOnChangeConfirmPasswordInput}
        />
        {this.isInvalidForm() ? this.validMessageRender() : ''}
        <Button
          type='submit'
          className='main-btn mt-5'
          disabled={this.isInvalidForm()}>
          <Translate value='resetPassword.changeBtn' />
        </Button>
      </form>
    );
  }
}

const addonWidth = {
  width: '3em',
};

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const connectedResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);
export {connectedResetPasswordForm as ResetPasswordForm};
