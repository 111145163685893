/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {partService} from 'shared/services/part.service';
import {PartForm} from './PartForm';
import {validate} from './validators/validators';

export const asyncValidate = values =>
  partService
    .existsByNameAndPartCategoryId(values.name, values.partCategoryId)
    .then(response => {
      if (response.data)
        return {name: {key: 'parts.alreadyExistsErrorMsg', params: {}}};
    });

const PartCreateForm = reduxForm({
  form: 'partCreateForm',
  enableReinitialize: true,
  validate: validate,
  asyncValidate,
  asyncBlurFields: ['name'],
})(PartForm);

function mapStateToProps(state, componentProps) {
  return {
    i18n: state.i18n,
    categories: state.part.categories,
    initialValues: {
      partCategoryId: componentProps.categoryId,
      name: componentProps.partName,
      description: componentProps.partDescription,
      countries: componentProps.countries,
    },
    isNeededPart: componentProps.isNeededPart,
    neededPartsInquiryId: componentProps.neededPartsInquiryId,
  };
}

export default connect(mapStateToProps)(PartCreateForm);
