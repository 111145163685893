/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import StatisticsView from '../../admin/statistics/Statistics';

export default class Statistics extends React.Component {
  render() {
    return <StatisticsView {...this.props} />;
  }
}
