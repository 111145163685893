/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {CalendarEventForm} from './CalendarEventForm';
import React from 'react';
import PropTypes from 'prop-types';
import {validate} from './calendar-event-form-form-validators';
import Loadable from 'react-loading-overlay';
import {
  getOrDefault,
  getOrEmptyArray,
  getOrEmptyObj,
} from '../../../utils/utils';
import moment from 'moment';
import {workshopActions} from '../../../actions/workshop.actions';

export const CalendarEventFormReduxFormWrapper = reduxForm({
  form: 'calendarEventEditForm',
  validate,
  enableReinitialize: true,
})(CalendarEventForm);

class CalendarEventEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  update(data) {
    const eventId = data.id;
    this.props.updateCalendarEvent(eventId, data, () =>
      this.props.onUpdateSuccess()
    );
  }

  delete() {
    const {deleteCalendarEvent, event, onDeleteSuccess} = this.props;
    deleteCalendarEvent(event.id, onDeleteSuccess);
  }

  render() {
    const {event} = this.props;
    const getAttributeValue = attrName =>
      getOrEmptyObj(
        getOrEmptyArray(event.attributes).filter(
          item => item.attribute === attrName
        )[0]
      ).value;

    const initialValues = {
      id: event.id,
      title: event.title,
      start: event.start.format(),
      end: getOrDefault(
        event.end,
        moment().startOf('hour').second(0).millisecond(0)
      ).format(),
      assignee: event.assignee,
      workstation: event.workstation,
      color: event.color,
      carData: getAttributeValue('CAR_DATA'),
      servicesData: getAttributeValue('SERVICES_DATA'),
    };

    const showAttributes = initialValues.carData && initialValues.servicesData;

    return (
      <Loadable active={this.props.isFetching} spinner>
        <CalendarEventFormReduxFormWrapper
          {...this.props}
          initialValues={initialValues}
          onSubmit={this.update}
          handleDelete={this.delete}
          minDate={null}
          maxDate={null}
          showAttributes={showAttributes}
        />
      </Loadable>
    );
  }
}

CalendarEventEditForm.propType = {
  event: PropTypes.object.isRequired,
  onDeleteSuccess: PropTypes.func,
};

CalendarEventEditForm.defaultProps = {
  onDeleteSuccess: () => {},
};

function mapStateToProps(state) {
  const {i18n, workshop, workshopWorker} = state;
  const {isFetching} = workshop;
  const workshopWorkers = getOrEmptyArray(workshopWorker.workshopWorkers);

  return {i18n, isFetching, workshopWorkers};
}

const mapDispatchToProps = {
  updateCalendarEvent: workshopActions.updateCalendarEvent,
  deleteCalendarEvent: workshopActions.deleteCalendarEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventEditForm);
