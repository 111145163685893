/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Rating} from 'primereact/rating';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Translate} from 'react-redux-i18n';
import {dateUtils} from '../../../utils/date.utils';
import './rating-info.css';

export class RatingInfo extends Component {
  constructor(props) {
    super(props);
  }

  resolveColorClass(value) {
    if (value > 3) return 'positive';
    else if (value === 3) return 'neutral';
    else if (value < 3) return 'negative';
  }

  render() {
    const {rating} = this.props;

    return (
      <div
        className={
          'row rating-info-row m-2 py-3 clickable ' +
          this.resolveColorClass(rating.value)
        }>
        <div className='col-sm-auto col-12'>
          <Rating value={rating.value} readonly={true} cancel={false} />
          <span style={{fontSize: 'x-small', fontWeight: 'bold'}}>
            {dateUtils.formatDate(rating.createdDate)}
          </span>
        </div>
        <div className='col-sm col-12 break-all'>
          {rating.comment || (
            <Translate value='rating.noComment' style={{fontStyle: 'italic'}} />
          )}
        </div>
      </div>
    );
  }
}

RatingInfo.propType = {
  rating: PropTypes.object.isRequired,
};
