/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {workshopActions} from 'shared/actions/workshop.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {workshopConstants} from 'shared/constants/workshop.constants';
import {WorkshopStatusEnum} from '../utils/utils';
import './selected-workshop.css';
import UpdateWorkshopDataForm from './update-workshop-data-form/UpdateWorkshopDataForm';

class SelectedWorkshop extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.sendInvite = this.sendInvite.bind(this);
    this.shouldShowInviteBtn = this.shouldShowInviteBtn.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: workshopConstants.CLEAR_SELECTED_WORKSHOP_DATA,
    });
  }

  handleOnSubmit(values) {
    this.props.dispatch(
      workshopActions.updateWorkshopByUserId(this.props.selected.userId, {
        ...values,
      })
    );
    if (values.status !== this.props.selected.status) {
      this.props.dispatch({type: updateDataSideBarConstants.HIDE});
      this.props.dispatch({
        type: workshopConstants.CLEAR_SELECTED_WORKSHOP_DATA,
      });
    }
  }

  sendInvite() {
    const {dispatch, selected} = this.props;
    dispatch(workshopActions.sendInvite(selected.workshopId));
  }

  shouldShowInviteBtn() {
    const {selected} = this.props;
    return selected && selected.status === WorkshopStatusEnum.INACTIVE;
  }

  renderButtons() {
    return (
      <div className='row buttons mb-3'>
        <div className='col-lg-8' />
        {this.shouldShowInviteBtn() && (
          <div className='col-sm-12 col-lg-4'>
            <Button
              size='sm'
              className='main-btn'
              style={{minWidth: '150px'}}
              onClick={this.sendInvite}>
              <Translate value={'selectedWorkshop.sendInviteBtn'} />
            </Button>
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className='selected-workshop-wrapper'>
        <Loadable active={this.props.isFetching} spinner>
          {this.props.selected &&
            !this.props.selected.status !== WorkshopStatusEnum.DELETED &&
            this.renderButtons()}
          <UpdateWorkshopDataForm
            onSubmit={this.handleOnSubmit}
            isReadOnly={this.props.isReadOnly}
          />
        </Loadable>
      </div>
    );
  }
}

SelectedWorkshop.propsTypes = {
  isReadOnly: PropTypes.bool.isRequired,
};

SelectedWorkshop.defaultProps = {
  isReadOnly: false,
};

const mapStateToProps = state => {
  const {i18n, workshop} = state;
  const {selected, isFetching} = workshop;
  return {i18n, selected, isFetching};
};

export default connect(mapStateToProps)(SelectedWorkshop);
