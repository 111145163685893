/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate} from 'react-redux-i18n';
import {Button, Card, CardBody} from 'reactstrap';
import {GenericModal} from 'shared/components/modal/GenericModal';
import {AppointmentDatePicker} from '../appointment-date-picker/AppointmentDatePicker';

export class ProposeAppointmentDateModal extends Component {
  constructor(props) {
    super(props);

    const isAppointmentDateFromPast =
      props.currentAppointmentDate &&
      moment(props.currentAppointmentDate).isBefore(moment());
    this.state = {
      isOpen: false,
      appointmentDate: isAppointmentDateFromPast
        ? moment()
        : props.currentAppointmentDate,
    };

    this.renderContent = this.renderContent.bind(this);
    this.renderCalendar = this.renderCalendar.bind(this);
    this.performAction = this.performAction.bind(this);
  }

  renderCalendar() {
    return (
      <Card className='mb-3'>
        <h6
          className='text-center mt-3'
          style={{fontFamily: 'Montserrat-Regular'}}
        >
          <Translate value='order.proposeAppointmentDateCalendarTitle' />
        </h6>
        <CardBody className='p-2'>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              <AppointmentDatePicker
                appointmentDate={this.state.appointmentDate}
                onAppointmentDateChange={date =>
                  this.setState({appointmentDate: date})
                }
                workshopUserId={this.props.workshopUserId}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  performAction() {
    const {action} = this.props;
    action(this.state).then(() => this.setState({isOpen: false}));
  }

  renderContent() {
    return (
      <Fragment>
        {this.renderCalendar()}

        <div className='text-sm-right text-center mt-3'>
          <Button
            id='propose-appointment-date-modal'
            className='m-1 main-btn'
            onClick={this.performAction}
            disabled={moment(this.props.currentAppointmentDate).isSame(
              moment(this.state.appointmentDate)
            )}
          >
            <Translate value='order.confirmBtn' />
          </Button>
          <Button
            id='propose-appointment-date-modal'
            className='m-1 main-btn'
            onClick={() => this.setState({isOpen: false})}
          >
            <Translate value='order.cancelBtn' />
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <GenericModal
          content={this.renderContent()}
          isOpen={this.state.isOpen}
          title='order.proposeAppointmentDateModalTitle'
        />

        <Button
          id='open-propose-appointment-date-modal'
          className='main-btn m-1'
          size='sm'
          onClick={() => {
            this.setState({isOpen: true});
            this.props.onOpen();
          }}
        >
          <Translate
            value={
              this.props.orderStatus && this.props.orderStatus == 'NEW'
                ? 'order.openProposeAppointmentDateModalBtn'
                : 'orderDetails.changeAppointmentDateBtn'
            }
          />
        </Button>
      </Fragment>
    );
  }
}

ProposeAppointmentDateModal.propTypes = {
  action: PropTypes.func.isRequired,
  currentAppointmentDate: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  workshopUserId: PropTypes.string,
};

ProposeAppointmentDateModal.defaultProps = {
  onOpen: () => {},
};
