/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Field} from 'redux-form';
import renderField from '../../form/text-field/RenderField';
import {Button} from 'reactstrap';
import {ConfirmModal} from '../../modal/ConfirmModal';
import {RenderColorPicker} from '../../form/color-picker/RenderColorPicker';

export class CalendarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      colorPickerIndex: 0,
      confirmDeleteModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValues.color !== this.props.initialValues.color)
      this.setState({colorPickerIndex: Math.random()});
  }

  renderFields() {
    return (
      <Fragment>
        <Field
          name='label'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('calendar.label')}
          icon='fa fa-star'
        />

        <Field
          name='url'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('calendar.url')}
          icon='fa fa-calendar'
        />

        <div className='mt-2'>
          <Field
            name='color'
            component={RenderColorPicker}
            required={true}
            label={I18n.t('calendar.color')}
            key={this.state.colorPickerIndex}
          />
        </div>
      </Fragment>
    );
  }

  renderButtons() {
    const {invalid, handleCancel} = this.props;

    return (
      <Fragment>
        <Button
          id='calendar-form-submit-btn'
          className='main-btn fixed-width-btn'
          type='submit'
          disabled={invalid}>
          <Translate value={'calendar.saveBtn'} />
        </Button>

        <Button
          id='calendar-form-cancel-btn'
          className='main-btn fixed-width-btn'
          onClick={handleCancel}>
          <Translate value={'calendar.cancelBtn'} />
        </Button>

        {this.renderDeleteSection()}
      </Fragment>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete()}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      this.props.handleDelete && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmDeleteModal()}
          <Button
            id='calendar-form-delete-btn'
            className='btn-danger fixed-width-btn'
            onClick={() => this.setState({confirmDeleteModalOpen: true})}>
            <Translate value={'calendar.deleteBtn'} />
          </Button>
        </div>
      )
    );
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className='calendar-form p-5'>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          <div className='text-sm-right text-center mt-2'>
            {this.renderButtons()}
          </div>
        </form>
      </div>
    );
  }
}

CalendarForm.propTypes = {
  titleKey: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  invalid: PropTypes.bool,
};
