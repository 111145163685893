/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const formatPhoneNumberField = value => {
  if (!value) return {phoneNumberPrefix: '', phoneNumber: ''};

  return {
    phoneNumberPrefix: value.phoneNumberPrefix,
    phoneNumber: value.phoneNumber
      ? value.phoneNumber
          .replace(/(\d{3})/g, '$1 ')
          .replace(/(\s+)/g, ' ')
          .trim()
      : '',
  };
};

export const formatPhoneNumber = value => {
  if (!value) return '';

  return value.replace(/(\d{3})/g, '$1 ').trim();
};

export const formatLandlinePhoneNumber = value => {
  if (!value) return '';

  return value
    .replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/g, '$1 $2 $3 $4 $5')
    .trim();
};
