/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, FieldArray, getFormValues, reduxForm, reset} from 'redux-form';
import RenderDatePicker from 'shared/components/form/date-picker/RenderDatePicker';
import PartsSection from './PartsSection';

class StatisticsLpgSearchArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  clearFilters() {
    this.props.dispatch(reset(this.props.form));
    this.setState({startDate: null});
  }

  onStartDateChange(date) {
    this.setState({startDate: date});
  }

  render() {
    const {i18n, handleSubmit, formValues} = this.props;
    return (
      <form className='statistics-lpg-search-area p-5' onSubmit={handleSubmit}>
        <div className='row' style={{flexDirection: 'row'}}>
          <div className='col-md-8 col-sm-11 p-3'>
            <h5 className='text-center read-only-label'>
              <Translate value='statistics.lpgFilter' />
            </h5>
            <FieldArray name='partIds' component={PartsSection} />
          </div>
          <div className='col-md-4 col-sm-11 p-3'>
            <h5 className='text-center read-only-label'>
              <Translate value='statistics.dates' />
            </h5>
            <Field
              name='startDate'
              component={RenderDatePicker}
              handleChangeCallback={this.onStartDateChange}
              label={I18n.t('statistics.startDate')}
              showClearIcon={true}
            />
            <Field
              name='endDate'
              component={RenderDatePicker}
              label={I18n.t('statistics.endDate')}
              minDate={this.state.startDate}
              showClearIcon={true}
            />
          </div>
        </div>
        <div className='text-sm-right text-center mt-3 mx-3'>
          <Button className='main-btn' type='submit' disabled={!formValues}>
            <Translate value='statistics.submitSearch' />
          </Button>
          <Button
            className='main-btn'
            size='sm'
            type='reset'
            onClick={this.clearFilters}>
            <Translate value='statistics.resetSearch' />
          </Button>
        </div>
      </form>
    );
  }
}

StatisticsLpgSearchArea = reduxForm({
  form: 'statisticsLpgSearchArea',
})(StatisticsLpgSearchArea);

const mapStateToProps = state => {
  const {i18n, part} = state;
  const formValues = getFormValues('statisticsLpgSearchArea')(state);
  return {i18n, formValues};
};

export default connect(mapStateToProps)(StatisticsLpgSearchArea);
