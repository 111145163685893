import axios from 'axios';
import {axiosService} from './axios.service';

export const warrantyService = {
  getAllWarranties,
  initNewWarrantyBook,
  editWarrantyBook,
  activateWarrantyBook,
  getWarrantyBookById,
  getPeriodicInspectionsByWarrantyId,
  getWarrantyBookByVIN,
  updatePeriodicInspection,
};

function getAllWarranties() {
  return axiosService.get('/car/warranty/list');
}

function initNewWarrantyBook(vin) {
  return axiosService.post(`/car/warranty/${vin}`);
}

function editWarrantyBook(id, data) {
  return axiosService.put(`/car/warranty/edit/${id}`, data);
}

function activateWarrantyBook(id) {
  return axiosService.put(`/car/warranty/activate/${id}`);
}

function getWarrantyBookById(id) {
  return axiosService.get(`/car/warranty/id/${id}`);
}

function getWarrantyBookByVIN(vin){
  return axiosService.get(`/car/warranty/vin/${vin}`)
}

function getPeriodicInspectionsByWarrantyId(warrantyId) {
  return axiosService.get(`/car/inspection/${warrantyId}`);
}

function updatePeriodicInspection(id, inspection) {
  return axiosService.put(`/car/inspection/${id}`, inspection);
}
