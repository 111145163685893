import {DataTable} from 'primereact/datatable';
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import styles from './WarrantiesTable.module.css';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import {Column} from 'primereact/column';
import {useDispatch, useSelector} from 'react-redux';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import WarrantyModal from '../warranty-modal/WarrantyModal';
import {warrantyActions} from 'shared/actions/warranty.actions';
import {dateUtils} from 'shared/utils/date.utils';
import {userService} from 'shared/services/user.service';
import {Dropdown} from 'primereact/dropdown';

const WarrantiesTable = props => {
  const warrantyTableRef = useRef();
  const dispatch = useDispatch();
  const [rowsNumber, setRowsNumber] = useState(10);
  const [globalFilter, setGlobalFilter] = useState();
  let renderPath = userService.isAdmin()? 'admin' : 'workshop'

  const warrantyBooks = useSelector(state => state.warrantyBook.warranties);

  useEffect(() => dispatch(warrantyActions.getAllWarranties()), []);

  const openCreateWarrantyBookModal = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WarrantyModal createStep />,
      title: I18n.t('warranty.wannahCreateWarrantyBook')
    });
  };

  return (
    <div className={styles.Container}>
      <DataTable
        style={{minWidth: '700px'}}
        value={[...warrantyBooks]}
        header={
          <div className={styles.Header}>
            {userService.isWorkshop() && <Button className='main-btn' onClick={openCreateWarrantyBookModal}>
              <Translate value={'warranty.addNew'} />
            </Button>}
            <div className={styles.GlobalFilter}>
              <Input
                type='search'
                placeholder={I18n.t('warranty.searchPlaceholder')}
                onInput={e => setGlobalFilter(e.target.value)}
                className='global-filter'
              />
            </div>
          </div>
        }
        className='data-table-padding-class'
        rows={rowsNumber}
        globalFilter={globalFilter}
        paginator={true}
        responsive={true}
        emptyMessage={'Brak danych'}
        paginatorLeft={
          <Dropdown
            options={rowsQuantityToDisplay}
            className='data-table-rows-dropdown'
            value={rowsNumber}
            onChange={e => setRowsNumber(e.value)}
          />
        }
        ref={warrantyTableRef}
        pageLinkSize={3}>
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field='warrantyNumber'
          header={"Numer książki \n gwarancyjnej"}
          sortable
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field='vin'
          header={I18n.t('warranty.vin')}
          sortable
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field='carDetails.registrationNumber'
          header={I18n.t('warranty.sectionI.registrationNumber')}
          sortable
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field='createdDate'
          header={I18n.t('warranty.createdDate')}
          sortable
          body = {rowData =>(
            rowData.createdDate && dateUtils.formatDate(rowData.createdDate, "YYYY-MM-DD")
          )}
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field='status'
          header={I18n.t('warranty.activity')}
          sortable
          body={rowData => (
            <>
            <div hidden>{rowData.status}</div>
              <div
                className={rowData.status == 'ACTIVE' ? styles.DotGreen : styles.DotRed}
              />
            </>
          )}
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field=''
          body={rowData => ( 
            <Link to={`/${renderPath}/warranty/${rowData.id}`}>
              <Button className={styles.WarrantyBtn}>
                {userService.isWorkshop()? I18n.t('warranty.edit') : I18n.t('warranty.warrantyBookButton')}
              </Button>
            </Link>
          )}
        />
      </DataTable>
    </div>
  );
};

export default WarrantiesTable;
