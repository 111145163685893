/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { I18n, Translate } from "react-redux-i18n";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Dropdown } from 'primereact/dropdown';
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { rowsQuantityToDisplay } from 'shared/utils/utils';
import { dateUtils, DATE_SHORT_FORMAT } from 'shared/utils/date.utils';
import { Input } from 'reactstrap';
import { RadioButton } from "primereact/radiobutton";
import DatePickerRadioButtonGroup from "./datePickerRadioButtonGroup/DatePickerRadioButtonGroup";
import { BlockType, DateType } from 'shared/constants/filter.constants';
import styles from './WorkshopList.module.css';

export const WorkshopParticipationBlockHistory = () => {
    const dispatch = useDispatch();

    const workshopsParticipationBlockHistory = useSelector(state => state.workshopLoyaltyProgram.workshopsParticipationBlockHistory);
    const workshopsParticipationBlockHistoryFetching = useSelector(state => state.workshopLoyaltyProgram.workshopsParticipationBlockHistoryFetching);
    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState();
    const [sortDirection, setSortDirection] = useState();
    const [first, setFirst] = useState(0)
    const [globalFilter, setGlobalFilter] = useState('');
    const [specificDate, setSpecificDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const workshopListFetching = useSelector(state => state.workshopLoyaltyProgram.workshopListFetching);
    const selectedDateType = useSelector((state) => state.workshopLoyaltyProgram.selectedDateType);
    const selectedBlockType = useSelector((state) => state.workshopLoyaltyProgram.selectedBlockType);

    const blockTypeFilter = [
      { value: BlockType.ALL, name: I18n.t('workshopLoyaltyProgram.workshops.all') },
      { value: BlockType.BLOCKED, name: I18n.t('workshopLoyaltyProgram.workshops.blocked') },
      { value: BlockType.UNBLOCKED, name: I18n.t('workshopLoyaltyProgram.workshops.unblocked') },
      ];

    const mapSortField = (label) => {
        switch (label) {
            case 'eventDateTime':
                return 'date'
            case 'event':
                return 'type'
            case 'workshopName':
                return 'workshopLoyalty.workshopName'
            default:
                break;
        }
    }

    useEffect(() => {
        if (
            selectedDateType &&
            ((selectedDateType === DateType.SPECIFIC_DATE && !specificDate) ||
            (selectedDateType === DateType.FROM_TO && (!fromDate || !toDate)))
        ) return;

        let params = {
            page: page - 1,
            size: pageLimit,
            sort: (sortField && sortDirection) && `${sortField},${sortDirection}`,
            search: globalFilter
        };
        if(selectedBlockType && selectedBlockType !== BlockType.ALL) {
            params['isBlocked'] = selectedBlockType === BlockType.BLOCKED;
        }

        if(selectedDateType && selectedDateType !== DateType.ALL) {
            if(selectedDateType === DateType.SPECIFIC_DATE && specificDate) {
                params['date'] = specificDate.format('YYYY-MM-DD');
            }  else if (selectedDateType === DateType.FROM_TO && fromDate && toDate) {
                params['fromDate'] = fromDate.format('YYYY-MM-DD');
                params['toDate'] = toDate.format('YYYY-MM-DD');
            }
        }
        dispatch(workshopLoyaltyProgramActions.loyaltyParticipationLockHistory(params));
    }, [page, pageLimit, sortField, sortDirection, workshopListFetching, globalFilter, selectedBlockType, selectedDateType, fromDate, toDate, specificDate]);

    return (
        <ContentWrapper title='workshopLoyaltyProgram.workshops.workshopBlockHistory' scrollY>
            <Loadable active={workshopsParticipationBlockHistoryFetching} spinner>
                <div className='col' style={{minWidth: 976}}>
                    <DataTable
                        value={workshopsParticipationBlockHistory.content}
                        paginator={false}
                        rows={pageLimit}
                        className='data-table-padding-class'
                        responsive={true}
                        emptyMessage={I18n.t('workshopLoyaltyProgram.workshops.noWorkshopLockHistory')}
                        onSort={e => {
                            setPage(1);
                            setFirst(0);
                            setSortField(mapSortField(e.sortField));
                            setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
                        }}
                        header={
                          <div className={styles.Header}>
                            <Loadable active={workshopListFetching} spinner>
                              <div className={styles.FilterContainer}>
                                <div className={styles.RadioButtons}>
                                <b>
                                  <Translate
                                  value={
                                    "workshopLoyaltyProgram.historyPoints.filterBy"
                                  }
                                  />
                                </b>
                                {blockTypeFilter.map((option) => (
                                  <div style={{ marginTop: "1rem" }} key={option.value}>
                                  <RadioButton
                                    value={option.value}
                                    name={option.name}
                                    onChange={(e) =>
                                    dispatch(
                                      workshopLoyaltyProgramActions.setSelectedBlockType(
                                      e.value
                                      )
                                    )
                                    }
                                    checked={option.value === selectedBlockType}
                                  />
                                  <span style={{ marginLeft: 9, fontWeight: 400 }}>
                                    <Translate value={option.name} />
                                  </span>
                                  </div>
                                ))}
                                </div>
                                <DatePickerRadioButtonGroup
                                specificDate={specificDate}
                                setSpecificDate={setSpecificDate}
                                toDate={toDate}
                                setToDate={setToDate}
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                />
                              </div>
                              </Loadable>
                              <div className={styles.SearchInput}>
                              <Input
                                type="search"
                                placeholder={I18n.t("workshops.searchPlaceholder")}
                                onInput={(e) => {
                                setFirst(0);
                                setPage(1);
                                setSortField(e.sortField);
                                setGlobalFilter(e.target.value);
                                }}
                                className="global-filter"
                              />
                            </div>
                          </div>
                        }
                    >
                        <Column
                            field='workshopName'
                            header={I18n.t('workshopLoyaltyProgram.workshops.workshopName')}
                            sortable={true}
                        />
                        <Column
                            field='event'
                            header={I18n.t('workshopLoyaltyProgram.workshops.eventType')}
                            body={(rowData) => rowData.event === 'locked' ? I18n.t('workshopLoyaltyProgram.workshops.blocked') : I18n.t('workshopLoyaltyProgram.workshops.unblocked')}
                            sortable={true}
                        />
                        <Column
                            field='eventDateTime'
                            header={I18n.t('workshopLoyaltyProgram.workshops.eventDate')}
                            sortable={true}
                            body={(rowData) => dateUtils.formatDate(rowData.eventDateTime, DATE_SHORT_FORMAT)}
                        />
                    </DataTable>
                    <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
                        <Paginator
                            first={first}
                            rows={pageLimit}
                            totalRecords={workshopsParticipationBlockHistory.totalElements}
                            pageLinkSize={5}
                            onPageChange={(e) => {
                                setPage(e.page + 1)
                                setFirst(e.first)
                            }}
                            leftContent={
                                <Dropdown
                                    options={rowsQuantityToDisplay}
                                    className='data-table-rows-dropdown'
                                    value={pageLimit}
                                    onChange={e => { setPage(1), setPageLimit(e.value) }}
                                />
                            }
                        ></Paginator>
                    </div>
                </div>
            </Loadable>
        </ContentWrapper>
    )
}
