/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {change, Field} from 'redux-form';
import {Alert, Button, Tooltip} from 'reactstrap';
import React from 'react';
import {Translate} from 'react-redux-i18n';
import {I18n} from 'react-redux-i18n';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import renderField from 'shared/components/form/text-field/RenderField';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {getOrEmptyArray, isEmptyArray, rangeArray} from 'shared/utils/utils';
import './car-form.css';
import normalizeNumber from 'shared/components/form/normalizer/number-normalizer';
import normalizeDoubleNumber from 'shared/components/form/normalizer/DoubleNumberNormalizer';
import formatDoubleNumber from 'shared/components/form/formatter/DoubleNumberFormatter';
import {getFuelTypeFormOptions} from 'shared/utils/fuel-type/fuel-type.utils';

export class CarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteModalOpen: false,
      nameTooltipOpen: false,
      engineCapacityTooltipOpen: false,
      enginePowerTooltipOpen: false,
    };

    this.brandChanged = this.brandChanged.bind(this);
    this.modelChanged = this.modelChanged.bind(this);
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete(this.props.selectedCarId)}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      <div style={{display: 'inline-block'}}>
        {this.renderConfirmDeleteModal()}
        <Button
          id='car-form-delete-btn'
          className='btn-danger'
          onClick={() => this.setState({confirmDeleteModalOpen: true})}>
          <Translate value={'car.deleteBtn'} />
        </Button>
      </div>
    );
  }

  brands() {
    const {carBrands} = this.props;
    return getOrEmptyArray(carBrands).map(carBrand => ({
      label: carBrand.name,
      value: carBrand.name,
    }));
  }

  models() {
    const {carBrands, brandFieldValue} = this.props;
    const selectedBrand = getOrEmptyArray(carBrands).filter(
      brand => brand.name === brandFieldValue
    );
    const selectedBrandModels = !isEmptyArray(selectedBrand)
      ? selectedBrand[0].models
      : [];
    return selectedBrandModels
      .map(model => ({label: model.name, value: model.name}))
      .concat([{label: I18n.t('car.otherModel'), value: 'other'}]);
  }

  brandChanged() {
    const {dispatch, formName} = this.props;
    dispatch(change(formName, 'model', ''));
    dispatch(change(formName, 'name', ''));
  }

  modelChanged() {
    setTimeout(() => this.generateName(), 100);
  }

  generateName() {
    const {brandFieldValue, modelFieldValue, formName, dispatch} = this.props;
    if (brandFieldValue && modelFieldValue) {
      const model = modelFieldValue !== 'other' ? ' ' + modelFieldValue : '';
      dispatch(change(formName, 'name', brandFieldValue + model));
    }
  }

  renderModelOtherTip() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <Alert className='mb-0 text-center' color='warning'>
            <Translate value='car.modelOtherTip' />
          </Alert>
        </div>
      </div>
    );
  }

  years() {
    return Array.from(rangeArray(1970, new Date().getFullYear() + 1, 1))
      .reverse()
      .map(year => ({label: year.toString(), value: year}));
  }

  fuelTypes() {
    const {fuelTypes} = this.props;
    return getFuelTypeFormOptions(fuelTypes, 'name');
  }

  isFuelTypeElectricity() {
    const {fuelTypeFieldValue} = this.props;
    return fuelTypeFieldValue === 'ELECTRICITY';
  }

  fuelTypeChanged(e, value) {
    const {dispatch, formName} = this.props;
    if (value === 'ELECTRICITY') {
      dispatch(change(formName, 'engineCapacityCm3', ''));
    }
  }

  render() {
    const {handleSubmit, invalid, handleDelete, handleCancel} = this.props;

    return (
      <div className='car-form px-5 pb-5'>
        <form onSubmit={handleSubmit}>
          <Field
            name='brand'
            component={RenderDropdown}
            required={true}
            placeholder={I18n.t('car.brandPlaceholder')}
            label={I18n.t('car.brandLabel')}
            icon='fa fa-cube'
            options={this.brands()}
            onChange={this.brandChanged}
          />

          <Field
            name='model'
            component={RenderDropdown}
            required={true}
            placeholder={I18n.t('car.modelPlaceholder')}
            label={I18n.t('car.modelLabel')}
            icon='fa fa-cubes'
            options={this.models()}
            onChange={this.modelChanged}
          />

          {this.renderModelOtherTip()}

          <Field
            name='modelOther'
            component={renderField}
            type='text'
            required={true}
            label={I18n.t('car.modelOtherLabel')}
            icon='fa fa-cubes'
            inputDisabled={this.props.modelFieldValue !== 'other'}
          />

          <div id='nameTooltip'>
            <Field
              name='name'
              component={renderField}
              type='text'
              required={true}
              label={I18n.t('car.nameLabel')}
              icon='fa fa-briefcase'
            />
            <Tooltip
              innerClassName='form-tooltip'
              placement='top'
              target='nameTooltip'
              isOpen={this.state.nameTooltipOpen}
              toggle={() =>
                this.setState({nameTooltipOpen: !this.state.nameTooltipOpen})
              }>
              <Translate value='car.nameTooltip' />
            </Tooltip>
          </div>

          <Field
            name='productionYear'
            component={RenderDropdown}
            required={true}
            placeholder={I18n.t('car.productionYearPlaceholder')}
            label={I18n.t('car.productionYearLabel')}
            icon='fa fa-calendar'
            options={this.years()}
          />

          <Field
            name='fuelType'
            component={RenderDropdown}
            required={true}
            options={this.fuelTypes()}
            placeholder={I18n.t('car.fuelTypePlaceholder')}
            label={I18n.t('car.fuelTypeLabel')}
            icon='fa fa-tint'
            onChange={(e, value) => this.fuelTypeChanged(e, value)}
          />

          {!this.isFuelTypeElectricity() && (
            <div id='engineCapacityTooltip'>
              <Field
                name='engineCapacityCm3'
                component={renderField}
                type='text'
                icon='fa fa-cog'
                required={!this.isFuelTypeElectricity()}
                label={I18n.t('car.engineCapacityCm3Label')}
                normalize={normalizeNumber}
                inputDisabled={this.isFuelTypeElectricity()}
              />
              <Tooltip
                innerClassName='form-tooltip'
                placement='top'
                target='engineCapacityTooltip'
                isOpen={this.state.engineCapacityTooltipOpen}
                toggle={() =>
                  this.setState({
                    engineCapacityTooltipOpen:
                      !this.state.engineCapacityTooltipOpen,
                  })
                }>
                <Translate value='car.engineCapacityTooltip' />
              </Tooltip>
            </div>
          )}

          <div id='enginePowerTooltip'>
            <Field
              name='enginePowerKw'
              component={renderField}
              type='text'
              required={true}
              label={I18n.t('car.enginePowerKwLabel')}
              icon='fa fa-bolt'
              normalize={normalizeDoubleNumber}
              format={formatDoubleNumber}
            />
            <Tooltip
              innerClassName='form-tooltip'
              placement='top'
              target='enginePowerTooltip'
              isOpen={this.state.enginePowerTooltipOpen}
              toggle={() =>
                this.setState({
                  enginePowerTooltipOpen: !this.state.enginePowerTooltipOpen,
                })
              }>
              <Translate value='car.enginePowerTooltip' />
            </Tooltip>
          </div>

          <Field
            name='vin'
            component={renderField}
            type='text'
            required={true}
            label={I18n.t('car.vinLabel')}
            icon='fa fa-car'
          />

          <Field
            name='registrationNumber'
            component={renderField}
            type='text'
            required={true}
            label={I18n.t('car.registrationNumberLabel')}
            icon='fa fa-car'
          />

          <div className='text-sm-right text-center mt-2'>
            <Button
              id='car-form-submit-btn'
              className='main-btn'
              type='submit'
              disabled={invalid}>
              <Translate value={'car.saveBtn'} />
            </Button>
            <Button
              id='car-form-cancel-btn'
              className='main-btn'
              onClick={handleCancel}>
              <Translate value={'car.cancelBtn'} />
            </Button>
            {handleDelete && this.renderDeleteSection()}
          </div>
        </form>
      </div>
    );
  }
}
