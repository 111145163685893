/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {getOrEmptyArray} from 'shared/utils/utils';

class PartsSection extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(partCategoryActions.findAllPartCategories());
  }

  mapPartsForDropdown(parts) {
    return parts
      ? parts.map(part => ({
          label: part.name,
          value: {partId: part.partId, categoryId: part.partCategoryId},
          key: part.partId,
        }))
      : [];
  }

  onChange(event, categoryId) {
    const {fields} = this.props;
    const oldValue = getOrEmptyArray(fields.getAll()).find(
      part => part.categoryId === categoryId
    );
    if (oldValue) fields.remove(fields.getAll().indexOf(oldValue));
    if (event.value) fields.push(event.value);
  }

  resolveValue(category) {
    const {fields} = this.props;
    return getOrEmptyArray(fields.getAll()).find(
      f => f.categoryId === category.id
    );
  }

  renderCategory(category) {
    const {fields} = this.props;
    return (
      <div className='row mt-3' key={category.id}>
        <div className='col-12 field-label'>{category.name}</div>
        <div className='col-12'>
          <div className='ui-inputgroup'>
            <Dropdown
              value={this.resolveValue(category)}
              options={this.mapPartsForDropdown(category.parts)}
              onChange={e => this.onChange(e, category.id)}
              placeholder={I18n.t('statistics.chooseComponent')}
              filterBy='label,value'
              style={dropdownStyle}
              filter={true}
              filterPlaceholder={I18n.t('renderField.searchPlaceholder')}
              showClear={true}
              className='dropdown-content'
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {categories} = this.props;
    return getOrEmptyArray(categories).map(this.renderCategory);
  }
}

const dropdownStyle = {
  borderLeft: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
};

function mapStateToProps(state) {
  const {part} = state;
  const {categories} = part;
  return {categories};
}

export default connect(mapStateToProps)(PartsSection);
