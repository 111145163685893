/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {SubscriptionPlanForm} from './SubscriptionPlanForm';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {subscriptionPlanActions} from '../../../actions/subscription-plan.actions';
import {validate} from './subscription-plan-form-validators';

export const SubscriptionPlanFormReduxFormWrapper = reduxForm({
  form: 'subscriptionPlanCreateForm',
  validate,
  enableReinitialize: true,
})(SubscriptionPlanForm);

class SubscriptionPlanCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createSubscriptionPlan, onCreateSuccess} = this.props;
    createSubscriptionPlan(data, onCreateSuccess);
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <SubscriptionPlanFormReduxFormWrapper
          {...this.props}
          onSubmit={this.create}
        />
      </Loadable>
    );
  }
}

SubscriptionPlanCreateForm.propTypes = {
  createSubscriptionPlan: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {i18n, subscriptionPlan} = state;

  const {isFetching} = subscriptionPlan;
  const disableMainFields = false;

  return {
    i18n,
    isFetching,
    disableMainFields,
  };
}

const mapDispatchToProps = {
  createSubscriptionPlan: subscriptionPlanActions.createSubscriptionPlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanCreateForm);
