/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Checkbox} from 'primereact/checkbox';
import React, {Fragment} from 'react';
import {Translate} from 'react-redux-i18n';
import {getOrEmptyArray} from 'shared/utils/utils';

const privileges = [
  {value: 'PRIVILEGE_EDIT', label: 'editionAccessLabel'},
  {value: 'PRIVILEGE_STATISTIC', label: 'statisticAccessLabel'},
  {value: 'PRIVILEGE_OWN_SERVICES', label: 'ownServicesAccessLabel'},
];

export default class AccessSection extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  renderPrivilege(privilege) {
    const {fields} = this.props;

    return (
      <div className='row mt-3' key={privilege.value}>
        <div className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0' />
        <div className='col-lg-9 col-12 my-auto'>
          <div
            className='text-justify'
            style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <Checkbox
              classname='mr-1'
              onChange={this.onChange}
              value={privilege.value}
              checked={
                getOrEmptyArray(fields.getAll()).indexOf(privilege.value) >= 0
              }
            />
            <div className='checkbox-label w-100'>
              <Translate value={`distributors.${privilege.label}`} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange(event) {
    const {fields} = this.props;

    event.checked
      ? fields.push(event.value)
      : fields.remove(getOrEmptyArray(fields.getAll()).indexOf(event.value));
  }

  render() {
    return (
      <Fragment>
        {privileges.map(privilege => this.renderPrivilege(privilege))}
      </Fragment>
    );
  }
}
