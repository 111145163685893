/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import DistributorsList from './distributors-list/DistributorsList';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';

class Distributors extends React.Component {
  render() {
    return (
      <ContentWrapper title='sideBarNav.distributors'>
        <Loadable active={this.props.isFetching} spinner>
          <DistributorsList />
        </Loadable>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => {
  const {i18n, distributor} = state;
  const {isFetching} = distributor;
  return {i18n, isFetching};
};

export default connect(mapStateToProps)(Distributors);
