/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const isValidByFileType = (fileType, types) => {
  return !!types.find(type => {
    return fileType === type;
  });
};

export const isValidBySize = (fileSize, maxFileSize) => {
  return fileSize <= maxFileSize;
};

export const cutPrefixFromBase64String = value => {
  return value.indexOf(',') >= 0 ? value.split(',')[1].toString() : value;
};

const mimeTypeMap = new Map([
  ['pdf', 'application/pdf'],
  ['png', 'image/png'],
  ['jpg', 'image/jpeg'],
  ['jpeg', 'image/jpeg'],
  ['doc', 'application/msword'],
  [
    'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  ['txt', 'text/plain'],
  ['zip', 'application/zip'],
  ['pages', 'application/x-iwork-pages-sffpages'],
  ['odt', 'application/vnd.oasis.opendocument.text'],
]);

export const getMimeTypeFromFileName = filename => {
  return mimeTypeMap.get(filename.split('.').pop());
};
