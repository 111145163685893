/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const languageService = {
  findAllLanguageSyncLogs,
  createLanguage,
  findAllLanguages,
  findLanguageById,
  updateLanguage,
  deleteLanguageById,
  updateLanguageValues,
};

export const LANGUAGE_SYNC_LOGS_API_BASE_PATH = '/language/language-sync-logs';
export const LANGUAGES_API_BASE_PATH = '/language/languages';

function findAllLanguageSyncLogs() {
  return axiosService
    .get(`${LANGUAGE_SYNC_LOGS_API_BASE_PATH}?languageVersion=WEB`)
    .then(response => {
      return response;
    });
}

function createLanguage(data) {
  return axiosService
    .post(`${LANGUAGES_API_BASE_PATH}`, {...data, version: 'WEB'})
    .then(response => {
      return response;
    });
}

function findAllLanguages() {
  return axiosService
    .get(`${LANGUAGES_API_BASE_PATH}?languageVersion=WEB`)
    .then(response => {
      return response;
    });
}

function findLanguageById(languageId) {
  return axiosService
    .get(`${LANGUAGES_API_BASE_PATH}/${languageId}`)
    .then(response => {
      return response;
    });
}

function updateLanguage(languageId, data) {
  return axiosService
    .put(`${LANGUAGES_API_BASE_PATH}/${languageId}`, data)
    .then(response => {
      return response;
    });
}

function deleteLanguageById(languageId) {
  return axiosService
    .delete(`${LANGUAGES_API_BASE_PATH}/${languageId}`)
    .then(response => {
      return response;
    });
}

function updateLanguageValues(languageId, data) {
  return axiosService
    .put(`${LANGUAGES_API_BASE_PATH}/${languageId}/values`, data)
    .then(response => {
      return response;
    });
}
