/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {loyaltyProgramConstants} from '../constants/loyalty-program.constants';
import store from '../../store';
import {getOrEmptyArray} from '../utils/utils';
import {SyncState} from '../utils/sync';

export function loyaltyProgramReducer(state = {}, action) {
  switch (action.type) {
    case loyaltyProgramConstants.LOYALTY_PROGRAMS_REQUEST_START:
      return {...state, isFetchingLoyaltyPrograms: true};

    case loyaltyProgramConstants.LOYALTY_PROGRAMS_REQUEST_END:
      return {...state, isFetchingLoyaltyPrograms: false};

    case loyaltyProgramConstants.LOYALTY_PROGRAM_ACCOUNTS_REQUEST_START:
      return {...state, isFetchingLoyaltyProgramAccounts: true};

    case loyaltyProgramConstants.LOYALTY_PROGRAM_ACCOUNTS_REQUEST_END:
      return {...state, isFetchingLoyaltyProgramAccounts: false};

    case loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_START:
      return {...state, isFetchingLoyaltyProgram: true};

    case loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END:
      return {...state, isFetchingLoyaltyProgram: false};

    case loyaltyProgramConstants.LOAD_SERVICES_LOYALTY_PROGRAM_POINTS:
      return {...state, servicesLoyaltyProgramPoints: action.payload.data};

    case loyaltyProgramConstants.MODIFY_SERVICES_LOYALTY_PROGRAM_POINTS:
      return {
        ...state,
        servicesLoyaltyProgramPoints: getOrEmptyArray(
          state.servicesLoyaltyProgramPoints
        ).map(p =>
          p.serviceId === action.payload.serviceId
            ? {
                ...p,
                loyaltyProgramPoints: parseInt(
                  action.payload.loyaltyProgramPoints || 0,
                  10
                ),
              }
            : p
        ),
      };

    case loyaltyProgramConstants.LOAD_LOYALTY_PROGRAMS:
      return {
        ...state,
        loyaltyPrograms: action.payload.data,
        loyaltyProgramsDataSyncState: SyncState.UP_TO_DATE,
      };

    case loyaltyProgramConstants.REFRESH_LOYALTY_PROGRAMS:
      return {...state, loyaltyProgramsDataSyncState: SyncState.OBSOLETE};

    case loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM:
      return {...state, activeLoyaltyProgram: action.payload.data};

    case loyaltyProgramConstants.CLEAR_LOYALTY_PROGRAM:
      const {activeLoyaltyProgram, ...otherState} = state;
      return otherState;

    case loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM_ACCOUNT:
      return {...state, activeLoyaltyProgramAccount: action.payload.data};

    case loyaltyProgramConstants.CLEAR_LOYALTY_PROGRAM_ACCOUNT:
      const {activeLoyaltyProgramAccount, ...otherState1} = state;
      return otherState1;

    case loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM_ACCOUNTS:
      return {...state, loyaltyProgramAccounts: action.payload.data};

    case loyaltyProgramConstants.CLEAR_ALL:
      return {};

    default:
      return state;
  }
}

const modifyServicesLoyaltyProgramPoints = (
  serviceId,
  loyaltyProgramPoints
) => {
  return getOrEmptyArray(
    store.getState().loyaltyProgram.servicesLoyaltyProgramPoints
  ).map(p =>
    p.serviceId === serviceId
      ? {...p, loyaltyProgramPoints: parseInt(loyaltyProgramPoints || 0, 10)}
      : p
  );
};
