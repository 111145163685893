/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {loyaltyProgramActions} from 'shared/actions/loyalty-program.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import LoyaltyProgramCreateForm from 'shared/components/loyalty-program/loyalty-program-form/LoyaltyProgramCreateForm';
import LoyaltyProgramEditForm from 'shared/components/loyalty-program/loyalty-program-form/LoyaltyProgramEditForm';
import LoyaltyProgramTable from 'shared/components/loyalty-program/loyalty-program-table/LoyaltyProgramTable';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';
import {getOrEmptyObj} from 'shared/utils/utils';

class WorkshopLoyaltyPrograms extends Component {
  constructor(props) {
    super(props);

    this.state = {isFirstFetch: true};

    this.fetch = this.fetch.bind(this);
    this.showLoyaltyProgramDetails = this.showLoyaltyProgramDetails.bind(this);
    this.showCreateLoyaltyProgram = this.showCreateLoyaltyProgram.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId, loyaltyProgramsDataSyncState} = this.props;
    const shouldFetch =
      (loggedWorkshopId && this.state.isFirstFetch) ||
      becameObsolete(
        prevProps.loyaltyProgramsDataSyncState,
        loyaltyProgramsDataSyncState
      );

    if (shouldFetch) {
      this.setState({isFirstFetch: false});
      this.fetch();
    }
  }

  fetch() {
    const {findLoyaltyProgramsByWorkshopId, loggedWorkshopId} = this.props;
    findLoyaltyProgramsByWorkshopId(loggedWorkshopId);
  }

  showLoyaltyProgramDetails(row) {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <LoyaltyProgramEditForm
        loyaltyProgramId={row.data.id}
        handleCancel={hideUpdateDataSideBar}
        onDeleteSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details);
  }

  showCreateLoyaltyProgram() {
    const {loggedWorkshopId, showUpdateDataSideBar, hideUpdateDataSideBar} =
      this.props;
    const details = (
      <LoyaltyProgramCreateForm
        workshopId={loggedWorkshopId}
        handleCancel={hideUpdateDataSideBar}
        onCreateSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details);
  }

  render() {
    const {loyaltyPrograms, isFetchingLoyaltyPrograms} = this.props;

    return (
      <ContentWrapper title='sideBarNav.loyaltyPrograms'>
        <Loadable active={isFetchingLoyaltyPrograms} spinner>
          <LoyaltyProgramTable
            loyaltyPrograms={loyaltyPrograms}
            onRowClick={this.showLoyaltyProgramDetails}
            onCreateClick={this.showCreateLoyaltyProgram}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {loyaltyProgram, workshop} = state;
  const {
    loyaltyPrograms,
    isFetchingLoyaltyPrograms,
    loyaltyProgramsDataSyncState,
  } = loyaltyProgram;
  const loggedWorkshopId = getOrEmptyObj(workshop.data).workshopId;

  return {
    loyaltyPrograms,
    isFetchingLoyaltyPrograms,
    loyaltyProgramsDataSyncState,
    loggedWorkshopId,
  };
}

const mapDispatchToProps = {
  findLoyaltyProgramsByWorkshopId:
    loyaltyProgramActions.findLoyaltyProgramsByWorkshopId,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedWorkshopLoyaltyPrograms = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkshopLoyaltyPrograms);
export {connectedWorkshopLoyaltyPrograms as WorkshopLoyaltyPrograms};
