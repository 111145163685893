import React, {useState} from 'react';
import {Collapse} from 'reactstrap';
import styles from './CollapseSection.module.css';

const CollapseSection = props => {
  const [open, setOpen] = useState(
    props.defaultState !== undefined ? props.defaultState : true
  );
  return (
    <div className={styles.Wrapper} style={props.wrapperStyles}>
      <div className={styles.ColapsableSection}>
        <button
          className={open ? styles.ToggleRotate : styles.Toggle}
          onClick={() => setOpen(!open)}>
          <i
            className='fa fa-angle-down'
            style={{fontSize: '30px', color: '#2c5c9a'}}
          />
        </button>
        <p className={styles.Title}>{props.title}</p>
      </div>
      <Collapse
        isOpen={open}
        style={props.collapseStyles}
        className={
          props.withBorder ? styles.ColapseBoxWithBorder : styles.ColapseBox
        }>
        {props.children}
      </Collapse>
    </div>
  );
};

export default CollapseSection;
