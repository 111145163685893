/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
  validateRequiredNumberWithRange,
} from 'shared/components/form/common-validators/common-validators';
import ValidationMessageEnum, {isEmptyArray} from 'shared/utils/utils';
import {CarDataTypes} from './ValuationInquiryFormSchemaPage2';

export const validate = (values, props) => {
  let errors = {};
  errors.carDataType = validateRequiredFields(values.carDataType);
  errors.carBrand = validateRequiredFields(values.carBrand);
  errors.carModel = validateRequiredFields(values.carModel);
  errors.carModelOther = validateCarModelOther(values);
  errors.carProductionYear = validateRequiredFields(values.carProductionYear);
  errors.carFuelTypeId = validateRequiredFields(values.carFuelTypeId);
  errors.carEngineCapacityCm3 = !isFuelTypeElectricity(
    props.fuelTypes,
    values.carFuelTypeId
  )
    ? validateRequiredNumberWithRange(values.carEngineCapacityCm3, 1, 20000)
    : '';
  errors.carEnginePowerKw = validateRequiredNumberWithRange(
    values.carEnginePowerKw,
    1,
    9999
  );

  if (props.carDataTypeValue === CarDataTypes.EXISTING)
    errors.carName =
      validateRequiredFields(values.carName) ||
      validateCarExistence(values, props.userCars);

  return errors;
};

const validateCarModelOther = values => {
  if (values.carModel === 'other')
    return validateRequiredFieldsWithMaxLength(values.carModelOther, 60);
};

const isFuelTypeElectricity = (fuelTypes, carFuelTypeId) => {
  const fuelType = fuelTypes
    ? fuelTypes.find(fuelType => fuelType.fuelTypeId === carFuelTypeId)
    : null;
  const fuelTypeName = fuelType ? fuelType.name : '';
  return fuelTypeName === 'ELECTRICITY';
};

const validateCarExistence = (values, userCars) => {
  if (
    values.carDataType === CarDataTypes.EXISTING &&
    !isEmptyArray(userCars) &&
    !userCars.find(car => car.name === values.carName)
  )
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
};
