import React, { useState } from "react";
import TechnicalDataGasInstallation from "./technicalDataGasInstallation/TechnicalDataGasInstallation";
import TechnicalDataGasInstallationEdit from "./technicalDataGasInstallation/TechnicalDataGasInstallationEdit";
import DescriptionFault from "./descriptionFault/DescriptionFault";
import DescriptionFaultEdit from "./descriptionFault/DescriptionFaultEdit";
import AdditionalAssembly from "./additionalAssembly/AdditionalAssembly";
import AdditionalAssemblyEdit from "./additionalAssembly/AdditionalAssemblyEdit";
import CollapseSection from "shared/components/collapse-section/CollapseSection";
import styles from "./WarrantyInstalationData.module.css";
import { I18n } from "react-redux-i18n";

const WarrantyInstalationData = (props) => {
  const {
    editMode,
    setTechnicalDetailsJSON,
    setAdditionalInstallationDetailsJSON,
    setDescriptionJSON,
    isTechnicalDetailsJSONHasErrors,
    setIsTechnicalDetailsJSONHasErrors,
    isAdditionalInstallationDetailsJSONHasErrors,
    setIsAdditionalInstallationDetailsJSONHasErrors,
    isDescriptionJSONHasErrors,
    setIsDescriptionJSONHasErrors,
  } = props;

  const pressureTemperatureSensorNameTypes = [
    {
      label: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-04 PLUS",
      value: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-04 PLUS",
    },
    {
      label: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-02 PLUS",
      value: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-02 PLUS",
    },
    {
      label: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-02",
      value: "CZUJNIK CIŚNIENIA I TEMPERATURY PS-02",
    },
    {
      label: "CZUJNIK CIŚNIENIA PS-01/2",
      value: "CZUJNIK CIŚNIENIA PS-01/2",
    },
  ];

  return (
    <div className={styles.Container}>
      <CollapseSection
        title={I18n.t("warranty.technicalDataGasInstallation")}
        withBorder
      >
        {editMode ? (
          <TechnicalDataGasInstallationEdit
            setTechnicalDetailsJSON={setTechnicalDetailsJSON}
            isTechnicalDetailsJSONHasErrors={isTechnicalDetailsJSONHasErrors}
            pressureTemperatureSensorNameTypes={
              pressureTemperatureSensorNameTypes
            }
            setIsTechnicalDetailsJSONHasErrors={
              setIsTechnicalDetailsJSONHasErrors
            }
          />
        ) : (
          <TechnicalDataGasInstallation
            pressureTemperatureSensorNameTypes={
              pressureTemperatureSensorNameTypes
            }
          />
        )}
      </CollapseSection>
      <CollapseSection
        title={
          I18n.t("warranty.descriptionFault") +
          " " +
          I18n.t("warranty.descriptionFaultInfo")
        }
        withBorder
      >
        {editMode ? (
          <DescriptionFaultEdit
            setDescriptionJSON={setDescriptionJSON}
            isDescriptionJSONHasErrors={isDescriptionJSONHasErrors}
            setIsDescriptionJSONHasErrors={setIsDescriptionJSONHasErrors}
          />
        ) : (
          <DescriptionFault />
        )}
      </CollapseSection>
      <CollapseSection
        title={
          I18n.t("warranty.additionalAssembly") +
          " " +
          I18n.t("warranty.additionalAssemblyInfo")
        }
        withBorder
      >
        {editMode ? (
          <AdditionalAssemblyEdit
            setAdditionalInstallationDetailsJSON={
              setAdditionalInstallationDetailsJSON
            }
            isAdditionalInstallationDetailsJSONHasErrors={
              isAdditionalInstallationDetailsJSONHasErrors
            }
            setIsAdditionalInstallationDetailsJSONHasErrors={
              setIsAdditionalInstallationDetailsJSONHasErrors
            }
          />
        ) : (
          <AdditionalAssembly />
        )}
      </CollapseSection>
    </div>
  );
};

export default WarrantyInstalationData;
