/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {axiosService} from './axios.service';
import ValidationMessageEnum from '../utils/utils';

export const distributorService = {
  create,
  findAll,
  update,
  findOne,
  exist,
  updateAddress,
  updateDetails,
  updateOtherSection,
  loadDistributorData,
};

export const DISTRIBUTOR_BASE_API_PATH = '/distributor/distributors';

function create(values) {
  return axiosService.post(DISTRIBUTOR_BASE_API_PATH, values).then(response => {
    return response;
  });
}

function findAll() {
  return axiosService.get(DISTRIBUTOR_BASE_API_PATH).then(response => {
    return response;
  });
}

function update(values, distributorId) {
  return axiosService
    .put(`${DISTRIBUTOR_BASE_API_PATH}/${distributorId}`, values)
    .then(response => {
      return response;
    });
}

function updateAddress(values, distributorId) {
  return axiosService
    .put(`${DISTRIBUTOR_BASE_API_PATH}/${distributorId}/address`, values)
    .then(response => {
      return response;
    });
}

function updateDetails(values, distributorId) {
  return axiosService
    .put(`${DISTRIBUTOR_BASE_API_PATH}/${distributorId}/details`, values)
    .then(response => {
      return response;
    });
}

function updateOtherSection(values, distributorId) {
  return axiosService
    .put(`${DISTRIBUTOR_BASE_API_PATH}/${distributorId}/otherSection`, values)
    .then(response => {
      return response;
    });
}

function findOne(distributorId) {
  return axiosService
    .get(`${DISTRIBUTOR_BASE_API_PATH}/${distributorId}`)
    .then(response => {
      return response;
    });
}

function loadDistributorData(userId) {
  return axiosService
    .get(`${DISTRIBUTOR_BASE_API_PATH}/userId/${userId}`)
    .then(response => {
      return response;
    });
}

function exist(values) {
  const path = '/user/users/exist?email=';
  const email = values.userDto.email;
  const returnedPromise = Promise.resolve({
    userDto: {email: {key: ValidationMessageEnum.EMAIL_EXIST, params: {}}},
  });
  return axiosService.get(path + email).then(response => {
    if (response.data) return returnedPromise;
  });
}
