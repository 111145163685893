/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {privilegeConstants} from 'shared/constants/privilege.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import 'shared/styles/datatables.css';
import {isContentLoaded} from 'shared/utils/isContentLoaded';
import {becameObsolete} from 'shared/utils/sync';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import CreateAdminForm from './create-admin-form/CreateAdminForm';
import {SelectedUser} from './selected-user/SelectedUser';
import './users.css';

export const UserType = {
  WEB: ['ROLE_USER'],
  MOBILE: ['ROLE_THIN_USER'],
  ADMIN: ['ROLE_ADMIN'],
};

export const SidebarContent = {
  SELECTED_USER: 'SELECTED_USER',
  CREATE_ADMIN: 'CREATE_ADMIN',
};

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: '',
      rows: 10,
      userTypeActive: props.userTypes.WEB,
      sidebarContent: SidebarContent.SELECTED_USER,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.showDetails = this.showDetails.bind(this);
    this.showCreateAdmin = this.showCreateAdmin.bind(this);
    this.createAdmin = this.createAdmin.bind(this);
  }

  componentDidMount() {
    this.props.findAllByParams({roleNames: this.state.userTypeActive});
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.userTypeActiveChanged(prevState) ||
      becameObsolete(
        prevProps.usersDataSyncState,
        this.props.usersDataSyncState
      )
    ) {
      this.props.findAllByParams({roleNames: this.state.userTypeActive});

      if (this.dataTable)
        this.dataTable.onPageChange({first: 0, rows: this.state.rows});
    }
  }

  userTypeActiveChanged(prevState) {
    return prevState.userTypeActive !== this.state.userTypeActive;
  }

  showDetails(event) {
    const canUpdate = userService.isAdmin()
      ? true
      : userService.isDistributor() &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT);
    const component = <SelectedUser canUpdate={canUpdate} />;
    this.setState({sidebarContent: SidebarContent.SELECTED_USER});
    this.props.getUserDataById(event.data.id);
    setTimeout(
      () => this.props.showUpdateDataSideBar(component, event.data.email),
      100
    );
  }

  showCreateAdmin(event) {
    this.setState({sidebarContent: SidebarContent.CREATE_ADMIN});
    const component = <CreateAdminForm onSubmit={this.createAdmin} />;
    setTimeout(
      () =>
        this.props.showUpdateDataSideBar(
          component,
          I18n.t('createAdminForm.createAdmin')
        ),
      100
    );
  }

  createAdmin(values) {
    this.props.createAdmin(values);
  }

  renderCreateAdminBtn() {
    return (
      <Button
        id='add-admin-btn'
        className='main-btn data-table-button'
        onClick={this.showCreateAdmin}>
        <Translate value={'users.createAdmin'} />
      </Button>
    );
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {Object.keys(this.props.userTypes).map((key, index) =>
          this.renderTile(key, index, this.getTileInfo(key).icon)
        )}
      </div>
    );
  }

  getTileInfo(key) {
    const userType = key ? UserType[key] : this.state.userTypeActive;
    switch (userType.toString()) {
      case UserType.WEB.toString():
        return {
          title: 'users.webUsersBtn',
          icon: 'fa-desktop',
        };
        break;
      case UserType.MOBILE.toString():
        return {
          title: 'users.mobileUsersBtn',
          icon: 'fa-mobile',
        };
        break;
      case UserType.ADMIN.toString():
        return {
          title: 'users.adminUsersBtn',
          icon: 'fa-cogs',
        };
        break;
    }
  }

  renderTile(key, index, iconName) {
    return (
      <div
        key={key}
        id={`${key.toLowerCase()}-tile`}
        className={
          'subsection-tile shadow col-sm col-12 ' +
          this.resolveCssClasses(key, index)
        }
        onClick={e =>
          this.setState({userTypeActive: this.props.userTypes[key]})
        }>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={`users.${key.toLowerCase()}UsersBtn`} />
      </div>
    );
  }

  resolveCssClasses(key, index) {
    let classes = '';
    if (this.state.userTypeActive === this.props.userTypes[key])
      classes = classes + 'subsection-tile-active ';
    if (index === 0) classes = classes + 'mr-md-3 ';
    else if (index + 1 === Object.keys(this.props.userTypes).length)
      classes = classes + 'ml-md-3 ';
    else classes = classes + 'mx-sm-3 my-sm-0 my-3';

    classes = classes + isContentLoaded(this.props.isFetching);

    return classes;
  }

  onRowsChange(e) {
    this.setState({rows: e.value});
    this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        onChange={e => this.onRowsChange(e)}
      />
    );
  }

  render() {
    const usersTableData = this.props.usersData.map((user, index) => ({
      index: index + 1,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      status: user.enabled ? I18n.t('users.active') : I18n.t('users.blocked'),
    }));
    const sectionTitle = this.getTileInfo() ? this.getTileInfo().title : '';

    const header = (
      <div className='row'>
        <div className='col-sm-1 col-1 mr-auto order-1' />
        <div className='col-sm-4 col-6 px-1 order-3'>
          <Input
            type='search'
            placeholder={I18n.t('users.searchPlaceholder')}
            className='global-filter m-1'
            onInput={e => this.setState({globalFilter: e.target.value})}
          />
        </div>
      </div>
    );

    return (
      <div>
        {this.renderTiles()}
        <ContentWrapper title={sectionTitle}>
          <Loadable active={this.props.isFetching} spinner>
            <div className='col'>
              <DataTable
                value={usersTableData}
                paginator={true}
                rows={this.state.rows}
                ref={this.setDatatableRef}
                header={header}
                className='data-table-padding-class'
                responsive={true}
                globalFilter={this.state.globalFilter}
                onRowClick={this.showDetails}
                emptyMessage={I18n.t('users.emptyPlaceholder')}
                paginatorLeft={this.renderRowsDropdown()}
                paginatorRight={
                  this.state.userTypeActive === UserType.ADMIN
                    ? this.renderCreateAdminBtn()
                    : null
                }>
                <Column
                  field='index'
                  className='lp-col'
                  header={I18n.t('users.lp')}
                  sortable={true}
                />
                <Column
                  field='firstName'
                  header={I18n.t('users.firstName')}
                  sortable={true}
                />
                <Column
                  field='lastName'
                  header={I18n.t('users.lastName')}
                  sortable={true}
                />
                <Column
                  field='email'
                  className='email-col'
                  header={I18n.t('users.email')}
                  sortable={true}
                />
                <Column
                  field='status'
                  header={I18n.t('users.status')}
                  sortable={true}
                />
              </DataTable>
            </div>
          </Loadable>
        </ContentWrapper>
      </div>
    );
  }
}

Users.propTypes = {
  userTypes: PropTypes.object.isRequired,
};

Users.defaultProps = {
  userTypes: UserType,
};

function mapStateToProps(state) {
  const {i18n, user} = state;
  const {isFetching, usersDataSyncState} = user;
  const usersData = user.usersData
    ? user.usersData.filter(userData => userData.id !== userService.getUserId())
    : [];

  return {i18n, usersData, isFetching, usersDataSyncState};
}

const mapDispatchToProps = {
  findAllByParams: userActions.findAllByParams,
  getUserDataById: userActions.getUserDataById,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  createAdmin: userActions.createAdmin,
};

const connectedUsers = connect(mapStateToProps, mapDispatchToProps)(Users);
export {connectedUsers as Users};
