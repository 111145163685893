/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {warrantyConstants} from 'shared/constants/warranty.constants';

export function warrantyReducer(
  state = {warranties: [], periodicInspections: []},
  action
) {
  switch (action.type) {
    case warrantyConstants.WARRANTY_REQUEST_START:
      return {...state, isFetching: true};

    case warrantyConstants.WARRANTY_REQUEST_END:
      return {...state, isFetching: false};

    case warrantyConstants.WARRANTY_REQUEST_CLEAR:
      return {...state, success: null, error: false, errorMessage: null};

    case warrantyConstants.GET_WARRANTIES:
      return {...state, warranties: action.payload};

    case warrantyConstants.INITIALIZE_WARRANTY:
    case warrantyConstants.EDIT_WARRANTY:
      return {...state, initializedWarranty: action.payload};
    case warrantyConstants.GET_PERIODIC_INSPECTIONS:
      return {...state, periodicInspections: action.payload};
    case warrantyConstants.PUSH_WARRANTY_TO_THE_USER_LIST:
      return {...state, warranties: [...state.warranties, action.payload]}
    case warrantyConstants.DELETE_INITIALIZED_WARRANTY:{
      return {...state, initializedWarranty: null, periodicInspections: []};
    }

    case warrantyConstants.FILL_INSPECTION: {
      const periodicInspections = state.periodicInspections;
      const filledInspection = action.payload;
      const inspectionExists = periodicInspections.find(
        insp => insp.inspectionIndex === filledInspection.inspectionIndex
      );

      if (inspectionExists) {
        const index = periodicInspections.findIndex(
          insp => insp.inspectionIndex === filledInspection.inspectionIndex
        );
        periodicInspections[index] = filledInspection;

        return {...state, periodicInspections};
      }
      return state;
    }
    default:
      return state;
  }
}
