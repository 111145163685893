/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {CountryField} from 'shared/components/form/country-field/hook-form-version/CountryField';
import {StateField} from 'shared/components/form/state-field/hook-form-version/StateField';
import {Button} from 'reactstrap';
import {I18n, Translate} from 'react-redux-i18n';
import {Controller, useForm} from 'react-hook-form';
import {RenderField} from 'shared/components/form/text-field/hook-form-version/RenderField';
import {RenderDropdown} from 'shared/components/form/dropdown/hook-form-version/RenderDropdown';
import {useDispatch, useSelector} from 'react-redux';
import {workshopActions} from 'shared/actions/workshop.actions';
import {
  mapWorkshopsToLabelValueObjects,
  mapCarsToLabelValueObjects,
} from 'shared/components/code-scanner/utils';
import {carActions} from 'shared/actions/car.actions';
import {userService} from 'shared/services/user.service';
import {capitalize, isEmpty} from 'shared/utils/utils';
import {byCountry} from 'country-code-lookup';
import PhoneNumber from '../../../../shared/components/form/phone-number/PhoneNumber';

const CodeScannerForm = props => {
  const {onSubmit, handleCancel} = props;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    setError,
    formState: {errors},
    control,
  } = useForm();
  const dispatch = useDispatch();
  const userCars = useSelector(state => state.car.userCars);
  const workshops = useSelector(state => state.workshop.workshops);

  const countryField = 'country';
  const addressField = 'address';
  const workshopField = 'workshop';
  const newWorkshopField = 'newWorkshop';
  const postCodeField = 'postCode';
  const cityField = 'city';
  const phoneField = 'phone';
  const emailField = 'email';
  const carField = 'car';
  const carMake = 'carMake';
  const carModel = 'carModel';
  const productionYear = 'productionYear';
  const engineCapacity = 'engineCapacity';
  const barcodeFieldBox = 'barcodeBox';
  const barcodeFieldControler = 'barcodeController';
  const barcodeFieldInjector = 'barcodeInjector';
  const stateField = 'state';

  const workshopData = watch(workshopField);
  const carData = watch(carField);

  useEffect(() => {
    dispatch(workshopActions.fetchWorkshops());
    dispatch(carActions.findCarsByUserId(userService.getUserId()));
  }, []);

  const mappedUserCars = [
    ...mapCarsToLabelValueObjects(userCars),
    {label: I18n.t('barcodes.other'), value: {}},
  ];
  const mappedWorkshops = [
    ...mapWorkshopsToLabelValueObjects(workshops),
    {label: I18n.t('barcodes.other'), value: {}},
  ];

  useEffect(() => {
    if (workshopData) {
      setValue(
        countryField,
        byCountry(capitalize(workshopData.address?.country))?.iso2
      );
      setValue(stateField, workshopData.address?.state);
      setValue(addressField, workshopData.address?.streetAddress);
      setValue(postCodeField, workshopData.address?.postalCode);
      setValue(cityField, workshopData.address?.city);
      clearErrors([stateField, addressField, postCodeField, cityField]);
      if (
        workshopData.workshopDetails &&
        workshopData.workshopDetails.phoneNumber.phoneNumber
      ) {
        setValue(phoneField, workshopData.workshopDetails.phoneNumber);
      }
      if (workshopData.email) {
        setValue(emailField, workshopData.email);
      }
    }
  }, [workshopData]);

  useEffect(() => {
    if (carData) {
      setValue(carModel, carData.model);
      setValue(carMake, carData.brand);
      setValue(productionYear, carData.productionYear);
      setValue(engineCapacity, carData.engineCapacityCm3);
      clearErrors([carModel, carMake, productionYear, engineCapacity]);
    }
  }, [carData]);

  const validateBarcodes = data => {
    const {barcodeBox, barcodeController, barcodeInjector} = data;
    if (
      [barcodeBox, barcodeController, barcodeInjector].every(
        code => code === ''
      )
    ) {
      [
        {type: 'manual', name: barcodeFieldBox},
        {type: 'manual', name: barcodeFieldControler},
        {
          type: 'manual',
          name: barcodeFieldInjector,
          message: I18n.t('validation.minOneCode'),
        },
      ].forEach(({name, type, message}) => setError(name, {type, message}));
      return Promise.reject();
    }
    clearErrors([barcodeFieldInjector]);
    return Promise.resolve(data);
  };

  return (
    <div className='car-form px-5 pb-5'>
      <form
        onSubmit={handleSubmit(data =>
          validateBarcodes(data).then(data => onSubmit(data))
        )}>
        <RenderDropdown
          options={mappedWorkshops}
          {...register(workshopField, {required: true})}
          value={watch(workshopField)}
          error={errors[workshopField]}
          label={I18n.t('workshops.workshopDeletedNotificationStart')}
          placeholder={I18n.t('workshops.searchPlaceholder')}
          required
        />
        {isEmpty(workshopData) && !!workshopData && (
          <RenderField
            {...register(newWorkshopField, {
              required: true,
              maxLength: 30,
            })}
            value={watch(newWorkshopField)}
            label={'Nazwa warsztatu'}
            placeholder={I18n.t('workshops.workshopDeletedNotificationStart')}
            error={errors[newWorkshopField]}
            required
            type='text'
          />
        )}

        {isEmpty(workshopData) ? (
          <>
            <CountryField register={register} watch={watch} errors={errors} />
            <StateField register={register} watch={watch} errors={errors} />
          </>
        ) : (
          <>
            <RenderField
              {...register(countryField, {required: true})}
              label={I18n.t('country.countryLabel')}
              readOnly
            />
            <RenderField
              {...register(stateField, {required: true})}
              label={I18n.t('state.stateLabel')}
              readOnly
            />
          </>
        )}

        <RenderField
          {...register(addressField, {required: true, maxLength: 30})}
          value={watch(addressField)}
          error={errors[addressField]}
          label={I18n.t('userProfileData.streetAddressLabel')}
          placeholder={I18n.t('userProfileData.streetAddressLabel')}
          required
          type='text'
          readOnly={!isEmpty(workshopData)}
        />

        <RenderField
          {...register(postCodeField, {required: true, maxLength: 10})}
          value={watch(postCodeField)}
          error={errors[postCodeField]}
          label={I18n.t('userProfileData.postalCodeLabel')}
          placeholder={I18n.t('userProfileData.postalCodeLabel')}
          required
          type='text'
          readOnly={!isEmpty(workshopData)}
        />
        <RenderField
          {...register(cityField, {required: true})}
          value={watch(cityField)}
          error={errors[cityField]}
          label={I18n.t('userProfileData.cityLabel')}
          placeholder={I18n.t('userProfileData.cityLabel')}
          required
          type='text'
          readOnly={!isEmpty(workshopData)}
        />
        {((workshopData &&
          workshopData.workshopDetails &&
          workshopData.workshopDetails.phoneNumber.phoneNumber) ||
          isEmpty(workshopData)) && (
          <Controller
            control={control}
            name={phoneField}
            render={({field: {value, onChange}}) => (
              <PhoneNumber
                {...register(phoneField)}
                input={{
                  onChange: onChange,
                  value: value,
                }}
                meta={{error: errors[phoneField]}}
                label={I18n.t('workshopProfile.phoneNumberLabel')}
                inputReadOnly={!isEmpty(workshopData)}
                prefixReadOnly={!isEmpty(workshopData)}
              />
            )}
            defaultValue={{phoneNumberPrefix: null, phoneNumber: null}}
          />
        )}
        {((workshopData && workshopData.email) || isEmpty(workshopData)) && (
          <RenderField
            {...register(emailField)}
            value={watch(emailField)}
            error={errors[emailField]}
            label={I18n.t('createWorkshop.emailLabel')}
            type='text'
            readOnly={!isEmpty(workshopData)}
          />
        )}
        <RenderDropdown
          {...register(carField, {required: true})}
          value={watch(carField)}
          error={errors[carField]}
          options={mappedUserCars}
          label={I18n.t('car.car')}
          placeholder={I18n.t('car.searchCar')}
          required
        />

        <RenderField
          {...register(carMake, {required: true})}
          value={watch(carMake)}
          error={errors[carMake]}
          label={I18n.t('car.brandLabel')}
          placeholder={I18n.t('car.brandPlaceholder')}
          required
          readOnly={!isEmpty(carData)}
        />

        <RenderField
          {...register(carModel, {required: true})}
          value={watch(carModel)}
          error={errors[carModel]}
          label={I18n.t('car.modelLabel')}
          placeholder={I18n.t('car.modelPlaceholder')}
          required
          readOnly={!isEmpty(carData)}
        />
        <RenderField
          {...register(productionYear, {
            required: true,
            min: 1900,
            max: new Date().getFullYear(),
            pattern: /^[0-9]*$/g,
          })}
          value={watch(productionYear)}
          error={errors[productionYear]}
          label={I18n.t('car.productionYearLabel')}
          placeholder={I18n.t('car.productionYearPlaceholder')}
          required
          readOnly={!isEmpty(carData)}
        />
        <RenderField
          {...register(engineCapacity, {
            required: true,
            min: 0,
            max: 20000,
            pattern: /^[0-9]*$/g,
          })}
          value={watch(engineCapacity)}
          error={errors[engineCapacity]}
          label={I18n.t('car.engineCapacityCm3Label')}
          placeholder={I18n.t('car.engineCapacityCm3Label')}
          required
          readOnly={!isEmpty(carData)}
        />

        <RenderField
          {...register(barcodeFieldBox)}
          value={watch(barcodeFieldBox)}
          error={errors[barcodeFieldBox]}
          label={I18n.t('barcodes.boxCode')}
          placeholder={I18n.t('barcodes.barcode')}
          focusEffect={() =>
            clearErrors([
              barcodeFieldInjector,
              barcodeFieldControler,
              barcodeFieldBox,
            ])
          }
          type='text'
        />

        <RenderField
          {...register(barcodeFieldControler)}
          value={watch(barcodeFieldControler)}
          error={errors[barcodeFieldControler]}
          label={I18n.t('barcodes.controllerCode')}
          placeholder={I18n.t('barcodes.barcode')}
          focusEffect={() =>
            clearErrors([
              barcodeFieldInjector,
              barcodeFieldControler,
              barcodeFieldBox,
            ])
          }
          type='text'
        />

        <RenderField
          {...register(barcodeFieldInjector)}
          value={watch(barcodeFieldInjector)}
          error={errors[barcodeFieldInjector]}
          label={I18n.t('barcodes.injectorCode')}
          placeholder={I18n.t('barcodes.barcode')}
          focusEffect={() =>
            clearErrors([
              barcodeFieldInjector,
              barcodeFieldControler,
              barcodeFieldBox,
            ])
          }
          type='text'
        />

        {errors[barcodeFieldInjector] && (
          <p
            className='text-danger'
            style={{marginTop: '16px', textAlign: 'center'}}>
            {errors[barcodeFieldInjector].message}
          </p>
        )}

        <Button
          id='car-form-cancel-btn'
          className='main-btn'
          onClick={handleCancel}>
          <Translate value={'car.cancelBtn'} />
        </Button>
        <Button id='car-form-submit-btn' className='main-btn' type='submit'>
          <Translate value={'barcodes.submit'} />
        </Button>
      </form>
    </div>
  );
};

CodeScannerForm.propTypes = {
  onSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CodeScannerForm;
