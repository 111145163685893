/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const orderConstants = {
  ORDER_REQUEST_START: 'ORDER_REQUEST_START',
  ORDER_REQUEST_END: 'ORDER_REQUEST_END',

  LOAD_VALUATION_INQUIRIES: 'LOAD_VALUATION_INQUIRIES',
  LOAD_VALUATION_INQUIRY: 'LOAD_VALUATION_INQUIRY',
  LOAD_VALUATION_INQUIRY_DRAFT: 'LOAD_VALUATION_INQUIRY_DRAFT',

  LOAD_VALUATION_INQUIRY_SERVICES: 'LOAD_VALUATION_INQUIRY_SERVICES',
  CLEAR_VALUATION_INQUIRY_SERVICES: 'CLEAR_VALUATION_INQUIRY_SERVICES',

  LOAD_VALUATIONS: 'LOAD_VALUATIONS',
  LOAD_VALUATION: 'LOAD_VALUATION',
  LOAD_VALUATION_DRAFT: 'LOAD_VALUATION_DRAFT',

  LOAD_VALUATION_DIRECT_CLIENT_DRAFT: 'LOAD_VALUATION_DIRECT_CLIENT_DRAFT',

  CLEAR_VALUATION_INQUIRY: 'CLEAR_VALUATION_INQUIRY',
  GET_VALUATION_INQUIRY_RESPONSE_FAIL_MSG:
    'GET_VALUATION_INQUIRY_RESPONSE_FAIL_MSG',
  CLEAR_VALUATION_INQUIRY_RESPONSE_FAIL_MSG:
    'CLEAR_VALUATION_INQUIRY_RESPOsNE_FAIL_MSG',

  CLEAR_VALUATION: 'CLEAR_VALUATION',
  CLEAR_VALUATIONS: 'CLEAR_VALUATIONS',

  LOAD_ORDERS: 'LOAD_ORDERS',
  LOAD_ORDER: 'LOAD_ORDER',
  CLEAR_ORDER: 'CLEAR_ORDER',
  REFRESH_ORDERS_DATA: 'REFRESH_ORDERS_DATA',

  LOAD_ORDER_MIN_CANCEL_DATE: 'LOAD_ORDER_MIN_CANCEL_DATE',
};
