import React, {useEffect} from 'react';
import styles from '../WarrantyInstalationData.module.css';
import {I18n} from 'react-redux-i18n';
import {useForm, Controller} from 'react-hook-form';
import RenderTextArea from 'shared/components/form/text-area/hook-form-version/RenderTextArea';
import {
  descriptionValidator,
} from "../../warranty-edit//validators";
import useWarrantyBookLocalStorageGetValue from 'shared/hooks/useWarrantyBookLocalStorageGetValue';

const DescriptionFaultEdit = props => {
  const {
    control,
    formState: {errors, isValid},
    clearErrors,
    watch,
    setValue,
    getValues
  } = useForm({ mode: "onChange", criteriaMode: "all" });
  const descriptionField = 'description';

  const description = useWarrantyBookLocalStorageGetValue("description");
 
  useEffect(() => {
    if (description) {
      setValue(descriptionField, description? description: "");
    }
  }, []);

  useEffect(() => {
    const subscription = watch(value => props.setDescriptionJSON(value.description));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isValid) {
      props.setIsDescriptionJSONHasErrors(false);
      clearErrors();
    }
  }, [props.isDescriptionJSONHasErrors, isValid]);

  useEffect(() => {
    const setErrorMessage = (field) => {
      const errorField = descriptionValidator(getValues()).errors[field];
      errorField
        ? setError(field, {
            type: errorField.key.substring(errorField.key.indexOf(".") + 1),
          })
        : null;
    };
    if (props.isDescriptionJSONHasErrors) {
      setErrorMessage(descriptionField);
    }
  }, [props.isDescriptionJSONHasErrors]);

  return (
    <div className={styles.SecondSegmentCollapsedContainer}>
      <div className={styles.SegmentTextStyle}>
        <Controller
          control={control}
          name={descriptionField}
          rules={{ maxLength: 10000 }}
          render={({ field: { value, onChange } }) => (
            <RenderTextArea
              rows={5}
              error={errors[descriptionField]}
              label={I18n.t('warranty.descriptionFaultField')}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
    </div>
  );
};

export default DescriptionFaultEdit;
