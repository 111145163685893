/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const orderRatingService = {
  createOrderRating,
  findAllOwnedForTargetCustomer,
  existsOwnedForOrder,
};

export const ORDER_RATING_API_BASE_PATH = '/order/order-ratings';

function createOrderRating(orderId, rating) {
  return axiosService
    .post(ORDER_RATING_API_BASE_PATH, {orderId, ...rating})
    .then(response => {
      return response;
    });
}

function findAllOwnedForTargetCustomer(customerId) {
  return axiosService
    .get(`${ORDER_RATING_API_BASE_PATH}/target-customer/${customerId}`)
    .then(response => {
      return response;
    });
}

function existsOwnedForOrder(orderId) {
  return axiosService
    .get(`${ORDER_RATING_API_BASE_PATH}/exists?orderId=${orderId}`)
    .then(response => {
      return response;
    });
}
