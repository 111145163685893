/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {orderActions} from '../../../actions/order.actions';
import {userService} from '../../../services/user.service';
import OrdersTable from '../orders-table/OrdersTable';
import Loadable from 'react-loading-overlay';
import {updateDataSideBarConstants} from '../../../constants/update-data-side-bar.constants';
import {OrderDetails} from '../order-details/OrderDetails';
import {becameObsolete} from '../../../utils/sync';
import ContentWrapper from '../../content-wrapper/ContentWrapper';

class OrdersForRealization extends React.Component {
  constructor(props) {
    super(props);

    this.showOrderDetails = this.showOrderDetails.bind(this);
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.ordersDataSyncState,
        this.props.ordersDataSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.findAllOrdersForRealizationByUserId(userService.getUserId());
  }

  showOrderDetails(row) {
    const {
      showUpdateDataSideBar,
      shouldShowWorkshopStatusAlert,
      hideUpdateDataSideBar,
      showWorkshopDetails,
      showUserDetails,
    } = this.props;
    const details = (
      <OrderDetails
        orderId={row.data.id}
        shouldShowWorkshopStatusAlert={shouldShowWorkshopStatusAlert}
        onFetchError={hideUpdateDataSideBar}
        showWorkshopDetails={showWorkshopDetails}
        showUserDetails={showUserDetails}
      />
    );
    const title =
      I18n.t('orderDetails.detailsHeader') + ' ' + row.data.orderNumber;
    setTimeout(() => showUpdateDataSideBar(details, title), 10);
  }

  render() {
    const {isFetching, orders, showUserDetails} = this.props;
    return (
      <ContentWrapper title='orders.ordersForRealizationBtn'>
        <Loadable active={isFetching} spinner>
          <div className='col'>
            <OrdersTable
              orders={orders}
              onRowClick={this.showOrderDetails}
              showCustomerDetails={showUserDetails}
              notConfirmedOrders={true}
            />
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

OrdersForRealization.propTypes = {
  shouldShowWorkshopStatusAlert: PropTypes.bool,
  findAllOrdersForRealizationByUserId: PropTypes.func.isRequired,
  showUpdateDataSideBar: PropTypes.func.isRequired,
  hideUpdateDataSideBar: PropTypes.func.isRequired,
  showWorkshopDetails: PropTypes.bool,
  showUserDetails: PropTypes.bool,
};

function mapStateToProps(state) {
  const {order} = state;
  const {isFetching, orders, ordersDataSyncState} = order;

  return {isFetching, orders, ordersDataSyncState};
}

const mapDispatchToProps = {
  findAllOrdersForRealizationByUserId:
    orderActions.findAllOrdersForRealizationByUserId,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedOrdersForRealization = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersForRealization);
export {connectedOrdersForRealization as OrdersForRealization};
