/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {GenericModal} from '../../modal/GenericModal';
import {Fragment} from 'react';
import {Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {RatingList} from '../rating-list/RatingList';

export class RatingListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isOpen: false};

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({isOpen: true});
    this.props.onOpen();
  }

  close() {
    this.setState({isOpen: false});
    this.props.onClose();
  }

  render() {
    const {titleLabelKey, openBtnLabelKey, ratings} = this.props;
    const content = (
      <Fragment>
        <RatingList ratings={ratings} />
        <div className='text-sm-right text-center mt-3'>
          <Button
            id='close-rating-list-modal'
            className='main-btn'
            onClick={this.close}>
            <Translate value='rating.closeBtn' />
          </Button>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <GenericModal
          content={content}
          title={titleLabelKey}
          isOpen={this.state.isOpen}
        />

        <Button
          id='open-rating-list-modal'
          className='main-btn fixed-width-big-btn'
          onClick={this.open}>
          <Translate value={openBtnLabelKey} />
        </Button>
      </Fragment>
    );
  }
}

RatingListModal.propType = {
  titleLabelKey: PropTypes.string,
  openBtnLabelKey: PropTypes.string,
  ratings: PropTypes.array,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

RatingListModal.defaultProps = {
  ratings: [],
  onOpen: () => {},
  onClose: () => {},
};
