/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Field} from 'redux-form';
import renderField from '../../form/text-field/RenderField';
import {RenderTextArea} from '../../form/text-area/RenderTextArea';
import {Button} from 'reactstrap';
import normalizeNumber from '../../form/normalizer/number-normalizer';
import {ConfirmModal} from '../../modal/ConfirmModal';

export class LoyaltyProgramForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {confirmDeleteModalOpen: false};
  }

  renderFields() {
    return (
      <Fragment>
        <Field
          name='title'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('loyaltyProgram.title')}
          icon='fa fa-briefcase'
        />

        <Field
          name='points'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('loyaltyProgram.points')}
          icon='fa fa-star'
          normalize={normalizeNumber}
        />

        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          required={false}
          label={I18n.t('loyaltyProgram.description')}
          icon='fa fa-info'
        />
      </Fragment>
    );
  }

  renderButtons() {
    const {invalid, handleCancel} = this.props;

    return (
      <Fragment>
        <Button
          id='loyalty-program-form-submit-btn'
          className='main-btn fixed-width-btn'
          type='submit'
          disabled={invalid}>
          <Translate value={'loyaltyProgram.saveBtn'} />
        </Button>

        <Button
          id='loyalty-program-form-cancel-btn'
          className='main-btn fixed-width-btn'
          onClick={handleCancel}>
          <Translate value={'loyaltyProgram.cancelBtn'} />
        </Button>

        {this.renderDeleteSection()}
      </Fragment>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete()}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      this.props.handleDelete && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmDeleteModal()}
          <Button
            id='loyalty-program-form-delete-btn'
            className='btn-danger fixed-width-btn'
            size='sm'
            onClick={() => this.setState({confirmDeleteModalOpen: true})}>
            <Translate value={'loyaltyProgram.deleteBtn'} />
          </Button>
        </div>
      )
    );
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className='loyalty-program-form p-5'>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          <div className='text-sm-right text-center mt-2'>
            {this.renderButtons()}
          </div>
        </form>
      </div>
    );
  }
}

LoyaltyProgramForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  invalid: PropTypes.bool,
};
