import React from 'react';
import styles from './PeriodicInspections.module.css';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {I18n} from 'react-redux-i18n';
import {userService} from 'shared/services/user.service';
import {dateUtils} from 'shared/utils/date.utils';

const PeriodicInspectionsReadOnly = ({inspectionData, setEditMode}) => {
  return (
    <div className={styles.SegmentCollapsedContainer}>
      <div className={styles.FirstPart}>
        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.inspectionDate'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.inspectionDate? 
              dateUtils.formatDate(inspectionData.inspectionDate, 'YYYY-MM-DD'): ''}
          </span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.millage'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>{inspectionData.mileage}</span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.nextInspectionDate'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.nextInspectionDate?
              dateUtils.formatDate(inspectionData.nextInspectionDate, 'YYYY-MM-DD'): ''}
          </span>
        </div>
      </div>

      <div className={styles.SecondPart}>
        {inspectionData.inspectionIndex === 0 && (
          <div className={styles.Label}>
            <p className={styles.Row}>
              {I18n.t('warranty.sectionVII.tankInspection')}
            </p>
            {inspectionData.tankInspection ? (
              <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
            ) : (
              <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
            )}
            <p className={styles.Description}>
              {inspectionData.tankInspectionDescription ?? ' '}
            </p>
          </div>
        )}

        <div className={styles.Label}>
          <p className={styles.Row}>
            {I18n.t('warranty.sectionVII.injectionInspection')}
          </p>
          {inspectionData.injectionInspection ? (
            <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
          ) : (
            <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
          )}
          <p className={styles.Description}>
            {inspectionData.injectionInspectionDescription ?? ' '}
          </p>
        </div>

        <div className={styles.Label}>
          <p className={styles.Row}>
            {I18n.t('warranty.sectionVII.hermeticInspection')}
          </p>
          {inspectionData.hermeticInspection ? (
            <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
          ) : (
            <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
          )}
          <p className={styles.Description}>
            {inspectionData.hermeticInspectionDescription ?? ' '}
          </p>
        </div>

        <div className={styles.Label}>
          <p className={styles.Row}>
            {I18n.t('warranty.sectionVII.stagControllerInspection')}
          </p>
          {inspectionData.stagControllerInspection ? (
            <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
          ) : (
            <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
          )}
          <p className={styles.Description}>
            {inspectionData.stagControllerInspectionDescription ?? ' '}
          </p>
        </div>

        {inspectionData.inspectionIndex !== 0 && (
          <div className={styles.Label}>
            <p className={styles.Row}>
              {I18n.t('warranty.sectionVII.liquidPhaseFilter')}
            </p>
            {inspectionData.liquidPhaseFilter ? (
              <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
            ) : (
              <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
            )}
            <p className={styles.Description}>
              {inspectionData.liquidPhaseFilterDescription ?? ' '}
            </p>
          </div>
        )}
        {inspectionData.inspectionIndex !== 0 && (
          <div className={styles.Label}>
            <p className={styles.Row}>
              {I18n.t('warranty.sectionVII.gasPhaseFilter')}
            </p>
            {inspectionData.gasPhaseFilter ? (
              <p className={styles.Yes}>{I18n.t('warranty.sectionVII.yes')}</p>
            ) : (
              <p className={styles.Not}>{I18n.t('warranty.sectionVII.no')}</p>
            )}
            <p className={styles.Description}>
              {inspectionData.gasPhaseFilterDescription ?? ' '}
            </p>
          </div>
        )}
      </div>

      <div className={styles.ThirdPart}>
        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.workshopWorker'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.workshopWorker}
          </span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.workshopName'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.workshopName}
          </span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.address'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>{inspectionData.address}</span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.phoneNumber'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.phoneNumber}
          </span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.nip'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>{inspectionData.nip}</span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.regon'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>{inspectionData.regon}</span>
        </div>

        <div className={styles.LabelContainer}>
          <Translate
            value={'warranty.sectionVII.workshopWorkerEmail'}
            className={styles.LabelName}
            style={{justifyContent: 'flex-start'}}
          />
          <span className={styles.LabelValue}>
            {inspectionData.workshopWorkerEmail}
          </span>
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {userService.isWorkshop() && (
          <Button className='btn main-btn' onClick={setEditMode}>
            {I18n.t('warranty.sectionVII.edit')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PeriodicInspectionsReadOnly;
