/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import { CodeScanner } from "layout/workshop/code-scanner/CodeScanner";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { subscriptionActions } from "shared/actions/subscription.actions";
import { userActions } from "shared/actions/user.actions";
import { workshopActions } from "shared/actions/workshop.actions";
import {
  workshopConstants,
  WorkshopType,
} from "shared/constants/workshop.constants";
import { withSubscriptionRequired } from "shared/hoc/subscription-required/SubscriptionRequiredHoc";
import { userService } from "shared/services/user.service";
import { getOrEmptyObj } from "shared/utils/utils";
import AcceptInvite from "./accept-invite/AcceptInvite";
import NeededPartsInquiry from "./needed-parts-inquiry/NeededPartsInquiry";
import { Orders } from "./orders/Orders";
import Promotions from "./promotions/Promotions";
import { Schedule } from "./schedule/Schedule";
import { SettingsContainer } from "./settings/SettingsContainer";
import { Subscriptions } from "./subscriptions/Subscriptions";
import ValuationDirectClients from "./valuation-direct-clients/ValuationDirectClients";
import { Valuations } from "./valuations/Valuations";
import { Workers } from "./workers/Workers";
import WorkshopProfile from "./workshop-profile/WorkshopProfile";
import { WorkshopServicesContainer } from "./workshops-services/WorkshopServicesContainer";
import WarrantyBookList from "../../warranty/warranty-books-list/WarrantyBookList";
import WarrantyEdit from "../../warranty/warranty-edit/WarrantyEdit";
import { WorkshopLoyaltyProgram } from "./workshop-loyalty-program/WorkshopLoyaltyProgram";

export const ValuationsSr = withSubscriptionRequired(Valuations);
export const OrdersSr = withSubscriptionRequired(Orders);
export const WorkshopServicesContainerSr = withSubscriptionRequired(
  WorkshopServicesContainer
);
export const PromotionsSr = withSubscriptionRequired(Promotions);
export const AcceptInviteSr = withSubscriptionRequired(AcceptInvite);
export const NeededPartsInquirySr =
  withSubscriptionRequired(NeededPartsInquiry);
export const ValuationDirectClientsSr = withSubscriptionRequired(
  ValuationDirectClients
);
export const SettingsContainerSr = withSubscriptionRequired(SettingsContainer);
export const ScheduleSr = withSubscriptionRequired(Schedule);
export const WorkersSr = withSubscriptionRequired(Workers);

class WorkshopContainer extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.loadSubscription = this.loadSubscription.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.dispatch(
      workshopActions.findWorkshopByUserId(
        userService.getUserId(),
        workshopConstants.LOAD_LOGGED_WORKSHOP_DATA
      )
    );
    this.props.dispatch(
      userActions.getUserDataByIdAndCheckFirstTimeLogin(userService.getUserId())
    );
    this.loadSubscription();
  }

  loadSubscription() {
    this.props.dispatch(
      subscriptionActions.getSubscriptionEndDate(userService.getUserId())
    );
  }

  render() {
    const { workshopType, subscriptionEndDate, isFetchingSubscription } =
      this.props;

    const subscriptionProps = {
      isFetching: isFetchingSubscription,
      onEnter: this.loadSubscription,
      subscriptionEndDate: subscriptionEndDate,
      bypassSubscriptionCheck: WorkshopType.PARTNER === workshopType,
    };

    return (
      <div>
        <Switch>
          {
            // TODO: Michal Balazi change on 8th May 2023 - removed routes
            /*
            <Route
              path='/workshop/valuations'
              render={props => <ValuationsSr {...props} {...subscriptionProps} />}
            />
                      <Route
            path='/workshop/orders'
            render={props => <OrdersSr {...props} {...subscriptionProps} />}
          />

          <Route
            exact
            path='/workshop/services'
            render={props => (
              <WorkshopServicesContainerSr {...props} {...subscriptionProps} />
            )}
          />

          <Route
            exact
            path='/workshop/promotions'
            render={props => <PromotionsSr {...props} {...subscriptionProps} />}
          />
            */
          }

          <Route
            exact
            path="/workshop" // workshop-loyalty-program
            render={(props) => <WorkshopLoyaltyProgram />}
          />

          {
            /*
                      <Route
                      exact
                      path='/workshop/accept-invite'
                      render={props => (
                        <AcceptInviteSr {...props} {...subscriptionProps} />
                      )}
                    />

                    <Route
                      exact
                      path='/workshop/needed-parts'
                      render={props => (
                        <NeededPartsInquirySr {...props} {...subscriptionProps} />
                      )}
                    />

                    <Route
                      exact
                      path='/workshop/valuation-direct-clients'
                      render={props => (
                        <ValuationDirectClientsSr {...props} {...subscriptionProps} />
                      )}
                    />
            */
            <Route exact path="/workshop/profile" component={WorkshopProfile} />
            /*
                    <Route
                      exact
                      path='/workshop/settings'
                      render={props => (
                        <SettingsContainerSr {...props} {...subscriptionProps} />
                      )}
                    />

                    <Route
                      exact
                      path='/workshop/schedule'
                      render={props => <ScheduleSr {...props} {...subscriptionProps} />}
                    />

                    <Route
                      exact
                      path='/workshop/subscriptions'
                      component={Subscriptions}
                    />

                    <Route
                      exact
                      path='/workshop/workers'
                      render={props => <WorkersSr {...props} {...subscriptionProps} />}
                    />

                    <Route exact path='/workshop/code-scanner' render={CodeScanner} />
                    <Route exact path='/workshop/warranty/:id' component={WarrantyEdit} />
                    <Route exact path='/workshop/warranty' component={WarrantyBookList} />

                    <Redirect to={'/workshop/valuations'} />
            */
          }
        </Switch>
      </div>
    );
  }
}

function mapStateToProps({ workshop, subscription }) {
  const workshopType = getOrEmptyObj(workshop.data).type;
  const { subscriptionEndDate, isFetchingSubscription } = subscription;

  return {
    workshopType,
    subscriptionEndDate,
    isFetchingSubscription,
  };
}

export default connect(mapStateToProps, null, null, { pure: false })(
  WorkshopContainer
);
