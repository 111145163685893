/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export class Address {
  country;
  city;
  state;
  postalCity;
  postalCode;
  streetAddress;
  latitude;
  longitude;

  constructor(values) {
    this.country = values.country;
    this.city = values.city;
    this.state = values.state;
    this.postalCity = values.postalCity;
    this.postalCode = values.postalCode;
    this.streetAddress = values.streetAddress;
    this.latitude = values.latitude;
    this.longitude = values.longitude;
  }
}

export class WorkshopDetails {
  workshopName;
  ownerName;
  language;
  phoneNumber;
  mobilePhoneNumber;
  faxNumber;
  nip;
  regon;
  krs;
  description;
  website;
  currency;

  constructor(values) {
    this.workshopName = values.workshopName;
    this.ownerName = values.ownerName;
    this.language = values.language;
    this.phoneNumber = this.assignPhoneNumber(values.phoneNumber);
    this.mobilePhoneNumber = this.assignPhoneNumber(values.mobilePhoneNumber);
    this.faxNumber = this.assignPhoneNumber(values.faxNumber);
    this.nip = values.nip;
    this.regon = values.regon;
    this.krs = values.krs;
    this.description = values.description;
    this.website = values.website;
    this.currency = values.currency;
  }

  assignPhoneNumber(numberObj) {
    return !numberObj.phoneNumber
      ? {phoneNumber: null, phoneNumberPrefix: null}
      : numberObj;
  }
}
