/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {userService} from 'shared/services/user.service';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import UserAvatar from './avatar/UserAvatar';
import './client-profile.css';
import UserProfileData from './data/UserProfileData';
import UserProfileForm from './update-form/UserProfileForm';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
    };
    this.handleOnClickEditBtn = this.handleOnClickEditBtn.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillMount() {
    const {dispatch} = this.props;
    dispatch(userActions.getUserDataById(userService.getUserId()));
  }

  componentDidUpdate() {
    const {dispatch} = this.props;
    if (userService.isThinUser() && this.hasUserFilledProfileData())
      dispatch(userActions.logout());
  }

  hasUserFilledProfileData() {
    const {data} = this.props;
    if (
      data &&
      data.firstName &&
      data.lastName &&
      data.city &&
      data.region &&
      data.phoneNumber.phoneNumber &&
      data.enabled === true
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderThinUserTip() {
    return (
      <div className='row mt-3'>
        <div className='thin-user-tip'>
          <Alert className='mb-0 text-center' color='warning'>
            <Translate value='accessDenied.fillMissingDataTip' />
          </Alert>
        </div>
      </div>
    );
  }

  handleOnClickEditBtn() {
    this.setState(prevState => {
      return {isEditable: !prevState.isEditable};
    });
  }

  handleOnSubmit(values) {
    const {dispatch} = this.props;
    delete values.avatar;
    if (values.newPassword !== null && values.newPassword.length === 0)
      delete values.newPassword;

    dispatch(userActions.updateProfile(userService.getUserId(), values));
    this.handleOnClickEditBtn();
  }

  renderAvatar() {
    const {data} = this.props;
    const avatar = data && data.avatar ? data.avatar : defaultAvatar;

    return (
      <div className='avatar-container mb-3'>
        <img src={avatar} style={{cursor: 'default'}} alt='Avatar' />
      </div>
    );
  }

  renderView() {
    const {data, avatarModalMode} = this.props;
    if (this.state.isEditable)
      return (
        <Fragment>
          <UserAvatar modalMode={false} />
          <UserProfileForm
            onSubmit={this.handleOnSubmit}
            handleCancel={this.handleOnClickEditBtn}
            isAdminForm={userService.isAdmin()}
            collapsiblePasswordForm={false}
          />
        </Fragment>
      );

    return (
      <div>
        {this.renderAvatar()}
        <UserProfileData data={data} />
        <div className='text-sm-right text-center'>
          <Button className='main-btn' onClick={this.handleOnClickEditBtn}>
            <Translate value='userProfile.editData' />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {data} = this.props;
    const userFullName =
      data && data.firstName && data.lastName
        ? data.firstName + ' ' + data.lastName
        : '';

    return (
      <ContentWrapper
        title='userProfile.profile'
        titleAddon={userFullName}
        containerClass='user-profile-container'>
        {userService.isThinUser() && this.renderThinUserTip()}
        <div className='profile-data'>{this.renderView()}</div>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {user, i18n} = state;
  const {data} = user;

  return {data, i18n};
}

export default connect(mapStateToProps)(UserProfile);
