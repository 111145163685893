/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import ValidationMessageEnum from '../../../utils/utils';

export const validate = values => {
  let errors = {};
  errors.flatValues = validateFlatValues(values.flatValues);
  return errors;
};

const required = {key: ValidationMessageEnum.REQUIRED, params: {}};

const validateFlatValues = flatValues => {
  if (!flatValues || !flatValues.length) return {_error: required};
  else {
    const flatValuesErrors = [];
    flatValues.forEach((flatValue, index) => {
      const flatValueError = {};
      if (!flatValue || !flatValue.value || !/\S/.test(flatValue.value)) {
        flatValueError.value = required;
        flatValuesErrors[index] = flatValueError;
      }
    });

    if (flatValuesErrors.length) return flatValuesErrors;
  }
};
