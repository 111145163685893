/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { I18n } from "react-redux-i18n";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Input } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { rowsQuantityToDisplay } from 'shared/utils/utils';
import { dateUtils, DATE_SHORT_FORMAT } from 'shared/utils/date.utils';

export const WorkshopLoyaltyProgramHistory = () => {
  const dispatch = useDispatch();

  const workshopHistory = useSelector(state => state.workshopLoyaltyProgram.history);
  const isFetching = useSelector(state => state.workshopLoyaltyProgram.historyFetching);
  const workshopData = useSelector(state => state.workshop.data);
  const registartionFetching = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);
  const registrationData = useSelector(state => state.workshopLoyaltyProgram.registrationData);
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [first, setFirst] = useState(0)
  const [globalFilter, setGlobalFilter] = useState();
  const [mappedHistory, setMappedHistory] = useState();

  useEffect(() => {
    if(workshopData && !registartionFetching){
      dispatch(workshopLoyaltyProgramActions.workshopLoyaltyProgramHistory({
        page: page - 1,
        size: pageLimit,
        sort: (sortField && sortDirection) && `${sortField},${sortDirection}`,
        search: globalFilter,
        filter: 'SPENT'
      }, workshopData.workshopId, !!registrationData));
    }
  }, [page, pageLimit, sortField, sortDirection, globalFilter, workshopData, registartionFetching]);

  const mapSortField = (label) => {
    switch (label) {
      case 'eventDate':
        return 'date'
      case 'totalPointsBeforeEvent':
        return 'totalPointsBefore'
      case 'totalEventPoints':
        return 'points'
      case 'totalPointsAfterEvent':
        return 'totalPointsAfter'
      default:
        break;
    }
  }

  useEffect(() => {
    if(workshopHistory.content?.length > 0) {
      const workshopHistoryTableData = workshopHistory.content.map((history, index) => {
        let valueString = '';
        let descriptionString = '';
        let productNameString = '';
        let productTypeString = '';
        let productCount = '';
        if(history.eventProducts?.length > 0) {
          history.eventProducts.forEach((item, index) => {
            valueString +=  item.value;
            descriptionString += item.description;
            productNameString += item.productName;
            productTypeString += item.productType;
            productCount += item.count;
            if(index < history.eventProducts.length - 1) {
              valueString += '\n';
              descriptionString += '\n';
              productNameString += '\n';
              productTypeString += '\n';
              productCount += '\n';
            }
          })
        }
        return {
          ...history,
          valueString: valueString,
          productNameString: productNameString,
          productTypeString: productTypeString,
          descriptionString: descriptionString,
          lastPointsExchangeDate: dateUtils.formatDate(history.lastPointsExchangeDate, 'YYYY-MM-DD'),
          productCount
        }
      });
      setMappedHistory(workshopHistoryTableData);
    }
  }, [workshopHistory]);

  return (
    <ContentWrapper title='workshopLoyaltyProgram.history.header' scrollY>
      <Loadable active={isFetching || registartionFetching} spinner>
        <div className='col' style={{minWidth: 976}}>
          <DataTable
            value={mappedHistory}
            rows={pageLimit}
            className='data-table-padding-class'
            responsive={true}
            emptyMessage={I18n.t('workshopLoyaltyProgram.history.noHistory')}
            paginator={false}
            onSort={e => {
              setPage(1);
              setFirst(0);
              setSortField(mapSortField(e.sortField));
              setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
            }}
            header={
              <div style={{
                width: 320, position: 'relative',
                float: 'right'
              }}>
                <Input
                  type='search'
                  placeholder={I18n.t('workshops.searchPlaceholder')}
                  onInput={e => {
                    setFirst(0);
                    setPage(1);
                    setSortField(mapSortField(e.sortField));
                    setGlobalFilter(e.target.value);
                  }}
                  className='global-filter'
                />
              </div>
            }
          >
            <Column
              field='totalPointsBeforeEvent'
              header={I18n.t('workshopLoyaltyProgram.history.pointsAmmountBeforeChanging')}
              style={{width: '7%'}}
              sortable={true}
            />
            <Column
              field='eventDate'
              header={I18n.t('workshopLoyaltyProgram.history.changingDate')}
              body={(rowData) => dateUtils.formatDate(rowData.eventDate, DATE_SHORT_FORMAT)}
              style={{width: '10%'}}
              sortable={true}
            />
            <Column
              field='productNameString'
              header={I18n.t('workshopLoyaltyProgram.history.productType')}
              style={{whiteSpace: 'pre-wrap', width: '30%'}}
            />
            <Column
              field='productTypeString'
              header={I18n.t('workshopLoyaltyProgram.history.productName')}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='descriptionString'
              header={I18n.t('workshopLoyaltyProgram.history.shortDescription')}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='valueString'
              header={I18n.t('workshopLoyaltyProgram.history.productValue')}
              style={{whiteSpace: 'pre-wrap', width: '10%'}}
            />
            <Column
              field='productCount'
              header={I18n.t('workshopLoyaltyProgram.workshops.count')}
              style={{whiteSpace: 'pre-wrap', width: '7%'}}
            />
            <Column
              field='totalEventPoints'
              header={I18n.t('workshopLoyaltyProgram.history.changedProductPoints')}
              style={{width: '7%'}}
              sortable={true}
            />
            <Column
              field='totalPointsAfterEvent'
              header={I18n.t('workshopLoyaltyProgram.history.savedPoints')}
              style={{width: '7%'}}
              sortable={true}
            />
          </DataTable>
          <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
            <Paginator
              first={first}
              rows={pageLimit}
              totalRecords={workshopHistory.totalElements}
              pageLinkSize={5}
              onPageChange={(e) => {
                setPage(e.page + 1)
                setFirst(e.first)
              }}
              leftContent={
                <Dropdown
                  options={rowsQuantityToDisplay}
                  className='data-table-rows-dropdown'
                  value={pageLimit}
                  onChange={e => { setPage(1), setPageLimit(e.value) }}
                />
              }
            ></Paginator>
          </div>
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
