/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {InputText} from 'primereact/inputtext';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Dropdown, DropdownMenu, DropdownToggle, Input} from 'reactstrap';
import {errorColor} from '../../../utils/style.utils';
import normalizePhoneNumber from '../normalizer/PhoneNumberNormalizer';
import './phone-number.css';
import {getDefaultPhonePrefix, prefixData} from './utils/utils';

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      data: prefixData,
      isTouched: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    this.clearPhoneNumberPrefix = this.clearPhoneNumberPrefix.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  componentDidMount() {
    const {i18n} = this.props;
    if (!this.props.input.value.phoneNumber)
      this.setPhonePrefix(getDefaultPhonePrefix(i18n));
    this.setState({dropdownOpen: false, isTouched: false});
  }

  toggleDropdown() {
    if (
      (!this.props.inputDisabled || !this.props.inputReadOnly) &&
      !this.props.prefixReadOnly
    )
      this.setState({dropdownOpen: !this.state.dropdownOpen});
  }

  setPhonePrefix(value) {
    this.toggleDropdown();
    this.props.input.onChange({
      phoneNumberPrefix: value,
      phoneNumber: this.props.input.value.phoneNumber,
    });
    this.setState({isTouched: true, data: prefixData});
  }

  onInputChange(event) {
    let phoneNumber = normalizePhoneNumber(event.target.value);
    this.props.input.onChange({
      phoneNumberPrefix: this.props.input.value.phoneNumberPrefix,
      phoneNumber: phoneNumber,
    });
  }

  onChangeSearchInput(event) {
    if (event.target.value) {
      const filteredData = prefixData.filter(obj => {
        return (
          obj.label.toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0
        );
      });
      this.setState({data: filteredData});
    } else this.setState({data: prefixData});
  }

  clearPhoneNumberPrefix() {
    this.props.input.onChange({
      phoneNumberPrefix: null,
      phoneNumber: this.props.input.value.phoneNumber,
    });
    this.toggleDropdown();
    this.setState({isTouched: true, data: prefixData});
  }

  renderToDropdownItem(item) {
    return (
      <div
        key={item.value}
        onClick={() => this.setPhonePrefix(item.value)}
        className='clickable mx-1 text-center'>
        {item.label}
      </div>
    );
  }

  onBlur() {
    this.setState({isTouched: true});
  }

  canShowError() {
    const {
      meta: {error},
    } = this.props;
    return this.state.isTouched && error;
  }

  render() {
    const {
      input,
      label,
      inputDisabled,
      inputReadOnly,
      inputAlwaysBottom,
      meta: {error},
      required,
    } = this.props;

    return (
      <div className='row mt-3 phone-number-wrapper'>
        <div className='col-12'>
          <span
            className='field-label'
            style={this.canShowError() ? {color: errorColor} : null}>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <div className='ui-inputgroup text-input-container'>
            <span className='phone-prefix mr-2'>
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggleDropdown}>
                <DropdownToggle
                  tag='span'
                  onClick={this.toggleDropdown}
                  className='clickable'
                  data-toggle='dropdown'
                  aria-expanded={this.state.dropdownOpen}>
                  {input.value.phoneNumberPrefix}
                  <i
                    className='fa fa-chevron-down ml-1'
                    onClick={this.toggleDropdown}
                  />
                </DropdownToggle>
                <DropdownMenu style={{maxHeight: '150px', overflowY: 'auto'}}>
                  <div style={{width: '95%', margin: 'auto'}}>
                    <Input
                      id='search-input'
                      onChange={this.onChangeSearchInput}
                      placeholder={I18n.t('phoneNumber.searchPlaceholder')}
                    />
                  </div>
                  <div
                    onClick={this.clearPhoneNumberPrefix}
                    className='text-center clickable'>
                    ----
                  </div>
                  {this.state.data.map(item => this.renderToDropdownItem(item))}
                </DropdownMenu>
              </Dropdown>
            </span>
            <InputText
              className='phone-number-input'
              type='tel'
              value={input.value.phoneNumber ? input.value.phoneNumber : ''}
              onChange={this.onInputChange}
              onBlur={this.onBlur}
              disabled={inputDisabled}
              readOnly={inputReadOnly}
            />
          </div>
        </div>
        <div className='col-12'>
          <div id='text-area-error-msg' style={{clear: 'both'}}>
            {this.canShowError() && (
              <span className='error-info'>
                {I18n.t(error.key, error.params)}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {i18n: state.i18n};
}

export default connect(mapStateToProps)(PhoneNumber);
