import React from 'react';
import {Button} from 'reactstrap';
import styles from './PeriodicInspectionNotStarted.module.css';
import {I18n} from 'react-redux-i18n';
import {userService} from 'shared/services/user.service';

const PeriodicInspectionNotStarted = ({setEditMode}) => {
  return (
    <div className={styles.Container}>
      <p className={styles.Text}>
        {I18n.t('warranty.sectionVII.notRealized')}
      </p>
      {userService.isWorkshop() && 
      <Button className='btn main-btn' onClick={setEditMode}>
        {I18n.t('warranty.sectionVII.realizePeriodicInspection')}
      </Button>}
    </div>
  );
};

export default PeriodicInspectionNotStarted;
