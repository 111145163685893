/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateRequiredFieldsWithMaxLength} from 'shared/components/form/common-validators/common-validators';
import {carService} from 'shared/services/car.service';
import {userService} from 'shared/services/user.service';

export const validate = (values, props) => {
  let errors = {};
  errors.carName = validateRequiredFieldsWithMaxLength(values.carName, 60);
  return errors;
};

export const asyncValidate = values => {
  if (userService.getUserId() != null) {
    return carService
      .carExistsForUser(values.carName, userService.getUserId())
      .then(response => {
        if (response.data)
          return {
            carName: {
              key: 'valuationInquiry.carNameExistsForUserError',
              params: {},
            },
          };
      });
  } else {
    return new Promise((resolve, reject) => resolve());
  }
};
