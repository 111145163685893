/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {workshopLoyaltyProgramConstants} from '../constants/workshopLoyaltyProgram.constants';
import {workshopLoyaltyProgramService} from '../services/workshopLoyaltyProgram.service';
import {messageActions} from './message.actions';
import {userService} from '../services/user.service';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';

export const workshopLoyaltyProgramActions = {
  getAllNotifications,
  updateNotification,
  getBarNotificationsToShow,
  getModalNotificationsToShow,
  addToModalNotificationsToShow,
  deleteFromModalNotificationsToShow,
  markModalNotificationAsRead,
  registerInWorkshopLoyaltyProgram,
  getWorkshopLoyaltyProgramRegistrationData,
  getWorkshopList,
  updateWorkshopDataInWorkshopLoyaltyProgram,
  getScoredProductNames,
  getScoredProductTypes,
  createScoredProduct,
  scoredProductList,
  deleteScoredProduct,
  updateScoredProduct,
  getWorkshopLoyaltyProgramPointsData,
  getLoyaltyConfig,
  createLoyaltyConfig,
  updateLoyaltyConfig,
  workshopLoyaltyProgramHistory,
  createPrizeProduct,
  updatePrizeProduct,
  prizeProductList,
  deletePrizeProduct,
  setActiveWorkshopLoyaltyProgramTile,
  buyPrizeProducts,
  getWorkshopsWithHistory,
  toggleWorkshopStatus,
  loyaltyParticipationLockHistory,
  setSelectedPointsType,
  setSelectedWorkshop,
  setSelectedBlockType,
  setSelectedDateType
};

function getAllNotifications() {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_START,
    });
    workshopLoyaltyProgramService.getWorkshopLoyaltyProgramNotifications().then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_NOTIFICATIONS,
          payload: response.data,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.notifications.getNotificationsErrorMessage'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_END,
        });
      }
    );
  };
}

function updateNotification(identifier, data) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_START,
    });
    workshopLoyaltyProgramService
      .updateWorkshopLoyaltyProgramNotification(identifier, data)
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_LOYALTY_PROGRAM_NOTIFICATION,
          });
          dispatch(
            messageActions.successMessage(
              'workshopLoyaltyProgram.notifications.saveSuccessMessage'
            )
          );
          dispatch(getAllNotifications());
        },
        error => {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.notifications.saveErrorMessage'
            )
          );
          dispatch({
            type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_END,
          });
        }
      );
  };
}

function getBarNotificationsToShow(maxNotificationAgeMillis) {
  return dispatch => {
    const userId = userService.getUserId();
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW_START,
    });
    workshopLoyaltyProgramService
      .getWorkshopLoyaltyProgramBarNotificationsToShow(
        userId,
        maxNotificationAgeMillis
      )
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW,
            payload: response.data,
          });
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW_END,
          });
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW_END,
          });
        }
      );
  };
}

function getModalNotificationsToShow() {
  return dispatch => {
    const userId = userService.getUserId();
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW_START,
    });
    workshopLoyaltyProgramService
      .getWorkshopLoyaltyProgramModalNotificationsToShow(userId)
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW,
            payload: response.data,
          });
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW_END,
          });
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW_END,
          });
        }
      );
  };
}

function markModalNotificationAsRead(
  notificationId,
  callbackSuccess = () => {}
) {
  return dispatch => {
    const userId = userService.getUserId();
    dispatch({
      type: workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ_START,
    });
    workshopLoyaltyProgramService
      .markModalNotificationAsRead(notificationId, userId)
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ,
          });
          callbackSuccess();
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ_END,
          });
        }
      );
  };
}

function addToModalNotificationsToShow(notification) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.ADD_TO_MODAL_NOTIFICATIONS_TO_SHOW,
      payload: notification,
    });
  };
}

function deleteFromModalNotificationsToShow(notification) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.DELETE_FROM_MODAL_NOTIFICATIONS_TO_SHOW,
      payload: notification,
    });
  };
}

function registerInWorkshopLoyaltyProgram(data) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_START,
    });
    workshopLoyaltyProgramService.registerInWorkshopLoyaltyProgram(data).then(
      response => {
        dispatch(getModalNotificationsToShow());
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA,
          payload: response.data,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.register.errorText'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_END,
        });
      }
    );
  };
}

function getWorkshopLoyaltyProgramRegistrationData(workshopId) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START,
    });
    workshopLoyaltyProgramService
      .getWorkshopLoyaltyProgramRegistrationData(workshopId)
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA,
            payload: response.data,
          });
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END,
          });
        }
      );
  };
}

function getWorkshopList(params, searchPhrase) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LIST_REQUEST_START,
    });
    workshopLoyaltyProgramService.getWorkshopList(params, searchPhrase).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LIST,
            payload: response.data,
          });
        }
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LIST_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.workshops.worshopListError'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LIST_REQUEST_END,
        });
      }
    );
  };
}

function getWorkshopsWithHistory() {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_WORKSHOPS_WITH_HISTORY_REQUEST_START,
    });
    workshopLoyaltyProgramService.getWorkshopsWithHistory().then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOPS_WITH_HISTORY,
            payload: response.data,
          });
        }
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_WORKSHOPS_WITH_HISTORY_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.workshops.worshopListError'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_WORKSHOPS_WITH_HISTORY_REQUEST_END,
        });
      }
    );
  };
}

function updateWorkshopDataInWorkshopLoyaltyProgram(workshopId, data) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START,
    });
    workshopLoyaltyProgramService
      .updateWorkshopDataInWorkshopLoyaltyProgram(workshopId, data)
      .then(
        response => {
          dispatch(
            messageActions.successMessage(
              'workshopLoyaltyProgram.saveSuccessMessage'
            )
          );
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA,
            payload: response.data,
          });
        },
        error => {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.saveErrorMessage'
            )
          );
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END,
          });
        }
      );
  };
}

function getScoredProductNames(productTypeId) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_NAMES_REQUEST_START,
    });
    workshopLoyaltyProgramService.getScoredProductNames(productTypeId).then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_NAMES,
          payload: response,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_NAMES_REQUEST_END,
        });
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_NAMES_REQUEST_END,
        });
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.scoredProducts.nameListError'
          )
        );
      }
    );
  };
}

function getScoredProductTypes() {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_TYPES_REQUEST_START,
    });
    workshopLoyaltyProgramService.getScoredProductTypes().then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_TYPES,
          payload: response,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_TYPES_REQUEST_END,
        });
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_TYPES_REQUEST_END,
        });
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.scoredProducts.typeListError'
          )
        );
      }
    );
  };
}

function createScoredProduct(product, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_START,
    });
    workshopLoyaltyProgramService.createScoredProduct(product).then(
      response => {
        dispatch(scoredProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.scoredProducts.newProductWasCreated'
          )
        );
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        if (error.response.status === 409) {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.scoredProducts.homologationIdAlreadyExists'
            )
          );
        } else {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.scoredProducts.productWasNotEdited'
            )
          );
        }
      }
    );
  };
}

function updateScoredProduct(product, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_START,
    });
    const {id, ...data} = product;
    workshopLoyaltyProgramService.updateScoredProduct(id, data).then(
      response => {
        dispatch(scoredProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.scoredProducts.productWasEdited'
          )
        );
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        if (error.response.status === 409) {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.scoredProducts.homologationIdAlreadyExists'
            )
          );
        } else {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.scoredProducts.productWasNotEdited'
            )
          );
        }
      }
    );
  };
}

function scoredProductList(params) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_START,
    });
    workshopLoyaltyProgramService.scoredProductList(params).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_LIST,
            payload: response.data,
          });
        }
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.scoredProducts.scoredProductsFetchError'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
      }
    );
  };
}

function deleteScoredProduct(id, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_START,
    });
    workshopLoyaltyProgramService.deleteScoredProduct(id).then(
      response => {
        dispatch(scoredProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.scoredProducts.productWasDeleted'
          )
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.scoredProducts.productWasNotDeleted'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END,
        });
      }
    );
  };
}

function getWorkshopLoyaltyProgramPointsData(workshopId) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_START,
    });
    workshopLoyaltyProgramService
      .getWorkshopLoyaltyProgramPointsData(workshopId)
      .then(
        response => {
          dispatch({
            type: workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_POINTS_DATA,
            payload: response.data,
          });
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_END,
          });
        }
      );
  };
}

function getLoyaltyConfig() {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_START,
    });
    workshopLoyaltyProgramService.getLoyaltyConfig().then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_LOYALTY_CONFIG,
          payload: response,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
      }
    );
  };
}

function createLoyaltyConfig(data) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_START,
    });
    workshopLoyaltyProgramService.createLoyaltyConfig(data).then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_LOYALTY_CONFIG,
          payload: response,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.accrualPeriodWasWritten'
          )
        );
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
      }
    );
  };
}

function updateLoyaltyConfig(data) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_START,
    });
    workshopLoyaltyProgramService.updateLoyaltyConfig(data).then(
      response => {
        dispatch({
          type: workshopLoyaltyProgramConstants.UPDATE_LOYALTY_CONFIG,
          payload: response,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.accrualPeriodWasWritten'
          )
        );
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END,
        });
      }
    );
  };
}

function workshopLoyaltyProgramHistory(params, id, isRegisteredWorkshop = true) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_START,
    });
    workshopLoyaltyProgramService
      .workshopLoyaltyProgramHistory(params, id)
      .then(
        response => {
          if (response.data) {
            dispatch({
              type: workshopLoyaltyProgramConstants.UPDATE_WORHSHOP_LOYALTY_PROGRAM_HISTORY,
              payload: response.data,
            });
          }
          dispatch({
            type: workshopLoyaltyProgramConstants.WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_END,
          });
        },
        error => {
          if(isRegisteredWorkshop){
            dispatch(
              messageActions.errorMessage(
                'workshopLoyaltyProgram.history.historyFetchError'
              )
            );
          }
          dispatch({
            type: workshopLoyaltyProgramConstants.WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_END,
          });
        }
      );
  };
}

function createPrizeProduct(product, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_START,
    });
    workshopLoyaltyProgramService.createPrizeProduct(product).then(
      response => {
        dispatch(prizeProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.prizeProducts.newProductWasCreated'
          )
        );
      },
      error => {
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
        if (error.response.status === 409) {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.prizeProducts.homologationIdAlreadyExists'
            )
          );
        } else {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.prizeProducts.productWasNotEdited'
            )
          );
        }
      }
    );
  };
}

function updatePrizeProduct(product, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_START,
    });
    const {id, ...data} = product;
    workshopLoyaltyProgramService.updatePrizeProduct(id, data).then(
      response => {
        dispatch(prizeProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.prizeProducts.productWasEdited'
          )
        );
      },
      error => {
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
        dispatch({
          type: updateDataSideBarConstants.HIDE,
        });
        if (error.response.status === 409) {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.prizeProducts.homologationIdAlreadyExists'
            )
          );
        } else {
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.prizeProducts.productWasNotEdited'
            )
          );
        }
      }
    );
  };
}

function prizeProductList(params) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_START,
    });
    workshopLoyaltyProgramService.prizeProductList(params).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_PRIZE_PRODUCT_LIST,
            payload: response.data,
          });
        }
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.prizeProducts.prizeProductsFetchError'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
      }
    );
  };
}

function deletePrizeProduct(id, pageSize) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_START,
    });
    workshopLoyaltyProgramService.deletePrizeProduct(id).then(
      response => {
        dispatch(prizeProductList({page: 0, size: pageSize}));
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
        dispatch(
          messageActions.successMessage(
            'workshopLoyaltyProgram.prizeProducts.productWasDeleted'
          )
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.prizeProducts.productWasNotDeleted'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END,
        });
      }
    );
  };
}

function setActiveWorkshopLoyaltyProgramTile(tile) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SET_ACTIVE_WORKSHOP_LOYALTY_PROGRAM_TILE,
      payload: tile,
    });
  };
}

function buyPrizeProducts(
  workshopId,
  prizeProductsIds,
  callbackSuccess = () => {}
) {
  return dispatch => {
    dispatch({type: workshopLoyaltyProgramConstants.BUY_PRIZE_PRODUCTS_START});
    workshopLoyaltyProgramService
      .buyPrizeProducts(workshopId, prizeProductsIds)
      .then(
        response => {
          callbackSuccess();
          dispatch({
            type: workshopLoyaltyProgramConstants.BUY_PRIZE_PRODUCTS_END,
          });
        },
        error => {
          dispatch({
            type: workshopLoyaltyProgramConstants.BUY_PRIZE_PRODUCTS_END,
          });
          dispatch(
            messageActions.errorMessage(
              'workshopLoyaltyProgram.productsForPoints.buyProductsError'
            )
          );
        }
      );
  };
}

function toggleWorkshopStatus(workshop, params, searchPhrase) {
  return dispatch => {
    workshop.loyaltyParticipationBlocked
      ? workshopLoyaltyProgramService
          .unlockLoyaltyParticipation(workshop.workshopId)
          .then(
            response => {
              dispatch(getWorkshopList(params, searchPhrase));
              dispatch(
                messageActions.successMessage(
                  'workshopLoyaltyProgram.workshops.workshopWasUnblocked'
                )
              );
            },
            error => {
              dispatch(
                messageActions.errorMessage(
                  'workshopLoyaltyProgram.workshops.toggleError'
                )
              );
            }
          )
      : workshopLoyaltyProgramService
          .lockLoyaltyParticipation(workshop.workshopId)
          .then(
            response => {
              dispatch(getWorkshopList(params, searchPhrase));
              dispatch(
                messageActions.successMessage(
                  'workshopLoyaltyProgram.workshops.workshopWasBlocked'
                )
              );
            },
            error => {
              dispatch(
                messageActions.errorMessage(
                  'workshopLoyaltyProgram.workshops.toggleError'
                )
              );
            }
          );
  };
}

function loyaltyParticipationLockHistory(params) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_START,
    });
    workshopLoyaltyProgramService.loyaltyParticipationLockHistory(params).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_PARTICIPATION_BLOCK_HISTORY,
            payload: response.data,
          });
        }
        dispatch({
          type: workshopLoyaltyProgramConstants.WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopLoyaltyProgram.workshops.loyaltyParticipationLockHistoryError'
          )
        );
        dispatch({
          type: workshopLoyaltyProgramConstants.WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_END,
        });
      }
    );
  };
}

function setSelectedPointsType(pointsType) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SET_SELECTED_POINTS_TYPE,
      payload: pointsType,
    });
  };
}

function setSelectedWorkshop(workshopId) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SET_SELECTED_WORKSHOP,
      payload: workshopId,
    });
  };
}

function setSelectedDateType(dateType) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SET_SELECTED_DATE_TYPE,
      payload: dateType,
    });
  };
}

function setSelectedBlockType(blockType) {
  return dispatch => {
    dispatch({
      type: workshopLoyaltyProgramConstants.SET_SELECTED_BLOCK_TYPE,
      payload: blockType,
    });
  };
}
