/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import {serviceCategoryService} from 'shared/services/service-category.service';
import {isEmptyArray} from 'shared/utils/utils';
import {validate} from './service-category-form-validators';
import {ServiceCategoryForm} from './ServiceCategoryForm';

export const asyncValidate = values =>
  serviceCategoryService.existsByName(values.name, values.id).then(response => {
    if (response.data)
      return {
        name: {key: 'services.serviceCategoryExistsError', params: {}},
      };
  });

const ServiceCategoryEditForm = reduxForm({
  form: 'serviceCategoryEditForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
  enableReinitialize: true,
})(ServiceCategoryForm);

function mapStateToProps(state) {
  const {i18n, service} = state;
  const initialValues = service.category || {};
  const selectedServiceCategoryId = service.category
    ? service.category.id
    : null;
  const hasServices = service.category
    ? !isEmptyArray(service.category.services)
    : false;

  return {i18n, initialValues, selectedServiceCategoryId, hasServices};
}

const mapDispatchToProps = {
  handleDelete: serviceCategoryActions.deleteServiceCategory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategoryEditForm);
