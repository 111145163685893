/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import SocialLogin from 'react-social-login';
import {Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';

const Btn = ({label, iconClassName, triggerLogin, ...props}) => (
  <Button type='button' onClick={triggerLogin} {...props}>
    <i className={iconClassName + ' fa-2x social-icon'} />
    <span className='social-button-label'>
      <Translate value={label} />
    </span>
  </Button>
);

export default SocialLogin(Btn);
