import React, {useState} from 'react';
import {Translate} from 'react-redux-i18n';
import {WorkshopLoyaltyProgramEditForm} from './workshop-loyalty-program-edit-form/WorkshopLoyaltyProgramEditForm';
import {Statute} from './statute/Statute';
import {WorkshopLoyaltyProgramNotifications} from './workshop-loyalty-program-notifications/WorkshopLoyaltyProgramNotifications';
import {PrizeProducts} from './prize-products/PrizeProducts';
import {WorkshopList} from './workshop-list/WorkshopList';
import {ScoredProducts} from './scored-products/ScoredProducts';
import {PointsAccrualPeriod} from './pointsAccrualPeriod/PointsAccrualPeriod';
import {Reports} from './reports/Reports';
import { WorkshopLoyaltyProgramHistoryList } from './workshop-loylty-program-history-list/WorkshopLoyaltyProgramHistoryList';
import styles from './WorshopLoyaltyProgram.module.css';

export const LoyaltyProgramContainerSubsection = {
  LOYALTY_PROGRAM: {
    key: 'LoyaltyProgram',
    divId: 'loyality-program-tile',
    translateValue: 'workshopLoyaltyProgram.workshopLoyaltyProgram',
  },
  STATUTE: {
    key: 'Statute',
    divId: 'statute-tile',
    translateValue: 'workshopLoyaltyProgram.statute',
  },
  NOTIFICATIONS: {
    key: 'notifications',
    divId: 'notifications-tile',
    translateValue: 'workshopLoyaltyProgram.notifications.header',
  },
  WORKSHOPS: {
    key: 'workshops',
    divId: 'wokshops-tile',
    translateValue: 'workshopLoyaltyProgram.workshops.header',
  },
  POINTS_ACCURAL_PERIOD: {
    key: 'PointsAccuralPeriod',
    divId: 'points-accural-period-tile',
    translateValue: 'workshopLoyaltyProgram.pointsAccrualPeriod',
  },
  SCORED_PRODUCTS: {
    key: 'scoredProducts',
    divId: 'scoredProducts-tile',
    translateValue: 'workshopLoyaltyProgram.scoredProducts.header',
  },
  PRIZE_PRODUCTS: {
    key: 'prize-products',
    divId: 'prize-products-tile',
    translateValue: 'workshopLoyaltyProgram.prizeProducts.header',
  },
  REPORTS: {
    key: 'reports',
    divId: 'reports-tile',
    translateValue: 'workshopLoyaltyProgram.reports.header',
  },
  WORSKHOP_LOYALTY_PROGRAM_HISTORY_LIST: {
    key: 'workshop-loyalty-program-history-list',
    divId: 'workshop-loyalty-program-history-list-tile',
    translateValue: 'workshopLoyaltyProgram.historyPoints.header',
  }
};

export const WorkshopLoyaltyProgram = () => {
  const [subsectionKeyActive, setSubsectionKeyActive] = useState(
    LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM.key
  );
  const isActive = subsection => {
    return subsectionKeyActive === subsection.key
      ? 'subsection-tile-active '
      : '';
  };
  const renderTile = (subsection, iconName, spacingClassesApply = true) => {
    const commonClasses =
      'subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3 mt-3 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';
    return (
      <div
        id={subsection.divId}
        className={commonClasses + spacingClasses + isActive(subsection)}
        onClick={e => setSubsectionKeyActive(subsection.key)}
      >
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={subsection.translateValue} />
      </div>
    );
  };

  const renderTiles = () => {
    return (
      <>
        <div className={styles.TilesContainer}>
          {renderTile(
            LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM,
            'fa-diamond'
          )}
          {renderTile(LoyaltyProgramContainerSubsection.STATUTE, 'fa-book')}
          {renderTile(
            LoyaltyProgramContainerSubsection.NOTIFICATIONS,
            'fa-bell',
          )}
          {renderTile(
            LoyaltyProgramContainerSubsection.POINTS_ACCURAL_PERIOD,
            'fa-calendar-o',
            false
          )}
        </div>
        <div className={styles.TilesContainer}>
          {renderTile(
            LoyaltyProgramContainerSubsection.WORKSHOPS,
            'fa-wrench',
          )}
          {renderTile(
            LoyaltyProgramContainerSubsection.WORSKHOP_LOYALTY_PROGRAM_HISTORY_LIST,
            'fa-history',
          )}
          {renderTile(
            LoyaltyProgramContainerSubsection.SCORED_PRODUCTS,
            'fa-list-alt ',
          )}
          {renderTile(
            LoyaltyProgramContainerSubsection.PRIZE_PRODUCTS,
            'fa-gift',
          )}
          {renderTile(
            LoyaltyProgramContainerSubsection.REPORTS,
            'fa-file-excel-o ',
            false
          )}
        </div>
      </>
    );
  };

  const renderSubsection = () => {
    switch (subsectionKeyActive) {
      case LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM.key:
        return <WorkshopLoyaltyProgramEditForm />;
      case LoyaltyProgramContainerSubsection.STATUTE.key:
        return <Statute />;
      case LoyaltyProgramContainerSubsection.NOTIFICATIONS.key:
        return <WorkshopLoyaltyProgramNotifications />;
      case LoyaltyProgramContainerSubsection.WORKSHOPS.key:
        return <WorkshopList />;
      case LoyaltyProgramContainerSubsection.POINTS_ACCURAL_PERIOD.key:
        return <PointsAccrualPeriod />;
      case LoyaltyProgramContainerSubsection.SCORED_PRODUCTS.key:
        return <ScoredProducts />;
      case LoyaltyProgramContainerSubsection.PRIZE_PRODUCTS.key:
        return <PrizeProducts />;
      case LoyaltyProgramContainerSubsection.REPORTS.key:
        return <Reports />;
      case LoyaltyProgramContainerSubsection.WORSKHOP_LOYALTY_PROGRAM_HISTORY_LIST.key:
        return <WorkshopLoyaltyProgramHistoryList />
    }
  };

  return (
    <>
      {renderTiles()}
      {renderSubsection()}
    </>
  );
};
