/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {carService} from '../services/car.service';
import {carConstants} from '../constants/car.constants';
import {messageActions} from './message.actions';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';
import {getErrorMessage} from '../utils/utils';
import {warrantyActions} from 'shared/actions/warranty.actions';

export const carActions = {
  findFuelTypes,
  findCarBrands,
  findCarsByUserId,
  findCarById,
  createCar,
  updateCar,
  deleteCar,
};

function findFuelTypes() {
  return dispatch => {
    dispatch({type: carConstants.CAR_REQUEST_START});
    carService.findFuelTypes().then(
      fuelTypes => {
        dispatch({type: carConstants.LOAD_FUEL_TYPES, payload: fuelTypes});
        dispatch({type: carConstants.CAR_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('car.fuelTypesFetchError'));
        dispatch({type: carConstants.CAR_REQUEST_END});
      }
    );
  };
}

function findCarBrands() {
  return dispatch => {
    dispatch({type: carConstants.CAR_REQUEST_START});
    carService.findCarBrands().then(
      findCarBrands => {
        dispatch({
          type: carConstants.LOAD_CAR_BRANDS,
          payload: findCarBrands,
        });
        dispatch({type: carConstants.CAR_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('car.carBrandsFetchError'));
        dispatch({type: carConstants.CAR_REQUEST_END});
      }
    );
  };
}

function findCarsByUserId(userId, fetchWarrantyBooks = false) {
  return dispatch => {
    dispatch({type: carConstants.CAR_REQUEST_START});
    carService.findCarsByUserId(userId).then(
      cars => {
        dispatch({type: carConstants.LOAD_USER_CARS, payload: cars});
        fetchWarrantyBooks &&
          cars.data
            .map(userCar => userCar.vin)
            .forEach(vin =>
              dispatch(warrantyActions.getWarrantyBookByVIN(vin))
            );
        dispatch({type: carConstants.CAR_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('car.userCarsFetchError'));
        dispatch({type: carConstants.CAR_REQUEST_END});
      }
    );
  };
}

function findCarById(carId) {
  return dispatch => {
    carService.findCarById(carId).then(car => {
      dispatch({type: carConstants.LOAD_USER_CAR, payload: car});
    });
  };
}

function createCar(values) {
  return dispatch => {
    carService.createCar(values).then(
      car => {
        dispatch(messageActions.successMessage('car.createCarSuccessMsg'));
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: carConstants.REFRESH_USER_CARS_DATA});
      },
      error => {
        if (error.response) {
          let responseText = JSON.parse(error.response.request.responseText);
          if (
            responseText &&
            responseText.message.includes('ObjectExistsException')
          ) {
            dispatch(
              messageActions.errorMessage('car.createCarObjectExistsMsg')
            );
          } else {
            dispatch(
              messageActions.errorMessage(
                getErrorMessage(error, 'car', 'createCarErrorMsg')
              )
            );
          }
        } else {
          dispatch(
            messageActions.errorMessage(
              getErrorMessage(error, 'car', 'createCarErrorMsg')
            )
          );
        }
      }
    );
  };
}

function updateCar(carId, values) {
  return dispatch => {
    carService.updateCar(carId, values).then(
      car => {
        dispatch(messageActions.successMessage('car.updateCarSuccessMsg'));
        car.data.loadTimestamp = new Date().getTime();
        dispatch({type: carConstants.LOAD_USER_CAR, payload: car});
        dispatch({type: carConstants.REFRESH_USER_CARS_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'car', 'updateCarErrorMsg')
          )
        );
      }
    );
  };
}

function deleteCar(carId) {
  return dispatch => {
    carService.deleteCar(carId).then(
      () => {
        dispatch(messageActions.successMessage('car.carDeleteSuccessMsg'));
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: carConstants.REFRESH_USER_CARS_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'car', 'carDeleteErrorMsg')
          )
        );
      }
    );
  };
}
