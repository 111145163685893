/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {subscriptionService} from '../services/subscription.service';
import {messageActions} from './message.actions';
import {subscriptionConstants} from '../constants/subscription.constants';

export const subscriptionActions = {
  createSubscription,
  getSubscriptionEndDate,
  findAllSubscriptionPaymentsByUserId,
  cancelSubscriptionPayment,
  findAllSubscriptionPayments,
};

function createSubscription(userId, subscriptionPlanId, paymentServiceType) {
  return dispatch => {
    dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_START});

    subscriptionService
      .createSubscription({userId, subscriptionPlanId, paymentServiceType})
      .then(
        response => {
          dispatch({type: subscriptionConstants.REFRESH_SUBSCRIPTION});
          dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_END});
          window.location.assign(response.data.redirectUrl);
        },
        error => {
          dispatch(messageActions.errorMessage('subscription.createErrorMsg'));
          dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_END});
        }
      );
  };
}

function getSubscriptionEndDate(userId) {
  return dispatch => {
    dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_START});

    subscriptionService.getSubscriptionEndDate(userId).then(
      response => {
        dispatch({
          type: subscriptionConstants.LOAD_SUBSCRIPTION_END_DATE,
          payload: response,
        });
        dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('subscription.fetchErrorMsg'));
        dispatch({type: subscriptionConstants.SUBSCRIPTION_REQUEST_END});
      }
    );
  };
}

function findAllSubscriptionPaymentsByUserId(userId) {
  return dispatch => {
    dispatch({
      type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_START,
    });

    subscriptionService.findAllSubscriptionPaymentsByUserId(userId).then(
      response => {
        dispatch({
          type: subscriptionConstants.LOAD_SUBSCRIPTION_PAYMENTS,
          payload: response,
        });
        dispatch({
          type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage('subscriptionPayment.fetchErrorMsg')
        );
        dispatch({
          type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_END,
        });
      }
    );
  };
}

function cancelSubscriptionPayment(userId, subscriptionPaymentId) {
  return dispatch => {
    subscriptionService
      .cancelSubscriptionPayment(userId, subscriptionPaymentId)
      .then(
        response => {
          dispatch(
            messageActions.successMessage(
              'subscriptionPayment.cancelSuccessMsg'
            )
          );
          dispatch({
            type: subscriptionConstants.REFRESH_SUBSCRIPTION_PAYMENTS,
          });
        },
        error => {
          dispatch(
            messageActions.errorMessage('subscriptionPayment.cancelErrorMsg')
          );
        }
      );
  };
}

function findAllSubscriptionPayments() {
  return dispatch => {
    dispatch({
      type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_START,
    });

    subscriptionService.findAllSubscriptionPayments().then(
      response => {
        dispatch({
          type: subscriptionConstants.LOAD_SUBSCRIPTION_PAYMENTS,
          payload: response,
        });
        dispatch({
          type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage('subscriptionPayment.fetchErrorMsg')
        );
        dispatch({
          type: subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_END,
        });
      }
    );
  };
}
