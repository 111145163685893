/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {loyaltyProgramConstants} from '../constants/loyalty-program.constants';
import {loyaltyProgramService} from '../services/loyalty-program.service';
import {messageActions} from './message.actions';
import {getErrorMessage} from '../utils/utils';

export const loyaltyProgramActions = {
  findLoyaltyProgramPointsByWorkshopId,
  updateLoyaltyProgramPoints,
  findLoyaltyProgramsByWorkshopId,
  findLoyaltyProgramById,
  updateLoyaltyProgram,
  deleteLoyaltyProgram,
  createLoyaltyProgram,
  findLoyaltyProgramAccountByParams,
  findLoyaltyProgramAccountsByParams,
};

function findLoyaltyProgramPointsByWorkshopId(workshopId) {
  return dispatch => {
    loyaltyProgramService.findLoyaltyProgramPointsByWorkshopId(workshopId).then(
      response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_SERVICES_LOYALTY_PROGRAM_POINTS,
          payload: response,
        });
      },
      error => {
        dispatch(messageActions.errorMessage('loyaltyProgram.fetchError'));
      }
    );
  };
}

function updateLoyaltyProgramPoints(
  workshopId,
  loyaltyProgramPoints,
  onSuccess = () => {}
) {
  return dispatch => {
    loyaltyProgramService
      .updateLoyaltyProgramPoints(workshopId, loyaltyProgramPoints)
      .then(
        response => {
          dispatch(
            messageActions.successMessage('loyaltyProgram.updateSuccess')
          );
          onSuccess();
        },
        error => {
          dispatch(messageActions.errorMessage('loyaltyProgram.updateError'));
        }
      );
  };
}

function findLoyaltyProgramsByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAMS_REQUEST_START});

    loyaltyProgramService.findLoyaltyProgramsByWorkshopId(workshopId).then(
      response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_LOYALTY_PROGRAMS,
          payload: response,
        });
        dispatch({
          type: loyaltyProgramConstants.LOYALTY_PROGRAMS_REQUEST_END,
        });
      },
      error => {
        dispatch(messageActions.errorMessage('loyaltyProgram.fetchError'));
        dispatch({
          type: loyaltyProgramConstants.LOYALTY_PROGRAMS_REQUEST_END,
        });
      }
    );
  };
}

function findLoyaltyProgramById(loyaltyProgramId) {
  return dispatch => {
    dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_START});

    loyaltyProgramService.findLoyaltyProgramById(loyaltyProgramId).then(
      response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM,
          payload: response,
        });
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('loyaltyProgram.fetchError'));
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      }
    );
  };
}

function updateLoyaltyProgram(loyaltyProgramId, data) {
  return dispatch => {
    dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_START});

    loyaltyProgramService.updateLoyaltyProgram(loyaltyProgramId, data).then(
      response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM,
          payload: response,
        });
        dispatch({type: loyaltyProgramConstants.REFRESH_LOYALTY_PROGRAMS});
        dispatch(
          messageActions.successMessage('loyaltyProgram.updateSingleSuccess')
        );
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'loyaltyProgram', 'updateSingleError')
          )
        );
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      }
    );
  };
}

function deleteLoyaltyProgram(loyaltyProgramId, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_START});

    loyaltyProgramService.deleteLoyaltyProgram(loyaltyProgramId).then(
      response => {
        dispatch({type: loyaltyProgramConstants.CLEAR_LOYALTY_PROGRAM});
        dispatch({type: loyaltyProgramConstants.REFRESH_LOYALTY_PROGRAMS});
        dispatch(messageActions.successMessage('loyaltyProgram.deleteSuccess'));
        onSuccess();
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('loyaltyProgram.deleteError'));
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      }
    );
  };
}

function createLoyaltyProgram(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_START});

    loyaltyProgramService.createLoyaltyProgram(data).then(
      response => {
        dispatch({type: loyaltyProgramConstants.REFRESH_LOYALTY_PROGRAMS});
        dispatch(messageActions.successMessage('loyaltyProgram.createSuccess'));
        onSuccess();
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'loyaltyProgram', 'createError')
          )
        );
        dispatch({type: loyaltyProgramConstants.LOYALTY_PROGRAM_REQUEST_END});
      }
    );
  };
}

function findLoyaltyProgramAccountByParams(workshopUserId, ownerUserId) {
  return dispatch => {
    loyaltyProgramService
      .findLoyaltyProgramAccountByParams(workshopUserId, ownerUserId)
      .then(response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM_ACCOUNT,
          payload: response,
        });
      });
  };
}

function findLoyaltyProgramAccountsByParams(ownerUserId) {
  return dispatch => {
    dispatch({
      type: loyaltyProgramConstants.LOYALTY_PROGRAM_ACCOUNTS_REQUEST_START,
    });

    loyaltyProgramService.findLoyaltyProgramAccountsByParams(ownerUserId).then(
      response => {
        dispatch({
          type: loyaltyProgramConstants.LOAD_LOYALTY_PROGRAM_ACCOUNTS,
          payload: response,
        });
        dispatch({
          type: loyaltyProgramConstants.LOYALTY_PROGRAM_ACCOUNTS_REQUEST_END,
        });
      },
      error => {
        dispatch(messageActions.errorMessage('loyaltyProgram.fetchError'));
        dispatch({
          type: loyaltyProgramConstants.LOYALTY_PROGRAM_ACCOUNTS_REQUEST_END,
        });
      }
    );
  };
}
