/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {partCategoryService} from '../services/part-category.service';
import {partConstants} from '../constants/part.constants';

export const partCategoryActions = {
  findAllPartCategories,
};

function findAllPartCategories() {
  return dispatch => {
    partCategoryService.findAllPartCategories().then(response => {
      dispatch({type: partConstants.LOAD_PART_CATEGORIES, payload: response});
    });
  };
}
