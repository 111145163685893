import React, { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n';
import { WorkshopLoyaltyProgramEditForm } from './workshop-loyalty-program-edit-form/WorkshopLoyaltyProgramEditForm';
import { Statute } from './statute/Statute';
import { useDispatch, useSelector } from 'react-redux';
import WorkshopLoyaltyProgramRegister from './workshop-loyalty-program-register/WorkshopLoyaltyProgramRegister';
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { I18n } from "react-redux-i18n";
import { workshopLoyaltyProgramActions } from "shared/actions/workshopLoyaltyProgram.actions";
import { statuteActions } from 'shared/actions/statute.actions';
import { WorkshopLoyaltyProgramHistory } from './workshop-loyalty-program-history/WorkshopLoyaltyProgramHistory';
import { ProductsForPoints } from "./products-for-points/ProductsForPoints";
import { ScoredProducts } from "./scored-products/ScoredProducts";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Checkbox } from "primereact/checkbox";
import { apiUrl } from "../../../environment";
import { statuteConstants } from 'shared/constants/statute.constants';
import styles from './WorshopLoyaltyProgram.module.css';

export const LoyaltyProgramContainerSubsection = {
    LOYALTY_PROGRAM: {
        key: 'LoyaltyProgram',
        divId: 'loyality-program-tile',
        translateValue: 'workshopLoyaltyProgram.workshopLoyaltyProgram',
    },
    STATUTE: {
        key: 'Statute',
        divId: 'statute-tile',
        translateValue: 'workshopLoyaltyProgram.statute',
    },
    HISTORY: {
        key: 'History',
        divId: 'history-tile',
        translateValue: 'workshopLoyaltyProgram.history.header',
    },
    PRODUCTS_FOR_POINTS: {
        key: 'ProductsForPoints',
        divId: 'products-for-points-tile',
        translateValue: 'workshopLoyaltyProgram.productsForPoints.header',
    },
    SCORED_PRODUCTS: {
        key: 'scoredProducts',
        divId: 'scoredProducts-tile',
        translateValue: 'workshopLoyaltyProgram.scoredProducts.header',
    },
};


export const WorkshopLoyaltyProgram = () => {
    const [subsectionKeyActive, setSubsectionKeyActive] = useState(LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM.key);
    const [isPreventRerenderFetching, setIsPreventRerenderFetching] = useState(true);
    const dispatch = useDispatch();
    const isLoyaltyStatuteModalOpen = useSelector(state => state.statute.isLoyaltyStatuteModalOpen);
    const registrationData = useSelector(state => state.workshopLoyaltyProgram.registrationData);
    const registartionFetching = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);
    const workshopData = useSelector(state => state.workshop.data);
    const workshopLoyaltyProgramActiveTile = useSelector(state => state.workshopLoyaltyProgram.activeWorkshopLoyaltyProgramTile);
    const [isStatuteAccepted, setIsStatuteAccepted] = useState(false);
    const [newStatuteAcceptationError, setNewStatuteAcceptationError] = useState(false);
    const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
    const isActive = (subsection) => {
        return subsectionKeyActive === subsection.key
            ? 'subsection-tile-active '
            : '';
    }
    useEffect(() => {
        if (workshopData && workshopData.workshopId) {
            dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramRegistrationData(workshopData.workshopId));
            dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramPointsData(workshopData.workshopId));
            dispatch(statuteActions.checkValidStatuteAcceptation(workshopData.workshopId));
        }
    }, [workshopData])

    useEffect(() => {
        dispatch(statuteActions.findLoyaltyProgramStatutes());
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (isPreventRerenderFetching) {
                setIsPreventRerenderFetching(false)
            }
        }, 1000);
    }, [])

    useEffect(() => {
        if (workshopLoyaltyProgramActiveTile) {
            setSubsectionKeyActive(workshopLoyaltyProgramActiveTile);
            dispatch(workshopLoyaltyProgramActions.setActiveWorkshopLoyaltyProgramTile(null));
        }
    }, [workshopLoyaltyProgramActiveTile]);

    const renderTile = (subsection, iconName, spacingClassesApply = true) => {
        const commonClasses =
        'subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3 mt-3 ';
      const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';
        return (
            <div
                id={subsection.divId}
                className={commonClasses + spacingClasses + isActive(subsection)}
                onClick={e => setSubsectionKeyActive(subsection.key)}
            >
                <i className={'fa fa-3x ' + iconName} />
                <Translate value={subsection.translateValue} />
            </div>
        );
    }

    const acceptNewStatute = () => {
        if (isStatuteAccepted) {
            dispatch({
                type: updateDataSideBarConstants.HIDE,
            })
            dispatch({ type: statuteConstants.LOYALTY_STATUTE_MODAL_OPEN, payload: false });
            dispatch(statuteActions.acceptNewStatute(workshopData.workshopId))
            setNewStatuteAcceptationError(!isStatuteAccepted);
        } else setNewStatuteAcceptationError(true)
    }

    const renderTiles = () => {
        return (
            <div className={styles.TilesContainer}>
                {renderTile(LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM, 'fa-diamond')}
                {renderTile(
                    LoyaltyProgramContainerSubsection.STATUTE,
                    'fa-book',
                )}
                {renderTile(
                    LoyaltyProgramContainerSubsection.HISTORY,
                    'fa-history',
                )}
                {renderTile(LoyaltyProgramContainerSubsection.PRODUCTS_FOR_POINTS, 'fa-gift', false)}
                {renderTile(
                    LoyaltyProgramContainerSubsection.SCORED_PRODUCTS,
                    'fa-list-alt ',
                )}
            </div>
        );
    }

    const renderSubsection = () => {
        switch (subsectionKeyActive) {
            case LoyaltyProgramContainerSubsection.LOYALTY_PROGRAM.key:
                return <WorkshopLoyaltyProgramEditForm />;
            case LoyaltyProgramContainerSubsection.STATUTE.key:
                return <Statute />;
            case LoyaltyProgramContainerSubsection.HISTORY.key:
                return <WorkshopLoyaltyProgramHistory />;
            case LoyaltyProgramContainerSubsection.PRODUCTS_FOR_POINTS.key:
                return <ProductsForPoints />;
            case LoyaltyProgramContainerSubsection.SCORED_PRODUCTS.key:
                return <ScoredProducts />;
        }
    }

    const renderRegisterButton = () => {
        return !registrationData && !isPreventRerenderFetching && !registartionFetching &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                <Button
                    style={{ width: "14.7rem", backgroundColor: '#CC0000' }}
                    onClick={() => {
                        dispatch({
                            type: updateDataSideBarConstants.SHOW,
                            variableComponent: <WorkshopLoyaltyProgramRegister />,
                            title: I18n.t("workshopLoyaltyProgram.registration"),
                        });
                    }}
                >
                    <Translate value='workshopLoyaltyProgram.registerLoyaltyProgram' />
                </Button>
            </div>
    }

    const renderNewStatuteAcceptationModal = () => {
        return <Modal isOpen={workshopData && loyaltyProgramStatute && isLoyaltyStatuteModalOpen} style={{ border: 'none' }} centered={true}>
            <ModalHeader>
                {I18n.t("workshopLoyaltyProgram.newStatuteAcceptation")}
            </ModalHeader>
            <ModalBody>
                <div style={{ fontFamily: 'Montserrat-Medium' }}>
                    {I18n.t("workshopLoyaltyProgram.newStatuteText")}
                </div>
                <div style={{ marginTop: '1rem' }}>
                    <Checkbox
                        classname='mr-1'
                        onChange={(event) => {
                            setIsStatuteAccepted(event.checked);
                            (newStatuteAcceptationError && event.checked) ? setNewStatuteAcceptationError(false) : null;
                        }}
                        checked={isStatuteAccepted}
                        value={isStatuteAccepted}
                    />
                    <span style={{ paddingLeft: 9 }}>{I18n.t('workshopLoyaltyProgram.register.statuteAccepted')}</span>
                    {loyaltyProgramStatute &&
                        <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
                            <span style={{ paddingLeft: 4 }}>{I18n.t('workshopLoyaltyProgram.register.statute')}</span>
                        </a>}
                    <div id="error-msg" className="col-12" style={{ minHeight: 24, paddingLeft: 0 }}>
                        {newStatuteAcceptationError &&
                            <span className="error-info">
                                <Translate value={"workshopLoyaltyProgram.register.acceptation"} />
                            </span>
                        }
                    </div>
                </div>
                <div className='text-sm-right text-center'>
                    <Button
                        onClick={acceptNewStatute}
                        id='confirm-btn'
                        className='main-btn'>
                        <Translate value={"workshopLoyaltyProgram.statuteAccepted"} />
                    </Button>
                    <Button
                        onClick={() => dispatch({ type: statuteConstants.LOYALTY_STATUTE_MODAL_OPEN, payload: false })}
                        id='cancel-btn'
                        className='main-btn'>
                        <Translate value={"workshopLoyaltyProgram.notifications.no"} />
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    }

    return (
        <>
            {renderTiles()}
            {renderRegisterButton()}
            {renderSubsection()}
            {renderNewStatuteAcceptationModal()}
        </>
    )
}

