/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {FileUpload} from 'primereact/fileupload';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Input} from 'reactstrap';
import {statuteActions} from 'shared/actions/statute.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {privilegeConstants} from 'shared/constants/privilege.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {becameObsolete} from 'shared/utils/sync';
import {indexArray, rowsQuantityToDisplay} from 'shared/utils/utils';
import ChooseCountryModal from './ChooseCountryModal';
import {StatuteDetails} from './statute-details/StatuteDetails';
import './statutes.css';

class Statutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: '',
      rows: 10,
      statuteFileUploadKey: 0,
      chooseCountryModalOpen: false,
      uploadedStatute: null,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.showDetails = this.showDetails.bind(this);
    this.createStatute = this.createStatute.bind(this);
    this.createStatuteWithCountry = this.createStatuteWithCountry.bind(this);
    this.rerenderStatuteFileUpload = this.rerenderStatuteFileUpload.bind(this);
    this.openChooseCountryModal = this.openChooseCountryModal.bind(this);
    this.onModalCancel = this.onModalCancel.bind(this);
  }

  componentDidMount() {
    this.props.findAllStatutes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      becameObsolete(
        prevProps.statutesDataSyncState,
        this.props.statutesDataSyncState
      )
    )
      this.props.findAllStatutes();
  }

  showDetails(event) {
    this.props.findStatuteById(event.data.id);
    const details = <StatuteDetails />;
    setTimeout(
      () =>
        this.props.showUpdateDataSideBar(
          details,
          I18n.t('statutes.detailsTitle')
        ),
      10
    );
  }

  createStatuteWithCountry(country) {
    this.props.createStatute(this.state.uploadedStatute, country);
    this.setState({chooseCountryModalOpen: false, uploadedStatute: null});
  }

  createStatute(event) {
    this.props.createStatute(event.formData);
  }

  openChooseCountryModal(event) {
    if (userService.isDistributor()) {
      this.createStatute(event);
    } else {
      this.setState({
        chooseCountryModalOpen: true,
        uploadedStatute: event.formData,
      });
    }
  }

  onModalCancel() {
    this.setState({chooseCountryModalOpen: false, uploadedStatute: null});
  }

  rerenderStatuteFileUpload() {
    setTimeout(() => this.setState({statuteFileUploadKey: Math.random()}), 100);
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        onChange={e => this.onRowsChange(e)}
      />
    );
  }

  onRowsChange(e) {
    this.setState({rows: e.value});
    this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  renderAddStatuteBtn() {
    return (
      <div id='add-statute-btn' className='data-table-button'>
        <FileUpload
          key={this.state.statuteFileUploadKey}
          mode='basic'
          name='file'
          accept='.pdf'
          auto={true}
          onBeforeSend={this.openChooseCountryModal}
          onError={this.rerenderStatuteFileUpload}
          url='not-used'
          chooseLabel={I18n.t('statutes.addStatute')}
        />
      </div>
    );
  }

  renderSearchInput() {
    return (
      <div className='col-sm-4 col-6 px-1 order-3'>
        <Input
          type='search'
          placeholder={I18n.t('statutes.searchPlaceholder')}
          onInput={e => this.setState({globalFilter: e.target.value})}
          className='global-filter m-1'
        />
      </div>
    );
  }

  statusColumn(rowData) {
    return rowData.status
      ? I18n.t('statutes.' + rowData.status.toLowerCase())
      : rowData.status;
  }

  countryColumn(rowData) {
    return rowData.country
      ? I18n.t('country.' + rowData.country.toLowerCase())
      : rowData.country;
  }

  shouldRenderAddStatuteBtn() {
    return (
      userService.isAdmin() ||
      (userService.isDistributor() &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT))
    );
  }

  renderChooseCountryModal() {
    return (
      <ChooseCountryModal
        createStatuteWithCountry={this.createStatuteWithCountry}
        isOpen={this.state.chooseCountryModalOpen}
        handleCancel={this.onModalCancel}
      />
    );
  }

  render() {
    const header = (
      <div className='row'>
        <div className='col-sm-1 col-1 mr-auto order-1' />
        {this.renderSearchInput()}
      </div>
    );

    return (
      <ContentWrapper title='sideBarNav.statutes'>
        <Loadable active={this.props.isFetching} spinner>
          {this.renderChooseCountryModal()}
          <div className='col'>
            <DataTable
              value={indexArray(this.props.statutes)}
              paginator={true}
              rows={this.state.rows}
              header={header}
              className='data-table-padding-class'
              responsive={true}
              globalFilter={this.state.globalFilter}
              onRowClick={this.showDetails}
              emptyMessage={I18n.t('statutes.emptyPlaceholder')}
              paginatorLeft={this.renderRowsDropdown()}
              ref={this.setDatatableRef}
              paginatorRight={
                this.shouldRenderAddStatuteBtn()
                  ? this.renderAddStatuteBtn()
                  : null
              }>
              <Column
                field='index'
                className='lp-col'
                header={I18n.t('statutes.lp')}
                sortable={true}
              />
              <Column
                field='fileName'
                header={I18n.t('statutes.fileName')}
                sortable={true}
              />
              {userService.isAdmin() && (
                <Column
                  field='country'
                  header={I18n.t('statutes.country')}
                  sortable={true}
                  body={this.countryColumn}
                />
              )}
              <Column
                field='status'
                header={I18n.t('statutes.status')}
                style={{width: '25%'}}
                sortable={true}
                body={this.statusColumn}
              />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, statute} = state;
  const {isFetching, statutesDataSyncState, statutes} = statute;

  return {i18n, isFetching, statutesDataSyncState, statutes};
}

const mapDispatchToProps = {
  createStatute: statuteActions.createStatute,
  findAllStatutes: statuteActions.findAllStatutes,
  findStatuteById: statuteActions.findStatuteById,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

const connectedStatutes = connect(
  mapStateToProps,
  mapDispatchToProps
)(Statutes);
export {connectedStatutes as Statutes};
