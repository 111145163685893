/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {statisticActions} from 'shared/actions/statistic.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import LpgStatistics from './statistics-content/LpgStatistics';
import OrderStatistics from './statistics-content/OrderStatistics';
import ValuationInquiryStatistics from './statistics-content/ValuationInquiryStatistics';
import ValuationStatistics from './statistics-content/ValuationStatistics';
import {StatisticsEnum} from './utils';

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statisticsView: StatisticsEnum.VALUATION_INQUIRY,
      searchAreaVisible: false,
    };
    this.toggleSearch = this.toggleSearch.bind(this);
    this.renderTotalCountArea = this.renderTotalCountArea.bind(this);
    this.saveToPdf = this.saveToPdf.bind(this);
  }

  toggleSearch() {
    this.setState({searchAreaVisible: !this.state.searchAreaVisible});
  }

  resolveTotalCountLabel() {
    switch (this.state.statisticsView) {
      case StatisticsEnum.VALUATION_INQUIRY:
        return I18n.t('statistics.totalValuationInquiryCountLabel');
      case StatisticsEnum.VALUATION:
        return I18n.t('statistics.totalValuationCountLabel');
      case StatisticsEnum.LPG:
        return I18n.t('statistics.totalLpgCountLabel');
      case StatisticsEnum.ORDER:
        return I18n.t('statistics.totalOrderCountLabel');
    }
  }

  saveToPdf(data) {
    switch (this.state.statisticsView) {
      case StatisticsEnum.VALUATION_INQUIRY:
        return this.props.generateValuationInquiryStatisticsPdf(data);
      case StatisticsEnum.VALUATION:
        return this.props.generateValuationStatisticsPdf(data);
      case StatisticsEnum.LPG:
        return this.props.generateLpgStatisticsPdf(data);
      case StatisticsEnum.ORDER:
        return this.props.generateOrderStatisticsPdf(data);
    }
  }

  getSubsectionTitle() {
    switch (this.state.statisticsView) {
      case StatisticsEnum.VALUATION_INQUIRY:
        return 'statistics.valuationInquiryStatisticsList';
        break;
      case StatisticsEnum.VALUATION:
        return 'statistics.valuationStatisticsList';
        break;
      case StatisticsEnum.LPG:
        return 'statistics.LPGStatisticsList';
        break;
      case StatisticsEnum.ORDER:
        return 'statistics.orderStatisticsList';
        break;
    }
  }

  renderTotalCountArea() {
    const {totalCount} = this.props;
    return (
      <Alert color='secondary' className='text-center m-3'>
        {this.resolveTotalCountLabel() + ' ' + totalCount}
      </Alert>
    );
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        <div
          id='valuation-inquiry-statistics-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
					 ${
             this.state.statisticsView === StatisticsEnum.VALUATION_INQUIRY &&
             'subsection-tile-active'
           } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({
              statisticsView: StatisticsEnum.VALUATION_INQUIRY,
              searchAreaVisible: false,
            })
          }>
          <i className='fa fa-3x fa-braille' />
          <Translate value='statistics.valuationInquiryStatisticsList' />
        </div>
        <div
          id='valuation-statistics-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
					 ${
             this.state.statisticsView === StatisticsEnum.VALUATION &&
             'subsection-tile-active'
           } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({
              statisticsView: StatisticsEnum.VALUATION,
              searchAreaVisible: false,
            })
          }>
          <i className='fa fa-3x fa-signal' />
          <Translate value='statistics.valuationStatisticsList' />
        </div>
        <div
          id='lpg-statistics-tile'
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3
					 ${
             this.state.statisticsView === StatisticsEnum.LPG &&
             'subsection-tile-active'
           } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({
              statisticsView: StatisticsEnum.LPG,
              searchAreaVisible: false,
            })
          }>
          <i className='fa fa-3x fa-align-center' />
          <Translate value='statistics.LPGStatisticsList' />
        </div>
        <div
          id='order-statistics-tile'
          className={`subsection-tile shadow col-sm col-12
					 ${
             this.state.statisticsView === StatisticsEnum.ORDER &&
             'subsection-tile-active'
           } + isContentLoaded(this.props.isFetching)`}
          onClick={() =>
            this.setState({
              statisticsView: StatisticsEnum.ORDER,
              searchAreaVisible: false,
            })
          }>
          <i className='fa fa-3x fa-list' />
          <Translate value='statistics.orderStatisticsList' />
        </div>
      </div>
    );
  }

  renderContent() {
    switch (this.state.statisticsView) {
      case StatisticsEnum.VALUATION_INQUIRY:
        return (
          <ValuationInquiryStatistics
            searchAreaVisible={this.state.searchAreaVisible}
            totalCountArea={this.renderTotalCountArea}
            saveToPdf={this.saveToPdf}
          />
        );
      case StatisticsEnum.VALUATION:
        return (
          <ValuationStatistics
            searchAreaVisible={this.state.searchAreaVisible}
            totalCountArea={this.renderTotalCountArea}
            saveToPdf={this.saveToPdf}
          />
        );
      case StatisticsEnum.LPG:
        return (
          <LpgStatistics
            searchAreaVisible={this.state.searchAreaVisible}
            totalCountArea={this.renderTotalCountArea}
            saveToPdf={this.saveToPdf}
          />
        );
      case StatisticsEnum.ORDER:
        return (
          <OrderStatistics
            searchAreaVisible={this.state.searchAreaVisible}
            totalCountArea={this.renderTotalCountArea}
            saveToPdf={this.saveToPdf}
          />
        );
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        <ContentWrapper title={this.getSubsectionTitle()}>
          <div className='text-center my-3 mx-3'>
            <Button
              className='main-btn m-1'
              size='sm'
              onClick={this.toggleSearch}>
              <Translate
                value={
                  this.state.searchAreaVisible
                    ? 'statistics.hideSearchArea'
                    : 'statistics.showSearchArea'
                }
              />
            </Button>
          </div>
          {this.renderContent()}
        </ContentWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = {
  generateValuationInquiryStatisticsPdf:
    statisticActions.generateValuationInquiryStatisticsPdf,
  generateValuationStatisticsPdf:
    statisticActions.generateValuationStatisticsPdf,
  generateLpgStatisticsPdf: statisticActions.generateLpgStatisticsPdf,
  generateOrderStatisticsPdf: statisticActions.generateOrderStatisticsPdf,
};

function mapStateToProps(state) {
  const {statistic} = state;
  const {totalCount, isFetching} = statistic;

  return {totalCount, isFetching};
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
