/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {reduxForm} from 'redux-form';
import SubmitButton from 'shared/components/form/submit-button/SubmitButton';
import AddressDataSection from '../../../admin/distributors/form/sections/address-data-section/AddressDataSection';
import {validateAddressForm} from './validators/validators';

class DistributorAddressForm extends React.Component {
  render() {
    const {handleSubmit, invalid, pristine} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributorProfile.editAddressHeader' />
        </h3>
        <AddressDataSection />
        <SubmitButton disabled={invalid || pristine} />
      </form>
    );
  }
}

DistributorAddressForm = reduxForm({
  form: 'distributorAddressForm',
  validate: validateAddressForm,
  enableReinitialize: true,
})(DistributorAddressForm);

function mapStateToProps(state) {
  const {distributor} = state;
  const {selectedDistributor} = distributor;
  let initialValues = selectedDistributor ? selectedDistributor.address : {};
  initialValues.companyName = selectedDistributor
    ? selectedDistributor.details.companyName
    : '';
  initialValues.ownerName = selectedDistributor
    ? selectedDistributor.details.ownerName
    : '';
  return {i18n: state.i18n, initialValues};
}

export default connect(mapStateToProps)(DistributorAddressForm);
