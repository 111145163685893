/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import {ColorPicker} from 'primereact/colorpicker';

export class RenderColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.input.onChange(`#${e.value}`);
  }

  render() {
    const {label, required, input} = this.props;

    return (
      <div
        style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div className='read-only-label mr-2'>
          {label}
          {required && <span style={{color: 'red'}}>*</span>}
        </div>
        <ColorPicker
          value={input.value}
          onChange={this.handleChange}
          overlay
          defaultColor='#22BAA0'
        />
      </div>
    );
  }
}

RenderColorPicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

RenderColorPicker.defaultProps = {
  required: false,
};
