/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withWorkshopValuationsTableCore} from '../utils/ValuationsCoreHoc';
import {ValuationInquiryDetails} from '../valuation-inquiry-details/ValuationInquiryDetails';

class ValuationInquiriesNoResponse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isFirstFetch: true};

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.showDetails = this.showDetails.bind(this);
    this.sortByInquiryNumber = this.sortByInquiryNumber.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId} = this.props;
    if (loggedWorkshopId && this.state.isFirstFetch) {
      this.setState({isFirstFetch: false});
      this.props.findNoResponseValuationInquiriesByWorkshopId(loggedWorkshopId);
    }

    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  showDetails(event) {
    this.props.findValuationInquiryById(event.data.id);
    const details = (
      <ValuationInquiryDetails shouldShowWorkshopStatusAlert={false} />
    );
    const title =
      I18n.t('valuationInquiryDetails.detailsHeader') +
      ' ' +
      event.data.inquiryNumber.inquiryNumberString;
    setTimeout(() => this.props.showUpdateDataSideBar(details, title), 10);
  }

  sortByInquiryNumber(event) {
    return this.props.sortByInquiryNumber(this.props.valuationInquiries, event);
  }

  render() {
    return (
      <ContentWrapper title='valuations.noResponseValuationsBtn'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='col'>
            <DataTable
              value={this.props.valuationInquiries}
              paginator={true}
              rows={this.props.rows}
              header={this.props.header}
              globalFilter={this.props.globalFilter}
              className='p-3'
              responsive={true}
              onRowClick={this.showDetails}
              emptyMessage={I18n.t(
                'valuationInquiriesNoResponse.emptyPlaceholder'
              )}
              paginatorLeft={this.props.renderRowsDropdown()}
              ref={this.setDatatableRef}>
              <Column
                field='inquiryNumber.inquiryNumberString'
                header={I18n.t('valuationInquiriesNoResponse.id')}
                sortable='custom'
                sortFunction={this.sortByInquiryNumber}
              />
              <Column
                field='userDetails'
                sortable={true}
                header={I18n.t('waitingValuations.userDetails')}
                body={this.props.renderUserDetailsCol}
              />
              <Column
                field='sendDate'
                sortable={true}
                header={I18n.t('valuationInquiriesNoResponse.sendDate')}
                body={this.props.renderDateCol}
              />
              <Column
                field='carData'
                header={I18n.t('valuationInquiriesNoResponse.carData')}
                sortable={true}
                body={this.props.renderCarDataCol}
              />
              <Column
                field='origin'
                header={I18n.t('valuationInquiriesNoResponse.origin')}
                sortable={true}
                body={this.props.renderOriginCol}
              />
              <Column
                field='endDate'
                header={I18n.t('valuationInquiriesNoResponse.endDate')}
                sortable={true}
                body={this.props.renderDateCol}
              />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, order, workshop} = state;
  const {valuationInquiries} = order;
  const {data} = workshop;
  const loggedWorkshopId = data ? data.workshopId : null;

  return {i18n, valuationInquiries, loggedWorkshopId};
}

const mapDispatchToProps = {
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findNoResponseValuationInquiriesByWorkshopId:
    orderActions.findAllNoResponseValuationInquiriesByWorkshopId,
  findValuationInquiryById: orderActions.findValuationInquiryById,
};

//export for testing purpose
export const ConnectedValuationInquiriesNoResponseWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiriesNoResponse);

export default withWorkshopValuationsTableCore(
  ConnectedValuationInquiriesNoResponseWithRedux
);
