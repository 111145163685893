/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as XLSX from 'sheetjs-style';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import {DATE_SHORT_FORMAT_WITH_HOUR, dateUtils} from '../date.utils';

const getCellStyles = cellType => {
  let styles = {
    border: {
      left: {
        style: 'thin',
        color: {
          auto: 1,
        },
      },
      top: {
        style: 'thin',
        color: {
          auto: 1,
        },
      },
      bottom: {
        style: 'thin',
        color: {
          auto: 1,
        },
      },
      right: {
        style: 'thin',
        color: {
          auto: 1,
        },
      },
    },
  };
  switch (cellType) {
    case 'header': {
      styles = {
        ...styles,
        font: {
          bold: true,
        },
        fill: {
          fgColor: {rgb: 'ffb2b2b2'},
        },
      };
      return styles;
    }
    default: {
      return styles;
    }
  }
};

const isDateBetween = (startDate, stopDate, testDate) => {
  if (startDate && stopDate) {
    return testDate.isBetween(startDate, stopDate, 'days', '[]');
  } else if (startDate) {
    return testDate.isSameOrAfter(startDate, 'days');
  } else if (stopDate) {
    return testDate.isSameOrBefore(stopDate, 'days');
  } else return true;
};

const isDateSameOrBefore = (stopDate, testDate) => {
  if (stopDate) {
    return testDate.isSameOrBefore(stopDate, 'days');
  } else return true;
};

export const generateReportWithPrizes = (workshops, startDate, stopDate) => {
  const filteredWorkshops = workshops.filter(workshop => {
    const workshopRegistrationDate = moment(
      workshop.workshopLoyalty.registrationDate
    );
    return isDateSameOrBefore(stopDate, workshopRegistrationDate);
  });

  var wb = {};
  wb.Sheets = {};
  wb.Props = {};
  wb.SSF = {};
  wb.SheetNames = [];

  var ws = {
    '!cols': [],
  };

  var range = {
    s: {c: 0, r: 0},
    e: {c: 0, r: 0},
  };

  var cell;
  var cell_ref = XLSX.utils.encode_cell({c: 0, r: 0});
  cell = {
    v: I18n.t('workshopLoyaltyProgram.reports.numberOfLoyaltyProgramMembers'),
  };
  cell.s = getCellStyles('');
  ws[cell_ref] = cell;
  cell_ref = XLSX.utils.encode_cell({c: 1, r: 0});
  cell = {v: filteredWorkshops.length};
  cell.s = getCellStyles('');
  ws[cell_ref] = cell;

  let allPrizesSum = 0;
  const headers = [
    I18n.t('workshopLoyaltyProgram.reports.lp'),
    I18n.t('workshopLoyaltyProgram.reports.workshopName'),
    I18n.t('workshopLoyaltyProgram.reports.workshopWorker'),
    I18n.t('workshopLoyaltyProgram.reports.address'),
    I18n.t('workshopLoyaltyProgram.reports.city'),
    I18n.t('workshopLoyaltyProgram.reports.postalCode'),
    I18n.t('workshopLoyaltyProgram.reports.email'),
    I18n.t('workshopLoyaltyProgram.reports.workshopType'),
    I18n.t('workshopLoyaltyProgram.reports.nip'),
    I18n.t('workshopLoyaltyProgram.reports.phone'),
    I18n.t('workshopLoyaltyProgram.reports.registrationDate'),
    I18n.t('workshopLoyaltyProgram.reports.statuteAcceptance'),
    I18n.t('workshopLoyaltyProgram.reports.dateOfStatuteAcceptance'),
    I18n.t('workshopLoyaltyProgram.reports.pointsSum'),
  ];
  const data = filteredWorkshops.map((workshop, idx) => {
    const workshopPrizes = workshop.workshopLoyaltyHistory;
    const filteredExchanges = workshopPrizes
      ? workshopPrizes.filter(exchange => {
          const prizesCollectDate = moment(exchange.eventDate);
          return isDateBetween(startDate, stopDate, prizesCollectDate);
        })
      : [];
    const prizesSum = filteredExchanges.reduce((a, b) => a + Number(b.totalEventPoints), 0);
    allPrizesSum += prizesSum;
    const workshopData = workshop.workshopLoyalty;
    return [
      idx + 1,
      workshopData.workshopName,
      workshopData.workshopWorker,
      workshopData.address.streetAddress,
      workshopData.address.city,
      workshopData.address.postalCode,
      workshopData.email,
      workshopData.workshopHomologationType,
      workshopData.nip,
      workshopData.phoneNumber,
      workshopData.registrationDate.slice(0, 10),
      workshopData.statuteAccepted
        ? I18n.t('workshopLoyaltyProgram.reports.accepted')
        : I18n.t('workshopLoyaltyProgram.reports.notAccepted'),
      workshopData.statuteAccepted
        ? workshopData.statuteAcceptanceDate.slice(0, 10)
        : '',
      prizesSum,
    ];
  });

  cell_ref = XLSX.utils.encode_cell({c: 0, r: 1});
  cell = {v: I18n.t('workshopLoyaltyProgram.reports.pointsSumLabel')};
  cell.s = getCellStyles('');
  ws[cell_ref] = cell;
  cell_ref = XLSX.utils.encode_cell({c: 1, r: 1});
  cell = {v: allPrizesSum};
  cell.s = getCellStyles('');
  ws[cell_ref] = cell;

  ws['!cols'].push({wch: 25});
  ws['!cols'].push({wch: 27});
  ws['!cols'].push({wch: 38});
  ws['!cols'].push({wch: 25});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 15});
  ws['!cols'].push({wch: 38});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 27});
  ws['!cols'].push({wch: 27});
  ws['!cols'].push({wch: 27});

  for (var r = 3; r <= 3 + data.length; r++) {
    if (range.e.r < r + 1) range.e.r = r + 1;
    for (var c = 0; c < 14; c++) {
      if (range.e.c < c) range.e.c = c;
      cell_ref = XLSX.utils.encode_cell({c: c, r: r});
      if (r === 3) {
        cell = {v: headers[c]};
        cell.s = getCellStyles('header');
      } else {
        cell = {v: data[r - 4][c]};
        cell.s = getCellStyles('');
      }
      ws[cell_ref] = cell;
    }
  }

  ws['!ref'] = XLSX.utils.encode_range(range);
  const sheetName = I18n.t('workshopLoyaltyProgram.reports.summary');
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;

  const prizesHeaders = [
    I18n.t('workshopLoyaltyProgram.reports.prizeName'),
    I18n.t('workshopLoyaltyProgram.reports.prizeCollectingDate'),
    I18n.t('workshopLoyaltyProgram.reports.prizePoints'),
    I18n.t('workshopLoyaltyProgram.workshops.count')
  ];

  filteredWorkshops.forEach((workshop, idx) => {
    ws = {
      '!cols': [],
    };
    ws['!cols'].push({wch: 30});
    ws['!cols'].push({wch: 25});
    ws['!cols'].push({wch: 25});
    ws['!cols'].push({wch: 25});

    let workshopPrizes = workshop.workshopLoyaltyHistory;
    let filteredPrizes = [];
    if (workshopPrizes) {
      const filteredExchanges = workshopPrizes
        ? workshopPrizes.filter(exchange => {
            const prizesCollectDate = moment(exchange.eventDate);
            return isDateBetween(startDate, stopDate, prizesCollectDate);
          })
        : [];
      filteredPrizes =
        filteredExchanges.length > 0
          ? filteredExchanges.reduce(
              (a, b) => [
                ...a,
                ...b.eventProducts.map(item => ({
                  ...item,
                  date: b.eventDate,
                })),
              ],
              []
            )
          : [];
    }
    range = {
      s: {c: 0, r: 0},
      e: {c: 0, r: 0},
    };

    const prizesData = filteredPrizes.map(prize => {
      return [prize.productName, prize.date.slice(0, 10), prize.value, prize.count];
    });

    if (filteredPrizes.length > 0) {
      for (var r = 0; r <= filteredPrizes.length; r++) {
        if (range.e.r < r + 1) range.e.r = r + 1;
        for (var c = 0; c < 4; c++) {
          if (range.e.c < c) range.e.c = c;
          cell_ref = XLSX.utils.encode_cell({c: c, r: r});
          if (r === 0) {
            cell = {v: prizesHeaders[c]};
            cell.s = getCellStyles('header');
          } else {
            cell = {v: prizesData[r - 1][c]};
            cell.s = getCellStyles('');
          }
          ws[cell_ref] = cell;
        }
      }
      ws['!ref'] = XLSX.utils.encode_range(range);
      const sheetName = `${idx + 1}.`;
      wb.SheetNames.push(sheetName);
      wb.Sheets[sheetName] = ws;
    }
  });
  var wopts = {bookType: 'xlsx', cellStyles: true};
  var OUTFILE = `${I18n.t('workshopLoyaltyProgram.reports.report')}.xlsx`;
  XLSX.writeFile(wb, OUTFILE, wopts);
};

export const generateWorkshopsBlockingHistoryReport = (
  workshopsParticipationBlockHistory,
  startDate,
  stopDate
) => {
  const filteredWorkshops = workshopsParticipationBlockHistory.filter(
    workshop => {
      const eventChangeDate = moment(workshop.eventDateTime);
      return isDateBetween(startDate, stopDate, eventChangeDate);
    }
  );

  var wb = {};
  wb.Sheets = {};
  wb.Props = {};
  wb.SSF = {};
  wb.SheetNames = [];

  var ws = {
    '!cols': [],
  };

  var range = {
    s: {c: 0, r: 0},
    e: {c: 0, r: 0},
  };

  var cell;

  const headers = [
    I18n.t('workshopLoyaltyProgram.reports.workshopName'),
    I18n.t('workshopLoyaltyProgram.reports.email'),
    I18n.t('workshopLoyaltyProgram.reports.address'),
    I18n.t('workshopLoyaltyProgram.reports.city'),
    I18n.t('workshopLoyaltyProgram.reports.postalCode'),
    I18n.t('workshopLoyaltyProgram.workshops.eventType'),
    I18n.t('workshopLoyaltyProgram.workshops.eventDate'),
  ];

  const data = filteredWorkshops.map(workshop => {
    return [
      workshop.workshopName,
      workshop.workshopEmail,
      workshop.workshopAddress,
      workshop.workshopCity,
      workshop.workshopPostalCode,
      workshop.event === 'locked'
        ? I18n.t('workshopLoyaltyProgram.workshops.blocked')
        : I18n.t('workshopLoyaltyProgram.workshops.unblocked'),
      dateUtils.formatDate(workshop.eventDateTime, DATE_SHORT_FORMAT_WITH_HOUR),
    ];
  });

  ws['!cols'].push({wch: 32});
  ws['!cols'].push({wch: 38});
  ws['!cols'].push({wch: 25});
  ws['!cols'].push({wch: 20});
  ws['!cols'].push({wch: 15});
  ws['!cols'].push({wch: 15});
  ws['!cols'].push({wch: 20});

  for (var r = 0; r <= data.length; r++) {
    if (range.e.r < r + 1) range.e.r = r + 1;
    for (var c = 0; c < 7; c++) {
      if (range.e.c < c) range.e.c = c;
      var cell_ref = XLSX.utils.encode_cell({c: c, r: r});
      if (r === 0) {
        cell = {v: headers[c]};
        cell.s = getCellStyles('header');
      } else {
        cell = {v: data[r - 1][c]};
        cell.s = getCellStyles('');
      }
      ws[cell_ref] = cell;
    }
  }

  ws['!ref'] = XLSX.utils.encode_range(range);
  const sheetName = I18n.t(
    'workshopLoyaltyProgram.reports.workshopsBlockingHistory'
  );
  wb.SheetNames.push(sheetName);
  wb.Sheets[sheetName] = ws;

  var wopts = {bookType: 'xlsx', cellStyles: true};
  var OUTFILE = `${I18n.t('workshopLoyaltyProgram.reports.report')}.xlsx`;
  XLSX.writeFile(wb, OUTFILE, wopts);
};
