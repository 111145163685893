/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  containsOnlyWhiteSpaces,
  validateByLengthOnly,
  validateRequiredFieldsWithMaxLength,
} from '../../form/common-validators/common-validators';
import ValidationMessageEnum from '../../../utils/utils';
import moment from 'moment';

export const validate = values => {
  let errors = {};
  errors.title = validateRequiredFieldsWithMaxLength(values.title, 60);
  errors.start = validateStartDate(values);
  errors.end = validateEndDate(values);
  errors.workstation = validateByLengthOnly(values.workstation, 60);
  return errors;
};

const validateStartDate = values => {
  const startDate = values.start;
  if (!startDate || containsOnlyWhiteSpaces(startDate))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};

  const startDateObj = moment(startDate);

  if (startDateObj.isSameOrAfter(moment(values.end)))
    return {key: 'workshopCalendarEvent.dateAfterEnd', params: {}};
};

const validateEndDate = values => {
  const endDate = values.end;
  if (!endDate || containsOnlyWhiteSpaces(endDate))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};

  const endDateObj = moment(endDate);

  if (endDateObj.isSameOrBefore(moment(values.start)))
    return {key: 'workshopCalendarEvent.dateBeforeStart', params: {}};
};
