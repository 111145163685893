/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateConfirmPassword,
  validateEmail,
  validateNumberWithRange,
  validatePassword,
  validatePhoneNumber,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';
import {distributorService} from 'shared/services/distributor.service';

export const validate = values => {
  let errors = {
    userDto: {access: {}},
    address: {},
    access: {},
    details: {},
  };
  errors.language = validateRequiredFields(values.language);
  errors.country = validateRequiredFields(values.country);

  errors.userDto.status = validateRequiredFields(
    values.userDto && values.userDto.status
  );
  errors.userDto.email = validateEmail(values.userDto && values.userDto.email);
  errors.userDto.password = validatePassword(
    values.userDto && values.userDto.password
  );
  errors.userDto.confirmPassword = validateConfirmPassword({
    password: values.userDto && values.userDto.password,
    confirmPassword: values.userDto && values.userDto.confirmPassword,
  });

  errors.address.companyName = validateRequiredFieldsWithMaxLength(
    values.address && values.address.companyName,
    100
  );
  errors.address.ownerName = validateRequiredFieldsWithMaxLength(
    values.address && values.address.ownerName,
    50
  );
  errors.address.city = validateByLengthOnly(
    values.address && values.address.city,
    60
  );
  errors.address.postalCode = validateByLengthOnly(
    values.address && values.address.postalCode,
    10
  );
  errors.address.postalCity = validateByLengthOnly(
    values.address && values.address.postalCity,
    60
  );
  errors.address.streetAddress = validateByLengthOnly(
    values.address && values.address.streetAddress,
    50
  );

  errors.details.phoneNumber = validatePhoneNumber(
    values.details && values.details.phoneNumber
  );
  errors.details.mobilePhoneNumber = validatePhoneNumber(
    values.details && values.details.mobilePhoneNumber
  );
  errors.details.faxNumber = validatePhoneNumber(
    values.details && values.details.faxNumber
  );
  errors.details.nip = validateByLengthOnly(
    values.details && values.details.nip,
    20
  );
  errors.details.regon = validateByLengthOnly(
    values.details && values.details.regon,
    20
  );
  errors.details.krs = validateByLengthOnly(
    values.details && values.details.krs,
    20
  );
  errors.details.description = validateByLengthOnly(
    values.details && values.details.description,
    500
  );
  errors.details.trader = validateByLengthOnly(
    values.details && values.details.trader,
    100
  );
  errors.details.taxValueInPercentage = validateNumberWithRange(
    values.details && values.details.taxValueInPercentage,
    0,
    99.99
  );

  return errors;
};

export const asyncValidate = values => {
  return distributorService.exist(values);
};
