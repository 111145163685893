/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from "react";
import "./customRadioButtonGroup.css";
import { RadioButton } from "primereact/radiobutton";

const CustomRadioButtonGroup = (props) => {
  const { options, value, onChange } = props;

  return (
    <div className={'customRadioButtonWrapper'}>
      {options.map((option) => (
        <div className={"customRadioButtonRow"}>
          <RadioButton value={option.value} name={""} onChange={onChange} checked={option.value === value}/>
          <span>{option.name}</span>
          {option.component && option.component}
        </div>
      ))}
    </div>
  );
};

export default CustomRadioButtonGroup;
