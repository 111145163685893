/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {reduxForm} from 'redux-form';
import SubmitButton from 'shared/components/form/submit-button/SubmitButton';
import OtherSection from '../../../admin/distributors/form/sections/other-section/OtherSection';
import {resolveCurrencyOptions} from '../../../admin/distributors/utils/utils';
import {validateOtherSectionForm} from './validators/validators';

class DistributorOtherSectionForm extends React.Component {
  render() {
    const {handleSubmit, invalid, pristine, countries, selectedDistributor} =
      this.props;
    const currencyOptions = selectedDistributor
      ? resolveCurrencyOptions(countries, selectedDistributor.address.country)
      : null;
    return (
      <form onSubmit={handleSubmit}>
        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributorProfile.editOtherSectionHeader' />
        </h3>
        <OtherSection currencyOptions={currencyOptions} />
        <SubmitButton disabled={invalid || pristine} />
      </form>
    );
  }
}

DistributorOtherSectionForm = reduxForm({
  form: 'distributorOtherSectionForm',
  validate: validateOtherSectionForm,
  enableReinitialize: true,
})(DistributorOtherSectionForm);

function mapStateToProps(state) {
  const {distributor, country} = state;
  const {selectedDistributor} = distributor;
  const {countries} = country;
  const initialValues = selectedDistributor ? selectedDistributor.details : {};
  return {i18n: state.i18n, initialValues, countries, selectedDistributor};
}

export default connect(mapStateToProps)(DistributorOtherSectionForm);
