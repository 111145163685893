/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const carConstants = {
  CAR_REQUEST_START: 'CAR_REQUEST_START',
  CAR_REQUEST_END: 'CAR_REQUEST_END',

  LOAD_FUEL_TYPES: 'LOAD_FUEL_TYPES',

  LOAD_CAR_BRANDS: 'LOAD_CAR_BRANDS',

  LOAD_USER_CARS: 'LOAD_USER_CARS',
  LOAD_USER_CAR: 'LOAD_USER_CAR',

  REFRESH_USER_CARS_DATA: 'REFRESH_USER_CARS_DATA',
};
