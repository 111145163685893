/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const subscriptionPlanService = {
  findSubscriptionPlans,
  findSubscriptionPlan,
  updateSubscriptionPlan,
  deleteSubscriptionPlan,
  createSubscriptionPlan,
  activateSubscriptionPlan,
};

export const SUBSCRIPTION_PLAN_API_BASE_PATH =
  '/subscription/subscription-plans';

function findSubscriptionPlans(search = '') {
  const url = search
    ? `${SUBSCRIPTION_PLAN_API_BASE_PATH}?search=${search}`
    : `${SUBSCRIPTION_PLAN_API_BASE_PATH}`;
  return axiosService.get(url).then(response => {
    return response;
  });
}

function findSubscriptionPlan(subscriptionPlanId) {
  return axiosService
    .get(`${SUBSCRIPTION_PLAN_API_BASE_PATH}/${subscriptionPlanId}`)
    .then(response => {
      return response;
    });
}

function updateSubscriptionPlan(subscriptionPlanId, data) {
  return axiosService
    .put(`${SUBSCRIPTION_PLAN_API_BASE_PATH}/${subscriptionPlanId}`, data)
    .then(response => {
      return response;
    });
}

function deleteSubscriptionPlan(subscriptionPlanId) {
  return axiosService
    .delete(`${SUBSCRIPTION_PLAN_API_BASE_PATH}/${subscriptionPlanId}`)
    .then(response => {
      return response;
    });
}

function createSubscriptionPlan(data) {
  return axiosService
    .post(`${SUBSCRIPTION_PLAN_API_BASE_PATH}`, data)
    .then(response => {
      return response;
    });
}

function activateSubscriptionPlan(subscriptionPlanId) {
  return axiosService
    .post(`${SUBSCRIPTION_PLAN_API_BASE_PATH}/${subscriptionPlanId}/activation`)
    .then(response => {
      return response;
    });
}
