/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {LoyaltyProgramForm} from './LoyaltyProgramForm';
import {loyaltyProgramActions} from '../../../actions/loyalty-program.actions';
import React from 'react';
import PropTypes from 'prop-types';
import {loyaltyProgramConstants} from '../../../constants/loyalty-program.constants';
import Loadable from 'react-loading-overlay';
import {validate} from './loyalty-program-form-validators';

export const LoyaltyProgramFormReduxFormWrapper = reduxForm({
  form: 'loyaltyProgramEditForm',
  validate,
  enableReinitialize: true,
})(LoyaltyProgramForm);

class LoyaltyProgramEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const {findLoyaltyProgramById, loyaltyProgramId} = this.props;
    findLoyaltyProgramById(loyaltyProgramId);
  }

  componentWillUnmount() {
    this.props.clearLoyaltyProgram();
  }

  update(data) {
    const {updateLoyaltyProgram, loyaltyProgramId} = this.props;
    updateLoyaltyProgram(loyaltyProgramId, data);
  }

  delete() {
    const {deleteLoyaltyProgram, loyaltyProgramId, onDeleteSuccess} =
      this.props;
    deleteLoyaltyProgram(loyaltyProgramId, onDeleteSuccess);
  }

  render() {
    const {isFetchingLoyaltyProgram} = this.props;

    return (
      <Loadable active={isFetchingLoyaltyProgram} spinner>
        <LoyaltyProgramFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          handleDelete={this.delete}
        />
      </Loadable>
    );
  }
}

LoyaltyProgramEditForm.propTypes = {
  loyaltyProgramId: PropTypes.string.isRequired,
  findLoyaltyProgramById: PropTypes.func.isRequired,
  clearLoyaltyProgram: PropTypes.func.isRequired,
  updateLoyaltyProgram: PropTypes.func.isRequired,
  deleteLoyaltyProgram: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, loyaltyProgram} = state;
  const initialValues = loyaltyProgram.activeLoyaltyProgram || {};
  const {isFetchingLoyaltyProgram} = loyaltyProgram;
  const titleKey = 'loyaltyProgram.editFormTitle';

  return {i18n, initialValues, isFetchingLoyaltyProgram, titleKey};
}

const mapDispatchToProps = {
  findLoyaltyProgramById: loyaltyProgramActions.findLoyaltyProgramById,
  clearLoyaltyProgram: () => ({
    type: loyaltyProgramConstants.CLEAR_LOYALTY_PROGRAM,
  }),
  updateLoyaltyProgram: loyaltyProgramActions.updateLoyaltyProgram,
  deleteLoyaltyProgram: loyaltyProgramActions.deleteLoyaltyProgram,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltyProgramEditForm);
