import React, {useEffect} from 'react';
import styles from './WarrantyNotes.module.css';
import {I18n} from 'react-redux-i18n';
import {useForm, Controller} from 'react-hook-form';
import RenderTextArea from 'shared/components/form/text-area/hook-form-version/RenderTextArea';
import {
  descriptionValidator,
} from "../warranty-edit/validators";

const WarrantyNotesEdit = props => {
  const {
    control,
    formState: {errors, isValid},
    clearErrors,
    watch,
    setValue,
    getValues
  } = useForm({ mode: "onChange", criteriaMode: "all" });

  const notesField = 'notes';

  useEffect(() => {
    if (props.notesJSON) {
      setValue(notesField, props.notesJSON);
    }
  }, []);

  useEffect(() => {
    const subscription = watch(value => props.setNotesJSON(value.notes));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isValid) {
      props.setIsNotesJSONHasErrors(false);
      clearErrors();
    }
  }, [props.IsNotesJSONHasErrors, isValid]);

  useEffect(() => {
    const setErrorMessage = (field) => {
      const errorField = descriptionValidator(getValues()).errors[field];
      errorField
        ? setError(field, {
            type: errorField.key.substring(errorField.key.indexOf(".") + 1),
          })
        : null;
    };
    if (props.IsNotesJSONHasErrors) {
      setErrorMessage(descriptionField);
    }
  }, [props.IsNotesJSONHasErrors]);

  return (
    <div className={styles.WarrantyNotesContainer}>
        <Controller
          control={control}
          name={notesField}
          rules={{ maxLength: 10000 }}
          render={({ field: { value, onChange } }) => (
            <RenderTextArea
              rows={15}
              error={errors[notesField]}
              label={I18n.t('warranty.sectionII.notesField')}
              onChange={onChange}
              value={value}
            />
          )}
        />
    </div>
  );
};

export default WarrantyNotesEdit;