/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {messageConstants} from '../constants/message.constants';

export const messageActions = {
  errorMessage,
  successMessage,
  clearMessage,
  customMessage,
};

function errorMessage(message) {
  window.scrollTo(0, 0);
  return {
    type: messageConstants.ERROR_MESSAGE,
    message: message,
  };
}

function successMessage(message) {
  window.scrollTo(0, 0);
  return {
    type: messageConstants.SUCCESS_MESSAGE,
    message: message,
  };
}

function clearMessage() {
  return {
    type: messageConstants.CLEAR_MESSAGE,
  };
}

// {type: 'success', message: 'message', visibilityDurationSeconds: 10000, shouldUserClose: true}
function customMessage(messageObject) {
  window.scrollTo(0, 0);
  return {
    type: messageConstants.CUSTOM_MESSAGE,
    payload: messageObject,
  };
}
