/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {NavLink} from 'react-router-dom';
import {Button} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import TextInput from 'shared/components/input/text-input/TextInput';
import {loginKey} from 'shared/utils/icons';
import {fbAppId, googleAppId} from '../environment';
import ResetTokenRequestModal from './forgot-password-modal/ForgotPasswordModal';
import './login.css';
import SocialButton from './SocialButton';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: '', googleBtnKey: 0, fbBtnKey: 1};
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.rerenderSocialButtons = this.rerenderSocialButtons.bind(this);
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handlePasswordChange(event) {
    this.setState({password: event.target.value});
  }

  handleLogin(event) {
    event.preventDefault();
    const {email, password} = this.state;
    const {dispatch} = this.props;
    if (email && password) dispatch(userActions.login(email, password));
  }

  handleSocialLogin(event) {
    const providerId = event._provider.toUpperCase();
    const accessToken =
      providerId === 'GOOGLE' ? event._token.idToken : event._token.accessToken;
    const {dispatch} = this.props;
    if (providerId && accessToken)
      dispatch(userActions.loginSocial(providerId, accessToken));
  }

  rerenderSocialButtons() {
    this.setState({googleBtnKey: Math.random()});
    this.setState({fbBtnKey: Math.random()});
  }

  render() {
    const {isFetching} = this.props;

    return (
      <ContentWrapper title='login.login' icon={loginKey}>
        <Loadable active={isFetching} spinner>
          <form onSubmit={this.handleLogin} className='login-container-col p-5'>
            <TextInput
              label={I18n.t('login.emailPlaceholder')}
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
            <TextInput
              label={I18n.t('login.passwordPlaceholder')}
              type='password'
              style={{marginTop: '30px', marginBottom: '30px'}}
              value={this.state.password}
              onChange={this.handlePasswordChange}
            />
            <Button
              className='login-btn px-5 py-1 mb-1'
              type='submit'
              color='info'
              disabled={!(this.state.email && this.state.password)}>
              <Translate value='login.loginBtn' />
            </Button>
            <div className='mb-4'>
              <ResetTokenRequestModal />
              <div className='login-register-link'>
                <Translate value='login.signupTip' />
                <br />
                {/* <NavLink to='/sign-up' className='ml-1'>
                  <span className='btn-link'>
                    <Translate value='login.signupBtn' />
                  </span>
                </NavLink>
                <Translate className='ml-1' value='login.signupOr' /> */}
                <NavLink to='/workshop-sign-up' className='ml-1'>
                  <span className='btn-link'>
                    <Translate value='login.workshopSignupBtn' />
                  </span>
                </NavLink>
              </div>
            </div>
            {/* <div className='row'>
              <div className='col-sm-6 mb-3'>
                <SocialButton
                  className='fb-btn social-button'
                  size='sm'
                  provider='facebook'
                  appId={fbAppId}
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.rerenderSocialButtons}
                  key={this.state.fbBtnKey}
                  iconClassName='fa fa-facebook'
                  label='login.loginBtnFb'
                />
              </div>
              <div className='col-sm-6'>
                <SocialButton
                  className='google-btn social-button'
                  size='sm'
                  provider='google'
                  appId={googleAppId}
                  onLoginSuccess={this.handleSocialLogin}
                  onLoginFailure={this.rerenderSocialButtons}
                  key={this.state.googleBtnKey}
                  iconClassName='fa fa-google'
                  label='login.loginBtnGoogle'
                />
              </div>
            </div> */}
          </form>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {user, i18n} = state;
  const {isAuthenticated, isFetching} = user;

  return {isAuthenticated, isFetching, i18n};
}

const connectedLogin = connect(mapStateToProps)(Login);
export {connectedLogin as Login};
