/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {validate} from './validators';
import ValuationInquiryFormSchemaPage2 from './ValuationInquiryFormSchemaPage2';

const ValuationInquiryFormPage2 = reduxForm({
  form: 'valuationInquiryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage2);

const selector = formValueSelector('valuationInquiryForm');

function mapStateToProps(state) {
  const {i18n, car, user, form} = state;
  const carNameFieldValue = selector(state, 'carName');
  const carBrandFieldValue = selector(state, 'carBrand');
  const carModelFieldValue = selector(state, 'carModel');
  const carDataTypeValue = selector(state, 'carDataType');
  const carEngineCapacityCm3FieldValue = selector(
    state,
    'carEngineCapacityCm3'
  );
  const carEnginePowerKwFieldValue = selector(state, 'carEnginePowerKw');
  const carFuelTypeIdFieldValue = selector(state, 'carFuelTypeId');
  const {valuationInquiryForm} = form;
  const {fields} = valuationInquiryForm;
  const {fuelTypes, carBrands, userCars} = car;

  return {
    i18n,
    carNameFieldValue,
    carBrandFieldValue,
    carModelFieldValue,
    carDataTypeValue,
    fuelTypes,
    carBrands,
    userCars,
    carEngineCapacityCm3FieldValue,
    carEnginePowerKwFieldValue,
    carFuelTypeIdFieldValue,
    user,
    fields,
  };
}

export default connect(mapStateToProps)(ValuationInquiryFormPage2);
