/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {workshopActions} from 'shared/actions/workshop.actions';
import CalendarCreateForm from 'shared/components/calendar/calendar-form/CalendarCreateForm';
import CalendarEditForm from 'shared/components/calendar/calendar-form/CalendarEditForm';
import CalendarTable from 'shared/components/calendar/calendar-table/CalendarTable';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {becameObsolete} from 'shared/utils/sync';

class CalendarSettings extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.showCalendarDetails = this.showCalendarDetails.bind(this);
    this.showCreateCalendar = this.showCreateCalendar.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.workshopExternalCalendarsSyncState,
        this.props.workshopExternalCalendarsSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.findAllWorkshopExternalCalendars(userService.getUserId());
  }

  showCalendarDetails(row) {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <CalendarEditForm
        workshopExternalCalendarId={row.data.id}
        handleCancel={hideUpdateDataSideBar}
        onDeleteSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('calendar.editFormTitle'));
  }

  showCreateCalendar() {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <CalendarCreateForm
        handleCancel={hideUpdateDataSideBar}
        onCreateSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('calendar.createFormTitle'));
  }

  render() {
    const {isFetching, workshopExternalCalendars} = this.props;

    return (
      <ContentWrapper title='settings.calendarSettingsBtn'>
        <Loadable active={isFetching} spinner>
          <div className='col py-3'>
            <CalendarTable
              calendars={workshopExternalCalendars}
              onRowClick={this.showCalendarDetails}
              onCreateClick={this.showCreateCalendar}
            />
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {workshop} = state;
  const {
    isFetching,
    workshopExternalCalendars,
    workshopExternalCalendarsSyncState,
  } = workshop;

  return {
    isFetching,
    workshopExternalCalendars,
    workshopExternalCalendarsSyncState,
  };
}

const mapDispatchToProps = {
  findAllWorkshopExternalCalendars:
    workshopActions.findAllWorkshopExternalCalendars,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

export const connectedCalendarSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarSettings);
export {connectedCalendarSettings as CalendarSettings};
