import React, { useState } from "react";
import { Translate } from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import styles from "./WorkshopLoyaltyProgramModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import { history } from 'shared/utils/history';
import WorkshopLoyaltyProgramRegister from '../workshop-loyalty-program-register/WorkshopLoyaltyProgramRegister';

const WorkshopLoyaltyProgramModal = (props) => {
  const {
    redirectToLoyaltyProgramRegistration,
    confirmUsePoints,
    onConfirmUsePoints,
  } = props;

  const dispatch = useDispatch();

  const goToWorkshopLoyaltyProgram = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    })
    history.push('/workshop/workshop-loyalty-program');
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WorkshopLoyaltyProgramRegister />,
      title: I18n.t("workshopLoyaltyProgram.registration"),
    });
  }

  return (
    <div className={styles.Container} style={{ padding: "25px" }}>
      {redirectToLoyaltyProgramRegistration && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.register.description")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={goToWorkshopLoyaltyProgram}>
            <Translate value={"workshopLoyaltyProgram.register.goToRegistration"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>}
      {confirmUsePoints && <>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            if (onConfirmUsePoints) onConfirmUsePoints();
            dispatch({
              type: updateDataSideBarConstants.HIDE,
            })
          }}>
            <Translate value={"workshopLoyaltyProgram.productsForPoints.yes"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.productsForPoints.no"} />
          </Button>
        </div>
      </>}
    </div>
  );
};

export default WorkshopLoyaltyProgramModal;
