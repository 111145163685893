/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
  validateRequiredNumberWithRange,
} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {price: {}};
  errors.label = validateRequiredFieldsWithMaxLength(values.label, 60);
  errors.period = validateRequiredFields(values.period);
  errors.price.currency = validateRequiredFields(
    values.price ? values.price.currency : null
  );
  errors.price.value = validateRequiredNumberWithRange(
    values.price ? values.price.value : null,
    0.01,
    99999.99
  );
  errors.country = validateRequiredFields(values.country);
  errors.description = validateByLengthOnly(values.description, 255);
  return errors;
};
