/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, getFormValues, reduxForm} from 'redux-form';
import ChangePasswordForm from 'shared/components/form/change-password-form/ChangePasswordForm';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import StateField from 'shared/components/form/state-field/StateField';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {resolveCurrencyOptions} from '../../../admin/distributors/utils/utils';
import WorkshopMap from '../workshop-map/WorkshopMap';
import {validate} from './validators';
import RenderGmap from '../../../../shared/components/form/gmap/RenderGmap';

class EditWorkshopForm extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, data, form} = this.props;
    if (this.shouldInitializePhonePrefixes(prevProps))
      this.props.initializePhonePrefixesByCountry(form, data.address.country);
  }

  shouldInitializePhonePrefixes(prevProps) {
    const {countries, formValues, data} = this.props;
    return (
      countries &&
      data &&
      data.address &&
      formValues &&
      !prevProps.formValues &&
      (!formValues.phoneNumber || !formValues.phoneNumber.phoneNumber) &&
      (!formValues.mobilePhoneNumber ||
        !formValues.mobilePhoneNumber.phoneNumber) &&
      (!formValues.faxNumber || !formValues.faxNumber.phoneNumber)
    );
  }

  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      handleCancel,
      countries,
      data,
      submitChangePasswordForm,
    } = this.props;
    const currencyOptions =
      data && data.address
        ? resolveCurrencyOptions(countries, data.address.country)
        : null;
    return (
      <form onSubmit={handleSubmit} className={'edit-workshop-form'}>
        <Field
          name='workshopName'
          type='text'
          component={renderField}
          required={true}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.workshopNameLabel')}
          icon='fa fa-briefcase'
        />

        <Field
          name='ownerName'
          type='text'
          component={renderField}
          required={true}
          inputAlwaysBottom={true}
          withTooltip={true}
          tooltipContent={I18n.t('workshopProfile.ownerNameTooltip')}
          tooltipClassName={'custom-tooltip-smaller-width'}
          label={I18n.t('workshopProfile.ownerNameLabel')}
          icon='fa fa-user'
        />

        <Field
          name='phoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.phoneNumberLabel')}
          format={formatPhoneNumberField}
        />

        <Field
          name='mobilePhoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.mobilePhoneNumber')}
          format={formatPhoneNumberField}
        />

        <Field
          name='faxNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.faxLabel')}
          format={formatPhoneNumberField}
        />

        <Field
          name='nip'
          type='text'
          component={renderField}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.nipLabel')}
          icon='fa fa-info'
        />

        <Field
          name='regon'
          type='text'
          component={renderField}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.regonLabel')}
          icon='fa fa-info'
        />

        <Field
          name='krs'
          type='text'
          component={renderField}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.krsLabel')}
          icon='fa fa-info'
        />

        <Field
          name='website'
          type='text'
          component={renderField}
          required={false}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.websiteLabel')}
          icon='fa fa-info'
        />

        <Field
          name='currency'
          component={RenderDropdown}
          options={currencyOptions}
          icon='fa fa-money'
          inputAlwaysBottom={true}
          label={I18n.t('distributors.currencyLabel')}
          placeholder={I18n.t('distributors.currencyPlaceholder')}
        />

        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          required={false}
          rows={4}
          icon='fa fa-info'
          label={I18n.t('workshopProfile.descriptionLabel')}
        />

        <StateField
          formName={'workshopProfileAddressForm'}
          required={true}
          inputAlwaysBottom={true}
        />

        <Field
          name='city'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('workshopProfile.cityLabel')}
          icon='fa fa-building'
          inputAlwaysBottom={true}
        />

        <Field
          name='streetAddress'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('workshopProfile.streetAddressLabel')}
          icon='fa fa-address-card'
          inputAlwaysBottom={true}
        />

        <Field
          name='postalCode'
          type='text'
          component={renderField}
          required={true}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.postalCodeLabel')}
          icon='fa fa-inbox'
        />

        <Field
          name='postalCity'
          type='text'
          component={renderField}
          required={true}
          inputAlwaysBottom={true}
          label={I18n.t('workshopProfile.postalCityLabel')}
          icon='fa fa-envelope'
        />

        <RenderGmap formName={'editWorkshopForm'} />
        {/*<WorkshopMap*/}
        {/*  formName='editWorkshopForm'*/}
        {/*  streetViewControl={false}*/}
        {/*  inputDisabled={false}*/}
        {/*  mapMinHeight={450}*/}
        {/*/>*/}

        <div className='text-right'>
          <Button
            className='main-btn'
            type='submit'
            disabled={invalid || pristine}
          >
            <Translate value='workshopProfile.saveBtn' />
          </Button>
          <Button
            id='cancel-workshop-form-btn'
            className='main-btn'
            onClick={handleCancel}
          >
            <Translate value='userProfileForm.cancelBtn' />
          </Button>
        </div>
        <ChangePasswordForm onSubmit={submitChangePasswordForm} />
      </form>
    );
  }
}

EditWorkshopForm = reduxForm({
  form: 'editWorkshopForm',
  validate: validate,
  enableReinitialize: true,
})(EditWorkshopForm);

const toFormJson = workshopData => {
  let data = {...workshopData};
  const address = !!data ? data.address : {};
  const workshopDetails = !!data ? data.workshopDetails : {};
  if (!!address) {
    address.latitude = address.latitude.toString();
    address.longitude = address.longitude.toString();
  }
  delete data.address;
  delete data.workshopDetails;
  return Object.assign({}, data, address, workshopDetails);
};

function mapStateToProps(state) {
  const {i18n, country, workshop} = state;
  const {data} = workshop;
  const {countries} = country;
  const initialValues = toFormJson(data);
  return {
    i18n,
    initialValues,
    data,
    countries,
    formValues: getFormValues('editWorkshopForm')(state),
  };
}

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(EditWorkshopForm)
);
