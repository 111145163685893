/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const statuteService = {
  createStatute,
  findAllStatutes,
  findAllStatutesByCountry,
  findStatuteById,
  markStatuteValid,
  deleteStatute,
  findLoyaltyStatutes,
  uploadNewLoyaltyProgramStatute,
  checkValidStatuteAcceptation,
  acceptNewStatute
};

function createStatute(data, country) {
  const url = !country
    ? '/workshop/statutes'
    : `/workshop/statutes?country=${country}`;
  return axiosService.post(url, data).then(response => {
    return response;
  });
}

function findAllStatutes() {
  return axiosService.get('/workshop/statutes').then(response => {
    return response;
  });
}

function findLoyaltyStatutes() {
  return axiosService.get('/workshop-loyalty/loyalty-statute').then(response => {
    return response;
  });
} 

function uploadNewLoyaltyProgramStatute(data, country) {
  const url = !country
  ? '/workshop-loyalty/loyalty-statute'
  : `/workshop-loyalty/loyalty-statute?country=${country}`;

  return axiosService
  .post(url, data).then(response => {
    return response;
  });
}

function findAllStatutesByCountry(country) {
  return axiosService
    .get(`/workshop/statutes/country?country=${country}`)
    .then(response => {
      return response;
    });
}

function findStatuteById(statuteId) {
  return axiosService.get(`/workshop/statutes/${statuteId}`).then(response => {
    return response;
  });
}

function markStatuteValid(statuteId) {
  return axiosService
    .post(`/workshop/statutes/${statuteId}/markValid`)
    .then(response => {
      return response;
    });
}

function deleteStatute(statuteId) {
  return axiosService
    .delete(`/workshop/statutes/${statuteId}`)
    .then(response => {
      return response;
    });
}

function checkValidStatuteAcceptation(workshopId) {
  return axiosService
  .get(`/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/statute/valid`)
  .then(response => {
    return response;
  });
}

function acceptNewStatute(workshopId) {
  return axiosService
  .put(`/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/statute/update`)
  .then(response => {
    return response;
  });
}
