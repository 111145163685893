/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';
import renderField from '../../form/text-field/RenderField';
import {RenderTextArea} from '../../form/text-area/RenderTextArea';
import RenderCheckbox from '../../form/checkbox/RenderCheckbox';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import {updateDataSideBarConstants} from '../../../constants/update-data-side-bar.constants';
import RenderDatePicker from '../../form/date-picker/RenderDatePicker';
import {promotionConstants} from '../../../constants/promotion.constants';
import {promotionActions} from '../../../actions/promotion.actions';
import {validate} from './validators/validators';
import FileUpload from '../../form/file-upload/FileUpload';
import Loadable from 'react-loading-overlay';
import moment from 'moment';
import ContentWrapper from '../../content-wrapper/ContentWrapper';

const imgMaxSizeInBytes = 1048576;
const fileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

const imagePrefix = 'data:image/png;base64,';

class PromotionsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateLocked: false,
      isDeleteLocked: false,
      startDate: null,
    };
    this.changePromotionStatus = this.changePromotionStatus.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {selectedPromotion} = this.props;
    if (!prevProps.selectedPromotion && selectedPromotion) {
      this.initializeComponentState();
    }
  }

  initializeComponentState() {
    const {selectedPromotion} = this.props;
    const canDeleteStatuses = [
      promotionConstants.STATUS_NEW,
      promotionConstants.STATUS_ACCEPTED,
      promotionConstants.STATUS_NOT_ACCEPTED,
    ];

    this.setState({startDate: selectedPromotion.startDate});
    if (canDeleteStatuses.indexOf(selectedPromotion.status) < 0)
      this.setState({isDeleteLocked: true});
    if (selectedPromotion.status !== promotionConstants.STATUS_NEW)
      this.setState({isUpdateLocked: true});
  }

  componentWillUnmount() {
    this.props.dispatch({type: promotionConstants.CLEAR_PROMOTION_DATA});
  }

  isPromotionActive() {
    const {selectedPromotion} = this.props;
    return selectedPromotion
      ? selectedPromotion.status === promotionConstants.STATUS_ACTIVE
      : false;
  }

  changePromotionStatus(newStatus) {
    const {selectedPromotion, dispatch} = this.props;
    dispatch(
      promotionActions.changePromotionStatus(selectedPromotion.id, newStatus)
    );
    dispatch({type: updateDataSideBarConstants.HIDE});
  }

  onStartDateChange(date) {
    this.setState({startDate: date});
  }

  renderPromotionPeriod() {
    const minStartDate = moment().add(1, 'days').format('YYYY-MM-DD');
    return (
      <div>
        <Field
          name='startDate'
          component={RenderDatePicker}
          required={true}
          minDate={minStartDate}
          label={I18n.t('promotions.startDate')}
          disabled={this.state.isUpdateLocked}
          handleChangeCallback={this.onStartDateChange}
        />

        <Field
          name='endDate'
          component={RenderDatePicker}
          required={true}
          minDate={this.state.startDate}
          label={I18n.t('promotions.endDate')}
          disabled={this.state.isUpdateLocked || !this.state.startDate}
        />
      </div>
    );
  }

  renderEndPromotionButton() {
    return (
      <div className='text-sm-right text-right mt-3'>
        <Button
          id='end-promotion-btn'
          className='main-btn'
          onClick={() =>
            this.changePromotionStatus(promotionConstants.STATUS_EXPIRED)
          }>
          <Translate value={'promotions.endPromotionBtn'} />
        </Button>
      </div>
    );
  }

  renderNewPromotionButtons() {
    return (
      <div className='text-sm-right text-right mt-3'>
        <Button
          id='promotions-form-submit-btn'
          className='main-btn'
          style={fixedBtn}
          onClick={this.props.handleSubmit}>
          <Translate value={'promotions.saveBtn'} />
        </Button>
        <Button
          id='promotions-form-cancel-btn'
          className='main-btn'
          style={fixedBtn}
          onClick={this.props.handleCancel}>
          <Translate value={'promotions.cancelBtn'} />
        </Button>
      </div>
    );
  }

  renderFormButtons() {
    return (
      <Fragment>
        <Button
          id='promotions-form-submit-btn'
          className='main-btn'
          style={fixedBtn}
          onClick={this.props.handleSubmit}>
          <Translate value={'promotions.saveBtn'} />
        </Button>
        <Button
          id='promotions-form-cancel-btn'
          className='main-btn'
          style={fixedBtn}
          onClick={() =>
            this.props.dispatch({type: updateDataSideBarConstants.HIDE})
          }>
          <Translate value={'promotions.cancelBtn'} />
        </Button>
      </Fragment>
    );
  }

  renderDeleteButton() {
    return (
      <Button
        id='promotions-form-delete-btn'
        className='btn-danger'
        style={fixedBtn}
        onClick={() =>
          this.changePromotionStatus(promotionConstants.STATUS_DELETED)
        }>
        <Translate value={'promotions.deleteBtn'} />
      </Button>
    );
  }

  renderButtons() {
    return (
      <div className='text-sm-right text-right mt-3'>
        {!this.state.isUpdateLocked && this.renderFormButtons()}
        {!this.state.isDeleteLocked && this.renderDeleteButton()}
      </div>
    );
  }

  renderContent() {
    const {handleSubmit, isWorkshopNameVisible, isFetching, newPromotion} =
      this.props;

    return (
      <Loadable active={isFetching} spinner>
        <div className={newPromotion ? 'root-panel' : ''}>
          <div className='p-5'>
            <form className='promotions-form'>
              <Field
                name='image'
                component={FileUpload}
                className='main-btn mx-1 my-1'
                maxSizeInBytes={imgMaxSizeInBytes}
                fileTypes={fileTypes}
                accept={'image/*'}
                keyGroup={'parts'}
                disabled={this.state.isUpdateLocked}
                allign='center'
                imageUpload={true}
              />
              {isWorkshopNameVisible && (
                <Field
                  name='workshopName'
                  type='text'
                  component={renderField}
                  label={I18n.t('promotions.workshopName')}
                  icon='fa fa-suitcase'
                  inputDisabled={true}
                />
              )}
              <Field
                name='title'
                type='text'
                component={renderField}
                required={true}
                label={I18n.t('promotions.title')}
                icon='fa fa-cube'
                inputDisabled={this.state.isUpdateLocked}
              />
              <Field
                name='description'
                type='text'
                component={RenderTextArea}
                required={true}
                label={I18n.t('promotions.description')}
                inputReadOnly={this.state.isUpdateLocked}
              />
              {this.renderPromotionPeriod()}
              <Field
                name='attachToValuation'
                component={RenderCheckbox}
                required={true}
                label={I18n.t('promotions.attachToValuation')}
                disabled={this.state.isUpdateLocked}
              />
              {!newPromotion && this.renderButtons()}
              {newPromotion && this.renderNewPromotionButtons()}
              {this.isPromotionActive() && this.renderEndPromotionButton()}
            </form>
          </div>
        </div>
      </Loadable>
    );
  }

  render() {
    const {newPromotion} = this.props;
    if (!newPromotion) return this.renderContent();

    return (
      <ContentWrapper title='promotions.createPromotionFormTitle'>
        {this.renderContent()}
      </ContentWrapper>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};

PromotionsForm.propTypes = {
  newPromotion: PropTypes.bool,
};

PromotionsForm.defaultProps = {
  newPromotion: false,
};

PromotionsForm = reduxForm({
  form: 'promotionsForm',
  validate: validate,
  enableReinitialize: true,
})(PromotionsForm);

const mapStateToProps = state => {
  const {i18n, promotion} = state;
  const {selectedPromotion, isFetching} = promotion;
  const initialValues = selectedPromotion
    ? mapInitialValues(selectedPromotion)
    : null;
  return {i18n, selectedPromotion, isFetching, initialValues};
};

function mapInitialValues(selectedPromotion) {
  let initialValues = {...selectedPromotion};
  if (
    selectedPromotion &&
    selectedPromotion.image &&
    selectedPromotion.image.indexOf(imagePrefix) < 0
  )
    initialValues.image = imagePrefix + initialValues.image;
  return initialValues;
}

export default connect(mapStateToProps)(PromotionsForm);
