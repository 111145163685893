/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import renderField from 'shared/components/form/text-field/RenderField';

export default class UserAuthInfoSection extends React.Component {
  render() {
    return (
      <Fragment>
        <Field
          name='email'
          type='email'
          component={renderField}
          required={true}
          label={I18n.t('distributors.emailLabel')}
          icon='fa fa-at'
        />
        <Field
          name='password'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('distributors.passwordLabel')}
          icon='fa fa-unlock-alt'
        />
        <Field
          name='confirmPassword'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('distributors.confirmPasswordLabel')}
          icon='fa fa-unlock-alt'
        />
      </Fragment>
    );
  }
}
