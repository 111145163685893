/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {SubscriptionOverview} from 'shared/components/subscription/subscription-overview/SubscriptionOverview';

export class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <SubscriptionOverview />;
  }
}

Subscriptions.propTypes = {};
