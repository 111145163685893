/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { I18n } from "react-redux-i18n";
import styles from "../WarrantyInstalationData.module.css";
import { RenderDropdown } from "shared/components/form/dropdown/hook-form-version/RenderDropdown";
import { RenderField } from "shared/components/form/text-field/hook-form-version/RenderField";
import RenderDatePicker from "shared/components/form/date-picker/hook-form-version/RenderDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { partCategoryActions } from "shared/actions/part-category.actions";
import { technicalDetailsValidator } from "../../warranty-edit//validators";
import useWarrantyBookLocalStorageGetValue from "shared/hooks/useWarrantyBookLocalStorageGetValue";
import {ReqexpValidationEnum} from 'shared/utils/utils';
import {dateUtils} from 'shared/utils/date.utils';

const TechnicalDataGasInstallationEdit = (props) => {
  const {
    register,
    control,
    watch,
    getValues,
    setValue,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm({ mode: "all", criteriaMode: "all" });

  const dispatch = useDispatch();
  const {
    setTechnicalDetailsJSON,
    pressureTemperatureSensorNameTypes,
    isTechnicalDetailsJSONHasErrors,
    setIsTechnicalDetailsJSONHasErrors,
  } = props;
  const technicalDetails =
    useWarrantyBookLocalStorageGetValue("technicalDetails");
  const partCategories = useSelector((state) => state.part.categories);
  const isPartCategoriesFetching = useSelector(
    (state) => state.part.isFetching
  );

  const getParts = (key) => {
    const parts =
      !isPartCategoriesFetching && partCategories
        ? partCategories
            .find((category) => category.key === key)
            .parts.map((part) => ({ label: part.name, value: part.name }))
        : [];
    return parts;
  };

  const reducerNameField = "reducerName";
  const reducerSerialNumbersField = "reducerSerialNumbers";
  const tankNameField = "tankName";
  const tankSerialNumberField = "tankSerialNumber";
  const stagControlSystemNameField = "stagControlSystemName";
  const stagControlSystemSerialNumberField = "stagControlSystemSerialNumber";
  const injectionRailNameField = "injectionRailName";
  const injectionRailSerialNumbersField = "injectionRailSerialNumbers";
  const pressureTemperatureSensorNameField = "pressureTemperatureSensorName";
  const pressureTemperatureSensorBarcodeField =
    "pressureTemperatureSensorBarcode";
  const pressureTemperatureSensorDateField = "pressureTemperatureSensorDate";
  const bgSwitchField = "bgSwitch";
  const gasLevelIndicatorTypeField = "gasLevelIndicatorType";
  const gasLevelIndicatorDateField = "gasLevelIndicatorDate";
  const workshopWorkerField = "workshopWorker";
  const workshopNameField = "workshopName";
  const addressField = "address";
  const phoneNumberField = "phoneNumber";
  const nipField = "nip";
  const regonField = "regon";
  const workshopWorkerEmailField = "workshopWorkerEmail";
  const dateField = "date";

  useEffect(() => {
    dispatch(partCategoryActions.findAllPartCategories());
  }, [isPartCategoriesFetching]);

  useEffect(() => {
    if (technicalDetails) {
      setValue(reducerNameField, technicalDetails.reducerName);
      setValue(
        reducerSerialNumbersField,
        technicalDetails.reducerSerialNumbers &&
          Array.isArray(technicalDetails.reducerSerialNumbers)
          ? technicalDetails.reducerSerialNumbers.join()
          : technicalDetails.reducerSerialNumbers
      );
      setValue(tankNameField, technicalDetails.tankName);
      setValue(
        stagControlSystemNameField,
        technicalDetails.stagControlSystemName
      );
      setValue(
        stagControlSystemSerialNumberField,
        technicalDetails.stagControlSystemSerialNumber
      );
      setValue(injectionRailNameField, technicalDetails.injectionRailName);
      setValue(
        injectionRailSerialNumbersField,
        technicalDetails.injectionRailSerialNumbers &&
          Array.isArray(technicalDetails.injectionRailSerialNumbers)
          ? technicalDetails.injectionRailSerialNumbers.join()
          : technicalDetails.injectionRailSerialNumbers
      );
      setValue(
        pressureTemperatureSensorNameField,
        technicalDetails.pressureTemperatureSensorName
      );
      setValue(
        pressureTemperatureSensorBarcodeField,
        technicalDetails.pressureTemperatureSensorBarcode
      );
      setValue(
        pressureTemperatureSensorDateField,
        technicalDetails.pressureTemperatureSensorDate
      );
      setValue(tankSerialNumberField, technicalDetails.tankSerialNumber);
      setValue(workshopWorkerField, technicalDetails.workshopWorker);
      setValue(
        gasLevelIndicatorDateField,
        technicalDetails.gasLevelIndicatorDate
      );
      setValue(
        gasLevelIndicatorTypeField,
        technicalDetails.gasLevelIndicatorType
      );
      setValue(workshopNameField, technicalDetails.workshopName);
      setValue(bgSwitchField, technicalDetails.bgSwitch);
      setValue(addressField, technicalDetails.address);
      setValue(phoneNumberField, technicalDetails.phoneNumber);
      setValue(nipField, technicalDetails.nip);
      setValue(regonField, technicalDetails.regon);
      setValue(dateField, technicalDetails.date);
      setValue(workshopWorkerEmailField, technicalDetails.workshopWorkerEmail);
    }
  }, [technicalDetails]);

  useEffect(() => {
    const subscription = watch((value) => {
      value.reducerSerialNumbers =
        value.reducerSerialNumbers &&
        (Array.isArray(value.reducerSerialNumbers)
          ? value.reducerSerialNumbers
          : value.reducerSerialNumbers.split(","));
      value.injectionRailSerialNumbers =
        value.injectionRailSerialNumbers &&
        (Array.isArray(value.injectionRailSerialNumbers)
          ? value.injectionRailSerialNumbers
          : value.injectionRailSerialNumbers.split(","));
      value.date = dateUtils.getDateFromInput(value.date)
      value.pressureTemperatureSensorDate = dateUtils.getDateFromInput(value.pressureTemperatureSensorDate);
      value.gasLevelIndicatorDate = dateUtils.getDateFromInput(value.gasLevelIndicatorDate);
      setTechnicalDetailsJSON(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isValid) {
      setIsTechnicalDetailsJSONHasErrors(false);
      clearErrors();
    }
  }, [isTechnicalDetailsJSONHasErrors, isValid]);

  useEffect(() => {
    const setErrorMessage = (field) => {
      const errorField = technicalDetailsValidator(getValues()).errors[field];
      errorField
        ? setError(field, {
            type: errorField.key.substring(errorField.key.indexOf(".") + 1),
          })
        : null;
    };
    if (isTechnicalDetailsJSONHasErrors) {
      setErrorMessage(reducerNameField);
      setErrorMessage(reducerSerialNumbersField);
      setErrorMessage(tankNameField);
      setErrorMessage(tankSerialNumberField);
      setErrorMessage(stagControlSystemNameField);
      setErrorMessage(stagControlSystemSerialNumberField);
      setErrorMessage(injectionRailNameField);
      setErrorMessage(injectionRailSerialNumbersField);
      setErrorMessage(bgSwitchField);
      setErrorMessage(pressureTemperatureSensorNameField);
      setErrorMessage(pressureTemperatureSensorBarcodeField);
      setErrorMessage(pressureTemperatureSensorDateField);
      setErrorMessage(gasLevelIndicatorDateField);
      setErrorMessage(gasLevelIndicatorTypeField);
      setErrorMessage(workshopWorkerField);
      setErrorMessage(workshopNameField);
      setErrorMessage(addressField);
      setErrorMessage(addressField);
      setErrorMessage(phoneNumberField);
      setErrorMessage(nipField);
      setErrorMessage(regonField);
      setErrorMessage(dateField);
      setErrorMessage(workshopWorkerEmailField);
    }
  }, [isTechnicalDetailsJSONHasErrors]);

  return (
    <div className={styles.FirstSegmentCollapsedContainer}>
      <div className={styles.ColumnContainer}>
        <div className={styles.Column}>
          <RenderDropdown
            {...register(reducerNameField, { required: true })}
            options={getParts("REDUCERS")}
            value={watch(reducerNameField)}
            label={I18n.t("warranty.sectionII.lpgReducerLabel")}
            error={errors[reducerNameField]}
            required
          />
          <RenderDropdown
            {...register(tankNameField, { required: true })}
            options={getParts("TANKS")}
            value={watch(tankNameField)}
            label={I18n.t("warranty.sectionII.lpgTankLabel")}
            error={errors[tankNameField]}
            required
          />
          <RenderDropdown
            {...register(stagControlSystemNameField, { required: true })}
            options={getParts("DRIVERS")}
            value={watch(stagControlSystemNameField)}
            label={I18n.t("warranty.sectionII.lpgDriverLabel")}
            error={errors[stagControlSystemNameField]}
            required
          />
          <RenderDropdown
            {...register(injectionRailNameField, { required: true })}
            options={getParts("INJECTORS")}
            value={watch(injectionRailNameField)}
            label={I18n.t("warranty.sectionII.lpgInjectorsLabel")}
            error={errors[injectionRailNameField]}
            required
          />
          <RenderDropdown
            {...register(pressureTemperatureSensorNameField, {
              required: true,
            })}
            options={pressureTemperatureSensorNameTypes}
            value={watch(pressureTemperatureSensorNameField)}
            label={I18n.t("warranty.sectionII.lpgTemperatureAndPressureSensor")}
            error={errors[pressureTemperatureSensorNameField]}
            required
          />
          <RenderDropdown
            {...register(bgSwitchField, { required: true })}
            options={getParts("BG_SWITCHES")}
            value={watch(bgSwitchField)}
            label={I18n.t("warranty.sectionII.lpgBgSwitchLabel")}
            error={errors[bgSwitchField]}
            required
          />
          <RenderField
            {...register(gasLevelIndicatorTypeField, {
              required: true,
              maxLength: 255,
            })}
            label={I18n.t("warranty.sectionII.lpgGazLevelType")}
            value={watch(gasLevelIndicatorTypeField)}
            type="text"
            error={errors[gasLevelIndicatorTypeField]}
            required
          />
          <RenderField
            {...register(workshopWorkerField, {
              required: true,
              maxLength: 60,
            })}
            label={I18n.t("warranty.sectionII.workshopWorker")}
            error={errors[workshopWorkerField]}
            value={watch(workshopWorkerField)}
            type="text"
            required
          />
          <RenderField
            {...register(addressField, { required: true, maxLength: 255 })}
            label={I18n.t("warranty.sectionII.address")}
            value={watch(addressField)}
            error={errors[addressField]}
            type="text"
            required
          />
          <RenderField
            {...register(nipField, {
              required: true,
              pattern: ReqexpValidationEnum.ONLY_NUMBERS,
              maxLength: 10,
            })}
            label={I18n.t("warranty.sectionII.nipLabel")}
            value={watch(nipField)}
            error={errors[nipField]}
            type="text"
            required
          />
          <RenderField
            {...register(workshopWorkerEmailField, {
              required: true,
              maxLength: 60,
              pattern: ReqexpValidationEnum.EMAIl
            })}
            label={I18n.t("warranty.sectionII.email")}
            validateOnBlur = {!(isTechnicalDetailsJSONHasErrors && !!technicalDetailsValidator(getValues()).errors[workshopWorkerEmailField])}
            value={watch(workshopWorkerEmailField)}
            type="email"
            error={errors[workshopWorkerEmailField]}
            required
          />
        </div>
        <div className={styles.Column}>
          <RenderField
            {...register(reducerSerialNumbersField, {
              maxLength: 255,
              required: true,
            })}
            label={I18n.t("warranty.sectionII.serialNumbers1")}
            value={watch(reducerSerialNumbersField)}
            error={errors[reducerSerialNumbersField]}
            type="text"
            required
          />
          <RenderField
            {...register(tankSerialNumberField, {
              maxLength: 255,
              required: true,
            })}
            label={I18n.t("warranty.sectionII.serialNumber2")}
            value={watch(tankSerialNumberField)}
            error={errors[tankSerialNumberField]}
            type="text"
            required
          />
          <RenderField
            {...register(stagControlSystemSerialNumberField, {
              maxLength: 255,
              required: true,
            })}
            label={I18n.t("warranty.sectionII.serialNumber3")}
            value={watch(stagControlSystemSerialNumberField)}
            error={errors[stagControlSystemSerialNumberField]}
            type="text"
            required
          />
          <RenderField
            {...register(injectionRailSerialNumbersField, {
              maxLength: 255,
              required: true,
            })}
            label={I18n.t("warranty.sectionII.serialNumbers4")}
            value={watch(injectionRailSerialNumbersField)}
            error={errors[injectionRailSerialNumbersField]}
            type="text"
            required
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "45%" }}>
              <RenderField
                {...register(pressureTemperatureSensorBarcodeField, {
                  maxLength: 255,
                  required: true,
                })}
                label={I18n.t("warranty.sectionII.serialNumber5")}
                value={watch(pressureTemperatureSensorBarcodeField)}
                error={errors[pressureTemperatureSensorBarcodeField]}
                type="text"
                required
              />
            </div>
            <div style={{ width: "45%" }}>
              <Controller
                control={control}
                name={pressureTemperatureSensorDateField}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <RenderDatePicker
                    selected={value}
                    onChange={onChange}
                    error={errors[pressureTemperatureSensorDateField]}
                    label={I18n.t(
                      "warranty.sectionII.pressureTemperatureSensorDateField"
                    )}
                    showClearIcon
                    required
                  />
                )}
              />
            </div>
          </div>
          <div style={{ width: "45%", height: "97px" }}></div>
          <Controller
            control={control}
            name={gasLevelIndicatorDateField}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <RenderDatePicker
                selected={value}
                onChange={onChange}
                error={errors[gasLevelIndicatorDateField]}
                label={I18n.t("warranty.sectionII.gasLevelIndicatorDateField")}
                required
                showClearIcon
              />
            )}
          />
          <RenderField
            {...register(workshopNameField, { required: true, maxLength: 255 })}
            label={I18n.t("warranty.sectionII.firmName")}
            value={watch(workshopNameField)}
            error={errors[workshopNameField]}
            type="text"
            required
          />
          <RenderField
            {...register(phoneNumberField, {
              required: true,
              maxLength: 20,
              pattern: ReqexpValidationEnum.PHONE_NUMBER
            })}
            label={I18n.t("warranty.sectionII.phone")}
            validateOnBlur = {!(isTechnicalDetailsJSONHasErrors && !!technicalDetailsValidator(getValues()).errors[phoneNumberField])}
            value={watch(phoneNumberField)}
            type="text"
            error={errors[phoneNumberField]}
            required
          />
          <RenderField
            {...register(regonField, {
              required: true,
              pattern: ReqexpValidationEnum.ONLY_NUMBERS,
              maxLength: 9,
            })}
            label={I18n.t("warranty.sectionII.regonLabel")}
            value={watch(regonField)}
            error={errors[regonField]}
            type="text"
            required
          />
          <Controller
            control={control}
            name={dateField}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <RenderDatePicker
                selected={value}
                onChange={onChange}
                error={errors[dateField]}
                label={I18n.t("warranty.sectionII.date")}
                required
                showClearIcon
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TechnicalDataGasInstallationEdit;
