/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Alert} from 'reactstrap';
import PropTypes from 'prop-types';

class WorkshopInactiveStatusAlert extends React.Component {
  renderAlert() {
    return (
      <Alert color='warning' className='text-center'>
        <Translate value='workshopInactiveStatusAlert.alertContent' />
      </Alert>
    );
  }

  render() {
    return this.props.show ? this.renderAlert() : <div />;
  }
}

WorkshopInactiveStatusAlert.propTypes = {
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const {i18n} = state;

  return {i18n};
};

export default connect(mapStateToProps)(WorkshopInactiveStatusAlert);
