/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import CountryField from 'shared/components/form/country-field/CountryField';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {languageOptions} from 'shared/utils/utils';

export default class FirstSection extends React.Component {
  render() {
    return (
      <Fragment>
        <Field
          name='language'
          component={RenderDropdown}
          options={languageOptions}
          placeholder={I18n.t('distributors.languagePlaceholder')}
          label={I18n.t('distributors.languageLabel')}
          icon='fa fa-language'
          required={true}
        />
        <CountryField />
      </Fragment>
    );
  }
}
