/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { RenderField } from "../../../../../shared/components/form/text-field/hook-form-version/RenderField";
import { I18n } from "react-redux-i18n";
import { useForm } from "react-hook-form";
import { ReqexpValidationEnum } from "../../../../../shared/utils/utils";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { useDispatch, useSelector } from "react-redux";
import { workshopLoyaltyProgramActions } from "shared/actions/workshopLoyaltyProgram.actions";
import { RenderDropdown } from "shared/components/form/dropdown/hook-form-version/RenderDropdown";
import Loadable from 'react-loading-overlay';
import WorkshopLoyaltyProgramModal from '../../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';

const ScoredProductsModalForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const [productTypeId, setProductTypeId] = useState(null);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const productNames = useSelector(state => state.workshopLoyaltyProgram.productNames);
  const productTypes = useSelector(state => state.workshopLoyaltyProgram.productTypes);
  const productNamesFetching = useSelector(state => state.workshopLoyaltyProgram.productNamesFetching);
  const productTypesFetching = useSelector(state => state.workshopLoyaltyProgram.productTypesFetching);

  const nameField = "homologationProductId";
  const typeField = "productTypeId";
  const descriptionField = "description";
  const productValueField = "value";
  const { product, pageSize, setPage } = props;

  useEffect(() => {
    if (product && productTypes) {
      setValue(nameField, product.homologationProductId.toString());
      setValue(typeField, product.productType.id);
      setValue(descriptionField, product.description);
      setValue(productValueField, product.value);
      setValue('id', product.id);
    }
  }, [product, productTypes]);

  useEffect(() => {
    dispatch(workshopLoyaltyProgramActions.getScoredProductTypes());
  }, []);

  useEffect(() => {
    if (productTypeId) {
      dispatch(workshopLoyaltyProgramActions.getScoredProductNames(productTypeId));
      product ? firstTimeRender ? setValue(nameField, product.homologationProductId.toString()) : setValue(nameField, null) : setValue(nameField, null);
      setFirstTimeRender(false);
    }
  }, [productTypeId]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.productTypeId) {
        setProductTypeId(value.productTypeId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useImperativeHandle(ref, () => ({
    onSave: handleSubmit((data) => onSubmit(data)),
  }));

  const onSubmit = (data) => {
    if (props.product) {
      dispatch({
        type: updateDataSideBarConstants.SHOW,
        variableComponent: (
          <WorkshopLoyaltyProgramModal
            updateScoredProduct
            product={data}
            pageSize={pageSize}
            setPage={setPage} />
        ),
        title: I18n.t("workshopLoyaltyProgram.scoredProducts.wannahEditProduct"),
      })
    } else {
      dispatch(workshopLoyaltyProgramActions.createScoredProduct(data, pageSize));
      setPage();
    }
  }

  return (
    <Loadable active={productNamesFetching || productTypesFetching} spinner>
      <RenderDropdown
        {...register(typeField, { required: true })}
        options={productTypes.map(type => { return { label: type.name, value: type.id } })}
        value={watch(typeField)}
        label={I18n.t("workshopLoyaltyProgram.scoredProducts.productType")}
        error={errors[typeField]}
        required
      />
      <RenderDropdown
        {...register(nameField, { required: true })}
        options={productNames.map(name => { return { label: name.homologationProductFullName, value: name.homologationProductId } })}
        value={watch(nameField)}
        disabled={!watch(typeField)}
        label={I18n.t("workshopLoyaltyProgram.scoredProducts.productName")}
        error={errors[nameField]}
        required
      />
      <RenderField
        {...register(descriptionField, { required: true, maxLength: 255 })}
        value={watch(descriptionField)}
        error={errors[descriptionField]}
        label={I18n.t("workshopLoyaltyProgram.scoredProducts.shortDescription")}
        required
      />
      <RenderField
        {...register(productValueField, { required: true, pattern: ReqexpValidationEnum.ONLY_NUMBERS, min: 0 })}
        value={watch(productValueField)}
        error={errors[productValueField]}
        label={I18n.t("workshopLoyaltyProgram.scoredProducts.productValue")}
        required
      />
    </Loadable>
  )
});

export default ScoredProductsModalForm;
