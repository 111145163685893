/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {countryConstants} from '../constants/country.constants';
import {countryService} from '../services/country.service';

export const countryActions = {
  findAll,
};

function findAll() {
  return dispatch => {
    dispatch({type: countryConstants.COUNTRY_REQUEST_START});
    countryService.findAll().then(
      countries => {
        dispatch({type: countryConstants.LOAD_COUNTRIES, payload: countries});
        dispatch({type: countryConstants.COUNTRY_REQUEST_END});
      },
      () => {
        dispatch({type: countryConstants.COUNTRY_REQUEST_END});
      }
    );
  };
}
