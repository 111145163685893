/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Workshops} from './workshops/Workshops';
import {Users} from './users/Users';
import {Services} from './services/Services';
import {PartsContainer} from './parts/PartsContainer';
import {Statutes} from './statutes/Statutes';
import Distributors from './distributors/Distributors';
import Promotions from './promotions/Promotions';
import Settings from './settings/Settings';
import Statistics from './statistics/Statistics';
import {LanguagesContainer} from './languages/LanguagesContainer';
import {SubscriptionsContainer} from './subscriptions/SubscriptionsContainer';
import BarcodesContainer from './barcodes/BarcodesContainer';
import WarrantyBookList from '../../warranty/warranty-books-list/WarrantyBookList';
import WarrantyEdit from '../../warranty/warranty-edit/WarrantyEdit';
import { WorkshopLoyaltyProgram } from './workshop-loyalty-program/WorkshopLoyaltyProgram';

export default class AdminContainer extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/admin/workshops' component={Workshops} />
          <Route exact path='/admin/users' component={Users} />
          <Route exact path='/admin/services' component={Services} />
          <Route exact path='/admin/parts/:id' component={PartsContainer} />
          <Route exact path='/admin/statutes' component={Statutes} />
          <Route exact path='/admin/distributors' component={Distributors} />
          <Route exact path='/admin/promotions' component={Promotions} />
          <Route exact path='/admin/workshop-loyalty-program' component={WorkshopLoyaltyProgram} />
          <Route exact path='/admin/statistics' component={Statistics} />
          <Route exact path='/admin/settings' component={Settings} />
          <Route exact path='/admin/languages' component={LanguagesContainer} />
          <Route
            exact
            path='/admin/subscriptions'
            component={SubscriptionsContainer}
          />
          <Route exact path='/admin/barcodes' component={BarcodesContainer} />
          <Route exact path='/admin/warranty' component={WarrantyBookList} />
          <Route exact path='/admin/warranty/:id' component={WarrantyEdit} />

          <Redirect to={'/admin/workshops'} />
        </Switch>
      </div>
    );
  }
}
