/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import FullCalendar from 'fullcalendar-reactwrapper';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import './schedule-main.css';
import moment from 'moment';
import {Button} from 'reactstrap';

export const buttonText = () => ({
  today: I18n.t('scheduleToolbar.today'),
  month: I18n.t('scheduleToolbar.month'),
  week: I18n.t('scheduleToolbar.week'),
  day: I18n.t('scheduleToolbar.day'),
  list: I18n.t('scheduleToolbar.list'),
});

export class ScheduleMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerRight: '',
      defaultView: '',
      defaultDate: moment().format(),
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.onEventDrop = this.onEventDrop.bind(this);
    this.onEventResize = this.onEventResize.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    const isWideScreen = window.innerWidth >= 768;

    this.setState({
      headerRight: isWideScreen ? 'month,agendaWeek,agendaDay,listWeek' : '',
      defaultView: isWideScreen ? 'agendaWeek' : 'listWeek',
    });
  }

  onViewChange(view) {
    if (this.state.defaultView !== view.name)
      this.setState({defaultView: view.name});
    if (this.state.defaultDate !== view.intervalStart.format())
      this.setState({defaultDate: view.intervalStart.format()});
  }

  onEventDrop(event, delta, revert) {
    this.props.onEventDrop(event, delta.as('minutes'), revert);
  }

  onEventResize(event, delta, revert) {
    this.props.onEventResize(event, delta.as('minutes'), revert);
  }

  onEventClick(event) {
    this.props.onEventClick(event);
  }

  render() {
    const {events, locale, workingDays, freeDays} = this.props;

    const businessHours = workingDays.map(wd => ({
      dow: [wd.dayOfWeekNumber !== 7 ? wd.dayOfWeekNumber : 0],
      start: wd.startTime.substring(0, 5),
      end: wd.endTime.substring(0, 5),
    }));

    const customButtons = {
      createBtn: {
        text: I18n.t('scheduleToolbar.createBtn'),
        click: this.props.onCreateClick,
      },
    };

    const freeDaysBgEvents = freeDays.map(fd => ({
      start: `${fd}T00:00:00`,
      end: `${fd}T23:59:59`,
      rendering: 'background',
      backgroundColor: 'red',
    }));

    const allEvents = [...events, ...freeDaysBgEvents];

    return (
      <Fragment>
        <FullCalendar
          events={allEvents}
          header={{
            left: 'prev,today,next createBtn',
            center: 'title',
            right: this.state.headerRight,
          }}
          defaultView={this.state.defaultView}
          defaultDate={this.state.defaultDate}
          nowIndicator={true}
          minTime='06:00:00'
          maxTime='18:00:00'
          timeFormat='H:mm'
          slotLabelFormat='H:mm'
          locale={locale}
          buttonText={buttonText()}
          height='parent'
          slotDuration={{minutes: 15}}
          allDaySlot={false}
          allDayText={I18n.t('scheduleToolbar.allDay')}
          viewRender={this.onViewChange}
          editable={true}
          eventDrop={this.onEventDrop}
          eventResize={this.onEventResize}
          eventColor='#22BAA0'
          eventClick={this.onEventClick}
          noEventsMessage={I18n.t('scheduleToolbar.noEvents')}
          views={{week: {columnFormat: 'ddd DD/MM'}}}
          businessHours={businessHours}
          customButtons={customButtons}
        />
      </Fragment>
    );
  }
}

ScheduleMain.propTypes = {
  events: PropTypes.array,
  locale: PropTypes.string.isRequired,
  onEventDrop: PropTypes.func,
  onEventResize: PropTypes.func,
  onEventClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  workingDays: PropTypes.array,
  freeDays: PropTypes.array,
};

ScheduleMain.defaultProps = {
  events: [],
  onEventDrop: () => {},
  onEventResize: () => {},
  onEventClick: () => {},
  onCreateClick: () => {},
  workingDays: [],
  freeDays: [],
};
