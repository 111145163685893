/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {promotionConstants} from '../constants/promotion.constants';
import {promotionService} from '../services/promotion.service';
import {messageActions} from './message.actions';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';

export const promotionActions = {
  findPromotionById,
  findAllPromotions,
  findAllPromotionsByWorkshopId,
  createPromotion,
  updatePromotion,
  changePromotionStatus,
  hasCreationConsent,
};

function findPromotionById(promotionId) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService.getPromotionById(promotionId).then(
      response => {
        dispatch({
          type: promotionConstants.LOAD_PROMOTION,
          payload: response,
        });
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(messageActions.errorMessage('promotions.fetchErrorMsg'));
      }
    );
  };
}

function findAllPromotions() {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService.getAllPromotions().then(
      response => {
        dispatch({
          type: promotionConstants.LOAD_PROMOTIONS,
          payload: response,
        });
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(messageActions.errorMessage('promotions.fetchErrorMsg'));
      }
    );
  };
}

function findAllPromotionsByWorkshopId(workshopId, activeOnly) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService
      .getAllPromotionsByWorkshopId(workshopId, activeOnly)
      .then(
        response => {
          dispatch({
            type: promotionConstants.LOAD_PROMOTIONS,
            payload: response,
          });
          dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        },
        error => {
          dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
          dispatch(messageActions.errorMessage('promotions.fetchErrorMsg'));
        }
      );
  };
}

function createPromotion(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService.createPromotion(data).then(
      response => {
        dispatch(messageActions.successMessage('promotions.createSuccessMsg'));
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
        onSuccess();
        dispatch({type: promotionConstants.REFRESH_PROMOTION_DATA});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(messageActions.errorMessage('promotions.createErrorMsg'));
      }
    );
  };
}

function updatePromotion(promotionId, data) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService.updatePromotion(promotionId, data).then(
      response => {
        dispatch({
          type: promotionConstants.LOAD_PROMOTION,
          payload: response,
        });
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(messageActions.successMessage('promotions.updateSuccessMsg'));
        dispatch({type: promotionConstants.REFRESH_PROMOTION_DATA});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(messageActions.errorMessage('promotions.updateErrorMsg'));
      }
    );
  };
}

function changePromotionStatus(promotionID, newStatus) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});
    return promotionService.changePromotionStatus(promotionID, newStatus).then(
      response => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        if (newStatus === promotionConstants.STATUS_DELETED) {
          dispatch(
            messageActions.successMessage('promotions.statusDeleteSuccessMsg')
          );
        } else {
          dispatch(
            messageActions.successMessage('promotions.statusChangeSuccessMsg')
          );
        }
        dispatch({type: promotionConstants.REFRESH_PROMOTION_DATA});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(
          messageActions.errorMessage('promotions.statusChangeErrorMsg')
        );
      }
    );
  };
}

function hasCreationConsent(workshopId) {
  return dispatch => {
    dispatch({type: promotionConstants.PROMOTION_REQUEST_START});

    return promotionService.hasCreationConsent(workshopId).then(
      response => {
        dispatch({
          type: promotionConstants.LOAD_CREATION_CONSENT,
          payload: response,
        });
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
      },
      error => {
        dispatch({type: promotionConstants.PROMOTION_REQUEST_END});
        dispatch(
          messageActions.errorMessage('promotions.fetchCreationConsentErrorMsg')
        );
      }
    );
  };
}
