/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import defaultAvatar from '../../../assets/images/default-avatar.png';
import * as React from 'react';
import './side-bar-nav-content.css';
import { NavLink } from 'react-router-dom';
import { UserCollapse } from '../user-collapse/UserCollapse';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { userService } from '../../services/user.service';
import { partCategoryActions } from '../../actions/part-category.actions';
import CollapsedLinks from '../collpased-links/CollapsedLinks';
import { I18n } from 'react-redux-i18n';
import { userConstants } from '../../constants/user.constants';
import { privilegeConstants } from '../../constants/privilege.constants';
import { warranty } from '../../utils/icons';

class SideBarNavContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCollapsed: false };
  }

  componentWillMount() {
    const { isAuthenticated } = this.props;
    if (
      isAuthenticated &&
      (userService.isAdmin() || userService.isDistributor())
    )
      this.props.dispatch(partCategoryActions.findAllPartCategories());
  }

  renderAvatar() {
    const { loggedUserAvatar } = this.props;
    return (
      <li className='py-2' id='user-avatar'>
        <img
          src={loggedUserAvatar ? loggedUserAvatar : defaultAvatar}
          alt='Avatar'
          style={{ width: '100px', height: '100px', borderRadius: '50%' }}
        />
      </li>
    );
  }

  shouldRenderStatisticsBtn(roleName) {
    return (
      roleName ===
      userService.splitRoleNameAndToLowerCase(userConstants.ROLE_ADMIN) ||
      (roleName ===
        userService.splitRoleNameAndToLowerCase(
          userConstants.ROLE_DISTRIBUTOR
        ) &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_STATISTIC))
    );
  }

  renderViewDependsOnRole() {
    const { isAuthenticated, categories } = this.props;
    const neededPartsLink = {
      id: 'needed-parts',
      name: I18n.t('sideBarNav.neededParts'),
    };
    const partLinks = categories ? categories.concat(neededPartsLink) : [];
    if (
      isAuthenticated &&
      (userService.isAdmin() || userService.isDistributor())
    ) {
      const roleName = userService.isAdmin() ? 'admin' : 'distributor';
      return (
        <ul>
          {this.renderAvatar()}
          <li>
            <NavLink
              to={`/${roleName}/users`}
              exact={true}
              activeClassName='active'
              className='fa fa-users fa-2x'>
              <p>
                <Translate value='sideBarNav.users' />
              </p>
            </NavLink>
          </li>
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <li>
                <NavLink
                  to='/admin/distributors'
                  exact={true}
                  activeClassName='active'
                  className='fa fa-user-plus fa-2x'>
                  <p>
                    <Translate value='sideBarNav.distributors' />
                  </p>
                </NavLink>
              </li>
            )}
          <li>
            <NavLink
              to={`/${roleName}/workshops`}
              exact={true}
              activeClassName='active'
              className='fa fa-wrench fa-2x'>
              <p>
                <Translate value='sideBarNav.workshops' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${roleName}/services`}
              exact={true}
              activeClassName='active'
              className='fa fa-suitcase fa-2x'>
              <p>
                <Translate value='sideBarNav.services' />
              </p>
            </NavLink>
          </li>
          <CollapsedLinks
            pathSuffix={`/${roleName}/parts/`}
            title={'sideBarNav.parts'}
            icon={'fa-cogs'}
            links={partLinks}
          />
          <li>
            <NavLink
              to={`/${roleName}/statutes`}
              exact={true}
              activeClassName='active'
              className='fa fa-book fa-2x'>
              <p>
                <Translate value='sideBarNav.statutes' />
              </p>
            </NavLink>
          </li>
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <>
                <li>
                  <NavLink
                    to='/admin/promotions'
                    exact={true}
                    activeClassName='active'
                    className='fa fa-gift fa-2x'>
                    <p>
                      <Translate value='sideBarNav.promotions' />
                    </p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to='/admin/workshop-loyalty-program'
                    exact={true}
                    activeClassName='active'
                    className='fa fa-diamond fa-2x'>
                    <p>
                      <Translate value='sideBarNav.workshopLoyaltyProgram' />
                    </p>
                  </NavLink>
                </li>
              </>
            )}
          {this.shouldRenderStatisticsBtn(roleName) && (
            <li>
              <NavLink
                to={`/${roleName}/statistics`}
                exact={true}
                activeClassName='active'
                className='fa fa-line-chart fa-2x'>
                <p>
                  <Translate value='sideBarNav.statistics' />
                </p>
              </NavLink>
            </li>
          )}
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <li>
                <NavLink
                  to='/admin/languages'
                  exact={true}
                  activeClassName='active'
                  className='fa fa-globe fa-2x'>
                  <p>
                    <Translate value='sideBarNav.languages' />
                  </p>
                </NavLink>
              </li>
            )}
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <li>
                <NavLink
                  to='/admin/subscriptions'
                  exact={true}
                  activeClassName='active'
                  className='fa fa-star fa-2x'>
                  <p>
                    <Translate value='sideBarNav.subscription' />
                  </p>
                </NavLink>
              </li>
            )}
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <li>
                <NavLink
                  to='/admin/barcodes'
                  exact={true}
                  activeClassName='active'
                  className='fa fa-barcode fa-2x'>
                  <p>
                    <Translate value='barcodes.barcodes' />
                  </p>
                </NavLink>
              </li>
            )}
          {roleName ===
            userService.splitRoleNameAndToLowerCase(
              userConstants.ROLE_ADMIN
            ) && (
              <li>
                <NavLink
                  to='/admin/warranty'
                  exact={true}
                  activeClassName='active'>
                  <p style={{ display: 'flex', alignItems: 'center', marginLeft: '0.3em' }}>
                    <img src={warranty} />
                    <Translate
                      value='warranty.warrantyBooks'
                      style={{ marginLeft: '1em' }}
                    />
                  </p>
                </NavLink>
              </li>
            )}
        </ul>
      );
    } else if (isAuthenticated && userService.isUser())
      return (
        <ul>
          {this.renderAvatar()}
          <li>
            <NavLink
              to='/user/valuations'
              activeClassName='active'
              className='fa fa-address-book fa-2x'>
              <p>
                <Translate value='sideBarNav.valuations' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/user/orders'
              activeClassName='active'
              className='fa fa-handshake-o fa-2x'>
              <p>
                <Translate value='sideBarNav.orders' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/user/cars'
              activeClassName='active'
              className='fa fa-car fa-2x'>
              <p>
                <Translate value='sideBarNav.cars' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/user/loyalty-programs'
              activeClassName='active'
              className='fa fa-star fa-2x'>
              <p>
                <Translate value='sideBarNav.loyaltyPrograms' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/user/code-scanner'
              activeClassName='active'
              className='fa fa-barcode fa-2x'>
              <p>
                <Translate value='barcodes.codeScanner' />
              </p>
            </NavLink>
          </li>
        </ul>
      );
    else if (isAuthenticated && userService.isWorkshop())
      return (
        <ul>
          {this.renderAvatar()}

          {
            // TODO: Michal Balazi change on 8th May 2023 - removed routes
            /*
                      <li>
            <NavLink
              to='/workshop/valuations'
              exact={false}
              activeClassName='active'
              className='fa fa-address-book fa-2x'>
              <p>
                <Translate value='sideBarNav.valuations' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/orders'
              activeClassName='active'
              className='fa fa-handshake-o fa-2x'>
              <p>
                <Translate value='sideBarNav.orders' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/schedule'
              activeClassName='active'
              className='fa fa-calendar fa-2x'>
              <p>
                <Translate value='sideBarNav.schedule' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/valuation-direct-clients'
              exact={true}
              activeClassName='active'
              className='fa fa-folder-open fa-2x'>
              <p>
                <Translate value='sideBarNav.valuationDirectClients' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/services'
              exact={true}
              activeClassName='active'
              className='fa fa-suitcase fa-2x'>
              <p>
                <Translate value='sideBarNav.services' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/needed-parts'
              exact={true}
              activeClassName='active'
              className='fa fa-wrench fa-2x'>
              <p>
                <Translate value='sideBarNav.neededPartsInquiry' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/promotions'
              exact={true}
              activeClassName='active'
              className='fa fa-gift fa-2x'>
              <p>
                <Translate value='sideBarNav.promotions' />
              </p>
            </NavLink>
          </li>
            */
          }

          <li>
            <NavLink
              to='/workshop' //workshop-loyalty-program
              exact={true}
              activeClassName='active'
              className='fa fa-diamond fa-2x'>
              <p>
                <Translate value='sideBarNav.workshopLoyaltyProgram' />
              </p>
            </NavLink>
          </li>

          { /* 
                    <li>
            <NavLink
              to='/workshop/subscriptions'
              exact={true}
              activeClassName='active'
              className='fa fa-star fa-2x'>
              <p>
                <Translate value='sideBarNav.subscription' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/workers'
              exact={true}
              activeClassName='active'
              className='fa fa-users fa-2x'>
              <p>
                <Translate value='sideBarNav.workers' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/code-scanner'
              activeClassName='active'
              className='fa fa-barcode fa-2x'>
              <p>
                <Translate value='barcodes.codeScanner' />
              </p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/workshop/warranty'
              exact={true}
              activeClassName='active'
              className='fa fa-leanpub fa-2x'>
              <p>
                <Translate value='warranty.warrantyBooks' />
              </p>
            </NavLink>
          </li>
          */ }

        </ul>
      );
  }

  render() {
    const { dispatch, isAuthenticated } = this.props;

    return (
      <div className='content-wrapper'>
        {isAuthenticated && <UserCollapse dispatch={dispatch} />}
        {this.renderViewDependsOnRole()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  const { isAuthenticated, loggedUserAvatar } = user;
  const { part } = state;
  const { categories } = part;

  return { isAuthenticated, loggedUserAvatar, categories };
}

const connectedSideBarNavContent = connect(mapStateToProps, null, null, {
  pure: false,
})(SideBarNavContent);
export { connectedSideBarNavContent as SideBarNavContent };
