/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Card, CardText, CardTitle} from 'reactstrap';
import './subscription-plans-list.css';

export class SubscriptionPlansList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSubscriptionPlanId: null,
    };

    this.onSubscriptionPlanClick = this.onSubscriptionPlanClick.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  onSubscriptionPlanClick(subscriptionPlanId) {
    const newId =
      this.state.activeSubscriptionPlanId === subscriptionPlanId
        ? null
        : subscriptionPlanId;
    this.setState({activeSubscriptionPlanId: newId});
    this.props.onSubscriptionPlanClick(newId);
  }

  isActive(subscriptionPlanId) {
    return this.state.activeSubscriptionPlanId === subscriptionPlanId
      ? 'subscription-plan-active'
      : '';
  }

  render() {
    const {subscriptionPlans} = this.props;

    return (
      <Fragment>
        {subscriptionPlans.map((plan, index) => (
          <Card
            key={index}
            body
            className={'clickable my-1 ' + this.isActive(plan.id)}
            onClick={() => this.onSubscriptionPlanClick(plan.id)}>
            <CardTitle
              style={{fontFamily: 'Montserrat-Medium'}}
              className={plan.description ? '' : 'mb-0'}>
              {plan.label}
              <br />
              {plan.price.value} {plan.price.currency}
            </CardTitle>
            <CardText>{plan.description}</CardText>
          </Card>
        ))}
      </Fragment>
    );
  }
}

SubscriptionPlansList.propTypes = {
  subscriptionPlans: PropTypes.array,
  onSubscriptionPlanClick: PropTypes.func,
};

SubscriptionPlansList.defaultProps = {
  subscriptionPlans: [],
  onSubscriptionPlanClick: () => {},
};
