/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import ValuationInquiryResponseFormSchemaPage2 from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-2/ValuationInquiryResponseFormSchemaPage2';
import {validate} from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-2/validators';

const ValuationDirectClientsFormPage5 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage2);

function mapStateToProps(state) {
  const {i18n, part, workshop} = state;
  const {data} = workshop;
  const partCategories = part.categories;
  const currency =
    data && data.workshopDetails ? data.workshopDetails.currency : null;
  const formValues = getFormValues('valuationDirectClientsForm')(state);

  return {i18n, partCategories, currency, formValues};
}

export default connect(mapStateToProps)(ValuationDirectClientsFormPage5);
