/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './valuation-inquiry-summary.css';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import RenderValuationInquiryDetails from '../../valuation-inquiry-details/render-valuation-inquiry-details/RenderValuationInquiryDetails';
import {mapToDetailsObject} from '../../valuation-inquiry-details/render-valuation-inquiry-details/utils';

export class ValuationInquirySummary extends Component {
  renderCarSummary() {
    const {valuationInquiry} = this.props;

    return (
      <Card key='car-summary-card' className='inquiry-summary-card'>
        <CardHeader className='inquiry-summary-card-header'>
          <Translate value='valuationInquirySummary.carDataTitle' />
        </CardHeader>
        <CardBody className='inquiry-summary-card-body'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'carDataTitle'}
            withHeader={false}
            detailsData={
              valuationInquiry && mapToDetailsObject('car', valuationInquiry)
            }
          />
        </CardBody>
      </Card>
    );
  }

  renderUserSummary() {
    const {valuationInquiry} = this.props;

    return (
      <Card key='user-summary-card' className='inquiry-summary-card'>
        <CardHeader className='inquiry-summary-card-header'>
          <Translate value='valuationInquirySummary.userDataTitle' />
        </CardHeader>
        <CardBody className='inquiry-summary-card-body'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'userDataTitle'}
            withHeader={false}
            detailsData={
              valuationInquiry && mapToDetailsObject('user', valuationInquiry)
            }
          />
        </CardBody>
      </Card>
    );
  }

  render() {
    const {valuationInquiry} = this.props;

    return (
      <div className='row'>
        <div className='col-lg-6 col-12 mt-3'>
          {valuationInquiry && this.renderCarSummary()}
        </div>
        <div className='col-lg-6 col-12 mt-3'>
          {valuationInquiry && this.renderUserSummary()}
        </div>
      </div>
    );
  }
}

ValuationInquirySummary.propTypes = {
  valuationInquiry: PropTypes.object.isRequired,
};
