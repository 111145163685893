/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.name = validateRequiredFieldsWithMaxLength(values.name, 255);
  errors.description = validateByLengthOnly(values.description, 255);

  return errors;
};
