/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import './content-wrapper.css';

export default class ContentWrapper extends React.Component {
  render() {
    const {children, icon, title, titleAddon, containerClass, contentClass, scrollY} =
      this.props;
    return (
      <div className={`content-wrapper-container shadow ${containerClass}`} style={{overflowY: scrollY? 'auto' : 'visible'}}>
        <div className='content-wrapper-title bottom-shadow'>
          {!!icon && <i class="fa fa-unlock-alt fa-2x clickable"></i>}
          <Translate value={title} />
          <span>{' ' + titleAddon}</span>
        </div>
        <div
          className={`content-wrapper-content bottom-shadow ${contentClass}`} style={{overflowY: scrollY? 'auto' : 'visible'}}>
          {children}
        </div>
      </div>
    );
  }
}

ContentWrapper.propType = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object,
  containerClass: PropTypes.string,
  contentClass: PropTypes.string,
  titleAddon: PropTypes.string,
  scrollY: PropTypes.bool
};

ContentWrapper.defaultProps = {
  icon: null,
  containerClass: 'default-content-container',
  contentStyle: 'p-3',
  titleAddon: '',
};
