/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n} from 'react-redux-i18n';
import NumberFormat from 'react-number-format';
import {getOrZero} from '../../../utils/utils';
import {getDecimalSeparator} from '../../../utils/i18n';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import './render-currency.css';
import {errorColor} from '../../../utils/style.utils';

class RenderCurrency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      currentValue: props.input.value ? props.input.value : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  renderChangeCurrencyInfo() {
    return (
      <span>
        <span
          id='changeCurrencyInfo'
          data-tip={I18n.t('valuationInquiryResponse.changeCurrencyInfoTip')}
          data-for='changeCurrencyInfo'
          style={{margin: '.5em .25em 0 0'}}>
          <i className='fa fa-info-circle mr-1' />
        </span>
        <ReactTooltip
          id='changeCurrencyInfo'
          className='form-tooltip-short'
          effect='solid'
          globalEventOff='click'
          place='top'
        />
      </span>
    );
  }

  handleChange(values) {
    const {input} = this.props;
    input.onChange(getOrZero(values.floatValue));
    if (!this.state.touched) this.setState({touched: true});
    this.setState({currentValue: values.floatValue});
  }

  render() {
    const {
      input,
      label,
      icon,
      required,
      meta: {error},
      suffix,
      disabled,
    } = this.props;
    const {touched} = this.state;

    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <span
            className='field-label'
            style={touched && error ? {color: errorColor} : null}>
            {/*{changeCurrencyInfo && this.renderChangeCurrencyInfo()*/}
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <div className='ui-inputgroup'>
            <NumberFormat
              className={
                'number-format-field w-100 ' +
                (disabled ? 'input-disabled' : '')
              }
              style={touched && error ? {borderBottomColor: errorColor} : null}
              value={this.state.currentValue}
              onValueChange={this.handleChange}
              decimalScale={2}
              fixedDecimalScale={true}
              allowNegative={false}
              suffix={suffix ? ' ' + suffix : ''}
              decimalSeparator={getDecimalSeparator()}
              disabled={disabled}
            />
          </div>
        </div>
        <div className='col-12'>
          {touched && error ? (
            <span className='error-info'>
              {I18n.t(error.key, error.params)}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n};
}

export default connect(mapStateToProps)(RenderCurrency);
