import React, { useEffect, useLayoutEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button } from "reactstrap";
import RenderDatePicker from "shared/components/form/date-picker/hook-form-version/RenderDatePicker";
import { RenderDropdown } from "shared/components/form/dropdown/hook-form-version/RenderDropdown";
import { RenderField } from "shared/components/form/text-field/hook-form-version/RenderField";
import styles from "./WarrantyBookEditMode.module.css";
import { getOrNull, isEmptyArray, rangeArray } from "shared/utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from "react-redux-i18n";
import { carActions } from "shared/actions/car.actions";
import { carDetailsValidator, warrantyNumberValidator } from "../../warranty-edit/validators";
import useWarrantyBookLocalStorageGetValue from "shared/hooks/useWarrantyBookLocalStorageGetValue";
import {ReqexpValidationEnum} from 'shared/utils/utils';
import {dateUtils} from 'shared/utils/date.utils';

const WarrantyBookEditMode = (props) => {
  const {
    isCarDetailsJSONHasErrors,
    setIsCarDetailsJSONHasErrors,
    setCarDetailsJSON,
    setWarrantyNumberJSON,
  } = props;
  const {
    register,
    clearErrors,
    setValue,
    setError,
    control,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", criteriaMode: "all" });

  const dispatch = useDispatch();
  const carDetails = useWarrantyBookLocalStorageGetValue("carDetails");
  const warrantyNumber = useWarrantyBookLocalStorageGetValue("warrantyNumber");
  const vin = useWarrantyBookLocalStorageGetValue("vin");

  const carBrands = useSelector((state) => state.car.carBrands);
  const isCarFetching = useSelector((state) => state.car.isFetching);

  useEffect(() => {
    dispatch(carActions.findCarBrands());
  }, []);

  const warrantyNumberField = "warrantyNumber";
  const brandField = "brand";
  const modelField = "model";
  const productionYearField = "productionYear";
  const gasInstallationDateField = "gasInstallationDate";
  const engineCapacityCm3Field = "engineCapacityCm3";
  const vinField = "vin";
  const enginePowerKwField = "enginePowerKw";
  const registrationNumberField = "registrationNumber";
  const mileageField = "mileage";
  const mileageUnitField = "mileageUnit";
  const years = () => {
    return Array.from(rangeArray(1970, new Date().getFullYear() + 1, 1))
      .reverse()
      .map((year) => ({ label: year.toString(), value: year }));
  };

  const carBrandsSelector = () => {
    return carBrands && !isCarFetching
      ? carBrands.map((carBrand) => ({
          label: carBrand.name,
          value: carBrand.name,
        }))
      : [];
  };

  const carModelSelector = () => {
    const carBrandFieldValue = watch(brandField);
    const selectedCarBrand =
      carBrands && !isCarFetching
        ? carBrands.filter((carBrand) => carBrand.name === carBrandFieldValue)
        : [];
    const selectedCarBrandModels = !isEmptyArray(selectedCarBrand)
      ? selectedCarBrand[0].models
      : [];
    return selectedCarBrandModels
      .map((carModel) => ({ label: carModel.name, value: carModel.name }))
      .concat([
        { label: I18n.t("valuationInquiry.otherModel"), value: "other" },
      ]);
  };

  useEffect(() => {
    if (carDetails) {
      setValue(vinField, vin);
      setValue(brandField, carDetails.brand);
      setValue(modelField, carDetails.model);
      setValue(productionYearField, carDetails.productionYear);
      setValue(engineCapacityCm3Field, carDetails.engineCapacityCm3);
      setValue(gasInstallationDateField, carDetails.gasInstallationDate);
      setValue(registrationNumberField, carDetails.registrationNumber);
      setValue(mileageField, carDetails.mileage);
      setValue(mileageUnitField, carDetails.mileageUnit);
      setValue(warrantyNumberField, warrantyNumber);
      setValue(enginePowerKwField, carDetails.enginePowerKw)
    }
  }, [carDetails, warrantyNumber]);

  useEffect(() => {
    const subscription = watch((value) => {
      const { warrantyNumber, ...carDetailsWithoutWarrantyNumber } = value;
      carDetailsWithoutWarrantyNumber.gasInstallationDate = dateUtils.getDateFromInput(carDetailsWithoutWarrantyNumber.gasInstallationDate);
      setCarDetailsJSON(carDetailsWithoutWarrantyNumber);
      setWarrantyNumberJSON(warrantyNumber);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isValid) {
      setIsCarDetailsJSONHasErrors(false);
      clearErrors();
    }
  }, [isCarDetailsJSONHasErrors, isValid]);

  useEffect(() => {
    const setErrorMessage = (field) => {
      const errorField = carDetailsValidator(getValues()).errors[field];
      errorField
        ? setError(field, {
            type: errorField.key.substring(errorField.key.indexOf(".") + 1),
          })
        : null;
    };
    if (isCarDetailsJSONHasErrors) {
      setErrorMessage(brandField);
      setErrorMessage(modelField);
      setErrorMessage(productionYearField);
      setErrorMessage(registrationNumberField);
      setErrorMessage(gasInstallationDateField);
      setErrorMessage(engineCapacityCm3Field);
      setErrorMessage(enginePowerKwField);
      setErrorMessage(mileageField);
      const warrantyNumberSetError = warrantyNumberValidator(watch(warrantyNumberField)).errors[warrantyNumberField]
      warrantyNumberSetError? setError(warrantyNumberField, {
            type: warrantyNumberSetError.key.substring(warrantyNumberSetError.key.indexOf(".") + 1),
          })
        : null;
    }
  }, [isCarDetailsJSONHasErrors]);

  return (
    <div className={styles.Container}>
      <RenderField
        {...register(warrantyNumberField, {
          required: true,
          maxLength: 60,
        })}
        label={I18n.t("warranty.sectionI.warrantyNumber")}
        error={errors[warrantyNumberField]}
        value={watch(warrantyNumberField)}
        type="text"
        required
      />
      <RenderDropdown
        {...register(brandField, { required: true })}
        options={carBrandsSelector()}
        value={watch(brandField)}
        label={I18n.t("warranty.sectionI.brand")}
        error={errors[brandField]}
        required
      />
      <RenderDropdown
        {...register(modelField, { required: true })}
        options={carModelSelector()}
        value={watch(modelField)}
        label={I18n.t("warranty.sectionI.model")}
        disabled={!watch(brandField)}
        error={errors[modelField]}
        required
      />
      <RenderDropdown
        {...register(productionYearField, { required: true })}
        options={years()}
        value={watch(productionYearField)}
        label={I18n.t("warranty.sectionI.productionYear")}
        error={errors[productionYearField]}
        icon="fa fa-calendar"
        required
      />
      <Controller
        control={control}
        name={gasInstallationDateField}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <RenderDatePicker
            selected={value}
            onChange={onChange}
            error={errors[gasInstallationDateField]}
            label={I18n.t("warranty.sectionI.gasInstallationDate")}
            showClearIcon
            required
          />
        )}
      />
      <RenderField
        {...register(engineCapacityCm3Field, {
          required: true,
          pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          min: 1,
          max: 20000
        })}
        label={I18n.t("warranty.sectionI.engineCapacityCm3")}
        error={errors[engineCapacityCm3Field]}
        value={watch(engineCapacityCm3Field)}
        required
      />
      <RenderField
        {...register(enginePowerKwField, {
          required: true,
          pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          min: 1,
          max: 9999
        })}
        label={I18n.t("warranty.sectionI.enginePowerKw")}
        error={errors[enginePowerKwField]}
        value={watch(enginePowerKwField)}
        required
      />
      <RenderField
        {...register(vinField)}
        label={I18n.t("warranty.sectionI.vin")}
        value={watch(vinField)}
        type="text"
        required
        readOnly
      />
      <RenderField
        {...register(registrationNumberField, {
          required: true,
          maxLength: 60,
        })}
        label={I18n.t("warranty.sectionI.registrationNumber")}
        error={errors[registrationNumberField]}
        value={watch(registrationNumberField)}
        type="text"
        required
      />
      <div className={styles.BottomSection}>
        <RenderField
          {...register(mileageField, {
            required: true,
            pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          })}
          label={I18n.t("warranty.sectionI.mileage")}
          error={errors[mileageField]}
          value={watch(mileageField)}
          type="text"
          required
        />
        <input {...register(mileageUnitField)} value ={watch(mileageUnitField)} hidden />
        <div
          className={
            watch(mileageUnitField) === "KM"
              ? styles.SwitchChecked
              : styles.Switch
          }
          onClick={() => setValue(mileageUnitField, "KM")}
        >
          <span className={styles.Unit}>km</span>
        </div>
        <div
          className={
            watch(mileageUnitField) === "MIL"
              ? styles.SwitchChecked
              : styles.Switch
          }
          onClick={() => setValue(mileageUnitField, "MIL")}
        >
          <span className={styles.Unit}>mil</span>
        </div>
      </div>
    </div>
  );
};

export default WarrantyBookEditMode;
