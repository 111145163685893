/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Field} from 'redux-form';
import RenderDropdown from '../dropdown/RenderDropdown';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {countryActions} from 'shared/actions/country.actions';
import './country-field.css';
import PropTypes from 'prop-types';
import {mapCountriesToLabelValueObjects} from './utils';

class CountryField extends React.Component {
  componentDidMount() {
    this.props.findAll();
  }

  render() {
    const {countries, disabled, required, showClear} = this.props;
    const mappedCountries = mapCountriesToLabelValueObjects(countries);
    return (
      <Field
        name='country'
        component={RenderDropdown}
        options={mappedCountries}
        disabled={disabled}
        placeholder={I18n.t('country.countryPlaceholder')}
        label={I18n.t('country.countryLabel')}
        icon='fa fa-globe'
        required={required}
        showClear={showClear}
      />
    );
  }
}

CountryField.propTypes = {
  required: PropTypes.bool,
};

CountryField.defaultProps = {
  required: true,
};

const mapStateToProps = state => {
  const {i18n, country} = state;
  const {countries} = country;

  return {i18n, countries};
};

const mapDispatchToProps = {
  findAll: countryActions.findAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryField);
