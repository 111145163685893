/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Loadable from 'react-loading-overlay';
import {connect, useSelector} from 'react-redux';
import {Translate, I18n} from 'react-redux-i18n';
import { RenderField } from 'shared/components/form/text-field/hook-form-version/RenderField';
import './workshop-list-selected-workshop.css';

const WorkshopListSelectedWorkshop = (props) => {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const registrationData = useSelector(state => state.workshopLoyaltyProgram.registrationData);
  const registartionFetching = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);

  const workshopNameField = "workshopName";
  const workshopWorkerField = "workshopWorker";
  const cityField = "city";
  const streetAddressField = "streetAddress";
  const emailField = "email";
  const phoneNumberField = "phoneNumber";
  const nipField = "nip";

  useEffect(() => {
    if (registrationData) {
      setValue(workshopNameField, registrationData.workshopName);
      setValue(streetAddressField, registrationData.address.streetAddress);
      setValue(cityField, registrationData.address.city);
      registrationData.phoneNumber ?
        setValue(phoneNumberField, registrationData.phoneNumber)
        : setValue(phoneNumberField, "");
      registrationData.workshopWorker ? setValue(workshopWorkerField, registrationData.workshopWorker) : setValue(workshopWorkerField, "");
      registrationData.nip ? setValue(nipField, registrationData.nip) : setValue(nipField, "");
      registrationData.email ? setValue(emailField, registrationData.email) : setValue(emailField, "");
    }
  }, [registrationData]);

  return (
    <Loadable active={registartionFetching} spinner>
      <div className='workshop-list-selected-workshop-wrapper'>
        <RenderField
          {...register(workshopNameField)}
          label={I18n.t("workshopLoyaltyProgram.register.workshopName")}
          value={watch(workshopNameField)}
          error={errors[workshopNameField]}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(workshopWorkerField)}
          label={I18n.t("workshopLoyaltyProgram.register.representant")}
          error={errors[workshopWorkerField]}
          value={watch(workshopWorkerField)}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(cityField)}
          label={I18n.t("workshopLoyaltyProgram.register.city")}
          error={errors[cityField]}
          value={watch(cityField)}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(streetAddressField)}
          label={I18n.t("workshopLoyaltyProgram.register.address")}
          error={errors[streetAddressField]}
          value={watch(streetAddressField)}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(emailField)}
          validateOnBlur={true}
          label={I18n.t("workshopLoyaltyProgram.register.email")}
          error={errors[emailField]}
          value={watch(emailField)}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(phoneNumberField)}
          validateOnBlur={true}
          label={I18n.t("workshopLoyaltyProgram.register.phone")}
          value={watch(phoneNumberField)}
          error={errors[phoneNumberField]}
          type="text"
          disabled={true}
        />
        <RenderField
          {...register(nipField)}
          error={errors[nipField]}
          label={I18n.t("workshopLoyaltyProgram.register.nip")}
          value={watch(nipField)}
          type="text"
          disabled={true}
        />
      </div>
    </Loadable>
  );
}

export default WorkshopListSelectedWorkshop;
