/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React, { useEffect } from "react";
import styles from "../WarrantyInstalationData.module.css";
import { I18n } from "react-redux-i18n";
import { RenderField } from "shared/components/form/text-field/hook-form-version/RenderField";
import RenderDatePicker from "shared/components/form/date-picker/hook-form-version/RenderDatePicker";
import RenderTextArea from "shared/components/form/text-area/hook-form-version/RenderTextArea";
import { useForm, Controller } from "react-hook-form";
import { additionalAssemblyValidator } from "../../warranty-edit//validators";
import useWarrantyBookLocalStorageGetValue from "shared/hooks/useWarrantyBookLocalStorageGetValue";
import { ReqexpValidationEnum } from "shared/utils/utils";
import {dateUtils} from 'shared/utils/date.utils';

const AdditionalAssemblyEdit = (props) => {
  const {
    setAdditionalInstallationDetailsJSON,
    isAdditionalInstallationDetailsJSONHasErrors,
    setIsAdditionalInstallationDetailsJSONHasErrors,
  } = props;
  const {
    register,
    control,
    formState: { errors, isValid },
    clearErrors,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({ mode: "onChange", criteriaMode: "all" });

  const workshopWorkerField = "workshopWorker";
  const workshopNameField = "workshopName";
  const addressField = "address";
  const phoneNumberField = "phoneNumber";
  const nipField = "nip";
  const regonField = "regon";
  const workshopWorkerEmailField = "workshopWorkerEmail";
  const dateField = "date";
  const additionalInspectionDescriptionField =
    "additionalInspectionDescription";

  const additionalInstallationDetails = useWarrantyBookLocalStorageGetValue(
    "additionalInstallationDetails"
  );

  useEffect(() => {
    if (additionalInstallationDetails) {
      setValue(
        workshopWorkerField,
        additionalInstallationDetails.workshopWorker
      );
      setValue(workshopNameField, additionalInstallationDetails.workshopName);
      setValue(addressField, additionalInstallationDetails.address);
      setValue(phoneNumberField, additionalInstallationDetails.phoneNumber);
      setValue(nipField, additionalInstallationDetails.nip);
      setValue(regonField, additionalInstallationDetails.regon);
      setValue(
        workshopWorkerEmailField,
        additionalInstallationDetails.workshopWorkerEmailField
      );
      setValue(dateField, additionalInstallationDetails.date);
      setValue(
        additionalInspectionDescriptionField,
        additionalInstallationDetails.additionalInspectionDescription
          ? additionalInstallationDetails.additionalInspectionDescription
          : ""
      );
    }
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      value.date = dateUtils.getDateFromInput(value.date);
      setAdditionalInstallationDetailsJSON(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isValid) {
      setIsAdditionalInstallationDetailsJSONHasErrors(false);
      clearErrors();
    }
  }, [isAdditionalInstallationDetailsJSONHasErrors, isValid]);

  useEffect(() => {
    const setErrorMessage = (field) => {
      const errorField = additionalAssemblyValidator(getValues()).errors[field];
      errorField
        ? setError(field, {
            type: errorField.key.substring(errorField.key.indexOf(".") + 1),
          })
        : null;
    };
    if (isAdditionalInstallationDetailsJSONHasErrors) {
      setErrorMessage(additionalInspectionDescriptionField);
      setErrorMessage(workshopNameField);
      setErrorMessage(phoneNumberField);
      setErrorMessage(nipField);
      setErrorMessage(regonField);
      setErrorMessage(workshopWorkerEmailField);
      setErrorMessage(dateField);
    }
  }, [isAdditionalInstallationDetailsJSONHasErrors]);

  return (
    <div className={styles.SecondSegmentCollapsedContainer}>
      <div className={styles.SegmentTextStyle}>
        <Controller
          control={control}
          rules={{ maxLength: 1000 }}
          name={additionalInspectionDescriptionField}
          render={({ field: { value, onChange } }) => (
            <RenderTextArea
              rows={5}
              error={errors[additionalInspectionDescriptionField]}
              label={I18n.t("warranty.additionalAssemblyField")}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className={styles.ColumnContainer}>
        <div className={styles.Column} style={{ padding: "32px 0px 0px 32px" }}>
          <RenderField
            {...register(workshopWorkerField, {
              maxLength: 255,
            })}
            label={I18n.t("warranty.sectionII.workshopWorker")}
            error={errors[workshopWorkerField]}
            value={watch(workshopWorkerField)}
            type="text"
          />
          <RenderField
            {...register(addressField, {
              maxLength: 255,
            })}
            label={I18n.t("warranty.sectionII.address")}
            error={errors[addressField]}
            value={watch(addressField)}
            type="text"
          />
          <RenderField
            {...register(nipField, {
              pattern: ReqexpValidationEnum.ONLY_NUMBERS,
              maxLength: 10,
            })}
            error={errors[nipField]}
            label={I18n.t("warranty.sectionII.nipLabel")}
            value={watch(nipField)}
            type="text"
          />
          <RenderField
            {...register(workshopWorkerEmailField, {
              maxLength: 60,
              pattern: ReqexpValidationEnum.EMAIl,
            })}
            validateOnBlur={true}
            label={I18n.t("warranty.sectionII.email")}
            error={errors[workshopWorkerEmailField]}
            value={watch(workshopWorkerEmailField)}
            type="text"
          />
        </div>
        <div className={styles.Column} style={{ padding: "32px 32px 0px 0px" }}>
          <RenderField
            {...register(workshopNameField, { maxLength: 60 })}
            label={I18n.t("warranty.sectionII.firmName")}
            value={watch(workshopNameField)}
            error={errors[workshopNameField]}
            type="text"
          />
          <RenderField
            {...register(phoneNumberField, {
              maxLength: 20,
              pattern: ReqexpValidationEnum.PHONE_NUMBER,
            })}
            validateOnBlur={true}
            label={I18n.t("warranty.sectionII.phone")}
            value={watch(phoneNumberField)}
            error={errors[phoneNumberField]}
            type="text"
          />
          <RenderField
            {...register(regonField, {
              pattern: ReqexpValidationEnum.ONLY_NUMBERS,
              maxLength: 10,
            })}
            label={I18n.t("warranty.sectionII.regonLabel")}
            value={watch(regonField)}
            error={errors[regonField]}
            type="text"
          />
          <Controller
            control={control}
            name={dateField}
            render={({ field: { value, onChange } }) => (
              <RenderDatePicker
                selected={value}
                onChange={onChange}
                error={errors[dateField]}
                label={I18n.t("warranty.sectionII.date")}
                showClearIcon
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalAssemblyEdit;
