/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {WorkerForm} from './WorkerForm';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {workshopWorkerConstants} from '../../../constants/workshop-worker.constants';
import {workshopWorkerActions} from '../../../actions/workshop-worker.actions';
import {validate} from './worker-form-validators';

const formName = 'workerEditForm';

export const WorkerFormReduxFormWrapper = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
})(WorkerForm);

const selector = formValueSelector(formName);

class WorkerEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const {findWorkerById, workerId} = this.props;
    findWorkerById(workerId);
  }

  componentWillUnmount() {
    this.props.clearWorker();
  }

  update(data) {
    const {updateWorker, workerId} = this.props;
    updateWorker(workerId, data);
  }

  delete() {
    const {deleteWorker, workerId, onDeleteSuccess} = this.props;
    deleteWorker(workerId, onDeleteSuccess);
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <WorkerFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          handleDelete={this.delete}
        />
      </Loadable>
    );
  }
}

WorkerEditForm.propTypes = {
  workerId: PropTypes.string.isRequired,
  findWorkerById: PropTypes.func.isRequired,
  updateWorker: PropTypes.func.isRequired,
  deleteWorker: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, workshopWorker} = state;

  const initialValues = workshopWorker.activeWorker || {};
  const {isFetching} = workshopWorker;
  const avatarFieldValue = selector(state, 'avatar');

  return {
    i18n,
    initialValues,
    isFetching,
    formName,
    avatarFieldValue,
  };
}

const mapDispatchToProps = {
  findWorkerById: workshopWorkerActions.findWorkerById,
  clearWorker: () => ({type: workshopWorkerConstants.CLEAR_WORKER}),
  updateWorker: workshopWorkerActions.updateWorker,
  deleteWorker: workshopWorkerActions.deleteWorker,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerEditForm);
