/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateGeographicData,
  validateRequiredNumberWithRange,
} from 'shared/components/form/common-validators/common-validators';
import ValidationMessageEnum from 'shared/utils/utils';

export const validate = values => {
  const errors = {};
  errors.workshopsDistanceRadius = validateRequiredNumberWithRange(
    values.workshopsDistanceRadius,
    1,
    9999
  );
  errors.latitude = validateGeographicData(values.latitude);
  errors.longitude = validateGeographicData(values.longitude);
  errors.workshopsIds = validateWorkshopsIds(values.workshopsIds);
  return errors;
};

const validateWorkshopsIds = workshopsIds => {
  if (!workshopsIds || !workshopsIds.length) return {_error: required};
  else {
    const workshopsIdsArrayErrors = [];
    workshopsIds.forEach((workshopId, index) => {
      if (!workshopId) workshopsIdsArrayErrors[index] = required;
    });

    if (workshopsIdsArrayErrors.length) return workshopsIdsArrayErrors;
  }
};

const required = {key: ValidationMessageEnum.REQUIRED, params: {}};
