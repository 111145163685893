/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import PropTypes from 'prop-types';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {Checkbox} from 'primereact/checkbox';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {DatePickerInput} from '../../form/date-time-picker/RenderDateTimePicker';
import {getOrEmptyObj, getOrNull} from '../../../utils/utils';

const daysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

class WorkingHoursTable extends React.Component {
  constructor(props) {
    super(props);

    this.renderIsOpenCell = this.renderIsOpenCell.bind(this);
    this.renderStartTimeCell = this.renderStartTimeCell.bind(this);
    this.renderTimePicker = this.renderTimePicker.bind(this);
    this.renderEndTimeCell = this.renderEndTimeCell.bind(this);
  }

  renderSaveBtn() {
    return (
      <div
        className='col-sm-2 col-12 m-1 order-sm-1'
        style={{minWidth: '150px'}}>
        <Button
          className='main-btn w-100'
          onClick={() => this.props.onSaveClick()}>
          <Translate value='workingHours.saveBtn' />
        </Button>
      </div>
    );
  }

  renderIsOpenCell(data) {
    return (
      <Checkbox
        onChange={e => this.props.isOpenChanged(data.dayOfWeek, e.checked)}
        checked={data.isOpen}
      />
    );
  }

  renderStartTimeCell(data) {
    return this.renderTimePicker(
      data.dayOfWeek,
      data.startTime,
      !data.isOpen,
      this.props.startTimeChanged,
      null,
      data.endTime
    );
  }

  renderTimePicker(dayOfWeek, time, disabled, onChange, minTime, maxTime) {
    const timeFormat = 'HH:mm';
    return (
      <div className='working-hours-datepicker'>
        <DatePicker
          customInput={<DatePickerInput showLeftBorder={true} />}
          selected={time ? moment(time, timeFormat) : null}
          onChange={date =>
            onChange(dayOfWeek, date.format(timeFormat.concat(':ss')))
          }
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat={timeFormat}
          timeFormat={timeFormat}
          timeCaption={I18n.t('datePicker.time')}
          disabled={disabled}
          minTime={moment(minTime ? minTime : '05:45', 'HH:mm').add(
            15,
            'minutes'
          )}
          maxTime={moment(maxTime ? maxTime : '22:15', 'HH:mm').subtract(
            15,
            'minutes'
          )}
        />
      </div>
    );
  }

  renderEndTimeCell(data) {
    return this.renderTimePicker(
      data.dayOfWeek,
      data.endTime,
      !data.isOpen,
      this.props.endTimeChanged,
      data.startTime,
      null
    );
  }

  render() {
    const {workingDays, headerBuilder} = this.props;

    const workingDaysRows = daysOfWeek.map(dayOfWeek => {
      const workingDay = workingDays.filter(
        workingDay => workingDay.dayOfWeek === dayOfWeek
      )[0];

      return {
        dayOfWeek: dayOfWeek,
        dayOfWeekLabel: I18n.t(`dayOfWeek.${dayOfWeek.toLowerCase()}`),
        isOpen: getOrNull(workingDay) !== null,
        startTime: getOrEmptyObj(workingDay).startTime || null,
        endTime: getOrEmptyObj(workingDay).endTime || null,
      };
    });

    return (
      <DataTable
        value={workingDaysRows}
        header={headerBuilder(this.renderSaveBtn())}
        rows={this.props.rows}
        responsive={true}
        className='p-3'
        emptyMessage={I18n.t('workingHours.emptyPlaceholder')}>
        <Column
          field='dayOfWeekLabel'
          header={I18n.t('workingHours.dayOfWeek')}
        />

        <Column
          field='isOpen'
          header={I18n.t('workingHours.isOpen')}
          body={this.renderIsOpenCell}
        />

        <Column
          field='startTime'
          header={I18n.t('workingHours.startTime')}
          body={this.renderStartTimeCell}
        />

        <Column
          field='endTime'
          header={I18n.t('workingHours.endTime')}
          body={this.renderEndTimeCell}
        />
      </DataTable>
    );
  }
}

WorkingHoursTable.propTypes = {
  workingDays: PropTypes.array,
  onSaveClick: PropTypes.func,
  isOpenChanged: PropTypes.func,
  startTimeChanged: PropTypes.func,
  endTimeChanged: PropTypes.func,
};

WorkingHoursTable.defaultProps = {
  workingDays: [],
  onSaveClick: () => {},
  isOpenChanged: () => {},
  startTimeChanged: () => {},
  endTimeChanged: () => {},
};

export {WorkingHoursTable as WorkingHoursTableCore};

export default withDataTableCore(WorkingHoursTable);
