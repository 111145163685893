/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const serviceConstants = {
  SERVICE_REQUEST_START: 'SERVICE_REQUEST_START',
  SERVICE_REQUEST_END: 'SERVICE_REQUEST_END',

  LOAD_SERVICE_CATEGORIES: 'LOAD_SERVICE_CATEGORIES',
  LOAD_SERVICE_CATEGORY: 'LOAD_SERVICE_CATEGORY',
  LOAD_SERVICE: 'LOAD_SERVICE',
  LOAD_LPG_SERVICE_CATEGORY_ID: 'LOAD_LPG_SERVICE_CATEGORY_ID',

  LOAD_SELECTED_SERVICES: 'LOAD_SELECTED_SERVICES',
  CLEAR_SELECTED_SERVICES: 'CLEAR_SELECTED_SERVICES',
  UPDATE_SELECTED_SERVICES: 'UPDATE_SELECTED_SERVICES',

  REFRESH_SERVICE_CATEGORIES_DATA: 'REFRESH_SERVICE_CATEGORIES_DATA',

  LOAD_WORKSHOP_SERVICE_CATEGORIES: 'LOAD_WORKSHOP_SERVICE_CATEGORIES',
};

export const operationType = {
  ADD: 'ADD',
  SUBTRACT: 'SUBTRACT',
};

export const serviceStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
};

export const serviceType = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};
