/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {workshopLoyaltyProgramActions} from '../../../../shared/actions/workshopLoyaltyProgram.actions';
import ContentWrapper from '../../../../shared/components/content-wrapper/ContentWrapper';
import Loadable from 'react-loading-overlay';
import {DataTable} from 'primereact/datatable';
import {I18n, Translate} from 'react-redux-i18n';
import {Column} from 'primereact/column';
import {Button, Input} from 'reactstrap';
import {Paginator} from 'primereact/components/paginator/Paginator';
import {Dropdown} from 'primereact/dropdown';
import {rowsQuantityToDisplay} from '../../../../shared/utils/utils';
import {Checkbox} from 'primereact/checkbox';
import {updateDataSideBarConstants} from '../../../../shared/constants/update-data-side-bar.constants';
import WorkshopLoyaltyProgramModal from '../worhsop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import {workshopLoyaltyProgramNotificationsConstants} from '../../../../shared/constants/workshopLoyaltyProgramNotifications.constants';
import CartQuantityInput from '../../../../shared/components/cartQuantityInput/CartQuantityInput';

export const ProductsForPoints = () => {
  const dispatch = useDispatch();

  const workshopLoyaltyProgramPointsData = useSelector(
    state => state.workshopLoyaltyProgram.pointsData
  );
  const workshopData = useSelector(state => state.workshop.data);
  const prizeProducts = useSelector(
    state => state.workshopLoyaltyProgram.prizeProducts
  );
  const isFetching = useSelector(
    state => state.workshopLoyaltyProgram.prizeProductsFetching
  );
  const isBuyingProducts = useSelector(
    state => state.workshopLoyaltyProgram.isBuyingProducts
  );
  const registrationData = useSelector(
    state => state.workshopLoyaltyProgram.registrationData
  );
  const registartionFetching = useSelector(
    state => state.workshopLoyaltyProgram.registartionFetching
  );

  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [first, setFirst] = useState(0);
  const [globalFilter, setGlobalFilter] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [pointsSummary, setPointsSummary] = useState({
    totalPoints: 0,
    usedPointsAmount: 0,
    remainingPointsAmount: 0,
  });

  useEffect(() => {
    if (workshopData && workshopData.workshopId) {
      dispatch(
        workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramPointsData(
          workshopData.workshopId
        )
      );
    }
  }, []);

  useEffect(() => {
    if (workshopLoyaltyProgramPointsData) {
      setPointsSummary({
        ...pointsSummary,
        totalPoints: workshopLoyaltyProgramPointsData.totalPoints,
        remainingPointsAmount: workshopLoyaltyProgramPointsData.totalPoints,
      });
    }
  }, [workshopLoyaltyProgramPointsData]);

  useEffect(() => {
    dispatch(
      workshopLoyaltyProgramActions.prizeProductList({
        page: page - 1,
        size: pageLimit,
        sort: sortField && sortDirection && `${sortField},${sortDirection}`,
        search: globalFilter,
      })
    );
  }, [page, pageLimit, sortField, sortDirection, globalFilter]);

  useEffect(() => {
    if (pointsSummary.remainingPointsAmount < 0) {
      dispatch(
        workshopLoyaltyProgramActions.addToModalNotificationsToShow({
          identifier:
            workshopLoyaltyProgramNotificationsConstants.NOT_ENOUGH_POINTS,
          modalNotificationHeader: I18n.t(
            'workshopLoyaltyProgram.productsForPoints.notEnoughPoints'
          ),
        })
      );
    }
  }, [pointsSummary.remainingPointsAmount]);

  const renderDetailsRow = (key, data) => {
    const translationCore = key.translationCore
      ? key.translationCore
      : 'workshopLoyaltyProgram';
    const errorClass =
      key.field === 'remainingPointsAmount' && data[key.field] < 0
        ? 'error-color'
        : '';
    return (
      <div
        className={`row ${
          key.field !== 'remainingPointsAmount'
            ? 'user-data-container'
            : 'user-data-container-big'
        } ${errorClass}`}
        key={key.field}
      >
        <div className='col-md-6 col-12 text-md-right user-data-label'>
          <Translate value={`${translationCore}.${key.translation}`} />:
        </div>
        <div className='col-md-6 col-12 mb-2 pl-md-0'>{data[key.field]}</div>
      </div>
    );
  };

  const onCountChange = (rowData, count) => {
    let newSelectedProducts = selectedProducts;
    let usedPoints = pointsSummary.usedPointsAmount;
    let selectedProductIdx = newSelectedProducts.findIndex(
      item => item.UUID === rowData.id
    );
    const newAmountOfPoints = count * rowData.value;
    if (selectedProductIdx !== -1) {
      const oldAmountOfPoints =
        newSelectedProducts[selectedProductIdx].count * rowData.value;
      if (count === 0) {
        newSelectedProducts = newSelectedProducts.filter(
          item => item.UUID !== rowData.id
        );
      } else {
        newSelectedProducts = newSelectedProducts.map(item =>
          item.UUID === rowData.id ? {UUID: rowData.id, count} : item
        );
      }
      usedPoints = usedPoints - oldAmountOfPoints + newAmountOfPoints;
    } else if (count > 0) {
      usedPoints += newAmountOfPoints;
      newSelectedProducts = [...newSelectedProducts, {UUID: rowData.id, count}];
    }
    setSelectedProducts(newSelectedProducts);
    setPointsSummary({
      ...pointsSummary,
      usedPointsAmount: usedPoints,
      remainingPointsAmount: pointsSummary.totalPoints - usedPoints,
    });
  };

  const renderPointsData = () => {
    const pointsDataToDisplay = [
      {
        field: 'totalPoints',
        translation: 'yourPoints',
      },
      {
        field: 'usedPointsAmount',
        translation: 'usedPointsAmount',
        translationCore: 'workshopLoyaltyProgram.productsForPoints',
      },
      {
        field: 'remainingPointsAmount',
        translation: 'remainingPointsAmount',
        translationCore: 'workshopLoyaltyProgram.productsForPoints',
      },
    ];

    return (
      <div>
        {!!pointsSummary && (
          <div className='points-section-with-border'>
            <div className={'points-container'}>
              <div className='workshop-details-section-title'>
                <Translate value='workshopLoyaltyProgram.yourPoints' />
              </div>
              {pointsDataToDisplay.map(key =>
                renderDetailsRow(key, pointsSummary)
              )}
            </div>
            <div className='workshop-profile-buttons'>
              <Button
                disabled={
                  pointsSummary.remainingPointsAmount < 0 ||
                  registrationData.loyaltyParticipationBlocked ||
                  selectedProducts.length === 0
                }
                className={'main-btn'}
                style={{width: '14.7rem'}}
                onClick={() => {
                  dispatch({
                    type: updateDataSideBarConstants.SHOW,
                    variableComponent: (
                      <WorkshopLoyaltyProgramModal
                        confirmUsePoints
                        onConfirmUsePoints={() => {
                          dispatch({type: updateDataSideBarConstants.HIDE});
                          dispatch(
                            workshopLoyaltyProgramActions.buyPrizeProducts(
                              workshopData.workshopId,
                              selectedProducts,
                              () => {
                                dispatch(
                                  workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramPointsData(
                                    workshopData.workshopId
                                  )
                                );
                                dispatch(
                                  workshopLoyaltyProgramActions.getModalNotificationsToShow()
                                );
                                setSelectedProducts([]);
                                setPointsSummary({
                                  ...pointsSummary,
                                  usedPointsAmount: 0,
                                  remainingPointsAmount:
                                    workshopLoyaltyProgramPointsData.totalPoints,
                                });
                              }
                            )
                          );
                        }}
                      />
                    ),
                    title: I18n.t(
                      'workshopLoyaltyProgram.productsForPoints.confirmChange'
                    ),
                  });
                }}
              >
                <Translate
                  value={
                    'workshopLoyaltyProgram.productsForPoints.changePointsForProducts'
                  }
                />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <ContentWrapper
      title='workshopLoyaltyProgram.productsForPoints.header'
      scrollY
    >
      <Loadable
        active={isFetching || isBuyingProducts || registartionFetching}
        spinner
      >
        <div className='col' style={{minWidth: 976}}>
          <DataTable
            value={prizeProducts.content}
            rows={pageLimit}
            className='data-table-padding-class'
            responsive={true}
            emptyMessage={I18n.t(
              'workshopLoyaltyProgram.productsForPoints.noProductsToSelect'
            )}
            paginator={false}
            onSort={e => {
              setPage(1);
              setFirst(0);
              setSortField(e.sortField);
              setSortDirection(sortDirection =>
                sortDirection == 'asc' ? 'desc' : 'asc'
              );
            }}
            header={
              <div
                style={{
                  width: 320,
                  position: 'relative',
                  float: 'right',
                }}
              >
                <Input
                  type='search'
                  placeholder={I18n.t('workshops.searchPlaceholder')}
                  onInput={e => {
                    setFirst(0);
                    setPage(1);
                    setSortField(e.sortField);
                    setGlobalFilter(e.target.value);
                  }}
                  className='global-filter'
                />
              </div>
            }
          >
            <Column
              field='productTypeName'
              header={I18n.t(
                'workshopLoyaltyProgram.scoredProducts.productType'
              )}
              sortable={true}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='productName'
              header={I18n.t(
                'workshopLoyaltyProgram.scoredProducts.productName'
              )}
              sortable={true}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='description'
              header={I18n.t(
                'workshopLoyaltyProgram.scoredProducts.shortDescription'
              )}
              sortable={false}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='value'
              header={I18n.t(
                'workshopLoyaltyProgram.scoredProducts.productValue'
              )}
              sortable={true}
            />
            {registrationData && (
              <Column
                headerStyle={{textAlign: 'center'}}
                bodyStyle={{textAlign: 'center'}}
                header={I18n.t(
                  'workshopLoyaltyProgram.productsForPoints.selectThisProduct'
                )}
                field=''
                body={rowData => (
                  <CartQuantityInput
                    onChange={count => onCountChange(rowData, count)}
                    selectedProducts={selectedProducts}
                    inputId={rowData.id}
                  />
                )}
                style={{width: '15%'}}
              />
            )}
          </DataTable>
          <div
            style={{paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32}}
          >
            <Paginator
              first={first}
              rows={pageLimit}
              totalRecords={prizeProducts.totalElements}
              pageLinkSize={5}
              onPageChange={e => {
                setPage(e.page + 1);
                setFirst(e.first);
              }}
              leftContent={
                <Dropdown
                  options={rowsQuantityToDisplay}
                  className='data-table-rows-dropdown'
                  value={pageLimit}
                  onChange={e => {
                    setPage(1), setPageLimit(e.value);
                  }}
                />
              }
            ></Paginator>
          </div>
          {registrationData && renderPointsData()}
        </div>
      </Loadable>
    </ContentWrapper>
  );
};
