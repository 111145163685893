/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {Field, getFormValues, reduxForm} from 'redux-form';
import CountryField from 'shared/components/form/country-field/CountryField';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import RenderGmap from 'shared/components/form/gmap/RenderGmap';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import StateField from 'shared/components/form/state-field/StateField';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {userService} from 'shared/services/user.service';
import {languageOptions} from 'shared/utils/utils';
import {resolveCurrencyOptions} from '../../../distributors/utils/utils';
import {validate} from '../../create-workshop/validators/validators';
import {
  statusOptions,
  WorkshopStatusEnum,
  workshopTypeOptions,
} from '../../utils/utils';

class UpdateWorkshopDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDeleteWorkshopModalOpen: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {formValues, form} = this.props;
    if (this.props.shouldInitializePhonePrefixes(prevProps, formValues))
      this.props.initializePhonePrefixesByCountry(form, formValues.country);
  }

  renderConfirmModal() {
    const {handleSubmit} = this.props;
    return (
      <ConfirmModal
        title='createWorkshop.confirmDeleteWorkshop'
        isOpen={this.state.confirmDeleteWorkshopModalOpen}
        action={() =>
          handleSubmit() &&
          this.setState({confirmDeleteWorkshopModalOpen: false})
        }
        handleCancel={() =>
          this.setState({confirmDeleteWorkshopModalOpen: false})
        }
      />
    );
  }

  onSubmit(values) {
    if (values.status === WorkshopStatusEnum.DELETED)
      this.setState({confirmDeleteWorkshopModalOpen: true});
    else this.props.handleSubmit();
  }

  render() {
    const {handleSubmit, invalid, isReadOnly, countries, formValues} =
      this.props;
    const currencyOptions = formValues
      ? resolveCurrencyOptions(countries, formValues.country)
      : null;
    return (
      <Fragment>
        {this.renderConfirmModal()}
        <form
          className='workshop-form update-workshop-form'
          onSubmit={handleSubmit(this.onSubmit)}>
          <Field
            name='language'
            component={RenderDropdown}
            options={languageOptions}
            placeholder={I18n.t('createWorkshop.languagePlaceholder')}
            label={I18n.t('createWorkshop.languageLabel')}
            icon='fa fa-language'
            required={true}
            disabled={isReadOnly}
          />
          <CountryField disabled={userService.isDistributor()} />
          <Field
            name='status'
            component={RenderDropdown}
            options={statusOptions()}
            placeholder={I18n.t('createWorkshop.statusPlaceholder')}
            label={I18n.t('createWorkshop.statusLabel')}
            icon='fa fa-info'
            required={true}
            disabled={isReadOnly}
          />
          <Field
            name='type'
            component={RenderDropdown}
            options={workshopTypeOptions()}
            placeholder={I18n.t('createWorkshop.typePlaceholder')}
            label={I18n.t('createWorkshop.typeLabel')}
            icon='fa fa-info'
            required={true}
            disabled={isReadOnly}
          />

          <h3 className='text-center mt-3 mb-3'>
            <Translate value='createWorkshop.addressData' />
          </h3>

          <Field
            name='workshopName'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.workshopNameLabel')}
            icon='fa fa-briefcase'
          />
          <Field
            name='ownerName'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            withTooltip={!isReadOnly}
            tooltipContent={I18n.t('createWorkshop.ownerNameTooltip')}
            tooltipClassName={'custom-tooltip-smaller-width'}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.ownerNameLabel')}
            icon='fa fa-user'
          />
          <Alert color='warning' className='text-center mt-3'>
            <Translate value='createWorkshop.mapTip' />
          </Alert>
          <RenderGmap
            formName={'updateWorkshopDataForm'}
            inputDisabled={isReadOnly}
          />
          <StateField formName={'updateWorkshopDataForm'} required={true} />
          <Field
            name='city'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.cityLabel')}
            icon='fa fa-building'
          />
          <Field
            name='postalCode'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.postalCodeLabel')}
            icon='fa fa-inbox'
          />
          <Field
            name='postalCity'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.postalCityLabel')}
            icon='fa fa-envelope'
          />
          <Field
            name='streetAddress'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.streetAddressLabel')}
            icon='fa fa-address-card'
          />
          <Field
            name='latitude'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.latitudeLabel')}
            icon='fa fa-globe'
          />
          <Field
            name='longitude'
            type='text'
            component={renderField}
            required={true}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.longitudeLabel')}
            icon='fa fa-globe'
          />

          <h3 className='text-center mt-3 mb-3'>
            <Translate value='createWorkshop.companyData' />
          </h3>

          <Field
            name='phoneNumber'
            type='text'
            component={PhoneNumber}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.phoneNumberLabel')}
            format={formatPhoneNumberField}
          />
          <Field
            name='mobilePhoneNumber'
            type='text'
            component={PhoneNumber}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.mobilePhoneNumber')}
            format={formatPhoneNumberField}
          />
          <Field
            name='faxNumber'
            type='text'
            component={PhoneNumber}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.faxLabel')}
            format={formatPhoneNumberField}
          />
          <Field
            name='nip'
            type='text'
            component={renderField}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.nipLabel')}
            icon='fa fa-info'
          />
          <Field
            name='regon'
            type='text'
            component={renderField}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.regonLabel')}
            icon='fa fa-info'
          />
          <Field
            name='krs'
            type='text'
            component={renderField}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.krsLabel')}
            icon='fa fa-info'
          />
          <Field
            name='website'
            type='text'
            component={renderField}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.websiteLabel')}
            icon='fa fa-info'
          />
          <Field
            name='currency'
            component={RenderDropdown}
            options={currencyOptions}
            icon='fa fa-money'
            label={I18n.t('distributors.currencyLabel')}
            placeholder={I18n.t('distributors.currencyPlaceholder')}
            disabled={!currencyOptions || isReadOnly}
            required={true}
          />
          <Field
            name='description'
            type='text'
            component={RenderTextArea}
            required={false}
            inputDisabled={isReadOnly}
            inputReadOnly={isReadOnly}
            label={I18n.t('createWorkshop.descriptionLabel')}
            icon='fa fa-info'
          />
          <div className='row mt-3'>
            <div
              className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0'
              style={{whiteSpace: 'nowrap'}}
            />
            {!isReadOnly && (
              <div className='col-lg-9 col-12'>
                <div id='workshop-form-save-btn' className='text-right'>
                  <Button
                    id='save-workshop-update-btn'
                    className='main-btn'
                    type='submit'
                    disabled={invalid}>
                    <Translate value='selectedWorkshop.saveBtn' />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </form>
      </Fragment>
    );
  }
}

UpdateWorkshopDataForm.propsTypes = {
  isReadOnly: PropTypes.bool.isRequired,
};

UpdateWorkshopDataForm.defaultProps = {
  isReadOnly: false,
};

UpdateWorkshopDataForm = reduxForm({
  form: 'updateWorkshopDataForm',
  validate,
  enableReinitialize: true,
})(UpdateWorkshopDataForm);

function mapStateToProps(state) {
  const {form, workshop, country, i18n} = state;
  const {selected} = workshop;
  const {countries} = country;
  const {updateWorkshopDataForm} = form;
  return {
    updateWorkshopDataForm,
    i18n,
    initialValues: selected ? toFormJson({...selected}) : {},
    formValues: getFormValues('updateWorkshopDataForm')(state),
    countries,
  };
}

const toFormJson = data => {
  const address = data.address;
  const workshopDetails = data.workshopDetails;
  address.latitude = address.latitude.toString();
  address.longitude = address.longitude.toString();
  delete data.address;
  delete data.workshopDetails;
  return {...data, ...address, ...workshopDetails};
};

//export for tests
export const UpdateWorkshopDataFormConnectedWithRedux = connect(
  mapStateToProps
)(UpdateWorkshopDataForm);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(UpdateWorkshopDataForm)
);
