/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const workshopWorkerConstants = {
  WORKSHOP_WORKER_REQUEST_START: 'WORKSHOP_WORKER_REQUEST_START',
  WORKSHOP_WORKER_REQUEST_END: 'WORKSHOP_WORKER_REQUEST_END',

  LOAD_WORKSHOP_WORKERS: 'LOAD_WORKSHOP_WORKERS',
  REFRESH_WORKSHOP_WORKERS: 'REFRESH_WORKSHOP_WORKERS',

  LOAD_WORKSHOP_WORKER: 'LOAD_WORKSHOP_WORKER',
  CLEAR_WORKER: 'CLEAR_WORKER',
};
