/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {history} from 'shared/utils/history';
import './user-activation.css';

class UserActivationInfo extends React.Component {
  goToHome() {
    history.push('/home');
  }

  render() {
    return (
      <ContentWrapper
        title='userActivation.infoTitle'
        containerClass='user-activation-info-container'
        contentClass='user-activation-info-content'>
        <div className='user-activation-info-text'>
          <Translate value='userActivation.infoText' />
        </div>
        <Button onClick={this.goToHome} className='main-btn'>
          <Translate value='userActivation.backBtn' />
        </Button>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const connectedUserActivationInfo =
  connect(mapStateToProps)(UserActivationInfo);
export {connectedUserActivationInfo as UserActivationInfo};
