/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2017 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const voivodeships = [
  {label: 'dolnośląskie', value: 'dolnośląskie'},
  {label: 'kujawsko-pomorskie', value: 'kujawsko-pomorskie'},
  {label: 'lubelskie', value: 'lubelskie'},
  {label: 'lubuskie', value: 'lubuskie'},
  {label: 'łódzkie', value: 'łódzkie'},
  {label: 'małopolskie', value: 'małopolskie'},
  {label: 'mazowieckie', value: 'mazowieckie'},
  {label: 'opolskie', value: 'opolskie'},
  {label: 'podkarpackie', value: 'podkarpackie'},
  {label: 'podlaskie', value: 'podlaskie'},
  {label: 'pomorskie', value: 'pomorskie'},
  {label: 'śląskie', value: 'śląskie'},
  {label: 'świętokrzyskie', value: 'świętokrzyskie'},
  {label: 'warmińsko-mazurskie', value: 'warmińsko-mazurskie'},
  {label: 'wielkopolskie', value: 'wielkopolskie'},
  {label: 'zachodniopomorskie', value: 'zachodniopomorskie'},
];

const ValidationMessageEnum = {
  REQUIRED: 'validation.required',
  EMAIl: 'validation.email',
  EMAIL_EXIST: 'validation.emailExists',
  MAX_LENGTH: 'validation.maxLength',
  MIN_LENGTH: 'validation.minLength',
  PASSWORD: 'validation.password',
  CONFIRM_PASSWORD: 'validation.confirmPassword',
  PHONE_NUMBER: 'validation.phoneNumber',
  INCORRECT_DATA: 'validation.incorrectData',
  PHONE_NUMBER_PREFIX_REQUIRED: 'validation.phoneNumberPrefixRequired',
  NOT_A_NUMBER: 'validation.notANumber',
  MAX_RANGE: 'validation.maxRange',
  MIN_RANGE: 'validation.minRange',
  DUPLICATES_EXISTS: 'validation.duplicatesExists',
  MORE_THAN_TWO_DECIMALS: 'validation.moreThanTwoDecimals',
};

export const ReqexpValidationEnum = {
  EMAIl: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  PASSWORD_SPECIAL_CHARACTERS:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/,
  FLOAT_NUMBER: /^-?\d*(\.\d+)?$/,
  ONLY_NUMBERS: /^[0-9]*$/g,
  CONTAINS_WHITE_SPACES_ONLY: /^\s+$/,
  PHONE_NUMBER: /^(?:(?:(?:(?:\+|00)\d{2})?[ -]?(?:(?:\(0?\d{2}\))|(?:0?\d{2})))?[ -]?(?:\d{3}[- ]?\d{2}[- ]?\d{2}|\d{2}[- ]?\d{2}[- ]?\d{3}|\d{7})|(?:(?:(?:\+|00)\d{2})?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}))$/,
};

const errorMessages = [
  {fieldMessage: 'PASSWORD_NOT_MATCH', value: 'passwordNotMatchFailMsg'},
  {fieldMessage: 'ALREADY_EXISTS', value: 'alreadyExistsErrorMsg'},
  {fieldMessage: 'USED', value: 'usedErrorMsg'},
  {fieldMessage: 'BLANK_FILE', value: 'blankFile'},
  {fieldMessage: 'FILE_MAX_SIZE_EXCEED', value: 'fileMaxSizeExceed'},
  {fieldMessage: 'WRONG_FILE_TYPE', value: 'wrongFileType'},
  {fieldMessage: 'LOCALIZATION_NOT_FOUND', value: 'localizationNotFoundMsg'},
  {
    fieldMessage: 'ADDRESS_IN_COUNTRY_NOT_FOUND',
    value: 'addressInCountryNotFoundMsg',
  },
  {fieldMessage: 'USER_FORBIDDEN_ACCESS', value: 'userForbiddenAccessMsg'},
  {fieldMessage: 'NOT_FOUND', value: 'objectNotFoundMsg'},
  {
    fieldMessage: 'ANOTHER_VALUATION_ALREADY_CHOSEN',
    value: 'anotherChosenMsg',
  },
  {
    fieldMessage: 'DISTRIBUTOR_IN_COUNTRY_ALREADY_EXISTS',
    value: 'distributorInCountryExists',
  },
];

export function getErrorMessage(
  error,
  keyGroup,
  defaultMsgKey = 'baseFailMessage'
) {
  if (
    !error ||
    !error.response ||
    !error.response.data ||
    !error.response.data.fieldErrors
  )
    return keyGroup + '.' + defaultMsgKey;

  const fieldErrors = error.response.data.fieldErrors;
  return getMessageToDisplay(fieldErrors[0].message, keyGroup, defaultMsgKey);
}

const getMessageToDisplay = (fieldMessage, keyGroup, defaultMsgKey) => {
  let messageToDisplay = keyGroup + '.' + defaultMsgKey;
  errorMessages.forEach(item => {
    if (item.fieldMessage === fieldMessage)
      messageToDisplay = keyGroup + '.' + item.value;
  });
  return messageToDisplay;
};

export const rowsQuantityToDisplay = [
  {label: '10', value: 10},
  {label: '25', value: 25},
  {label: '50', value: 50},
  {label: '100', value: 100},
];

export function indexArray(array, startIndex = 1) {
  let currentIndex = startIndex;
  return !isEmptyArray(array)
    ? array.map(item => {
        item.index = currentIndex++;
        return item;
      })
    : array;
}

export function getLoginErrorMessage(error) {
  if (
    !error ||
    !error.response ||
    !error.response.data ||
    !error.response.data.error
  )
    return 'login.errorMsg';
  const errorKey = error.response.data.error;
  return toLoginErrorMessage(errorKey);
}

function toLoginErrorMessage(errorKey) {
  switch (errorKey) {
    case 'invalid_grant':
      return 'login.invalidGrant';
    case 'unauthorized':
      return 'login.unauthorized';
    default:
      return 'login.errorMsg';
  }
}

export const countryOptions = [{label: 'Polska', value: 'POLAND'}];

export const languageOptions = [{label: 'Polski', value: 'pl'}];

export function isEmptyArray(array) {
  return (
    typeof array === 'undefined' ||
    array === null ||
    array.length === null ||
    array.length === 0
  );
}

export function hasArrayDuplicates(array) {
  return isEmptyArray(array) ? false : array.length !== new Set(array).size;
}

export function* rangeArray(start, end, step) {
  while (start < end) {
    yield start;
    start += step;
  }
}

export const removeMatchItem = (array, itemToRemove) => {
  array.some((item, index) => {
    if (item.id === itemToRemove.id) {
      array.splice(index, 1);
      return true;
    }
    return false;
  });
  return [...array];
};

export const hasCommonItems = (arrayOne, arrayTwo) => {
  const setOne = new Set(arrayOne);
  const setTwo = new Set(arrayTwo);
  return [...setOne].filter(item => setTwo.has(item)).length > 0;
};

export const searchCriteriaToString = searchCriteria => {
  let searchCriteriaString = '';
  searchCriteria.forEach((sc, index) => {
    searchCriteriaString = searchCriteriaString.concat(
      `${sc.toString()}${checkNeedSeparator(index, searchCriteria.length)}`
    );
  });
  return searchCriteriaString;
};

const checkNeedSeparator = (index, lengthOfArray) => {
  return index + 1 === lengthOfArray ? '' : ',';
};

export const getOrNull = value => (value ? value : null);

export const getOrEmptyObj = value => (value ? value : {});

export const getOrDefault = (value, def) => (value ? value : def);

export const getOrEmptyArray = array => (!isEmptyArray(array) ? array : []);

export const getOrZero = value => (value ? value : 0);

export const ValuationInquiryOriginEnum = {
  KM: 'KM',
  SW: 'SW',
};

export const isUUIDFormat = stringValue => {
  return stringValue.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-9][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const capitalize = str => {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
  }
  return '';
};

export default ValidationMessageEnum;
