/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Switch} from 'react-router-dom';
import {Header} from 'shared/components/header/Header';
import {Message} from 'shared/components/message/Message';
import {ProtectedRouteBasedOnRoles} from 'shared/components/protected-route/ProtectedRoute';
import {SideBarNav} from 'shared/components/sidebar-nav/SideBarNav';
import UpdateDataSideBar from 'shared/components/update-data-side-bar/UpdateDataSideBar';
import {userConstants} from 'shared/constants/user.constants';
import {userService} from 'shared/services/user.service';
import AdminContainer from './admin/AdminContainer';
import DistributorContainer from './distributor/DistributorContainer';
import UserProfile from './user/user-profile/UserProfile';
import UserContainer from './user/UserContainer';
import WorkshopContainer from './workshop/WorkshopContainer';
import {
  WorkshopLoyaltyProgramNotificationModal
} from '../shared/components/workshop-loyalty-program/workshop-loyalty-program-notification-modal/WorkshopLoyaltyProgramNotificationModal';

class Layout extends Component {
  render() {
    const {dispatch, isAuthenticated} = this.props;
    const isAdminOrWorkshop = userService.isRole(userConstants.ROLE_ADMIN) || userService.isRole(userConstants.ROLE_WORKSHOP);

    return (
      <div>
        <SideBarNav />
        <Header dispatch={dispatch} />
        <div className='content'>
          <UpdateDataSideBar />
          { isAdminOrWorkshop && <WorkshopLoyaltyProgramNotificationModal />}
          <Message />
          <Switch>
            <ProtectedRouteBasedOnRoles
              path='/admin'
              component={AdminContainer}
              isAuthenticated={isAuthenticated}
              roles={[userConstants.ROLE_ADMIN]}
            />

            <ProtectedRouteBasedOnRoles
              path='/user/profile'
              component={UserProfile}
              isAuthenticated={isAuthenticated}
              roles={[
                userConstants.ROLE_USER,
                userConstants.ROLE_THIN_USER,
                userConstants.ROLE_ADMIN,
              ]}
            />

            <ProtectedRouteBasedOnRoles
              path='/user'
              component={UserContainer}
              isAuthenticated={isAuthenticated}
              roles={[userConstants.ROLE_USER, userConstants.ROLE_THIN_USER]}
            />

            <ProtectedRouteBasedOnRoles
              path='/workshop'
              component={WorkshopContainer}
              isAuthenticated={isAuthenticated}
              roles={[userConstants.ROLE_WORKSHOP]}
            />

            <ProtectedRouteBasedOnRoles
              path='/distributor'
              component={DistributorContainer}
              isAuthenticated={isAuthenticated}
              roles={[userConstants.ROLE_DISTRIBUTOR]}
            />

            <Redirect exact from='/' to={userService.getPathWithRoleName()} />

            <Redirect
              to={{
                pathname: '/not-found',
                state: {
                  from: this.props.location,
                },
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

function mapStateToProps({user}) {
  const {isAuthenticated, redirectPath} = user;
  return {isAuthenticated, redirectPath};
}

export default connect(mapStateToProps)(Layout);
