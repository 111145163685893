/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Captcha} from 'primereact/captcha';
import {googleReCaptchaSiteKey} from '../environment';
import {connect} from 'react-redux';

class ReCaptcha extends React.Component {
  render() {
    const {i18n} = this.props;

    return (
      <div className='captcha-container'>
        <div className='row mt-3' align='center'>
          {i18n.locale && (
            <Captcha
              siteKey={googleReCaptchaSiteKey}
              language={i18n.locale}
              onResponse={e => this.props.input.onChange(e.response)}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const connectedReCaptcha = connect(mapStateToProps)(ReCaptcha);
export {connectedReCaptcha as ReCaptcha};
