/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const userNotificationConstants = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',

  LOAD_UNREAD_NOTIFICATIONS_COUNT: 'LOAD_UNREAD_NOTIFICATIONS_COUNT',
  LOAD_RECENT_NOTIFICATIONS: 'LOAD_RECENT_NOTIFICATIONS',
};
