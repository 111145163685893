/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {formatPhoneNumber} from 'shared/components/form/formatter/PhoneNumberFormatter';

const dataToDisplay = [
  'firstName',
  'lastName',
  'country',
  'city',
  'state',
  'phoneNumber',
  'email',
];

class UserProfileData extends React.Component {
  getDataFromKey(key) {
    const {data} = this.props;
    if (dataToDisplay.indexOf(key) >= 0 && key === 'phoneNumber')
      return data[key].phoneNumber
        ? `${data[key].phoneNumberPrefix} ${formatPhoneNumber(
            data[key].phoneNumber
          )}`
        : `${I18n.t('userProfileData.noData')}`;
    if (dataToDisplay.indexOf(key) >= 0 && key === 'country')
      return data[key]
        ? `${I18n.t(`country.${data[key].toLowerCase()}`)}`
        : `${I18n.t('userProfileData.noData')}`;
    else if (dataToDisplay.indexOf(key) >= 0)
      return data[key] ? data[key] : `${I18n.t('userProfileData.noData')}`;
  }

  renderData(key) {
    const {data} = this.props;
    let toDisplay = this.getDataFromKey(key);
    if (dataToDisplay.indexOf(key) >= 0)
      return (
        <div className='row user-data-container' key={key}>
          <div className='col-md-6 col-12 text-md-right user-data-label'>
            <Translate value={`userProfileData.${key}Label`} />
          </div>
          <div className='col-md-6 col-12 mb-2 pl-md-0'>
            {data ? toDisplay : ''}
          </div>
        </div>
      );
  }

  render() {
    const {data} = this.props;
    return (
      <div>{data && Object.keys(data).map(key => this.renderData(key))}</div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

export default connect(mapStateToProps)(UserProfileData);
