/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {isEmptyArray} from 'shared/utils/utils';

export function applyCategoriesFilter(categories, phrase) {
  return canBeFiltered(categories, phrase)
    ? categories.filter(category =>
        applyCategoryFilter(category, phrase.toLowerCase())
      )
    : categories;
}

export function applyServicesFilter(services, phrase) {
  return canBeFiltered(services, phrase)
    ? services.filter(service =>
        applyServiceFilter(service, phrase.toLowerCase())
      )
    : services;
}

function applyCategoryFilter(category, phrase) {
  return (
    matchPhrase(category.name, phrase) ||
    matchPhrase(category.description, phrase) ||
    !isEmptyArray(applyServicesFilter(category.services, phrase))
  );
}

function applyServiceFilter(service, phrase) {
  return (
    matchPhrase(service.name, phrase) ||
    matchPhrase(service.description, phrase)
  );
}

function canBeFiltered(array, phrase) {
  return !isEmptyArray(array) && phrase;
}

function matchPhrase(value, phrase) {
  return value && value.toLowerCase().indexOf(phrase) !== -1;
}
