/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import moment from 'moment';
import {dateUtils} from '../../../utils/date.utils';
import {subscriptionActions} from '../../../actions/subscription.actions';
import {userService} from '../../../services/user.service';
import {becameObsolete} from '../../../utils/sync';

class SubscriptionInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.subscriptionSyncState,
        this.props.subscriptionSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.getSubscriptionEndDate(userService.getUserId());
  }

  renderSubscriptionInfo() {
    const {subscriptionEndDate} = this.props;

    return (
      <Fragment>
        <i className='fa fa-star' />{' '}
        <Translate value='subscriptionInfo.subscriptionText' />:<br />
        <span className='ml-md-3 pl-md-2'>
          {dateUtils.formatDate(subscriptionEndDate, 'YYYY-MM-DD HH:mm')}
        </span>
      </Fragment>
    );
  }

  renderNoSubscriptionInfo() {
    return (
      <Fragment>
        <i className='fa fa-star' />{' '}
        <Translate value='subscriptionInfo.noSubscriptionText' />
      </Fragment>
    );
  }

  render() {
    const {subscriptionEndDate} = this.props;
    const hasSubscription =
      subscriptionEndDate && moment(subscriptionEndDate).isAfter(moment());

    return (
      <Fragment>
        {hasSubscription && this.renderSubscriptionInfo()}
        {!hasSubscription && this.renderNoSubscriptionInfo()}
      </Fragment>
    );
  }
}

SubscriptionInfo.propTypes = {};

function mapStateToProps(state) {
  const {i18n, subscription} = state;
  const {subscriptionEndDate, subscriptionSyncState} = subscription;

  return {i18n, subscriptionEndDate, subscriptionSyncState};
}

const mapDispatchToProps = {
  getSubscriptionEndDate: subscriptionActions.getSubscriptionEndDate,
};

const connectedSubscriptionInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionInfo);
export {connectedSubscriptionInfo as SubscriptionInfo};
