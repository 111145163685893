/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const userConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  ACCESS_TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',

  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',

  LOAD_PROFILE_DATA: 'LOAD_PROFILE_DATA',
  CHANGE_PROFILE_STATUS: 'CHANGE_PROFILE_STATUS',

  LOAD_USERS_DATA: 'LOAD_USERS_DATA',

  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_USER: 'ROLE_USER',
  ROLE_WORKSHOP: 'ROLE_WORKSHOP',
  ROLE_THIN_USER: 'ROLE_THIN_USER',
  ROLE_DISTRIBUTOR: 'ROLE_DISTRIBUTOR',

  REFRESH_USERS_DATA: 'REFRESH_USERS_DATA',

  LOGGED_USER_AVATAR_LOADED: 'LOGGED_USER_AVATAR_LOADED',

  CLEAR_SELECTED_USER: 'CLEAR_SELECTED_USER',

  USER_BARCODE_INFO: 'USER_BARCODE_INFO',
  USER_BARCODE_INFO_CLEAR: 'USER_BARCODE_INFO_CLEAR',

  REPORT_ISSUE_SUCCESS: 'REPORT_ISSUE_SUCCESS',
  REPORT_ISSUE_CLEAR: 'REPORT_ISSUE_CLEAR',
};
