/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredFields,
  validateRequiredFieldsWithZeroAsValidValue,
  validateRequiredNumberWithRange,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.lpgDriver = validateRequiredFields(values.lpgDriver);
  errors.lpgBgSwitch = validateRequiredFields(values.lpgBgSwitch);
  errors.lpgReducer = validateRequiredFields(values.lpgReducer);
  errors.lpgInjectors = validateRequiredFields(values.lpgInjectors);
  errors.lpgTank = validateRequiredFields(values.lpgTank);
  errors.lpgRefuelingValve = validateRequiredFields(values.lpgRefuelingValve);
  errors.lpgTotalCost = validateRequiredNumberWithRange(
    values.lpgTotalCost,
    0.01,
    99999.99
  );
  errors.lpgGuaranteePeriodMonths = validateRequiredFieldsWithZeroAsValidValue(
    values.lpgGuaranteePeriodMonths
  );

  errors.lpgDriverOther = validateByLengthOnly(values.lpgDriverOther, 255);
  errors.lpgBgSwitchOther = validateByLengthOnly(values.lpgBgSwitchOther, 255);
  errors.lpgReducerOther = validateByLengthOnly(values.lpgReducerOther, 255);
  errors.lpgInjectorsOther = validateByLengthOnly(
    values.lpgInjectorsOther,
    255
  );
  errors.lpgTankOther = validateByLengthOnly(values.lpgTankOther, 255);
  errors.lpgRefuelingValveOther = validateByLengthOnly(
    values.lpgRefuelingValveOther,
    255
  );
  return errors;
};
