/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const workshopLoyaltyProgramConstants = {
  WORKSHOP_LOYALTY_PROGRAM_REQUEST_START: 'WORKSHOP_LOYALTY_PROGRAM_REQUEST_START',
  WORKSHOP_LOYALTY_PROGRAM_REQUEST_END: 'WORKSHOP_LOYALTY_PROGRAM_REQUEST_END',
  GET_WORKSHOP_LOYALTY_PROGRAM_NOTIFICATIONS: 'GET_WORKSHOP_LOYALTY_PROGRAM_NOTIFICATIONS',
  UPDATE_LOYALTY_PROGRAM_NOTIFICATION: 'UPDATE_LOYALTY_PROGRAM_NOTIFICATION',
  GET_BAR_NOTIFICATIONS_TO_SHOW_START: 'GET_BAR_NOTIFICATIONS_TO_SHOW_START',
  GET_BAR_NOTIFICATIONS_TO_SHOW: 'GET_BAR_NOTIFICATIONS_TO_SHOW',
  GET_BAR_NOTIFICATIONS_TO_SHOW_END: 'GET_BAR_NOTIFICATIONS_TO_SHOW_END',
  GET_MODAL_NOTIFICATIONS_TO_SHOW_START: 'GET_MODAL_NOTIFICATIONS_TO_SHOW_START',
  GET_MODAL_NOTIFICATIONS_TO_SHOW: 'GET_MODAL_NOTIFICATIONS_TO_SHOW',
  GET_MODAL_NOTIFICATIONS_TO_SHOW_END: 'GET_MODAL_NOTIFICATIONS_TO_SHOW_END',
  ADD_TO_MODAL_NOTIFICATIONS_TO_SHOW: 'ADD_TO_MODAL_NOTIFICATIONS_TO_SHOW',
  DELETE_FROM_MODAL_NOTIFICATIONS_TO_SHOW: 'DELETE_FROM_MODAL_NOTIFICATIONS_TO_SHOW',
  REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_START: 'REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_START',
  REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_END: 'REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_END',
  GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START: 'GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START',
  GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END: 'GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END',
  UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA: 'UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA',
  GET_WORKSHOP_LIST_REQUEST_START: 'GET_WORKSHOP_LIST_REQUEST_START',
  GET_WORKSHOP_LIST_REQUEST_END: 'GET_WORKSHOP_LIST_REQUEST_END',
  UPDATE_WORKSHOP_LIST: 'UPDATE_WORKSHOP_LIST',
  GET_WORKSHOPS_WITH_HISTORY_REQUEST_START: 'GET_WORKSHOPS_WITH_HISTORY_REQUEST_START',
  GET_WORKSHOPS_WITH_HISTORY_REQUEST_END: 'GET_WORKSHOPS_WITH_HISTORY_REQUEST_END',
  UPDATE_WORKSHOPS_WITH_HISTORY: 'UPDATE_WORKSHOPS_WITH_HISTORY',
  UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START: 'UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START',
  UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END: 'UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END',
  GET_SCORED_PRODUCT_NAMES_REQUEST_START: 'GET_SCORED_PRODUCT_NAMES_REQUEST_START',
  GET_SCORED_PRODUCT_NAMES_REQUEST_END: 'GET_SCORED_PRODUCT_NAMES_REQUEST_END',
  UPDATE_SCORED_PRODUCT_NAMES: 'UPDATE_SCORED_PRODUCT_NAMES',
  GET_SCORED_PRODUCT_TYPES_REQUEST_START: 'GET_SCORED_PRODUCT_TYPES_REQUEST_START',
  GET_SCORED_PRODUCT_TYPES_REQUEST_END: 'GET_SCORED_PRODUCT_TYPES_REQUEST_END',
  UPDATE_SCORED_PRODUCT_TYPES: 'UPDATE_SCORED_PRODUCT_TYPES',
  SCORED_PRODUCTS_REQUEST_START: 'SCORED_PRODUCTS_REQUEST_START',
  SCORED_PRODUCTS_REQUEST_END: 'SCORED_PRODUCTS_REQUEST_END',
  UPDATE_SCORED_PRODUCT_LIST: 'UPDATE_SCORED_PRODUCT_LIST',
  GET_WORKSHOP_LOYALTY_PROGRAM_POINTS_DATA: 'GET_WORKSHOP_LOYALTY_PROGRAM_POINTS_DATA',
  LOYALTY_CONFIG_REQUEST_START: "LOYALTY_CONFIG_REQUEST_START",
  LOYALTY_CONFIG_REQUEST_END: "LOYALTY_CONFIG_REQUEST_END",
  UPDATE_LOYALTY_CONFIG: "UPDATE_LOYALTY_CONFIG",
  WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_START: "WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_START",
  WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_END: "WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_END",
  UPDATE_WORHSHOP_LOYALTY_PROGRAM_HISTORY: "UPDATE_WORHSHOP_LOYALTY_PROGRAM_HISTORY",
  PRIZE_PRODUCT_REQUEST_START: 'PRIZE_PRODUCT_REQUEST_START',
  PRIZE_PRODUCT_REQUEST_END: 'PRIZE_PRODUCT_REQUEST_END',
  UPDATE_PRIZE_PRODUCT_LIST: 'UPDATE_PRIZE_PRODUCT_LIST',
  SET_ACTIVE_WORKSHOP_LOYALTY_PROGRAM_TILE: 'SET_ACTIVE_WORKSHOP_LOYALTY_PROGRAM_TILE',
  MARK_MODAL_NOTIFICATION_AS_READ_START: 'MARK_MODAL_NOTIFICATION_AS_READ_START',
  MARK_MODAL_NOTIFICATION_AS_READ: 'MARK_MODAL_NOTIFICATION_AS_READ',
  MARK_MODAL_NOTIFICATION_AS_READ_END: 'MARK_MODAL_NOTIFICATION_AS_READ_END',
  BUY_PRIZE_PRODUCTS_START: 'BUY_PRIZE_PRODUCTS_START',
  BUY_PRIZE_PRODUCTS_END: 'BUY_PRIZE_PRODUCTS_END',
  WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_START: 'WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_START',
  WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_END: 'WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_END',
  UPDATE_WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY: 'UPDATE_WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY',
  WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_START: 'WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_START',
  WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_END: 'WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_END',
  UPDATE_WORKSHOP_PARTICIPATION_BLOCK_HISTORY: 'UPDATE_WORKSHOP_PARTICIPATION_BLOCK_HISTORY',
  SET_SELECTED_WORKSHOP: 'SET_SELECTED_WORKSHOP',
  SET_SELECTED_POINTS_TYPE: 'SET_SELECTED_POINTS_TYPE',
  SET_SELECTED_DATE_TYPE: 'SET_SELECTED_DATE_TYPE',
  SET_SELECTED_BLOCK_TYPE: 'SET_SELECTED_BLOCK_TYPE'
}
