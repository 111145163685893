/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from "react";
import { Translate } from "react-redux-i18n";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import { withRedirectForLoggedUser } from "shared/hoc/redirect-logged-user/redirectLoggedUserHoc";
import { Login } from "../login/Login";
import { LoginWarningPopup } from "./LoginWarningPopup";
import "./home-page.css";

export class HomePage extends React.Component {
  render() {
    return (
      <div className="start-page-wrapper">
        <LoginWarningPopup />
        <Login />
        {/* <NavLink to='/public-valuation-inquiry'>
          <Button id='valuation-btn' className='shadow'>
            <Translate value='homePage.valuationBtn' />
          </Button>
        </NavLink> */}
      </div>
    );
  }
}

export default withRedirectForLoggedUser(HomePage);
