/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {settingsActions} from 'shared/actions/settings.actions';
import {Settings} from 'shared/components/settings/Settings';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {userService} from 'shared/services/user.service';
import {becameObsolete} from 'shared/utils/sync';
import {webBaseUrl} from '../../../../environment';

class SettingsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.settingsDataSyncState,
        this.props.settingsDataSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.findAllUserSettingsByUserId(userService.getUserId());
  }

  update(toUpdate) {
    this.props.updateUserSettingsByUserId(userService.getUserId(), toUpdate);
  }

  composeDirectValuationInquirySetting(settings) {
    const {workshopId} = this.props;
    settings.forEach(s => {
      if (
        s.settingsDefinitionName === 'WORKSHOP_VALUATION_INQUIRY' &&
        s.value === 'true'
      ) {
        s.additionalValue = `${webBaseUrl}/workshop-valuation-inquiry/${workshopId}`;
      }
    });
    return settings;
  }

  composeLoadedSettings() {
    const {loadedSettings} = this.props;
    let settings = loadedSettings ? [...loadedSettings] : [];
    this.composeDirectValuationInquirySetting(settings);
    return settings;
  }

  render() {
    const settings = this.composeLoadedSettings();
    return (
      <Settings
        {...this.props}
        loadedSettings={settings}
        update={this.update}
      />
    );
  }
}

function mapStateToProps(state) {
  const {settings, workshop} = state;
  const {isFetching, loadedSettings, settingsDataSyncState} = settings;
  const {data} = workshop;
  const workshopId = !!(data && data.workshopId) ? data.workshopId : '';

  return {isFetching, loadedSettings, settingsDataSyncState, workshopId};
}

const mapDispatchToProps = {
  findAllUserSettingsByUserId: settingsActions.findAllUserSettingsByUserId,
  updateUserSettingsByUserId: settingsActions.updateUserSettingsByUserId,
};

export const ConnectedSettingsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsWrapper);

export default withDataTableCore(ConnectedSettingsWithRedux, false);
