/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Rating} from 'primereact/rating';
import React from 'react';
import {getComponentDisplayName} from 'shared/hoc/utils/utils.hoc';
import {formatAddress} from 'shared/utils/address-formatter/address-formater.utils';
import {getOrEmptyObj} from 'shared/utils/utils';

export const withValuationTableCore = WrappedComponent => {
  class ValuationsTableCore extends React.Component {
    renderWorkshopInfoCol(data) {
      data.workshopInfoString =
        data.workshopInfo.workshopName +
        ' - ' +
        formatAddress(data.workshopInfo.address);
      return data.workshopInfoString;
    }

    renderWorkshopAvgRatingCol(data) {
      data.workshopAvgRating =
        getOrEmptyObj(data.workshopInfo).averageRating || 0;
      return (
        <Rating value={data.workshopAvgRating} readonly={true} cancel={false} />
      );
    }

    renderTotalCostCol(data) {
      data.totalCostString =
        data.totalCost.value + ' ' + data.totalCost.currency;
      return data.totalCostString;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          renderWorkshopInfoCol={this.renderWorkshopInfoCol}
          renderWorkshopAvgRatingCol={this.renderWorkshopAvgRatingCol}
          renderTotalCostCol={this.renderTotalCostCol}
        />
      );
    }
  }

  ValuationsTableCore.displayName = `WithValuationTableCore(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return ValuationsTableCore;
};
