/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {orderConstants} from '../constants/order.constants';
import {SyncState} from '../utils/sync';
import {getOrEmptyArray} from '../utils/utils';

export function orderReducer(state = {}, action) {
  switch (action.type) {
    case orderConstants.ORDER_REQUEST_START:
      return {...state, isFetching: true};
    case orderConstants.ORDER_REQUEST_END:
      return {...state, isFetching: false};
    case orderConstants.LOAD_VALUATION_INQUIRIES:
      return {...state, valuationInquiries: action.payload.data};
    case orderConstants.LOAD_VALUATION_INQUIRY:
      return {...state, valuationInquiry: action.payload.data};
    case orderConstants.LOAD_VALUATION_INQUIRY_SERVICES:
      return {...state, valuationInquiryServices: action.payload.data};
    case orderConstants.CLEAR_VALUATION_INQUIRY_SERVICES:
      return {...state, valuationInquiryServices: []};
    case orderConstants.LOAD_VALUATIONS:
      return {...state, valuations: action.payload.data};
    case orderConstants.LOAD_VALUATION:
      return {...state, valuation: action.payload.data};
    case orderConstants.LOAD_VALUATION_INQUIRY_DRAFT:
      return {...state, valuationInquiryDraft: action.payload.data};
    case orderConstants.LOAD_VALUATION_DRAFT:
      return {...state, valuationDraft: action.payload.data};
    case orderConstants.LOAD_VALUATION_DIRECT_CLIENT_DRAFT:
      return {...state, valuationDirectClientDraft: action.payload.data};
    case orderConstants.CLEAR_VALUATION_INQUIRY:
      return {...state, valuationInquiry: null};
    case orderConstants.GET_VALUATION_INQUIRY_RESPONSE_FAIL_MSG:
      return {...state, valuationInquiryResponseFailMsg: action.payload};
    case orderConstants.CLEAR_VALUATION_INQUIRY_RESPONSE_FAIL_MSG:
      return {...state, valuationInquiryResponseFailMsg: null};
    case orderConstants.CLEAR_VALUATION:
      return {...state, valuation: null};
    case orderConstants.CLEAR_VALUATIONS:
      return {...state, valuations: []};
    case orderConstants.LOAD_ORDERS:
      return {
        ...state,
        orders: action.payload,
        ordersDataSyncState: SyncState.UP_TO_DATE,
      };
    case orderConstants.REFRESH_ORDERS_DATA:
      return {...state, ordersDataSyncState: SyncState.OBSOLETE};
    case orderConstants.LOAD_ORDER:
      return {...state, order: action.payload.data};
    case orderConstants.CLEAR_ORDER:
      return {...state, order: null};
    case orderConstants.LOAD_ORDER_MIN_CANCEL_DATE:
      return {...state, orderMinCancelDate: action.payload.data};
    default:
      return state;
  }
}
