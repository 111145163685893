/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {distributorConstants} from '../constants/distributor.constants';
import {distributorService} from '../services/distributor.service';
import {messageActions} from './message.actions';
import {getErrorMessage} from '../utils/utils';
import {userService} from '../services/user.service';

export const distributorActions = {
  create,
  findAll,
  update,
  findOne,
  enable,
  disable,
  updateAddress,
  updateDetails,
  updateOtherSection,
  loadDistributorData,
};

function create(values, onSuccessCallback = null) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.create(values).then(
      () => {
        if (onSuccessCallback) onSuccessCallback();
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.successMessage('distributors.createSuccessMsg')
        );
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
      },
      error => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'distributors', 'createErrorMsg')
          )
        );
      }
    );
  };
}

function findAll() {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.findAll().then(
      response => {
        dispatch({
          type: distributorConstants.LOAD_DISTRIBUTORS_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
      },
      () => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.errorMessage('distributors.fetchErrorMsg'));
      }
    );
  };
}

function update(values, distributorId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.update(values, distributorId).then(
      response => {
        dispatch(
          messageActions.successMessage('distributors.successUpdateMsg')
        );
        dispatch({
          type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
        });
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
      },
      error => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'distributors', 'updateErrorMsg')
          )
        );
      }
    );
  };
}

function updateAddress(values, distributorId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.updateAddress(values, distributorId).then(
      response => {
        dispatch(
          messageActions.successMessage('distributors.successUpdateMsg')
        );
        dispatch({
          type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
        });
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
      },
      error => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'distributors', 'updateErrorMsg')
          )
        );
      }
    );
  };
}

function updateDetails(values, distributorId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.updateDetails(values, distributorId).then(
      response => {
        dispatch(
          messageActions.successMessage('distributors.successUpdateMsg')
        );
        dispatch({
          type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
        });
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
      },
      error => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'distributors', 'updateErrorMsg')
          )
        );
      }
    );
  };
}

function updateOtherSection(values, distributorId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.updateOtherSection(values, distributorId).then(
      response => {
        dispatch(
          messageActions.successMessage('distributors.successUpdateMsg')
        );
        dispatch({
          type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
        });
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
      },
      error => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'distributors', 'updateErrorMsg')
          )
        );
      }
    );
  };
}

function findOne(distributorId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.findOne(distributorId).then(
      response => {
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
      },
      () => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.errorMessage('distributors.fetchErrorMsg'));
      }
    );
  };
}

function loadDistributorData(userId) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    distributorService.loadDistributorData(userId).then(
      response => {
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
      },
      () => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.errorMessage('distributors.fetchErrorMsg'));
      }
    );
  };
}

function enable(userId, distributorId, languageInfo) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    userService
      .enable(userId, languageInfo)
      .then(() => {
        return distributorService.findOne(distributorId);
      })
      .then(response => {
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.successMessage('distributors.enableSuccess'));
      })
      .catch(() => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.errorMessage('distributors.enableError'));
      });
  };
}

function disable(userId, distributorId, languageInfo) {
  return dispatch => {
    dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_START});
    userService
      .disable(userId, languageInfo)
      .then(() => {
        return distributorService.findOne(distributorId);
      })
      .then(response => {
        dispatch({type: distributorConstants.REFRESH_DISTRIBUTORS_DATA});
        dispatch({
          type: distributorConstants.LOAD_SELECTED_DISTRIBUTOR_DATA,
          payload: response,
        });
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.successMessage('distributors.disableSuccess'));
      })
      .catch(() => {
        dispatch({type: distributorConstants.DISTRIBUTOR_REQUEST_END});
        dispatch(messageActions.errorMessage('distributors.disableError'));
      });
  };
}
