/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const loyaltyProgramService = {
  findLoyaltyProgramPointsByWorkshopId,
  updateLoyaltyProgramPoints,
  findLoyaltyProgramsByWorkshopId,
  findLoyaltyProgramById,
  updateLoyaltyProgram,
  deleteLoyaltyProgram,
  createLoyaltyProgram,
  findLoyaltyProgramAccountByParams,
  findLoyaltyProgramAccountsByParams,
};

export const LOYALTY_PROGRAM_API_BASE_PATH = '/workshop/loyalty-programs';

export const LOYALTY_PROGRAM_ACCOUNTS_API_BASE_PATH =
  '/workshop/loyalty-program-accounts';

function findLoyaltyProgramPointsByWorkshopId(workshopId) {
  return axiosService
    .get(`${LOYALTY_PROGRAM_API_BASE_PATH}/points?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}

function updateLoyaltyProgramPoints(workshopId, loyaltyProgramPoints) {
  return axiosService
    .put(`${LOYALTY_PROGRAM_API_BASE_PATH}/points`, {
      workshopId,
      loyaltyProgramPoints,
    })
    .then(response => {
      return response;
    });
}

function findLoyaltyProgramsByWorkshopId(workshopId) {
  return axiosService
    .get(`${LOYALTY_PROGRAM_API_BASE_PATH}?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}

function findLoyaltyProgramById(loyaltyProgramId) {
  return axiosService
    .get(`${LOYALTY_PROGRAM_API_BASE_PATH}/${loyaltyProgramId}`)
    .then(response => {
      return response;
    });
}

function updateLoyaltyProgram(loyaltyProgramId, data) {
  return axiosService
    .put(`${LOYALTY_PROGRAM_API_BASE_PATH}/${loyaltyProgramId}`, data)
    .then(response => {
      return response;
    });
}

function deleteLoyaltyProgram(loyaltyProgramId) {
  return axiosService
    .delete(`${LOYALTY_PROGRAM_API_BASE_PATH}/${loyaltyProgramId}`)
    .then(response => {
      return response;
    });
}

function createLoyaltyProgram(data) {
  return axiosService
    .post(`${LOYALTY_PROGRAM_API_BASE_PATH}`, data)
    .then(response => {
      return response;
    });
}

function findLoyaltyProgramAccountByParams(workshopUserId, ownerUserId) {
  return axiosService
    .get(
      `${LOYALTY_PROGRAM_ACCOUNTS_API_BASE_PATH}?workshopUserId=${workshopUserId}&ownerUserId=${ownerUserId}`
    )
    .then(response => {
      return response;
    });
}

function findLoyaltyProgramAccountsByParams(ownerUserId) {
  return axiosService
    .get(
      `${LOYALTY_PROGRAM_ACCOUNTS_API_BASE_PATH}/list?ownerUserId=${ownerUserId}`
    )
    .then(response => {
      return response;
    });
}
