/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Translate} from 'react-redux-i18n';

export class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.notShowAgainChange = this.notShowAgainChange.bind(this);
    this.state = {notShowAgain: false};
  }

  notShowAgainChange() {
    this.setState(prevState => {
      return {notShowAgain: !prevState.notShowAgain};
    });
  }

  render() {
    const {
      title,
      color,
      action,
      isOpen,
      handleCancel,
      notShowAgainText,
      bodyText,
    } = this.props;
    document.querySelector('body').style.overflowY = 'auto';

    return (
      <div>
        <Modal isOpen={isOpen} style={{border: 'none'}}>
          <ModalHeader>
            <Translate value={title} />
          </ModalHeader>
          <ModalBody>
            {notShowAgainText && this.renderNotShowAgainCheckbox()}
            {bodyText && (
              <Translate
                style={{fontFamily: 'Montserrat-Medium'}}
                value={bodyText}
              />
            )}
            <div className='text-sm-right text-center'>
              <Button
                onClick={() => action(this.state.notShowAgain)}
                id='confirm-btn'
                className='main-btn'>
                <Translate value='confirmModal.confirmBtn' />
              </Button>
              <Button
                onClick={() => handleCancel(this.state.notShowAgain)}
                id='cancel-btn'
                className='main-btn'>
                <Translate value='confirmModal.cancelBtn' />
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  renderNotShowAgainCheckbox() {
    const {notShowAgainText} = this.props;

    return (
      <div className='text-sm-left text-center checkbox-label d-flex mb-2'>
        <input
          type='checkbox'
          onClick={this.notShowAgainChange}
          checked={this.state.notShowAgain}
          className='mr-1'
        />
        <Translate value={notShowAgainText} />
      </div>
    );
  }
}
