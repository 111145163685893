/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {Translate} from 'react-redux-i18n';
import {Alert, Button, Card, CardBody, CardHeader} from 'reactstrap';
import {GenericModal} from 'shared/components/modal/GenericModal';
import p24logo from '../../../../assets/images/p24-logo.png';
import {isEmptyArray} from '../../../utils/utils';
import {SubscriptionPlansList} from '../subscription-plans-list/SubscriptionPlansList';

export class SubscriptionBuyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionPlanId: null,
      paymentServiceType: 'P24',
    };

    this.renderContent = this.renderContent.bind(this);
    this.renderSubscriptionPlans = this.renderSubscriptionPlans.bind(this);
    this.renderPaymentServiceInfo = this.renderPaymentServiceInfo.bind(this);
    this.onClickSubscriptionPlan = this.onClickSubscriptionPlan.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  renderSubscriptionPlans() {
    return (
      <Card className='mb-4'>
        <CardHeader>
          <Translate
            style={{fontFamily: 'Montserrat-Medium'}}
            value='subscriptionBuyModal.choosePlanLabel'
          />
        </CardHeader>
        <CardBody className='text-center'>
          <SubscriptionPlansList
            subscriptionPlans={this.props.subscriptionPlans}
            onSubscriptionPlanClick={this.onClickSubscriptionPlan}
          />
        </CardBody>
      </Card>
    );
  }

  renderNoSubscriptionPlansAlert() {
    return (
      <Alert className='text-center' color='warning'>
        <h5 className='alert-heading'>
          <Translate value='alert.warning' />
        </h5>
        <Translate value='subscriptionBuyModal.noSubscriptionPlansAlert' />
      </Alert>
    );
  }

  onClickSubscriptionPlan(subscriptionPlanId) {
    this.setState({subscriptionPlanId: subscriptionPlanId});
  }

  onConfirm() {
    this.props.handleConfirm(this.state);
  }

  renderPaymentServiceInfo() {
    return (
      <Card>
        <CardHeader>
          <Translate
            style={{fontFamily: 'Montserrat-Medium'}}
            value='subscriptionBuyModal.paymentServiceLabel'
          />
        </CardHeader>
        <CardBody className='text-center'>
          <img src={p24logo} className='w-50' />
        </CardBody>
      </Card>
    );
  }

  renderContent() {
    const {handleCancel, subscriptionPlans, isFetching} = this.props;

    const hasSubscriptionPlans = !isEmptyArray(subscriptionPlans);

    return (
      <Fragment>
        <Loadable active={isFetching} spinner>
          {!hasSubscriptionPlans && this.renderNoSubscriptionPlansAlert()}
          {hasSubscriptionPlans && (
            <Fragment>
              {this.renderSubscriptionPlans()}
              {this.renderPaymentServiceInfo()}
            </Fragment>
          )}

          <div className='text-sm-right text-center mt-3'>
            {hasSubscriptionPlans && (
              <Button
                id='conform-subscription-buy-modal'
                className='main-btn fixed-width-btn'
                onClick={this.onConfirm}
                disabled={!this.state.subscriptionPlanId}>
                <Translate value='subscriptionBuyModal.confirmBtn' />
              </Button>
            )}
            <Button
              id='cancel-subscription-buy-modal'
              className='main-btn fixed-width-btn'
              onClick={handleCancel}>
              <Translate value='subscriptionBuyModal.cancelBtn' />
            </Button>
          </div>
        </Loadable>
      </Fragment>
    );
  }

  render() {
    const {isOpen} = this.props;

    return (
      <Fragment>
        <GenericModal
          content={this.renderContent()}
          isOpen={isOpen}
          title='subscriptionBuyModal.modalTitle'
        />
      </Fragment>
    );
  }
}

SubscriptionBuyModal.propTypes = {
  isOpen: PropTypes.bool,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  subscriptionPlans: PropTypes.array,
  isFetching: PropTypes.bool,
};

SubscriptionBuyModal.defaultProps = {
  subscriptionPlans: [],
};
