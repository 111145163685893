/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

/*global google*/
import {GMap} from 'primereact/gmap';
import {Rating} from 'primereact/rating';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {change, getFormValues} from 'redux-form';
import {messageActions} from 'shared/actions/message.actions';

class RenderGmap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      overlays: null,
      searchBox: null,
      bounds: null,
      circle: null,
    };

    this.placeChangedHandler = this.placeChangedHandler.bind(this);
    this.initAutocomplete = this.initAutocomplete.bind(this);
  }

  componentDidMount() {
    this.initAutocomplete();
  }

  initAutocomplete() {
    const searchBox = new google.maps.places.SearchBox(this.refs.searchBox);
    const bounds = new google.maps.LatLngBounds();
    this.centerOnMarkerOnInit();
    searchBox.addListener('places_changed', this.placeChangedHandler);
    this.map
      .getMap()
      .controls[google.maps.ControlPosition.TOP_LEFT].push(this.refs.searchBox);
    this.setState({searchBox: searchBox, bounds: bounds});
  }

  centerOnMarkerOnInit() {
    const {formValues, circleRadiusKm} = this.props;
    if (!formValues) return;

    const markerOptions = {
      position: {
        lat: parseFloat(formValues.latitude),
        lng: parseFloat(formValues.longitude),
      },
    };
    const marker = new google.maps.Marker(markerOptions);
    const circle = new google.maps.Circle({
      map: this.map.getMap(),
      radius: circleRadiusKm * 1000,
      fillColor: '#AA0000',
    });
    circle.bindTo('center', marker, 'position');
    this.setState({overlays: [marker].concat(this.getAdditionalMarkers())});
    this.setState({circle: circle});

    const bounds = new google.maps.LatLngBounds();
    bounds.union(circle.getBounds());

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + 0.01,
        bounds.getNorthEast().lng() + 0.01
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - 0.01,
        bounds.getNorthEast().lng() - 0.01
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    this.map.getMap().fitBounds(bounds);
  }

  getAdditionalMarkers() {
    const {additionalMarkersInfo} = this.props;
    const iconBase = 'https://maps.google.com/mapfiles/kml/pal4/';
    return additionalMarkersInfo
      ? additionalMarkersInfo.map(ami => {
          const content = this.prepareInfoWindowContent(ami);
          const infowindow = new google.maps.InfoWindow({content: content});
          const marker = new google.maps.Marker({
            position: ami.position,
            icon: iconBase + ami.icon,
            animation: google.maps.Animation.DROP,
          });
          marker.addListener('mouseover', () =>
            infowindow.open(this.map, marker)
          );
          marker.addListener('mouseout', () =>
            infowindow.close(this.map, marker)
          );
          return marker;
        })
      : [];
  }

  prepareInfoWindowContent(object) {
    const content = document.createElement('div');
    const titleDiv = document.createElement('div');
    titleDiv.innerHTML = object.title;
    const ratingDiv = document.createElement('div');
    ratingDiv.setAttribute('class', 'mt-2');
    ReactDOM.render(
      <Rating value={object.averageRating} readonly={true} cancel={false} />,
      ratingDiv
    );
    content.appendChild(titleDiv);
    content.appendChild(ratingDiv);
    return content;
  }

  placeChangedHandler() {
    const {circleRadiusKm} = this.props;
    const places = this.state.searchBox.getPlaces();
    if (places.length === 0) {
      this.props.dispatch(
        messageActions.errorMessage('renderField.placesNotFound')
      );
      return;
    }

    const place = places[0];
    if (!place.geometry) {
      this.props.dispatch(
        messageActions.errorMessage('renderField.placesNotFound')
      );
      return;
    }

    this.state.circle.setMap(null);
    const marker = new google.maps.Marker({
      position: place.geometry.location,
    });
    const circle = new google.maps.Circle({
      map: this.map.getMap(),
      radius: circleRadiusKm * 1000,
      fillColor: '#AA0000',
    });
    circle.bindTo('center', marker, 'position');
    this.setState({overlays: [marker].concat(this.getAdditionalMarkers())});
    this.setState({circle: circle});

    this.state.bounds.union(circle.getBounds());
    this.map.getMap().fitBounds(this.state.bounds);

    this.fillForm({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    });
  }

  fillForm(data) {
    const {onFormFilled} = this.props;
    this.props.dispatch(
      change(this.props.formName, 'latitude', data.latitude.toString())
    );
    this.props.dispatch(
      change(this.props.formName, 'longitude', data.longitude.toString())
    );
    this.props.dispatch(messageActions.successMessage('renderField.checkData'));
    if (onFormFilled) onFormFilled();
  }

  render() {
    const {searchBoxPlaceholderKey, searchEnabled} = this.props;
    const options = {
      center: {lat: 52.237049, lng: 21.017532},
      zoom: 5,
      gestureHandling: 'cooperative',
    };

    return (
      <div className='mt-3 mb-3'>
        {searchEnabled && (
          <input
            id='search-box'
            type='text'
            ref='searchBox'
            placeholder={I18n.t(
              searchBoxPlaceholderKey
                ? searchBoxPlaceholderKey
                : 'renderField.searchBoxPlaceholder'
            )}
          />
        )}
        <GMap
          ref={ref => (this.map = ref)}
          overlays={this.state.overlays}
          options={options}
          style={{width: '100%', minHeight: '320px'}}
        />
      </div>
    );
  }
}

RenderGmap.propTypes = {
  searchEnabled: PropTypes.bool.isRequired,
};

RenderGmap.defaultProps = {
  searchEnabled: true,
};

function mapStateToProps(state, componentProps) {
  return {
    formValues: getFormValues(componentProps.formName)(state),
    i18n: state.i18n,
  };
}

export default connect(mapStateToProps)(RenderGmap);
