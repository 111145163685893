/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateCity,
  validatePhoneNumber,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  const errors = {};

  errors.workshopName = validateRequiredFieldsWithMaxLength(
    values.workshopName,
    100
  );
  errors.ownerName = validateRequiredFieldsWithMaxLength(values.ownerName, 50);
  errors.phoneNumber = validatePhoneNumber(values.phoneNumber);
  errors.mobilePhoneNumber = validatePhoneNumber(values.mobilePhoneNumber);
  errors.faxNumber = validatePhoneNumber(values.faxNumber);
  errors.nip = validateByLengthOnly(values.nip, 20);
  errors.regon = validateByLengthOnly(values.regon, 20);
  errors.krs = validateByLengthOnly(values.krs, 20);
  errors.website = validateByLengthOnly(values.website, 20);
  errors.description = validateByLengthOnly(values.description, 100);

  errors.city = validateCity(values.city);
  errors.postalCode = validateRequiredFieldsWithMaxLength(
    values.postalCode,
    10
  );
  errors.postalCity = validateCity(values.postalCity);
  errors.streetAddress = validateRequiredFieldsWithMaxLength(
    values.streetAddress,
    50
  );

  return errors;
};
