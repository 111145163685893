/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import Steps, {Step} from 'rc-steps';
import 'rc-steps/assets/index.css';
import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {getFormValues} from 'redux-form';
import {carActions} from 'shared/actions/car.actions';
import {messageActions} from 'shared/actions/message.actions';
import {orderActions} from 'shared/actions/order.actions';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {userService} from 'shared/services/user.service';
import 'shared/styles/rc-steps.css';
import {history} from 'shared/utils/history';
import {ValuationInquiryOriginEnum} from 'shared/utils/utils';
import CreateCarProposalModal from './valuation-inquiry-form/create-car-proposal/CreateCarProposalModal';
import ValuationInquiryFormPage1 from './valuation-inquiry-form/page-1/ValuationInquiryFormPage1';
import ValuationInquiryFormPage2 from './valuation-inquiry-form/page-2/ValuationInquiryFormPage2';
import {CarDataTypes} from './valuation-inquiry-form/page-2/ValuationInquiryFormSchemaPage2';
import ValuationInquiryFormPage3 from './valuation-inquiry-form/page-3/ValuationInquiryFormPage3';
import ValuationInquiryFormPage4 from './valuation-inquiry-form/page-4/ValuationInquiryFormPage4';
import './valuation-inquiry.css';

class ValuationInquiry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      confirmDraftModalOpen: false,
      createCarModalOpen: false,
      valuationInquiryDraft: null,
      showPage3: props.isValuationPublic,
      pageTitle: '',
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.create = this.create.bind(this);
    this.cancel = this.cancel.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.submitValuationInquiryAndCreateCar =
      this.submitValuationInquiryAndCreateCar.bind(this);
    this.renderShowPage3Checkbox = this.renderShowPage3Checkbox.bind(this);
    this.setPageTitle = this.setPageTitle.bind(this);
  }

  componentDidMount() {
    this.loadServiceCategories();
    this.props.findFuelTypes();
    this.props.findCarBrands();
    if (this.props.isValuationPublic !== true) {
      this.props.findValuationInquiryDraftByUserId(userService.getUserId());
      this.props.findCarsByUserId(userService.getUserId());
      this.props.getUserDataById(userService.getUserId());
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isDraftLoaded(prevProps))
      this.setState({confirmDraftModalOpen: true});
  }

  componentWillUnmount() {
    this.props.resetValuationInquiryForm();
  }

  setPageTitle(pageTitle) {
    this.setState({pageTitle});
  }

  loadServiceCategories() {
    const {
      directWorkshop,
      findAllServiceCategories,
      findAllActiveServiceCategoriesByWorkshopId,
    } = this.props;
    if (!!directWorkshop)
      findAllActiveServiceCategoriesByWorkshopId(directWorkshop.workshopId);
    else findAllServiceCategories();
  }

  isDraftLoaded(prevProps) {
    return (
      prevProps.valuationInquiryDraft !== this.props.valuationInquiryDraft &&
      this.props.valuationInquiryDraft
    );
  }

  nextPage() {
    this.setState({page: this.state.page + 1});
  }

  previousPage() {
    this.setState({page: this.state.page - 1});
  }

  create(values, shouldCreateCar) {
    this.setAdditionalValues(values);
    this.props.createValuationInquiry(
      values,
      shouldCreateCar,
      this.props.isValuationPublic
    );
  }

  cancel() {
    this.props.resetValuationInquiryForm();
    setTimeout(() => history.push('/'), 100);
  }

  generatePdf() {
    const {values} = this.props;
    this.setAdditionalValues(values);
    this.props.generateValuationInquiryPdf(values);
  }

  setAdditionalValues(values) {
    values.userVoivodeship = values.state;
    values.userPhoneNumber.phoneNumberPrefix = values.userPhoneNumber
      .phoneNumber
      ? values.userPhoneNumber.phoneNumberPrefix
      : null;
    if (!!this.props.directWorkshop)
      values.origin = ValuationInquiryOriginEnum.SW;
    else values.origin = ValuationInquiryOriginEnum.KM;
  }

  createDraft() {
    const {values} = this.props;
    this.props.createValuationInquiryDraft(values);
  }

  submitValuationInquiryAndCreateCar(createCarProposalFormValues) {
    const {values} = this.props;
    const finalValues = Object.assign(values, createCarProposalFormValues);
    this.create(finalValues, true);
  }

  handleFormSubmit(submittedValues) {
    if (!submittedValues.acceptStatute) {
      this.props.errorMessage(I18n.t('valuationInquiry.acceptStatuteAlert'));
    } else {
      const {isValuationPublic} = this.props;
      if (
        isValuationPublic ||
        submittedValues.carDataType === CarDataTypes.EXISTING
      ) {
        this.create(submittedValues, false);
      } else {
        this.setState({createCarModalOpen: true});
      }
    }
  }

  renderCreateCarProposalModal() {
    const {values} = this.props;
    return (
      <CreateCarProposalModal
        isOpen={this.state.createCarModalOpen}
        acceptCreateCar={this.submitValuationInquiryAndCreateCar}
        declineCreateCar={() => this.create(values, false)}
      />
    );
  }

  renderSteps() {
    return (
      <div className='mt-3'>
        <Steps
          className='valuation-inquiry-steps'
          labelPlacement='vertical'
          current={this.state.page - 1}>
          <Step />
          <Step />
          {this.state.showPage3 && <Step />}
          <Step />
        </Steps>
        <Steps
          className='valuation-inquiry-steps-mobile'
          direction='vertical'
          labelPlacement='horizontal'
          current={this.state.page - 1}>
          <Step />
          <Step />
          {this.state.showPage3 && <Step />}
          <Step />
        </Steps>
      </div>
    );
  }

  renderConfirmDraftModal() {
    const {deleteValuationInquiryDraftByUserId, valuationInquiryDraft} =
      this.props;

    return (
      <ConfirmModal
        title='valuationInquiry.existingDraftQuestion'
        isOpen={this.state.confirmDraftModalOpen}
        notShowAgainText='valuationInquiry.deleteDraftCheckboxLabel'
        action={notShowAgain => {
          if (notShowAgain)
            deleteValuationInquiryDraftByUserId(userService.getUserId());
          this.setState({confirmDraftModalOpen: false});
          this.setState({valuationInquiryDraft: valuationInquiryDraft});
        }}
        handleCancel={notShowAgain => {
          if (notShowAgain)
            deleteValuationInquiryDraftByUserId(userService.getUserId());
          this.setState({confirmDraftModalOpen: false});
          this.setState({valuationInquiryDraft: null});
        }}
      />
    );
  }

  resolveInitialValues() {
    const {valuationInquiryDraft} = this.state;
    const {initialValues} = this.props;
    return valuationInquiryDraft ? valuationInquiryDraft : initialValues;
  }

  renderShowPage3Checkbox() {
    return (
      <div className='row mt-4'>
        <div className='col-12'>
          <div className='mb-2' style={{display: 'flex', alignItems: 'center'}}>
            <input
              type='checkbox'
              onClick={e =>
                this.setState({showPage3: e.nativeEvent.srcElement.checked})
              }
              checked={this.state.showPage3}
            />
            <div className='checkbox-label'>
              <Translate value='valuationInquiry.addressDataShowCheckbox' />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {page, valuationInquiryDraft, pageTitle} = this.state;
    const {user, isValuationPublic, directWorkshop, isFetching} = this.props;
    const resolvedInitialValues = this.resolveInitialValues();
    const createDraftHandler = isValuationPublic ? null : this.createDraft;
    const showPage3Checkbox = isValuationPublic
      ? null
      : this.renderShowPage3Checkbox();
    const containerClass = isValuationPublic
      ? 'public-valuation-inquiry-container'
      : 'valuation-inquiry-container';
    const contentClass = isValuationPublic
      ? 'public-valuation-inquiry-content'
      : 'valuation-inquiry-content';

    return (
      <ContentWrapper title={pageTitle} containerClass={containerClass}>
        <Loadable active={isFetching} spinner>
          <div className={contentClass}>
            {this.renderConfirmDraftModal()}
            {this.renderCreateCarProposalModal()}
            {page === 1 && (
              <ValuationInquiryFormPage1
                onSubmit={this.nextPage}
                handleCancel={this.cancel}
                initialValues={resolvedInitialValues}
                handleCreateDraft={createDraftHandler}
                isValuationPublic={isValuationPublic}
                setPageTitle={this.setPageTitle}
              />
            )}

            {page === 2 && (
              <ValuationInquiryFormPage2
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                user={user}
                handleCancel={this.cancel}
                initialValues={resolvedInitialValues}
                handleCreateDraft={createDraftHandler}
                loadedFromDraft={valuationInquiryDraft}
                additionalFields={showPage3Checkbox}
                setPageTitle={this.setPageTitle}
              />
            )}

            {this.state.showPage3 && page === 3 && (
              <ValuationInquiryFormPage3
                previousPage={this.previousPage}
                onSubmit={this.nextPage}
                handleCancel={this.cancel}
                initialValues={resolvedInitialValues}
                handleCreateDraft={createDraftHandler}
                isValuationPublic={isValuationPublic}
                directWorkshop={directWorkshop}
                setPageTitle={this.setPageTitle}
              />
            )}

            {page === (this.state.showPage3 ? 4 : 3) && (
              <ValuationInquiryFormPage4
                previousPage={this.previousPage}
                onSubmit={this.handleFormSubmit}
                handleCancel={this.cancel}
                initialValues={resolvedInitialValues}
                handleGeneratePdf={this.generatePdf}
                handleCreateDraft={createDraftHandler}
                directWorkshop={directWorkshop}
                setPageTitle={this.setPageTitle}
              />
            )}
            {this.renderSteps()}
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

ValuationInquiry.propTypes = {
  isValuationPublic: PropTypes.bool.isRequired,
  directWorkshop: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  contentClass: PropTypes.string,
  isFetching: PropTypes.bool,
};

ValuationInquiry.defaultProps = {
  isValuationPublic: false,
  directWorkshop: null,
  containerClass: 'valuation-inquiry-container',
  contentClass: 'valuation-inquiry-content',
  isFetching: false,
};

function mapStateToProps(state) {
  const {i18n, user, order} = state;
  const profile = user.data || {};
  const values = getFormValues('valuationInquiryForm')(state);
  const {valuationInquiryDraft} = order;
  const initialValues = {
    valuationInquiryServices: [{}],
    carDataType: user.isAuthenticated
      ? CarDataTypes.EXISTING
      : CarDataTypes.NEW,
    userFirstName: profile.firstName,
    userLastName: profile.lastName,
    userCity: profile.city,
    country: profile.country,
    state: profile.state,
    userEmail: profile.email,
    userPhoneNumber: profile.phoneNumber,
    workshopsDistanceRadius: '10',
  };

  return {i18n, initialValues, user, values, valuationInquiryDraft};
}

const mapDispatchToProps = {
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  findAllActiveServiceCategoriesByWorkshopId:
    serviceCategoryActions.findAllActiveServiceCategoriesByWorkshopId,
  findFuelTypes: carActions.findFuelTypes,
  findCarBrands: carActions.findCarBrands,
  findCarsByUserId: carActions.findCarsByUserId,
  getUserDataById: userActions.getUserDataById,
  createValuationInquiry: orderActions.createValuationInquiry,
  resetValuationInquiryForm: orderActions.resetValuationInquiryForm,
  generateValuationInquiryPdf: orderActions.generateValuationInquiryPdf,
  findValuationInquiryDraftByUserId:
    orderActions.findValuationInquiryDraftByUserId,
  createValuationInquiryDraft: orderActions.createValuationInquiryDraft,
  deleteValuationInquiryDraftByUserId:
    orderActions.deleteValuationInquiryDraftByUserId,
  errorMessage: messageActions.errorMessage,
};

const connectedValuationInquiry = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiry);
export {connectedValuationInquiry as ValuationInquiry};
