/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, Fields} from 'redux-form';
import moment from 'moment';
import RenderDatePicker from 'shared/components/form/date-picker/RenderDatePicker';
import RenderDuration from 'shared/components/form/duration/RenderDuration';
import FileUpload from 'shared/components/form/file-upload/FileUpload';
import RenderReadonly from 'shared/components/form/readonly/RenderReadonly';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import '../valuation-inquiry-response-form.css';
import RenderMultiSelect from 'shared/components/form/multi-select/RenderMultiSelect';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import RenderCurrency from 'shared/components/form/currency/RenderCurrency';

export default class ValuationInquiryResponseFormSchemaPage3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workers: [],
    };
  }

  componentDidMount() {
    this.props.setPageTitle('valuationInquiryResponse.valuationSummary');
  }

  componentDidMount() {
    const {
      findAllWorkersByWorkshopId,
      loggedWorkshopId,
      workshopWorkers,
      isFetching,
    } = this.props;
    this.props.setPageTitle('valuationInquiryResponse.valuationSummary');
    findAllWorkersByWorkshopId(loggedWorkshopId);
    this.setState({
      workers:
        workshopWorkers &&
        workshopWorkers.map(workshopWorker => {
          return {
            label: workshopWorker.firstName + ' ' + workshopWorker.lastName,
            value: workshopWorker.id,
          };
        }),
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isFetching !== this.props.isFetching) {
      this.setState({
        workers:
          this.props.workshopWorkers &&
          this.props.workshopWorkers.map(workshopWorker => {
            return {
              label: workshopWorker.firstName + ' ' + workshopWorker.lastName,
              value: workshopWorker.id,
            };
          }),
      });
    }
  }

  getPaymentTypes() {
    return [
      {
        label: I18n.t('valuationInquiryResponse.cashPaymentTypeLabel'),
        value: 'CASH',
      },
      {
        label: I18n.t('valuationInquiryResponse.cardPaymentTypeLabel'),
        value: 'CARD',
      },
      {
        label: I18n.t('valuationInquiryResponse.creditPaymentTypeLabel'),
        value: 'CREDIT',
      },
    ];
  }

  getWorkerList() {
    this.props.workshopWorkers &&
      this.props.workshopWorkers.map(workshopWorker => {
        return {
          label: workshopWorker.firstName + ' ' + workshopWorker.lastName,
          value: workshopWorker.id,
        };
      });
  }

  renderAttachment() {
    const imgMaxSizeInBytes = 5242880;
    const fileTypes = [
      'application/pdf',
      'application/msword',
      'text/plain',
      'application/zip',
      'application/x-iwork-pages-sffpages',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'image/jpeg',
      'image/png',
    ];
    const acceptedMimeTypes =
      'application/pdf,application/msword,text/plain,application/zip,application/x-iwork-pages-sffpages' +
      'application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/jpeg,image/png';

    return (
      <div className='row mt-3'>
        <div
          className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0 read-only-label'
          style={{whiteSpace: 'break-word'}}>
          <Translate value='valuationInquiryResponse.attachmentLabel' />
        </div>
        <div className='col-lg-9 col-12 my-auto'>
          <Field
            name='attachment'
            component={FileUpload}
            allign='left'
            marginY='0'
            maxSizeInBytes={imgMaxSizeInBytes}
            fileTypes={fileTypes}
            accept={acceptedMimeTypes}
            keyGroup={'valuationInquiryResponse'}
            isComplexValue={true}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      previousPage,
      handleSubmit,
      handleCancel,
      invalid,
      totalSum,
      handleGeneratePdf,
      handleCreateDraft,
      currency,
    } = this.props;
    const minStartDate = moment().format('YYYY-MM-DD');
    return (
      <div className='pb-5'>
        <form
          className='valuation-inquiry-response-form'
          onSubmit={handleSubmit}>
          <Field
            name='additionalInfo'
            type='text'
            component={RenderTextArea}
            required={false}
            label={I18n.t('valuationInquiryResponse.additionalInfoLabel')}
          />
          <Fields
            names={['durationValue', 'durationUnit']}
            component={RenderDuration}
            required={true}
            label={I18n.t('valuationInquiryResponse.estimatedServiceTime')}
          />
          <Field
            name='paymentTypes'
            component={RenderMultiSelect}
            options={this.getPaymentTypes()}
            required={false}
            placeholder={I18n.t(
              'valuationInquiryResponse.paymentTypePlaceholder'
            )}
            label={I18n.t('valuationInquiryResponse.paymentTypeLabel')}
          />
          <Field
            name='additionalCost.value'
            component={RenderCurrency}
            icon='fa fa-money'
            label={I18n.t('valuationInquiryResponse.additionalCostLabel')}
            suffix={currency}
            required={false}
          />
          <Field
            name='expiryDate'
            component={RenderDatePicker}
            label={I18n.t('valuationInquiryResponse.expiryDateLabel')}
            minDate={minStartDate}
            required={true}
            showClearIcon={true}
          />
          <Field
            name='proposedDate'
            component={RenderDatePicker}
            label={I18n.t('valuationInquiryResponse.proposedDateLabel')}
            minDate={minStartDate}
            required={false}
            showClearIcon={true}
          />
          <Field
            name='workshopWorkerId'
            component={RenderDropdown}
            options={this.state.workers}
            required={true}
            placeholder={I18n.t(
              'valuationInquiryResponse.workshopWorkerPlaceholder'
            )}
            label={I18n.t('valuationInquiryResponse.valuationInquiryCreatedBy')}
          />
          {this.renderAttachment()}
          <RenderReadonly
            element={(totalSum ? totalSum : '0.00') + ' ' + currency}
            label={I18n.t('valuationInquiryResponse.sumLabel')}
          />

          <div className='text-lg-right text-center mt-3'>
            <Button className='main-btn float-left' onClick={previousPage}>
              <Translate value='valuationInquiryResponse.previousPageBtn' />
            </Button>
          </div>

          <div className='text-lg-right text-center mt-3'>
            {handleGeneratePdf && (
              <Button
                className='main-btn'
                onClick={handleGeneratePdf}
                disabled={invalid}>
                <Translate value='valuationInquiryResponse.generatePdfBtn' />
              </Button>
            )}
            {handleCreateDraft && (
              <Button className='main-btn' onClick={handleCreateDraft}>
                <Translate value='valuationInquiryResponse.createDraftBtn' />
              </Button>
            )}
            <Button className='main-btn' type='submit' disabled={invalid}>
              <Translate value='valuationInquiryResponse.sendBtn' />
            </Button>
            <Button className='main-btn' onClick={handleCancel}>
              <Translate value='valuationInquiryResponse.cancelBtn' />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ValuationInquiryResponseFormSchemaPage3.propTypes = {
  previousPage: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
};
