/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState, useRef, useMemo } from 'react'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styles from './WorkshopLoyaltyProgramEditForm.module.css'
import { Button } from "reactstrap";
import { Translate } from 'react-redux-i18n';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import "./Jodit.css";
import { useDispatch, useSelector } from 'react-redux';
import WorkshopLoyaltyProgramModal from '../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { I18n } from "react-redux-i18n";
import { Prompt } from "react-router-dom";
import { history } from "shared/utils/history";
import Loadable from 'react-loading-overlay';
import { settingsActions } from 'shared/actions/settings.actions';
import JoditEditor from 'jodit-react';

export const WorkshopLoyaltyProgramEditForm = () => {
  const [editorState, setEditorState] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const dispatch = useDispatch();
  const content = useSelector(state => state.settings.content);
  const isFetching = useSelector(state => state.settings.isFetching);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(settingsActions.getWorkshopLoyaltyProgramEditor());
  }, [])

  useEffect(() => {
    content && setEditorState(JSON.parse(content))
  }, [content])

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  const config = useMemo(() => {
    return {
      readonly: !editMode,
      language: "pl",
      toolbar: editMode,
      autofocus: true,
      buttons: [
        'source', '|',
        'bold',
        'strikethrough',
        'underline',
        'italic', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent', '|',
        'font',
        'fontsize',
        'brush',
        'paragraph', '|',
        'image',
        'video',
        'table',
        'link', '|',
        'align', 'undo', 'redo', '|',
        'hr',
        'eraser',
        'copyformat', '|',
        'print',
      ],
      uploader: {
        insertImageAsBase64URI: true
      },
    }
  }, [editMode])

  const cancelEdition = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WorkshopLoyaltyProgramModal
        cancelStep
        setEditMode={setEditMode}
        setEditorState={() => setEditorState(JSON.parse(content))}
        saveLoyaltyProgram={() => dispatch(settingsActions.updateWorkshopLoyaltyProgramEditor(editorState))} />,
      title: I18n.t("workshopLoyaltyProgram.onLeaveMessage"),
    });
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      dispatch({
        type: updateDataSideBarConstants.SHOW,
        variableComponent: (
          <WorkshopLoyaltyProgramModal
            leaveStep
            setConfirmedNavigation={setConfirmedNavigation}
            saveLoyaltyProgram={() => dispatch(settingsActions.updateWorkshopLoyaltyProgramEditor(editorState))} />
        ),
        title: I18n.t("workshopLoyaltyProgram.onLeaveMessage"),
      });
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  return (
    <ContentWrapper title='workshopLoyaltyProgram.workshopLoyaltyProgram'>
      <Loadable active={isFetching} spinner>
        <Prompt when={editMode} message={handleBlockedNavigation} />
        <div style={{ padding: '1rem' }}>
          {(content || editMode) ?
            <JoditEditor
              ref={editor}
              config={config}
              value={String(editorState)}
              tabIndex={1}
              onBlur={newContent => setEditorState(newContent)}
              onChange={newContent => setEditorState(newContent)}
            />
            : (!editMode &&
              <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <b><Translate value={"workshopLoyaltyProgram.editorEmptyPlaceholder"} /></b>
              </div>)}
          <div className={styles.WorkshopLoyaltyButtons} style={{ marginTop: "2rem" }}>
            {editMode ? <>
              <Button
                className="main-btn"
                style={{ width: "14.7rem" }}
                onClick={() => {
                  dispatch(settingsActions.updateWorkshopLoyaltyProgramEditor(editorState));
                  setEditMode(false);
                }}
              >
                <Translate value={"workshopLoyaltyProgram.confirm"} />
              </Button>
              <Button
                className="main-btn"
                style={{ width: "14.7rem" }}
                onClick={() => {
                  cancelEdition();
                }}
              >
                <Translate value={"workshopLoyaltyProgram.cancel"} />
              </Button>
            </> :
              <Button
                className="main-btn"
                style={{ width: "14.7rem" }}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <Translate value={"workshopLoyaltyProgram.edit"} />
              </Button>
            }
          </div>
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
