/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateRequiredFieldsWithMaxLength,
  validateRequiredFields,
} from '../../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.title = validateRequiredFieldsWithMaxLength(values.title, 60);
  errors.description = validateRequiredFieldsWithMaxLength(
    values.description,
    255
  );
  errors.startDate = validateRequiredFields(values.startDate);
  errors.endDate = validateRequiredFields(values.endDate);

  return errors;
};
