/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { createRef, useEffect } from "react";
import { Translate } from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import styles from "./WorkshopLoyaltyProgramRegister.module.css";
import { useDispatch, useSelector } from "react-redux";
import WorkshopRegisterDataForm from "./workshop-loyalty-register-data-form/WorkshopRegisterDataForm";
import Loadable from 'react-loading-overlay';
import { statuteActions } from 'shared/actions/statute.actions';

const WorkshopLoyaltyProgramRegister = () => {
  const formRef = createRef();

  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.statute.isFetching);
  const isWorkshopFetching = useSelector(state => state.workshop.isFetching);

  const onSave = () => {
    formRef.current.onSave();
  };

  return (
    <>
      <div className={styles.Container} style={{ padding: "25px" }}>
        <Loadable active={isFetching || isWorkshopFetching} spinner>
          <WorkshopRegisterDataForm ref={formRef} />
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSave}>
              <Translate value={"workshopLoyaltyProgram.register.register"} />
            </Button>
            <Button className="main-btn" onClick={
              () => dispatch({
                type: updateDataSideBarConstants.HIDE,
              })}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
        </Loadable>
      </div>
    </>
  );
};

export default WorkshopLoyaltyProgramRegister;
