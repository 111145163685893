/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, UncontrolledTooltip} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {dateUtils} from '../../../utils/date.utils';
import {getOrEmptyArray} from '../../../utils/utils';

class SubscriptionPaymentsTable extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.renderActionsCol = this.renderActionsCol.bind(this);
    this.renderActionButtons = this.renderActionButtons.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  prepareToDisplay(items) {
    return getOrEmptyArray(items).map(item => ({
      ...item,
      createdDateFormatted: dateUtils.formatDate(item.createdDate),
      lastModifiedDateFormatted: dateUtils.formatDate(item.lastModifiedDate),
      amountFormatted: `${item.amount.value} ${item.amount.currency}`,
      statusFormatted: I18n.t(`subscriptionPayment.status${item.status}`),
      paymentServiceTypeFormatted: I18n.t(
        `subscriptionPayment.paymentServiceType${item.paymentServiceType}`
      ),
      paidPeriodFormatted:
        item.status === 'COMPLETED' && item.paidPeriodStartDate
          ? dateUtils.formatTimePeriod(
              item.paidPeriodStartDate,
              item.paidPeriodEndDate
            )
          : '',
    }));
  }

  getRowClassName(item) {
    switch (item.status) {
      case 'PENDING':
        return {'orange-row': true};
      case 'COMPLETED':
        return {'green-row': true};
      case 'CANCELED':
        return {'red-row': true};
      default:
        return {};
    }
  }

  renderActionsCol(item) {
    return item.status === 'PENDING' ? this.renderActionButtons(item) : <div />;
  }

  renderActionButtons(item) {
    return (
      <div>
        <Button
          id={'resume-btn-' + item.id}
          className='main-btn decision-btn'
          onClick={() => this.resumeSubscriptionPayment(item.redirectUrl)}>
          <i className='fa fa-arrow-right' />
        </Button>
        <UncontrolledTooltip
          placement='top'
          target={'resume-btn-' + item.id}
          innerClassName='custom-tooltip'>
          <Translate value='subscriptionPayment.resumePaymentBtnTooltip' />
        </UncontrolledTooltip>

        <Button
          id={'cancel-btn-' + item.id}
          className='btn-danger decision-btn'
          onClick={() => this.props.onCancelClick(item.id)}>
          <i className='fa fa-remove' />
        </Button>
        <UncontrolledTooltip
          placement='top'
          target={'cancel-btn-' + item.id}
          innerClassName='custom-tooltip'>
          <Translate value='subscriptionPayment.cancelPaymentBtnTooltip' />
        </UncontrolledTooltip>
      </div>
    );
  }

  resumeSubscriptionPayment(redirectUrl) {
    window.location.assign(redirectUrl);
  }

  render() {
    const {
      subscriptionPayments,
      headerBuilder,
      showUserLabel,
      showActions,
      showTitle,
      renderRowsDropdown,
    } = this.props;

    return (
      <div className='p-3'>
        {showTitle && (
          <h3
            className='text-center'
            style={{fontFamily: 'Montserrat-SemiBold', color: '#2A3FAB'}}>
            <Translate value='subscriptionPayment.subscriptionPaymentsList' />
          </h3>
        )}
        <DataTable
          value={this.prepareToDisplay(subscriptionPayments)}
          header={headerBuilder()}
          rows={this.props.rows}
          globalFilter={this.props.globalFilter}
          paginator={true}
          responsive={true}
          className='p-3'
          emptyMessage={I18n.t('subscriptionPayment.emptyPlaceholder')}
          rowClassName={this.getRowClassName}
          paginatorLeft={renderRowsDropdown()}
          ref={this.setDatatableRef}>
          {showUserLabel && (
            <Column
              field='userLabel'
              header={I18n.t('subscriptionPayment.userLabel')}
              sortable={true}
            />
          )}

          <Column
            field='planLabel'
            header={I18n.t('subscriptionPayment.planLabel')}
            sortable={true}
          />

          <Column
            field='createdDateFormatted'
            header={I18n.t('subscriptionPayment.createdDate')}
            sortable={true}
          />

          <Column
            field='lastModifiedDateFormatted'
            header={I18n.t('subscriptionPayment.lastModifiedDate')}
            sortable={true}
          />

          <Column
            field='paidPeriodFormatted'
            header={I18n.t('subscriptionPayment.paidPeriod')}
            sortable={true}
          />

          <Column
            field='amountFormatted'
            header={I18n.t('subscriptionPayment.amount')}
            sortable={true}
          />

          <Column
            field='statusFormatted'
            header={I18n.t('subscriptionPayment.status')}
            sortable={true}
          />

          <Column
            field='paymentServiceTypeFormatted'
            header={I18n.t('subscriptionPayment.paymentServiceType')}
            sortable={true}
          />

          <Column
            field='externalId'
            header={I18n.t('subscriptionPayment.externalId')}
            sortable={true}
          />

          {showActions && (
            <Column
              header={I18n.t('subscriptionPayment.actions')}
              body={this.renderActionsCol}
            />
          )}
        </DataTable>
      </div>
    );
  }
}

SubscriptionPaymentsTable.propTypes = {
  subscriptionPayments: PropTypes.array,
  onCancelClick: PropTypes.func,
  showUserLabel: PropTypes.bool,
  showActions: PropTypes.bool,
  showTitle: PropTypes.bool,
};

SubscriptionPaymentsTable.defaultProps = {
  subscriptionPayments: [],
  onCancelClick: () => {},
  showUserLabel: false,
  showActions: false,
  showTitle: false,
};

export {SubscriptionPaymentsTable as SubscriptionPaymentsTableCore};

export default withDataTableCore(SubscriptionPaymentsTable);
