/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import jwt_decode from 'jwt-decode';

export const jwt = {
  decode,
};

function decode(token) {
  return jwt_decode(token);
}
