/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {CalendarForm} from './CalendarForm';
import {workshopActions} from '../../../actions/workshop.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {userService} from '../../../services/user.service';
import {validate} from './calendar-form-validators';

export const CalendarFormReduxFormWrapper = reduxForm({
  form: 'calendarCreateForm',
  validate,
  enableReinitialize: true,
})(CalendarForm);

class CalendarCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createWorkshopExternalCalendar, onCreateSuccess} = this.props;
    createWorkshopExternalCalendar(
      {...data, workshopUserId: userService.getUserId()},
      onCreateSuccess
    );
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <CalendarFormReduxFormWrapper {...this.props} onSubmit={this.create} />
      </Loadable>
    );
  }
}

CalendarCreateForm.propTypes = {
  createWorkshopExternalCalendar: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, workshop} = state;
  const {isFetching} = workshop;
  const initialValues = {color: '#22BAA0'};

  return {i18n, isFetching, initialValues};
}

const mapDispatchToProps = {
  createWorkshopExternalCalendar:
    workshopActions.createWorkshopExternalCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCreateForm);
