/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import renderField from 'shared/components/form/text-field/RenderField';

export default class AddressDataSection extends React.Component {
  render() {
    return (
      <Fragment>
        <Field
          name='companyName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('distributors.companyNameLabel')}
          icon='fa fa-briefcase'
        />
        <Field
          name='ownerName'
          type='text'
          component={renderField}
          required={true}
          withTooltip={true}
          tooltipContent={I18n.t('distributors.ownerNameTooltip')}
          icon='fa fa-user'
          tooltipClassName={'custom-tooltip-smaller-width'}
          label={I18n.t('distributors.ownerNameLabel')}
        />
        <Field
          name='city'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.cityLabel')}
          icon='fa fa-building'
        />
        <Field
          name='postalCode'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.postalCodeLabel')}
          icon='fa fa-inbox'
        />
        <Field
          name='postalCity'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.postalCityLabel')}
          icon='fa fa-envelope'
        />
        <Field
          name='streetAddress'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.streetAddressLabel')}
          icon='fa fa-address-card'
        />
      </Fragment>
    );
  }
}
