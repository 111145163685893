/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {withRedirectForLoggedUser} from 'shared/hoc/redirect-logged-user/redirectLoggedUserHoc';
import './valuation-inquiry.css';
import {ValuationInquiry} from './ValuationInquiry';

export class PublicValuationInquiry extends React.Component {
  render() {
    return (
      <div className='root-panel py-5'>
        <ValuationInquiry isValuationPublic={true} />
      </div>
    );
  }
}

export default withRedirectForLoggedUser(
  PublicValuationInquiry,
  '/valuations',
  true
);
