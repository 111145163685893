/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {FieldArray, FormSection, getFormValues, reduxForm} from 'redux-form';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {resolveCurrencyOptions} from '../utils/utils';
import './distributors-form.css';
import AccessSection from './sections/access-section/AccessSection';
import AddressDataSection from './sections/address-data-section/AddressDataSection';
import DistributorDetailsSection from './sections/distributor-details-section/DistributorDetailsSection';
import FirstSection from './sections/first-section/FirstSection';
import OtherSection from './sections/other-section/OtherSection';
import {validate} from './validators/validators';

class EditDistributorForm extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, form} = this.props;
    if (this.props.shouldInitializeNestedPhonePrefixes(prevProps, formValues))
      this.props.initializePhonePrefixesByCountry(
        form,
        formValues.country,
        true
      );
  }

  render() {
    const {handleSubmit, invalid, formValues, countries} = this.props;
    const currencyOptions = formValues
      ? resolveCurrencyOptions(countries, formValues.country)
      : null;
    return (
      <form className='edit-distributor-form' onSubmit={handleSubmit}>
        <FirstSection />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.addressDataHeader' />
        </h3>
        <FormSection name='address'>
          <AddressDataSection />
        </FormSection>

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.detailsDataHeader' />
        </h3>
        <FormSection name='details'>
          <DistributorDetailsSection />
        </FormSection>

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.accessHeader' />
        </h3>
        <FieldArray name='privileges' component={AccessSection} />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.otherDataHeader' />
        </h3>
        <FormSection name='details'>
          <OtherSection currencyOptions={currencyOptions} />
        </FormSection>

        <div className='row mt-3 justify-content-end'>
          <div className='col-lg-9 col-12'>
            <div id='distributor-buttons' className='text-right'>
              <Button type='submit' disabled={invalid} className='main-btn'>
                <Translate value='distributors.editBtn' />
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

EditDistributorForm = reduxForm({
  form: 'editDistributorForm',
  validate,
  enableReinitialize: true,
})(EditDistributorForm);

const mapStateToProps = state => {
  const {i18n, distributor, country} = state;
  const {selectedDistributor} = distributor;
  const {countries} = country;

  return {
    i18n,
    countries,
    initialValues: toCorrectJson(selectedDistributor),
    formValues: getFormValues('editDistributorForm')(state),
  };
};

const toCorrectJson = values => {
  if (!values) return {};

  values.country = values.address.country;
  values.language = values.details.language;
  values.address.ownerName = values.details.ownerName;
  values.address.companyName = values.details.companyName;
  values.privileges = values.userDto.privileges;

  return values;
};

//export for tests
export const EditDistributorFormConnectedWithRedux =
  connect(mapStateToProps)(EditDistributorForm);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(EditDistributorForm)
);
