/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { RenderField } from "../../../../../shared/components/form/text-field/hook-form-version/RenderField";
import { I18n } from "react-redux-i18n";
import { useForm } from "react-hook-form";
import { ReqexpValidationEnum } from "../../../../../shared/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { Translate } from 'react-redux-i18n';
import { apiUrl } from '../../../../../environment';
import {workshopLoyaltyProgramActions} from "shared/actions/workshopLoyaltyProgram.actions";

const WorkshopLoyaltyProgramProfileEditForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    setValue,
    handleSubmit,
    register,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const {workshopLoyaltyProgramData} = props;
  const [isStatuteAccepted, setIsStatuteAccepted] = useState(false);
  const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);

  const workshopNameField = "workshopName";
  const workshopWorkerField = "workshopWorker";
  const cityField = "city";
  const postalCodeField = "postalCode";
  const streetAddressField = "streetAddress";
  const emailField = "email";
  const phoneNumberField = "phoneNumber";
  const nipField = "nip";
  const statuteAcceptedField = "statuteAccepted";

  useImperativeHandle(ref, () => ({
    onSave: handleSubmit((data) => onSubmit(data)),
  }));

  useEffect(() => {
    if (workshopLoyaltyProgramData) {
      setValue(workshopNameField, workshopLoyaltyProgramData.workshopName);
      setValue(streetAddressField, workshopLoyaltyProgramData.address.streetAddress);
      setValue(postalCodeField, workshopLoyaltyProgramData.address.postalCode);
      setValue(cityField, workshopLoyaltyProgramData.address.city);
      workshopLoyaltyProgramData.phoneNumber ? setValue(phoneNumberField, workshopLoyaltyProgramData.phoneNumber) : setValue(phoneNumberField, "");
      workshopLoyaltyProgramData.workshopWorker ? setValue(workshopWorkerField, workshopLoyaltyProgramData.workshopWorker) : setValue(workshopWorkerField, "");
      workshopLoyaltyProgramData.nip ? setValue(nipField, workshopLoyaltyProgramData.nip) : setValue(nip, "");
      setValue(emailField, workshopLoyaltyProgramData.email);
      setIsStatuteAccepted(workshopLoyaltyProgramData.acceptedStatuteId && workshopLoyaltyProgramData.statuteAcceptanceDate);
    }
  }, [workshopLoyaltyProgramData]);

  const onSubmit = (data) => {
    let objectToSave = {
      workshopName: data.workshopName,
      workshopWorker: data.workshopWorker,
      address: {
          city: data.city,
          postalCode: data.postalCode,
          streetAddress: data.streetAddress,
      },
      email: data.email,
      phoneNumber: data.phoneNumber,
      nip: data.nip,
      statuteAccepted: true,
    }
    props.onSuccess();
    dispatch(workshopLoyaltyProgramActions.updateWorkshopDataInWorkshopLoyaltyProgram(workshopLoyaltyProgramData.workshopId, objectToSave));
  }

  return (
    <>
      <RenderField
        {...register(workshopNameField, { maxLength: 60, required: true })}
        label={I18n.t("workshopLoyaltyProgram.register.workshopName")}
        value={watch(workshopNameField)}
        error={errors[workshopNameField]}
        type="text"
      />
      <RenderField
        {...register(workshopWorkerField, {
          maxLength: 255,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.representant")}
        error={errors[workshopWorkerField]}
        value={watch(workshopWorkerField)}
        type="text"
      />
      <RenderField
        {...register(cityField, {
          maxLength: 60,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.city")}
        error={errors[cityField]}
        value={watch(cityField)}
        type="text"
      />
      <RenderField
        {...register(streetAddressField, {
          maxLength: 255,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.address")}
        error={errors[streetAddressField]}
        value={watch(streetAddressField)}
        type="text"
      />
      <RenderField
        {...register(postalCodeField, {
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.postalCode")}
        error={errors[postalCodeField]}
        value={watch(postalCodeField)}
        type="text"
      />
      <RenderField
        {...register(emailField, {
          maxLength: 60,
          pattern: ReqexpValidationEnum.EMAIl,
          required: true
        })}
        validateOnBlur={true}
        label={I18n.t("workshopLoyaltyProgram.register.email")}
        error={errors[emailField]}
        value={watch(emailField)}
        type="text"
      />
      <RenderField
        {...register(phoneNumberField, {
          maxLength: 20,
          pattern: ReqexpValidationEnum.PHONE_NUMBER,
          required: true
        })}
        validateOnBlur={true}
        label={I18n.t("workshopLoyaltyProgram.register.phone")}
        value={watch(phoneNumberField)}
        error={errors[phoneNumberField]}
        type="text"
      />
      <RenderField
        {...register(nipField, {
          pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          maxLength: 10,
          required: true
        })}
        error={errors[nipField]}
        label={I18n.t("workshopLoyaltyProgram.register.nip")}
        value={watch(nipField)}
        type="text"
      />
      <div style={{ marginTop: '1rem' }}>
        <Checkbox
          {...register(statuteAcceptedField, {
            validate: {
              isTrue: v => {
                return !!isStatuteAccepted
              }
            }
          })}
          classname='mr-1'
          onChange={(event) => {
            setValue(statuteAcceptedField, event.checked);
            setIsStatuteAccepted(event.checked);
            (errors[statuteAcceptedField] && event.checked) ? clearErrors(statuteAcceptedField) : null;
          }}
          checked={isStatuteAccepted}
          value={isStatuteAccepted}
          required
        />
        <span style={{ paddingLeft: 9 }}>{I18n.t('workshopLoyaltyProgram.register.statuteAccepted')}</span>
        {loyaltyProgramStatute &&
          <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
            <span style={{ paddingLeft: 4 }}>{I18n.t('workshopLoyaltyProgram.register.statute')}</span>
          </a>}
        <div id="error-msg" className="col-12" style={{ minHeight: 24, paddingLeft: 0 }}>
          {errors[statuteAcceptedField] &&
            <span className="error-info">
              <Translate value={"workshopLoyaltyProgram.register.acceptation"} />
            </span>
          }
        </div>
      </div>
    </>
  )
});

export default WorkshopLoyaltyProgramProfileEditForm;
