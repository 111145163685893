import React from "react";
import styles from "../WarrantyInstalationData.module.css";
import EmptyFieldPlaceholder from "../../empty-field-placeholder/EmptyFieldPlaceholder";
import { useSelector } from "react-redux";

const DescriptionFault = () => {
  const description = useSelector(
    (state) => state.warrantyBook.initializedWarranty.description
  );
  
  return (
      <div className={styles.SecondSegmentCollapsedContainer}>
        {description ? (
          description
        ) : (
          <EmptyFieldPlaceholder content={"warranty.noData"} />
        )}
      </div>
  );
};

export default DescriptionFault;
