/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const workshopConstants = {
  LOAD_WORKSHOP_DATA: 'LOAD_WORKSHOP_DATA',
  LOAD_WORKSHOPS_DATA: 'LOAD_WORKSHOPS_DATA',
  REFRESH_WORKSHOPS_DATA: 'REFRESH_WORKSHOPS_DATA',
  WORKSHOP_REQUEST_START: 'WORKSHOP_REQUEST_START',
  WORKSHOP_REQUEST_END: 'WORKSHOP_REQUEST_END',
  LOAD_WORKSHOP_SERVICES: 'LOAD_WORKSHOP_SERVICES',
  LOAD_LOGGED_WORKSHOP_DATA: 'LOAD_LOGGED_WORKSHOP_DATA',
  ADD_SERVICE: 'ADD_SERVICE',
  DELETE_SERVICE: 'DELETE_SERVICE',
  CLEAR_SERVICES: 'CLEAR_SERVICES',
  ACCEPT_INVITE_MESSAGE: 'ACCEPT_INVITE_MESSAGE',
  CLEAR_SELECTED_WORKSHOP_DATA: 'CLEAR_SELECTED_WORKSHOP_DATA',
  LOAD_NEEDED_PARTS_INQUIRIES: 'LOAD_NEEDED_PARTS_INQUIRIES',
  SET_UPDATED_ADDRESS: 'SET_UPDATED_ADDRESS',
  SET_UPDATED_DETAILS: 'SET_UPDATED_DETAILS',
  REFRESH_ADDRESS_MAP: 'REFRESH_ADDRESS_MAP',
  REFRESH_NEEDED_PARTS: 'REFRESH_NEEDED_PARTS',
  LOAD_WORKSHOP_CALENDAR_EVENTS: 'LOAD_WORKSHOP_CALENDAR_EVENTS',
  LOAD_WORKSHOP_EXTERNAL_CALENDARS: 'LOAD_WORKSHOP_EXTERNAL_CALENDARS',
  REFRESH_WORKSHOP_EXTERNAL_CALENDARS: 'REFRESH_WORKSHOP_EXTERNAL_CALENDARS',
  LOAD_WORKSHOP_EXTERNAL_CALENDAR: 'LOAD_WORKSHOP_EXTERNAL_CALENDAR',
  CLEAR_WORKSHOP_EXTERNAL_CALENDAR: 'CLEAR_WORKSHOP_EXTERNAL_CALENDAR',
  LOAD_WORKSHOP_WORKING_DAYS: 'LOAD_WORKSHOP_WORKING_DAYS',
  REFRESH_WORKSHOP_WORKING_DAYS: 'REFRESH_WORKSHOP_WORKING_DAYS',
  REPLACE_CALENDAR_EVENT: 'REPLACE_CALENDAR_EVENT',
  REFRESH_WORKSHOP_CALENDAR_EVENTS: 'REFRESH_WORKSHOP_CALENDAR_EVENTS',
  LOAD_WORKSHOP_WORKLOAD: 'LOAD_WORKSHOP_WORKLOAD',
  WORKSHOP_ADD_BARCODE_SUCCESS: 'WORKSHOP_ADD_BARCODE_SUCCESS',
  WORKSHOP_ADD_BARCODE_CLEAR: 'WORKSHOP_ADD_BARCODE_CLEAR',
  WORKSHOP_LOAD_BARCODES: 'WORKSHOP_LOAD_BARCODES',
  WORKSHOP_LOAD_AC_BARCODES: 'WORKSHOP_LOAD_AC_BARCODES',
  POTENTIAL_SUSPICIOUS_WORKSHOP_DATA: 'POTENTIAL_SUSPICIOUS_WORKSHOP',
  POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR: 'POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR',
};

export const WorkshopType = {
  PARTNER: 'PARTNER',
  NON_PARTNER: 'NON_PARTNER',
};
