/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, FieldArray} from 'redux-form';
import {workshopActions} from 'shared/actions/workshop.actions';
import FileUpload from 'shared/components/form/file-upload/FileUpload';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {partConstants} from 'shared/constants/part.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {NeededPartsInquiryStatusEnum} from '../../../workshop/utils/utils';
import CountrySection from './CountrySection';

const imgMaxSizeInBytes = 1048576;
const fileTypes = ['image/jpeg', 'image/png'];

export class PartForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {category: null, confirmDeleteModalOpen: false};
    this.modalAction = this.modalAction.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch({type: partConstants.CLEAR_PART_DATA});
  }

  getCategoryName() {
    const {categories, categoryId} = this.props;
    return categories.find(category => {
      return category.id === categoryId;
    }).name;
  }

  modalAction() {
    this.props.handleDelete(this.props.selected.partId);
    this.handleCancel();
    this.props.dispatch({type: updateDataSideBarConstants.HIDE});
  }

  handleCancel() {
    this.setState({confirmDeleteModalOpen: false});
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={this.modalAction}
        handleCancel={this.handleCancel}
      />
    );
  }

  renderDeleteSection() {
    return (
      <div
        className='text-sm-right text-right'
        style={{display: 'inline-block'}}>
        {this.renderConfirmDeleteModal()}
        <Button
          id='parts-edit-form-delete-btn'
          className='btn-danger'
          onClick={() => this.setState({confirmDeleteModalOpen: true})}>
          <Translate value={'partForm.deleteBtn'} />
        </Button>
      </div>
    );
  }

  renderDeclineNeededPartInquirySection() {
    const {neededPartsInquiryId} = this.props;
    return (
      <div
        className='text-sm-right text-right'
        style={{display: 'inline-block'}}>
        <Button
          id='parts-edit-form-delete-btn'
          size='sm'
          className='btn-danger mx-1 my-1'
          style={fixedBtn}
          onClick={() => this.onClickDeclineNeededPart(neededPartsInquiryId)}>
          <Translate value={'neededPartsInquiry.chooseDecline'} />
        </Button>
      </div>
    );
  }

  onClickDeclineNeededPart(neededPartsInquiryId) {
    this.props.dispatch(
      workshopActions.changeNeededPartsInquiryStatus(
        neededPartsInquiryId,
        NeededPartsInquiryStatusEnum.DECLINED
      )
    );
    this.props.dispatch({type: updateDataSideBarConstants.HIDE});
  }

  render() {
    const {handleSubmit, handleDelete, invalid, isNeededPart} = this.props;
    return (
      <form className='p-5' onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-3 px-lg-0 text-lg-right read-only-label'>
            <Translate value={'partForm.categoryLabel'} />
          </div>
          <div className='col-lg-9' style={{fontFamily: 'Montserrat-Medium'}}>
            {this.props.categories && this.getCategoryName()}
          </div>
        </div>
        <Field
          name='name'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('partForm.nameLabel')}
          icon='fa fa-cog'
        />
        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          required={false}
          label={I18n.t('partForm.descriptionLabel')}
        />
        {userService.isAdmin() && (
          <FieldArray name='countries' component={CountrySection} />
        )}
        <Field
          name='icon'
          component={FileUpload}
          className='main-btn'
          maxSizeInBytes={imgMaxSizeInBytes}
          fileTypes={fileTypes}
          accept={'image/*'}
          keyGroup={'parts'}
        />
        <div className='text-sm-right text-right'>
          <Button
            id='parts-edit-form-submit-btn'
            type='submit'
            className='main-btn'
            disabled={invalid}>
            <Translate
              value={
                isNeededPart
                  ? 'neededPartsInquiry.chooseAccept'
                  : handleDelete
                  ? 'partForm.editBtn'
                  : 'partForm.saveBtn'
              }
            />
          </Button>
          <Button
            id='parts-edit-form-cancel-btn'
            className='main-btn'
            onClick={() =>
              this.props.dispatch({type: updateDataSideBarConstants.HIDE})
            }>
            <Translate value={'partForm.cancelBtn'} />
          </Button>
          {isNeededPart && this.renderDeclineNeededPartInquirySection()}
          {handleDelete && this.renderDeleteSection()}
        </div>
      </form>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};

PartForm.propTypes = {
  isNeededPart: PropTypes.bool.isRequired,
};

PartForm.defaultProps = {
  isNeededPart: false,
};
