/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {languageConstants} from '../constants/language.constants';
import {languageService} from '../services/language.service';
import {messageActions} from './message.actions';

export const languageActions = {
  findAllLanguageSyncLogs,
  createLanguage,
  findAllLanguages,
  findLanguageById,
  updateLanguage,
  deleteLanguageById,
  updateLanguageValues,
};

function findAllLanguageSyncLogs() {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_SYNC_LOGS_REQUEST_START});

    languageService.findAllLanguageSyncLogs().then(
      response => {
        dispatch({
          type: languageConstants.LOAD_LANGUAGE_SYNC_LOGS,
          payload: response,
        });
        dispatch({type: languageConstants.LANGUAGE_SYNC_LOGS_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languageSyncLogs.fetchError'));
        dispatch({type: languageConstants.LANGUAGE_SYNC_LOGS_REQUEST_END});
      }
    );
  };
}

function createLanguage(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_REQUEST_START});

    languageService.createLanguage(data).then(
      response => {
        dispatch({type: languageConstants.REFRESH_LANGUAGES});
        dispatch(messageActions.successMessage('languages.createSuccess'));
        onSuccess();
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      },
      error => {
        if (error.response.status === 409) {
          dispatch(
            messageActions.errorMessage('languages.languageExistsCreateError')
          );
        } else {
          dispatch(messageActions.errorMessage('languages.createError'));
        }
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      }
    );
  };
}

function findAllLanguages() {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGES_REQUEST_START});

    languageService.findAllLanguages().then(
      response => {
        dispatch({type: languageConstants.LOAD_LANGUAGES, payload: response});
        dispatch({type: languageConstants.LANGUAGES_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languages.fetchError'));
        dispatch({type: languageConstants.LANGUAGES_REQUEST_END});
      }
    );
  };
}

function findLanguageById(languageId) {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_REQUEST_START});

    languageService.findLanguageById(languageId).then(
      response => {
        dispatch({type: languageConstants.LOAD_LANGUAGE, payload: response});
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languages.fetchSingleError'));
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      }
    );
  };
}

function updateLanguage(languageId, data) {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_REQUEST_START});

    languageService.updateLanguage(languageId, data).then(
      response => {
        dispatch({type: languageConstants.LOAD_LANGUAGE, payload: response});
        dispatch({type: languageConstants.REFRESH_LANGUAGES});
        dispatch(
          messageActions.successMessage('languages.updateSingleSuccess')
        );
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languages.updateSingleError'));
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      }
    );
  };
}

function deleteLanguageById(languageId, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_REQUEST_START});

    languageService.deleteLanguageById(languageId).then(
      response => {
        dispatch({type: languageConstants.CLEAR_LANGUAGE});
        dispatch({type: languageConstants.REFRESH_LANGUAGES});
        dispatch(messageActions.successMessage('languages.deleteSuccess'));
        onSuccess();
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languages.deleteError'));
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      }
    );
  };
}

function updateLanguageValues(languageId, data) {
  return dispatch => {
    dispatch({type: languageConstants.LANGUAGE_REQUEST_START});

    languageService.updateLanguageValues(languageId, data).then(
      response => {
        dispatch({type: languageConstants.LOAD_LANGUAGE, payload: response});
        dispatch({type: languageConstants.REFRESH_LANGUAGES});
        dispatch(
          messageActions.successMessage('languages.updateSingleSuccess')
        );
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('languages.updateSingleError'));
        dispatch({type: languageConstants.LANGUAGE_REQUEST_END});
      }
    );
  };
}
