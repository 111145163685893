/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import { InputText } from "primereact/inputtext";
import React, { forwardRef, useState } from "react";
import { I18n, Translate } from "react-redux-i18n";
import ReactTooltip from "react-tooltip";
import { Tooltip } from "reactstrap";
import { errorColor } from "shared/utils/style.utils";

import "../../../input/text-input/text-input.css";
import "../text-field.css";

export const RenderField = forwardRef((props, ref) => {
  const {
    onChange,
    focusEffect,
    name,
    label,
    placeholder,
    value,
    error,
    required,
    type,
    withTooltip,
    tooltipContent,
    additionalInfoContent,
    prefixIcon,
    disabled,
    tooltipClassName,
    readOnly,
    validateOnBlur,
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [inputFiled, setInputFiled] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  let onBlurValidationCriteria = validateOnBlur? isTouched : true;
  
  const toggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const removeDotsFromInputNameAndJoin = (name) => {
    return withTooltip ? name.split(".").join("") : name;
  };

  return (
    <div className="row mt-2" style={{ marginTop: "2.5rem" }}>
      <div className="col-12">
        <div className="flex">
          {additionalInfoContent && (
            <span>
              <span
                id="additional-info-icon"
                data-tip=""
                data-for="additional-info-icon"
                style={{ margin: ".5em .25em 0 -1.5em" }}
              >
                <i
                  className="fa fa-info-circle mr-1"
                  style={{ color: "#2A3FAB" }}
                />
              </span>
              <ReactTooltip
                id="additional-info-icon"
                className="form-tooltip-short"
                effect="solid"
                globalEventOff="click"
                place="top"
                getContent={additionalInfoContent}
              />
            </span>
          )}
          {prefixIcon && (
            <img
              src={prefixIcon}
              alt="prefixIcon"
              style={{ height: "24px", width: "24px" }}
            />
          )}
          <div className="text-input-container ui-float-label">
            <label
              htmlFor={removeDotsFromInputNameAndJoin(name)}
              style={{ color: error && onBlurValidationCriteria && errorColor }}
              className="field-label"
            >
              {label}
              {required && <span style={{ color: errorColor }}>*</span>}
            </label>
            <InputText
              ref={ref}
              readOnly={readOnly}
              value={value}
              name={name}
              placeholder={placeholder}
              onFocus={focusEffect}
              onChange={onChange}
              onBlur={() => validateOnBlur && setIsTouched(true)}
              id={removeDotsFromInputNameAndJoin(name)}
              type={type}
              className={"w-100" + (inputFiled ? " ui-state-filled-hack" : "")}
              disabled={disabled}
              style={{ borderBottomColor: error && onBlurValidationCriteria && errorColor }}
            />
          </div>
          {withTooltip && (
            <Tooltip
              innerClassName={`custom-tooltip ${
                tooltipClassName ? tooltipClassName : ""
              }`}
              placement="top"
              isOpen={tooltipOpen}
              target={removeDotsFromInputNameAndJoin(name)}
              toggle={toggle}
            >
              <Translate value={tooltipContent} />
            </Tooltip>
          )}
        </div>
      </div>
      <div id="error-msg" className="col-12" style={{ minHeight: 24 }}>
        {error && error.type === "required" && (
          <span className="error-info">
            <Translate value={"validation.required"} />
          </span>
        )}
        {error && error.type === "phoneNumber" && (
          <span className="error-info">
            <Translate value={"validation.phoneNumber"} />
          </span>
        )}
        {error && error.type === "maxLength" && (
          <span className="error-info">
            <Translate value={"validation.maxLengthInfo"} />
          </span>
        )}
        {error && onBlurValidationCriteria && error.type === "pattern" && (
          <span className="error-info">
            <Translate value={"validation.badFormat"} />
          </span>
        )}
        {error && error.type === "email" && (
          <span className="error-info">
            <Translate value={"validation.email"} />
          </span>
        )}
        {error && error.type === "min" && (
          <span className="error-info">
            <Translate value={"validation.minRangeInfo"} />
          </span>
        )}
        {error && error.type === "max" && (
          <span className="error-info">
            <Translate value={"validation.maxRangeInfo"} />
          </span>
        )}
      </div>
    </div>
  );
});
