/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import axios from 'axios';
import {apiUrl} from '../../environment';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';

export const selectedLanguageKey = 'selected_language';

export function initI18n(store) {
  syncTranslationWithStore(store);
  store.dispatch(loadI18n());
}

function loadI18n() {
  const translationsKey = 'translations';
  const cachedTranslations = localStorage.getItem(translationsKey);

  return dispatch => {
    if (cachedTranslations) dispatch(process(JSON.parse(cachedTranslations)));

    return axios
      .get(`${apiUrl}/language/languages/web/packages`)
      .then(response => {
        const fetchedTranslations = response.data;
        dispatch(process(fetchedTranslations));
        localStorage.setItem(
          translationsKey,
          JSON.stringify(fetchedTranslations)
        );
      });
  };
}

function process(translations) {
  return dispatch => {
    const defaultLocale = 'pl';
    const selectedLanguage = localStorage.getItem(selectedLanguageKey);
    const detectedLocale = selectedLanguage
      ? selectedLanguage
      : detectBrowserLanguage() || defaultLocale;
    const resolvedLocale = translations.hasOwnProperty(
      getLanguageFromLocale(detectedLocale)
    )
      ? detectedLocale
      : defaultLocale;
    dispatch(loadTranslations(translations));
    dispatch(setLocale(getLanguageFromLocale(resolvedLocale)));
    moment.locale(resolvedLocale);
  };
}

function detectBrowserLanguage() {
  return (
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  );
}

function getLanguageFromLocale(locale) {
  return locale.substring(0, 2);
}

export function getDecimalSeparator() {
  return I18n.t('renderField.decimalSeparator') !== 'decimalSeparator'
    ? I18n.t('renderField.decimalSeparator')
    : '.';
}
