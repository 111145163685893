/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {Header} from 'shared/components/header/Header';
import {Message} from 'shared/components/message/Message';

export const withPublicViewCore = WrappedComponent => {
  class PublicViewHoc extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Fragment>
          <Header location={this.props.location} />
          <div className='content px-0'>
            <Message noMargin={true} />
            <WrappedComponent {...this.props} />
          </div>
        </Fragment>
      );
    }
  }

  return PublicViewHoc;
};
