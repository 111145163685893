/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {partConstants} from '../constants/part.constants';
import {partService} from '../services/part.service';
import {messageActions} from './message.actions';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';
import {getErrorMessage} from '../utils/utils';

export const partActions = {
  findAllPartsByCategoryId,
  createPart,
  findPartById,
  updatePart,
  deletePart,
};

function findAllPartsByCategoryId(categoryId) {
  return dispatch => {
    dispatch({type: partConstants.PART_REQUEST_START});

    return partService.findAllPartsByCategoryId(categoryId).then(
      response => {
        dispatch({type: partConstants.LOAD_PARTS, payload: response});
        dispatch({type: partConstants.PART_REQUEST_END});
      },
      error => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.errorMessage('parts.fetchErrorMsg'));
      }
    );
  };
}

function createPart(data) {
  return dispatch => {
    dispatch({type: partConstants.PART_REQUEST_START});

    return partService.createPart(data).then(
      response => {
        dispatch(messageActions.successMessage('parts.createSuccessMsg'));
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: partConstants.REFRESH_PART_DATA});
      },
      error => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.errorMessage('parts.createErrorMsg'));
      }
    );
  };
}

function findPartById(partId) {
  return dispatch => {
    dispatch({type: partConstants.PART_REQUEST_START});

    return partService.findPartById(partId).then(
      response => {
        dispatch({type: partConstants.LOAD_PART, payload: response});
        dispatch({type: partConstants.PART_REQUEST_END});
      },
      error => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.errorMessage('parts.partFetchErrorMsg'));
      }
    );
  };
}

function updatePart(partId, data) {
  return dispatch => {
    dispatch({type: partConstants.PART_REQUEST_START});

    return partService.updatePart(partId, data).then(
      response => {
        dispatch({type: partConstants.LOAD_PART, payload: response});
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.successMessage('parts.editSuccessMsg'));
        dispatch({type: partConstants.REFRESH_PART_DATA});
      },
      error => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.errorMessage(getErrorMessage(error, 'parts')));
      }
    );
  };
}

function deletePart(partId) {
  return dispatch => {
    dispatch({type: partConstants.PART_REQUEST_START});

    return partService.deletePart(partId).then(
      response => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(messageActions.successMessage('parts.deleteSuccessMsg'));
        dispatch({type: partConstants.REFRESH_PART_DATA});
      },
      error => {
        dispatch({type: partConstants.PART_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'parts', 'deleteErrorMsg')
          )
        );
      }
    );
  };
}
