/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {apiUrl, loyaltyProgramSystemNotificationsRefreshIntervalMillis} from "../../../../environment";
import {workshopLoyaltyProgramActions} from "../../../actions/workshopLoyaltyProgram.actions";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {I18n, Translate} from "react-redux-i18n";
import {
  workshopLoyaltyProgramNotificationsConstants
} from "../../../constants/workshopLoyaltyProgramNotifications.constants";
import {history} from '../../../utils/history';
import {Checkbox} from "primereact/checkbox";
import {statuteActions} from "../../../actions/statute.actions";
import {useLocation} from "react-router-dom";

export const WorkshopLoyaltyProgramNotificationModal = () => {
  const dispatch = useDispatch();

  const workshopData = useSelector(state => state.workshop.data);
  const modalNotificationsToShow = useSelector(state => state.workshopLoyaltyProgram.modalNotificationsToShow);
  const isLoyaltyStatuteModalOpen = useSelector(state => state.statute.isLoyaltyStatuteModalOpen);
  const [currentlyShown, setCurrentlyShown] = useState([]);

  const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
  const [isStatuteAccepted, setIsStatuteAccepted] = useState(false);
  const [newStatuteAcceptationError, setNewStatuteAcceptationError] = useState(false);
  const [notificationsThatWasShown, setNotificationsThatWasShown] = useState([]);
  const prevModalNotificationsToShowRef = useRef([]);

  useEffect(() => {
    dispatch(statuteActions.findLoyaltyProgramStatutes());
    const fetchModalNotificationsInterval = setInterval(() => {
      dispatch(workshopLoyaltyProgramActions.getModalNotificationsToShow());
    }, loyaltyProgramSystemNotificationsRefreshIntervalMillis);
    return () => clearInterval(fetchModalNotificationsInterval);
  }, []);

  useEffect(() => {
    if(modalNotificationsToShow && modalNotificationsToShow.length > 0) {
      let newNotifications = [];
      prevModalNotificationsToShowRef.current = modalNotificationsToShow;

      modalNotificationsToShow.forEach(notification => {
        const wasOnPrevList = prevModalNotificationsToShowRef.current.find(prevItem => prevItem.identifier === notification.identifier);
        if(!wasOnPrevList){
          const updatedWasShownNotifications = notificationsThatWasShown.filter(notification => notification.identifier !== notification.identifier);
          setNotificationsThatWasShown(updatedWasShownNotifications);
        }

        if(!isModalOpen(notification) && !notificationsThatWasShown.find(item => item === notification.identifier)) {
          newNotifications.push(notification.identifier);
        }
      });
      setCurrentlyShown([...currentlyShown, ...newNotifications]);
    }
    else {
      setNotificationsThatWasShown([]);
    }
  }, [modalNotificationsToShow]);

  const isModalOpen = (notification) => {
    return !!currentlyShown.find(item => item === notification.identifier);
  }

  const hideModal = (notification) => {
    setCurrentlyShown(currentlyShown.filter(item => item !== notification.identifier));
  }

  const getModalCharacteristic = (notification) => {
    const markModalAsRead = () => {
      dispatch(workshopLoyaltyProgramActions.markModalNotificationAsRead(notification.id, () => {
        dispatch(workshopLoyaltyProgramActions.getModalNotificationsToShow());
      }));
    }
    let acceptButton = 'confirmModal.confirmBtn';
    let cancelButton = 'confirmModal.cancelBtn';
    let acceptAction = () => {
      hideModal(notification);
      markModalAsRead();
      setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
    };
    let cancelAction = () => {
      hideModal(notification);
      markModalAsRead();
      setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
    }
    let renderCustomElement = () => {
      return <></>
    };
    let withCancelAction = true;

    switch(notification.identifier) {
      case workshopLoyaltyProgramNotificationsConstants.POINTS_DEADLINE_CLOSE_WARNING:
        acceptButton = 'workshopLoyaltyProgram.notifications.usePoints';
        acceptAction = () => {
          hideModal(notification);
          history.push('/workshop/workshop-loyalty-program');
          dispatch(workshopLoyaltyProgramActions.setActiveWorkshopLoyaltyProgramTile('ProductsForPoints'));
          setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
          markModalAsRead();
        };
        cancelButton = 'workshopLoyaltyProgram.cancel';
        break;
      case workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE:
        acceptButton = 'workshopLoyaltyProgram.statuteAccepted';
        renderCustomElement = () => {
          return (
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                classname='mr-1'
                onChange={(event) => {
                  setIsStatuteAccepted(event.checked);
                  (newStatuteAcceptationError && event.checked) ? setNewStatuteAcceptationError(false) : null;
                }}
                checked={isStatuteAccepted}
                value={isStatuteAccepted}
              />
              <span style={{ paddingLeft: 9 }}>{I18n.t('workshopLoyaltyProgram.register.statuteAccepted')}</span>
              {loyaltyProgramStatute &&
                <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
                  <span style={{ paddingLeft: 4 }}>{I18n.t('workshopLoyaltyProgram.register.statute')}</span>
                </a>}
              <div id="error-msg" className="col-12" style={{ minHeight: 24, paddingLeft: 0 }}>
                {newStatuteAcceptationError &&
                  <span className="error-info">
                <Translate value={"workshopLoyaltyProgram.register.acceptation"} />
              </span>
                }
              </div>
            </div>
          )
        }
        acceptAction = () => {
          setNewStatuteAcceptationError(!isStatuteAccepted);
          if(isStatuteAccepted) {
            hideModal(notification);
            setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
            dispatch(statuteActions.acceptNewStatute(workshopData.workshopId));
            markModalAsRead();
            setIsStatuteAccepted(false);
          }
        }
        cancelAction = () => {
          hideModal(notification);
          setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
          setIsStatuteAccepted(false);
        };
        break;
      case workshopLoyaltyProgramNotificationsConstants.NOT_ENOUGH_POINTS:
        acceptAction = () => {
          hideModal(notification);
          dispatch(workshopLoyaltyProgramActions.deleteFromModalNotificationsToShow(notification));
        };
        withCancelAction = false;
        acceptButton = 'workshopLoyaltyProgram.ok';
        break;
      case workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_REGISTERED_FOR_WORKSHOP:
        withCancelAction = false;
        acceptButton = 'workshopLoyaltyProgram.ok';
        acceptAction = () => {
          dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramPointsData(workshopData.workshopId));
          hideModal(notification);
          markModalAsRead();
          setNotificationsThatWasShown([...notificationsThatWasShown, notification]);
        }
        break;
      default:
        withCancelAction = false;
        acceptButton = 'workshopLoyaltyProgram.ok';
        break;
    }

    return {
      acceptButton,
      cancelButton,
      acceptAction,
      cancelAction,
      renderCustomElement,
      withCancelAction,
    }
  }

  const location = useLocation();

  useEffect(() => {
    const newNotifications = notificationsThatWasShown.filter(
      item => item.identifier !== workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE
    );
    setNotificationsThatWasShown(newNotifications);
    const statuteNotification = modalNotificationsToShow.find(item => item.identifier === workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE);
    if(statuteNotification) {
      setCurrentlyShown([...currentlyShown, statuteNotification.identifier]);
    }
  }, [location]);

  useEffect(()=>{
    const statuteNotification = modalNotificationsToShow.find(item => item?.identifier === workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE);
    if(statuteNotification){
      setNotificationsThatWasShown([...notificationsThatWasShown, statuteNotification]);
      dispatch(workshopLoyaltyProgramActions.markModalNotificationAsRead(statuteNotification.id, () => {
        dispatch(workshopLoyaltyProgramActions.getModalNotificationsToShow());
      }));
      hideModal(statuteNotification);
    }
  }, [isLoyaltyStatuteModalOpen])

  const renderNotification = (notification) => {
    const modalCharacteristic = getModalCharacteristic(notification);

    return (
      <div key={notification.identifier}>
        <Modal isOpen={isModalOpen(notification)} style={{border: 'none'}} centered={true}>
          <ModalHeader>
            {notification.modalNotificationHeader}
          </ModalHeader>
          <ModalBody>
            {notification.modalNotificationText && <div style={{fontFamily: 'Montserrat-Medium'}}>
              {notification.modalNotificationText}
            </div>}
            {modalCharacteristic.renderCustomElement()}
            <div className='text-sm-right text-center'>
              <Button
                onClick={() => modalCharacteristic.acceptAction()}
                id='confirm-btn'
                className='main-btn'>
                <Translate value={modalCharacteristic.acceptButton} />
              </Button>
              {modalCharacteristic.withCancelAction && <Button
                onClick={() => modalCharacteristic.cancelAction()}
                id='cancel-btn'
                className='main-btn'>
                <Translate value={modalCharacteristic.cancelButton} />
              </Button>}
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }

  return (
    <>
      {
        currentlyShown && modalNotificationsToShow && modalNotificationsToShow.map(notification => (
          <div key={notification.id ? notification.id : notification.identifier}>
            {notification.identifier === workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE
              && window.location.hash.includes('/workshop/workshop-loyalty-program')
              && !isLoyaltyStatuteModalOpen
              && !notificationsThatWasShown.find(item => item.identifier === workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE)
                ? renderNotification(notification)
                : null
            }
            {notification.identifier !== workshopLoyaltyProgramNotificationsConstants.WORKSHOP_LOYALTY_TERMS_CHANGE
              && !notificationsThatWasShown.find(item => item.identifier === notification.identifier)
              && renderNotification(notification)}
          </div>
        ))
      }
    </>
  )
}
