/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {forwardRef} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Input} from 'reactstrap';
import '../text-area.css';
import {errorColor} from 'shared/utils/style.utils';

const RenderTextArea = forwardRef((props, ref) => {
  const {disabled, readOnly, label, required, rows, error, onChange, value} =
    props;
  return (
    <div className='row mt-3'>
      <div className='col-12'>
        <span className={`field-label ${error ? 'error-color' : ''}`}>
          {label}
          {required && <span style={{color: errorColor}}>*</span>}
        </span>
      </div>
      <div className='col-12'>
        <Input
          ref={ref}
          type='textarea'
          className='custom-text-area ui-inputtext'
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          rows={rows}
          disabled={disabled}
          style={error ? {borderColor: errorColor} : null}
        />
        <div id='error-msg' className='col-12'>
        {error && error.type === 'required' && (
          <span className='error-info'>
            <Translate value={'validation.required'} />
          </span>
        )}
        {error && error.type === 'maxLength' && (
          <span className='error-info'>
            <Translate value={'validation.maxLengthInfo'} />
          </span>
        )}
        {error && error.type === 'pattern' && (
          <span className='error-info'>
            <Translate value={'validation.badFormat'} />
          </span>
        )}
        {error && error.type === 'min' && (
          <span className='error-info'>
            <Translate value={'validation.minRangeInfo'} />
          </span>
        )}
        {error && error.type === 'max' && (
          <span className='error-info'>
            <Translate value={'validation.maxRangeInfo'} />
          </span>
        )}
        </div>
      </div>
    </div>
  );
});
export default RenderTextArea;
