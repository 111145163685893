/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Checkbox} from 'primereact/checkbox';
import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {countryActions} from 'shared/actions/country.actions';
import {mapCountriesToLabelValueObjects} from 'shared/components/form/country-field/utils';
import {getOrEmptyArray} from 'shared/utils/utils';

class CountrySection extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(countryActions.findAll());
  }

  onChange(event) {
    const {fields} = this.props;
    event.checked
      ? fields.push(event.value)
      : fields.remove(getOrEmptyArray(fields.getAll()).indexOf(event.value));
  }

  renderCountry(country) {
    const {fields} = this.props;

    return (
      <div
        key={country.value}
        className='text-justify my-3'
        style={{display: 'flex', alignItems: 'center', width: '100%'}}>
        <Checkbox
          classname='mr-1'
          onChange={this.onChange}
          value={country.value}
          checked={getOrEmptyArray(fields.getAll()).indexOf(country.value) >= 0}
        />
        <div className='checkbox-label w-100'>
          <Translate value={`country.${country.label}`} />
        </div>
      </div>
    );
  }

  render() {
    const {countries} = this.props;
    const mappedCountries = mapCountriesToLabelValueObjects(countries);
    return (
      <div className='row'>
        <div className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0'>
          <h6 className='mt-3 font-bold'>
            <Translate value={'parts.countriesFieldLabel'} />
          </h6>
        </div>
        <div className='col-lg-9 col-12 my-3'>
          {mappedCountries.map(country => this.renderCountry(country))}
        </div>
      </div>
    );
  }
}

function mapStateToProps({country}) {
  const {countries} = country;
  return {countries};
}

export default connect(mapStateToProps)(CountrySection);
