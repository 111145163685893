/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {I18n} from 'react-redux-i18n';

export class LoyaltyProgramPresentationTable extends Component {
  constructor(props) {
    super(props);
  }

  getRowClassName(data) {
    if (data) return {'green-row': data.isAchieved};
  }

  render() {
    const {title, loyaltyPrograms} = this.props;
    return (
      <Fragment>
        {title && <h5 className='text-center'>{title}</h5>}
        <DataTable
          value={loyaltyPrograms}
          className='expanded-datatable'
          emptyMessage={I18n.t('loyaltyProgram.emptyPlaceholder')}
          responsive={true}
          rowClassName={this.getRowClassName}>
          <Column
            field='title'
            header={I18n.t('loyaltyProgramAccount.workshopLoyaltyPrograms')}
          />

          <Column field='points' header={I18n.t('loyaltyProgram.points')} />
        </DataTable>
      </Fragment>
    );
  }
}

LoyaltyProgramPresentationTable.propTypes = {
  title: PropTypes.string,
  loyaltyPrograms: PropTypes.array,
};

LoyaltyProgramPresentationTable.defaultProps = {
  loyaltyPrograms: [],
};
