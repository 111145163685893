/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, useState} from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import {DataTable} from "primereact/datatable";
import {I18n} from "react-redux-i18n";
import {Column} from "primereact/column";
import {updateDataSideBarConstants} from "../../../../shared/constants/update-data-side-bar.constants";
import WorkshopLoyaltyProgramSelectedNotification
  from "../workshop-loyalty-program-selected-notification/WorkshopLoyaltyProgramSelectedNotification";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "reactstrap";
import styles from './WorkshopLoyaltyProgramNotifications.module.css';
import {workshopLoyaltyProgramActions} from "../../../../shared/actions/workshopLoyaltyProgram.actions";

export const WorkshopLoyaltyProgramNotifications = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(state => state.workshopLoyaltyProgram.notifications);
  const isFetching = useSelector(state => state.workshopLoyaltyProgram.isFetching);

  const [mappedNotifications, setMappedNotifications] = useState([]);

  useEffect(() => {
    dispatch(workshopLoyaltyProgramActions.getAllNotifications());
  }, []);

  useEffect(() => {
    if(notifications && notifications.length > 0) {
      const notificationsTableData = notifications.map((notification, index) => {
        let typesString = '';
        if(notification.types && notification.types.length > 0) {
          notification.types.forEach((item, index) => {
            typesString +=  I18n.t(`workshopLoyaltyProgram.notifications.types.${item.toLowerCase()}`);
            if(index < notification.types.length - 1) {
              typesString += '\n';
            }
          })
        }
        return {
          ...notification,
          visibilityDurationSeconds: notification.visibilityDurationSeconds > 0 && !notification.shouldUserClose ? notification.visibilityDurationSeconds : '',
          shouldUserCloseString: notification.shouldUserClose ? I18n.t('workshopLoyaltyProgram.notifications.yes') : I18n.t('workshopLoyaltyProgram.notifications.no'),
          typesString: typesString,
        }
      });
      setMappedNotifications(notificationsTableData);
    }
  }, [notifications]);

  function showDetails(rowData) {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WorkshopLoyaltyProgramSelectedNotification notification={rowData} />,
      title: I18n.t("workshopLoyaltyProgram.notifications.editNotification"),
    });
  }

  return (
    <ContentWrapper title='workshopLoyaltyProgram.notifications.header' scrollY>
      <Loadable active={isFetching} spinner>
        <div className='col' style={{minWidth: 976}}>
          <DataTable
            value={mappedNotifications}
            responsiveLayout="scroll"
            paginator={false}
            rows={10}
            className='data-table-padding-class'
            responsive={true}
            emptyMessage={I18n.t('workshopLoyaltyProgram.notifications.noNotifications')}
          >
            <Column
              field='title'
              header={I18n.t('workshopLoyaltyProgram.notifications.name')}
              sortable={true}
            />
            <Column
              field='typesString'
              header={I18n.t('workshopLoyaltyProgram.notifications.typeShort')}
              sortable={true}
              style={{whiteSpace: 'pre-wrap'}}
            />
            <Column
              field='systemNotificationText'
              header={I18n.t('workshopLoyaltyProgram.notifications.textUser')}
              sortable={true}
              style={{width: '25%'}}
            />
            <Column
              field='visibilityDurationSeconds'
              header={I18n.t('workshopLoyaltyProgram.notifications.lifetime')}
              sortable={true}
            />
            <Column
              field='shouldUserCloseString'
              header={I18n.t('workshopLoyaltyProgram.notifications.shouldUserCloseShort')}
              sortable={true}
            />
            <Column
              headerStyle={{textAlign: 'center'}}
              bodyStyle={{textAlign: 'center'}}
              field=''
              body={rowData => (
                <Button className={styles.Btn} onClick={() => showDetails(rowData)}>
                  {I18n.t('workshopLoyaltyProgram.edit')}
                </Button>
              )}
              style={{width: '15%'}}
            />
          </DataTable>
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
