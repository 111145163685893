/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from "react";
import { Translate } from "react-redux-i18n";
import styles from "../WarrantyInstalationData.module.css";
import {dateUtils} from 'shared/utils/date.utils';
import EmptyFieldPlaceholder from "../../empty-field-placeholder/EmptyFieldPlaceholder";
import { useSelector } from "react-redux";

const AdditionalAssembly = () => {
  const additionalInstallationDetails = useSelector(
    (state) =>
      state.warrantyBook.initializedWarranty.additionalInstallationDetails
  );

  const renderRow = (labels) => {
    return (
      additionalInstallationDetails && (
        <div className={styles.Row}>
          {labels.map((label) => (
            <div className={styles.LabelContainer} key={label.label}>
              <Translate value={label.label} className={styles.LabelName} />
              <span className={styles.LabelValue}>{label.value}</span>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
      <div className={styles.SecondSegmentCollapsedContainer}>
        {Object.values(additionalInstallationDetails).every((x) => !x) ? (
          <EmptyFieldPlaceholder content={"warranty.noData"} />
        ) : (
          <>
            <div className={styles.SegmentTextStyle}>
              {additionalInstallationDetails.additionalInspectionDescription}
            </div>
            <div
              className={styles.Column}
              style={{ padding: "32px 32px 0px 32px", width: "100%" }}
            >
              {renderRow([
                {
                  label: "warranty.sectionII.workshopWorker",
                  value: additionalInstallationDetails.workshopWorker,
                },
                {
                  label: "warranty.sectionII.firmName",
                  value: additionalInstallationDetails.workshopName,
                },
              ])}
              {renderRow([
                {
                  label: "warranty.sectionII.address",
                  value: additionalInstallationDetails.address,
                },
                {
                  label: "warranty.sectionII.phone",
                  value: additionalInstallationDetails.phone,
                },
              ])}
              {renderRow([
                {
                  label: "warranty.sectionII.nipLabel",
                  value: additionalInstallationDetails.nip,
                },
                {
                  label: "warranty.sectionII.regonLabel",
                  value: additionalInstallationDetails.regon,
                },
              ])}
              {renderRow([
                {
                  label: "warranty.sectionII.email",
                  value: additionalInstallationDetails.email,
                },
                {
                  label: "warranty.sectionII.date",
                  value: additionalInstallationDetails.date
                    ? dateUtils.formatDate(additionalInstallationDetails.date, 'YYYY-MM-DD'): ''
                },
              ])}
            </div>
          </>
        )}
      </div>
  );
};

export default AdditionalAssembly;
