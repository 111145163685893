import {Button} from 'reactstrap';
import React, {useEffect} from 'react';
import styles from './CodeIssueForm.module.css';
import {useForm, Controller} from 'react-hook-form';
import {RenderField} from 'shared/components/form/text-field/hook-form-version/RenderField';
import {I18n, Translate} from 'react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';
import RenderDatePicker from 'shared/components/form/date-picker/hook-form-version/RenderDatePicker';
import RenderTextArea from 'shared/components/form/text-area/hook-form-version/RenderTextArea';

const CodeIssueForm = props => {
  const {onSubmit, handleCancel} = props;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: {errors},
  } = useForm();

  const nameField = 'name';
  const lastNameField = 'lastname';
  const emailField = 'email';
  const workshopField = 'workshop';
  const addressField = 'address';
  const postCodeField = 'postcode';
  const cityField = 'city';
  const countryField = 'country';
  const stateField = 'state';
  const assemblyDateField = 'assemblyDate';
  const descriptionField = 'description';

  const userData = useSelector(state => state.user.data);
  const workshopData = useSelector(
    state => state.workshop.potentialSuspiciousWorkshop
  );
  const suspiciousBarcodes = useSelector(state => state.user.infoBarcode);

  useEffect(() => {
    if (userData) {
      setValue(nameField, userData.firstName);
      setValue(lastNameField, userData.lastName);
      setValue(emailField, userData.email);
    }
  }, [userData]);

  useEffect(() => {
    if (workshopData) {
      setValue(workshopField, workshopData?.workshopName);
      setValue(addressField, workshopData?.workshopAddress);
      setValue(postCodeField, workshopData?.workshopZipcode);
      setValue(cityField, workshopData?.workshopCity);
      setValue(countryField, workshopData?.workshopCountry);
      setValue(stateField, workshopData?.workshopState);
    }
  }, [workshopData]);

  const codesString = Object.values(suspiciousBarcodes ?? {})
    ?.filter(barcodeEntry => barcodeEntry?.result === 'USER_DUPLICATE')
    ?.map(barcodeEntry => barcodeEntry?.code)
    ?.join(', ');

  return (
    <div className={`px-5 pb-5 ${styles.Container}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RenderField
          {...register(nameField, {required: true})}
          error={errors[nameField]}
          label={I18n.t('userProfileData.firstNameLabel')}
          type='text'
          required
          readOnly
        />

        <RenderField
          {...register(lastNameField, {required: true})}
          error={errors[lastNameField]}
          label={I18n.t('userProfileData.lastNameLabel')}
          type='text'
          required
          readOnly
        />
        <RenderField
          {...register(emailField, {required: true})}
          value={watch(emailField)}
          error={errors[emailField]}
          label={I18n.t('userProfileData.emailLabel')}
          type='text'
          required
          readOnly
        />

        <RenderField
          {...register(workshopField, {required: true})}
          error={errors[workshopField]}
          label={I18n.t('workshops.workshopDeletedNotificationStart')}
          type='text'
          readOnly
        />

        <RenderField
          {...register(addressField, {required: true})}
          error={errors[addressField]}
          label={I18n.t('workshopProfile.streetAddressLabel')}
          required
          type='text'
          readOnly
        />

        <RenderField
          {...register(cityField, {required: true})}
          error={errors[cityField]}
          label={I18n.t('workshopProfile.cityLabel')}
          required
          type='text'
          readOnly
        />
        <RenderField
          {...register(postCodeField, {required: true})}
          error={errors[postCodeField]}
          label={I18n.t('workshopProfile.postalCodeLabel')}
          required
          type='text'
          readOnly
        />

        <RenderField
          {...register(countryField, {required: true})}
          error={errors[countryField]}
          label={I18n.t('country.countryLabel')}
          required
          type='text'
          readOnly
        />
        <RenderField
          {...register(stateField, {required: true})}
          error={errors[stateField]}
          label={I18n.t('state.stateLabel')}
          required
          type='text'
          readOnly
        />

        <Controller
          control={control}
          name={assemblyDateField}
          rules={{required: true}}
          render={({field: {value, onChange}}) => (
            <RenderDatePicker
              selected={value}
              onChange={onChange}
              label={I18n.t('barcodes.assemblyDate')}
              error={errors[assemblyDateField]}
              required
              showClearIcon={true}
            />
          )}
        />

        <RenderField
          label={I18n.t('barcodes.subject')}
          value={`${I18n.t('barcodes.codes')}: ${codesString}`}
          required
          type='text'
          disabled
          readOnly
        />

        <Controller
          control={control}
          name={descriptionField}
          rules={{required: true}}
          render={({field: {value, onChange}}) => (
            <RenderTextArea
              rows={5}
              error={errors[descriptionField]}
              label={I18n.t('barcodes.issueDescription')}
              onChange={onChange}
              value={value}
              required
            />
          )}
        />

        <Button
          id='car-form-cancel-btn'
          className='main-btn'
          onClick={handleCancel}>
          <Translate value={'barcodes.cancel'} />
        </Button>
        <Button id='car-form-submit-btn' className='main-btn' type='submit'>
          <Translate value={'barcodes.send'} />
        </Button>
      </form>
    </div>
  );
};

export default CodeIssueForm;
