/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { I18n } from "react-redux-i18n";
import { Column } from "primereact/column";
import { updateDataSideBarConstants } from "../../../../shared/constants/update-data-side-bar.constants";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { Translate } from 'react-redux-i18n';
import WorkshopLoyaltyProgramModal from '../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Input } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { rowsQuantityToDisplay } from 'shared/utils/utils';
import styles from './PrizeProducts.module.css';
import PrizeProductsModal from './prize-products-modal/PrizeProductsModal';

export const PrizeProducts = () => {
  const dispatch = useDispatch();

  const prizeProducts = useSelector(state => state.workshopLoyaltyProgram.prizeProducts);
  const isFetching = useSelector(state => state.workshopLoyaltyProgram.prizeProductsFetching);

  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [first, setFirst] = useState(0)
  const [globalFilter, setGlobalFilter] = useState();

  useEffect(() => {
    dispatch(workshopLoyaltyProgramActions.prizeProductList({
      page: page - 1,
      size: pageLimit,
      sort: (sortField && sortDirection) && `${sortField},${sortDirection}`,
      search: globalFilter
    }));
  }, [page, pageLimit, sortField, sortDirection, globalFilter]);

  return (
    <ContentWrapper title='workshopLoyaltyProgram.prizeProducts.header' scrollY>
      <Loadable active={isFetching} spinner>
        <div className='col' style={{minWidth: 976}}>
          <DataTable
            value={prizeProducts.content}
            rows={pageLimit}
            className='data-table-padding-class'
            responsive={true}
            emptyMessage={I18n.t('workshopLoyaltyProgram.prizeProducts.noPrizeProducts')}
            paginator={false}
            onSort={e => {
              setPage(1);
              setFirst(0);
              setSortField(e.sortField);
              setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
            }}
            header={
              <div style={{
                width: 320, position: 'relative',
                float: 'right'
              }}>
                <Input
                  type='search'
                  placeholder={I18n.t('workshops.searchPlaceholder')}
                  onInput={e => {
                    setFirst(0);
                    setPage(1);
                    setSortField(e.sortField);
                    setGlobalFilter(e.target.value);
                  }}
                  className='global-filter'
                />
              </div>
            }
          >
            <Column
              field='productTypeName'
              header={I18n.t('workshopLoyaltyProgram.prizeProducts.productType')}
              sortable={true}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='productName'
              header={I18n.t('workshopLoyaltyProgram.prizeProducts.productName')}
              sortable={true}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='description'
              header={I18n.t('workshopLoyaltyProgram.prizeProducts.shortDescription')}
              sortable={false}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='value'
              header={I18n.t('workshopLoyaltyProgram.prizeProducts.productValue')}
              sortable={true}
            />
            <Column
              headerStyle={{ textAlign: 'center' }}
              bodyStyle={{ textAlign: 'center' }}
              field=''
              body={rowData => (
                <>
                  <Button className={styles.Btn} onClick={() => dispatch({
                    type: updateDataSideBarConstants.SHOW,
                    variableComponent: (
                      <PrizeProductsModal
                        product={rowData}
                        pageSize={pageLimit}
                        setPage={()=> {setPage(1); setFirst(0)}}
                        />
                    ),
                    title: I18n.t("workshopLoyaltyProgram.prizeProducts.wannahEditProduct"),
                  })
                  }>
                    {I18n.t('workshopLoyaltyProgram.edit')}
                  </Button>
                  <Button className={styles.Btn} onClick={() => dispatch({
                    type: updateDataSideBarConstants.SHOW,
                    variableComponent: (
                      <WorkshopLoyaltyProgramModal
                        deletePrizeProduct
                        product={rowData}
                        pageSize={pageLimit}
                        setPage={()=> {setPage(1); setFirst(0)}}
                        />
                    ),
                    title: I18n.t("workshopLoyaltyProgram.prizeProducts.wannahDeleteProduct"),
                  })
                  }>
                    {I18n.t('workshopLoyaltyProgram.delete')}
                  </Button>
                </>
              )}
              style={{ width: '15%' }}
            />
          </DataTable>
          <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
            <Paginator
              first={first}
              rows={pageLimit}
              totalRecords={prizeProducts.totalElements}
              pageLinkSize={5}
              onPageChange={(e) => {
                setPage(e.page + 1)
                setFirst(e.first)
              }}
              leftContent={
                <Dropdown
                  options={rowsQuantityToDisplay}
                  className='data-table-rows-dropdown'
                  value={pageLimit}
                  onChange={e => { setPage(1), setPageLimit(e.value) }}
                />
              }
              rightContent={
                <Button
                  className="main-btn"
                  style={{ width: "14.7rem" }}
                  onClick={() => {
                    dispatch({
                      type: updateDataSideBarConstants.SHOW,
                      variableComponent: (
                        <WorkshopLoyaltyProgramModal createPrizeProduct pageSize={pageLimit} setPage={()=> {setPage(1); setFirst(0)}} />
                      ),
                      title: I18n.t("workshopLoyaltyProgram.prizeProducts.wannahCreateNewProduct"),
                    });
                  }}
                >
                  <Translate value={"workshopLoyaltyProgram.prizeProducts.addPrizeProduct"} />
                </Button>
              }
            ></Paginator>
            <div className={styles.NewProductBox}>
              <Button
                className="main-btn"
                style={{ width: "14.7rem" }}
                onClick={() => {
                  dispatch({
                    type: updateDataSideBarConstants.SHOW,
                    variableComponent: (
                      <WorkshopLoyaltyProgramModal createPrizeProduct isNewProduct pageSize={pageLimit} setPage={()=> {setPage(1); setFirst(0)}} />
                    ),
                    title: I18n.t("workshopLoyaltyProgram.prizeProducts.wannahCreateNewProduct"),
                  });
                }}
              >
                <Translate value={"workshopLoyaltyProgram.prizeProducts.newProduct"} />
              </Button>
              <Translate value={"workshopLoyaltyProgram.prizeProducts.newProductDescription"} />
            </div>
          </div>
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
