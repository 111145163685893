/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { createRef } from "react";
import { Translate } from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import styles from "./ScoredProductsModal.module.css";
import { useDispatch } from "react-redux";
import ScoredProductsModalForm from "./ScoredProductsModalForm";

const ScoredProductsModal = (props) => {
    const formRef = createRef();

    const dispatch = useDispatch();

    const onSave = () => {
        formRef.current.onSave();
    };

    const { product, pageSize, setPage } = props;
    return (
        <>
            <div className={styles.Container} style={{ padding: 25 }}>
                <>
                    <ScoredProductsModalForm
                        ref={formRef}
                        product={product}
                        pageSize={pageSize}
                        setPage={setPage}
                    />
                    <div className={styles.buttonPanel}>
                        <Button className="main-btn" onClick={onSave}>
                            <Translate value={"workshopLoyaltyProgram.confirm"} />
                        </Button>
                        <Button className="main-btn" onClick={() => dispatch({
                            type: updateDataSideBarConstants.HIDE,
                        })}>
                            <Translate value={"workshopLoyaltyProgram.cancel"} />
                        </Button>
                    </div>
                </>
            </div>
        </>
    );
};

export default ScoredProductsModal;