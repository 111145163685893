/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateCity,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';

export const validate = (values, props) => {
  const errors = {};
  errors.userFirstName = props.isValuationPublic
    ? validateByLengthOnly(values.userFirstName, 60)
    : validateFirstName(values.userFirstName);
  errors.userLastName = props.isValuationPublic
    ? validateByLengthOnly(values.userLastName, 60)
    : validateLastName(values.userLastName);
  errors.userCity = validateCity(values.userCity);
  errors.country = validateRequiredFields(values.country);
  errors.state = validateRequiredFieldsWithMaxLength(values.state, 255);
  errors.userEmail = validateEmail(values.userEmail);
  errors.userPhoneNumber = validatePhoneNumber(
    values.userPhoneNumber,
    !props.isValuationPublic
  );
  errors.userRemarks = validateByLengthOnly(values.userRemarks, 255);
  return errors;
};
