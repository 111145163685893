/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {statuteActions} from 'shared/actions/statute.actions';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {privilegeConstants} from 'shared/constants/privilege.constants';
import {userService} from 'shared/services/user.service';
import {apiUrl} from '../../../../environment';
import './statute-details.css';
import {Document, Page} from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class StatuteDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1,
      renderDocument: false,
      displayDocument: 'none',
      documentIsFetching: true,
      confirmDeleteModalOpen: false,
    };
    this.onDocumentLoad = this.onDocumentLoad.bind(this);
    this.markStatuteValid = this.markStatuteValid.bind(this);
    this.deleteStatute = this.deleteStatute.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({renderDocument: true}), 200);
    setTimeout(() => this.setState({displayDocument: 'flex'}), 300);
  }

  onDocumentLoad({numPages}) {
    this.setState({numPages});
    setTimeout(() => this.setState({documentIsFetching: false}), 1000);
  }

  markStatuteValid() {
    this.props.markStatuteValid(this.props.statute.id);
  }

  deleteStatute() {
    this.props.deleteStatute(this.props.statute.id);
  }

  renderConfirmDeleteModal() {
    const title =
      this.props.statute.status === 'VALID'
        ? 'statutes.confirmDeleteValidStatute'
        : 'statutes.confirmDeleteInvalidStatute';
    return (
      <ConfirmModal
        title={title}
        isOpen={this.state.confirmDeleteModalOpen}
        action={this.deleteStatute}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  shouldRenderMarkValidButton(statute) {
    const isCorrectStatus = statute && statute.status === 'INVALID';
    const hasAccess =
      userService.isAdmin() ||
      (userService.isDistributor() &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT));
    return isCorrectStatus && hasAccess;
  }

  renderMarkValidButton() {
    return (
      <div className='text-sm-right text-center my-2'>
        <Button
          id='mark-statute-valid-btn'
          className='main-btn'
          style={{whiteSpace: 'normal'}}
          onClick={this.markStatuteValid}>
          <Translate value='statutes.markValid' />
        </Button>
      </div>
    );
  }

  renderDocument() {
    const {statute} = this.props;

    return (
      <div
        style={{display: this.state.displayDocument, 
          alignItems: "center", 
          justifyContent: "center", 
          flexDirection:'column',
          marginTop: 10
          }}>
            {this.shouldRenderMarkValidButton(statute) &&
              this.renderMarkValidButton()}
        <Loadable active={this.state.documentIsFetching} spinner>
          <Document
            file={apiUrl + '/workshop/statutes/' + statute.id + '/file'}
            onLoadSuccess={this.onDocumentLoad}
          >
            <Page pageNumber={this.state.pageNumber} />
          </Document>
        </Loadable>
        <div className='row my-2 text-sm-right text-center'>
          <div className='col'>
            <Translate className='mr-1 read-only-label' value='statutes.page' />
            {this.state.pageNumber}/{this.state.numPages}
            <Button
              id='page-minus'
              className='main-btn'
              disabled={this.state.pageNumber === 1}
              onClick={() =>
                this.setState({pageNumber: this.state.pageNumber - 1})
              }>
              <i className='fa fa-arrow-left' />
            </Button>
            <Button
              id='page-plus'
              className='main-btn'
              disabled={this.state.pageNumber === this.state.numPages}
              onClick={() =>
                this.setState({pageNumber: this.state.pageNumber + 1})
              }>
              <i className='fa fa-arrow-right' />
            </Button>
            <a href={apiUrl + '/workshop/statutes/' + statute.id + '/file'}>
              <Button id='download-statute' className='main-btn'>
                <Translate value='statutes.download' />
              </Button>
            </a>
            <Button
              id='delete-statute'
              className='btn-danger'
              onClick={() => this.setState({confirmDeleteModalOpen: true})}>
              <Translate value='statutes.statuteDeleteBtn' />
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {statute} = this.props;

    return (
      <div className='service-edit-form px-sm-5 px-sm-0 pb-2'>
        {statute && this.state.renderDocument && this.renderDocument()}
        {statute && this.renderConfirmDeleteModal()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;
  const statute = state.statute.statute;

  return {i18n, statute};
}

const mapDispatchToProps = {
  markStatuteValid: statuteActions.markStatuteValid,
  deleteStatute: statuteActions.deleteStatute,
};

const connectedStatuteDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatuteDetails);
export {connectedStatuteDetails as StatuteDetails};
