/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {getComponentDisplayName} from '../utils/utils.hoc';
import {countryActions} from '../../actions/country.actions';
import {getPhonePrefixByCountry} from '../../components/form/phone-number/utils/utils';
import {change} from 'redux-form';
import {connect} from 'react-redux';

export const withPhoneNumberPrefixHoc = WrappedComponent => {
  class PhoneNumberPrefixHoc extends React.Component {
    constructor(props) {
      super(props);
      this.initializePhonePrefixByCountry =
        this.initializePhonePrefixByCountry.bind(this);
      this.initializePhonePrefixesByCountry =
        this.initializePhonePrefixesByCountry.bind(this);
      this.shouldInitializePhonePrefix =
        this.shouldInitializePhonePrefix.bind(this);
      this.shouldInitializePhonePrefixes =
        this.shouldInitializePhonePrefixes.bind(this);
      this.shouldInitializeNestedPhonePrefixes =
        this.shouldInitializeNestedPhonePrefixes.bind(this);
    }

    componentDidMount() {
      const {dispatch} = this.props;
      dispatch(countryActions.findAll());
    }

    initializePhonePrefixByCountry(formName, country) {
      const {dispatch, countries} = this.props;
      dispatch(
        change(
          formName,
          'phoneNumber.phoneNumberPrefix',
          getPhonePrefixByCountry(country, countries)
        )
      );
    }

    initializePhonePrefixesByCountry(formName, country, innerFields = false) {
      const {dispatch, countries} = this.props;
      const phonePrefix = getPhonePrefixByCountry(country, countries);
      const fieldPath = innerFields ? 'details.' : '';
      dispatch(
        change(
          formName,
          `${fieldPath}phoneNumber.phoneNumberPrefix`,
          phonePrefix
        )
      );
      dispatch(
        change(
          formName,
          `${fieldPath}mobilePhoneNumber.phoneNumberPrefix`,
          phonePrefix
        )
      );
      dispatch(
        change(formName, `${fieldPath}faxNumber.phoneNumberPrefix`, phonePrefix)
      );
    }

    shouldInitializePhonePrefix(prevProps, formValues) {
      const {countries} = this.props;
      return (
        countries &&
        formValues &&
        prevProps &&
        prevProps.formValues &&
        prevProps.formValues.country !== formValues.country &&
        (!formValues.phoneNumber || !formValues.phoneNumber.phoneNumber)
      );
    }

    shouldInitializePhonePrefixes(prevProps, formValues) {
      const {countries} = this.props;
      return (
        countries &&
        formValues &&
        formValues.country &&
        prevProps.formValues &&
        prevProps.formValues.country !== formValues.country &&
        (!formValues.phoneNumber || !formValues.phoneNumber.phoneNumber) &&
        (!formValues.mobilePhoneNumber ||
          !formValues.mobilePhoneNumber.phoneNumber) &&
        (!formValues.faxNumber || !formValues.faxNumber.phoneNumber)
      );
    }

    shouldInitializeNestedPhonePrefixes(prevProps, formValues) {
      const {countries} = this.props;
      return (
        countries &&
        formValues &&
        formValues.details &&
        prevProps.formValues &&
        prevProps.formValues.details &&
        prevProps.formValues.country !== formValues.country &&
        (!formValues.details.phoneNumber ||
          !formValues.details.phoneNumber.phoneNumber) &&
        (!formValues.details.mobilePhoneNumber ||
          !formValues.details.mobilePhoneNumber.phoneNumber) &&
        (!formValues.details.faxNumber ||
          !formValues.details.faxNumber.phoneNumber)
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          initializePhonePrefixByCountry={this.initializePhonePrefixByCountry}
          initializePhonePrefixesByCountry={
            this.initializePhonePrefixesByCountry
          }
          shouldInitializeNestedPhonePrefixes={
            this.shouldInitializeNestedPhonePrefixes
          }
          shouldInitializePhonePrefixes={this.shouldInitializePhonePrefixes}
          shouldInitializePhonePrefix={this.shouldInitializePhonePrefix}
        />
      );
    }
  }

  const mapStateToProps = ({country}) => {
    const {countries} = country;
    return {countries};
  };

  const connectedComponent = connect(mapStateToProps)(PhoneNumberPrefixHoc);
  connectedComponent.displayName = `WithPhoneNumberPrefixHoc(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return connectedComponent;
};
