/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import ValuationInquiryFormSchemaPage2 from '../../../../../valuation-inquiry/valuation-inquiry-form/page-2/ValuationInquiryFormSchemaPage2';
import {validate} from '../../../../../valuation-inquiry/valuation-inquiry-form/page-2/validators';

const ValuationDirectClientsPage2 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage2);

const selector = formValueSelector('valuationDirectClientsForm');

function mapStateToProps(state) {
  const {i18n, car} = state;
  const carBrandFieldValue = selector(state, 'carBrand');
  const carModelFieldValue = selector(state, 'carModel');
  const carDataTypeValue = selector(state, 'carDataType');
  const carFuelTypeIdFieldValue = selector(state, 'carFuelTypeId');
  const {fuelTypes, carBrands, userCars} = car;
  const displayCarName = false;

  return {
    i18n,
    carBrandFieldValue,
    carModelFieldValue,
    carDataTypeValue,
    fuelTypes,
    carBrands,
    userCars,
    displayCarName,
    carFuelTypeIdFieldValue,
  };
}

export default connect(mapStateToProps)(ValuationDirectClientsPage2);
