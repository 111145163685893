/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import '../valuation-inquiry-form.css';
import {validate} from './validators';
import ValuationInquiryFormSchemaPage1 from './ValuationInquiryFormSchemaPage1';

const ValuationInquiryFormPage1 = reduxForm({
  form: 'valuationInquiryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage1);

function mapStateToProps(state) {
  const {i18n, service, form} = state;
  const {categories} = service;
  const valuationForm = form.valuationInquiryForm;

  return {i18n, categories, valuationForm};
}

export default connect(mapStateToProps)(ValuationInquiryFormPage1);
