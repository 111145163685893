/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const settingsConstants = {
  SETTINGS_REQUEST_START: 'SETTINGS_REQUEST_START',
  SETTINGS_REQUEST_END: 'SETTINGS_REQUEST_END',

  REFRESH_SETTINGS_DATA: 'REFRESH_SETTINGS_DATA',

  LOAD_SETTINGS: 'LOAD_SETTINGS',

  WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START: 'WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START',
  WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END: 'WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END',
  LOAD_WORKSHOP_LOYALTY_PROGRAM_EDITOR: 'LOAD_WORKSHOP_LOYALTY_PROGRAM_EDITOR',
  REFRESH_WORKSHOP_LOYALTY_PROGRAM_EDITOR: 'REFRESH_WORKSHOP_LOYALTY_PROGRAM_EDITOR'
};
