/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import UserAvatar from 'layout/user/user-profile/avatar/UserAvatar';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {change, Field} from 'redux-form';
import renderField from '../../form/text-field/RenderField';
import {ConfirmModal} from '../../modal/ConfirmModal';

export class WorkerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDeleteModalOpen: false,
    };

    this.onUpdateAvatar = this.onUpdateAvatar.bind(this);
  }

  onUpdateAvatar(avatarDto) {
    const {dispatch, formName} = this.props;
    dispatch(change(formName, 'avatar', avatarDto.avatar));
  }

  renderFields() {
    const {avatarFieldValue} = this.props;
    const avatar = avatarFieldValue
      ? `data:image/png;base64,${avatarFieldValue}`
      : null;

    return (
      <Fragment>
        <UserAvatar
          disableInternalUpdate={true}
          onUpdateAvatar={this.onUpdateAvatar}
          disableInternalAvatar={true}
          externalAvatar={avatar}
        />

        <Field
          name='firstName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('worker.firstName')}
          icon='fa fa-star'
        />

        <Field
          name='lastName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('worker.lastName')}
          icon='fa fa-star'
        />

        <Field name='avatar' component={props => <div className='d-none' />} />
      </Fragment>
    );
  }

  renderButtons() {
    const {invalid, handleCancel} = this.props;

    return (
      <Fragment>
        <Button
          id='worker-form-submit-btn'
          className='main-btn fixed-width-btn'
          type='submit'
          disabled={invalid}>
          <Translate value={'worker.saveBtn'} />
        </Button>

        <Button
          id='worker-form-cancel-btn'
          className='main-btn fixed-width-btn'
          onClick={handleCancel}>
          <Translate value={'worker.cancelBtn'} />
        </Button>

        {this.renderDeleteSection()}
      </Fragment>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete()}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      this.props.handleDelete && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmDeleteModal()}
          <Button
            id='worker-form-delete-btn'
            className='btn-danger fixed-width-btn'
            onClick={() => this.setState({confirmDeleteModalOpen: true})}>
            <Translate value={'worker.deleteBtn'} />
          </Button>
        </div>
      )
    );
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className='worker-form p-5'>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          <div className='text-sm-right text-center mt-2'>
            {this.renderButtons()}
          </div>
        </form>
      </div>
    );
  }
}

WorkerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  invalid: PropTypes.bool,
};
