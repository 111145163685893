/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {statisticActions} from '../../actions/statistic.actions';
import {getComponentDisplayName} from '../utils/utils.hoc';

export const withStatisticsHoc = WrappedComponent => {
  class StatisticsHoc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        lastFilterData: null,
      };
      this.persistLastFilterData = this.persistLastFilterData.bind(this);
      this.saveToPdf = this.saveToPdf.bind(this);
      this.renderPdfBtn = this.renderPdfBtn.bind(this);
    }

    componentWillUnmount() {
      statisticActions.clearStatisticsData();
    }

    persistLastFilterData(data) {
      this.setState({lastFilterData: data});
    }

    saveToPdf() {
      const data = this.state.lastFilterData;
      this.props.saveToPdf(data);
    }

    renderPdfBtn() {
      return (
        <div className='text-right'>
          <Button
            id='generate-pdf'
            className='main-btn m-3 fixed-width-big-btn'
            size='sm'
            onClick={this.saveToPdf}>
            <Translate value='statistics.saveToPdf' />
          </Button>
        </div>
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          persistLastFilterData={this.persistLastFilterData}
          renderPdfBtn={this.renderPdfBtn}
        />
      );
    }
  }

  StatisticsHoc.displayName = `WithStatisticsHoc(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return StatisticsHoc;
};
