/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {dateUtils} from '../../../utils/date.utils';
import moment from 'moment';

export class SubscriptionOverviewSummary extends Component {
  constructor(props) {
    super(props);
  }

  renderNoSubscriptionInfo() {
    const {pendingSubscriptionPaymentExists, onSubscriptionActionBtnClick} =
      this.props;

    return (
      <Alert color='info' className='text-center m-0'>
        <div>
          <Translate value='subscriptionOverview.noSubscriptionInfo' />:
        </div>
        <Button
          className='m-2 main-btn'
          size='sm'
          onClick={onSubscriptionActionBtnClick}
          disabled={pendingSubscriptionPaymentExists}>
          <Translate value='subscriptionOverview.buySubscriptionBtn' />
        </Button>
      </Alert>
    );
  }

  renderActiveSubscriptionInfo() {
    const {
      pendingSubscriptionPaymentExists,
      subscriptionEndDate,
      onSubscriptionActionBtnClick,
    } = this.props;

    return (
      <Alert color='success' className='text-center m-0'>
        <div>
          <Translate value='subscriptionOverview.activeSubscriptionInfo' />:
          <br />
          <b>{dateUtils.formatDate(subscriptionEndDate, 'YYYY-MM-DD HH:mm')}</b>
          <br />
          <Translate value='subscriptionOverview.activeSubscriptionInfoSuffix' />
          :
        </div>
        <Button
          className='m-2 main-btn'
          onClick={onSubscriptionActionBtnClick}
          disabled={pendingSubscriptionPaymentExists}>
          <Translate value='subscriptionOverview.extendSubscriptionBtn' />
        </Button>
      </Alert>
    );
  }

  pendingSubscriptionPaymentInfo() {
    return (
      this.props.pendingSubscriptionPaymentExists && (
        <Alert color='warning' className='text-center mx-0 mt-0 mb-3'>
          <div>
            <Translate value='subscriptionOverview.pendingSubscriptionPaymentInfo' />
            .
          </div>
        </Alert>
      )
    );
  }

  render() {
    const {subscriptionEndDate, showContent} = this.props;
    const hasSubscription =
      subscriptionEndDate && moment(subscriptionEndDate).isAfter(moment());

    return (
      <div className='p-3'>
        {showContent && (
          <Fragment>
            {this.pendingSubscriptionPaymentInfo()}

            {!hasSubscription && this.renderNoSubscriptionInfo()}
            {hasSubscription && this.renderActiveSubscriptionInfo()}
          </Fragment>
        )}
      </div>
    );
  }
}

SubscriptionOverviewSummary.propTypes = {
  showContent: PropTypes.bool,
  pendingSubscriptionPaymentExists: PropTypes.bool,
  subscriptionEndDate: PropTypes.string,
  onSubscriptionActionBtnClick: PropTypes.func.isRequired,
};

SubscriptionOverviewSummary.defaultProps = {
  pendingSubscriptionPaymentExists: false,
};
