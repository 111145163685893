/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import moment from 'moment';
import {history} from '../../utils/history';
import {Alert, Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import Loadable from 'react-loading-overlay';
import {getOrDefault} from '../../utils/utils';

const noServicesAlertStyle = visible => ({
  backgroundColor: '#FFF3CD',
  color: '#856404',
  borderRadius: '.25rem',
  visibility: visible ? 'visible' : 'hidden',
});

export const withSubscriptionRequired = WrappedComponent => {
  class SubscriptionRequiredHoc extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        isFetchingInternal: true,
      };
    }

    componentDidMount() {
      getOrDefault(this.props.onEnter, () => {})();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.isFetching && !this.props.isFetching) {
        setTimeout(() => this.setState({isFetchingInternal: false}), 1000);
      }
    }

    renderNoSubscriptionAlert() {
      const loadableActive =
        this.props.isFetching || this.state.isFetchingInternal;

      return (
        <div className='root-panel m-3 no-subscription-alert'>
          <Loadable
            active={loadableActive}
            spinner
            className='p-3'
            background={'rgba(0, 0, 0, 0.9)'}>
            <div
              className='text-center p-3'
              style={noServicesAlertStyle(!loadableActive)}>
              <div className='mb-3'>
                <Translate value='subscription.noSubscriptionAlert' />
              </div>
              <Button
                className='m-1 main-btn'
                size='sm'
                onClick={this.navigateToSubscriptions}>
                <Translate value='subscription.goToSubscriptionsBtn' />
              </Button>
            </div>
          </Loadable>
        </div>
      );
    }

    navigateToSubscriptions() {
      history.push('/workshop/subscriptions');
    }

    render() {
      const {subscriptionEndDate, bypassSubscriptionCheck} = this.props;
      const hasSubscription =
        subscriptionEndDate && moment(subscriptionEndDate).isAfter(moment());
      const show = bypassSubscriptionCheck || hasSubscription;

      return (
        <Fragment>
          {!show && this.renderNoSubscriptionAlert()}
          {show && <WrappedComponent {...this.props} />}
        </Fragment>
      );
    }
  }

  return SubscriptionRequiredHoc;
};
