export const warrantyConstants = {
  WARRANTY_REQUEST_START: 'WARRANTY_REQUEST_START',
  WARRANTY_REQUEST_END: 'WARRANTY_REQUEST_END',
  WARRANTY_REQUEST_CLEAR: 'WARRANTY_REQUEST_CLEAR',
  GET_WARRANTIES: 'GET_WARRANTIES',
  INITIALIZE_WARRANTY: 'INITIALIZE_WARRANTY',
  EDIT_WARRANTY: 'EDIT_WARRANTY',
  GET_PERIODIC_INSPECTIONS: 'GET_PERIODIC_INSPECTIONS',
  DELETE_INITIALIZED_WARRANTY : 'DELETE_INITIALIZED_WARRANTY',
  PUSH_WARRANTY_TO_THE_USER_LIST: 'PUSH_WARRANTY_TO_THE_USER_LIST',
  FILL_INSPECTION: 'FILL_INSPECTION',
};
