/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Rating} from 'primereact/rating';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {orderRatingActions} from 'shared/actions/order-rating.actions';
import './render-valuation-inquiry-details.css';

export class RenderValuationInquiryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {collapse: false};
    this.resolveToggleIcon = this.resolveToggleIcon.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const {customerUserId, findAllRatingsForTargetCustomer} = this.props;
    if (customerUserId) findAllRatingsForTargetCustomer(customerUserId);
  }

  componentDidUpdate(prevProps) {
    const {customerUserId, findAllRatingsForTargetCustomer} = this.props;
    if (customerUserId !== prevProps.customerUserId)
      findAllRatingsForTargetCustomer(customerUserId);
  }

  componentWillUnmount() {
    this.props.clearOrderRatings();
  }

  resolveToggleIcon() {
    return this.state.collapse
      ? 'fa-chevron-circle-up'
      : 'fa-chevron-circle-down';
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  renderCustomerRating() {
    const {orderRatings} = this.props;
    const average =
      orderRatings && orderRatings.length > 0
        ? orderRatings.map(r => r.value).reduce((a, b) => a + b, 0) /
          orderRatings.length
        : 0;

    return (
      <div className='customer-order-rating'>
        <Rating value={average} readonly={true} cancel={false} />
        <span className='rating-average'>{average.toFixed(1)}</span>
        <span className='ratings-count-info'>
          {`(${I18n.t('valuationInquiryDetails.orderRatings')} - ${
            orderRatings && orderRatings.length
          })`}
        </span>
      </div>
    );
  }

  renderHeader() {
    const {headerTitleDetails, withHeader} = this.props;
    if (withHeader)
      return (
        <div
          className={`row valuation-inquiry-details-header mx-0 ${
            this.state.collapse ? ' collapsed-header' : ''
          }`}
          onClick={this.toggle}>
          <div className='col-12 text-left my-auto'>
            <i
              id='toggle-icon'
              className={'mr-3 fa ' + this.resolveToggleIcon()}
            />
            {`${I18n.t(`valuationInquiryDetails.${headerTitleDetails}`)}`}
          </div>
        </div>
      );
  }

  renderDetails(key) {
    const {detailsData} = this.props;
    return (
      <div className='col' key={key}>
        <div className='row'>
          <div className='col-12'>
            <Translate
              value={`valuationInquiryDetails.${key}Label`}
              className='mr-1 font-bold'
            />
            {detailsData[key]}
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const {withHeader, detailsData, customerUserId} = this.props;
    if (withHeader)
      return (
        <Collapse isOpen={this.state.collapse}>
          <div className='row valuation-inquiry-details-content'>
            <div className='col'>
              {customerUserId && this.renderCustomerRating()}
              {detailsData &&
                Object.keys(detailsData).map(key => this.renderDetails(key))}
            </div>
          </div>
        </Collapse>
      );
    else
      return (
        <div className='row'>
          <div className='col'>
            {detailsData &&
              Object.keys(detailsData).map(key => this.renderDetails(key))}
          </div>
        </div>
      );
  }

  render() {
    const {withHeader} = this.props;
    return (
      <div className={withHeader ? 'details-border mt-1' : ''}>
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

RenderValuationInquiryDetails.propTypes = {
  headerTitleDetails: PropTypes.string,
  detailsData: PropTypes.object,
  withHeader: PropTypes.bool.isRequired,
  customerUserId: PropTypes.string,
};

RenderValuationInquiryDetails.defaultProps = {
  withHeader: true,
  customerUserId: null,
};

const mapDispatchToProps = {
  findAllRatingsForTargetCustomer:
    orderRatingActions.findAllOwnedForTargetCustomer,
  clearOrderRatings: orderRatingActions.clearAll,
};

const mapStateToProps = state => {
  const {i18n, orderRating} = state;
  const {orderRatings} = orderRating;
  return {i18n, orderRatings};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderValuationInquiryDetails);
