/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, getFormValues, reduxForm, reset} from 'redux-form';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import {statisticActions} from 'shared/actions/statistic.actions';
import CountryField from 'shared/components/form/country-field/CountryField';
import RenderDatePicker from 'shared/components/form/date-picker/RenderDatePicker';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {userService} from 'shared/services/user.service';
import {getOrEmptyArray} from 'shared/utils/utils';

class StatisticsLocalizationSearchArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
    };
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(statisticActions.findAllCities());
    this.props.dispatch(statisticActions.findAllStates());
    this.props.dispatch(statisticActions.findAllWorkshopOptions());
    this.props.dispatch(serviceCategoryActions.findAllServiceCategories());
  }

  mapForDropdown(array) {
    return array ? array.map(obj => ({label: obj, value: obj})) : [];
  }

  resolveWorkshopOptions() {
    const {workshops} = this.props;
    return workshops
      ? workshops.map(workshop => ({
          label: workshop.workshopName,
          value: workshop.workshopId,
          key: workshop.workshopId,
        }))
      : [];
  }

  resolveServiceOptions() {
    const {services} = this.props;

    return services
      ? services.map(service => ({
          label: service.name,
          value: service.id,
          key: service.id,
        }))
      : [];
  }

  onStartDateChange(date) {
    this.setState({startDate: date});
  }

  clearFilters() {
    this.props.dispatch(reset(this.props.form));
    this.setState({startDate: null});
  }

  render() {
    const {
      i18n,
      handleSubmit,
      cities,
      states,
      formValues,
      serviceFilterVisible,
    } = this.props;
    const isLocalizationFilterChosen =
      formValues && (formValues.city || formValues.country || formValues.state)
        ? true
        : false;
    const isWorkshopFilterChosen =
      formValues && formValues.workshopId ? true : false;
    return (
      <form
        className='statistics-localization-search-area p-5'
        onSubmit={handleSubmit}>
        <div className='row' style={{flexDirection: 'row'}}>
          <div className='col-md-4 col-sm-11 p-3'>
            <h5 className='text-center read-only-label'>
              <Translate value='statistics.localizationFilter' />
            </h5>
            {userService.isAdmin() && (
              <Field
                name='country'
                component={CountryField}
                placeholder={I18n.t('statistics.countryPlaceholder')}
                label={I18n.t('statistics.country')}
                icon='fa fa-folder'
                required={false}
                disabled={isWorkshopFilterChosen}
                showClear={true}
              />
            )}
            <Field
              name='state'
              component={RenderDropdown}
              options={this.mapForDropdown(states)}
              placeholder={I18n.t('statistics.statePlaceholder')}
              disabled={isWorkshopFilterChosen}
              label={I18n.t('statistics.state')}
              icon='fa fa-folder'
              required={false}
              showClear={true}
            />
            <Field
              name='city'
              component={RenderDropdown}
              options={this.mapForDropdown(cities)}
              disabled={isWorkshopFilterChosen}
              placeholder={I18n.t('statistics.cityPlaceholder')}
              label={I18n.t('statistics.city')}
              icon='fa fa-folder'
              showClear={true}
            />
          </div>
          <div className='col-md-4 col-sm-11 p-3'>
            <h5 className='text-center read-only-label'>
              <Translate value='statistics.workshopFilter' />
            </h5>
            <Field
              name='workshopId'
              component={RenderDropdown}
              options={this.resolveWorkshopOptions()}
              placeholder={I18n.t('statistics.workshopPlaceholder')}
              disabled={isLocalizationFilterChosen}
              label={I18n.t('statistics.workshop')}
              icon='fa fa-folder'
              showClear={true}
            />

            {serviceFilterVisible && (
              <Field
                name='serviceId'
                component={RenderDropdown}
                options={this.resolveServiceOptions()}
                placeholder={I18n.t('statistics.servicePlaceholder')}
                label={I18n.t('statistics.service')}
                icon='fa fa-folder'
                showClear={true}
              />
            )}
          </div>
          <div className='col-md-4 col-sm-11 p-3'>
            <h5 className='text-center read-only-label'>
              <Translate value='statistics.dates' />
            </h5>
            <Field
              name='startDate'
              component={RenderDatePicker}
              handleChangeCallback={this.onStartDateChange}
              label={I18n.t('statistics.startDate')}
              showClearIcon={true}
            />
            <Field
              name='endDate'
              component={RenderDatePicker}
              label={I18n.t('statistics.endDate')}
              minDate={this.state.startDate}
              showClearIcon={true}
            />
          </div>
        </div>
        <div className='text-sm-right text-center mt-3 mx-3'>
          <Button className='main-btn' type='submit' disabled={!formValues}>
            <Translate value='statistics.submitSearch' />
          </Button>
          <Button className='main-btn' type='reset' onClick={this.clearFilters}>
            <Translate value='statistics.resetSearch' />
          </Button>
        </div>
      </form>
    );
  }
}

StatisticsLocalizationSearchArea = reduxForm({
  form: 'statisticsLocalizationSearchArea',
})(StatisticsLocalizationSearchArea);

const mapStateToProps = state => {
  const {i18n, statistic, service} = state;
  const {cities, states, workshops} = statistic;
  const formValues = getFormValues('statisticsLocalizationSearchArea')(state);

  const services = [].concat(
    ...getOrEmptyArray(service.categories).map(category => category.services)
  );

  return {i18n, cities, states, workshops, formValues, services};
};

export default connect(mapStateToProps)(StatisticsLocalizationSearchArea);
