/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';

export function updateDataSideBarReducer(state = {}, action) {
  switch (action.type) {
    case updateDataSideBarConstants.SHOW:
      return action.variableComponent
        ? {
            visible: true,
            variableComponent: action.variableComponent,
            title: action.title,
          }
        : {visible: true};
    case updateDataSideBarConstants.HIDE:
      return {visible: false, title: ''};
    default:
      return state;
  }
}
