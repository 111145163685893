/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {userConstants} from '../constants/user.constants';
import {userService} from '../services/user.service';
import {refreshTokenRequest} from '../services/axios.service';
import {messageActions} from './message.actions';
import {history} from '../utils/history';
import {getErrorMessage, getLoginErrorMessage} from '../utils/utils';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';
import {workshopService} from 'shared/services/workshop.service';
import {workshopConstants} from 'shared/constants/workshop.constants';
import WorkshopLoyaltyProgramModal from '../../layout/workshop/workshop-loyalty-program/worhsop-loyalty-program-modal/WorkshopLoyaltyProgramModal'
import { I18n } from "react-redux-i18n";

export const userActions = {
  login,
  logout,
  loginSocial,
  signup,
  sendLinkToResetPassword,
  resetPassword,
  getUserDataById,
  updateProfile,
  findAllByParams,
  disable,
  enable,
  createAdmin,
  activate,
  loadLoggedUserAvatar,
  updateAvatar,
  changePassword,
  addNewBarcode,
  sendBarcodeIssue,
  getUserDataByIdAndCheckFirstTimeLogin,
  updateFirstTimeLoginStatus
};

function login(email, password) {
  return dispatch => {
    dispatch(requestLogin());

    userService.login(email, password).then(
      user => {
        dispatch(loginSuccess());
      },
      error => {
        dispatch(loginError());
        dispatch(messageActions.errorMessage(getLoginErrorMessage(error)));
      }
    );
  };
}

function requestLogin() {
  return {type: userConstants.LOGIN_REQUEST};
}

function loginSuccess() {
  return {type: userConstants.LOGIN_SUCCESS};
}

function loginError() {
  return {type: userConstants.LOGIN_FAILURE};
}

function logout() {
  userService.logout();
  return {type: userConstants.LOGOUT};
}

function loginSocial(providerId, accessToken) {
  return dispatch => {
    dispatch(requestLogin());

    userService.loginSocial(providerId, accessToken).then(
      user => {
        dispatch(loginSuccess());
      },
      error => {
        dispatch(loginError());
        if (error.enabled === false) {
          dispatch(messageActions.errorMessage('login.unauthorized'));
        } else {
          dispatch(messageActions.errorMessage('login.socialErrorMsg'));
        }
      }
    );
  };
}

function signup(values) {
  return dispatch => {
    dispatch(requestStart());

    userService.signup(values).then(
      user => {
        setTimeout(() => {
          dispatch(requestEnd());
          history.push('/user-activation-info');
        }, 3200);
      },
      error => {
        dispatch(requestEnd());
        dispatch(messageActions.errorMessage('signup.errorMsg'));
      }
    );
  };
}

function requestStart() {
  return {
    type: userConstants.REQUEST_START,
  };
}

function requestEnd() {
  return {
    type: userConstants.REQUEST_END,
  };
}

function sendLinkToResetPassword(data) {
  return dispatch => {
    dispatch(requestStart());

    userService.sendLinkToResetPassword(data).then(
      () => {
        dispatch(
          messageActions.successMessage('forgotPasswordModal.successMsg')
        );
        dispatch(requestEnd());
      },
      error => {
        dispatch(requestEnd());
        dispatch(messageActions.errorMessage('forgotPasswordModal.errorMsg'));
      }
    );
  };
}

function resetPassword(data) {
  return dispatch => {
    dispatch(requestStart());

    userService.resetPassword(data).then(
      () => {
        dispatch(messageActions.successMessage('resetPassword.successMsg'));
        setTimeout(() => {
          dispatch(requestEnd());
          history.push('/home');
        }, 2000);
      },
      error => {
        dispatch(requestEnd());
        dispatch(messageActions.errorMessage('resetPassword.errorMsg'));
      }
    );
  };
}

function getUserDataById(userId) {
  return dispatch => {
    userService.getUserDataById(userId).then(res => {
      res.data.id = userId;
      dispatch({type: userConstants.LOAD_PROFILE_DATA, payload: res});
    });
  };
}

function getUserDataByIdAndCheckFirstTimeLogin(userId) {
  return dispatch => {
    userService.getUserDataById(userId).then(res => {
      res.data.id = userId;
      if (res.data.firstTimeLogin) {
        dispatch({
          type: updateDataSideBarConstants.SHOW,
          variableComponent: <WorkshopLoyaltyProgramModal
            redirectToLoyaltyProgramRegistration
          />,
          title: I18n.t("workshopLoyaltyProgram.register.goToRegistration"),
        });
        dispatch(updateFirstTimeLoginStatus(userId, res));
      } else dispatch({ type: userConstants.LOAD_PROFILE_DATA, payload: res });
    });
  };
}

function updateProfile(userId, data) {
  return dispatch => {
    dispatch(requestStart());
    userService
      .updateProfile(userId, data)
      .then(res => {
        return Promise.all([upgradeAccessIfThinUser(), res]);
      })
      .then(([upgradeThinUserResponse, res]) => {
        res.data.id = userId;
        dispatch(messageActions.successMessage('userProfileForm.successMsg'));
        dispatch({type: userConstants.LOAD_PROFILE_DATA, payload: res});
        dispatch(requestEnd());
      })
      .catch(error => {
        dispatch(
          messageActions.errorMessage(getErrorMessage(error, 'userProfileForm'))
        );
        dispatch(requestEnd());
      });
  };
}

function updateFirstTimeLoginStatus(userId, res) {
  return dispatch => {
    dispatch(requestStart());
    userService.updateFirstTimeLoginStatus(userId).then(
      () => {
        res.data.firstTimeLogin = false;
        dispatch({ type: userConstants.LOAD_PROFILE_DATA, payload: res });
      },
      error => {
        dispatch(requestEnd());
      }
    );
  };
}

async function upgradeAccessIfThinUser() {
  try {
    if (userService.isThinUser()) {
      const refreshToken = localStorage.getItem(
        userConstants.REFRESH_TOKEN_KEY
      );
      await refreshTokenRequest(refreshToken);
    } else {
      Promise.resolve();
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

function isLoggedUser(userId) {
  return userId === userService.getUserId();
}

function findAllByParams(params) {
  return dispatch => {
    dispatch(requestStart());

    userService.findAllByParams(params).then(
      res => {
        dispatch({type: userConstants.LOAD_USERS_DATA, payload: res});
        dispatch(requestEnd());
      },
      error => {
        dispatch(requestEnd());
        dispatch(messageActions.errorMessage('users.fetchError'));
      }
    );
  };
}

function disable(userId, languageInfo) {
  return dispatch => {
    userService.disable(userId, languageInfo).then(
      res => {
        res.data.id = userId;
        dispatch(messageActions.successMessage('users.disableSuccess'));
        dispatch({type: userConstants.CHANGE_PROFILE_STATUS, payload: res});
      },
      error => {
        dispatch(messageActions.errorMessage('users.disableError'));
      }
    );
  };
}

function enable(userId, languageInfo) {
  return dispatch => {
    userService.enable(userId, languageInfo).then(
      res => {
        res.data.id = userId;
        dispatch(messageActions.successMessage('users.enableSuccess'));
        dispatch({type: userConstants.CHANGE_PROFILE_STATUS, payload: res});
      },
      error => {
        dispatch(messageActions.errorMessage('users.enableError'));
      }
    );
  };
}

function createAdmin(values) {
  return dispatch => {
    userService.createAdmin(values).then(
      user => {
        dispatch(messageActions.successMessage('createAdminForm.successMsg'));
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch(refreshUsersData());
      },
      error => {
        dispatch(messageActions.errorMessage('createAdminForm.errorMsg'));
      }
    );
  };
}

function refreshUsersData() {
  return {type: userConstants.REFRESH_USERS_DATA};
}

function activate(activationToken) {
  return dispatch => {
    dispatch(requestStart());

    userService.activate(activationToken).then(
      res => {
        dispatch(messageActions.successMessage('userActivation.successMsg'));
        setTimeout(() => {
          dispatch(requestEnd());
          history.push('/home');
        }, 3200);
      },
      error => {
        dispatch(requestEnd());
        dispatch(messageActions.errorMessage('userActivation.errorMsg'));
      }
    );
  };
}

function loadLoggedUserAvatar() {
  return dispatch => {
    userService.getUserDataById(userService.getUserId()).then(res => {
      dispatch({
        type: userConstants.LOGGED_USER_AVATAR_LOADED,
        payload: res.data.avatar,
      });
    });
  };
}

function updateAvatar(userId, data) {
  return dispatch => {
    dispatch(requestStart());
    userService.updateAvatar(userId, data).then(
      response => {
        response.data.id = userId;
        dispatch(messageActions.successMessage('userProfileForm.successMsg'));
        dispatch({type: userConstants.LOAD_PROFILE_DATA, payload: response});
        if (isLoggedUser(userId)) dispatch(loadLoggedUserAvatar());
        dispatch(requestEnd());
      },
      error => {
        dispatch(
          messageActions.errorMessage(getErrorMessage(error, 'userProfileForm'))
        );
        dispatch(requestEnd());
      }
    );
  };
}

function changePassword(userId, data) {
  return dispatch => {
    dispatch(requestStart());
    userService.changePassword(userId, data).then(
      response => {
        dispatch(messageActions.successMessage('changePassword.successMsg'));
        dispatch(requestEnd());
      },
      error => {
        dispatch(
          messageActions.errorMessage(getErrorMessage(error, 'changePassword'))
        );
        dispatch(requestEnd());
      }
    );
  };
}

function addNewBarcode(data) {
  return dispatch => {
    dispatch({type: userConstants.REQUEST_START});
    workshopService.createBarcode(data).then(
      response => {
        if (response.data) {
          const [BOX, CONTROLLER, INJECTOR] = response.data;
          if (
            [BOX?.result, CONTROLLER?.result, INJECTOR?.result].some(
              value => value === 'USER_DUPLICATE'
            )
          ) {
            dispatch({
              type: userConstants.USER_BARCODE_INFO,
              payload: {
                BOX: {
                  result: BOX?.result,
                  code: BOX?.barcodeValue,
                  barcodeType: 'BOX',
                },
                CONTROLLER: {
                  result: CONTROLLER?.result,
                  code: CONTROLLER?.barcodeValue,
                  barcodeType: 'CONTROLLER',
                },
                INJECTOR: {
                  result: INJECTOR?.result,
                  code: INJECTOR?.barcodeValue,
                  barcodeType: 'INJECTOR',
                },
                showForm: true,
              },
            });
          } else {
            dispatch({
              type: userConstants.USER_BARCODE_INFO,
              payload: {
                BOX: {
                  result: BOX?.result,
                  code: BOX?.barcodeValue,
                  barcodeType: 'BOX',
                },
                CONTROLLER: {
                  result: CONTROLLER?.result,
                  code: CONTROLLER?.barcodeValue,
                  barcodeType: 'CONTROLLER',
                },
                INJECTOR: {
                  result: INJECTOR?.result,
                  code: INJECTOR?.barcodeValue,
                  barcodeType: 'INJECTOR',
                },
                showForm: false,
              },
            });
            dispatch({
              type: workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR,
            });
            setTimeout(
              () =>
                dispatch({
                  type: userConstants.USER_BARCODE_INFO_CLEAR,
                }),
              6000
            );
          }
        }
        dispatch({type: userConstants.REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('barcodes.fetchError'));
        dispatch({type: userConstants.REQUEST_END});
      }
    );
  };
}

function sendBarcodeIssue(data) {
  return dispatch => {
    dispatch({type: userConstants.REQUEST_START});
    workshopService.reportBarcodeIssue(data).then(
      response => {
        dispatch({
          type: userConstants.USER_BARCODE_INFO_CLEAR,
        });
        dispatch({
          type: workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR,
        });
        dispatch({type: userConstants.REPORT_ISSUE_SUCCESS});
        setTimeout(
          () => dispatch({type: userConstants.REPORT_ISSUE_CLEAR}),
          4000
        );

        dispatch({type: userConstants.REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('barcodes.sendIssueError'));
        dispatch({type: userConstants.REQUEST_END});
      }
    );
  };
}
