/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {promotionActions} from 'shared/actions/promotion.actions';
import {Promotions} from 'shared/components/promotions/Promotions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {becameObsolete} from 'shared/utils/sync';

class PromotionsWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.findAllPromotions();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.promotionDataSyncState,
        this.props.promotionDataSyncState
      )
    )
      this.props.findAllPromotions();
  }

  render() {
    return <Promotions {...this.props} />;
  }
}

function mapStateToProps(state) {
  const {promotion} = state;
  const {promotions, promotionDataSyncState, isFetching} = promotion;
  const isWorkshopNameVisible = true;
  const isActionsVisible = true;

  return {
    promotions,
    isFetching,
    promotionDataSyncState,
    isWorkshopNameVisible,
    isActionsVisible,
  };
}

const mapDispatchToProps = {
  findAllPromotions: promotionActions.findAllPromotions,
  updatePromotion: promotionActions.updatePromotion,
  findPromotionById: promotionActions.findPromotionById,
  changePromotionStatus: promotionActions.changePromotionStatus,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

export const ConnectedPromotionsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsWrapper);

export default withDataTableCore(ConnectedPromotionsWithRedux, false);
