/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {LanguageForm} from './LanguageForm';
import {languageActions} from '../../../actions/language.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {validate} from './language-form-validators';
import {languageConstants} from '../../../constants/language.constants';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';

export const LanguageFormReduxFormWrapper = reduxForm({
  form: 'languageEditForm',
  validate,
  enableReinitialize: true,
})(LanguageForm);

class LanguageEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.renderEditLanguageValuesBtn =
      this.renderEditLanguageValuesBtn.bind(this);
  }

  componentDidMount() {
    const {findLanguageById, languageId} = this.props;
    findLanguageById(languageId);
  }

  componentWillUnmount() {
    this.props.clearLanguage();
  }

  update(data) {
    const {updateLanguage, languageId} = this.props;
    updateLanguage(languageId, data);
  }

  delete() {
    const {deleteLanguageById, languageId, onDeleteSuccess} = this.props;
    deleteLanguageById(languageId, onDeleteSuccess);
  }

  renderEditLanguageValuesBtn() {
    return (
      <Button
        id='language-form-edit-values-btn'
        size='sm'
        color='primary'
        className='mx-1 my-1 main-btn fixed-width-xbig-btn'
        onClick={this.props.onEditLanguageValuesClick}>
        <Translate value={'languages.editLanguageValuesBtn'} />
      </Button>
    );
  }

  render() {
    const {isFetchingLanguage} = this.props;

    return (
      <Loadable active={isFetchingLanguage} spinner>
        <LanguageFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          handleDelete={this.delete}
          renderAdditionalButtons={this.renderEditLanguageValuesBtn}
        />
      </Loadable>
    );
  }
}

LanguageEditForm.propTypes = {
  languageId: PropTypes.string.isRequired,
  findLanguageById: PropTypes.func.isRequired,
  clearLanguage: PropTypes.func.isRequired,
  updateLanguage: PropTypes.func.isRequired,
  deleteLanguageById: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
  onEditLanguageValuesClick: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, language} = state;
  const initialValues = language.language || {};
  const {isFetchingLanguage} = language;

  return {i18n, initialValues, isFetchingLanguage};
}

const mapDispatchToProps = {
  findLanguageById: languageActions.findLanguageById,
  clearLanguage: () => ({type: languageConstants.CLEAR_LANGUAGE}),
  updateLanguage: languageActions.updateLanguage,
  deleteLanguageById: languageActions.deleteLanguageById,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageEditForm);
