/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const promotionService = {
  getPromotionById,
  getAllPromotions,
  getAllPromotionsByWorkshopId,
  createPromotion,
  updatePromotion,
  changePromotionStatus,
  hasCreationConsent,
};

function getPromotionById(promotionId) {
  return axiosService
    .get(`/workshop/promotions/${promotionId}`)
    .then(response => {
      return response;
    });
}

function getAllPromotions() {
  return axiosService.get('/workshop/promotions').then(response => {
    return response;
  });
}

function getAllPromotionsByWorkshopId(workshopId, activeOnly) {
  const requestPath =
    `/workshop/promotions/workshop/${workshopId}` +
    (activeOnly ? '/active' : '');
  return axiosService.get(requestPath).then(response => {
    return response;
  });
}

function createPromotion(data) {
  return axiosService.post('/workshop/promotions', data).then(response => {
    return response;
  });
}

function updatePromotion(promotionId, data) {
  return axiosService
    .put(`/workshop/promotions/${promotionId}`, data)
    .then(response => {
      return response;
    });
}

function changePromotionStatus(promotionId, newStatus) {
  return axiosService
    .put(`/workshop/promotions/${promotionId}/status?newStatus=${newStatus}`)
    .then(response => {
      return response;
    });
}

function hasCreationConsent(workshopId) {
  return axiosService
    .get(`/workshop/promotions/creation-consent?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}
