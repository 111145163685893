/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {RenderField} from '../../text-field/hook-form-version/RenderField';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {RenderDropdown} from '../../dropdown/hook-form-version/RenderDropdown';
import {voivodeships} from 'shared/utils/utils';

export const StateField = props => {
  const {register, watch, errors} = props;
  const field = 'state';
  const country = watch('country');

  if (country === 'POLAND') {
    return (
      <RenderDropdown
        options={voivodeships}
        placeholder={I18n.t('renderField.searchPlaceholder')}
        label={I18n.t('userProfileForm.voivodeshipLabel')}
        {...register(`${field}`, {required: true})}
        value={watch(`${field}`)}
        error={errors[`${field}`]}
        required
      />
    );
  }

  return (
    <RenderField
      placeholder={I18n.t('renderField.searchPlaceholder')}
      label={I18n.t('state.stateLabel')}
      {...register(`${field}`, {required: true})}
      value={watch(`${field}`)}
      error={errors[`${field}`]}
      required
      type='text'
    />
  );
};

StateField.propTypes = {
  formName: PropTypes.string.isRequired,
};
