/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {orderRatingActions} from 'shared/actions/order-rating.actions';
import {RatingModal} from 'shared/components/rating/rating-modal/RatingModal';

class CreateOrderCustomerRatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.createOrderCustomerRating = this.createOrderCustomerRating.bind(this);
  }

  createOrderCustomerRating(values) {
    const {createOrderRating, orderId, onCreateOrderRatingSuccess} = this.props;
    return createOrderRating(orderId, values, onCreateOrderRatingSuccess);
  }

  render() {
    return (
      <RatingModal
        titleLabelKey='orderRating.createOrderCustomerRatingModalTitle'
        openBtnLabelKey='orderRating.createOrderCustomerRatingModalOpenBtn'
        action={this.createOrderCustomerRating}
      />
    );
  }
}

CreateOrderCustomerRatingModal.propType = {
  orderId: PropTypes.string.isRequired,
  createOrderRating: PropTypes.func.isRequired,
  onCreateOrderRatingSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  createOrderRating: orderRatingActions.createOrderRating,
};

const connectedCreateOrderCustomerRatingModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrderCustomerRatingModal);
export {connectedCreateOrderCustomerRatingModal as CreateOrderCustomerRatingModal};
