/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

/*global google*/
import {GMap} from 'primereact/gmap';
import PropTypes from 'prop-types';
import React from 'react';

export default class RenderGmap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {overlays: []};
  }

  componentDidMount() {
    this.initAutocomplete();
  }

  componentDidUpdate(prevProps) {
    const {workshopData} = this.props;
    if (!prevProps.workshopData && workshopData) this.centerOnMarkerOnInit();
  }

  initAutocomplete() {
    const {workshopData} = this.props;
    if (workshopData) this.centerOnMarkerOnInit();
  }

  centerOnMarkerOnInit() {
    const {latitude, longitude} = this.props.workshopData.address;
    this.setState({
      overlays: [
        new google.maps.Marker({position: {lat: latitude, lng: longitude}}),
      ],
    });
    const myLatlng = new google.maps.LatLng(latitude, longitude);

    this.map.getMap().setZoom(13);
    this.map.getMap().setCenter(myLatlng);
  }

  render() {
    const options = {
      center: {lat: 52.237049, lng: 21.017532},
      zoom: 5,
      gestureHandling: 'cooperative',
    };
    return (
      <GMap
        ref={map => (this.map = map)}
        options={options}
        overlays={this.state.overlays}
        style={{width: '96%', minHeight: '320px', margin: 'auto'}}
      />
    );
  }
}

RenderGmap.propType = {
  workshopData: PropTypes.object,
};
