/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Statutes as StatutesView} from '../../admin/statutes/Statutes';

export default class Statutes extends React.Component {
  render() {
    return <StatutesView {...this.props} />;
  }
}
