import React from "react";
import { Translate } from "react-redux-i18n";
import styles from "./WarrantyBookReadonlyMode.module.css";
import { useSelector } from "react-redux";
import {dateUtils} from 'shared/utils/date.utils';

const WarrantyBookReadonlyMode = () => {
  const warrantyBook = useSelector(
    (state) => state.warrantyBook.initializedWarranty
  );

  const renderRow = (label, value) => {
    return (
      <div className={styles.LabelContainer}>
        <Translate value={label} className={styles.LabelName} />
        <span className={styles.LabelValue}>{value}</span>
      </div>
    );
  };

  return (
      <div className={styles.Container}>
        {renderRow(
          "warranty.sectionI.warrantyNumber",
          warrantyBook && warrantyBook.warrantyNumber
        )}
        {renderRow(
          "warranty.sectionI.brand",
          warrantyBook && warrantyBook.carDetails.brand
        )}
        {renderRow(
          "warranty.sectionI.model",
          warrantyBook && warrantyBook.carDetails.model
        )}
        {renderRow(
          "warranty.sectionI.productionYear",
          warrantyBook && warrantyBook.carDetails.productionYear
        )}
        {renderRow(
          "warranty.sectionI.gasInstallationDate",
          warrantyBook && warrantyBook.carDetails.gasInstallationDate
          ? dateUtils.formatDate(warrantyBook.carDetails.gasInstallationDate, 'YYYY-MM-DD'): ''
        )}
        {renderRow(
          "warranty.sectionI.engineCapacityCm3",
          warrantyBook && warrantyBook.carDetails.engineCapacityCm3
        )}
        {renderRow(
          "warranty.sectionI.enginePowerKw",
          warrantyBook && warrantyBook.carDetails.enginePowerKw
        )}
        {renderRow("warranty.sectionI.vin", warrantyBook && warrantyBook.vin)}
        {renderRow(
          "warranty.sectionI.registrationNumber",
          warrantyBook &&  warrantyBook.carDetails.registrationNumber
        )}
        {renderRow(
          "warranty.sectionI.mileage",
          warrantyBook &&  warrantyBook.carDetails.mileage? warrantyBook.carDetails.mileage +
              " " +
              warrantyBook.carDetails.mileageUnit.toLowerCase() : ''
        )}
      </div>
  );
};

export default WarrantyBookReadonlyMode;
