/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const statisticConstants = {
  STATISTIC_REQUEST_START: 'STATISTIC_REQUEST_START',
  STATISTIC_REQUEST_END: 'STATISTIC_REQUEST_END',

  LOAD_STATISTICS_DATA: 'LOAD_STATISTICS_DATA',
  CLEAR_STATISTICS_DATA: 'CLEAR_STATISTICS_DATA',
  LOAD_CITIES_DATA: 'LOAD_CITIES_DATA',
  LOAD_STATES_DATA: 'LOAD_STATES_DATA',
  LOAD_WORKSHOP_OPTIONS: 'LOAD_WORKSHOP_OPTIONS',
};
