/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {serviceConstants} from '../constants/service.constants';
import {serviceCategoryService} from '../services/service-category.service';
import {messageActions} from './message.actions';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';
import {getErrorMessage} from '../utils/utils';

export const serviceCategoryActions = {
  findAllServiceCategories,
  createServiceCategory,
  findServiceCategoryById,
  updateServiceCategory,
  deleteServiceCategory,
  findLpgServiceCategoryId,
  findAllServiceCategoriesByWorkshopId,
  acceptServiceCategory,
  findAllActiveServiceCategoriesByWorkshopId,
};

function findAllServiceCategories() {
  return dispatch => {
    dispatch({type: serviceConstants.SERVICE_REQUEST_START});
    serviceCategoryService.findAllServiceCategories().then(
      serviceCategories => {
        dispatch({
          type: serviceConstants.LOAD_SERVICE_CATEGORIES,
          payload: serviceCategories,
        });
        dispatch({type: serviceConstants.SERVICE_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('services.fetchError'));
        dispatch({type: serviceConstants.SERVICE_REQUEST_END});
      }
    );
  };
}

function createServiceCategory(values) {
  return dispatch => {
    serviceCategoryService.createServiceCategory(values).then(
      serviceCategory => {
        dispatch(
          messageActions.successMessage('services.createCategorySuccessMsg')
        );
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'createCategoryErrorMsg')
          )
        );
      }
    );
  };
}

function findServiceCategoryById(serviceCategoryId) {
  return dispatch => {
    serviceCategoryService
      .findServiceCategoryById(serviceCategoryId)
      .then(serviceCategory => {
        dispatch({
          type: serviceConstants.LOAD_SERVICE_CATEGORY,
          payload: serviceCategory,
        });
      });
  };
}

function updateServiceCategory(serviceCategoryId, values) {
  return dispatch => {
    serviceCategoryService
      .updateServiceCategory(serviceCategoryId, values)
      .then(
        serviceCategory => {
          dispatch(
            messageActions.successMessage('services.updateCategorySuccessMsg')
          );
          dispatch({
            type: serviceConstants.LOAD_SERVICE_CATEGORY,
            payload: serviceCategory,
          });
          dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
        },
        error => {
          dispatch(
            messageActions.errorMessage(
              getErrorMessage(error, 'services', 'updateCategoryErrorMsg')
            )
          );
        }
      );
  };
}

function acceptServiceCategory(serviceCategoryId) {
  return dispatch => {
    serviceCategoryService.acceptServiceCategory(serviceCategoryId).then(
      serviceCategory => {
        dispatch(
          messageActions.successMessage('services.updateCategorySuccessMsg')
        );
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'updateCategoryErrorMsg')
          )
        );
      }
    );
  };
}

function deleteServiceCategory(serviceCategoryId) {
  return dispatch => {
    serviceCategoryService.deleteServiceCategory(serviceCategoryId).then(
      () => {
        dispatch(
          messageActions.successMessage(
            'services.serviceCategoryDeleteSuccessMsg'
          )
        );
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'serviceCategoryDeleteErrorMsg')
          )
        );
      }
    );
  };
}

function findLpgServiceCategoryId() {
  return dispatch => {
    serviceCategoryService
      .findLpgServiceCategoryId()
      .then(lpgServiceCategoryId => {
        dispatch({
          type: serviceConstants.LOAD_LPG_SERVICE_CATEGORY_ID,
          payload: lpgServiceCategoryId,
        });
      });
  };
}

function findAllServiceCategoriesByWorkshopId(workshopId) {
  return dispatch => {
    serviceCategoryService
      .findAllActiveServiceCategoriesByWorkshopId(workshopId)
      .then(
        response => {
          dispatch({
            type: serviceConstants.LOAD_WORKSHOP_SERVICE_CATEGORIES,
            payload: response,
          });
        },
        error => {
          dispatch(messageActions.errorMessage('services.fetchError'));
        }
      );
  };
}

function findAllActiveServiceCategoriesByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: serviceConstants.SERVICE_REQUEST_START});
    serviceCategoryService
      .findAllActiveServiceCategoriesByWorkshopId(workshopId)
      .then(
        serviceCategories => {
          dispatch({
            type: serviceConstants.LOAD_SERVICE_CATEGORIES,
            payload: serviceCategories,
          });
          dispatch({type: serviceConstants.SERVICE_REQUEST_END});
        },
        error => {
          dispatch(messageActions.errorMessage('services.fetchError'));
          dispatch({type: serviceConstants.SERVICE_REQUEST_END});
        }
      );
  };
}
