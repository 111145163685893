/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {dateUtils} from '../../../utils/date.utils';
import {getOrEmptyArray, indexArray} from '../../../utils/utils';

class LoyaltyProgramTable extends Component {
  constructor(props) {
    super(props);
    this.renderCreateBtn = this.renderCreateBtn.bind(this);
  }

  prepareLoyaltyProgramsToDisplay(loyaltyPrograms) {
    return getOrEmptyArray(indexArray(loyaltyPrograms)).map(loyaltyProgram => ({
      ...loyaltyProgram,
      createdDateFormatted: dateUtils.formatDate(loyaltyProgram.createdDate),
    }));
  }

  renderCreateBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={() => this.props.onCreateClick()}>
        <Translate value='loyaltyProgram.createBtn' />
      </Button>
    );
  }

  render() {
    const {loyaltyPrograms, onRowClick, headerBuilder, renderRowsDropdown} =
      this.props;
    return (
      <DataTable
        value={this.prepareLoyaltyProgramsToDisplay(loyaltyPrograms)}
        header={headerBuilder()}
        rows={this.props.rows}
        globalFilter={this.props.globalFilter}
        paginator={true}
        responsive={true}
        className='data-table-padding-class'
        emptyMessage={I18n.t('loyaltyProgram.emptyPlaceholder')}
        onRowClick={onRowClick}
        paginatorLeft={renderRowsDropdown()}
        paginatorRight={this.renderCreateBtn()}>
        <Column
          field='index'
          className='lp-col'
          header={I18n.t('loyaltyProgram.lp')}
          sortable={true}
        />
        <Column
          field='title'
          header={I18n.t('loyaltyProgram.title')}
          sortable={true}
        />
        <Column
          field='points'
          header={I18n.t('loyaltyProgram.points')}
          sortable={true}
        />
        <Column
          field='createdDateFormatted'
          header={I18n.t('loyaltyProgram.createdDate')}
          sortable={true}
        />
      </DataTable>
    );
  }
}

LoyaltyProgramTable.propTypes = {
  loyaltyPrograms: PropTypes.array,
  onRowClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

LoyaltyProgramTable.defaultProps = {
  loyaltyPrograms: [],
  onRowClick: () => {},
  onCreateClick: () => {},
};

export {LoyaltyProgramTable as LoyaltyProgramTableCore};

export default withDataTableCore(LoyaltyProgramTable);
