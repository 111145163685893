/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {
  sortByCustomField,
  sortByInquiryNumber,
} from 'shared/utils/sort-utils/sort.utils';
import {ValuationInquiryStatusEnum} from '../../../workshop/valuations/utils/utils';
import {ValuationInquiryDetails} from '../../../workshop/valuations/valuation-inquiry-details/ValuationInquiryDetails';

class InquiriesInProgress extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    this.props.findAllValuationInquiries();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  onRowClick(event) {
    this.props.findValuationInquiryById(event.data.id);
    const details = (
      <ValuationInquiryDetails shouldShowWorkshopStatusAlert={false} />
    );
    const title =
      I18n.t('valuationInquiryDetails.detailsHeader') +
      ' ' +
      event.data.inquiryNumber.inquiryNumberString;
    this.props.showUpdateDataSideBar(details, title);
  }

  render() {
    const {
      valuationInquiries,
      isFetching,
      header,
      rows,
      globalFilter,
      renderDateCol,
      renderRowsDropdown,
    } = this.props;
    const activeValuationInquiries = valuationInquiries
      ? valuationInquiries.filter(
          vi =>
            vi.status === ValuationInquiryStatusEnum.NEW ||
            vi.status === ValuationInquiryStatusEnum.EXTENDED_PERIOD
        )
      : [];

    return (
      <ContentWrapper title='valuations.inquiriesInProgressBtn'>
        <Loadable active={isFetching} spinner>
          <DataTable
            value={activeValuationInquiries}
            paginator={true}
            rows={rows}
            header={header}
            className='p-3'
            responsive={true}
            globalFilter={globalFilter}
            onRowClick={this.onRowClick}
            emptyMessage={I18n.t('inquiriesHistory.emptyPlaceholder')}
            paginatorLeft={renderRowsDropdown()}
            ref={this.setDatatableRef}>
            <Column
              field='inquiryNumber.inquiryNumberString'
              sortable='custom'
              sortFunction={event =>
                sortByInquiryNumber(activeValuationInquiries, event)
              }
              header={I18n.t('inquiriesHistory.inquiryNumberHeader')}
            />
            <Column
              field='sendDate'
              header={I18n.t('inquiriesHistory.sendDateHeader')}
              body={renderDateCol}
              sortable='custom'
              sortFunction={e =>
                sortByCustomField(e, activeValuationInquiries, 'sendDate')
              }
            />
          </DataTable>
        </Loadable>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({order}) => {
  const {isFetching, valuationInquiries} = order;
  return {isFetching, valuationInquiries};
};

const mapDispatchToProps = {
  findAllValuationInquiries: orderActions.findAllValuationInquiries,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findValuationInquiryById: orderActions.findValuationInquiryById,
};

//For testing
export const ConnectedInquiriesInProgressWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InquiriesInProgress);

const InquiriesInProgressWithDataTableCore = withDataTableCore(
  ConnectedInquiriesInProgressWithRedux
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InquiriesInProgressWithDataTableCore);
