/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {statisticActions} from 'shared/actions/statistic.actions';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {withStatisticsHoc} from 'shared/hoc/statistics-hoc/StatisticsHoc';
import {userService} from 'shared/services/user.service';
import {formatCityAddress} from 'shared/utils/address-formatter/address-formater.utils';
import {dateUtils} from 'shared/utils/date.utils';
import {indexArray} from 'shared/utils/utils';
import StatisticsLocalizationSearchArea from './search-area/StatisticsLocalizationSearchArea';

class OrderStatistics extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
    this.onFilterSubmit = this.onFilterSubmit.bind(this);
  }

  componentDidMount() {
    this.props.fetchOrderStatistics();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  onFilterSubmit(data) {
    this.props.persistLastFilterData(data);
    this.props.fetchFilteredOrderStatistics(data);
  }

  mapDatatableValues() {
    const {statistics} = this.props;
    return statistics
      ? indexArray(statistics).map(stat => ({
          ...stat,
          country: I18n.t(`country.${stat.workshopInfo.country.toLowerCase()}`),
          workshopName: stat.workshopInfo.workshopName,
          workshopAddress: formatCityAddress(stat.workshopInfo.address),
          dates: dateUtils.formatTimePeriod(stat.startDate, stat.endDate),
        }))
      : [];
  }

  render() {
    const {isFetching, searchAreaVisible, totalCountArea} = this.props;
    return (
      <div>
        <Loadable active={isFetching} spinner>
          {searchAreaVisible && (
            <StatisticsLocalizationSearchArea
              onSubmit={this.onFilterSubmit}
              serviceFilterVisible={true}
            />
          )}
          <div className='col py-3'>
            {totalCountArea()}
            {this.props.renderPdfBtn()}
            <DataTable
              value={this.mapDatatableValues()}
              paginator={true}
              rows={this.props.rows}
              globalFilter={this.props.globalFilter}
              header={this.props.header}
              className='p-3'
              responsive={true}
              emptyMessage={I18n.t('statistics.emptyStatisticsPlaceholder')}
              paginatorLeft={this.props.renderRowsDropdown()}
              ref={this.setDatatableRef}>
              <Column
                field='index'
                className='lp-col'
                header={I18n.t('statistics.lp')}
                sortable={true}
                style={{width: '4rem'}}
              />

              <Column
                field='completedOrdersCount'
                header={I18n.t('statistics.completedOrdersCount')}
                sortable={true}
              />

              <Column
                field='cancelledOrdersCount'
                header={I18n.t('statistics.cancelledOrdersCount')}
                sortable={true}
              />

              <Column
                field='completedLpgServicesCount'
                header={I18n.t('statistics.completedLpgServicesCount')}
                sortable={true}
              />

              <Column
                field='completedOtherServicesCount'
                header={I18n.t('statistics.completedOtherServicesCount')}
                sortable={true}
              />

              <Column
                field='completedAdditionalInspectionCount'
                header={I18n.t('statistics.completedAdditionalInspectionCount')}
                sortable={true}
              />

              {userService.isAdmin() && (
                <Column
                  field='country'
                  header={I18n.t('statistics.country')}
                  sortable={true}
                />
              )}

              <Column
                field='workshopName'
                header={I18n.t('statistics.workshopName')}
                sortable={true}
              />

              <Column
                field='workshopAddress'
                header={I18n.t('statistics.workshopAddress')}
                sortable={true}
              />

              <Column
                field='dates'
                header={I18n.t('statistics.dates')}
                sortable={true}
              />
            </DataTable>
          </div>
        </Loadable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {statistic} = state;
  const {statistics, isFetching} = statistic;

  return {statistics, isFetching};
}

const mapDispatchToProps = {
  fetchFilteredOrderStatistics: statisticActions.fetchFilteredOrderStatistics,
  fetchOrderStatistics: statisticActions.fetchOrderStatistics,
};

export const ConnectedOrderStatisticsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderStatistics);

export default withStatisticsHoc(
  withDataTableCore(ConnectedOrderStatisticsWithRedux, false)
);
