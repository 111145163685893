/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';
import {serviceStatus} from '../constants/service.constants';

export const serviceCategoryService = {
  findAllServiceCategories,
  createServiceCategory,
  existsByName,
  findServiceCategoryById,
  updateServiceCategory,
  deleteServiceCategory,
  findLpgServiceCategoryId,
  findAllServiceCategoriesByWorkshopId,
  findAllActiveServiceCategoriesByWorkshopId,
  acceptServiceCategory,
};

function findAllServiceCategories() {
  return axiosService.get('/workshop/service-categories').then(response => {
    return response;
  });
}

function createServiceCategory(values) {
  return axiosService
    .post('/workshop/service-categories', values)
    .then(response => {
      return response;
    });
}

function existsByName(name, excludedServiceCategoryId) {
  const opt = excludedServiceCategoryId
    ? `&excludedServiceCategoryId=${excludedServiceCategoryId}`
    : '';
  return axiosService
    .get(`/workshop/service-categories/exists?name=${name}${opt}`)
    .then(response => {
      return response;
    });
}

function findServiceCategoryById(serviceCategoryId) {
  return axiosService
    .get(`/workshop/service-categories/${serviceCategoryId}`)
    .then(response => {
      return response;
    });
}

function updateServiceCategory(serviceCategoryId, values) {
  return axiosService
    .put(`/workshop/service-categories/${serviceCategoryId}`, values)
    .then(response => {
      return response;
    });
}

function deleteServiceCategory(serviceCategoryId) {
  return axiosService
    .delete(`/workshop/service-categories/${serviceCategoryId}`)
    .then(response => {
      return response;
    });
}

function findLpgServiceCategoryId() {
  return axiosService
    .get(`/workshop/service-categories/lpg/id`)
    .then(response => {
      return response;
    });
}

function findAllServiceCategoriesByWorkshopId(workshopId) {
  return axiosService
    .get(`/workshop/service-categories/workshop/${workshopId}`)
    .then(response => {
      return response;
    });
}

function acceptServiceCategory(serviceCategoryId) {
  return axiosService
    .put(
      `/workshop/service-categories/${serviceCategoryId}/status?newStatus=${serviceStatus.ACTIVE}`
    )
    .then(response => {
      return response;
    });
}

function findAllActiveServiceCategoriesByWorkshopId(workshopId) {
  return axiosService
    .get(`/workshop/service-categories/workshop/${workshopId}/active`)
    .then(response => {
      return response;
    });
}
