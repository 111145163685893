/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import WorkshopServices from './WorkshopServices';
import {WorkshopLoyaltyPrograms} from './../loyalty-program/WorkshopLoyaltyPrograms';
import {Translate} from 'react-redux-i18n';

export const WorkshopServicesContainerSubsection = {
  WORKSHOP_SERVICES: {
    key: 'WORKSHOP_SERVICES',
    divId: 'workshop-services-tile',
    translateValue: 'workshopServicesContainer.workshopServicesBtn',
  },
  WORKSHOP_LOYALTY_PROGRAMS: {
    key: 'WORKSHOP_LOYALTY_PROGRAMS',
    divId: 'workshop-loyalty-programs-tile',
    translateValue: 'workshopServicesContainer.workshopLoyaltyProgramsBtn',
  },
};

export class WorkshopServicesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subsectionKeyActive:
        WorkshopServicesContainerSubsection.WORKSHOP_SERVICES.key,
    };
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(
          WorkshopServicesContainerSubsection.WORKSHOP_SERVICES,
          'fa-suitcase'
        )}
        {this.renderTile(
          WorkshopServicesContainerSubsection.WORKSHOP_LOYALTY_PROGRAMS,
          'fa-handshake-o',
          false
        )}
      </div>
    );
  }

  renderTile(subsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        id={subsection.divId}
        className={commonClasses + spacingClasses + this.isActive(subsection)}
        onClick={e => this.setActive(subsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={subsection.translateValue} />
      </div>
    );
  }

  isActive(subsection) {
    return this.state.subsectionKeyActive === subsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(subsection) {
    this.setState({subsectionKeyActive: subsection.key});
  }

  renderSubsection() {
    switch (this.state.subsectionKeyActive) {
      case WorkshopServicesContainerSubsection.WORKSHOP_SERVICES.key:
        return <WorkshopServices />;
      case WorkshopServicesContainerSubsection.WORKSHOP_LOYALTY_PROGRAMS.key:
        return <WorkshopLoyaltyPrograms />;
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        {this.renderSubsection()}
      </div>
    );
  }
}
