import React from 'react';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import styles from './WarrantyBookList.module.css';
import Loadable from 'react-loading-overlay';
import WarrantiesTable from '../warranties-table/WarrantiesTable';
import {useSelector} from 'react-redux';

const WarrantyBookList = props => {
  const isFeatching = useSelector(state => state.warrantyBook.isFeatching);

  return (
    <ContentWrapper title='warranty.warrantyBooks'>
      <Loadable active={isFeatching} spinner>
        <WarrantiesTable />
      </Loadable>
    </ContentWrapper>
  );
};

export default WarrantyBookList;
