/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateRequiredFieldsWithMaxLength} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.symbol = validateRequiredFieldsWithMaxLength(values.symbol, 3);
  errors.label = validateRequiredFieldsWithMaxLength(values.label, 40);
  return errors;
};
