/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const workshopLoyaltyProgramNotificationsConstants = {
  POINTS_DEADLINE_CLOSE_WARNING: 'points_deadline_close_warning',
  POINTS_SETTLED: 'points_settled',
  POINTS_DEADLINE_EXPIRED_WARNING: 'points_deadline_expired_warning',
  WORKSHOP_LOYALTY_TERMS_CHANGE: 'statement_changed',
  NOT_ENOUGH_POINTS: 'not_enough_points', // not editable notification
  WORKSHOP_LOYALTY_REGISTERED_FOR_WORKSHOP: 'workshop_loyalty_registered_for_workshop',
}
