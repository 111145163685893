/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React, {useRef, useState, useEffect} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import {Dropdown} from 'primereact/dropdown';
import styles from './BarcodesTable.module.css';
import {Input} from 'reactstrap';
import {Column} from 'primereact/column';
import {useDispatch, useSelector} from 'react-redux';
import {workshopActions} from 'shared/actions/workshop.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import ACBarcodesDetails from './barcodes-details/ACBarcodesDetails';
import {dateUtils} from 'shared/utils/date.utils';
import {Paginator} from 'primereact/components/paginator/Paginator';

const ACBarcodesTable = () => {
  const dataTableRef = useRef();
  const [rowsNumber, setRowsNumber] = useState(10);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0)
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState('ASC')
  const [globalFilter, setGlobalFilter] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      workshopActions.getValidBarcodes({
        page: page,
        pageLimit: rowsNumber,
        searchPhrase: globalFilter,
        sortField: sortField,
        sortDirection: setSortDirection(sortDirection === 'ASC')
      })
    );
  }, [globalFilter, page, rowsNumber, sortField]);

  const barcodes = useSelector(state => state.workshop.acBarcodesData.content);
  const pageInfo = useSelector(state => state.workshop.acBarcodesData);
  
  const showMoreData = rowData => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <ACBarcodesDetails data={rowData} />,
      title: I18n.t('barcodes.detailsPrimary'),
    });
  };

  return (
    <div className={styles.Container}>
      <DataTable
        style={{minWidth: '700px'}}
        value={barcodes}
        header={
          <div className={styles.Header}>
            <div className={styles.GlobalFilter}>
              <Input
                type='search'
                placeholder={I18n.t('workshops.searchPlaceholder')}
                onInput={e => setGlobalFilter(e.target.value)}
                className='global-filter'
              />
            </div>
          </div>
        }
        rows={rowsNumber}
        globalFilter={globalFilter}
        responsive={true}
        className='data-table-padding-class'
        emptyMessage={'Brak danych'}
        responsive={true}
        pageLinkSize={3}
        onSort={e=> {
          setSortField(e.sortField)
          setPage(1)
          setFirst(0)
        }}
        ref={dataTableRef}>
        <Column
          field='scannedDate'
          header={I18n.t('barcodes.scanDate')}
          sortable
          body={rowData => (
            <div
              className={styles.ClicableField}
              onClick={() => showMoreData(rowData)}>
              {dateUtils.formatDate(rowData.scannedDate) &&
                dateUtils.formatDate(rowData.scannedDate, "YYYY-MM-DD")}
            </div>
          )}
        />
        <Column
          field='createdDate'
          header={I18n.t('barcodes.createdDate')}
          sortable
          body={rowData => (
            <div
              className={styles.ClicableField}
              onClick={() => showMoreData(rowData)}>
              {dateUtils.formatDate(rowData.createdDate) &&
                dateUtils.formatDate(rowData.createdDate, "YYYY-MM-DD")}
            </div>
          )}
        />
        <Column
          field='barcode'
          header={I18n.t('barcodes.barcode')}
          sortable
          className={styles.ClicableField}
          body={rowData => (
            <div
              className={styles.ClicableField}
              onClick={() => showMoreData(rowData)}>
              {rowData.barcode}
            </div>
          )}
        />
      </DataTable>
      <Paginator 
        first={first}
        rows={rowsNumber}
        totalRecords={pageInfo.totalElements}
        pageLinkSize = {5} 
        onPageChange={(e)=>{
          console.log(e, rowsNumber)
          setPage(e.page + 1)
          setFirst(e.first)
        }} 
        leftContent = {
          <Dropdown
            options={rowsQuantityToDisplay}
            className='data-table-rows-dropdown'
            value={rowsNumber}
            onChange={e => {setPage(1), setRowsNumber(e.value)}}
         />
        }></Paginator>
    </div>
  );
};

export default ACBarcodesTable;
