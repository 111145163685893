/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const loyaltyProgramConstants = {
  LOAD_SERVICES_LOYALTY_PROGRAM_POINTS: 'LOAD_SERVICES_LOYALTY_PROGRAM_POINTS',
  MODIFY_SERVICES_LOYALTY_PROGRAM_POINTS:
    'MODIFY_SERVICES_LOYALTY_PROGRAM_POINTS',

  LOAD_LOYALTY_PROGRAMS: 'LOAD_LOYALTY_PROGRAMS',
  LOAD_LOYALTY_PROGRAM: 'LOAD_LOYALTY_PROGRAM',
  LOAD_LOYALTY_PROGRAM_ACCOUNT: 'LOAD_LOYALTY_PROGRAM_ACCOUNT',
  LOAD_LOYALTY_PROGRAM_ACCOUNTS: 'LOAD_LOYALTY_PROGRAM_ACCOUNTS',

  CLEAR_ALL: 'CLEAR_ALL',
  CLEAR_LOYALTY_PROGRAM: 'CLEAR_LOYALTY_PROGRAM',
  CLEAR_LOYALTY_PROGRAM_ACCOUNT: 'CLEAR_LOYALTY_PROGRAM_ACCOUNT',

  LOYALTY_PROGRAMS_REQUEST_START: 'LOYALTY_PROGRAMS_REQUEST_START',
  LOYALTY_PROGRAMS_REQUEST_END: 'LOYALTY_PROGRAMS_REQUEST_END',
  LOYALTY_PROGRAM_REQUEST_START: 'LOYALTY_PROGRAM_REQUEST_START',
  LOYALTY_PROGRAM_REQUEST_END: 'LOYALTY_PROGRAM_REQUEST_END',
  LOYALTY_PROGRAM_ACCOUNTS_REQUEST_START:
    'LOYALTY_PROGRAM_ACCOUNTS_REQUEST_START',
  LOYALTY_PROGRAM_ACCOUNTS_REQUEST_END: 'LOYALTY_PROGRAM_ACCOUNTS_REQUEST_END',

  REFRESH_LOYALTY_PROGRAMS: 'REFRESH_LOYALTY_PROGRAMS',
};
