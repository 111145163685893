/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';
import {serviceStatus} from '../constants/service.constants';

export const serviceService = {
  findServiceById,
  updateService,
  createService,
  deleteService,
  findAllServices,
  acceptService,
};

function findServiceById(serviceId) {
  return axiosService.get(`/workshop/services/${serviceId}`).then(response => {
    return response;
  });
}

function updateService(serviceId, values) {
  return axiosService
    .put(`/workshop/services/${serviceId}`, values)
    .then(response => {
      return response;
    });
}

function createService(values) {
  return axiosService.post('/workshop/services', values).then(response => {
    return response;
  });
}

function deleteService(serviceId) {
  return axiosService
    .delete(`/workshop/services/${serviceId}`)
    .then(response => {
      return response;
    });
}

function findAllServices(queryString = '') {
  return axiosService.get(`/workshop/services${queryString}`).then(response => {
    return response;
  });
}

function acceptService(serviceId) {
  return axiosService
    .put(
      `/workshop/services/${serviceId}/status?newStatus=${serviceStatus.ACTIVE}`
    )
    .then(response => {
      return response;
    });
}
