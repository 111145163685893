import React, { useEffect, useState, useRef } from 'react'
import Loadable from 'react-loading-overlay';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import WorkshopLoyaltyProgramModal from '../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { I18n } from "react-redux-i18n";
import { statuteActions } from 'shared/actions/statute.actions';
import { apiUrl } from '../../../../environment';
import './Statute.css';

export const Statute = () => {
    const [documentIsFetching, setDocumentIsFetching] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const dispatch = useDispatch()

    const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
    const isFetching = useSelector(state => state.statute.isFetching);
    const getWindowSize = () => {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
      const handleWindowResize = () => {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const onDocumentLoad = ({ numPages }) => {
        setNumPages(numPages);
        setTimeout(() => setDocumentIsFetching(false), 1000);
    }

    useEffect(() => {
        setPageNumber(1);
        dispatch(statuteActions.findLoyaltyProgramStatutes());
    }, []);

    return (
        <ContentWrapper title='workshopLoyaltyProgram.statute' scrollY>
            {!isFetching ?
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '2rem' }} className="statute-wrapper">
                    {!loyaltyProgramStatute && <div style={{ textAlign: 'center', marginTop: '1rem', marginBottom: '1rem' }}>
                        <b><Translate value={"workshopLoyaltyProgram.editorStatuteEmptyPlaceholder"} /></b>
                    </div>}
                    <div style={{ margin: '1rem' }}>
                        <Button
                            id='mark-statute-valid-btn'
                            className='main-btn'
                            style={{ whiteSpace: 'normal' }}
                            onClick={() => dispatch({
                                type: updateDataSideBarConstants.SHOW,
                                variableComponent: (
                                    loyaltyProgramStatute ?
                                        <WorkshopLoyaltyProgramModal
                                            statuteActualization
                                        /> :
                                        <WorkshopLoyaltyProgramModal
                                            addStatute
                                        />
                                ),
                                title: loyaltyProgramStatute ? I18n.t("workshopLoyaltyProgram.actualization") : I18n.t("workshopLoyaltyProgram.addNew"),
                            })}>
                            <Translate value={loyaltyProgramStatute ? "workshopLoyaltyProgram.actualization" : "workshopLoyaltyProgram.addNew"} />
                        </Button>
                    </div>
                    {loyaltyProgramStatute &&
                        <>
                            <Loadable active={documentIsFetching} spinner>
                                <Document
                                    file={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}
                                    onLoadSuccess={(e) => onDocumentLoad(e)}
                                    loading={<div style={{ fontFamily: 'Montserrat-Medium' }}><Translate value={"workshopLoyaltyProgram.pdfLoading"} /></div>}
                                >
                                    <Page scale={windowSize?.innerWidth > 1187? 1.4 : (windowSize.innerWidth * 1.4)/1187} pageNumber={pageNumber} />
                                </Document>
                            </Loadable>
                                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', margin: '0 1rem 2rem 1rem' }}>
                                    <Translate className='mr-1 read-only-label' value='statutes.page' />
                                    {pageNumber}/{numPages}
                                    <div style={{flexGrow: 2}}>
                                        <Button
                                            id='page-minus'
                                            className='main-btn'
                                            disabled={pageNumber === 1}
                                            onClick={() =>
                                                setPageNumber(pageNumber - 1)
                                            }>
                                            <i className='fa fa-arrow-left' />
                                        </Button>
                                        <Button
                                            id='page-plus'
                                            className='main-btn'
                                            disabled={pageNumber === numPages}
                                            onClick={() =>
                                                setPageNumber(pageNumber + 1)
                                            }>
                                            <i className='fa fa-arrow-right' />
                                        </Button>
                                    </div>
                                    <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
                                        <Button id='download-statute' className='main-btn'>
                                            <Translate value='statutes.download' />
                                        </Button>
                                    </a>
                                </div>
                        </>}
                </div> :
                <Loadable active={isFetching}>
                    <div style={{ height: 150 }}></div>
                </Loadable>}
        </ContentWrapper>
    )
}
