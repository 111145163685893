/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {indexArray} from '../../utils/utils';
import {I18n, Translate} from 'react-redux-i18n';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';
import ContentWrapper from '../../components/content-wrapper/ContentWrapper';

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.renderValue = this.renderValue.bind(this);
  }

  translateSettingsDefinitionName(settings) {
    return settings.map(s => {
      s.translatedSettingsDefinitionName = I18n.t(
        `settings.${s.settingsDefinitionName}`
      );
      return s;
    });
  }

  renderValue(data) {
    switch (data.settingsValueType) {
      case 'BOOLEAN':
        return (
          <Checkbox
            checked={data.value === 'true'}
            onChange={e =>
              this.applyModification(
                data.settingsDefinitionName,
                e.checked.toString()
              )
            }
          />
        );
      case 'POSITIVE_NUMBER':
        return (
          <InputText
            keyfilter='pint'
            value={data.value}
            onChange={e =>
              this.applyModification(
                data.settingsDefinitionName,
                e.target.value.toString()
              )
            }
          />
        );
      default:
        return <Translate value='settings.unsupportedValueType' />;
    }
  }

  applyModification(settingsDefinitionName, value) {
    this.props.loadedSettings.find(
      s => s.settingsDefinitionName === settingsDefinitionName
    ).value = value;
    this.forceUpdate();
  }

  renderSaveBtn() {
    return (
      <div
        className='col-sm-2 col-12 order-sm-1 m-1'
        style={{minWidth: '150px'}}>
        <Button
          className='main-btn w-100'
          onClick={() => this.props.update(this.props.loadedSettings)}>
          <Translate value='settings.saveBtn' />
        </Button>
      </div>
    );
  }

  render() {
    const {isFetching, loadedSettings, headerBuilder, renderRowsDropdown} =
      this.props;

    return (
      <ContentWrapper title='settings.generalSettingsBtn'>
        <Loadable active={isFetching} spinner>
          <div className='col py-3'>
            <DataTable
              value={indexArray(
                this.translateSettingsDefinitionName(loadedSettings)
              )}
              paginator={true}
              rows={this.props.rows}
              globalFilter={this.props.globalFilter}
              header={headerBuilder(this.renderSaveBtn())}
              className='p-3'
              responsive={true}
              emptyMessage={I18n.t('settings.emptySettingsPlaceholder')}
              paginatorLeft={renderRowsDropdown()}>
              <Column
                field='index'
                className='lp-col'
                header={I18n.t('settings.lp')}
                sortable={true}
              />
              <Column
                field='translatedSettingsDefinitionName'
                header={I18n.t('settings.settingsDefinitionName')}
                sortable={true}
              />
              <Column
                field='value'
                header={I18n.t('settings.value')}
                body={this.renderValue}
                style={{width: '200px'}}
              />
              <Column field='additionalValue' />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

Settings.propTypes = {
  loadedSettings: PropTypes.array,
  update: PropTypes.func.isRequired,
};

Settings.defaultProps = {
  loadedSettings: [],
};
