/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {
  indexArray,
  isEmptyArray,
  rowsQuantityToDisplay,
} from 'shared/utils/utils';
import {NeededPartsInquiryStatusEnum} from '../utils/utils';
import NeededPartsInquiryForm from './needed-parts-inquiry-form/NeededPartsInquiryForm';

class NeededPartsInquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {rows: 10};

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.props.findNeededPartsInquiriesByWorkshopId(
      this.props.loggedWorkshopId
    );
    this.props.findAllPartCategories();
  }

  onRowsChange(e) {
    this.setState({rows: e.value});
    this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        onChange={e => this.onRowsChange(e)}
      />
    );
  }

  create(values) {
    const {loggedWorkshopId} = this.props;
    values.workshopId = loggedWorkshopId;
    values.status = NeededPartsInquiryStatusEnum.PENDING;
    this.props.createNeededPartsInquiry(values);
    setTimeout(() => {
      this.props.findNeededPartsInquiriesByWorkshopId(
        this.props.loggedWorkshopId
      );
    }, 1000);
  }

  resolvePartCategory(categoryId) {
    const {part} = this.props;
    const partCategories = part.categories;
    if (!isEmptyArray(partCategories)) {
      const currentCategory = partCategories.filter(
        category => category.id === categoryId
      );
      const currentCategoryName = currentCategory[0].name;
      return currentCategoryName;
    } else {
      return '';
    }
  }

  translateNeededPartStatus(status) {
    switch (status) {
      case NeededPartsInquiryStatusEnum.PENDING:
        return I18n.t('neededPartsInquiry.PENDING');
      case NeededPartsInquiryStatusEnum.ACCEPTED:
        return I18n.t('neededPartsInquiry.ACCEPTED');
      case NeededPartsInquiryStatusEnum.DECLINED:
        return I18n.t('neededPartsInquiry.DECLINED');
    }
  }

  render() {
    const {part, neededParts} = this.props;
    const partCategories = part.categories;
    const allPartCategories = partCategories
      ? partCategories.map(partCategory => ({
          label: partCategory.name,
          value: partCategory.id,
        }))
      : [];
    const neededPartsList = neededParts
      ? neededParts
          .map(neededPart => ({
            partCategory: this.resolvePartCategory(neededPart.partCategoryId),
            partName: neededPart.partName,
            partDescription: neededPart.partDescription,
            status: this.translateNeededPartStatus(neededPart.status),
          }))
          .reverse()
      : [];

    return (
      <ContentWrapper title='neededPartsInquiry.neededPartsFormTitle'>
        <Loadable active={this.props.isFetching} spinner>
          <NeededPartsInquiryForm
            parts={allPartCategories}
            onSubmit={this.create}
          />
          <div className='col'>
            <h3
              className='text-center'
              style={{fontFamily: 'Montserrat-Medium', color: '#2A3FAB'}}>
              <Translate value='neededPartsInquiry.neededPartsFormList' />
            </h3>
            <DataTable
              value={indexArray(neededPartsList)}
              paginator={true}
              rows={this.state.rows}
              className='p-3'
              responsive={true}
              sortField='status'
              sortOrder={1}
              emptyMessage={I18n.t(
                'neededPartsInquiry.emptyRequestedPartsPlaceholder'
              )}
              paginatorLeft={this.renderRowsDropdown()}
              ref={this.setDatatableRef}>
              <Column
                field='index'
                className='lp-col'
                header={I18n.t('neededPartsInquiry.lp')}
                sortable={true}
              />
              <Column
                field='partCategory'
                header={I18n.t('neededPartsInquiry.partCategory')}
                sortable={true}
              />
              <Column
                field='partName'
                header={I18n.t('neededPartsInquiry.partName')}
                sortable={true}
              />
              <Column
                field='partDescription'
                header={I18n.t('neededPartsInquiry.partDescription')}
                sortable={true}
              />
              <Column
                field='status'
                header={I18n.t('neededPartsInquiry.status')}
                sortable={true}
              />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, workshop, part} = state;
  const {data, neededParts, isFetching} = workshop;
  const loggedWorkshopId = data ? data.workshopId : null;

  return {i18n, isFetching, loggedWorkshopId, neededParts, part};
}

const mapDispatchToProps = {
  findAllPartCategories: partCategoryActions.findAllPartCategories,
  findNeededPartsInquiriesByWorkshopId:
    workshopActions.findNeededPartsInquiriesByWorkshopId,
  createNeededPartsInquiry: workshopActions.createNeededPartsInquiry,
};

export default connect(mapStateToProps, mapDispatchToProps)(NeededPartsInquiry);
