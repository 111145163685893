/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {userService} from '../../services/user.service';
import {getComponentDisplayName} from '../utils/utils.hoc';

export const withRedirectForLoggedUser = (
  WrappedComponent,
  redirectPath = '/',
  withRolePrefix = false
) => {
  class RedirectForLoggedUser extends React.Component {
    resolveRedirectPath() {
      const {state} = this.props.location;
      return state && state.from
        ? state.from
        : `${
            withRolePrefix
              ? `${userService.getPathWithRoleName()}${redirectPath}`
              : redirectPath
          }`;
    }

    render() {
      return this.props.isAuthenticated ? (
        <Redirect to={this.resolveRedirectPath()} />
      ) : (
        <WrappedComponent {...this.props} />
      );
    }
  }

  const mapStateToProps = ({user}) => {
    const {isAuthenticated} = user;
    return {isAuthenticated};
  };

  const connectedComponent = connect(mapStateToProps)(RedirectForLoggedUser);
  connectedComponent.displayName = `WithRedirectForLoggedUser(${getComponentDisplayName(
    WrappedComponent
  )})`;

  return connectedComponent;
};
