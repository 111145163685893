/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateConfirmNewPassword,
  validateNewPassword,
  validatePassword,
} from '../common-validators/common-validators';

export const validate = values => {
  const errors = {};
  errors.currentPassword = validatePassword(values.currentPassword);
  errors.newPassword = validateNewPassword(values);
  errors.confirmPassword = validateConfirmNewPassword(values);

  return errors;
};
