/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import CreateWorkshopForm from 'layout/admin/workshops/create-workshop/CreateWorkshopForm';
import {CreateWorkshopFormMode} from 'layout/admin/workshops/utils/utils';
import React, {Component} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {history} from 'shared/utils/history';
import {linkToUserActivation} from '../environment';
import './workshop-sign-up.css';

class WorkshopSignUp extends Component {
  constructor(props) {
    super(props);

    this.createNonPartnerWorkshop = this.createNonPartnerWorkshop.bind(this);
    this.onCreateSuccess = this.onCreateSuccess.bind(this);
  }

  createNonPartnerWorkshop(values) {
    const {createNonPartnerWorkshop} = this.props;
    createNonPartnerWorkshop(
      {...values, activationLink: linkToUserActivation},
      this.onCreateSuccess
    );
  }

  onCreateSuccess() {
    history.push('/user-activation-info');
  }

  render() {
    const {isFetching, history} = this.props;

    return (
      <ContentWrapper
        title='signup.cardTitle'
        containerClass='workshop-sign-up-container'>
        <Loadable active={isFetching} spinner>
          <CreateWorkshopForm
            onSubmit={this.createNonPartnerWorkshop}
            disableRootPanel={true}
            mode={CreateWorkshopFormMode.SIGN_UP}
            goBackHandler={history.goBack}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, workshop} = state;

  const {isFetching} = workshop;

  return {
    i18n,
    isFetching,
  };
}

const mapDispatchToProps = {
  createNonPartnerWorkshop: workshopActions.createNonPartnerWorkshop,
};

const connectedWorkshopSignUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkshopSignUp);
export {connectedWorkshopSignUp as WorkshopSignUp};
