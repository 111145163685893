/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {SubscriptionPlanForm} from './SubscriptionPlanForm';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {subscriptionPlanConstants} from '../../../constants/subscription-plan.constants';
import {subscriptionPlanActions} from '../../../actions/subscription-plan.actions';
import {validate} from './subscription-plan-form-validators';

export const SubscriptionPlanFormReduxFormWrapper = reduxForm({
  form: 'subscriptionPlanEditForm',
  validate,
  enableReinitialize: true,
})(SubscriptionPlanForm);

class SubscriptionPlanEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.activate = this.activate.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const {findSubscriptionPlan, subscriptionPlanId} = this.props;
    findSubscriptionPlan(subscriptionPlanId);
  }

  componentWillUnmount() {
    this.props.clearSubscriptionPlan();
  }

  update(data) {
    const {updateSubscriptionPlan, subscriptionPlanId} = this.props;
    updateSubscriptionPlan(subscriptionPlanId, data);
  }

  delete() {
    const {deleteSubscriptionPlan, subscriptionPlanId, onDeleteSuccess} =
      this.props;
    deleteSubscriptionPlan(subscriptionPlanId, onDeleteSuccess);
  }

  activate() {
    const {activateSubscriptionPlan, subscriptionPlanId, onActivateSuccess} =
      this.props;
    activateSubscriptionPlan(subscriptionPlanId, onActivateSuccess);
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <SubscriptionPlanFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          handleActivate={this.activate}
          handleDelete={this.delete}
        />
      </Loadable>
    );
  }
}

SubscriptionPlanEditForm.propTypes = {
  subscriptionPlanId: PropTypes.string.isRequired,
  findSubscriptionPlan: PropTypes.func.isRequired,
  updateSubscriptionPlan: PropTypes.func.isRequired,
  deleteSubscriptionPlan: PropTypes.func.isRequired,
  activateSubscriptionPlan: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
  onActivateSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, subscriptionPlan} = state;

  const initialValues = subscriptionPlan.activeSubscriptionPlan || {};
  const {isFetching} = subscriptionPlan;
  const disableMainFields = initialValues.status !== 'DRAFT';

  return {
    i18n,
    initialValues,
    isFetching,
    disableMainFields,
  };
}

const mapDispatchToProps = {
  findSubscriptionPlan: subscriptionPlanActions.findSubscriptionPlan,
  clearSubscriptionPlan: () => ({
    type: subscriptionPlanConstants.CLEAR_SUBSCRIPTION_PLAN,
  }),
  updateSubscriptionPlan: subscriptionPlanActions.updateSubscriptionPlan,
  deleteSubscriptionPlan: subscriptionPlanActions.deleteSubscriptionPlan,
  activateSubscriptionPlan: subscriptionPlanActions.activateSubscriptionPlan,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanEditForm);
