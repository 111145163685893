/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Dropdown} from 'primereact/dropdown';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import {DATE_FORMAT_SHORT, dateUtils, YEAR_FORMAT} from '../../utils/date.utils';
import {rowsQuantityToDisplay} from '../../utils/utils';
import {getComponentDisplayName, getNestedObject} from '../utils/utils.hoc';
import {composeServicesInCategories} from './data-table-core-hoc-utils';
import './data-table-hoc.css';

export const withDataTableCore = (WrappedComponent, withAddBtn = false) => {
  class DataTableCoreHoc extends React.Component {
    constructor(props) {
      super(props);

      this.state = {rows: 10, globalFilter: '', addSectionIsOpen: false};

      this.onChangeRow = this.onChangeRow.bind(this);
      this.onGlobalFilterHandler = this.onGlobalFilterHandler.bind(this);
      this.renderAddBtn = this.renderAddBtn.bind(this);
      this.onClickAddBtn = this.onClickAddBtn.bind(this);
      this.closeAddSection = this.closeAddSection.bind(this);
      this.renderCountryCol = this.renderCountryCol.bind(this);
      this.renderServicesCol = this.renderServicesCol.bind(this);
      this.renderCarDataCol = this.renderCarDataCol.bind(this);
      this.renderRowsDropdown = this.renderRowsDropdown.bind(this);
    }

    renderRowsDropdown() {
      return (
        <Dropdown
          options={rowsQuantityToDisplay}
          className='data-table-rows-dropdown'
          value={this.state.rows}
          onChange={this.onChangeRow}
        />
      );
    }

    onChangeRow(event) {
      this.setState({rows: event.value});
    }

    renderGlobalFilter() {
      return (
        <div
          className='col-sm-4 col-12 px-3 m-1 order-last'
          style={{minWidth: '150px'}}>
          <Input
            type='search'
            placeholder={I18n.t('workshops.searchPlaceholder')}
            onInput={this.onGlobalFilterHandler}
            className='global-filter'
          />
        </div>
      );
    }

    onGlobalFilterHandler(event) {
      this.setState({globalFilter: event.target.value});
    }

    renderDateCol(data, column) {
      return dateUtils.formatDate(data[column.field], DATE_FORMAT_SHORT);
    }

    renderCountryCol(data, column) {
      data[column.field] = I18n.t(
        `country.${getNestedObject(
          data,
          column.fieldValuInJson.split('.')
        ).toLowerCase()}`
      );
      return data[column.field];
    }

    renderServicesCol(data) {
      const {serviceCategories} = this.props;
      const {servicesIds} = data;
      const composedServicesInCategories = composeServicesInCategories(
        servicesIds,
        serviceCategories
      );
      return composedServicesInCategories.map(cat => (
        <div key={cat.serviceCategoryName}>
          <span style={{fontWeight: 'bold'}}>{cat.serviceCategoryName}: </span>
          {cat.services.map(s => s.name).join(', ')}
        </div>
      ));
    }

    renderCarDataCol(data) {
      data.carData = `${data.carBrand} ${data.carModel} ${data.carProductionYear}`;
      return data.carData;
    }

    onClickAddBtn() {
      this.setState({addSectionIsOpen: !this.state.addSectionIsOpen});
    }

    closeAddSection() {
      this.setState({addSectionIsOpen: false});
    }

    renderAddBtn(disableAddBtn) {
      return (
        <div
          className='col-sm-2 col-12 px-1 my-sm-0 my-2 order-sm-1'
          style={{minWidth: '150px'}}>
          <Button
            className='main-btn'
            style={{width: '100%', height: '100%'}}
            disabled={disableAddBtn}
            onClick={this.onClickAddBtn}>
            <Translate value='dataTableCore.addBtn' />
          </Button>
        </div>
      );
    }

    render() {
      const headerBuilder = (
        customHeaderElements = <Fragment />,
        props = {}
      ) => (
        <div className='row'>
          <div className='col-sm-1 col-12 mr-auto order-1' />
          {withAddBtn && this.renderAddBtn(props.disableAddBtn)}
          {customHeaderElements}
          {this.renderGlobalFilter()}
        </div>
      );
      return (
        <WrappedComponent
          {...this.props}
          globalFilter={this.state.globalFilter}
          rows={this.state.rows}
          header={headerBuilder()}
          headerBuilder={headerBuilder}
          renderDateCol={this.renderDateCol}
          addSectionIsOpen={this.state.addSectionIsOpen}
          closeAddSection={this.closeAddSection}
          renderCountryCol={this.renderCountryCol}
          renderServicesCol={this.renderServicesCol}
          renderCarDataCol={this.renderCarDataCol}
          renderRowsDropdown={this.renderRowsDropdown}
        />
      );
    }
  }

  const mapStateToProps = ({service}) => {
    const {categories} = service;
    return {serviceCategories: categories};
  };

  const connectedComponent = connect(mapStateToProps)(DataTableCoreHoc);
  connectedComponent.displayName = `WithDataTableCore(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return connectedComponent;
};
