/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export function isContentLoaded(isFetching) {
  if (isFetching === true) {
    return ' not-clickable';
  }
  return '';
}
