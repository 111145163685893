/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './date-picker.css';
import {I18n} from 'react-redux-i18n';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/pl';
import {DatePickerInput} from '../date-time-picker/RenderDateTimePicker';
import {errorColor} from '../../../utils/style.utils';

const dateFormat = 'YYYY-MM-DD';

class RenderDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onClickClearIcon = this.onClickClearIcon.bind(this);
  }

  componentDidUpdate() {
    const {input, minDate, disabled} = this.props;

    if (
      input.value &&
      !disabled &&
      moment(minDate).isAfter(moment(input.value))
    )
      input.onChange(moment(minDate).format(dateFormat));
  }

  onClickClearIcon() {
    this.props.input.onChange(null);
  }

  handleChange(date) {
    const {input, handleChangeCallback} = this.props;

    const formattedDate = moment(date).format(dateFormat);
    input.onChange(formattedDate);
    handleChangeCallback(formattedDate);
  }

  render() {
    const {
      input,
      label,
      required,
      disabled,
      minDate,
      showClearIcon,
      meta: {touched, error},
    } = this.props;

    return (
      <div>
        <div className='mt-3 row'>
          <div className='col-12'>
            <span
              className='field-label'
              style={touched && error ? {color: errorColor} : null}>
              {label}
              {required && <span style={{color: errorColor}}>*</span>}
            </span>
          </div>
          <div className='col-12 date-time-picker-container my-auto'>
            <div className='ui-inputgroup'>
              <DatePicker
                customInput={<DatePickerInput error={touched && error} />}
                placeholderText={I18n.t('datePicker.chooseDate')}
                minDate={!disabled ? moment(minDate) : null}
                dateFormat={dateFormat}
                selected={input.value ? moment(input.value) : null}
                onChange={this.handleChange}
                disabled={disabled}
              />

              {input.value && showClearIcon && (
                <span
                  className='p-inputgroup-addon no-left-border clickable'
                  style={{width: '3em'}}
                  onClick={this.onClickClearIcon}>
                  <i className='clear-icon fa fa-close m-auto' />
                </span>
              )}
            </div>
          </div>
        </div>
        <div id='text-area-error-msg'>
          {touched && error && (
            <span className='error-info'>
              {I18n.t(error.key, error.params)}
            </span>
          )}
        </div>
      </div>
    );
  }
}

RenderDatePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({touched: PropTypes.bool, error: PropTypes.object}),
  handleChangeCallback: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
};

RenderDatePicker.defaultProps = {
  handleChangeCallback: () => {},
  required: false,
  disabled: false,
  minDate: null,
};

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n};
}

export default connect(mapStateToProps)(RenderDatePicker);
