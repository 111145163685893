/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {Field, reduxForm} from 'redux-form';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import renderField from 'shared/components/form/text-field/RenderField';
import {validate} from './validators';

class NeededPartsInquiryForm extends React.Component {
  itemTemplate(option) {
    const optionId = 'option-' + option.value;
    return (
      <div className='ui-helper-clearfix'>
        <span id={optionId} style={{margin: '.5em .25em 0 0'}}>
          {option.label}
        </span>
      </div>
    );
  }

  render() {
    const {invalid, parts, handleSubmit} = this.props;

    return (
      <div className='px-sm-5 py-sm-5 pb-5'>
        <Alert color='warning' className='text-center'>
          <Translate value='neededPartsInquiry.neededPartsInquiryDescription' />
        </Alert>
        <form className='valuation-inquiry-form' onSubmit={handleSubmit}>
          <Field
            name='partCategoryId'
            component={RenderDropdown}
            options={parts}
            placeholder={I18n.t('neededPartsInquiry.partCategoryPlaceholder')}
            label={I18n.t('neededPartsInquiry.partCategory')}
            itemTemplate={this.itemTemplate}
            icon='fa fa-folder'
            required={true}
          />
          <Field
            name='partName'
            type='text'
            component={renderField}
            required={true}
            label={I18n.t('neededPartsInquiry.partName')}
            icon='fa fa-cube'
          />
          <Field
            name='partDescription'
            type='text'
            component={renderField}
            required={false}
            label={I18n.t('neededPartsInquiry.partDescription')}
            icon='fa fa-cubes'
          />
          <div className='text-sm-right text-center mt-3'>
            <Button
              className='main-btn'
              size='sm'
              type='submit'
              disabled={invalid}>
              <Translate value='neededPartsInquiry.submitNeededPartsInquiry' />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

NeededPartsInquiryForm = reduxForm({
  form: 'neededPartsInquiryForm',
  validate: validate,
})(NeededPartsInquiryForm);

const mapStateToProps = state => {
  const {i18n} = state;
  return {i18n};
};

export default connect(mapStateToProps)(NeededPartsInquiryForm);
