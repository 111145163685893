/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  updateServicesToAdd,
  updateServicesToDelete,
} from 'layout/workshop/workshops-services/utils/utils';
import {workshopConstants} from '../constants/workshop.constants';
import {SyncState} from '../utils/sync';
import {getOrEmptyArray} from '../utils/utils';
import {removeMatchItem} from '../utils/utils';

export function workshopReducer(
  state = {
    data: {},
    workshopMap: SyncState.UP_TO_DATE,
    isFetching: false,
    servicesToAdd: [],
    servicesToDelete: [],
    neededPartsDataSyncState: SyncState.UP_TO_DATE,
    barcodesSuccess: null,
    barcodesData: [],
    acBarcodesData: [],
  },
  action
) {
  switch (action.type) {
    case workshopConstants.WORKSHOP_REQUEST_START:
      return {...state, isFetching: true};
    case workshopConstants.WORKSHOP_REQUEST_END:
      return {...state, isFetching: false};
    case workshopConstants.LOAD_WORKSHOP_DATA:
      return {...state, selected: action.payload.data};
    case workshopConstants.LOAD_WORKSHOPS_DATA:
      return {
        ...state,
        workshops: action.payload.data,
        workshopDataSyncState: SyncState.UP_TO_DATE,
      };
    case workshopConstants.REFRESH_WORKSHOPS_DATA:
      return {...state, workshopDataSyncState: SyncState.OBSOLETE};
    case workshopConstants.LOAD_WORKSHOP_SERVICES:
      return {...state, services: action.payload.data};
    case workshopConstants.LOAD_LOGGED_WORKSHOP_DATA:
      return {...state, data: action.payload.data};
    case workshopConstants.ADD_SERVICE:
      return {
        ...state,
        servicesToAdd: state.services.some(service => {
          return service.id === action.payload.id;
        })
          ? [...state.servicesToAdd]
          : [...state.servicesToAdd, action.payload],
        servicesToDelete: state.services.some(service => {
          return service.id === action.payload.id;
        })
          ? removeMatchItem(state.servicesToDelete, action.payload)
          : state.servicesToDelete,
      };
    case workshopConstants.DELETE_SERVICE:
      return {
        ...state,
        servicesToDelete: !state.services.some(service => {
          return service.id === action.payload.id;
        })
          ? [...state.servicesToDelete]
          : [state.servicesToDelete, action.payload],
        serviceToAdd: removeMatchItem(state.servicesToAdd, action.payload),
      };
    case workshopConstants.CLEAR_SERVICES:
      return {...state, servicesToDelete: [], servicesToAdd: []};
    case workshopConstants.ACCEPT_INVITE_MESSAGE:
      return {...state, acceptInviteMessageKey: action.payload.message};
    case workshopConstants.CLEAR_SELECTED_WORKSHOP_DATA:
      return {...state, selected: null};
    case workshopConstants.LOAD_NEEDED_PARTS_INQUIRIES:
      return {
        ...state,
        neededParts: action.payload.data,
        neededPartsDataSyncState: SyncState.UP_TO_DATE,
      };
    case workshopConstants.SET_UPDATED_ADDRESS:
      return {
        ...state,
        data: {...state.data, address: action.payload.data},
        workshopMap: SyncState.OBSOLETE,
      };
    case workshopConstants.REFRESH_ADDRESS_MAP:
      return {...state, workshopMap: SyncState.UP_TO_DATE};
    case workshopConstants.SET_UPDATED_DETAILS:
      return {
        ...state,
        data: {...state.data, workshopDetails: action.payload.data},
      };
    case workshopConstants.REFRESH_NEEDED_PARTS:
      return {...state, neededPartsDataSyncState: SyncState.OBSOLETE};
    case workshopConstants.LOAD_WORKSHOP_CALENDAR_EVENTS:
      return {
        ...state,
        workshopCalendarEvents: action.payload.data,
        workshopCalendarEventsSyncState: SyncState.UP_TO_DATE,
      };
    case workshopConstants.LOAD_WORKSHOP_EXTERNAL_CALENDARS:
      return {
        ...state,
        workshopExternalCalendars: action.payload.data,
        workshopExternalCalendarsSyncState: SyncState.UP_TO_DATE,
      };
    case workshopConstants.REFRESH_WORKSHOP_EXTERNAL_CALENDARS:
      return {
        ...state,
        workshopExternalCalendarsSyncState: SyncState.OBSOLETE,
      };
    case workshopConstants.LOAD_WORKSHOP_EXTERNAL_CALENDAR:
      return {...state, workshopExternalCalendar: action.payload.data};
    case workshopConstants.CLEAR_WORKSHOP_EXTERNAL_CALENDAR:
      const {workshopExternalCalendar, ...otherState} = state;
      return otherState;
    case workshopConstants.LOAD_WORKSHOP_WORKING_DAYS:
      return {
        ...state,
        workshopWorkingDays: action.payload.data,
        workshopWorkingDaysSyncState: SyncState.UP_TO_DATE,
      };
    case workshopConstants.REFRESH_WORKSHOP_WORKING_DAYS:
      return {...state, workshopWorkingDaysSyncState: SyncState.OBSOLETE};
    case workshopConstants.REPLACE_CALENDAR_EVENT:
      return {
        ...state,
        workshopCalendarEvents: replaceCalendarEvent(
          state.workshopCalendarEvents,
          action.payload.data
        ),
      };
    case workshopConstants.REFRESH_WORKSHOP_CALENDAR_EVENTS:
      return {...state, workshopCalendarEventsSyncState: SyncState.OBSOLETE};
    case workshopConstants.LOAD_WORKSHOP_WORKLOAD:
      return {...state, workshopWorkload: action.payload.data};

    case workshopConstants.WORKSHOP_ADD_BARCODE_SUCCESS:
      return {...state, barcodesSuccess: true};

    case workshopConstants.WORKSHOP_ADD_BARCODE_CLEAR:
      return {...state, barcodesSuccess: null};

    case workshopConstants.WORKSHOP_LOAD_BARCODES:
      return {...state, barcodesData: action.payload};

    case workshopConstants.WORKSHOP_LOAD_AC_BARCODES:
      return {...state, acBarcodesData: action.payload};

    case workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_DATA:
      return {...state, potentialSuspiciousWorkshop: action.payload};

    case workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR:
      return {...state, potentialSuspiciousWorkshop: null};
    default:
      return state;
  }
}

function replaceCalendarEvent(events, replacingEvent) {
  return getOrEmptyArray(events).map(event =>
    event.id === replacingEvent.id ? replacingEvent : event
  );
}
