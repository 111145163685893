/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Parts from './Parts';
import {connect} from 'react-redux';
import NeededParts from './NeededParts';

export default class PartsContainer extends React.Component {
  render() {
    const {...props} = this.props;
    const id = this.props.match.params.id ? this.props.match.params.id : '';

    return id === 'needed-parts' ? (
      <NeededParts {...props} />
    ) : (
      <Parts {...props} />
    );
  }
}

const connectedPartsContainer = connect(null)(PartsContainer);

export {connectedPartsContainer as PartsContainer};
