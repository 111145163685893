/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import Avatar from 'react-avatar-edit';
import {renderToString} from 'react-dom/server';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import {cutPrefixFromBase64String} from 'shared/components/form/file-upload/utils/file-utils';
import {getOrDefault} from 'shared/utils/utils';
import defaultAvatar from '../../../../assets/images/default-avatar.png';
import './user-avatar.css';

class UserAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      src: null,
      isOpenModal: false,
      valid: false,
      imageIsChosen: false,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setAvatar = this.setAvatar.bind(this);
    this.modalLoaded = this.modalLoaded.bind(this);
    this.onFileLoad = this.onFileLoad.bind(this);
  }

  componentDidMount() {
    if (!this.props.modalMode) this.translateModalLabel();
  }

  toggle() {
    if (this.props.canUpdate)
      this.setState({isOpenModal: !this.state.isOpenModal, valid: false});
  }

  translateModalLabel() {
    document
      .querySelector('.avatar-wrapper-class')
      .querySelector('label').innerHTML = renderToString(
      <Translate value='userAvatar.chooseFile' />
    );
  }

  modalLoaded() {
    this.translateModalLabel();
    this.setState({imageIsChosen: false});
  }

  onClose() {
    this.translateModalLabel();
    this.setState({valid: false, preview: null, imageIsChosen: false});
  }

  onCrop(preview) {
    this.setState({valid: true, preview: preview});
  }

  setAvatar(setDefault = false) {
    const {data, dispatch, disableInternalUpdate, onUpdateAvatar} = this.props;
    const avatarDto = {
      avatar: setDefault ? null : cutPrefixFromBase64String(this.state.preview),
    };

    if (!disableInternalUpdate) {
      dispatch(userActions.updateAvatar(data.id, avatarDto));
    }

    onUpdateAvatar(avatarDto);
    this.toggle();
  }

  onFileLoad() {
    this.setState({imageIsChosen: true});
  }

  renderAvatar() {
    const {data, disableInternalAvatar, externalAvatar} = this.props;

    let avatar;
    if (disableInternalAvatar) {
      avatar = getOrDefault(externalAvatar, defaultAvatar);
    } else {
      avatar = data && data.avatar ? data.avatar : defaultAvatar;
    }

    const isDefault = avatar === defaultAvatar;

    return (
      <div className='avatar-container'>
        <img
          src={avatar}
          alt='Avatar'
          style={{cursor: 'pointer'}}
          onClick={this.toggle}
          className='ml-sm-0 ml-3'
        />
        {isDefault && (
          <span className='avatar-default-tip' onClick={this.toggle}>
            <Translate value='userAvatar.defaultTip' />
          </span>
        )}
        {!isDefault && (
          <span className='avatar-custom-tip' onClick={this.toggle}>
            <i className='fa fa-pencil-square fa-3x' />
          </span>
        )}
      </div>
    );
  }

  renderPlainAvatarEdit() {
    return (
      <Fragment>
        <div className='avatar-smaller-container avatar-wrapper-class'>
          <Avatar
            id='avatarId'
            width={'100%'}
            height={72}
            mimeTypes={'image/jpeg,image/png'}
            onFileLoad={this.onFileLoad}
            onCrop={this.onCrop}
            onClose={this.onClose}
            src={this.state.src}
          />
        </div>
        <div className='text-right mt-1'>
          <Button
            className='main-btn'
            disabled={!this.state.valid}
            style={!this.state.valid ? {opacity: 0.4} : {}}
            onClick={() => this.setAvatar()}
            id='send-btn'>
            <Translate value='userAvatar.sendBtn' />
          </Button>
          <Button
            className='main-btn'
            onClick={() => this.setAvatar(true)}
            id='set-default-btn'>
            <Translate value='userAvatar.setDefault' />
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    if (!this.props.modalMode) return this.renderPlainAvatarEdit();

    return (
      <div className='mt-3 mb-3 text-center'>
        {this.renderAvatar()}
        <Modal
          isOpen={this.state.isOpenModal}
          toggle={this.toggle}
          size='sm'
          backdrop={this.state.imageIsChosen ? 'static' : true}
          onOpened={this.modalLoaded}>
          <ModalHeader toggle={this.toggle}>
            <Translate value='userAvatar.setAvatar' />
          </ModalHeader>
          <ModalBody
            className='mx-3 avatar-wrapper-class'
            style={{overflow: 'hidden'}}>
            <Avatar
              id='avatarId'
              width={'100%'}
              height={200}
              mimeTypes={'image/jpeg,image/png'}
              onFileLoad={this.onFileLoad}
              onCrop={this.onCrop}
              onClose={this.onClose}
              src={this.state.src}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              className='main-btn'
              disabled={!this.state.valid}
              style={!this.state.valid ? {opacity: 0.4} : {}}
              onClick={() => this.setAvatar()}
              id='send-btn'>
              <Translate value='userAvatar.sendBtn' />
            </Button>
            <Button
              className='main-btn'
              onClick={() => this.setAvatar(true)}
              id='set-default-btn'>
              <Translate value='userAvatar.setDefault' />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UserAvatar.propTypes = {
  disableInternalUpdate: PropTypes.bool,
  onUpdateAvatar: PropTypes.func,
  disableInternalAvatar: PropTypes.bool,
  externalAvatar: PropTypes.string,
  modalMode: PropTypes.bool,
};

UserAvatar.defaultProps = {
  canUpdate: true,
  disableInternalUpdate: false,
  onUpdateAvatar: () => {},
  disableInternalAvatar: false,
  modalMode: true,
};

function mapStateToProps({user}) {
  const {data} = user;
  return {data};
}

export default connect(mapStateToProps)(UserAvatar);
