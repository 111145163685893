/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import './App.css';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Layout from './layout/Layout';
import {SignUpContainer} from './sign-up/SignUp';
import {connect} from 'react-redux';
import {history} from './shared/utils/history';
import ResetPassword from './reset-password/ResetPassword';
import {ProtectedRoute} from './shared/components/protected-route/ProtectedRoute';
import {UserActivationInfo} from './user-activation/UserActivationInfo';
import {UserActivation} from './user-activation/UserActivation';
import AccessDenied from './access-dnied/AccessDenied';
import HomePage from './home-page/HomePage';
import {Translate} from 'react-redux-i18n';
import PublicValuationInquiry from './valuation-inquiry/PublicValuationInquiry.js';
import WorkshopValuationInquiry from './valuation-inquiry/WorkshopValuationInquiry.js';
import {NotFound} from './404-not-found/NotFound';
import {withPublicViewCore} from './shared/hoc/public-view/PublicViewHoc';
import './shared/styles/paginator.css';
import {WorkshopSignUp} from './workshop-sign-up/WorkshopSignUp';
import 'primeicons/primeicons.css';

export const HomePagePvc = withPublicViewCore(HomePage);
export const SignUpContainerPvc = withPublicViewCore(SignUpContainer);
export const AccessDeniedPvc = withPublicViewCore(AccessDenied);
export const NotFoundPvc = withPublicViewCore(NotFound);
export const ResetPasswordPvc = withPublicViewCore(ResetPassword);
export const UserActivationInfoPvc = withPublicViewCore(UserActivationInfo);
export const UserActivationPvc = withPublicViewCore(UserActivation);
export const WorkshopSignUpPvc = withPublicViewCore(WorkshopSignUp);
export const PublicValuationInquiryPvc = withPublicViewCore(
  PublicValuationInquiry
);
export const WorkshopValuationInquiryPvc = withPublicViewCore(
  WorkshopValuationInquiry
);

class App extends React.Component {
  componentDidUpdate(prevProps) {
    const updateSideBarOpened =
      !prevProps.updateSideBarVisible && this.props.updateSideBarVisible;
    const hasUserNavigated = this.props.appPath !== prevProps.appPath;
    if (updateSideBarOpened || hasUserNavigated) {
      window.scrollTo(0, 0);
    }
  }

  versionFooter() {
    const pjson = require('.././package.json');
    return (
      <div className='version-footer-container'>
        <div className='version-footer-content'>
          <Translate value='footer.appVersion' />: {pjson.version}
        </div>
      </div>
    );
  }
  render() {
    const {user} = this.props;

    return (
      <div>
        <HashRouter history={history}>
          <Switch>
            <Route exact path='/home' component={HomePagePvc} />
            <Route exact path='/sign-up' component={SignUpContainerPvc} />
            <Route
              exact
              path='/public-valuation-inquiry'
              component={PublicValuationInquiryPvc}
            />
            <Route
              exact
              path='/workshop-valuation-inquiry/:workshopId'
              component={WorkshopValuationInquiryPvc}
            />
            <Route exact path='/access-denied' component={AccessDeniedPvc} />
            <Route exact path='/not-found' component={NotFoundPvc} />
            <Route exact path='/reset-password' component={ResetPasswordPvc} />
            <Route
              exact
              path='/user-activation-info'
              component={UserActivationInfoPvc}
            />
            <Route
              exact
              path='/user-activation'
              component={UserActivationPvc}
            />
            <Route
              exact
              path='/workshop-sign-up'
              component={WorkshopSignUpPvc}
            />
            <ProtectedRoute
              path='/'
              component={Layout}
              isAuthenticated={user.isAuthenticated}
            />
          </Switch>
        </HashRouter>
        {this.versionFooter()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user, updateSideBar} = state;
  const appPath = window.location.hash;
  const updateSideBarVisible =
    updateSideBar && updateSideBar.visible ? updateSideBar.visible : false;

  return {user, appPath, updateSideBarVisible};
}

export default connect(mapStateToProps)(App);
