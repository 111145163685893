/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {orderActions} from 'shared/actions/order.actions';
import {userService} from 'shared/services/user.service';
import {dateUtils} from 'shared/utils/date.utils';
import {getOrEmptyObj} from 'shared/utils/utils';
import {OrderCustomerRatingListModal} from '../../order-rating/OrderCustomerRatingListModal';
import WorkshopInactiveStatusAlert from '../../utils/WorkshopInactiveStatusAlert';
import {ValuationInquiryStatusEnum} from '../utils/utils';
import RenderValuationInquiryDetails from './render-valuation-inquiry-details/RenderValuationInquiryDetails';
import RenderValuationInquiryServicesDetails from './render-valuation-inquiry-details/RenderValuationInquiryServicesDetails';
import {mapToDetailsObject} from './render-valuation-inquiry-details/utils';

class ValuationInquiryDetails extends Component {
  constructor(props) {
    super(props);
    this.isDisabledPrepareValBtn = this.isDisabledPrepareValBtn.bind(this);
    this.generatePdfById = this.generatePdfById.bind(this);
  }

  isDisabledPrepareValBtn() {
    return (
      (this.props.valuationInquiry &&
        this.props.valuationInquiry.status ===
          ValuationInquiryStatusEnum.RESPONDED) ||
      this.props.shouldShowWorkshopStatusAlert ||
      this.props.blockPrepareValuation
    );
  }

  generatePdfById() {
    const {valuationInquiry} = this.props;
    if (valuationInquiry)
      this.props.generateValuationInquiryPdfById(valuationInquiry.id);
  }

  shouldShowCustomerRatings() {
    const {valuationInquiry} = this.props;
    return (
      userService.isWorkshop() &&
      valuationInquiry &&
      valuationInquiry.senderUserId
    );
  }

  renderOrderCustomerRatingListModal() {
    const {valuationInquiry} = this.props;
    return (
      this.shouldShowCustomerRatings() && (
        <OrderCustomerRatingListModal
          customerId={valuationInquiry.senderUserId}
        />
      )
    );
  }

  renderButtons() {
    const {prepareValuation, valuationInquiry} = this.props;
    return (
      <div className='row my-3 text-sm-right text-center mr-1'>
        <div className='col p-0'>
          {this.renderOrderCustomerRatingListModal()}
          <Button
            id='generate-pdf'
            className='main-btn fixed-width-big-btn'
            onClick={this.generatePdfById}>
            <Translate value='valuationInquiryDetails.generatePdfBtn' />
          </Button>
          {prepareValuation && (
            <Button
              id='prepare-valuation'
              className='main-btn fixed-width-big-btn'
              disabled={this.isDisabledPrepareValBtn()}
              onClick={prepareValuation(valuationInquiry)}>
              <Translate
                value={'valuationInquiryDetails.prepareValuationBtn'}
              />
            </Button>
          )}
        </div>
      </div>
    );
  }

  renderValuationInquiryNumber() {
    const {valuationInquiry} = this.props;
    return (
      valuationInquiry &&
      valuationInquiry.inquiryNumber &&
      valuationInquiry.inquiryNumber.inquiryNumberString
    );
  }

  renderBlockPrepareValuationAlert() {
    const {prepareValuation, valuationInquiry, blockPrepareValuation} =
      this.props;
    const protectionEndDate = getOrEmptyObj(valuationInquiry).protectionEndDate;

    return (
      prepareValuation &&
      blockPrepareValuation &&
      protectionEndDate && (
        <Alert color='warning' className='text-center'>
          <Translate value='valuationInquiryDetails.blockPrepareValuationAlertPrefix' />
          :&nbsp;
          {dateUtils.formatDate(protectionEndDate, 'YYYY-MM-DD HH:mm')}
          <br />
          <Translate value='valuationInquiryDetails.blockPrepareValuationAlertSuffix' />
        </Alert>
      )
    );
  }

  render() {
    const {valuationInquiry, shouldShowWorkshopStatusAlert} = this.props;

    return (
      <Fragment>
        <WorkshopInactiveStatusAlert show={shouldShowWorkshopStatusAlert} />
        {this.renderBlockPrepareValuationAlert()}
        <div className='m-auto pt-3' style={{width: '90%'}}>
          <RenderValuationInquiryServicesDetails
            headerTitleDetails={'servicesDataTitle'}
            servicesIds={valuationInquiry && valuationInquiry.servicesIds}
          />
          <RenderValuationInquiryDetails
            headerTitleDetails={'carDataTitle'}
            detailsData={
              valuationInquiry && mapToDetailsObject('car', valuationInquiry)
            }
          />
          <RenderValuationInquiryDetails
            headerTitleDetails={'userDataTitle'}
            customerUserId={
              this.shouldShowCustomerRatings() && valuationInquiry.senderUserId
            }
            detailsData={
              valuationInquiry && mapToDetailsObject('user', valuationInquiry)
            }
          />
          {this.renderButtons()}
        </div>
      </Fragment>
    );
  }
}

ValuationInquiryDetails.propTypes = {
  prepareValuation: PropTypes.func,
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
};

function isValInqAfterProtectionPeriodOrWorkshopHasSubscription(
  valuationInquiry,
  subscriptionEndDate
) {
  const protectionEndDate = getOrEmptyObj(valuationInquiry).protectionEndDate;
  const isAfterProtectionPeriod =
    protectionEndDate && moment().isAfter(moment(protectionEndDate));
  const hasSubscription =
    subscriptionEndDate && moment(subscriptionEndDate).isAfter(moment());

  return isAfterProtectionPeriod || hasSubscription;
}

function mapStateToProps(state) {
  const {i18n, order, subscription} = state;
  const {valuationInquiry} = order;
  const {subscriptionEndDate} = subscription;

  const blockPrepareValuation =
    !isValInqAfterProtectionPeriodOrWorkshopHasSubscription(
      valuationInquiry,
      subscriptionEndDate
    );

  return {i18n, valuationInquiry, blockPrepareValuation};
}

const mapDispatchToProps = {
  generateValuationInquiryPdfById: orderActions.generateValuationInquiryPdfById,
};

const connectedValuationInquiryDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiryDetails);
export {connectedValuationInquiryDetails as ValuationInquiryDetails};
