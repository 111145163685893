/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {formatPhoneNumber} from 'shared/components/form/formatter/PhoneNumberFormatter';
import {userService} from 'shared/services/user.service';
import defaultAvatar from '../../../../assets/images/default-avatar.png';
import WorkshopMap from '../workshop-map/WorkshopMap';
import EditWorkshopForm from '../workshop-profile-form/EditWorkshopForm';
import './workshop-profile-container.css';
import UserAvatar from "../../../user/user-profile/avatar/UserAvatar";

class WorkshopProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editSectionOpen: false};
    this.submitEditWorkshopForm = this.submitEditWorkshopForm.bind(this);
    this.submitChangePasswordForm = this.submitChangePasswordForm.bind(this);
  }

  submitChangePasswordForm(values) {
    this.props.changePassword(userService.getUserId(), values);
  }

  submitEditWorkshopForm(values) {
    this.props.updateWorkshopByUserId(this.props.workshopData.userId, values);
    this.setState({editSectionOpen: false});
  }

  renderAvatar() {
    const {userData} = this.props;

    const avatar =
      userData && userData.avatar ? userData.avatar : defaultAvatar;

    return (
      <div className='avatar-container mb-3'>
        <img
          src={avatar}
          style={{cursor: 'default', maxWidth: '100px', maxHeight: '100px'}}
          alt='Avatar'
        />
      </div>
    );
  }

  getDataFromKey(key, data, dataToDisplay) {
    if (
      dataToDisplay.indexOf(key) >= 0 &&
      ['phoneNumber', 'mobilePhoneNumber', 'faxNumber'].indexOf(key) >= 0
    )
      return data[key].phoneNumber
        ? `${data[key].phoneNumberPrefix} ${formatPhoneNumber(
            data[key].phoneNumber
          )}`
        : `${I18n.t('userProfileData.noData')}`;
    else if (dataToDisplay.indexOf(key) >= 0)
      return data[key] ? data[key] : `${I18n.t('userProfileData.noData')}`;
  }

  renderData(key, data, dataToDisplay) {
    if (dataToDisplay.indexOf(key) >= 0)
      return (
        <div className='row user-data-container' key={key}>
          <div className='col-md-6 col-12 text-md-right user-data-label'>
            <Translate value={`userProfileData.${key}Label`} />
          </div>
          <div className='col-md-6 col-12 mb-2 pl-md-0'>
            {this.getDataFromKey(key, data, dataToDisplay)}
          </div>
        </div>
      );
  }

  renderWorkshopData() {
    const {workshopData} = this.props;
    const detailsDataToDisplay = [
      'workshopName',
      'ownerName',
      'phoneNumber',
      'mobilePhoneNumber',
      'faxNumber',
      'nip',
      'regon',
      'krs',
      'description',
    ];
    const addressDataToDisplay = [
      'state',
      'city',
      'streetAddress',
      'postalCode',
      'postalCity',
    ];

    return (
      <div>
        {!!workshopData.workshopDetails && (
          <div className='workshop-details-section'>
            <div className='workshop-details-section-title'>
              <Translate value='workshopProfile.companyData' />
            </div>
            {detailsDataToDisplay.map(key =>
              this.renderData(
                key,
                workshopData.workshopDetails,
                detailsDataToDisplay
              )
            )}
          </div>
        )}
        {!!workshopData.address && (
          <div className='workshop-details-section'>
            <div className='workshop-details-section-title'>
              <Translate value='workshopProfile.addressData' />
            </div>
            {addressDataToDisplay.map(key =>
              this.renderData(key, workshopData.address, addressDataToDisplay)
            )}
          </div>
        )}
      </div>
    );
  }

  renderDetails() {
    const {workshopData} = this.props;
    const coords =
      workshopData && workshopData.address
        ? {
            latitude: workshopData.address.latitude,
            longitude: workshopData.address.longitude,
          }
        : null;
    return (
      <div>
        {this.renderAvatar()}
        {!!workshopData && this.renderWorkshopData()}
        <WorkshopMap
          formValues={coords}
          streetViewControl={false}
          inputDisabled={true}
          mapMinHeight={450}
        />
        <div className='workshop-profile-buttons'>
          <Button
            id='edit-workshop-btn'
            className='main-btn'
            onClick={() => this.setState({editSectionOpen: true})}>
            <Translate value='userProfile.editData' />
          </Button>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <UserAvatar modalMode={false} />
        <EditWorkshopForm
          onSubmit={this.submitEditWorkshopForm}
          handleCancel={() => this.setState({editSectionOpen: false})}
          submitChangePasswordForm={this.submitChangePasswordForm}
        />
      </div>
    );
  }

  render() {
    const {workshopData} = this.props;
    const ownerName =
      workshopData &&
      workshopData.workshopDetails &&
      workshopData.workshopDetails.ownerName
        ? workshopData.workshopDetails.ownerName
        : '';
    return (
      <ContentWrapper
        title='userProfile.profile'
        titleAddon={ownerName}
        containerClass='user-profile-container'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='profile-data'>
            {this.state.editSectionOpen
              ? this.renderForm()
              : this.renderDetails()}
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({workshop, user}) => {
  return {
    isFetching: workshop.isFetching || user.isFetching,
    workshopData: workshop.data,
    userData: user.data,
  };
};

const mapDispatchToProps = {
  updateWorkshopByUserId: workshopActions.updateWorkshopByUserId,
  changePassword: userActions.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopProfileContainer);
