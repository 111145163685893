/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getDecimalSeparator} from '../../../utils/i18n';
import {errorColor} from '../../../utils/style.utils';
import {getOrZero} from '../../../utils/utils';

export const DURATION_UNITS = {
  DAYS: 'DAYS',
  HOURS: 'HOURS',
  MINUTES: 'MINUTES',
};

class RenderDuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      currentDuration: props.durationValue.input.value
        ? props.durationValue.input.value
        : null,
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  handleValueChange(values) {
    const {durationValue} = this.props;
    durationValue.input.onChange(getOrZero(values.floatValue));
    if (!this.state.touched) this.setState({touched: true});
    this.setState({currentDuration: values.floatValue});
  }

  handleUnitChange(e) {
    const {durationUnit} = this.props;
    durationUnit.input.onChange(e.value);
    if (!this.state.touched) this.setState({touched: true});
  }

  render() {
    const {durationValue, durationUnit, label, required, suffix} = this.props;
    const {touched} = this.state;
    const durationUnitsOptions = Object.keys(DURATION_UNITS).map(key => ({
      label: I18n.t(`duration.${key.toLowerCase()}`),
      value: key,
    }));
    const error = durationValue.meta.error || durationUnit.meta.error;

    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <span
            className='field-label'
            style={touched && error ? {color: errorColor} : null}>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <div className='ui-inputgroup'>
            <NumberFormat
              className='number-format-field w-75'
              style={touched && error ? {borderBottomColor: errorColor} : null}
              value={this.state.currentDuration}
              onValueChange={this.handleValueChange}
              decimalScale={0}
              fixedDecimalScale={true}
              allowNegative={false}
              suffix={suffix ? ' ' + suffix : ''}
              decimalSeparator={getDecimalSeparator()}
            />
            <Dropdown
              value={durationUnit.input.value}
              options={durationUnitsOptions}
              onChange={this.handleUnitChange}
              className='dropdown-content small'
            />
          </div>
        </div>
        <div className='col-12'>
          {touched && error ? (
            <span className='error-info'>
              {I18n.t(error.key, error.params)}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n};
}

export default connect(mapStateToProps)(RenderDuration);
