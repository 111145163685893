/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {distributorActions} from 'shared/actions/distributor.actions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {becameObsolete} from 'shared/utils/sync';
import {indexArray} from 'shared/utils/utils';
import CreateDistributorForm from '../form/CreateDistributorForm';
import SelectedDistributor from '../selected-distributor/SelectedDistributor';
import {formValuesToCorrectJson} from '../utils/utils';

class DistributorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addSectionIsOpen: false,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.createDistributor = this.createDistributor.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.renderStatusCol = this.renderStatusCol.bind(this);
  }

  componentDidMount() {
    this.props.findAll();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.distributorsDataSyncState,
        this.props.distributorsDataSyncState
      )
    )
      this.props.findAll();

    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  createDistributor(values) {
    formValuesToCorrectJson(values);
    this.props.createDistributor(values, this.props.closeAddSection);
  }

  onRowClick(event) {
    const component = (
      <SelectedDistributor distributorId={event.data.distributorId} />
    );
    this.props.showUpdateDataSideBar(component, event.data.userDto.email);
  }

  renderStatusCol(data) {
    data.status = data.userDto.enabled
      ? I18n.t('users.active')
      : I18n.t('users.blocked');
    return data.status;
  }

  renderCreateDistributorBtn() {
    return (
      <Button
        id='add-distributor-btn'
        className='main-btn data-table-button'
        onClick={() => this.setState({addSectionIsOpen: true})}>
        <Translate value={'users.createAdmin'} />
      </Button>
    );
  }

  renderContent() {
    if (this.state.addSectionIsOpen)
      return (
        <CreateDistributorForm
          onSubmit={this.createDistributor}
          handleCancel={() => this.setState({addSectionIsOpen: false})}
        />
      );
    else
      return (
        <Fragment>
          <DataTable
            value={indexArray(this.props.distributors)}
            paginator={true}
            rows={this.props.rows}
            header={this.props.header}
            className='data-table-padding-class'
            responsive={true}
            globalFilter={this.props.globalFilter}
            onRowClick={this.onRowClick}
            emptyMessage={I18n.t('distributors.emptyPlaceholder')}
            paginatorRight={this.renderCreateDistributorBtn()}
            ref={this.setDatatableRef}
            paginatorLeft={this.props.renderRowsDropdown()}>
            <Column
              field='index'
              className='lp-col'
              header={I18n.t('distributors.lpHeader')}
              sortable={true}
            />
            <Column
              field='userDto.email'
              header={I18n.t('distributors.emailHeader')}
              sortable={true}
            />
            <Column
              field='country'
              fieldValuInJson='address.country'
              header={I18n.t('distributors.countryHeader')}
              sortable={true}
              body={this.props.renderCountryCol}
            />
            <Column
              field='details.ownerName'
              header={I18n.t('distributors.ownerNameHeader')}
              sortable={true}
            />
            <Column
              field='details.companyName'
              header={I18n.t('distributors.companyNameHeader')}
              sortable={true}
            />
            <Column
              field='status'
              header={I18n.t('distributors.statusHeader')}
              sortable={true}
              body={this.renderStatusCol}
            />
          </DataTable>
        </Fragment>
      );
  }

  render() {
    return (
      <Fragment>
        <div className='col'>{this.renderContent()}</div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, distributor} = state;
  const {distributors, distributorsDataSyncState, isFetching} = distributor;

  return {i18n, isFetching, distributors, distributorsDataSyncState};
}

const mapDispatchToProps = {
  createDistributor: distributorActions.create,
  findAll: distributorActions.findAll,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findOne: distributorActions.findOne,
};

export const DistributorsListConnectedWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorsList);

export default withDataTableCore(DistributorsListConnectedWithRedux);
