/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Field} from 'redux-form';
import {I18n, Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import renderField from '../../form/text-field/RenderField';
import RenderDateTimePicker from '../../form/date-time-picker/RenderDateTimePicker';
import {Fragment} from 'react';
import {RenderColorPicker} from '../../form/color-picker/RenderColorPicker';
import RenderDropdown from '../../form/dropdown/RenderDropdown';
import {ConfirmModal} from '../../modal/ConfirmModal';

export class CalendarEventForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {confirmDeleteModalOpen: false};

    this.renderFields = this.renderFields.bind(this);
  }

  renderFields() {
    const {minDate, maxDate, workshopWorkers, showAttributes} = this.props;
    const workshopWorkersOptions = workshopWorkers.map(worker => ({
      label: `${worker.firstName} ${worker.lastName}`,
      value: worker.id,
    }));

    return (
      <Fragment>
        <Field
          name='title'
          component={renderField}
          icon='fa fa-cube'
          label={I18n.t('workshopCalendarEvent.title')}
          required={true}
        />

        {showAttributes && (
          <Fragment>
            <Field
              name='carData'
              component={renderField}
              icon='fa fa-cube'
              label={I18n.t('workshopCalendarEvent.carData')}
              inputDisabled={true}
            />

            <Field
              name='servicesData'
              component={renderField}
              icon='fa fa-cube'
              label={I18n.t('workshopCalendarEvent.servicesData')}
              inputDisabled={true}
            />
          </Fragment>
        )}

        <Field
          name='start'
          component={RenderDateTimePicker}
          label={I18n.t('workshopCalendarEvent.startDate')}
          required={true}
          minDate={minDate}
          maxDate={maxDate}
        />

        <Field
          name='end'
          component={RenderDateTimePicker}
          label={I18n.t('workshopCalendarEvent.endDate')}
          required={true}
          minDate={minDate}
          maxDate={maxDate}
        />

        <Field
          name='assignee'
          component={RenderDropdown}
          icon='fa fa-user'
          label={I18n.t('workshopCalendarEvent.assignee')}
          options={workshopWorkersOptions}
          showClear={true}
        />

        <Field
          name='workstation'
          component={renderField}
          icon='fa fa-wrench'
          label={I18n.t('workshopCalendarEvent.workstation')}
        />
      </Fragment>
    );
  }

  renderButtons() {
    const {handleCancel, invalid} = this.props;

    return (
      <Fragment>
        <Button
          id='calendar-event-form-submit-btn'
          className='main-btn'
          type='submit'
          disabled={invalid}>
          <Translate value='workshopCalendarEvent.submitBtn' />
        </Button>
        <Button
          id='calendar-event-form-cancel-btn'
          className='main-btn'
          onClick={handleCancel}>
          <Translate value='workshopCalendarEvent.cancelBtn' />
        </Button>

        {this.renderDeleteSection()}
      </Fragment>
    );
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete()}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      this.props.handleDelete && (
        <div
          className='text-sm-right text-center'
          style={{display: 'inline-block'}}>
          {this.renderConfirmDeleteModal()}
          <Button
            id='calendar-event-form-delete-btn'
            className='btn-danger'
            onClick={() => this.setState({confirmDeleteModalOpen: true})}>
            <Translate value={'workshopCalendarEvent.deleteBtn'} />
          </Button>
        </div>
      )
    );
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <div className='calendar-event-form px-5 pb-5'>
        <form onSubmit={handleSubmit}>
          {this.renderFields()}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            className='mt-3'>
            <Field
              name='color'
              component={RenderColorPicker}
              label={I18n.t('workshopCalendarEvent.color')}
            />
            <div className='text-right'>{this.renderButtons()}</div>
          </div>
        </form>
      </div>
    );
  }
}

CalendarEventForm.propType = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  invalid: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  showAttributes: PropTypes.bool,
};
