/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const subscriptionConstants = {
  SUBSCRIPTION_REQUEST_START: 'SUBSCRIPTION_REQUEST_START',
  SUBSCRIPTION_REQUEST_END: 'SUBSCRIPTION_REQUEST_END',
  LOAD_SUBSCRIPTION_END_DATE: 'LOAD_SUBSCRIPTION_END_DATE',
  REFRESH_SUBSCRIPTION: 'REFRESH_SUBSCRIPTION',

  SUBSCRIPTION_PAYMENT_REQUEST_START: 'SUBSCRIPTION_PAYMENT_REQUEST_START',
  SUBSCRIPTION_PAYMENT_REQUEST_END: 'SUBSCRIPTION_PAYMENT_REQUEST_END',
  LOAD_SUBSCRIPTION_PAYMENTS: 'LOAD_SUBSCRIPTION_PAYMENTS',
  REFRESH_SUBSCRIPTION_PAYMENTS: 'REFRESH_SUBSCRIPTION_PAYMENTS',
};
