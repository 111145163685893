/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {change, Field} from 'redux-form';
import CountryField from 'shared/components/form/country-field/CountryField';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import {getPhonePrefixByCountry} from 'shared/components/form/phone-number/utils/utils';
import StateField from 'shared/components/form/state-field/StateField';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';

export default class ValuationInquiryFormSchemaPage3 extends React.Component {
  componentDidMount() {
    const {title, dispatch, formName, directWorkshop, setPageTitle} =
      this.props;
    if (!!title) setPageTitle(title);

    if (!!directWorkshop)
      dispatch(change(formName, 'country', directWorkshop.country));

    this.initializeDirectClientPhonePrefixByWorkshopCountry();
  }

  initializeDirectClientPhonePrefixByWorkshopCountry() {
    const {loggedWorkshopCountry, countries, formName, dispatch, formValues} =
      this.props;
    if (
      loggedWorkshopCountry &&
      countries &&
      formValues &&
      formName === 'valuationDirectClientsForm' &&
      (!formValues.userPhoneNumber || !formValues.userPhoneNumber.phoneNumber)
    )
      dispatch(
        change(
          formName,
          'userPhoneNumber.phoneNumberPrefix',
          getPhonePrefixByCountry(loggedWorkshopCountry, countries)
        )
      );
  }

  composeLoyaltyProgramInfo() {
    return <div>{I18n.t('valuationInquiry.loyaltyProgramInfoContent')}</div>;
  }

  render() {
    const {
      previousPage,
      handleSubmit,
      handleCancel,
      invalid,
      handleCreateDraft,
      isValuationPublic,
      directWorkshop,
    } = this.props;
    const fixedBtn = {width: '7rem'};

    return (
      <form className='valuation-inquiry-form' onSubmit={handleSubmit}>
        <Field
          name='userFirstName'
          type='text'
          component={renderField}
          required={!isValuationPublic}
          label={I18n.t('valuationInquiry.userFirstNameLabel')}
          icon='fa fa-user'
        />
        <Field
          name='userLastName'
          type='text'
          component={renderField}
          required={!isValuationPublic}
          label={I18n.t('valuationInquiry.userLastNameLabel')}
          icon='fa fa-user'
        />
        {isValuationPublic && !directWorkshop && <CountryField />}
        <StateField formName={this.props.formName} />
        <Field
          name='userCity'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('valuationInquiry.userCityLabel')}
          icon='fa fa-building-o'
        />
        <Field
          name='userEmail'
          type='text'
          component={renderField}
          required={true}
          icon='fa fa-envelope'
          label={I18n.t('valuationInquiry.userEmailLabel')}
          additionalInfoContent={this.composeLoyaltyProgramInfo}
        />
        <Field
          name='userPhoneNumber'
          component={PhoneNumber}
          required={!isValuationPublic}
          label={I18n.t('valuationInquiry.userPhoneNumberLabel')}
          format={formatPhoneNumberField}
        />
        <Field
          name='userRemarks'
          type='text'
          component={RenderTextArea}
          required={false}
          label={I18n.t('valuationInquiry.userRemarksLabel')}
        />

        <div className='text-right mt-3'>
          <Button className='main-btn float-left' onClick={previousPage}>
            <Translate value='valuationInquiry.previousPageBtn' />
          </Button>
          <Button className='main-btn' type='submit' disabled={invalid}>
            <Translate value='valuationInquiry.nextPageBtn' />
          </Button>
        </div>

        <div className='text-sm-right text-center mt-3'>
          {handleCreateDraft && (
            <Button className='main-btn' onClick={handleCreateDraft}>
              <Translate value='valuationInquiry.createDraftBtn' />
            </Button>
          )}
          <Button className='main-btn' onClick={handleCancel}>
            <Translate value='valuationInquiry.cancelBtn' />
          </Button>
        </div>
      </form>
    );
  }
}

ValuationInquiryFormSchemaPage3.propTypes = {
  formName: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ValuationInquiryFormSchemaPage3.defaultProps = {
  formName: 'valuationInquiryForm',
};
