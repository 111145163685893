/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import { Dropdown } from "primereact/dropdown";
import React, { forwardRef } from "react";
import { I18n } from "react-redux-i18n";
import { errorColor } from "shared/utils/style.utils";
import "../render-dropdown.css";

export const RenderDropdown = forwardRef(
  (
    { onChange, name, label, options, placeholder, value, error, required, disabled, virtualScrollerOptions },
    ref
  ) => {
    return (
      <div className='row mt-2'>
        <div className='col-12'>
          {/* <span className='field-label'>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span> */}
          <label
            htmlFor={name}
            style={error && {color: errorColor}}
            className='field-label'>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </label>
        </div>
        <div className="col-12">
          <Dropdown
            value={value}
            name={name}
            options={options}
            onChange={onChange}

            placeholder={placeholder}
            filter={true}
            filterPlaceholder={placeholder}
            virtualScrollerOptions={virtualScrollerOptions}
            filterBy="label,value"
            className="dropdown-content"
            ref={ref}
            disabled = {disabled}
            style={{border: error? "1px solid #CC0000" : "1px solid #ced4da"}}
          />
          <div id="error-msg" style={{minHeight: 24}}>
            {error && <span className="error-info">Pole Wymagane</span>}
          </div>
        </div>
      </div>
    );
  }
);
