/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {settingsConstants} from '../constants/settings.constants';
import {SyncState} from '../utils/sync';
import {serviceConstants} from '../constants/service.constants';

export function settingsReducer(state = {}, action) {
  switch (action.type) {
    case settingsConstants.SETTINGS_REQUEST_START:
      return {...state, isFetching: true};
    case settingsConstants.SETTINGS_REQUEST_END:
      return {...state, isFetching: false};
    case settingsConstants.LOAD_SETTINGS:
      return {
        ...state,
        loadedSettings: action.payload.data,
        settingsDataSyncState: SyncState.UP_TO_DATE,
      };
    case settingsConstants.REFRESH_SETTINGS_DATA:
      return {...state, settingsDataSyncState: SyncState.OBSOLETE};
    case settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_START:
      return {...state, isFetching: true};
    case settingsConstants.WORKSHOP_LOYALTY_PROGRAM_EDITOR_REQUEST_END:
      return {...state, isFetching: false};
    case settingsConstants.LOAD_WORKSHOP_LOYALTY_PROGRAM_EDITOR:
      return {...state, content: action.payload.data.value};
    case settingsConstants.REFRESH_WORKSHOP_LOYALTY_PROGRAM_EDITOR:
      return {...state, content: action.payload.data};
    default:
      return state;
  }
}
