/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const countryService = {
  findAll,
};

export const COUNTRY_API_BASE_PATH = '/user/countries';

function findAll() {
  return axiosService.get(COUNTRY_API_BASE_PATH).then(response => {
    return response;
  });
}
