/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';

export const formValuesToCorrectJson = formValues => {
  formValues.userDto.country = formValues.country;
  formValues.address.country = formValues.country;
  formValues.details.language = formValues.language;
  formValues.details.ownerName = formValues.address.ownerName;
  formValues.details.companyName = formValues.address.companyName;
};

export const resolveCurrencyOptions = (countries, country) => {
  const currencyOptions =
    countries && country ? countries[country].currencies : null;
  return currencyOptions
    ? currencyOptions.map(c => ({label: c, value: c}))
    : null;
};

export const getDefaultCurrency = (userCountry, countries) => {
  const userCountryObj = countries[userCountry];
  return userCountryObj.currencies[0];
};
