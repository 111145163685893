/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';
import {isEmptyArray} from '../utils';

const getFuelTypeFormOptions = (fuelTypes, fieldNameAsValue = 'fuelTypeId') =>
  !isEmptyArray(fuelTypes)
    ? fuelTypes
        .filter(fuelType => fuelType.name !== 'ADDBLUE')
        .map(fuelType => ({
          label: `${I18n.t(`fuelType.${fuelType.name.toLowerCase()}`)}`,
          value: fuelType[fieldNameAsValue],
        }))
    : [];

export {getFuelTypeFormOptions};
