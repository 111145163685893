/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const privilegeConstants = {
  PRIVILEGE_EDIT: 'PRIVILEGE_EDIT',
  PRIVILEGE_STATISTIC: 'PRIVILEGE_STATISTIC',
  PRIVILEGE_OWN_SERVICES: 'PRIVILEGE_OWN_SERVICES',
};
