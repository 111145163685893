/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {subscriptionPlanConstants} from '../constants/subscription-plan.constants';
import {SyncState} from '../utils/sync';

export function subscriptionPlanReducer(state = {}, action) {
  switch (action.type) {
    case subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START:
      return {...state, isFetching: true};

    case subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END:
      return {...state, isFetching: false};

    case subscriptionPlanConstants.LOAD_SUBSCRIPTION_PLANS:
      return {
        ...state,
        subscriptionPlans: action.payload.data,
        subscriptionPlansSyncState: SyncState.UP_TO_DATE,
      };

    case subscriptionPlanConstants.REFRESH_SUBSCRIPTION_PLANS:
      return {...state, subscriptionPlansSyncState: SyncState.OBSOLETE};

    case subscriptionPlanConstants.LOAD_SUBSCRIPTION_PLAN:
      return {...state, activeSubscriptionPlan: action.payload.data};

    case subscriptionPlanConstants.CLEAR_SUBSCRIPTION_PLAN:
      const {activeSubscriptionPlan, ...otherState} = state;
      return otherState;

    default:
      return state;
  }
}
