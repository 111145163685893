/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Workshops as WokrshopView} from './../../admin/workshops/Workshops';

export class Workshops extends React.Component {
  render() {
    return <WokrshopView />;
  }
}
