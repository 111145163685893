/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import ValuationInquiryResponseFormSchemaPage1 from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-1/ValuationInquiryResponseFormSchemaPage1';
import {formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {validate} from '../../../valuations/valuation-inquiry-response/valuation-inquiry-response-form/page-1/validators';
import {overwriteDuplicates} from '../../../valuations/utils/utils';
import {prepareServiceFields} from '../../../valuations/valuation-inquiry-response/ValuationInquiryResponse';

const ValuationDirectClientsFormPage4 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage1);

const selector = formValueSelector('valuationDirectClientsForm');

function mapStateToProps(state) {
  const {i18n, service, workshop} = state;
  const {lpgServiceCategoryId} = service;
  const {data} = workshop;
  const categories = service.workshopServiceCategories;
  const valuationInquiryServices = selector(state, 'valuationInquiryServices');
  const currentServices = selector(state, 'services');
  const valuationInquiry = {
    servicesIds: valuationInquiryServices.map(obj => {
      return obj.serviceId;
    }),
  };
  const currency =
    data && data.workshopDetails ? data.workshopDetails.currency : null;

  const services = prepareServiceFields(
    valuationInquiry,
    categories,
    lpgServiceCategoryId,
    currency
  );
  overwriteDuplicates(currentServices, services);
  getFormValues('valuationDirectClientsForm')(state).services = services;

  return {i18n, categories, currency};
}

export default connect(mapStateToProps)(ValuationDirectClientsFormPage4);
