/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {SyncState} from '../utils/sync';
import {statuteConstants} from '../constants/statute.constants';

export function statuteReducer(state = {loyaltyProgramStatute: null, isLoyaltyStatuteModalOpen: false}, action) {
  switch (action.type) {
    case statuteConstants.STATUTE_REQUEST_START:
      return {...state, isFetching: true};
    case statuteConstants.STATUTE_REQUEST_END:
      return {...state, isFetching: false};
    case statuteConstants.REFRESH_STATUTES_DATA:
      return {...state, statutesDataSyncState: SyncState.OBSOLETE};
    case statuteConstants.LOAD_STATUTES:
      return {
        ...state,
        statutes: action.payload.data,
        statutesDataSyncState: SyncState.UP_TO_DATE,
      };
    case statuteConstants.LOAD_STATUTE:
      return {...state, statute: action.payload.data};
    case statuteConstants.LOYALTY_STATUTE_REQUEST_START:
      return {...state, isFetching: true};
    case statuteConstants.LOYALTY_STATUTE_REQUEST_END:
      return {...state, isFetching: false};
    case statuteConstants.LOYALTY_STATUTE:
      return {
        ...state,
        loyaltyProgramStatute: action.payload.data.length? action.payload.data[0] : null
    };
    case statuteConstants.LOYALTY_STATUTE_MODAL_OPEN:
      return {
        ...state,
        isLoyaltyStatuteModalOpen: action.payload
      }
    case statuteConstants.UPDATE_LOYALTY_STATUTE:
      return {
        ...state,
        loyaltyProgramStatute: action.payload.data
    };
    default:
      return state;
  }
}
