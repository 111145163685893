/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './loyalty-program-info.css';
import {Translate} from 'react-redux-i18n';

export class LoyaltyProgramInfo extends Component {
  constructor(props) {
    super(props);

    this.isActive = this.isActive.bind(this);
  }

  isActive() {
    return this.props.active ? 'loyalty-program-info-row-active' : '';
  }

  render() {
    const {loyaltyProgram, onClick} = this.props;

    return (
      <div
        className={
          'row loyalty-program-info-row m-2 py-3 clickable ' + this.isActive()
        }
        onClick={() => onClick(loyaltyProgram.id)}>
        <div className='col-sm col-12 break-all'>{loyaltyProgram.title}</div>
        <div className='col-sm col-12'>
          <Translate value='loyaltyProgram.points' />: {loyaltyProgram.points}
        </div>
      </div>
    );
  }
}

LoyaltyProgramInfo.propTypes = {
  loyaltyProgram: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

LoyaltyProgramInfo.defaultProps = {
  onClick: () => {},
  active: false,
};
