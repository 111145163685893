/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import SubscriptionPaymentsTable from '../subscription-payments-table/SubscriptionPaymentsTable';
import {SubscriptionOverviewSummary} from '../subscription-overview-summary/SubscriptionOverviewSummary';
import {connect} from 'react-redux';
import {subscriptionActions} from '../../../actions/subscription.actions';
import Loadable from 'react-loading-overlay';
import {userService} from '../../../services/user.service';
import {getOrEmptyArray} from '../../../utils/utils';
import {becameObsolete} from '../../../utils/sync';
import {ConfirmModal} from '../../modal/ConfirmModal';
import {SubscriptionBuyModal} from '../subscription-buy-modal/SubscriptionBuyModal';
import {subscriptionPlanActions} from '../../../actions/subscription-plan.actions';
import ContentWrapper from '../../content-wrapper/ContentWrapper';

class SubscriptionOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionPaymentIdCancelCandidate: null,
      subscriptionBuyModalOpen: false,
    };

    this.load = this.load.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
    this.cancelSubscriptionPayment = this.cancelSubscriptionPayment.bind(this);
    this.renderCancelSubscriptionPaymentModal =
      this.renderCancelSubscriptionPaymentModal.bind(this);
    this.renderSubscriptionBuyModal =
      this.renderSubscriptionBuyModal.bind(this);
    this.onSubscriptionActionBtnClick =
      this.onSubscriptionActionBtnClick.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.subscriptionPaymentsSyncState,
        this.props.subscriptionPaymentsSyncState
      )
    )
      this.load();
  }

  load() {
    const {getSubscriptionEndDate, findAllSubscriptionPaymentsByUserId} =
      this.props;
    getSubscriptionEndDate(userService.getUserId());
    findAllSubscriptionPaymentsByUserId(userService.getUserId());
  }

  createSubscription(modalState) {
    this.props.createSubscription(
      userService.getUserId(),
      modalState.subscriptionPlanId,
      modalState.paymentServiceType
    );
  }

  cancelSubscriptionPayment(subscriptionPaymentId) {
    this.props.cancelSubscriptionPayment(
      userService.getUserId(),
      subscriptionPaymentId
    );
  }

  renderCancelSubscriptionPaymentModal() {
    const action = () => {
      this.cancelSubscriptionPayment(
        this.state.subscriptionPaymentIdCancelCandidate
      );
      this.setState({subscriptionPaymentIdCancelCandidate: null});
    };

    return (
      <ConfirmModal
        title='confirmModal.cancel'
        isOpen={this.state.subscriptionPaymentIdCancelCandidate}
        bodyText='subscription.cancelPaymentWarning'
        action={action}
        handleCancel={() =>
          this.setState({subscriptionPaymentIdCancelCandidate: null})
        }
      />
    );
  }

  renderSubscriptionBuyModal() {
    const {
      subscriptionPlans,
      isFetchingSubscription,
      isFetchingSubscriptionPlans,
    } = this.props;

    return (
      <SubscriptionBuyModal
        isOpen={this.state.subscriptionBuyModalOpen}
        handleConfirm={this.createSubscription}
        handleCancel={() => this.setState({subscriptionBuyModalOpen: false})}
        subscriptionPlans={subscriptionPlans}
        isFetching={isFetchingSubscription || isFetchingSubscriptionPlans}
      />
    );
  }

  onSubscriptionActionBtnClick() {
    this.props.findActiveSubscriptionPlansByCountry(
      userService.getUserCountry()
    );
    this.setState({subscriptionBuyModalOpen: true});
  }

  render() {
    const {
      isFetchingSubscription,
      subscriptionEndDate,
      isFetchingSubscriptionPayment,
      subscriptionPayments,
      pendingSubscriptionPaymentExists,
    } = this.props;

    return (
      <ContentWrapper title='sideBarNav.subscription'>
        {this.renderCancelSubscriptionPaymentModal()}
        {this.renderSubscriptionBuyModal()}
        <div className='root-panel m-3'>
          <Loadable active={isFetchingSubscription} spinner>
            <SubscriptionOverviewSummary
              pendingSubscriptionPaymentExists={
                pendingSubscriptionPaymentExists
              }
              subscriptionEndDate={subscriptionEndDate}
              onSubscriptionActionBtnClick={this.onSubscriptionActionBtnClick}
              showContent={true}
            />
          </Loadable>
        </div>

        <div className='root-panel m-3'>
          <Loadable active={isFetchingSubscriptionPayment} spinner>
            <SubscriptionPaymentsTable
              subscriptionPayments={subscriptionPayments}
              onCancelClick={id =>
                this.setState({subscriptionPaymentIdCancelCandidate: id})
              }
              showUserLabel={false}
              showActions={true}
              showTitle={true}
            />
          </Loadable>
        </div>
      </ContentWrapper>
    );
  }
}

SubscriptionOverview.propTypes = {};

function mapStateToProps(state) {
  const {i18n, subscription, subscriptionPlan} = state;

  const {
    isFetchingSubscription,
    subscriptionEndDate,
    isFetchingSubscriptionPayment,
    subscriptionPayments,
    subscriptionPaymentsSyncState,
  } = subscription;

  const pendingSubscriptionPaymentExists =
    getOrEmptyArray(subscriptionPayments).filter(sp => sp.status === 'PENDING')
      .length > 0;

  const {subscriptionPlans} = subscriptionPlan;
  const isFetchingSubscriptionPlans = subscriptionPlan.isFetching;

  return {
    i18n,
    isFetchingSubscription,
    subscriptionEndDate,
    isFetchingSubscriptionPayment,
    subscriptionPayments,
    subscriptionPaymentsSyncState,
    pendingSubscriptionPaymentExists,
    subscriptionPlans,
    isFetchingSubscriptionPlans,
  };
}

const mapDispatchToProps = {
  getSubscriptionEndDate: subscriptionActions.getSubscriptionEndDate,
  findAllSubscriptionPaymentsByUserId:
    subscriptionActions.findAllSubscriptionPaymentsByUserId,
  createSubscription: subscriptionActions.createSubscription,
  cancelSubscriptionPayment: subscriptionActions.cancelSubscriptionPayment,
  findActiveSubscriptionPlansByCountry:
    subscriptionPlanActions.findActiveSubscriptionPlansByCountry,
};

const connectedSubscriptionOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionOverview);
export {connectedSubscriptionOverview as SubscriptionOverview};
