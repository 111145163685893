/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {userNotificationConstants} from '../constants/user-notification.constants';

export function userNotificationReducer(state = {}, action) {
  switch (action.type) {
    case userNotificationConstants.REQUEST_START:
      return {...state, isFetching: true};
    case userNotificationConstants.REQUEST_END:
      return {...state, isFetching: false};
    case userNotificationConstants.LOAD_UNREAD_NOTIFICATIONS_COUNT:
      return {...state, unreadNotificationsCount: action.payload.data};
    case userNotificationConstants.LOAD_RECENT_NOTIFICATIONS:
      return {...state, recentNotifications: action.payload.data};
    default:
      return state;
  }
}
