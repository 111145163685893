/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {FinishedOrders} from 'shared/components/order/finished-orders/FinishedOrders';
import {ArchivedOrders} from 'shared/components/order/archived-orders/ArchivedOrders';
import {OrdersForRealization} from 'shared/components/order/orders-for-realization/OrdersForRealization';
import {OrdersInProgress} from 'shared/components/order/orders-in-progress/OrdersInProgress';
import 'shared/styles/subsection-tiles.css';

export const OrdersSubsection = {
  ORDERS_FOR_REALIZATION: {
    key: 'ORDERS_FOR_REALIZATION',
    divId: 'orders-for-realization-tile',
    translateValue: 'orders.ordersForRealizationBtn',
  },
  ORDERS_IN_PROGRESS: {
    key: 'ORDERS_IN_PROGRESS',
    divId: 'orders-in-progress-tile',
    translateValue: 'orders.ordersInProgressBtn',
  },
  FINISHED_ORDERS: {
    key: 'FINISHED_ORDERS',
    divId: 'finished-orders-tile',
    translateValue: 'orders.finishedOrdersBtn',
  },
  ARCHIVES: {
    key: 'ARCHIVES',
    divId: 'archived-orders-tile',
    translateValue: 'orders.archives',
  },
};

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subsectionKeyActive: OrdersSubsection.ORDERS_FOR_REALIZATION.key,
    };

    this.isActive = this.isActive.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(
          OrdersSubsection.ORDERS_FOR_REALIZATION,
          'fa-check-square-o'
        )}
        {this.renderTile(
          OrdersSubsection.ORDERS_IN_PROGRESS,
          'fa-hourglass-half'
        )}
        {this.renderTile(OrdersSubsection.FINISHED_ORDERS, 'fa-book')}
        {this.renderTile(OrdersSubsection.ARCHIVES, 'fa-archive', false)}
      </div>
    );
  }

  renderTile(subsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        id={subsection.divId}
        className={commonClasses + spacingClasses + this.isActive(subsection)}
        onClick={e => this.setActive(subsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={subsection.translateValue} />
      </div>
    );
  }

  isActive(subsection) {
    return this.state.subsectionKeyActive === subsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(subsection) {
    this.setState({subsectionKeyActive: subsection.key});
  }

  renderSubsection() {
    switch (this.state.subsectionKeyActive) {
      case OrdersSubsection.ORDERS_FOR_REALIZATION.key:
        return <OrdersForRealization showWorkshopDetails={true} />;
      case OrdersSubsection.ORDERS_IN_PROGRESS.key:
        return <OrdersInProgress showWorkshopDetails={true} />;
      case OrdersSubsection.FINISHED_ORDERS.key:
        return <FinishedOrders showWorkshopDetails={true} />;
      case OrdersSubsection.ARCHIVES.key:
        return <ArchivedOrders showWorkshopDetails={true} />;
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        {this.renderSubsection()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const mapDispatchToProps = {};

const connectedOrders = connect(mapStateToProps, mapDispatchToProps)(Orders);
export {connectedOrders as Orders};
