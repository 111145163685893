/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Translate} from 'react-redux-i18n';
import formatDoubleNumber from 'shared/components/form/formatter/DoubleNumberFormatter';
import {formatPhoneNumber} from 'shared/components/form/formatter/PhoneNumberFormatter';

export const mapToDetailsObject = (objectName, valuationInquiry) => {
  let obj = {};
  Object.keys(valuationInquiry).forEach(key => {
    if (key.indexOf(objectName) >= 0 && valuationInquiry[key])
      obj[key] = valuationInquiry[key];
  });
  obj = modifyIfMapToUserDetailsObj(objectName, obj, valuationInquiry);
  obj = modifyIfMapToCarDetailsObj(objectName, obj);
  return obj;
};

const modifyIfMapToUserDetailsObj = (objectName, userObj, valuationInquiry) => {
  if (objectName === 'user') {
    userObj = Object.assign(
      {
        userName: `${valuationInquiry.userFirstName || ''} ${
          valuationInquiry.userLastName || ''
        }`,
      },
      userObj
    );
    userObj.userPhoneNumber = `${
      valuationInquiry.userPhoneNumber.phoneNumberPrefix || ''
    } ${formatPhoneNumber(valuationInquiry.userPhoneNumber.phoneNumber) || ''}`;
    userObj.userRemarks = valuationInquiry.userRemarks ? (
      valuationInquiry.userRemarks
    ) : (
      <Translate value={'valuationInquiryDetails.noUserRemarks'} />
    );
    delete userObj.userFirstName;
    delete userObj.userLastName;
  }
  return userObj;
};

const modifyIfMapToCarDetailsObj = (objectName, carObj) => {
  if (objectName === 'car') {
    carObj.carEnginePowerKw = formatDoubleNumber(carObj.carEnginePowerKw);
    delete carObj.carFuelTypeId;
    carObj.carFuelTypeName = getFuelTypeName(carObj.carFuelTypeName);
  }

  return {...carObj};
};

const getFuelTypeName = carFuelTypeName => {
  return carFuelTypeName && carFuelTypeName !== '-' ? (
    <Translate value={`fuelType.${carFuelTypeName.toLowerCase()}`} />
  ) : (
    ''
  );
};
