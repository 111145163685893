/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';

export class AcceptTerms extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.state = {checked: false};
  }

  onClickHandler() {
    this.setState(prevState => {
      this.props.input.onChange(!prevState.checked);
      return {checked: !prevState.checked};
    });
  }

  render() {
    return (
      <div className='text-justify terms-text mt-3'>
        <div className='accept-terms-container'>
          <input
            type='checkbox'
            onClick={this.onClickHandler}
            style={{alignSelf: 'flex-start'}}
          />
          <div className='ml-2'>
            <Translate
              value='signup.acceptTermsStart'
              style={{fontFamily: 'Montserrat-SemiBold'}}
            />
            <span style={{color: 'red'}}>* </span>
            <Translate value='signup.acceptTermsEnd' />
            <br></br>
            <Translate value='signup.acceptTermsEnd2' />
            <br></br>
            <Translate value='signup.acceptTermsEnd3' />
          </div>
        </div>
        <div className='text-center' style={{width: '100%'}}>
          {this.props.meta.touched && this.props.meta.error ? (
            <span className='error-info'>
              {I18n.t(this.props.meta.error.key)}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
