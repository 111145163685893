/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import {messageActions} from 'shared/actions/message.actions';
import {partActions} from 'shared/actions/part.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {cutPrefixFromBase64String} from 'shared/components/form/file-upload/utils/file-utils';
import {privilegeConstants} from 'shared/constants/privilege.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {becameObsolete} from 'shared/utils/sync';
import {isEmptyArray, rowsQuantityToDisplay} from 'shared/utils/utils';
import defaultPartImg from '../../../assets/images/default-part-img.png';
import PartCreateForm from './part-form/PartCreateForm';
import PartEditForm from './part-form/PartEditForm';
import './parts.css';

class Parts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: '',
      rows: 10,
      dataTableKey: 0,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.onInputHandler = this.onInputHandler.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.onClickAddPartBtn = this.onClickAddPartBtn.bind(this);
    this.createPart = this.createPart.bind(this);
    this.updatePart = this.updatePart.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      partActions.findAllPartsByCategoryId(this.props.match.params.id)
    );
  }

  componentDidUpdate(prevProps) {
    if (this.checkNeedUpdate(prevProps))
      this.props.dispatch(
        partActions.findAllPartsByCategoryId(this.props.match.params.id)
      );
  }

  checkNeedUpdate(prevProps) {
    return (
      this.props.match.params.id !== prevProps.match.params.id ||
      becameObsolete(prevProps.partDataSyncState, this.props.partDataSyncState)
    );
  }

  onChangeHandler(event) {
    this.setState({rows: event.value, dataTableKey: Math.random()});

    if (this.dataTable)
      this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  onInputHandler(event) {
    this.setState({globalFilter: event.target.value});
  }

  onClickAddPartBtn() {
    const form = (
      <PartCreateForm
        onSubmit={this.createPart}
        categoryId={this.props.match.params.id}
      />
    );
    this.props.dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: form,
      title: I18n.t('parts.partCreateHeader'),
    });
  }

  onClickEditPartBtn(partId) {
    this.props.dispatch(partActions.findPartById(partId));
    const form = (
      <PartEditForm
        onSubmit={this.updatePart}
        categoryId={this.props.match.params.id}
      />
    );
    this.props.dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: form,
      title: I18n.t('parts.partEditHeader'),
    });
  }

  createPart(value) {
    if (
      userService.isAdmin() &&
      (!value.countries || isEmptyArray(value.countries))
    ) {
      this.props.dispatch(
        messageActions.errorMessage('parts.adminActionWithNoCountriesError')
      );
    } else {
      this.props.dispatch(partActions.createPart(value));
    }
  }

  updatePart(value) {
    if (
      userService.isAdmin() &&
      (!value.countries || isEmptyArray(value.countries))
    ) {
      this.props.dispatch(
        messageActions.errorMessage('parts.adminActionWithNoCountriesError')
      );
    } else {
      value.icon = value.icon && cutPrefixFromBase64String(value.icon);
      this.props.dispatch(partActions.updatePart(value.partId, value));
    }
  }

  shouldRenderEditBtn(part) {
    return (
      userService.isAdmin() ||
      (userService.isDistributor() &&
        userService.hasAuthority(privilegeConstants.PRIVILEGE_EDIT) &&
        !part.createdByAdmin)
    );
  }

  getCurrentPartCategoryName() {
    const {match, ...part} = this.props;
    return part && part.categories
      ? part.categories.find(c => c.id === match.params.id).name
      : '';
  }

  itemTemplate(part) {
    return (
      <div className='ui-g' style={{padding: '2em'}}>
        <div className='ui-g-12 ui-lg-3 text-center'>
          <img
            src={part.icon ? part.icon : defaultPartImg}
            alt=''
            width={150}
            height={120}
          />
        </div>
        <div className='ui-g-12 ui-lg-8 car-details'>
          <div className='ui-g'>
            <div className='ui-g-12 ui-sm-12'>
              <Translate value={'parts.nameLabel'} className='font-bold' />
              <div className='parts-content' style={{fontSize: '1rem'}}>
                {part.name}
              </div>
            </div>
            <div className='ui-g-12 ui-sm-12'>
              <Translate
                value={'parts.descriptionLabel'}
                className='font-bold'
              />
              <div className='parts-content' style={{fontSize: '1rem'}}>
                {part.description}
              </div>
            </div>
          </div>
        </div>
        {this.shouldRenderEditBtn(part) && (
          <div
            className='ui-g-12 ui-lg-1 search-icon'
            style={{marginTop: '40px'}}>
            <Button
              className='main-btn'
              onClick={this.onClickEditPartBtn.bind(this, part.partId)}>
              <Translate value={'parts.editBtn'} />
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        onChange={this.onChangeHandler}
      />
    );
  }

  render() {
    const header = (
      <div className='row'>
        <div className='col-lg-1 col-1 px-1 mr-auto order-1' />
        <div className='col-xl-2 col-12 px-3 my-xl-0 my-2 order-xl-2 order-last'>
          {(userService.isAdmin() ||
            (userService.isDistributor() &&
              userService.hasAuthority(
                privilegeConstants.PRIVILEGE_OWN_SERVICES
              ))) && (
            <Button
              id='add-parts-btn'
              className='main-btn w-100'
              onClick={this.onClickAddPartBtn}>
              <Translate value='parts.addBtn' />
            </Button>
          )}
        </div>
        <div className='col-xl-4 col-6 px-3 order-3'>
          <Input
            type='search'
            placeholder={I18n.t('parts.searchPlaceholder')}
            onInput={this.onInputHandler}
            className='global-filter m-1'
          />
        </div>
      </div>
    );

    return (
      <ContentWrapper title={this.getCurrentPartCategoryName()}>
        <div className='parts-wrapper'>
          <Loadable active={this.props.isFetching} spinner>
            <div id='parts-list'>
              <DataTable
                value={this.props.parts}
                paginator={true}
                rows={this.state.rows}
                header={header}
                className='p-3'
                footerStyle={{display: 'none !important'}}
                responsive={true}
                ref={this.setDatatableRef}
                emptyMessage={I18n.t('parts.emptyMessage')}
                sortField='name'
                sortOrder={1}
                key={this.state.dataTableKey}
                paginatorLeft={this.renderRowsDropdown()}
                globalFilter={this.state.globalFilter}>
                <Column field='name' body={this.itemTemplate} />
              </DataTable>
            </div>
          </Loadable>
        </div>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, part} = state;

  return {i18n, ...part};
}

export default connect(mapStateToProps)(Parts);
