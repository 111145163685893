/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import Countdown from 'shared/components/countdown/Countdown';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {dateUtils} from 'shared/utils/date.utils';
import {sortByCustomField} from 'shared/utils/sort-utils/sort.utils';
import {valuationInquiryRefreshIntervalMillis} from '../../../../environment';
import {ValuationInquiryStatusEnum} from '../utils/utils';
import {withWorkshopValuationsTableCore} from '../utils/ValuationsCoreHoc';
import {ValuationInquiryDetails} from '../valuation-inquiry-details/ValuationInquiryDetails';

class WaitingValuations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isFirstFetch: true};

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.showDetails = this.showDetails.bind(this);
    this.getRowClassName = this.getRowClassName.bind(this);
    this.renderTimeToLeftColl = this.renderTimeToLeftColl.bind(this);
    this.onCompleteTimeLeftCallback =
      this.onCompleteTimeLeftCallback.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.sortByInquiryNumber = this.sortByInquiryNumber.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(
      this.refreshData,
      valuationInquiryRefreshIntervalMillis
    );
    this.forceUpdate();
  }

  refreshData() {
    const {loggedWorkshopId, findAllWaitingValuationInquiriesByWorkshopId} =
      this.props;
    if (!this.state.isFirstFetch && loggedWorkshopId)
      findAllWaitingValuationInquiriesByWorkshopId(loggedWorkshopId);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId} = this.props;
    if (loggedWorkshopId && this.state.isFirstFetch) {
      this.setState({isFirstFetch: false});
      this.props.findAllWaitingValuationInquiriesByWorkshopId(loggedWorkshopId);
    }

    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  showDetails(event) {
    this.props.findValuationInquiryById(event.data.id);
    const details = (
      <ValuationInquiryDetails
        prepareValuation={this.props.prepareValuation}
        shouldShowWorkshopStatusAlert={this.props.shouldShowWorkshopStatusAlert}
      />
    );
    const title =
      I18n.t('valuationInquiryDetails.detailsHeader') +
      ' ' +
      event.data.inquiryNumber.inquiryNumberString;
    setTimeout(() => this.props.showUpdateDataSideBar(details, title), 10);
  }

  getRowClassName(data) {
    const diffHours = dateUtils.countHoursBetweenDates(new Date(data.endDate));
    return {'red-row': this.isRedRowClass(diffHours, data.status)};
  }

  isRedRowClass(hours, status) {
    return (
      hours <= 24 &&
      (status === ValuationInquiryStatusEnum.NEW ||
        status === ValuationInquiryStatusEnum.EXTENDED_PERIOD)
    );
  }

  onCompleteTimeLeftCallback() {
    const {loggedWorkshopId, findAllWaitingValuationInquiriesByWorkshopId} =
      this.props;
    findAllWaitingValuationInquiriesByWorkshopId(loggedWorkshopId);
  }

  renderTimeToLeftColl(data) {
    return (
      <Countdown
        date={data.endDate}
        onCompleteCallBack={this.onCompleteTimeLeftCallback}
      />
    );
  }

  sortByInquiryNumber(event) {
    return this.props.sortByInquiryNumber(this.props.valuationInquiries, event);
  }

  render() {
    return (
      <ContentWrapper title='valuations.waitingValuationsBtn'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='col'>
            <DataTable
              value={this.props.valuationInquiries}
              paginator={true}
              rows={this.props.rows}
              header={this.props.header}
              globalFilter={this.props.globalFilter}
              className='p-3'
              responsive={true}
              onRowClick={this.showDetails}
              paginatorLeft={this.props.renderRowsDropdown()}
              rowClassName={this.getRowClassName}
              emptyMessage={I18n.t('waitingValuations.emptyPlaceholder')}
              ref={this.setDatatableRef}>
              <Column
                field='inquiryNumber.inquiryNumberString'
                header={I18n.t('waitingValuations.id')}
                sortable='custom'
                sortFunction={this.sortByInquiryNumber}
                style={{width: '5rem'}}
              />
              <Column
                field='userDetails'
                sortable={true}
                header={I18n.t('waitingValuations.userDetails')}
                body={this.props.renderUserDetailsCol}
              />
              <Column
                field='sendDate'
                sortable={true}
                header={I18n.t('waitingValuations.sendDate')}
                body={this.props.renderDateCol}
              />
              <Column
                field='services'
                header={I18n.t('valuationInquiryDetails.servicesDataTitle')}
                body={this.props.renderServicesCol}
              />
              <Column
                field='carData'
                header={I18n.t('waitingValuations.carData')}
                sortable={true}
                body={this.props.renderCarDataCol}
              />
              <Column
                field='origin'
                header={I18n.t('waitingValuations.origin')}
                sortable={true}
                body={this.props.renderOriginCol}
              />
              <Column
                field='timeToLeft'
                header={I18n.t('waitingValuations.timeToLeft')}
                body={this.renderTimeToLeftColl}
                sortable='custom'
                sortFunction={e =>
                  sortByCustomField(e, this.props.valuationInquiries, 'endDate')
                }
              />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

WaitingValuations.propTypes = {
  prepareValuation: PropTypes.func.isRequired,
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {i18n, order, workshop} = state;
  const {valuationInquiries} = order;
  const {data} = workshop;
  const loggedWorkshopId = data ? data.workshopId : null;

  return {i18n, valuationInquiries, loggedWorkshopId};
}

const mapDispatchToProps = {
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findAllWaitingValuationInquiriesByWorkshopId:
    orderActions.findAllWaitingValuationInquiriesByWorkshopId,
  findValuationInquiryById: orderActions.findValuationInquiryById,
};

//export for testing purpose
export const ConnectedWaitingValuationsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitingValuations);

const waitingValuationsWithDataTableCore = withWorkshopValuationsTableCore(
  ConnectedWaitingValuationsWithRedux
);
export {waitingValuationsWithDataTableCore as WaitingValuations};
