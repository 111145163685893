/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import * as React from 'react';
import {errorColor} from '../../../utils/style.utils';
import './text-input.css';

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  componentDidMount() {
    //Obejście błędu z labelką zachodzącą na input po autouzupełnieniu na chrome
    // this.input.current.onInput({target: {value: 'hackValue'}});
  }

  render() {
    const {label, value, onChange, onClick, type, style, hasErrors, ...props} =
      this.props;
    return (
      <div className='text-input-container p-float-label' style={style}>
        <InputText
          {...props}
          ref={this.input}
          id='in'
          type={type}
          className='w-100'
          value={value}
          onChange={onChange}
          onClick={onClick}
          style={hasErrors ? {borderBottomColor: errorColor} : null}
        />
        <label style={hasErrors ? {color: errorColor} : null} htmlFor='in'>
          {label}
        </label>
      </div>
    );
  }
}

TextInput.propType = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  style: PropTypes.object,
};

TextInput.defaultProps = {
  hasErrors: false,
  onClick: () => {},
  type: 'text',
  style: null,
};
