/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateCity,
  validateConfirmPassword,
  validateEmail,
  validateGeographicData,
  validatePassword,
  validatePhoneNumber,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';
import {CreateWorkshopFormMode} from '../../utils/utils';

export const validate = (values, props) => {
  let errors = {};
  errors.language = validateRequiredFields(values.language);
  errors.country = validateRequiredFields(values.country);
  errors.status = validateRequiredFields(values.status);
  errors.email = validateEmail(values.email);
  errors.password = validatePassword(values.password);
  errors.confirmPassword = validateConfirmPassword({
    password: values.password,
    confirmPassword: values.confirmPassword,
  });
  errors.workshopName = validateRequiredFieldsWithMaxLength(
    values.workshopName,
    100
  );
  errors.ownerName = validateRequiredFieldsWithMaxLength(values.ownerName, 50);
  errors.state = validateRequiredFieldsWithMaxLength(values.state, 120);
  errors.city = validateCity(values.city);
  errors.postalCode = validateRequiredFieldsWithMaxLength(
    values.postalCode,
    10
  );
  errors.postalCity = validateCity(values.postalCity);
  errors.streetAddress = validateRequiredFieldsWithMaxLength(
    values.streetAddress,
    50
  );
  errors.latitude = validateGeographicData(values.latitude);
  errors.longitude = validateGeographicData(values.longitude);
  errors.phoneNumber = validatePhoneNumber(values.phoneNumber);
  errors.mobilePhoneNumber = validatePhoneNumber(values.mobilePhoneNumber);
  errors.faxNumber = validatePhoneNumber(values.faxNumber);
  errors.nip = validateByLengthOnly(values.nip, 20);
  errors.regon = validateByLengthOnly(values.regon, 20);
  errors.krs = validateByLengthOnly(values.krs, 20);
  errors.website = validateByLengthOnly(values.website, 120);
  errors.description = validateByLengthOnly(values.description, 100);
  errors.currency = validateRequiredFields(values.currency);

  if (props.mode === CreateWorkshopFormMode.SIGN_UP) {
    errors.acceptTerms = validateRequiredFields(values.acceptTerms);
    errors.captchaToken = validateRequiredFields(values.captchaToken);
  }

  return errors;
};
