/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { I18n } from "react-redux-i18n";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Input } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { rowsQuantityToDisplay } from 'shared/utils/utils';

export const ScoredProducts = () => {
  const dispatch = useDispatch();

  const scoredProducts = useSelector(state => state.workshopLoyaltyProgram.scoredProducts);
  const isFetching = useSelector(state => state.workshopLoyaltyProgram.scoredProductsFetching);

  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState();
  const [sortDirection, setSortDirection] = useState();
  const [first, setFirst] = useState(0)
  const [globalFilter, setGlobalFilter] = useState();

  useEffect(() => {
    dispatch(workshopLoyaltyProgramActions.scoredProductList({
      page: page - 1,
      size: pageLimit,
      sort: (sortField && sortDirection) && `${sortField},${sortDirection}`,
      search: globalFilter,

    }));
  }, [page, pageLimit, sortField, sortDirection, globalFilter]);

  return (
    <ContentWrapper title='workshopLoyaltyProgram.scoredProducts.header' scrollY>
      <Loadable active={isFetching} spinner>
        <div className='col' style={{minWidth: 976}}>
          <DataTable
            value={scoredProducts.content}
            rows={pageLimit}
            className='data-table-padding-class'
            responsive={true}
            emptyMessage={I18n.t('workshopLoyaltyProgram.scoredProducts.noProducts')}
            paginator={false}
            onSort={e => {
              setPage(1);
              setFirst(0);
              setSortField(e.sortField);
              setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
            }}
            header={
              <div style={{
                width: 320, position: 'relative',
                float: 'right'
              }}>
                <Input
                  type='search'
                  placeholder={I18n.t('workshops.searchPlaceholder')}
                  onInput={e => {
                    setFirst(0);
                    setPage(1);
                    setSortField(e.sortField);
                    setGlobalFilter(e.target.value);
                  }}
                  className='global-filter'
                />
              </div>
            }
          >
            <Column
              field='productType.name'
              header={I18n.t('workshopLoyaltyProgram.scoredProducts.productType')}
              sortable={true}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='homologationProductName'
              header={I18n.t('workshopLoyaltyProgram.scoredProducts.productName')}
              sortable={true}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='description'
              header={I18n.t('workshopLoyaltyProgram.scoredProducts.shortDescription')}
              sortable={false}
              style={{ whiteSpace: 'pre-wrap' }}
            />
            <Column
              field='value'
              header={I18n.t('workshopLoyaltyProgram.scoredProducts.productValue')}
              sortable={true}
            />
            
          </DataTable>
          <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
            <Paginator
              first={first}
              rows={pageLimit}
              totalRecords={scoredProducts.totalElements}
              pageLinkSize={5}
              onPageChange={(e) => {
                setPage(e.page + 1)
                setFirst(e.first)
              }}
              leftContent={
                <Dropdown
                  options={rowsQuantityToDisplay}
                  className='data-table-rows-dropdown'
                  value={pageLimit}
                  onChange={e => { setPage(1), setPageLimit(e.value) }}
                />
              }
            ></Paginator>
          </div>
        </div>
      </Loadable>
    </ContentWrapper>
  )
}
