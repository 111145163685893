/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';

export const ONE_DAY_MILIS = 24 * 60 * 60 * 1000;
export const ONE_YEAR_MILIS = 365.25 * ONE_DAY_MILIS;
export const ONE_HOURS_MILIS = 60 * 60 * 1000;
export const ONE_MINUTE_MILIS = 60 * 1000;

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT_SHORT = 'YYYY-MM-DD HH:mm';
export const YEAR_FORMAT = 'YYYY-MM-DD';
export const DATE_SHORT_FORMAT = 'DD-MM-YY';
export const DATE_SHORT_FORMAT_WITH_HOUR = 'DD-MM-YY HH:mm';

export const dateUtils = {
  countHoursBetweenDates,
  formatDate,
  formatTimePeriod,
  getDateFromInput,
  formatDatePickerDate,
  formatDatePickerDateWithEndOfTheDay
};

function countHoursBetweenDates(endDate, startDate = new Date()) {
  return Math.abs(endDate - startDate) / ONE_HOURS_MILIS;
}

function formatDate(value, format = DATE_FORMAT) {
  return value ? moment(value).format(format) : '';
}

function getDateFromInput(value) {
  return moment(value).set('hour', 23).toISOString();
}

function formatTimePeriod(startDate, endDate) {
  return (
    formatDate(startDate, 'YYYY-MM-DD') +
    ' — ' +
    formatDate(endDate, 'YYYY-MM-DD')
  );
}

function addZero(value) {
  if (value.toString().length === 1) {
    return '0'.concat(value);
  }
  return value;
}

function formatDatePickerDate(momentDate) {
  if(momentDate) {
    const date = momentDate.toDate();
    const month = addZero(date.getMonth()+1);
    const day = addZero(date.getDate());
    const iso = `${date.getFullYear()}-${month}-${day}T00:00:00Z`;
    return moment(iso);
  }
  return momentDate;
}

function formatDatePickerDateWithEndOfTheDay(momentDate) {
  if(momentDate) {
    const date = momentDate.toDate();
    const month = addZero(date.getMonth()+1);
    const day = addZero(date.getDate());
    console.log(date)
    const iso = `${date.getFullYear()}-${month}-${day}T21:59:59Z`;
    return moment(iso);
  }
  return momentDate;
}
