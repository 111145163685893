/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {
  ONE_DAY_MILIS,
  ONE_HOURS_MILIS,
  ONE_MINUTE_MILIS,
  ONE_YEAR_MILIS,
} from '../../utils/date.utils';

class Countdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(this.props.date);
      date ? this.setState(date) : this.onComplete();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  calculateCountdown(endDate) {
    let diff = Date.parse(new Date(endDate)) - Date.parse(new Date());

    if (diff <= 0) return false;

    const timeLeft = {years: 0, days: 0, hours: 0, min: 0, sec: 0};

    if (diff >= ONE_YEAR_MILIS) {
      timeLeft.years = Math.floor(diff / ONE_YEAR_MILIS);
      diff -= timeLeft.years * ONE_YEAR_MILIS;
    }
    if (diff >= ONE_DAY_MILIS) {
      timeLeft.days = Math.floor(diff / ONE_DAY_MILIS);
      diff -= timeLeft.days * ONE_DAY_MILIS;
    }
    if (diff >= ONE_HOURS_MILIS) {
      timeLeft.hours = Math.floor(diff / ONE_HOURS_MILIS);
      diff -= timeLeft.hours * ONE_HOURS_MILIS;
    }
    if (diff >= ONE_MINUTE_MILIS) {
      timeLeft.min = Math.floor(diff / ONE_MINUTE_MILIS);
      diff -= timeLeft.min * ONE_MINUTE_MILIS;
    }
    timeLeft.sec = diff / 1000;

    return timeLeft;
  }

  onComplete() {
    clearInterval(this.interval);
    setTimeout(() => this.props.onCompleteCallBack(), 500);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) value = '0' + value;
    return value;
  }

  render() {
    const countdown = this.state;

    return (
      <div>
        {countdown.days > 0 && (
          <span>
            <span>
              <strong>{this.addLeadingZeros(countdown.days)}</strong>
              <Translate
                value={`countDown.${countdown.days === 1 ? 'day' : 'days'}`}
              />
            </span>
          </span>
        )}

        <span>
          <span>
            <strong>{this.addLeadingZeros(countdown.hours)}</strong>
            <span>h </span>
          </span>
        </span>

        <span>
          <span>
            <strong>{this.addLeadingZeros(countdown.min)}</strong>
            <span>min </span>
          </span>
        </span>

        {countdown.hours === 0 && countdown.days === 0 && (
          <span>
            <span>
              <strong>{this.addLeadingZeros(countdown.sec)}</strong>
              <span>s</span>
            </span>
          </span>
        )}
      </div>
    );
  }
}

Countdown.propTypes = {
  date: PropTypes.string.isRequired,
  onCompleteCallBack: PropTypes.func,
};

Countdown.defaultProps = {
  date: new Date(),
};

export default Countdown;
