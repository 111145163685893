/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {getDecimalSeparator} from 'shared/utils/i18n';
import {
  getOrEmptyArray,
  getOrEmptyObj,
  getOrNull,
  getOrZero,
} from 'shared/utils/utils';
import '../valuation-inquiry-response-form.css';
import {validate} from './validators';
import ValuationInquiryResponseFormSchemaPage3 from './ValuationInquiryResponseFormSchemaPage3';
import {workshopWorkerActions} from 'shared/actions/workshop-worker.actions';

const ValuationInquiryResponseFormPage3 = reduxForm({
  form: 'valuationInquiryResponseForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage3);

export function calculateTotalSum(state, formName, locale = '') {
  const selector = formValueSelector(formName);
  const servicesFields = getOrEmptyArray(selector(state, 'services'));
  const lpgTotalCostField = getOrNull(selector(state, 'lpgTotalCost'));
  const additionalCostField = getOrEmptyObj(selector(state, 'additionalCost'));

  const servicesCostSum = getOrZero(
    servicesFields
      .map(s => getOrEmptyObj(s.serviceCost).value)
      .reduce((a, b) => a + b, 0)
  );
  const lpgTotalCostSum = getOrZero(lpgTotalCostField);
  const additionalCostSum = getOrZero(additionalCostField.value);

  return (servicesCostSum + lpgTotalCostSum + additionalCostSum)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, '$1 ')
    .replace('.', getDecimalSeparator());
}

function mapStateToProps(state) {
  const {i18n, workshop, workshopWorker} = state;
  const {data} = workshop;
  const currency =
    data && data.workshopDetails ? data.workshopDetails.currency : null;
  const loggedWorkshopId = data ? data.workshopId : null;
  const {isFetching, workshopWorkers} = workshopWorker;
  const totalSum = calculateTotalSum(
    state,
    'valuationInquiryResponseForm',
    i18n.locale
  );

  return {
    i18n,
    totalSum,
    currency,
    loggedWorkshopId,
    workshopWorkers,
    isFetching,
  };
}

const mapDispatchToProps = {
  findAllWorkersByWorkshopId: workshopWorkerActions.findAllWorkersByWorkshopId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiryResponseFormPage3);
