/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {getOrEmptyArray, indexArray} from '../../../utils/utils';

class CalendarTable extends Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.renderCreateBtn = this.renderCreateBtn.bind(this);
    this.renderColor = this.renderColor.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  renderCreateBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={() => this.props.onCreateClick()}>
        <Translate value='calendar.createBtn' />
      </Button>
    );
  }

  renderColor(data) {
    return (
      <div style={{backgroundColor: data.color, width: '30px', height: '30px'}}>
        &nbsp;
      </div>
    );
  }

  prepareCalendarsToDisplay(calendars) {
    return getOrEmptyArray(indexArray(calendars)).map(calendar => ({
      ...calendar,
      urlShort:
        calendar.url.length > 30
          ? calendar.url.substring(0, 30) + '...'
          : calendar.url,
    }));
  }

  render() {
    const {calendars, onRowClick, headerBuilder, renderRowsDropdown} =
      this.props;
    return (
      <DataTable
        value={this.prepareCalendarsToDisplay(calendars)}
        header={headerBuilder()}
        ref={this.setDatatableRef}
        rows={this.props.rows}
        globalFilter={this.props.globalFilter}
        paginator={true}
        responsive={true}
        className='data-table-padding-class'
        emptyMessage={I18n.t('calendar.emptyPlaceholder')}
        onRowClick={onRowClick}
        paginatorLeft={renderRowsDropdown()}
        paginatorRight={this.renderCreateBtn()}>
        <Column
          field='index'
          className='lp-col'
          header={I18n.t('calendar.lp')}
          sortable={true}
        />
        <Column
          field='label'
          header={I18n.t('calendar.label')}
          sortable={true}
        />
        <Column
          field='urlShort'
          header={I18n.t('calendar.url')}
          sortable={true}
        />
        <Column
          field='color'
          header={I18n.t('calendar.color')}
          body={this.renderColor}
        />
      </DataTable>
    );
  }
}

CalendarTable.propTypes = {
  calendars: PropTypes.array,
  onRowClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

CalendarTable.defaultProps = {
  calendars: [],
  onRowClick: () => {},
  onCreateClick: () => {},
};

export {CalendarTable as CalendarTableCore};

export default withDataTableCore(CalendarTable);
