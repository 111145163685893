/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {getErrorMessage, searchCriteriaToString} from '../utils/utils';
import {messageActions} from './message.actions';
import {subscriptionPlanService} from '../services/subscription-plan.service';
import {subscriptionPlanConstants} from '../constants/subscription-plan.constants';
import {operation, SearchCriteriaModel} from '../model/SearchCriteriaModel';

export const subscriptionPlanActions = {
  findSubscriptionPlansBySearchCriteria,
  findAllSubscriptionPlans,
  findActiveSubscriptionPlansByCountry,
  findSubscriptionPlan,
  updateSubscriptionPlan,
  deleteSubscriptionPlan,
  createSubscriptionPlan,
  activateSubscriptionPlan,
};

function findSubscriptionPlansBySearchCriteria(searchCriteria = []) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService
      .findSubscriptionPlans(searchCriteriaToString(searchCriteria))
      .then(
        response => {
          dispatch({
            type: subscriptionPlanConstants.LOAD_SUBSCRIPTION_PLANS,
            payload: response,
          });
          dispatch({
            type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
          });
        },
        error => {
          dispatch(messageActions.errorMessage('subscriptionPlan.fetchError'));
          dispatch({
            type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
          });
        }
      );
  };
}

function findAllSubscriptionPlans() {
  return findSubscriptionPlansBySearchCriteria();
}

function findActiveSubscriptionPlansByCountry(country) {
  const searchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'ACTIVE'),
    new SearchCriteriaModel('country', operation.EQUAL, country),
  ];
  return findSubscriptionPlansBySearchCriteria(searchCriteria);
}

function findSubscriptionPlan(subscriptionPlanId) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService.findSubscriptionPlan(subscriptionPlanId).then(
      response => {
        dispatch({
          type: subscriptionPlanConstants.LOAD_SUBSCRIPTION_PLAN,
          payload: response,
        });
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage('subscriptionPlan.fetchSingleError')
        );
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      }
    );
  };
}

function updateSubscriptionPlan(subscriptionPlanId, data) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService
      .updateSubscriptionPlan(subscriptionPlanId, data)
      .then(
        response => {
          dispatch({
            type: subscriptionPlanConstants.LOAD_SUBSCRIPTION_PLAN,
            payload: response,
          });
          dispatch({
            type: subscriptionPlanConstants.REFRESH_SUBSCRIPTION_PLANS,
          });
          dispatch(
            messageActions.successMessage('subscriptionPlan.updateSuccessMsg')
          );
          dispatch({
            type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
          });
        },
        error => {
          dispatch(
            messageActions.errorMessage('subscriptionPlan.updateErrorMsg')
          );
          dispatch({
            type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
          });
        }
      );
  };
}

function deleteSubscriptionPlan(subscriptionPlanId, onSuccess = () => {}) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService.deleteSubscriptionPlan(subscriptionPlanId).then(
      response => {
        dispatch({type: subscriptionPlanConstants.CLEAR_SUBSCRIPTION_PLAN});
        dispatch({
          type: subscriptionPlanConstants.REFRESH_SUBSCRIPTION_PLANS,
        });
        dispatch(
          messageActions.successMessage('subscriptionPlan.deleteSuccessMsg')
        );
        onSuccess();
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage('subscriptionPlan.deleteErrorMsg')
        );
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      }
    );
  };
}

function createSubscriptionPlan(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService.createSubscriptionPlan(data).then(
      response => {
        dispatch({
          type: subscriptionPlanConstants.REFRESH_SUBSCRIPTION_PLANS,
        });
        dispatch(
          messageActions.successMessage('subscriptionPlan.createSuccessMsg')
        );
        onSuccess();
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'subscriptionPlan', 'createErrorMsg')
          )
        );
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      }
    );
  };
}

function activateSubscriptionPlan(subscriptionPlanId, onSuccess = () => {}) {
  return dispatch => {
    dispatch({
      type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_START,
    });

    subscriptionPlanService.activateSubscriptionPlan(subscriptionPlanId).then(
      response => {
        dispatch({
          type: subscriptionPlanConstants.REFRESH_SUBSCRIPTION_PLANS,
        });
        dispatch(
          messageActions.successMessage('subscriptionPlan.activateSuccessMsg')
        );
        onSuccess();
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'subscriptionPlan', 'activateErrorMsg')
          )
        );
        dispatch({
          type: subscriptionPlanConstants.SUBSCRIPTION_PLAN_REQUEST_END,
        });
      }
    );
  };
}
