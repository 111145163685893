/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import ValidationMessageEnum, {
  ReqexpValidationEnum,
} from '../../../utils/utils';
import formatDoubleNumber from '../formatter/DoubleNumberFormatter';

export const containsOnlyWhiteSpaces = value => {
  return value && ReqexpValidationEnum.CONTAINS_WHITE_SPACES_ONLY.test(value);
};

export const validateFirstName = value => {
  if (!value || containsOnlyWhiteSpaces(value))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (value.length > 60)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 60}};
};

export const validateLastName = value => {
  if (!value || containsOnlyWhiteSpaces(value))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (value.length > 60)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 60}};
};

export const validateCity = value => {
  if (!value || containsOnlyWhiteSpaces(value))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (value.length > 120)
    return {
      key: ValidationMessageEnum.MAX_LENGTH,
      params: {maxLength: 120},
    };
};

export const validateEmail = (value, isRequired = true) => {
  if (!value && isRequired) return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (value && !ReqexpValidationEnum.EMAIl.test(value))
    return {key: ValidationMessageEnum.EMAIl, params: {}};
  else if (value && value.length > 60)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 60}};
};

export const validatePassword = value => {
  if (!value) return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (value.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (!ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(value))
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (value.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

export const validateConfirmPassword = values => {
  if (!values.confirmPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.confirmPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
      values.confirmPassword
    )
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (values.password !== values.confirmPassword)
    return {key: ValidationMessageEnum.CONFIRM_PASSWORD, params: {}};
  else if (values.confirmPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

export const validateRequiredFields = value => {
  if (!value || containsOnlyWhiteSpaces(value))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
};

export const validateRequiredFieldsWithZeroAsValidValue = value => {
  if (!(value || value === 0))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
};

export const validateByLengthOnly = (value, maxLength) => {
  if (value && value.length > maxLength)
    return {
      key: ValidationMessageEnum.MAX_LENGTH,
      params: {maxLength: maxLength},
    };
};

export const validateRequiredFieldsWithMaxLength = (value, maxLength) => {
  if (!value || containsOnlyWhiteSpaces(value))
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  if (value.length > maxLength)
    return {
      key: ValidationMessageEnum.MAX_LENGTH,
      params: {maxLength: maxLength},
    };
};

export const validateGeographicData = value => {
  if (!value) return {key: ValidationMessageEnum.REQUIRED, params: {}};
  if (!value.match(ReqexpValidationEnum.FLOAT_NUMBER))
    return {key: ValidationMessageEnum.INCORRECT_DATA, params: {}};
};

export const validatePhoneNumber = (value, isRequired = false) => {
  if (value && value.phoneNumber && !value.phoneNumberPrefix)
    return {
      key: ValidationMessageEnum.PHONE_NUMBER_PREFIX_REQUIRED,
      params: {},
    };
  if (value && value.phoneNumber && value.phoneNumber.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
  if (
    (isRequired && !value) ||
    (isRequired && value && (!value.phoneNumberPrefix || !value.phoneNumber))
  )
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
};

export const validatePhoneNumberWithoutPrefixField = (value, isRequired = true) => {
    if (isRequired && !value)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
    else if (value  && value.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
    else if (value && !ReqexpValidationEnum.PHONE_NUMBER.test(value))
    return {key: ValidationMessageEnum.PHONE_NUMBER, params: {}};
};

export const validateRequiredNumberWithRange = (value, minRange, maxRange) => {
  if (!value) return {key: ValidationMessageEnum.REQUIRED, params: {}};
  if (isNaN(value))
    return {key: ValidationMessageEnum.NOT_A_NUMBER, params: {}};
  if (minRange && value < minRange)
    return {
      key: ValidationMessageEnum.MIN_RANGE,
      params: {minRange: formatDoubleNumber(minRange)},
    };
  if (maxRange && value > maxRange)
    return {
      key: ValidationMessageEnum.MAX_RANGE,
      params: {maxRange: formatDoubleNumber(maxRange)},
    };
  if (
    value.toString().indexOf('.') >= 0 &&
    value.length > value.toString().indexOf('.') + 3
  )
    return {key: ValidationMessageEnum.MORE_THAN_TWO_DECIMALS, params: {}};
};

export const validateNumberWithRange = (value, minRange, maxRange) => {
  if (value) {
    if (isNaN(value))
      return {key: ValidationMessageEnum.NOT_A_NUMBER, params: {}};
    if (minRange && value < minRange)
      return {
        key: ValidationMessageEnum.MIN_RANGE,
        params: {minRange: formatDoubleNumber(minRange)},
      };
    if (maxRange && value > maxRange)
      return {
        key: ValidationMessageEnum.MAX_RANGE,
        params: {maxRange: formatDoubleNumber(maxRange)},
      };
  }
};

export const validateNewPassword = values => {
  if (!values.newPassword && values.currentPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.currentPassword && values.newPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.currentPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(values.newPassword)
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (values.currentPassword && values.newPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};

export const validateConfirmNewPassword = values => {
  if (!values.confirmPassword && values.currentPassword)
    return {key: ValidationMessageEnum.REQUIRED, params: {}};
  else if (values.currentPassword && values.confirmPassword.length < 8)
    return {key: ValidationMessageEnum.MIN_LENGTH, params: {minLength: 8}};
  else if (
    values.currentPassword &&
    !ReqexpValidationEnum.PASSWORD_SPECIAL_CHARACTERS.test(
      values.confirmPassword
    )
  )
    return {key: ValidationMessageEnum.PASSWORD, params: {}};
  else if (
    values.currentPassword &&
    values.newPassword !== values.confirmPassword
  )
    return {key: ValidationMessageEnum.CONFIRM_PASSWORD, params: {}};
  else if (values.currentPassword && values.confirmPassword.length > 20)
    return {key: ValidationMessageEnum.MAX_LENGTH, params: {maxLength: 20}};
};
