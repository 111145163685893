/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const BlockType = {
	ALL: "ALL",
	BLOCKED: "BLOCKED",
	UNBLOCKED: "UNBLOCKED",
};

export const DateType = {
	ALL: "ALL",
	FROM_TO: "FROM_TO",
	SPECIFIC_DATE: "SPECIFIC_DATE",
};