/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {languageConstants} from '../constants/language.constants';
import {SyncState} from '../utils/sync';

export function languageReducer(state = {}, action) {
  switch (action.type) {
    case languageConstants.LANGUAGE_SYNC_LOGS_REQUEST_START:
      return {...state, isFetchingLanguageSyncLogs: true};

    case languageConstants.LANGUAGE_SYNC_LOGS_REQUEST_END:
      return {...state, isFetchingLanguageSyncLogs: false};

    case languageConstants.LOAD_LANGUAGE_SYNC_LOGS:
      return {...state, languageSyncLogs: action.payload.data};

    case languageConstants.CLEAR_LANGUAGE_SYNC_LOGS:
      const {languageSyncLogs, ...otherState1} = state;
      return otherState1;

    case languageConstants.LANGUAGE_REQUEST_START:
      return {...state, isFetchingLanguage: true};

    case languageConstants.LANGUAGE_REQUEST_END:
      return {...state, isFetchingLanguage: false};

    case languageConstants.LANGUAGES_REQUEST_START:
      return {...state, isFetchingLanguages: true};

    case languageConstants.LANGUAGES_REQUEST_END:
      return {...state, isFetchingLanguages: false};

    case languageConstants.LOAD_LANGUAGES:
      return {
        ...state,
        languages: action.payload.data,
        languagesDataSyncState: SyncState.UP_TO_DATE,
      };

    case languageConstants.CLEAR_LANGUAGES:
      const {languages, ...otherState2} = state;
      return otherState2;

    case languageConstants.REFRESH_LANGUAGES:
      return {...state, languagesDataSyncState: SyncState.OBSOLETE};

    case languageConstants.LOAD_LANGUAGE:
      return {...state, language: action.payload.data};

    case languageConstants.CLEAR_LANGUAGE:
      const {language, ...otherState3} = state;
      return otherState3;

    default:
      return state;
  }
}
