/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const StatisticsEnum = {
  VALUATION_INQUIRY: 'VALUATION_INQUIRY',
  VALUATION: 'VALUATION',
  LPG: 'LPG',
  ORDER: 'ORDER',
};
