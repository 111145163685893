/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateNumberWithRange,
  validatePhoneNumber,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';

export const validateDetailsForm = values => {
  let errors = {};

  errors.phoneNumber = validatePhoneNumber(values.phoneNumber);
  errors.mobilePhoneNumber = validatePhoneNumber(values.mobilePhoneNumber);
  errors.faxNumber = validatePhoneNumber(values.faxNumber);
  errors.nip = validateByLengthOnly(values.nip, 20);
  errors.regon = validateByLengthOnly(values.regon, 20);
  errors.krs = validateByLengthOnly(values.krs, 20);
  errors.description = validateByLengthOnly(values.description, 500);

  return errors;
};

export const validateAddressForm = values => {
  let errors = {};

  errors.companyName = validateRequiredFieldsWithMaxLength(
    values.companyName,
    100
  );
  errors.ownerName = validateRequiredFieldsWithMaxLength(values.ownerName, 50);
  errors.city = validateByLengthOnly(values.city, 60);
  errors.postalCode = validateByLengthOnly(values.postalCode, 10);
  errors.postalCity = validateByLengthOnly(values.postalCity, 60);
  errors.streetAddress = validateByLengthOnly(values.streetAddress, 50);

  return errors;
};

export const validateOtherSectionForm = values => {
  let errors = {};

  errors.trader = validateByLengthOnly(values.trader, 100);
  errors.taxValueInPercentage = validateNumberWithRange(
    values.taxValueInPercentage,
    0,
    99.99
  );

  return errors;
};
