/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {NavLink} from 'react-router-dom';
import {Button} from 'reactstrap';
import {userService} from 'shared/services/user.service';

class AccessDenied extends React.Component {
  renderThinUserTip() {
    return (
      <div
        style={{width: '50%', margin: '0 auto', lineHeight: '50%'}}
        className='text-center'>
        <h3>
          <Translate value={'accessDenied.fillMissingDataHeader'} />
        </h3>
        <h5>
          <Translate value={'accessDenied.fillMissingDataTip'} />
        </h5>
        <NavLink to={'/user/profile'}>
          <Button size='sm' className='mt-2 main-btn'>
            <Translate value='accessDenied.editUserData' />
          </Button>
        </NavLink>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div
          style={{
            width: '50%',
            margin: '0 auto',
            lineHeight: '50%',
            padding: '10% 0 5% 0',
          }}
          className='text-center'>
          <h3>
            <Translate value={'accessDenied.accessDeniedText'} />
          </h3>
        </div>
        {userService.isThinUser() && this.renderThinUserTip()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {i18n: state.i18n};
}

export default connect(mapStateToProps)(AccessDenied);
