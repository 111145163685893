/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'redux-form';
import '../valuation-inquiry-response-form.css';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {RenderServiceSection} from './RenderServiceSection';

export default class ValuationInquiryResponseFormSchemaPage1 extends React.Component {
  componentDidMount() {
    this.props.setPageTitle('valuationInquiryResponse.valuateServices');
  }

  render() {
    const {
      handleSubmit,
      handleCancel,
      previousPage,
      invalid,
      handleCreateDraft,
      valuationInquiry,
      currency,
    } = this.props;

    return (
      <div className='pb-5'>
        <form
          className='valuation-inquiry-response-form'
          onSubmit={handleSubmit}>
          <FieldArray
            name='services'
            component={RenderServiceSection}
            valuationInquiry={valuationInquiry}
            rerenderOnEveryChange={true}
            currency={currency}
          />

          <div className='text-lg-right text-center mt-3'>
            {previousPage && (
              <Button className='main-btn' onClick={previousPage}>
                <Translate value='valuationInquiryResponse.previousPageBtn' />
              </Button>
            )}
            <Button className='main-btn' type='submit' disabled={invalid}>
              <Translate value='valuationInquiryResponse.nextPageBtn' />
            </Button>
          </div>

          <div className='text-lg-right text-center mt-3'>
            {handleCreateDraft && (
              <Button className='main-btn' onClick={handleCreateDraft}>
                <Translate value='valuationInquiryResponse.createDraftBtn' />
              </Button>
            )}
            <Button className='main-btn' onClick={handleCancel}>
              <Translate value='valuationInquiryResponse.cancelBtn' />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ValuationInquiryResponseFormSchemaPage1.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  previousPage: PropTypes.func,
  currency: PropTypes.string.isRequired,
};
