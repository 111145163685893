/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {Field} from 'redux-form';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import './service-form.css';

export class ServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {confirmDeleteModalOpen: false};
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() => this.props.handleDelete(this.props.selectedServiceId)}
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      <div
        className='text-sm-right text-center'
        style={{display: 'inline-block'}}>
        {this.renderConfirmDeleteModal()}
        <Button
          id='service-edit-form-delete-btn'
          className='btn-danger'
          style={fixedBtn}
          onClick={() => this.setState({confirmDeleteModalOpen: true})}>
          <Translate value={'services.deleteBtn'} />
        </Button>
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      invalid,
      titleKey,
      handleDelete,
      handleCancel,
      privateServicesRestriction,
    } = this.props;

    return (
      <div className='service-edit-form p-5'>
        {titleKey && (
          <h2 className='my-5 text-center'>
            <Translate value={titleKey} />
          </h2>
        )}
        {privateServicesRestriction && (
          <Alert color='warning' className='text-center'>
            <Translate value='services.newWorkshopServiceTip' />
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <Field
            name='name'
            type='text'
            component={renderField}
            required={true}
            label={I18n.t('services.nameLabel')}
            icon='fa fa-briefcase'
          />
          <Field
            name='serviceCategoryId'
            component={RenderDropdown}
            options={this.props.categoryOptions}
            placeholder={I18n.t('services.categoryPlaceholder')}
            label={I18n.t('services.categoryLabel')}
            icon='fa fa-folder'
            required={true}
          />
          <Field
            name='description'
            type='text'
            component={RenderTextArea}
            required={false}
            label={I18n.t('services.descriptionLabel')}
            icon='fa fa-info'
          />
          <div className='text-sm-right text-center mt-2'>
            <Button
              id='service-edit-form-submit-btn'
              className='main-btn'
              style={fixedBtn}
              type='submit'
              disabled={invalid}>
              <Translate value={'services.saveBtn'} />
            </Button>
            <Button
              id='service-edit-form-cancel-btn'
              className='main-btn'
              style={fixedBtn}
              onClick={handleCancel}>
              <Translate value={'services.cancelBtn'} />
            </Button>
            {handleDelete && this.renderDeleteSection()}
          </div>
        </form>
      </div>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};
