/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import './settings-icon.css';
import {NavLink} from 'react-router-dom';
import {userService} from '../../services/user.service';

export class SettingsIcon extends React.Component {
  render() {
    return (
      <div className='settings-icon align-horizontal'>
        <div id='settings-icon-toggle'>
          <NavLink to={`${userService.getPathWithRoleName()}/settings`}>
            <i className='fa fa-cog fa-3x clickable' />
          </NavLink>
        </div>
      </div>
    );
  }
}
