/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {languageActions} from 'shared/actions/language.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import LanguageSyncLogsTable from 'shared/components/languages/language-sync-logs-table/LanguageSyncLogsTable';
import {languageConstants} from 'shared/constants/language.constants';
import {dateUtils} from 'shared/utils/date.utils';
import {getOrEmptyArray, getOrEmptyObj} from 'shared/utils/utils';

class LanguageSyncLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      syncDate: null,
    };

    this.onSyncDateChange = this.onSyncDateChange.bind(this);
  }

  componentDidMount() {
    this.props.findAllLanguageSyncLogs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.languageSyncLogs !== prevProps.languageSyncLogs)
      this.setState({syncDate: this.getNewestSyncDate()});
  }

  componentWillUnmount() {
    this.props.clearLanguageSyncLogs();
  }

  getNewestSyncDate() {
    return getOrEmptyObj(getOrEmptyArray(this.props.languageSyncLogs)[0])
      .syncDate;
  }

  onSyncDateChange(syncDate) {
    this.setState({syncDate: syncDate});
  }

  getSyncDateOptions() {
    return getOrEmptyArray(this.props.languageSyncLogs).map(lsl => ({
      label: dateUtils.formatDate(lsl.syncDate),
      value: lsl.syncDate,
    }));
  }

  getSelectedSyncDateLogs() {
    return getOrEmptyObj(
      getOrEmptyArray(this.props.languageSyncLogs).find(
        lsl => lsl.syncDate === this.state.syncDate
      )
    ).syncLogDetails;
  }

  renderSyncDateDropdown() {
    return (
      <div className='root-panel m-3 p-3 row justify-content-center'>
        <div className='col-xl-8 col-lg-10 col-12 mb-3'>
          <RenderDropdown
            label={I18n.t('languageSyncLogs.syncDateDropdownLabel')}
            placeholder={I18n.t('languageSyncLogs.syncDateDropdownPlaceholder')}
            input={{
              value: this.state.syncDate,
              onChange: this.onSyncDateChange,
            }}
            options={this.getSyncDateOptions()}
            meta={{}}
          />
        </div>
      </div>
    );
  }

  render() {
    const {isFetchingLanguageSyncLogs} = this.props;

    return (
      <ContentWrapper title='languagesContainer.languageSyncLogsBtn'>
        {this.renderSyncDateDropdown()}
        <Loadable active={isFetchingLanguageSyncLogs} spinner>
          <div className='p-3'>
            <LanguageSyncLogsTable logs={this.getSelectedSyncDateLogs()} />
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, language} = state;
  const {isFetchingLanguageSyncLogs, languageSyncLogs} = language;

  return {i18n, isFetchingLanguageSyncLogs, languageSyncLogs};
}

const mapDispatchToProps = {
  findAllLanguageSyncLogs: languageActions.findAllLanguageSyncLogs,
  clearLanguageSyncLogs: () => ({
    type: languageConstants.CLEAR_LANGUAGE_SYNC_LOGS,
  }),
};

const connectedLanguageSyncLogsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSyncLogs);
export {connectedLanguageSyncLogsWithRedux as LanguageSyncLogs};
