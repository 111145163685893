/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect} from 'react';
import WorkshopProfileContainer from "./workshop-profile-container/WorkshopProfileContainer";
import WorkshopLoyaltyProgramProfileContainer from "./workshop-loyalty-program-profile-container/WorkshopLoyaltyProgramProfileContainer";
import {workshopLoyaltyProgramActions} from "../../../shared/actions/workshopLoyaltyProgram.actions";
import {statuteActions} from "../../../shared/actions/statute.actions";
import {useDispatch, useSelector} from "react-redux";
import './workshop-profile.css';

const WorkshopProfile = () => {
  const dispatch = useDispatch();

  const workshopLoyaltyProgramData = useSelector(state => state.workshopLoyaltyProgram.registrationData);
  const workshopData = useSelector(state => state.workshop.data);

  const getWorkshopLoyaltyProgramData = () => {
    if (workshopData && workshopData.workshopId) {
      dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramRegistrationData(workshopData.workshopId));
      dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramPointsData(workshopData.workshopId));
      dispatch(statuteActions.checkValidStatuteAcceptation(workshopData.workshopId));
    }
  };

  useEffect(() => {
    getWorkshopLoyaltyProgramData();
    dispatch(statuteActions.findLoyaltyProgramStatutes());
  }, []);

  useEffect(() => {
    getWorkshopLoyaltyProgramData();
  }, [workshopData]);

  return (
    <div className={'row containers'}>
      <WorkshopProfileContainer />
      {workshopLoyaltyProgramData && <WorkshopLoyaltyProgramProfileContainer />}
    </div>
  );
}

export default WorkshopProfile;
