/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React, { useState, useEffect, useLayoutEffect } from "react";
import Loadable from "react-loading-overlay";
import { Button } from "reactstrap";
import ContentWrapper from "shared/components/content-wrapper/ContentWrapper";
import { UncontrolledTooltip } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import styles from "./WarrantyEdit.module.css";
import WarrantyStaticContentContainer from "../warranty-static-container/WarrantyStaticContentContainer";
import WarrantyInstalationData from "../warranty-instalation-data/WarrantyInstalationData";
import WarrantyNotes from "../warranty-notes/WarrantyNotes";
import WarrantyNotesEdit from "../warranty-notes/WarrantyNotesEdit";
import { I18n } from "react-redux-i18n";
import { Translate } from "react-redux-i18n";
import WarrantyBook from "../warranty-book/WarrantyBook";
import { Prompt } from "react-router-dom";
import { history } from "shared/utils/history";
import { warrantyActions } from "shared/actions/warranty.actions";
import WarrantyModal from "../warranty-modal/WarrantyModal";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import useWarrantyBookLocalStorageGetValue from "shared/hooks/useWarrantyBookLocalStorageGetValue";
import useWarrantyBookLocalStorageSetValue from "shared/hooks/useWarrantyBookLocalStorageSetValue";
import { useParams } from "react-router-dom";
import {
  technicalDetailsValidator,
  descriptionValidator,
  additionalAssemblyValidator,
  notesValidator,
  carDetailsValidator,
  warrantyNumberValidator,
} from "./validators";
import PeriodicInspections from "../periodic-inspections/PeriodicInspections";
import {userService} from 'shared/services/user.service';

const WarrantyEdit = () => {
  const tabs = [
    {
      id: "1",
      title: "warranty.warrantyBook",
    },
    {
      id: "2",
      title: "warranty.technicalDataGasInstallation",
    },
    {
      id: "3",
      title: "warranty.switchOperation",
    },
    {
      id: "4",
      title: "warranty.safetyRules",
    },
    {
      id: "5",
      title: "warranty.vehicleCheckup",
    },
    {
      id: "6",
      title: "warranty.termsOfWarranty",
    },
    {
      id: "7",
      title: "warranty.periodicInspections",
    },
    {
      id: "8",
      title: "warranty.notes",
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [editMode, setEditMode] = useState(
    localStorage.getItem("editMode") !== null
      ? JSON.parse(localStorage.getItem("editMode"))
      : false
  );
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const warrantyBookId = useWarrantyBookLocalStorageGetValue("id");

  const isActive = (tabId) => tabId === activeTab.id;

  const [
    additionalInstallationDetailsJSON,
    setAdditionalInstallationDetailsJSON,
  ] = useWarrantyBookLocalStorageSetValue("additionalInstallationDetails");
  const [technicalDetailsJSON, setTechnicalDetailsJSON] =
    useWarrantyBookLocalStorageSetValue("technicalDetails");
  const [descriptionJSON, setDescriptionJSON] =
    useWarrantyBookLocalStorageSetValue("description");
  const [notesJSON, setNotesJSON] =
    useWarrantyBookLocalStorageSetValue("notes");
  const [carDetailsJSON, setCarDetailsJSON] =
    useWarrantyBookLocalStorageSetValue("carDetails");
  const [warrantyNumberJSON, setWarrantyNumberJSON] =
    useWarrantyBookLocalStorageSetValue("warrantyNumber");
  const [termsAcceptedJSON, setTermsAcceptedJSON] = 
    useWarrantyBookLocalStorageSetValue("termsAccepted");

  const dispatch = useDispatch();
  const params = useParams();
  const [isTechnicalDetailsJSONHasErrors, setIsTechnicalDetailsJSONHasErrors] =
    useState(false);
  const [
    isAdditionalInstallationDetailsJSONHasErrors,
    setIsAdditionalInstallationDetailsJSONHasErrors,
  ] = useState(false);

  const [isDescriptionJSONHasErrors, setIsDescriptionJSONHasErrors] =
    useState(false);
  const [isNotesJSONHasErrors, setIsNotesJSONHasErrors] = useState(false);
  const [isCarDetailsJSONHasErrors, setIsCarDetailsJSONHasErrors] =
    useState(false);
  const [tabsWithError, setTabsWithError] = useState([]);

  const isWarrantyFetching = useSelector(
    (state) => state.warrantyBook.isFetching
  );
  const initializedBook = useSelector(
    (state) => state.warrantyBook.initializedWarranty
  );

  const openErrorWarrantyBookModal = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WarrantyModal errorStep />,
      title: I18n.t("warranty.hasErrorsModalTitle"),
    });
  };

  const cancelEditionWarrantyBookModal = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WarrantyModal cancelStep setEditMode = {setEditMode} />,
      title: I18n.t("warranty.onLeaveMessage"),
    });
  };

  const openEditApproveChangesWarrantyBookModal = () => {
    dispatch({
      type: updateDataSideBarConstants.SHOW,
      variableComponent: <WarrantyModal enterStep setEditMode={setEditMode} />,
      title: I18n.t("warranty.onEditChangesApprove"),
    });
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation) {
      dispatch({
        type: updateDataSideBarConstants.SHOW,
        variableComponent: (
          <WarrantyModal
            leaveStep
            setConfirmedNavigation={setConfirmedNavigation}
          />
        ),
        title: I18n.t("warranty.onLeaveMessage"),
      });
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (
      !isWarrantyFetching &&
      initializedBook &&
      initializedBook.id !== warrantyBookId
    ) {
      setTechnicalDetailsJSON(initializedBook.technicalDetails);
      setDescriptionJSON(initializedBook.description);
      setAdditionalInstallationDetailsJSON(
        initializedBook.additionalInstallationDetails
      );
      setNotesJSON(initializedBook.notes);
      setCarDetailsJSON(initializedBook.carDetails);
      setWarrantyNumberJSON(initializedBook.warrantyNumber);
      setTermsAcceptedJSON(initializedBook.termsAccepted);
    }
  }, [isWarrantyFetching]);

  useEffect(() => {
    if (!isWarrantyFetching && initializedBook) {
      localStorage.setItem(
        "warrantyBook",
        JSON.stringify({
          id: initializedBook.id,
          vin: initializedBook.vin,
          status: initializedBook.status,
          creatorUserId: initializedBook.creatorUserId,
          createdDate: initializedBook.createdDate,
          additionalInstallationDetails: additionalInstallationDetailsJSON,
          technicalDetails: technicalDetailsJSON,
          warrantyNumber: warrantyNumberJSON,
          termsAccepted: termsAcceptedJSON,
          carDetails: carDetailsJSON,
          description: descriptionJSON,
          notes: notesJSON,
        })
      );
      localStorage.setItem("editMode", editMode);
    }
  }, [
    isWarrantyFetching,
    additionalInstallationDetailsJSON,
    technicalDetailsJSON,
    carDetailsJSON,
    descriptionJSON,
    notesJSON,
    editMode,
    termsAcceptedJSON
  ]);

  useLayoutEffect(() => {
    isTechnicalDetailsJSONHasErrors ||
    isAdditionalInstallationDetailsJSONHasErrors ||
    isDescriptionJSONHasErrors
      ? setTabsWithError((tabsWithError) => [
          ...new Set([...tabsWithError, "2"]),
        ])
      : setTabsWithError(tabsWithError.filter((tab) => tab !== "2"));
    isNotesJSONHasErrors
      ? setTabsWithError((tabsWithError) => [
          ...new Set([...tabsWithError, "8"]),
        ])
      : setTabsWithError((tabsWithError) =>
          tabsWithError.filter((tab) => tab !== "8")
        );
    isCarDetailsJSONHasErrors
      ? setTabsWithError((tabsWithError) => [
          ...new Set([...tabsWithError, "1"]),
        ])
      : setTabsWithError((tabsWithError) =>
          tabsWithError.filter((tab) => tab !== "1")
        );
  }, [
    isTechnicalDetailsJSONHasErrors,
    isAdditionalInstallationDetailsJSONHasErrors,
    isDescriptionJSONHasErrors,
    isNotesJSONHasErrors,
    isCarDetailsJSONHasErrors,
  ]);

  useEffect(() => {
      dispatch(warrantyActions.getWarrantyBookById(params.id));
      dispatch(warrantyActions.getPeriodicInspectionsByWarrantyId(params.id));
  }, [editMode]);

  const renderSubsection = () => {
    switch (activeTab.id) {
      case "1":
        return (
          <WarrantyBook
            editMode={editMode}
            initializedBook={initializedBook}
            setCarDetailsJSON={setCarDetailsJSON}
            setWarrantyNumberJSON={setWarrantyNumberJSON}
            isCarDetailsJSONHasErrors={isCarDetailsJSONHasErrors}
            setIsCarDetailsJSONHasErrors={setIsCarDetailsJSONHasErrors}
          />
        );
      case "2":
        return (
          <WarrantyInstalationData
            isTechnicalDetailsJSONHasErrors={isTechnicalDetailsJSONHasErrors}
            setIsTechnicalDetailsJSONHasErrors={
              setIsTechnicalDetailsJSONHasErrors
            }
            isAdditionalInstallationDetailsJSONHasErrors={
              isAdditionalInstallationDetailsJSONHasErrors
            }
            setIsAdditionalInstallationDetailsJSONHasErrors={
              setIsAdditionalInstallationDetailsJSONHasErrors
            }
            isDescriptionJSONHasErrors={isDescriptionJSONHasErrors}
            setIsDescriptionJSONHasErrors={setIsDescriptionJSONHasErrors}
            editMode={editMode}
            setAdditionalInstallationDetailsJSON={
              setAdditionalInstallationDetailsJSON
            }
            setTechnicalDetailsJSON={setTechnicalDetailsJSON}
            setDescriptionJSON={setDescriptionJSON}
          />
        );
      case "3":
        return (
          <WarrantyStaticContentContainer
            imageListSection={true}
            staticContent={I18n.t("warranty.sectionIII.mainText")}
          />
        );
      case "4":
        return (
          <WarrantyStaticContentContainer
            staticContent={I18n.t("warranty.sectionIV.mainText")}
          />
        );
      case "5":
        return (
          <WarrantyStaticContentContainer
            staticContent={I18n.t("warranty.sectionV.mainText")}
          />
        );
      case "6":
        return (
          <WarrantyStaticContentContainer
            staticContent={I18n.t("warranty.sectionVI.mainText")}
            setTermsAcceptedJSON = {setTermsAcceptedJSON}
            termsAcceptedJSON = {termsAcceptedJSON}
            editMode={editMode}
            termsField
          />
        );
      case "7":
        return <PeriodicInspections />;
      case "8":
        return editMode ? (
          <WarrantyNotesEdit
            notesJSON={notesJSON}
            setNotesJSON={setNotesJSON}
            isNotesJSONHasErrors={isNotesJSONHasErrors}
            setIsNotesJSONHasErrors={setIsNotesJSONHasErrors}
          />
        ) : (
          <WarrantyNotes />
        );
    }
  };

  const onStepButtonClick = (currentActiveTab) => {
    if (editMode) {
      if (activeTab.id === "1") {
        setIsCarDetailsJSONHasErrors(
          carDetailsValidator(carDetailsJSON).hasErrors ||
            warrantyNumberValidator(warrantyNumberJSON).hasErrors
        );
        carDetailsValidator(carDetailsJSON).hasErrors ||
        warrantyNumberValidator(warrantyNumberJSON).hasErrors
          ? openErrorWarrantyBookModal()
          : setActiveTab(currentActiveTab);
      }
      if (activeTab.id === "2") {
        setIsTechnicalDetailsJSONHasErrors(
          technicalDetailsValidator(technicalDetailsJSON).hasErrors
        );
        setIsAdditionalInstallationDetailsJSONHasErrors(
          additionalAssemblyValidator(additionalInstallationDetailsJSON)
            .hasErrors
        );
        setIsDescriptionJSONHasErrors(
          descriptionValidator(descriptionJSON).hasErrors
        );
        technicalDetailsValidator(technicalDetailsJSON).hasErrors ||
        additionalAssemblyValidator(additionalInstallationDetailsJSON)
          .hasErrors ||
        descriptionValidator(descriptionJSON).hasErrors
          ? openErrorWarrantyBookModal()
          : setActiveTab(currentActiveTab);
      }
      if (
        activeTab.id === "3" ||
        activeTab.id === "4" ||
        activeTab.id === "5" ||
        activeTab.id === "6"
      ) {
        setActiveTab(currentActiveTab);
      }
      if (activeTab.id === "7") {
        tabsWithError.some((t) => t === activeTab.id)
          ? setActiveTab(activeTab)
          : setActiveTab(currentActiveTab);
      }
      if (activeTab.id === "8") {
        setIsNotesJSONHasErrors(notesValidator(notesJSON).hasErrors);
        notesValidator(notesJSON).hasErrors
          ? openErrorWarrantyBookModal()
          : setActiveTab(currentActiveTab);
      }
    } else {
      setActiveTab(currentActiveTab);
    }
  };

  const setTabColor = (tab) => {
    let classStyle;

    if (editMode) {
      if (isActive(tab.id)) {
        classStyle = styles.BtnTabActive;
      } else {
        if (tabsWithError.length === 0) {
          classStyle = styles.BtnTab;
        } else {
          if (
            tabsWithError.some((t) => t === tab.id) &&
            tabsWithError.some((t) => t === activeTab.id)
          ) {
            classStyle = styles.BtnTab;
          } else classStyle = styles.BtnDisabledTab;
        }
      }
    } else {
      if (isActive(tab.id)) {
        classStyle = styles.BtnTabActive;
      } else classStyle = styles.BtnTab;
    }
    return classStyle;
  };
  return (
    <Loadable active={isWarrantyFetching} spinner>
      <div className={styles.Container}>
        {tabs.map((tab, idx) => (
          <div
            className={setTabColor(tab)}
            onClick={() => onStepButtonClick(tab)}
            key={idx}
          >
            {tabsWithError.some((t) => t === tab.id) ? (
              <>
                <span
                  id="additional-info-icon"
                  data-tip=""
                  data-for="additional-info-icon"
                  style={{
                    position: "absolute",
                    top: "-15px",
                    right: "-22px",
                  }}
                >
                  <i
                    className="fa fa-info-circle fa-2x mr-1"
                    style={{ color: "#CC0000" }}
                  />
                </span>
                <UncontrolledTooltip
                  placement="top"
                  target="additional-info-icon"
                  innerClassName="custom-tooltip"
                >
                  <Translate value="warranty.hasErrors" />
                </UncontrolledTooltip>
              </>
            ) : null}
            <p className={styles.TabTxt}>{I18n.t(tab.title).toUpperCase()}</p>
            {isActive(tab.id) && <div className={styles.Line} />}
          </div>
        ))}
      </div>
      <ContentWrapper title={activeTab.title}>
          {renderSubsection()}
        <div className={styles.WarrantyButtons}>
          {!editMode && userService.isWorkshop() && (
            <Button
              className="main-btn"
              style={{ width: "14.7rem" }}
              onClick={() => {
                localStorage.setItem('warrantyBook', JSON.stringify(initializedBook))
                setEditMode(true);
              }}
            >
              <Translate value={"warranty.edit"} />
            </Button>
          )}
          {activeTab.id !== "1" && (
            <Button
              className="main-btn"
              style={{ width: "14.7rem" }}
              disabled={tabsWithError.some((t) => t === activeTab.id)}
              onClick={() =>
                onStepButtonClick(
                  activeTab.id === "1"
                    ? tabs[parseInt(activeTab.id) - 1]
                    : tabs[parseInt(activeTab.id) - 2]
                )
              }
            >
              <Translate value={"warranty.back"} />
            </Button>
          )}
          {activeTab.id !== "8" && (
            <Button
              className="main-btn"
              style={{ width: "14.7rem" }}
              disabled={tabsWithError.some((t) => t === activeTab.id)}
              onClick={() =>
                onStepButtonClick(
                  activeTab.id === "8"
                    ? tabs[parseInt(activeTab.id) - 1]
                    : tabs[parseInt(activeTab.id)]
                )
              }
            >
              <Translate value={"warranty.forward"} />
            </Button>
          )}
        </div>
        <Prompt when={editMode} message={handleBlockedNavigation} />
      </ContentWrapper>
      {editMode && (
        <div className={styles.WarrantyButtons} style={{ marginTop: "3rem" }}>
          <Button
            className="main-btn"
            style={{ width: "14.7rem" }}
            disabled={tabsWithError.length !== 0}
            onClick={() => {
              setIsTechnicalDetailsJSONHasErrors(
                technicalDetailsValidator(technicalDetailsJSON).hasErrors
              );
              setIsAdditionalInstallationDetailsJSONHasErrors(
                additionalAssemblyValidator(additionalInstallationDetailsJSON)
                  .hasErrors
              );
              setIsDescriptionJSONHasErrors(
                descriptionValidator(descriptionJSON).hasErrors
              );
              setIsNotesJSONHasErrors(notesValidator(notesJSON).hasErrors);
              setIsCarDetailsJSONHasErrors(
                carDetailsValidator(carDetailsJSON).hasErrors ||
                  warrantyNumberValidator(warrantyNumberJSON).hasErrors
              );
              if (
                !technicalDetailsValidator(technicalDetailsJSON).hasErrors &&
                !additionalAssemblyValidator(additionalInstallationDetailsJSON)
                  .hasErrors &&
                !descriptionValidator(descriptionJSON).hasErrors &&
                !notesValidator(notesJSON).hasErrors &&
                !carDetailsValidator(carDetailsJSON).hasErrors &&
                !warrantyNumberValidator(warrantyNumberJSON).hasErrors
              ) {
                openEditApproveChangesWarrantyBookModal();
              } else openErrorWarrantyBookModal()
            }}
          >
            <Translate value={"warranty.confirm"} />
          </Button>
          <Button
            className="main-btn"
            style={{ width: "14.7rem" }}
            onClick={() => {
              cancelEditionWarrantyBookModal()
              if(initializedBook){
                setAdditionalInstallationDetailsJSON(initializedBook.additionalInstallationDetails);
                setCarDetailsJSON(initializedBook.carDetails)
                setDescriptionJSON(initializedBook.description);
                setTechnicalDetailsJSON(initializedBook.technicalDetails);
                setWarrantyNumberJSON(initializedBook.warrantyNumber);
                setNotesJSON(initializedBook.notes);
              }
            }}
          >
            <Translate value={"warranty.cancel"} />
          </Button>
        </div>
      )}
    </Loadable>
  );
};

export default WarrantyEdit;
