/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';

export const WorkshopStatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  BLOCKED: 'BLOCKED',
  DELETED: 'DELETED',
};

export const statusOptions = () => {
  return [
    {
      label: I18n.t('workshopStatuses.active'),
      value: WorkshopStatusEnum.ACTIVE,
    },
    {
      label: I18n.t('workshopStatuses.inactive'),
      value: WorkshopStatusEnum.INACTIVE,
    },
    {
      label: I18n.t('workshopStatuses.blocked'),
      value: WorkshopStatusEnum.BLOCKED,
    },
    {
      label: I18n.t('workshopStatuses.deleted'),
      value: WorkshopStatusEnum.DELETED,
    },
  ];
};

export const workshopTypeOptions = () => {
  return [
    {label: I18n.t('workshops.partner'), value: 'PARTNER'},
    {label: I18n.t('workshops.non_partner'), value: 'NON_PARTNER'},
  ];
};

export const CreateWorkshopFormMode = {
  ADMIN: 'ADMIN',
  SIGN_UP: 'SIGN_UP',
};
