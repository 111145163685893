/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const fileUtils = {
  getFileNameFromResponse,
  openPdf,
};

function getFileNameFromResponse(response) {
  let filename = '';
  const disposition = response.request.getResponseHeader('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

function openPdf(pdfResponse) {
  if (!!window.navigator.msSaveOrOpenBlob) {
    const fileName = getFileNameFromResponse(pdfResponse);
    const newBlob = new Blob([pdfResponse.data], {type: 'application/pdf'});
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
  } else {
    window.open(URL.createObjectURL(pdfResponse.data));
  }
}
