/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import { axiosService } from './axios.service';
import {defaultClientId, defaultClientSecret} from '../../environment';

export const settingsService = {
  findAllSystemSettings,
  updateSystemSettings,
  findAllUserSettingsByUserId,
  updateUserSettingsByUserId,
  getWorkshopLoyaltyProgramEditor,
  updateWorkshopLoyaltyProgramEditor,
  getWorkshopLoyaltyProgramEditorAsWorkshop
};

const WORKSHOP_LOYALTY_PROGRAM_URL = '/settings/editor-settings';

function findAllSystemSettings() {
  return axiosService.get('/settings/system-settings').then(response => {
    return response;
  });
}

function updateSystemSettings(toUpdate) {
  return axiosService
    .put('/settings/system-settings', toUpdate)
    .then(response => {
      return response;
    });
}

function findAllUserSettingsByUserId(userId) {
  return axiosService
    .get(`/settings/user-settings/${userId}`)
    .then(response => {
      return response;
    });
}

function updateUserSettingsByUserId(userId, toUpdate) {
  return axiosService
    .put(`/settings/user-settings/${userId}`, toUpdate)
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramEditor() {
  return axiosService
    .get(`${WORKSHOP_LOYALTY_PROGRAM_URL}`)
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramEditorAsWorkshop() {
  const clientAuth = btoa(defaultClientId + ':' + defaultClientSecret);

  return axiosService
    .get(`${WORKSHOP_LOYALTY_PROGRAM_URL}/LOYALTY_PROGRAM_EDITOR`,{headers: {'client-auth': clientAuth}})
    .then(response => {
      return response;
    });
}

function updateWorkshopLoyaltyProgramEditor(value) {
  return axiosService
    .put(`${WORKSHOP_LOYALTY_PROGRAM_URL}`,
      JSON.stringify(value), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      return response;
    });
}
