import {warrantyService} from 'shared/services/warranty.service';
import {warrantyConstants} from 'shared/constants/warranty.constants';
import {messageActions} from 'shared/actions/message.actions';
import {history} from 'shared/utils/history';

export const warrantyActions = {
  getAllWarranties,
  initNewWarrantyBook,
  getWarrantyBookById,
  editWarrantyBook,
  getPeriodicInspectionsByWarrantyId,
  deleteInitializedWarranty,
  getWarrantyBookByVIN,
  fillPeriodicInspection,
};

function getAllWarranties() {
  return dispatch => {
    dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
    warrantyService.getAllWarranties().then(
      response => {
        dispatch({
          type: warrantyConstants.GET_WARRANTIES,
          payload: response.data.filter(warranty => warranty.status !== 'NEW'),
        });
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}

function initNewWarrantyBook(vin, linkProps) {
  const partCategories = {
    DRIVER: 'lpgDriver',
    BG_SWITCH: 'lpgBgSwitch',
    REDUCER: 'lpgReducer',
    INJECTOR: 'lpgInjectors',
    TANK: 'lpgTank',
  };
  return (dispatch) => {
    dispatch({ type: warrantyConstants.WARRANTY_REQUEST_START });
    warrantyService.initNewWarrantyBook(vin).then(
      response => {
        let warrantyBook;
        if (linkProps) {
          const carDetails = {
            brand: linkProps.carBrand,
            engineCapacityCm3: linkProps.carEngineCapacityCm3,
            enginePowerKw: linkProps.carEnginePowerKw,
            gasInstallationDate: response.data.carDetails.gasInstallationDate,
            mileage: response.data.carDetails.mileage,
            mileageUnit: response.data.carDetails.mileageUnit,
            model: linkProps.carModel,
            productionYear: linkProps.carProductionYear,
            registrationNumber: response.data.carDetails.registrationNumber,
          };
          const findPart = (part) =>{
            return linkProps.lpgServiceCategoryParams.find((param) => param.id === part).name
          }
          const technicalDetails = {
            address: response.data.technicalDetails.address,
            bgSwitch: findPart(partCategories.BG_SWITCH),
            date: response.data.technicalDetails.date,
            gasLevelIndicatorDate: response.data.technicalDetails.gasLevelIndicatorDate,
            gasLevelIndicatorType:  response.data.technicalDetails.gasLevelIndicatorType,
            injectionRailName: findPart(partCategories.INJECTOR),
            injectionRailSerialNumbers:  response.data.technicalDetails.injectionRailSerialNumbers,
            nip:  response.data.technicalDetails.nip,
            phoneNumber:  response.data.technicalDetails.phoneNumber,
            pressureTemperatureSensorBarcode:  response.data.technicalDetails.pressureTemperatureSensorBarcode,
            pressureTemperatureSensorDate:  response.data.technicalDetails.pressureTemperatureSensorDate,
            pressureTemperatureSensorName:  response.data.technicalDetails.pressureTemperatureSensorName,
            reducerName: findPart(partCategories.REDUCER),
            reducerSerialNumbers:  response.data.technicalDetails.reducerSerialNumbers,
            regon:  response.data.technicalDetails.regon,
            stagControlSystemName: findPart(partCategories.DRIVER),
            stagControlSystemSerialNumber:  response.data.technicalDetails.stagControlSystemSerialNumber,
            tankName: findPart(partCategories.TANK),
            tankSerialNumber:  response.data.technicalDetails.tankSerialNumber,
            workshopName:  response.data.technicalDetails.workshopName,
            workshopWorker:  response.data.technicalDetails.workshopWorker,
            workshopWorkerEmail:  response.data.technicalDetails.workshopWorkerEmail,
          };
          warrantyBook = {
            id: response.data.id,
            vin: response.data.vin,
            status: response.data.status,
            creatorUserId: response.data.creatorUserId,
            createdDate: response.data.createdDate,
            additionalInstallationDetails: response.data.additionalInstallationDetails,
            technicalDetails: technicalDetails,
            warrantyNumber: response.data.warrantyNumber,
            carDetails: carDetails,
            description: response.data.description,
            notes: response.data.notes,
          };
        }
        dispatch({
          type: warrantyConstants.INITIALIZE_WARRANTY,
          payload: linkProps ? warrantyBook : response.data,
        });
        localStorage.setItem('warrantyBook', JSON.stringify(linkProps ? warrantyBook : response.data));
        localStorage.setItem('editMode', true);
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
        history.push(`/workshop/warranty/${response.data.id}`);
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}

function getWarrantyBookById(id) {
  return dispatch => {
    dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
    warrantyService.getWarrantyBookById(id).then(
      response => {
        dispatch({
          type: warrantyConstants.INITIALIZE_WARRANTY,
          payload: response.data,
        });
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}

function getWarrantyBookByVIN(vin) {
  return (dispatch) => {
    dispatch({ type: warrantyConstants.WARRANTY_REQUEST_START });
    warrantyService.getWarrantyBookByVIN(vin).then(
      (response) => {
        dispatch({
          type: warrantyConstants.PUSH_WARRANTY_TO_THE_USER_LIST,
          payload: response.data,
        });
        dispatch({ type: warrantyConstants.WARRANTY_REQUEST_END });
      },
      (error) => {
        dispatch({ type: warrantyConstants.WARRANTY_REQUEST_END });
      }
    );
  };
}

function editWarrantyBook(id, data, setEditMode = null) {
  return dispatch => {
    dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
    warrantyService.editWarrantyBook(id, data).then(
      (response) => {
        if(response.data.status !== 'ACTIVE'){
          dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
          warrantyService.activateWarrantyBook(id).then(
            response => {
              dispatch({
                type: warrantyConstants.EDIT_WARRANTY,
                payload: response.data,
              });
              dispatch(messageActions.successMessage('warranty.successMessage'));
              dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
              localStorage.removeItem("warrantyBook");
              localStorage.removeItem("editMode");
              if(setEditMode){
                setEditMode(false)
              }
            },
            error => {
              dispatch(messageActions.errorMessage(error.message));
              dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
            }
          );
        } else {
        dispatch({
          type: warrantyConstants.EDIT_WARRANTY,
          payload: response.data,
        });
        dispatch(messageActions.successMessage('warranty.successMessage'));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
        localStorage.removeItem("warrantyBook");
        localStorage.removeItem("editMode");
        if(setEditMode){
          setEditMode(false)
        }
      }
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}

function deleteInitializedWarranty() {
  return dispatch => {
    dispatch({type: warrantyConstants.DELETE_INITIALIZED_WARRANTY});
  };
}

function getPeriodicInspectionsByWarrantyId(id) {
  return dispatch => {
    dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
    warrantyService.getPeriodicInspectionsByWarrantyId(id).then(
      response => {
        dispatch({
          type: warrantyConstants.GET_PERIODIC_INSPECTIONS,
          payload: response.data,
        });
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}

function fillPeriodicInspection(inspection) {
  return dispatch => {
    dispatch({type: warrantyConstants.FILL_INSPECTION, payload: inspection});
    dispatch({type: warrantyConstants.WARRANTY_REQUEST_START});
    warrantyService.updatePeriodicInspection(inspection.id, inspection).then(
      res => {
        console.log(res);
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage(error.message));
        dispatch({type: warrantyConstants.WARRANTY_REQUEST_END});
      }
    );
  };
}
