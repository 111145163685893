/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {defaultClientId, defaultClientSecret} from '../../environment';
import {userConstants} from '../constants/user.constants';
import {axiosService} from './axios.service';
import ValidationMessageEnum, {getOrEmptyArray} from '../utils/utils';
import {jwt} from '../utils/jwt';
import * as qs from 'qs';
import {history} from '../utils/history';
import btoa from 'btoa';

export const userService = {
  login,
  logout,
  loginSocial,
  getUserInfo,
  exist,
  signup,
  sendLinkToResetPassword,
  resetPassword,
  getUserDataById,
  getUserId,
  updateProfile,
  isAdmin,
  isUser,
  isThinUser,
  findAllByParams,
  disable,
  enable,
  createAdmin,
  activate,
  isRole,
  updateAvatar,
  isWorkshop,
  changePassword,
  getRoleName,
  getPathWithRoleName,
  isDistributor,
  splitRoleNameAndToLowerCase,
  getUserCountry,
  hasAuthority,
  updateFirstTimeLoginStatus
};

function login(username, password) {
  const body =
    `grant_type=password` +
    `&username=${username}` +
    `&password=${password}` +
    `&client_id=${defaultClientId}` +
    `&client_secret=${defaultClientSecret}`;

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axiosService.post('/user/oauth/token', body, config).then(response => {
    localStorage.setItem(
      userConstants.ACCESS_TOKEN_KEY,
      response.data.access_token
    );
    localStorage.setItem(
      userConstants.REFRESH_TOKEN_KEY,
      response.data.refresh_token
    );
    localStorage.removeItem('warrantyBook');
    localStorage.removeItem('editMode');
    return response;
  });
}

function logout() {
  localStorage.removeItem(userConstants.ACCESS_TOKEN_KEY);
  localStorage.removeItem(userConstants.REFRESH_TOKEN_KEY);
  localStorage.removeItem('warrantyBook');
  localStorage.removeItem('editMode');
  history.push('/home');
}

function loginSocial(providerId, accessToken) {
  return axiosService
    .post('/user/oauth/tokenSocial', {
      providerId: providerId,
      accessToken: accessToken,
    })
    .then(response => {
      localStorage.setItem(
        userConstants.ACCESS_TOKEN_KEY,
        response.data.access_token
      );
      localStorage.setItem(
        userConstants.REFRESH_TOKEN_KEY,
        response.data.refresh_token
      );
      return response;
    })
    .then(response => {
      return Promise.all([getUserDataById(response.data.user_id), response]);
    })
    .then(([userData, response]) => {
      if (userData.data.enabled === false) {
        localStorage.removeItem(userConstants.ACCESS_TOKEN_KEY);
        localStorage.removeItem(userConstants.REFRESH_TOKEN_KEY);
        response.enabled = false;
        return Promise.reject(response);
      } else {
        return Promise.resolve(response);
      }
    });
}

function getUserInfo() {
  let accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN_KEY);
  return accessToken ? jwt.decode(accessToken) : {};
}

function getUserId() {
  let accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN_KEY);
  return accessToken ? jwt.decode(accessToken).user_id : null;
}

function signup(values) {
  const clientAuth = btoa(defaultClientId + ':' + defaultClientSecret);
  return axiosService
    .post('/user/users/create', values, {
      headers: {'client-auth': clientAuth},
    })
    .then(response => {
      return response;
    });
}

function exist(values) {
  const config = {
    params: {
      email: values.email,
    },
  };
  return axiosService.get('/user/users/exist', config).then(response => {
    if (response.data)
      return Promise.resolve({
        email: {key: ValidationMessageEnum.EMAIL_EXIST, params: {}},
      });
  });
}

function sendLinkToResetPassword(data) {
  return axiosService
    .post('/user/users/forgotPassword', data)
    .then(response => {
      return response;
    });
}

function resetPassword(data) {
  return axiosService.post('/user/users/resetPassword', data).then(response => {
    return response;
  });
}

function getUserDataById(userId) {
  return axiosService.get('/user/users/' + userId).then(response => {
    return response;
  });
}

function updateProfile(userId, data) {
  return axiosService.put('/user/users/' + userId, data).then(response => {
    return response;
  });
}

function updateFirstTimeLoginStatus(userId) {
  return axiosService.put('/user/users/first-login/' + userId).then(response => {
    return response;
  });
}

function isRole(roleToCheck) {
  return getOrEmptyArray(getUserInfo().authorities).indexOf(roleToCheck) > -1;
}

function isAdmin() {
  return isRole(userConstants.ROLE_ADMIN);
}

function isUser() {
  return (
    isRole(userConstants.ROLE_USER) || isRole(userConstants.ROLE_THIN_USER)
  );
}

function isThinUser() {
  return isRole(userConstants.ROLE_THIN_USER);
}

function isWorkshop() {
  return isRole(userConstants.ROLE_WORKSHOP);
}

function isDistributor() {
  return isRole(userConstants.ROLE_DISTRIBUTOR);
}

function findAllByParams(params) {
  const paramsObj = params
    ? {
        params: {roleNames: params.roleNames},
        paramsSerializer: function (params) {
          return qs.stringify(params, {arrayFormat: 'repeat'});
        },
      }
    : {};

  return axiosService.get('/user/users', paramsObj).then(response => {
    return response;
  });
}

function disable(userId, languageInfo) {
  return axiosService
    .post('/user/users/' + userId + '/disable', languageInfo)
    .then(response => {
      return response;
    });
}

function enable(userId, languageInfo) {
  return axiosService
    .post('/user/users/' + userId + '/enable', languageInfo)
    .then(response => {
      return response;
    });
}

function createAdmin(values) {
  const clientAuth = btoa(defaultClientId + ':' + defaultClientSecret);
  return axiosService
    .post('/user/users/createAdmin', values, {
      headers: {'client-auth': clientAuth},
    })
    .then(response => {
      return response;
    });
}

function activate(activationToken) {
  return axiosService
    .post('/user/users/activate', {activationToken: activationToken})
    .then(response => {
      return response;
    });
}

function updateAvatar(userId, data) {
  return axiosService
    .put(`/user/users/avatar/${userId}`, data)
    .then(response => {
      return response;
    });
}

function changePassword(userId, data) {
  return axiosService
    .put(`/user/users/password/${userId}`, data)
    .then(response => {
      return response;
    });
}

function getRoleName() {
  if (userService.isAdmin() || userService.isUser())
    return splitRoleNameAndToLowerCase(userConstants.ROLE_USER);
  else if (userService.isWorkshop())
    return splitRoleNameAndToLowerCase(userConstants.ROLE_WORKSHOP);
  else if (userService.isDistributor())
    return splitRoleNameAndToLowerCase(userConstants.ROLE_DISTRIBUTOR);
}

function splitRoleNameAndToLowerCase(role) {
  return role.split('_')[1].toLowerCase();
}

function getPathWithRoleName() {
  if (userService.isAdmin())
    return `/${splitRoleNameAndToLowerCase(userConstants.ROLE_ADMIN)}`;
  else if (userService.isWorkshop())
    return `/${splitRoleNameAndToLowerCase(userConstants.ROLE_WORKSHOP)}`;
  else if (userService.isUser())
    return `/${splitRoleNameAndToLowerCase(userConstants.ROLE_USER)}`;
  else if (userService.isDistributor())
    return `/${splitRoleNameAndToLowerCase(userConstants.ROLE_DISTRIBUTOR)}`;
}

function hasAuthority(authority) {
  return getUserInfo().authorities.indexOf(authority) > -1;
}

function getUserCountry() {
  return getUserInfo().country;
}
