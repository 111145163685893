/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const partConstants = {
  PART_REQUEST_START: 'PART_REQUEST_START',
  PART_REQUEST_END: 'PART_REQUEST_END',

  LOAD_PARTS: 'LOAD_PARTS',
  LOAD_PART: 'LOAD_PART',
  CLEAR_PART_DATA: 'CLEAR_PART_DATA',

  LOAD_PART_CATEGORIES: 'LOAD_ALL_PART_CATEGORIES',

  REFRESH_PART_DATA: 'REFRESH_PART_DATA',
};
