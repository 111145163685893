/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Collapse} from 'reactstrap';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {userActions} from 'shared/actions/user.actions';
import CountryField from 'shared/components/form/country-field/CountryField';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import StateField from 'shared/components/form/state-field/StateField';
import renderField from 'shared/components/form/text-field/RenderField';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {userService} from 'shared/services/user.service';
import {validate} from './validators';

class UserProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
      isOpenCollapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {formValues, initialValues, countries, form} = this.props;
    if (this.props.shouldInitializePhonePrefix(prevProps, formValues))
      this.props.initializePhonePrefixByCountry(form, formValues.country);
    if (
      !prevProps.countries &&
      countries &&
      initialValues &&
      initialValues.country &&
      !initialValues.phoneNumber.phoneNumber
    )
      this.props.initializePhonePrefixByCountry(form, initialValues.country);
  }

  toggle() {
    this.setState({isOpenCollapse: !this.state.isOpenCollapse});
  }

  renderBlockOrUnblockBtn() {
    const {canUpdate, dispatch} = this.props;
    const languageInfo = {
      languageVersion: 'WEB',
      languageSymbol: this.props.i18n.locale,
    };
    if (this.props.isAdminForm)
      return (
        <span>
          {this.props.data.enabled ? (
            <Button
              id='disable-btn'
              disabled={!canUpdate}
              className='main-btn'
              onClick={e =>
                dispatch(userActions.disable(this.props.data.id, languageInfo))
              }>
              <Translate value={'users.disable'} />
            </Button>
          ) : (
            <Button
              id='enable-btn'
              disabled={!canUpdate}
              className='main-btn'
              onClick={e =>
                dispatch(userActions.enable(this.props.data.id, languageInfo))
              }>
              <Translate value={'users.enable'} />
            </Button>
          )}
        </span>
      );
  }

  renderPasswordForm() {
    const {canUpdate, data, collapsiblePasswordForm} = this.props;
    const form = (
      <Fragment>
        {!this.props.isAdminForm &&
          !userService.isAdmin() &&
          !data.socialUserOnly && (
            <Field
              name='currentPassword'
              type='password'
              component={renderField}
              inputDisabled={!canUpdate}
              label={I18n.t('userProfileForm.currentPassword')}
              icon='fa fa-unlock-alt'
            />
          )}
        <Field
          name='newPassword'
          type='password'
          component={renderField}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.newPassword')}
          icon='fa fa-unlock-alt'
        />
        <Field
          name='confirmPassword'
          type='password'
          component={renderField}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.repeatPasswordLabel')}
          icon='fa fa-unlock-alt'
        />
      </Fragment>
    );

    return collapsiblePasswordForm ? (
      <Collapse isOpen={this.state.isOpenCollapse}>{form}</Collapse>
    ) : (
      form
    );
  }

  render() {
    const {
      handleSubmit,
      invalid,
      handleCancel,
      canUpdate,
      data,
      collapsiblePasswordForm,
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name='email'
          type='text'
          component={renderField}
          inputDisabled={true}
          label={I18n.t('userProfileForm.emailLabel')}
          icon='fa fa-envelope'
        />
        <Field
          name='firstName'
          type='text'
          component={renderField}
          required={true}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.firstNameLabel')}
          icon='fa fa-user'
        />
        <Field
          name='lastName'
          type='text'
          component={renderField}
          required={true}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.lastNameLabel')}
          icon='fa fa-user'
        />
        <CountryField disabled={!canUpdate} />
        <StateField formName='userProfileForm' disabled={!canUpdate} />
        <Field
          name='city'
          type='text'
          component={renderField}
          required={true}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.cityLabel')}
          icon='fa fa-building-o'
        />
        <Field
          name='phoneNumber'
          component={PhoneNumber}
          required={true}
          inputDisabled={!canUpdate}
          label={I18n.t('userProfileForm.phoneNumberLabel')}
          format={formatPhoneNumberField}
        />
        {collapsiblePasswordForm && (
          <div className='text-sm-right text-center mt-3'>
            <Button
              id='user-pass-change-btn'
              onClick={this.toggle}
              disabled={!canUpdate}
              className='main-btn'>
              <Translate value={'userProfileForm.changePassword'} />
            </Button>
          </div>
        )}
        {this.renderPasswordForm()}
        <div className='text-sm-right text-center mt-3'>
          <Button
            type='submit'
            id='save-user-edit-btn'
            className={
              window.location.href.indexOf('user/profile') >= 0
                ? 'main-btn'
                : !handleCancel && 'main-btn'
            }
            disabled={invalid || !canUpdate}>
            <Translate value={'userProfileForm.saveBtn'} />
          </Button>
          <Button
            id='cancel-user-edit-btn'
            className='main-btn'
            onClick={
              window.location.href.indexOf('user/profile') >= 0
                ? handleCancel
                : () =>
                    this.props.dispatch({
                      type: updateDataSideBarConstants.HIDE,
                    })
            }>
            <Translate value='userProfileForm.cancelBtn' />
          </Button>
          {this.props.data && this.renderBlockOrUnblockBtn()}
        </div>
      </form>
    );
  }
}

UserProfileForm.propTypes = {
  canUpdate: PropTypes.bool,
  collapsiblePasswordForm: PropTypes.bool,
};

UserProfileForm.defaultProps = {
  canUpdate: true,
  collapsiblePasswordForm: true,
};

UserProfileForm = reduxForm({
  form: 'userProfileForm',
  validate,
  enableReinitialize: true,
})(UserProfileForm);

function mapStateToProps(state) {
  const {country, i18n, user} = state;
  const {data} = user;
  const {countries} = country;
  const formValues = getFormValues('userProfileForm')(state);
  return {initialValues: data, i18n, countries, formValues, data};
}

//export for tests
export const UserProfileFormConnectedWithRedux =
  connect(mapStateToProps)(UserProfileForm);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(UserProfileForm)
);
