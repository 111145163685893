/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const carService = {
  findFuelTypes,
  findCarBrands,
  findCarsByUserId,
  carExistsForUser,
  findCarById,
  createCar,
  updateCar,
  deleteCar,
};

function findFuelTypes() {
  return axiosService.get('/car/cars/fuelTypes').then(response => {
    return response;
  });
}

function findCarBrands() {
  return axiosService.get('/car/car-brands').then(response => {
    return response;
  });
}

function findCarsByUserId(userId) {
  return axiosService.get(`/car/cars/${userId}`).then(response => {
    return response;
  });
}

function carExistsForUser(carName, userId, excludedCarId) {
  const opt = excludedCarId ? `&excludedCarId=${excludedCarId}` : '';
  return axiosService
    .get(`/car/cars/exist?name=${carName}&userId=${userId}${opt}`)
    .then(response => {
      return response;
    });
}

function findCarById(carId) {
  return axiosService.get(`/car/cars/id/${carId}`).then(response => {
    return response;
  });
}

function createCar(values) {
  return axiosService.post('/car/cars/create', values).then(response => {
    return response;
  });
}

function updateCar(carId, values) {
  return axiosService.put(`/car/cars/${carId}`, values).then(response => {
    return response;
  });
}

function deleteCar(carId) {
  return axiosService.delete(`/car/cars/${carId}`).then(response => {
    return response;
  });
}
