/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {orderActions} from 'shared/actions/order.actions';
import {RenderDetailsCore} from '../RenderDetailsCore';

class RenderOtherServices extends RenderDetailsCore {
  constructor(props) {
    super(props);
    this.state = {toggle: false};
  }

  componentDidUpdate() {
    const {valuationInquiryId, valuationInquiry} = this.props;
    if (!valuationInquiry && valuationInquiryId)
      this.props.findValuationInquiryById(valuationInquiryId);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  renderOtherServices() {
    const {valuationInquiry} = this.props;
    return (
      <div className='details-border mt-1'>
        {super.renderHeader(I18n.t('valuationDetails.otherServices'), true)}
        <Collapse isOpen={this.state.collapse}>
          <div className='row valuation-inquiry-details-content px-3'>
            <div className='col-12 '>{valuationInquiry.otherServices}</div>
          </div>
        </Collapse>
      </div>
    );
  }

  render() {
    const {valuationInquiry} = this.props;
    return (
      <div>
        {valuationInquiry &&
          valuationInquiry.otherServices &&
          this.renderOtherServices()}
      </div>
    );
  }
}

RenderOtherServices.propsTypes = {
  valuationInquiry: PropTypes.object,
};

const mapStateToProps = ({order}) => {
  const {valuationInquiry} = order;
  return {valuationInquiry};
};

const mapDispatchToProps = {
  findValuationInquiryById: orderActions.findValuationInquiryById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderOtherServices);
