/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Checkbox} from 'primereact/checkbox';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {messageActions} from 'shared/actions/message.actions';
import {serviceActions} from 'shared/actions/service.actions';
import {formatPhoneNumber} from 'shared/components/form/formatter/PhoneNumberFormatter';
import {loyaltyProgramConstants} from 'shared/constants/loyalty-program.constants';
import {
  operationType,
  serviceConstants,
  serviceStatus,
  serviceType,
} from 'shared/constants/service.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {formatAddress} from 'shared/utils/address-formatter/address-formater.utils';
import {getOrEmptyArray, getOrEmptyObj} from 'shared/utils/utils';
import ServiceEditForm from './service-form/ServiceEditForm';
import './service-list.css';

class ServiceList extends React.Component {
  constructor(props) {
    super(props);
    this.showDetails = this.showDetails.bind(this);
    this.updateService = this.updateService.bind(this);
    this.renderChooseServicesCell = this.renderChooseServicesCell.bind(this);
    this.onChange = this.onChange.bind(this);
    this.notEditableTip = this.notEditableTip.bind(this);
    this.renderLoyaltyProgramPointsCell =
      this.renderLoyaltyProgramPointsCell.bind(this);
    this.renderButtonsSection = this.renderButtonsSection.bind(this);
  }

  showDetails(id) {
    this.props.findServiceById(id);
    const form = (
      <ServiceEditForm
        onSubmit={this.updateService}
        privateServicesRestriction={this.props.privateServicesRestriction}
      />
    );
    setTimeout(
      () =>
        this.props.showUpdateDataSideBar(
          form,
          I18n.t('services.editServiceFormTitle')
        ),
      10
    );
  }

  updateService(values) {
    this.props.updateService(this.props.service.id, values);
    this.props.handleCancel();
  }

  onChange(event) {
    this.props.handleSelect(event);
    this.props.updateSelectedServices(
      event.checked ? operationType.ADD : operationType.SUBTRACT,
      event.value
    );
  }

  renderChooseServicesCell(data) {
    const {selectedServices} = this.props;
    if (data.status === serviceStatus.PENDING) return null;

    return (
      <Checkbox
        value={data}
        onChange={this.onChange}
        checked={selectedServices && this.isChecked(data.id)}
      />
    );
  }

  renderWorkshopInfoCell(data) {
    if (data.serviceType !== serviceType.PRIVATE || !data || !data.workshopInfo)
      return null;

    return (
      <div>
        <div>{data.workshopInfo.workshopName}</div>
        <div>{formatAddress(data.workshopInfo.address)}</div>
        {!!data.workshopInfo.phoneNumber && (
          <div>
            {data.workshopInfo.phoneNumber.phoneNumberPrefix +
              ' ' +
              formatPhoneNumber(data.workshopInfo.phoneNumber.phoneNumber)}
          </div>
        )}
        {!!data.workshopInfo.mobilePhoneNumber && (
          <div>
            {data.workshopInfo.mobilePhoneNumber.phoneNumberPrefix +
              ' ' +
              formatPhoneNumber(
                data.workshopInfo.mobilePhoneNumber.phoneNumber
              )}
          </div>
        )}
      </div>
    );
  }

  shouldRenderDecisionButtons(status) {
    const {isServiceManager} = this.props;
    return isServiceManager && status === serviceStatus.PENDING;
  }

  shouldRenderEditBtn(rowData) {
    const {loggedUserId, editingRestricted} = this.props;
    return (
      !editingRestricted ||
      (rowData.serviceType === serviceType.PRIVATE &&
        rowData.creatorUserId === loggedUserId)
    );
  }

  renderDecisionButtons(rowData) {
    const {isServiceManager} = this.props;
    if (rowData.status !== serviceStatus.PENDING) return null;

    if (isServiceManager)
      return (
        <div className='text-center'>
          <Translate value={'services.decisionNeeded'} />
          <div>
            <Button
              className='btn main-btn m-1 decision-btn'
              onClick={() => this.props.acceptService(rowData.id)}
            >
              <i className='fa fa-check' />
            </Button>
            <Button
              className='btn btn-danger m-1 decision-btn'
              onClick={() => this.props.deleteService(rowData.id)}
            >
              <i className='fa fa-remove' />
            </Button>
          </div>
        </div>
      );
    else return <Translate value={'services.waitingForAcceptance'} />;
  }

  renderEditServiceBtn(rowData) {
    const {isEditable} = this.props;
    if (!this.shouldRenderEditBtn(rowData)) return null;

    return (
      <Button
        id='edit-service-btn'
        onClick={
          isEditable ? () => this.showDetails(rowData.id) : this.notEditableTip
        }
        className='main-btn'
      >
        <Translate value={'services.editServiceFormTitle'} />
      </Button>
    );
  }

  renderEditServiceBtn(rowData) {
    const {isEditable} = this.props;
    if (!this.shouldRenderEditBtn(rowData)) return null;

    return (
      <Button
        id='edit-service-btn'
        onClick={
          isEditable ? () => this.showDetails(rowData.id) : this.notEditableTip
        }
        className='main-btn'
      >
        <Translate value={'services.editServiceFormTitle'} />
      </Button>
    );
  }

  renderButtonsSection(rowData) {
    return (
      <div className='service-button-section'>
        {this.renderDecisionButtons(rowData)}
        {this.renderEditServiceBtn(rowData)}
      </div>
    );
  }

  isChecked(serviceId) {
    return !!this.props.selectedServices.find(service => {
      return service.id === serviceId;
    });
  }

  notEditableTip() {
    this.props.errorMessage('services.serviceNotEditableTip');
  }

  renderLoyaltyProgramPointsCell(data) {
    const {
      selectedServices,
      servicesLoyaltyProgramPoints,
      modifyServicesLoyaltyProgramPoints,
    } = this.props;
    if (data.status === serviceStatus.PENDING) return null;

    const serviceLoyaltyProgramPoints = getOrEmptyArray(
      servicesLoyaltyProgramPoints
    ).find(p => p.serviceId === data.id);
    const loyaltyProgramPoints =
      getOrEmptyObj(serviceLoyaltyProgramPoints).loyaltyProgramPoints || 0;
    const isChecked = selectedServices && this.isChecked(data.id);

    const input = (
      <InputText
        type='text'
        keyfilter='pint'
        disabled={!isChecked}
        value={loyaltyProgramPoints}
        onChange={e =>
          modifyServicesLoyaltyProgramPoints(data.id, e.target.value)
        }
      />
    );

    return serviceLoyaltyProgramPoints && isChecked ? (
      input
    ) : (
      <Translate value='services.loyaltyProgramServiceNotConnected' />
    );
  }

  render() {
    const {services, handleSelect, isServiceManager} = this.props;

    return (
      <DataTable
        value={services}
        className='services-table p-3'
        responsive={true}
        emptyMessage={I18n.t('services.serviceListEmptyPlaceholder')}
      >
        <Column
          field='name'
          header={I18n.t('services.nameLabel')}
          sortable={true}
        />
        <Column
          field='description'
          header={I18n.t('services.descriptionLabel')}
          sortable={true}
        />
        {isServiceManager && (
          <Column
            header={I18n.t('valuationDetails.workshopInfoHeader')}
            body={this.renderWorkshopInfoCell}
            field='workshopInfo'
            sortable={true}
            sortField={'workshopInfo.workshopName'}
          />
        )}
        {!!handleSelect && (
          <Column
            header={I18n.t('services.chooseServiceLabel')}
            body={this.renderChooseServicesCell}
          />
        )}
        {!!handleSelect && (
          <Column
            header={I18n.t('services.loyaltyProgramPointsLabel')}
            body={this.renderLoyaltyProgramPointsCell}
          />
        )}
        <Column
          field='buttonsSection'
          body={this.renderButtonsSection}
          style={{width: '150px'}}
        />
      </DataTable>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  const service = state.service.service;
  const {selectedServices} = state.service;
  const {servicesLoyaltyProgramPoints} = state.loyaltyProgram;
  const loggedUserId = user.data ? user.data.id : '';
  return {
    service,
    selectedServices,
    servicesLoyaltyProgramPoints,
    loggedUserId,
  };
}

const mapDispatchToProps = {
  findServiceById: serviceActions.findServiceById,
  handleCancel: () => ({type: updateDataSideBarConstants.HIDE}),
  updateService: serviceActions.updateService,
  deleteService: serviceActions.deleteService,
  acceptService: serviceActions.acceptService,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  updateSelectedServices: (operationType, service) => ({
    type: serviceConstants.UPDATE_SELECTED_SERVICES,
    payload: {operationType: operationType, service: service},
  }),
  errorMessage: message => messageActions.errorMessage(message),
  modifyServicesLoyaltyProgramPoints: (serviceId, loyaltyProgramPoints) => ({
    type: loyaltyProgramConstants.MODIFY_SERVICES_LOYALTY_PROGRAM_POINTS,
    payload: {serviceId: serviceId, loyaltyProgramPoints: loyaltyProgramPoints},
  }),
};

const connectedServiceList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceList);
export {connectedServiceList as ServiceList};
