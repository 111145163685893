/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {errorColor} from 'shared/utils/style.utils';
import {voivodeships} from 'shared/utils/utils';

class VoivodeshipsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVoivodeship: null,
      checked: false,
    };
  }

  onChangeHandler(e) {
    this.setState(() => {
      return {selectedVoivodeship: e.value};
    });
    this.props.input.onChange(e.value);
  }

  render() {
    const {
      input,
      required,
      labelKey,
      meta: {touched, error},
      disabled,
      inputAlwaysBottom,
    } = this.props;

    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <span
            className='field-label'
            style={touched && error ? {color: errorColor} : null}>
            <Translate value={labelKey} />
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <Dropdown
            value={input.value}
            options={voivodeships}
            disabled={disabled}
            onChange={e => this.onChangeHandler(e)}
            className='dropdown-content'
            placeholder={I18n.t('signup.voivodeshipSearchPlaceholder')}
            filter={true}
            filterPlaceholder={I18n.t('signup.voivodeshipFilterPlaceholder')}
            filterBy='value'
            required
            style={touched && error ? {borderBottomColor: errorColor} : null}
          />
          <div id='error-msg' className='col-12'>
            {touched && error ? (
              <span className='error-info'>
                {I18n.t(error.key, error.params)}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const connectedVoivodeshipsDropdown =
  connect(mapStateToProps)(VoivodeshipsDropdown);
export {connectedVoivodeshipsDropdown as VoivodeshipsDropdown};
