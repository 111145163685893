/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import ValuationInquiryResponseFormSchemaPage1 from './ValuationInquiryResponseFormSchemaPage1';
import {validate} from './validators';

const ValuationInquiryResponseFormPage1 = reduxForm({
  form: 'valuationInquiryResponseForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage1);

function mapStateToProps(state) {
  const {i18n, order, service} = state;
  const {valuationInquiry} = order;
  const {categories} = service;

  return {i18n, valuationInquiry, categories};
}

export default connect(mapStateToProps)(ValuationInquiryResponseFormPage1);
