/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Messages} from 'primereact/messages';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {messageActions} from '../../actions/message.actions';
import {workshopLoyaltyProgramActions} from '../../actions/workshopLoyaltyProgram.actions';
import {loyaltyProgramSystemNotificationsRefreshIntervalMillis} from '../../../environment';

class Message extends React.Component {
  constructor() {
    super();
    this.showMessage = this.showMessage.bind(this);
    this.onRemoveHandler = this.onRemoveHandler.bind(this);
  }

  componentDidMount() {
    this.fetchSystemNotificationsInterval = setInterval(
      () =>
        this.props.getBarNotificationsToShow(
          loyaltyProgramSystemNotificationsRefreshIntervalMillis
        ),
      loyaltyProgramSystemNotificationsRefreshIntervalMillis
    );
  }

  componentWillUnmount() {
    clearInterval(this.fetchSystemNotificationsInterval);
  }

  setMessageOptions(message) {
    let options = {
      life: 10000,
    };
    if (message.shouldUserClose) {
      options = {
        sticky: message.shouldUserClose,
      };
    } else if (
      message.visibilityDurationSeconds &&
      message.visibilityDurationSeconds > 0
    ) {
      options = {
        life: message.visibilityDurationSeconds * 1000,
      };
    }
    return options;
  }

  componentDidUpdate(prevProps) {
    const {message, barNotificationsToShow} = this.props;
    let messageText = message.message;

    const messagesToShow = [];
    if (messageText && messageText !== prevProps.message.message) {
      let options = this.setMessageOptions(message);
      messagesToShow.push({
        severity: message.type,
        summary: I18n.t(messageText),
        ...options,
        detail: '',
      });
    }

    if (
      barNotificationsToShow &&
      barNotificationsToShow.length > 0 &&
      barNotificationsToShow !== prevProps.barNotificationsToShow
    ) {
      barNotificationsToShow.forEach(notification => {
        const isCurrentlyShown = this.messages.state.messages.find(
          item => item.identifier === notification.identifier
        );
        if (!isCurrentlyShown) {
          let options = this.setMessageOptions(notification);
          messagesToShow.push({
            severity: notification.severity,
            summary: notification.systemNotificationText,
            ...options,
            detail: '',
            identifier: notification.identifier,
          });
        }
      });
    }

    if (messagesToShow.length > 0) {
      this.showMultipleMessage(messagesToShow);
    }
  }

  showMultipleMessage(messagesToShow) {
    this.messages.show(messagesToShow);
  }

  showMessage(severity, summary, options) {
    this.messages.show({
      severity: severity,
      summary: I18n.t(summary),
      detail: '',
      ...options,
    });
  }

  onRemoveHandler() {
    this.props.clearMessage();
  }

  render() {
    const marginClass = this.props.noMargin ? '' : 'm-3';

    return (
      <Messages
        ref={messages => {
          this.messages = messages;
        }}
        onRemove={this.onRemoveHandler}
        className={'' + marginClass}
      />
    );
  }
}

Message.propTypes = {
  noMargin: PropTypes.bool,
};

function mapStateToProps(state) {
  const {message, workshopLoyaltyProgram} = state;
  const {barNotificationsToShow} = workshopLoyaltyProgram;
  return {message, barNotificationsToShow};
}

const mapDispatchToProps = {
  getBarNotificationsToShow:
    workshopLoyaltyProgramActions.getBarNotificationsToShow,
  clearMessage: messageActions.clearMessage,
};

const connectedMessage = connect(mapStateToProps, mapDispatchToProps)(Message);
export {connectedMessage as Message};
