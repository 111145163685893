/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';

const RenderCheckbox = ({input, label, disabled}) => (
  <div className='row mt-3'>
    <div className='col-12 my-3'>
      <div
        className='text-justify'
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          outline: 'none',
        }}>
        <input
          type='checkbox'
          {...input}
          checked={input.value === true}
          className='mr-1'
          disabled={disabled}
        />
        <div className='checkbox-label w-100'>{label}</div>
      </div>
    </div>
  </div>
);

export default RenderCheckbox;
