/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {partActions} from 'shared/actions/part.actions';
import {PartForm} from './PartForm';
import {validate} from './validators/validators';

const PartEditForm = reduxForm({
  form: 'partEditForm',
  enableReinitialize: true,
  validate: validate,
})(PartForm);

function mapStateToProps(state) {
  return {
    i18n: state.i18n,
    categories: state.part.categories,
    initialValues: state.part.selected,
    selected: state.part.selected,
  };
}

const mapDispatchToProps = {
  handleDelete: partActions.deletePart,
};
export default connect(mapStateToProps, mapDispatchToProps)(PartEditForm);
