/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {Translate, I18n} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {getOrZero} from 'shared/utils/utils';
import {RenderDetailsCore} from '../RenderDetailsCore';
import moment from 'moment';

export class RenderValuationSummaryDetails extends RenderDetailsCore {
  constructor(props) {
    super(props);

    this.state = {toggle: false};
  }
  render() {
    const {valuation} = this.props;
    const renderedTypeString =
      valuation && valuation.paymentType
        ? valuation.paymentType
            .map(type => I18n.t(`paymentType.${type.toLowerCase()}`))
            .join()
        : '';

    return (
      <div className='details-border mt-1'>
        {super.renderHeader('summary')}
        <Collapse isOpen={this.state.collapse}>
          {valuation && (
            <div className='row valuation-inquiry-details-content px-3'>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.additionalInfoLabel'}
                  className='mr-1 font-bold'
                />
                {valuation.additionalInfo ? valuation.additionalInfo : '-'}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.estimatedServiceTime'}
                  className='mr-1 font-bold'
                />
                <span className='mr-1 font-bold'>
                  (
                  <Translate
                    value={`duration.${
                      valuation.durationUnit
                        ? valuation.durationUnit.toLowerCase()
                        : ''
                    }`}
                  />
                  ):
                </span>
                {getOrZero(valuation.durationValue)}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.additionalCostLabel'}
                  className='mr-1 font-bold'
                />
                {valuation.additionalCost
                  ? `${valuation.additionalCost.value} ${valuation.additionalCost.currency}`
                  : '-'}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.paymentTypeLabel'}
                  className='mr-1 font-bold'
                />
                {renderedTypeString}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.totalCostLabel'}
                  className='mr-1 font-bold'
                />
                {valuation.totalCost.value} {valuation.totalCost.currency}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.expiryDateLabel'}
                  className='mr-1 font-bold'
                />
                {valuation.expiryDate
                  ? moment(valuation.expiryDate).format('YYYY-MM-DD')
                  : '-'}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.proposedDateLabel'}
                  className='mr-1 font-bold'
                />
                {valuation.proposedDate
                  ? moment(valuation.proposedDate).format('YYYY-MM-DD')
                  : '-'}
              </div>
              <div className='col-12'>
                <Translate
                  value={'valuationDetails.valuationInquiryCreatedBy'}
                  className='mr-1 font-bold'
                />
                {valuation.workshopWorker
                  ? valuation.workshopWorker.firstName +
                    ' ' +
                    valuation.workshopWorker.lastName
                  : '-'}
              </div>
            </div>
          )}
        </Collapse>
      </div>
    );
  }
}

RenderValuationSummaryDetails.propsTypes = {
  valuation: PropTypes.object,
};
