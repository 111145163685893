/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import './text-area.css';
import {errorColor} from '../../../utils/style.utils';

export class RenderTextArea extends React.Component {
  render() {
    const {
      input,
      inputDisabled,
      inputReadOnly,
      label,
      required,
      rows,
      meta: {touched, error},
    } = this.props;
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <span
            className={`field-label ${touched && error ? 'error-color' : ''}`}>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <Input
            type='textarea'
            className='custom-text-area ui-inputtext'
            readOnly={inputReadOnly}
            rows={rows}
            {...input}
            disabled={inputDisabled}
            style={touched && error ? {borderColor: errorColor} : null}
          />
          <div id='error-msg' className='col-12'>
            {touched && error ? (
              <span className='error-info'>
                {I18n.t(error.key, error.params)}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

RenderTextArea.propTypes = {
  rows: PropTypes.number,
  required: PropTypes.bool,
};

RenderTextArea.defaultProps = {
  rows: 5,
  required: false,
};
