/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {statisticService} from '../services/statistic.service';
import {messageActions} from './message.actions';
import {statisticConstants} from '../constants/statistic.constants';
import {fileUtils} from '../utils/file.utils';
import {getErrorMessage} from '../utils/utils';

export const statisticActions = {
  clearStatisticsData,
  fetchValuationInquiryStatistics,
  fetchFilteredValuationInquiryStatistics,
  fetchValuationStatistics,
  fetchFilteredValuationStatistics,
  fetchLpgStatistics,
  fetchFilteredLpgStatistics,
  findAllCities,
  findAllStates,
  findAllWorkshopOptions,
  generateValuationInquiryStatisticsPdf,
  generateValuationStatisticsPdf,
  generateLpgStatisticsPdf,
  fetchOrderStatistics,
  fetchFilteredOrderStatistics,
  generateOrderStatisticsPdf,
};

function clearStatisticsData() {
  return dispatch => {
    dispatch({type: statisticConstants.CLEAR_STATISTICS_DATA});
  };
}

function fetchValuationInquiryStatistics() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchValuationInquiryStatistics().then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchFilteredValuationInquiryStatistics(data) {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchFilteredValuationInquiryStatistics(data).then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchValuationStatistics() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchValuationStatistics().then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchFilteredValuationStatistics(data) {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchFilteredValuationStatistics(data).then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchLpgStatistics() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchLpgStatistics().then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchFilteredLpgStatistics(data) {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchFilteredLpgStatistics(data).then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function findAllCities() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.findAllCities().then(
      cities => {
        dispatch({
          type: statisticConstants.LOAD_CITIES_DATA,
          payload: cities,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function findAllStates() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.findAllStates().then(
      states => {
        dispatch({
          type: statisticConstants.LOAD_STATES_DATA,
          payload: states,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function findAllWorkshopOptions() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.findAllWorkshopOptions().then(
      workshopOptions => {
        dispatch({
          type: statisticConstants.LOAD_WORKSHOP_OPTIONS,
          payload: workshopOptions,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function generateStatisticsPdf(data, resourcePath) {
  return dispatch => {
    statisticService.generateStatisticsPdf(data, resourcePath).then(
      res => {
        dispatch(
          messageActions.successMessage('statisticsPdf.generatePdfSuccessMsg')
        );
        fileUtils.openPdf(res);
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statisticsPdf', 'generatePdfErrorMsg')
          )
        );
      }
    );
  };
}

function generateValuationInquiryStatisticsPdf(data) {
  return generateStatisticsPdf(data, 'valuationInquiry');
}

function generateValuationStatisticsPdf(data) {
  return generateStatisticsPdf(data, 'valuation');
}

function generateLpgStatisticsPdf(data) {
  return dispatch => {
    statisticService.generateLpgStatisticsPdf(data).then(
      res => {
        dispatch(
          messageActions.successMessage('statisticsPdf.generatePdfSuccessMsg')
        );
        fileUtils.openPdf(res);
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'statisticsPdf', 'generatePdfErrorMsg')
          )
        );
      }
    );
  };
}

function fetchOrderStatistics() {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchOrderStatistics().then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function fetchFilteredOrderStatistics(data) {
  return dispatch => {
    dispatch({type: statisticConstants.STATISTIC_REQUEST_START});
    statisticService.fetchFilteredOrderStatistics(data).then(
      statistics => {
        dispatch({
          type: statisticConstants.LOAD_STATISTICS_DATA,
          payload: statistics,
        });
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('statistics.fetchError'));
        dispatch({type: statisticConstants.STATISTIC_REQUEST_END});
      }
    );
  };
}

function generateOrderStatisticsPdf(data) {
  return generateStatisticsPdf(data, 'order');
}
