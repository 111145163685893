/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {userActions} from 'shared/actions/user.actions';
import {userConstants} from 'shared/constants/user.constants';
import UserAvatar from '../../../user/user-profile/avatar/UserAvatar';
import UserProfileForm from '../../../user/user-profile/update-form/UserProfileForm';

class SelectedUser extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  handleOnSubmit(values) {
    delete values.avatar;
    this.props.updateProfile(this.props.data.id, values);
  }

  render() {
    const {canUpdate} = this.props;
    return (
      <div className='selected-user-profile-data px-sm-5 px-sm-0 pb-5'>
        <UserAvatar canUpdate={canUpdate} />
        <UserProfileForm
          onSubmit={this.handleOnSubmit}
          isAdminForm={true}
          canUpdate={canUpdate}
        />
      </div>
    );
  }
}

SelectedUser.propTypes = {
  canUpdate: PropTypes.bool,
};

SelectedUser.defaultProps = {
  canUpdate: true,
};

function mapStateToProps(state) {
  const {i18n, user} = state;
  const {data} = user;

  return {i18n, data};
}

const mapDispatchToProps = {
  updateProfile: userActions.updateProfile,
  clearData: () => ({type: userConstants.CLEAR_SELECTED_USER}),
};

const connectedSelectedUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedUser);
export {connectedSelectedUser as SelectedUser};
