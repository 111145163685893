/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {forwardRef, useState, useRef} from 'react';
import DatePicker from 'react-datepicker';
import '../date-picker.css';
import {errorColor} from 'shared/utils/style.utils';
import {I18n, Translate} from 'react-redux-i18n';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/pl';
import {DatePickerInput} from '../../date-time-picker/RenderDateTimePicker';
const dateFormat = 'YYYY-MM-DD';

const RenderDatePicker = forwardRef((props, ref) => {
  const {label, required, disabled, showClearIcon, error, onChange, selected, minDate, maxDate} =
    props;
  const datePickerRef = useRef(null);

  const onClickClearIcon = () => {
    onChange(null);
  };

  return (
    <>
      <div className='row mt-2'>
        <div className='col-12'>
          <label style={error && {color: errorColor}} className='field-label'>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </label>
        </div>
        <div className='col-12 date-time-picker-container my-auto'>
          <div className='p-inputgroup'>
            <DatePicker
              ref={datePickerRef}
              customInput={<DatePickerInput error={error} />}
              placeholderText={I18n.t('datePicker.chooseDate')}
              dateFormat={dateFormat}
              selected={selected ? moment(selected) : null}
              disabled={disabled}
              onSelect={onChange}
              minDate={minDate && moment(minDate)}
              maxDate={maxDate && moment(maxDate)}
            />

            {selected && showClearIcon ? (
              <span
                className={`p-inputgroup-addon no-left-border clickable ${disabled && 'disabled'}`}
                style={{width: '3em'}}
                onClick={disabled ? null : onClickClearIcon}>
                <i className='clear-icon fa fa-close m-auto' />
              </span>
            ) : (
              <span
                ref={ref}
                className={`p-inputgroup-addon no-left-border clickable ${disabled && 'disabled'}`}
                style={{
                  width: '3em',
                  borderBottomColor: error ? errorColor : 'rgb(206, 212, 218)',
                  borderBottomRightRadius: 0,
                }}
                onClick={() => disabled ? null : datePickerRef.current.setOpen(true)}>
                <i className='fa fa-calendar' />
              </span>
            )}
          </div>
        </div>
      </div>
      <div id='error-msg' style={{minHeight: 24, width: '100%'}}>
        {error && (
          <span className='error-info'>
            <Translate value={'validation.required'} />
          </span>
        )}
      </div>
    </>
  );
});

export default RenderDatePicker;
