/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import {
  cutPrefixFromBase64String,
  getMimeTypeFromFileName,
  isValidByFileType,
  isValidBySize,
} from './utils/file-utils';
import {messageActions} from '../../../actions/message.actions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import documentIcon from '../../../../assets/images/document-icon.png';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {imagePreviewUrl: null};
    this.onClickRemoveFileBtn = this.onClickRemoveFileBtn.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const {value} = this.props.input;
    if (value && value.preview) this.setState({imagePreviewUrl: value.preview});
  }

  onClickRemoveFileBtn() {
    this.setState({imagePreviewUrl: null});
    this.props.input.onChange(null);
    document.getElementById('file-input').value = null;
  }

  isValidFile(file) {
    const {maxSizeInBytes, fileTypes, keyGroup} = this.props;
    if (file.size === 0) {
      this.props.dispatch(
        messageActions.errorMessage(`${keyGroup}.emptyErrorMsg`)
      );
      return false;
    } else if (!isValidBySize(file.size, maxSizeInBytes)) {
      this.props.dispatch(
        messageActions.errorMessage(`${keyGroup}.maxSizeErrorMsg`)
      );
      return false;
    } else if (!isValidByFileType(file.type, fileTypes)) {
      this.props.dispatch(
        messageActions.errorMessage(`${keyGroup}.typeErrorMsg`)
      );
      return false;
    } else return true;
  }

  onChange(e) {
    e.preventDefault();
    const {isComplexValue} = this.props;
    const reader = new FileReader();
    const file = e.target.files[0];
    if (!this.isValidFile(file)) return;
    reader.onloadend = () => {
      const content = cutPrefixFromBase64String(reader.result);
      const newValue = isComplexValue
        ? {name: file.name, content: content, preview: reader.result}
        : content;
      this.props.input.onChange(newValue);
      this.setState({imagePreviewUrl: reader.result});
    };

    reader.readAsDataURL(file);
  }

  onError(e) {
    e.target.src = documentIcon;
  }

  renderFilePreview() {
    const {isComplexValue, input, disabled, imageUpload} = this.props;
    const content = isComplexValue
      ? this.props.input.value.content
      : this.props.input.value;

    return (
      <span className='ml-3'>
        <img
          src={
            this.state.imagePreviewUrl ? this.state.imagePreviewUrl : content
          }
          width={imageUpload ? 'auto' : 60}
          height={imageUpload ? 300 : 50}
          alt=''
          className='mr-1'
          onError={this.onError}
        />
        {isComplexValue && (
          <a
            href={`data:${getMimeTypeFromFileName(input.value.name)};base64,${
              input.value.content
            }`}
            target='_blank'>
            {this.props.input.value.name}
          </a>
        )}
        {!disabled && (
          <Button
            size='sm'
            className='ml-2 mr-1 my-1 btn-danger'
            onClick={this.onClickRemoveFileBtn}>
            <i className='fa fa-times' />
          </Button>
        )}
      </span>
    );
  }

  render() {
    const {allign, marginY, isComplexValue, disabled, imageUpload} = this.props;
    const allignClass = 'text-' + (allign ? allign : 'right');
    const myClass = 'my-' + (marginY ? marginY : '3');
    const content = isComplexValue
      ? this.props.input.value.content
      : this.props.input.value;

    return (
      <div className={myClass + ' ' + allignClass}>
        <input
          id='file-input'
          type='file'
          onChange={this.onChange}
          accept={this.props.accept}
          ref={ref => (this.fileInput = ref)}
          style={{display: 'none'}}
        />
        {!disabled && (
          <Button
            size='sm'
            className='main-btn'
            style={imageUpload ? {} : fixedBtn}
            onClick={() => this.fileInput.click()}>
            <Translate
              value={
                imageUpload ? 'fileUpload.chooseImage' : 'fileUpload.chooseFile'
              }
            />
          </Button>
        )}
        {content && this.renderFilePreview()}
      </div>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};

FileUpload.propTypes = {
  accept: PropTypes.string.isRequired,
  maxSizeInBytes: PropTypes.number.isRequired,
  fileTypes: PropTypes.array.isRequired,
  keyGroup: PropTypes.string.isRequired,
  allign: PropTypes.string,
  marginY: PropTypes.string,
  isComplexValue: PropTypes.bool,
  disabled: PropTypes.bool,
  imageUpload: PropTypes.bool,
};

function mapStateToProps(state) {
  return {i18n: state.i18n};
}

export default connect(mapStateToProps)(FileUpload);
