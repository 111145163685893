/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {history} from 'shared/utils/history';

export class NotFound extends React.Component {
  render() {
    const {from} = this.props.location.state || {from: {pathname: null}};
    return (
      <div
        style={{
          width: '50%',
          margin: '0 auto',
          lineHeight: '50%',
          padding: '20% 0',
        }}
        className='text-center'>
        <h3>
          <Translate value={'notFound.notFoundText'} />
          {`${from.pathname ? `: ${from.pathname}` : ''}`}
        </h3>
        <Button
          className='main-btn'
          size='sm'
          onClick={() => history.push('/')}>
          <Translate value={'notFound.backBtn'} />
        </Button>
      </div>
    );
  }
}
