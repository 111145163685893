/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {PartsContainer} from '../../admin/parts/PartsContainer';

export default class Parts extends React.Component {
  render() {
    return <PartsContainer {...this.props} />;
  }
}
