/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import ObjectUtils from 'primereact/utils';

export const sortNumbersAsc = (a, b) => {
  return a - b;
};

export const sortNumbersDesc = (a, b) => {
  return b - a;
};

export const sortByCustomField = (event, list, eventField) => {
  let value = list;
  event.field = eventField;
  value.sort((data1, data2) => {
    let value1 = ObjectUtils.resolveFieldData(data1, event.field);
    let value2 = ObjectUtils.resolveFieldData(data2, event.field);
    let result = null;
    if (value1 == null && value2 != null) result = -1;
    else if (value1 != null && value2 == null) result = 1;
    else if (value1 == null && value2 == null) result = 0;
    else if (typeof value1 === 'string' && typeof value2 === 'string')
      result = value1.localeCompare(value2);
    else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    return event.order * result;
  });
  return value;
};

export const sortByInquiryNumber = (valuationInquiries, event) => {
  let values = [...valuationInquiries];
  values.sort((data1, data2) =>
    event.order === 1
      ? sortNumbersAsc(data1.inquiryNumber.number, data2.inquiryNumber.number)
      : sortNumbersDesc(data1.inquiryNumber.number, data2.inquiryNumber.number)
  );

  return values;
};

export const sortByOrderNumber = (orders, event) => {
  let values = [...orders];
  values.sort((data1, data2) =>
    event.order === 1
      ? sortNumbersAsc(
          digitsOnly(data1.orderNumber),
          digitsOnly(data2.orderNumber)
        )
      : sortNumbersDesc(
          digitsOnly(data1.orderNumber),
          digitsOnly(data2.orderNumber)
        )
  );

  return values;
};

const digitsOnly = value => {
  return value.replace(/\D+/g, '');
};
