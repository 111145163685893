/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Collapse} from 'reactstrap';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import {serviceActions} from 'shared/actions/service.actions';
import {serviceStatus} from 'shared/constants/service.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import ServiceCategoryDetails from './service-category-details/ServiceCategoryDetails';
import './service-category.css';
import {ServiceList} from './service-list/ServiceList';

class ServiceCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {collapse: false};
    this.toggle = this.toggle.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.updateCategory = this.updateCategory.bind(this);
    this.createService = this.createService.bind(this);
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  showDetails(serviceCategoryId) {
    this.props.findServiceCategoryById(serviceCategoryId);
    const details = (
      <ServiceCategoryDetails
        onCategoryEdit={this.updateCategory}
        onServiceCreate={this.createService}
        editingRestricted={this.props.editingRestricted}
        privateServicesRestriction={this.props.privateServicesRestriction}
      />
    );
    setTimeout(
      () =>
        this.props.showUpdateDataSideBar(
          details,
          I18n.t('services.editCategoryFormTitle')
        ),
      10
    );
  }

  updateCategory(values) {
    this.props.updateServiceCategory(this.props.selectedCategory.id, values);
  }

  createService(values) {
    this.props.createService(values);
  }

  resolveToggleIcon() {
    const {filterActive} = this.props;
    return this.state.collapse || filterActive
      ? 'fa-arrow-circle-up'
      : 'fa-arrow-circle-down';
  }

  renderPendingServiceInfo(category) {
    const {isServiceManager} = this.props;
    const pendingServices = category.services.filter(
      s => s.status === serviceStatus.PENDING
    );
    if (pendingServices.length > 0 && isServiceManager) {
      return (
        <div className='pending-services-info'>
          <Translate value={'services.pendingServicesInCategoryInfo'} />
        </div>
      );
    }
    return null;
  }

  renderDecisionButtons(categoryId, status) {
    const {isServiceManager} = this.props;
    if (status !== serviceStatus.PENDING) return <div />;

    if (isServiceManager)
      return (
        <div className='decision-buttons text-center'>
          <Translate value={'services.decisionNeeded'} />
          <div>
            <Button
              className='btn main-btn decision-btn'
              onClick={() => this.props.acceptServiceCategory(categoryId)}>
              <i className='fa fa-check' />
            </Button>
            <Button
              className='btn btn-danger decision-btn'
              onClick={() => this.props.deleteServiceCategory(categoryId)}>
              <i className='fa fa-remove' />
            </Button>
          </div>
        </div>
      );
    else
      return (
        <div className='decision-buttons text-center'>
          <Translate value={'services.waitingForAcceptance'} />
        </div>
      );
  }

  renderEditServiceCategoryBtn(category) {
    if (!this.props.isEditable) return null;

    return (
      <Button
        id='edit-category-btn'
        className='main-btn'
        onClick={() => this.showDetails(category.id)}>
        <Translate value={'services.editCategoryBtnLabel'} />
      </Button>
    );
  }

  renderHeader(category) {
    const {id, name, description, status} = category;
    return (
      <div className='row category-header py-3'>
        <div className='col-lg-4 col-12 vertical-centered'>
          <i
            id='toggle-icon'
            className={'mr-3 fa fa-2x ' + this.resolveToggleIcon()}
            onClick={this.toggle}
          />
          <div className='hyphens'>
            {name}
            {this.renderPendingServiceInfo(category)}
          </div>
        </div>
        <div className='col-lg-4 col-12 vertical-centered' id='description'>
          {`${I18n.t('services.descriptionLabel')}: ${
            description ? description : I18n.t('services.noData')
          }`}
        </div>
        <div className='col-lg-4 col-12 mt-3' id='categoryButtons'>
          {this.renderDecisionButtons(id, status)}
          {this.renderEditServiceCategoryBtn(category)}
        </div>
      </div>
    );
  }

  render() {
    const {
      category,
      filterActive,
      handleSelect,
      isEditable,
      editingRestricted,
      isServiceManager,
      privateServicesRestriction,
    } = this.props;

    return (
      <div className='mx-3'>
        {this.renderHeader(category)}
        <div className='row'>
          <div className='col px-0'>
            <Collapse isOpen={this.state.collapse || filterActive}>
              <ServiceList
                services={category.services}
                handleSelect={handleSelect}
                isEditable={isEditable}
                filterActive={filterActive}
                editingRestricted={editingRestricted}
                isServiceManager={isServiceManager}
                privateServicesRestriction={privateServicesRestriction}
              />
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {service, user} = state;
  const selectedCategory = service.category;
  const loggedUserId = user.data ? user.data.id : '';
  return {selectedCategory, loggedUserId};
}

const mapDispatchToProps = {
  findServiceCategoryById: serviceCategoryActions.findServiceCategoryById,
  updateServiceCategory: serviceCategoryActions.updateServiceCategory,
  deleteServiceCategory: serviceCategoryActions.deleteServiceCategory,
  acceptServiceCategory: serviceCategoryActions.acceptServiceCategory,
  createService: serviceActions.createService,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

const connectedServiceCategory = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceCategory);
export {connectedServiceCategory as ServiceCategory};
