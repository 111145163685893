/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Paginator} from 'primereact/paginator';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button, Input} from 'reactstrap';
import {Field, FieldArray} from 'redux-form';
import {getOrEmptyArray} from '../../../utils/utils';
import renderField from '../../form/text-field/RenderField';
import {
  applyLanguageValueFilter,
  applyLanguageValuesFilter,
} from './language-values.filter';

export const renderLanguageValues = ({
  fields,
  searchPhrase,
  firstIndex,
  lastIndex,
}) => {
  const filteredFormFieldIndexes = getOrEmptyArray(fields.getAll())
    .map((field, index) => ({formFieldIndex: index, ...field}))
    .filter(field => applyLanguageValueFilter(field, searchPhrase))
    .filter((field, index) => index >= firstIndex && index < lastIndex)
    .map(field => field.formFieldIndex);

  return fields.map(
    (field, index) =>
      filteredFormFieldIndexes.indexOf(index) >= 0 && (
        <Field
          key={index}
          name={`${field}.value`}
          label={fields.get(index).key}
          type='text'
          component={renderField}
          required={true}
          inputAlwaysBottom={true}
        />
      )
  );
};

export class LanguageValuesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first: 0,
      rows: 50,
      searchPhrase: '',
    };
  }

  renderButtons() {
    const {invalid, handleCancel} = this.props;

    return (
      <Fragment>
        <Button
          id='language-values-form-submit-btn'
          className='mx-1 my-1 main-btn fixed-width-btn'
          type='submit'
          disabled={invalid}
          size='sm'>
          <Translate value={'languages.saveBtn'} />
        </Button>

        <Button
          id='language-values-form-cancel-btn'
          className='mx-1 my-1 fixed-width-btn'
          color='secondary'
          onClick={handleCancel}
          size='sm'>
          <Translate value={'languages.cancelBtn'} />
        </Button>
      </Fragment>
    );
  }

  renderHeader() {
    const {invalid} = this.props;

    const validationErrorsAlert = (
      <div className='row justify-content-center mt-2'>
        <div className='col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12'>
          <Alert className='text-center m-3' color='danger'>
            <i className='fa fa-exclamation-circle' />
            &nbsp;
            <Translate value='languages.editValuesFormValidationErrorsAlert' />
          </Alert>
        </div>
      </div>
    );

    const header = (
      <div className='row justify-content-lg-between justify-content-center mb-2'>
        <div className='col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12 text-lg-left text-center'>
          {this.renderButtons()}
        </div>
        <div className='col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12'>
          <Input
            id='filter-input'
            type='search'
            placeholder={I18n.t('languages.searchPhrasePlaceholder')}
            value={this.state.searchPhrase}
            onInput={e =>
              this.setState({searchPhrase: e.target.value, first: 0})
            }
          />
        </div>
      </div>
    );

    return invalid ? validationErrorsAlert : header;
  }

  renderFooter() {
    const {invalid, initialValues} = this.props;

    const paginator = (
      <Paginator
        first={this.state.first}
        rows={this.state.rows}
        totalRecords={
          applyLanguageValuesFilter(
            initialValues.flatValues,
            this.state.searchPhrase
          ).length
        }
        rowsPerPageOptions={[50, 100, 200]}
        onPageChange={e => this.setState({first: e.first, rows: e.rows})}
      />
    );

    const elements = invalid ? [] : [paginator];

    return elements.map((element, index) => (
      <div key={index} className='row justify-content-center mt-3'>
        <div className='col-xl-4 col-lg-5 col-md-7 col-sm-8 col-12'>
          {element}
        </div>
      </div>
    ));
  }

  render() {
    const {handleSubmit, titleKey, showValues} = this.props;

    return (
      <div className='language-values-form px-sm-5 px-sm-0 py-5'>
        {titleKey && (
          <h2 className='mb-3 text-center'>
            <Translate value={titleKey} />
          </h2>
        )}
        <form onSubmit={handleSubmit}>
          {showValues && (
            <Fragment>
              {this.renderHeader()}
              <FieldArray
                name='flatValues'
                component={renderLanguageValues}
                searchPhrase={this.state.searchPhrase}
                firstIndex={this.state.first}
                lastIndex={this.state.first + this.state.rows}
              />
              {this.renderFooter()}
            </Fragment>
          )}
        </form>
      </div>
    );
  }
}

LanguageValuesForm.propTypes = {
  titleKey: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  showValues: PropTypes.bool,
};
