/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n} from 'react-redux-i18n';

export class RenderDetailsCore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {collapse: false};
    this.toggle = this.toggle.bind(this);
  }

  resolveToggleIcon() {
    return this.state.collapse
      ? 'fa-chevron-circle-up'
      : 'fa-chevron-circle-down';
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  renderHeader(headerTitle, isServiceName = false, categoryName = '') {
    const title = isServiceName ? (
      <span>{` [${headerTitle}]`}</span>
    ) : (
      I18n.t(`valuationDetails.${headerTitle}`)
    );
    return (
      <div
        className={`row valuation-inquiry-details-header mx-0 ${
          this.state.collapse ? 'collapsed-header' : ''
        }`}
        onClick={this.toggle}>
        <div className='col-12 text-left my-auto'>
          <i
            id='toggle-icon'
            className={'mr-3 fa ' + this.resolveToggleIcon()}
          />
          {categoryName} {title}
        </div>
      </div>
    );
  }

  render() {
    return <div />;
  }
}
