/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {MultiSelect} from 'primereact/multiselect';
import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {errorColor} from 'shared/utils/style.utils';
import './render-multi-select.css';

class RenderMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    console.log(event);
    const {input} = this.props;
    input.onChange(event.value);
  }

  render() {
    const {
      options,
      input,
      placeholder,
      label,
      icon,
      required,
      disabled,
      itemTemplate,
      showClear,
      meta: {touched, error},
    } = this.props;

    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <span
            className='field-label'
            style={touched && error ? {color: errorColor} : null}>
            {label}
            {required && <span style={{color: errorColor}}>*</span>}
          </span>
        </div>
        <div className='col-12'>
          <MultiSelect
            value={input.value}
            options={options}
            onChange={this.handleOnChange}
            placeholder={placeholder}
            filterPlaceholder={I18n.t('renderField.searchPlaceholder')}
            filterBy='label,value'
            required={required}
            disabled={disabled}
            itemTemplate={itemTemplate}
            showClear={showClear}
            className='dropdown-content'
          />
          <div id='error-msg' className='col-12'>
            {touched && error ? (
              <span className='error-info'>
                {I18n.t(error.key, error.params)}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n};
}

export default connect(mapStateToProps)(RenderMultiSelect);
