import React from "react";
import { Translate } from "react-redux-i18n";
import styles from "./WarrantyNotes.module.css";
import EmptyFieldPlaceholder from "../empty-field-placeholder/EmptyFieldPlaceholder";
import { useSelector } from "react-redux";
import Loadable from 'react-loading-overlay';

const DescriptionFault = () => {
  const initializedWarranty = useSelector(
    (state) => state.warrantyBook.initializedWarranty
  );
  const isFetching = useSelector((state) => state.warrantyBook.isFetching);

  return (
    <Loadable active={isFetching} spinner>
      <div className={styles.WarrantyNotesContainer}>
        {initializedWarranty && initializedWarranty.notes ? (
          initializedWarranty.notes
        ) : (
          <EmptyFieldPlaceholder content={"warranty.noData"} />
        )}
      </div>
    </Loadable>
  );
};

export default DescriptionFault;
