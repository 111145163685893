/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const orderRatingConstants = {
  ORDER_RATING_REQUEST_START: 'ORDER_RATING_REQUEST_START',
  ORDER_RATING_REQUEST_END: 'ORDER_RATING_REQUEST_END',

  LOAD_ORDER_RATINGS: 'LOAD_ORDER_RATINGS',
  LOAD_EXISTS_OWNED_FOR_ORDER: 'LOAD_EXISTS_OWNED_FOR_ORDER',

  CLEAR_ALL: 'CLEAR_ALL',
};
