/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export class ValuationInquiryModel {
  valuationInquiryServices;
  otherServices;
  carName;
  carBrand;
  carModel;
  carModelOther;
  carProductionYear;
  carEngineCapacityCm3;
  carEnginePowerKw;
  carFuelTypeId;
  userFirstName;
  userLastName;
  userCity;
  userVoivodeship;
  userEmail;
  userPhoneNumber;
  userRemarks;
  workshopsIds;
  country;
  origin;

  constructor(formValues) {
    this.valuationInquiryServices = formValues.valuationInquiryServices;
    this.otherServices = formValues.otherServices;
    this.carName = formValues.carName;
    this.carBrand = formValues.carBrand;
    this.carModel = formValues.carModel;
    this.carModelOther = formValues.carModelOther;
    this.carProductionYear = formValues.carProductionYear;
    this.carEngineCapacityCm3 = formValues.carEngineCapacityCm3;
    this.carEnginePowerKw = formValues.carEnginePowerKw;
    this.carFuelTypeId = formValues.carFuelTypeId;
    this.userFirstName = formValues.userFirstName;
    this.userLastName = formValues.userLastName;
    this.userCity = formValues.userCity;
    this.userVoivodeship = formValues.userVoivodeship;
    this.userEmail = formValues.userEmail;
    this.userPhoneNumber = formValues.userPhoneNumber;
    this.userRemarks = formValues.userRemarks;
    this.workshopsIds = formValues.workshopsIds;
    this.country = formValues.country;
    this.origin = formValues.origin;
  }
}
