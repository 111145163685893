/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {partConstants} from '../constants/part.constants';
import {SyncState} from '../utils/sync';

export function partReducer(state = {}, action) {
  switch (action.type) {
    case partConstants.PART_REQUEST_START:
      return {...state, isFetching: true};
    case partConstants.PART_REQUEST_END:
      return {...state, isFetching: false};
    case partConstants.LOAD_PART:
      return {...state, selected: action.payload.data};
    case partConstants.CLEAR_PART_DATA:
      return {...state, selected: null};
    case partConstants.LOAD_PARTS:
      return {
        ...state,
        parts: action.payload.data,
        partDataSyncState: SyncState.UP_TO_DATE,
      };
    case partConstants.LOAD_PART_CATEGORIES:
      return {...state, categories: action.payload.data};
    case partConstants.REFRESH_PART_DATA:
      return {...state, partDataSyncState: SyncState.OBSOLETE};
    default:
      return state;
  }
}
