/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Sidebar} from 'primereact/sidebar';
import {connect} from 'react-redux';
import {updateDataSideBarConstants} from '../../constants/update-data-side-bar.constants';
import './update-data-side-bar.css';

class UpdateDataSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.onHide = this.onHide.bind(this);
  }

  onHide() {
    const {dispatch} = this.props;
    dispatch({type: updateDataSideBarConstants.HIDE});
  }

  render() {
    const {visible, title, component, variableComponent} = this.props;
    return (
      <Sidebar
        id='update-data-sidebar'
        visible={visible}
        onHide={this.onHide}
        position='right'
        baseZIndex={1}
        className={visible ? 'shadow' : 'd-none'}
        icons={() => (
          <div id='top-bar' className='top-bar bottom-shadow'>
            {!!title ? title : ''}
          </div>
        )}>
        <div>{component || variableComponent}</div>
      </Sidebar>
    );
  }
}

function mapStateToProps({updateSideBar}) {
  const {visible, variableComponent, title} = updateSideBar;
  return {visible, variableComponent, title};
}

export default connect(mapStateToProps)(UpdateDataSideBar);
