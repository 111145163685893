import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import RenderTextArea from 'shared/components/form/text-area/hook-form-version/RenderTextArea';
import styles from './CheckBoxWithTextArea.module.css';
import {I18n} from 'react-redux-i18n';

const CheckBoxWithTextArea = props => {
  const {checkName, control, textAreaName, setValue, initValue, error} = props;
  const [checked, setChecked] = useState(initValue ?? false);

  useEffect(() => setValue(checkName, checked), [checked]);

  return (
    <div className={styles.Container}>
      <p className={styles.Label}>{props.label}</p>

      <Controller
        name={checkName}
        control={control}
        name={checkName}
        render={() => (
          <div className={styles.DotsBox}>
            <div className={styles.DotBox} onClick={() => setChecked(true)}>
              <div className={checked ? styles.DotChecked : styles.Dot} />
              <p className={styles.DotLabel}>{I18n.t('warranty.sectionVII.yes')}</p>
            </div>

            <div className={styles.DotBox} onClick={() => setChecked(false)}>
              <div className={checked ? styles.Dot : styles.DotChecked} />
              <p className={styles.DotLabel}>{I18n.t('warranty.sectionVII.no')}</p>
            </div>
          </div>
        )}
      />

      <Controller
        name={textAreaName}
        control={control}
        rules={{ maxLength: 1000 }}
        render={({field: {value, onChange}}) => (
          <RenderTextArea
            disabled={!checked}
            value={value}
            onChange={onChange}
            label={I18n.t('warranty.sectionVII.attensions')}
            error={error}
          />
        )}
      />
    </div>
  );
};

export default CheckBoxWithTextArea;
