/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {languageActions} from 'shared/actions/language.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import LanguageValuesEditForm from 'shared/components/languages/language-values-form/LanguageValuesEditForm';
import LanguageCreateForm from 'shared/components/languages/languages-form/LanguageCreateForm';
import LanguageEditForm from 'shared/components/languages/languages-form/LanguageEditForm';
import LanguagesTable from 'shared/components/languages/languages-table/LanguagesTable';
import {languageConstants} from 'shared/constants/language.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';
import {getOrEmptyArray} from 'shared/utils/utils';

class Languages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLanguageId: null,
    };

    this.showCreateLanguage = this.showCreateLanguage.bind(this);
    this.showLanguageDetails = this.showLanguageDetails.bind(this);
    this.renderView = this.renderView.bind(this);
    this.onEditLanguageValuesClick = this.onEditLanguageValuesClick.bind(this);
  }

  componentDidMount() {
    this.props.findAllLanguages();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.languagesDataSyncState,
        this.props.languagesDataSyncState
      )
    )
      this.props.findAllLanguages();
  }

  componentWillUnmount() {
    this.props.clearLanguages();
  }

  showCreateLanguage() {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <LanguageCreateForm
        handleCancel={hideUpdateDataSideBar}
        onCreateSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('languages.createFormTitle'));
  }

  showLanguageDetails(row) {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <LanguageEditForm
        languageId={row.data.id}
        handleCancel={hideUpdateDataSideBar}
        onDeleteSuccess={hideUpdateDataSideBar}
        onEditLanguageValuesClick={() => {
          this.onEditLanguageValuesClick(row.data.id);
          hideUpdateDataSideBar();
        }}
      />
    );
    showUpdateDataSideBar(details, I18n.t('languages.editFormTitle'));
  }

  onEditLanguageValuesClick(languageId) {
    this.setState({activeLanguageId: languageId});
  }

  renderView() {
    const {loadedLanguages} = this.props;

    if (this.state.activeLanguageId) {
      return (
        <LanguageValuesEditForm
          languageId={this.state.activeLanguageId}
          handleCancel={() => this.setState({activeLanguageId: null})}
        />
      );
    } else {
      return (
        <LanguagesTable
          languages={loadedLanguages}
          onRowClick={this.showLanguageDetails}
          onCreateClick={this.showCreateLanguage}
        />
      );
    }
  }

  render() {
    const {isFetchingLanguages} = this.props;

    return (
      <ContentWrapper title='languagesContainer.languagesBtn'>
        <Loadable active={isFetchingLanguages} spinner>
          <div className='p-3'>{this.renderView()}</div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, language} = state;
  const {isFetchingLanguages, languagesDataSyncState} = language;
  const loadedLanguages = getOrEmptyArray(language.languages);
  return {i18n, isFetchingLanguages, languagesDataSyncState, loadedLanguages};
}

const mapDispatchToProps = {
  findAllLanguages: languageActions.findAllLanguages,
  clearLanguages: () => ({type: languageConstants.CLEAR_LANGUAGES}),
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedLanguagesWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(Languages);
export {connectedLanguagesWithRedux as Languages};
