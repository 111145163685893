/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {withRedirectForLoggedUser} from 'shared/hoc/redirect-logged-user/redirectLoggedUserHoc';
import {languageVersion, linkToUserActivation} from '../environment';
import './sign-up.css';
import SignUpForm from './SignUpForm';

class SignUpContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onSubmitHandler(values) {
    const {dispatch, i18n} = this.props;
    const languageInfoDto = {
      languageSymbol: i18n.locale,
      languageVersion: languageVersion,
    };
    const userActivationParamsDto = {
      userActivationLink: linkToUserActivation,
      languageInfoDto: languageInfoDto,
    };
    const signupFullDto = {
      userFullDto: values,
      userActivationParamsDto: userActivationParamsDto,
    };
    dispatch(userActions.signup(signupFullDto));
  }

  render() {
    const {isFetching} = this.props;

    return (
      <ContentWrapper
        title='signup.cardTitle'
        containerClass='sign-up-container'>
        <Loadable active={isFetching} spinner>
          <SignUpForm
            onSubmit={this.onSubmitHandler}
            goBackHandler={this.props.history.goBack}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {user, i18n} = state;
  const {isFetching} = user;
  return {isFetching, i18n};
}

//export for test purposes
export const ConnectedSignUpContainerWithRedux =
  connect(mapStateToProps)(SignUpContainer);

const WithRedirectSignUpContainer = withRedirectForLoggedUser(SignUpContainer);
const connectedSignUpContainer = connect(mapStateToProps)(
  WithRedirectSignUpContainer
);
export {connectedSignUpContainer as SignUpContainer};
