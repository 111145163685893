/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';

export default class DistributorDetailsSection extends React.Component {
  render() {
    return (
      <Fragment>
        <Field
          name='phoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('distributors.phoneNumberLabel')}
          format={formatPhoneNumberField}
        />
        <Field
          name='mobilePhoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('distributors.mobilePhoneNumber')}
          format={formatPhoneNumberField}
        />
        <Field
          name='faxNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('distributors.faxLabel')}
          format={formatPhoneNumberField}
        />
        <Field
          name='nip'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.nipLabel')}
          icon='fa fa-info'
        />
        <Field
          name='regon'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.regonLabel')}
          icon='fa fa-info'
        />
        <Field
          name='krs'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('distributors.krsLabel')}
          icon='fa fa-info'
        />
        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          required={false}
          rows={4}
          icon='fa fa-info'
          label={I18n.t('distributors.descriptionLabel')}
        />
      </Fragment>
    );
  }
}
