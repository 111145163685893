import React, { useEffect, useState } from 'react'
import { RadioButton } from 'primereact/radiobutton';
import RenderDatePicker from 'shared/components/form/date-picker/hook-form-version/RenderDatePicker';
import { Button } from "reactstrap";
import styles from './PointsAccuralPeriod.module.css'
import moment from 'moment';
import { Translate } from 'react-redux-i18n';
import { I18n } from "react-redux-i18n";
import { useForm, Controller } from 'react-hook-form';
import { updateDataSideBarConstants } from 'shared/constants/update-data-side-bar.constants';
import { useDispatch } from 'react-redux';
import WorkshopLoyaltyProgramModal from '../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import { workshopLoyaltyProgramActions } from 'shared/actions/workshopLoyaltyProgram.actions';
import { dateUtils } from '../../../../shared/utils/date.utils';
import { PointsAccrualPeriodType } from './PointsAccrualPeriod';

export const PointsAccuralPeriodForm = (props) => {
    const { loyaltyConfig } = props;
    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onSubmit"
    });

    const onSubmit = (val) => {
        let values;
        if( val.repeatability === PointsAccrualPeriodType.REAL_TIME ){
            delete val.stopDate
        }
        if(!val.expirationDate){
            delete val.spentPointsEndDate
        }
        values = val;
        if (loyaltyConfig) {
            if ((loyaltyConfig.repeatability === "QUARTER" && values.repeatability === "MONTH" && moment(values.startDate).utc().add(2, 'months').isBefore(moment().utc().subtract(1, 'months'))) ||
                (loyaltyConfig.repeatability === "YEAR" && values.repeatability === "MONTH" && moment(values.startDate).utc().add(11, 'months').isBefore(moment().utc().subtract(1, 'months'))) ||
                (loyaltyConfig.repeatability === "YEAR" && values.repeatability === "QUARTER" && moment(values.startDate).utc().add(8, 'months').isBefore(moment().utc().subtract(3, 'months')))) {
                dispatch({
                    type: updateDataSideBarConstants.SHOW,
                    variableComponent: <WorkshopLoyaltyProgramModal tooShortAccuralPeriodStep />,
                    title: I18n.t("workshopLoyaltyProgram.tooShortPeriod"),
                })
            } else dispatch({
                type: updateDataSideBarConstants.SHOW,
                variableComponent: <WorkshopLoyaltyProgramModal
                    editPointAccuralPeriodStep
                    setEditMode={props.setEditMode}
                    savePointsAccuralPeriodForm={() => { values.id ? dispatch(workshopLoyaltyProgramActions.updateLoyaltyConfig(values)) : dispatch(workshopLoyaltyProgramActions.createLoyaltyConfig(values)); }} />,
                title: I18n.t("workshopLoyaltyProgram.onEditMessageSetPointsAccrualPeriod"),
            })
        } else
            dispatch({
                type: updateDataSideBarConstants.SHOW,
                variableComponent: <WorkshopLoyaltyProgramModal
                    editPointAccuralPeriodStep
                    setEditMode={props.setEditMode}
                    savePointsAccuralPeriodForm={() => { values.id ? dispatch(workshopLoyaltyProgramActions.updateLoyaltyConfig(values)) : dispatch(workshopLoyaltyProgramActions.createLoyaltyConfig(values)); }} />,
                title: I18n.t("workshopLoyaltyProgram.onEditMessageSetPointsAccrualPeriod"),
            });
    };

    const dispatch = useDispatch();

    const expirationDateField = "expirationDate";
    const repeatabilityField = "repeatability";
    const startDateField = "startDate";
    const stopDateField = "stopDate";
    const spentPointsEndDateField = "spentPointsEndDate"

    useEffect(() => {
        if (loyaltyConfig) {
            setValue(expirationDateField, loyaltyConfig?.expirationDate);
            setValue(repeatabilityField, loyaltyConfig?.repeatability);
            setValue(startDateField, loyaltyConfig?.startDate);
            setValue(stopDateField, loyaltyConfig?.stopDate);
            setValue(spentPointsEndDateField, loyaltyConfig?.spentPointsEndDate);
            setValue("id", loyaltyConfig?.id)
        }
    }, [loyaltyConfig])

    const renderMaxDateField = (repeatabilityField, startDateField) => {
        switch (repeatabilityField) {
            case PointsAccrualPeriodType.MONTH:
                return moment(watch(startDateField)).add(1, 'month')
            case PointsAccrualPeriodType.QUARTER:
                return moment(watch(startDateField)).add(3, 'month')
            case PointsAccrualPeriodType.YEAR:
                return moment(watch(startDateField)).add(1, 'year')
            default:
                break;
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginLeft: '10%', marginTop: '2rem', marginRight: '10%' }} >
                <div className={styles.InputField}>
                    <Controller
                        control={control}
                        name={startDateField}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                            <RenderDatePicker
                                selected={value}
                                onChange={(value => {
                                    const date = dateUtils.formatDatePickerDate(value);
                                    setValue(expirationDateField, null);
                                    setValue(stopDateField, null);
                                    setValue(spentPointsEndDateField, null);
                                    onChange(date)
                                })}
                                label={I18n.t("workshopLoyaltyProgram.pointsAccrualPeriodStartDate")}
                                error={errors[startDateField]}
                                minDate={moment()}
                                required
                                showClearIcon={true}
                            />
                        )}
                    />
                </div>
                <>
                    <div style={{ marginTop: '1rem' }}>
                        <div className={styles.Header}>
                            <Translate value={'workshopLoyaltyProgram.setPointsAccrualPeriod'} />
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <Controller
                                control={control}
                                name={repeatabilityField}
                                defaultValue={props.pointsAccuralPeriods[0].value}
                                render={({ field: { onChange, value } }) => (
                                    props.pointsAccuralPeriods.map((option) => (
                                        <div style={{ paddingBottom: '0.5rem' }} key={option.value}>
                                            <RadioButton value={option.value} name={option.name} onChange={(e) => {
                                                onChange(e.value); 
                                                setValue(stopDateField, null); 
                                                setValue(expirationDateField, null);
                                                setValue(spentPointsEndDateField, null);
                                            }} 
                                            checked={value === option.value} />
                                            <span style={{ marginLeft: 9 }}><Translate value={option.name} /></span>
                                        </div>
                                    ))
                                )}
                            />
                        </div>
                    </div>
                    {!(watch(repeatabilityField) === PointsAccrualPeriodType.REAL_TIME) &&
                        <div className={styles.InputField}>
                            <Controller
                                control={control}
                                name={stopDateField}
                                rules={{ required: !(watch(repeatabilityField) === PointsAccrualPeriodType.REAL_TIME) }}
                                render={({ field: { value, onChange } }) => (
                                    <RenderDatePicker
                                        disabled={!watch(startDateField) || watch(repeatabilityField) === PointsAccrualPeriodType.REAL_TIME}
                                        selected={(!watch(startDateField) || watch(repeatabilityField) === PointsAccrualPeriodType.REAL_TIME) ? null : value}
                                        onChange={(value => {
                                            const date = dateUtils.formatDatePickerDateWithEndOfTheDay(value);
                                            onChange(date);
                                        })}
                                        label={I18n.t("workshopLoyaltyProgram.pointsAccrualPeriodEndDate")}
                                        error={errors[stopDateField]}
                                        minDate={moment(watch(startDateField)).add(1, 'day')}
                                        maxDate={renderMaxDateField(watch(repeatabilityField), watch(startDateField))}
                                        required={!(watch(repeatabilityField) === PointsAccrualPeriodType.REAL_TIME)}
                                        showClearIcon={true}
                                    />
                                )}
                            />
                        </div>}
                    <div className={styles.InputField} style={{ marginTop: '1rem'}}>
                        <Controller
                            control={control}
                            name={expirationDateField}
                            render={({ field: { value, onChange } }) => (
                                <RenderDatePicker
                                    selected={value}
                                    onChange={(value => {
                                        const date = dateUtils.formatDatePickerDateWithEndOfTheDay(value);
                                        setValue(spentPointsEndDateField, null);
                                        onChange(date)
                                    })}
                                    label={I18n.t("workshopLoyaltyProgram.workshopLoyaltyProgramEndDateField")}
                                    minDate={watch(startDateField) ? moment(watch(startDateField)).add(1, 'day') : moment().add(1, 'day')}
                                    showClearIcon
                                />
                            )}
                        />
                        {!!watch(expirationDateField) &&
                            <div className={styles.InputField}>
                                <Controller
                                    control={control}
                                    name={spentPointsEndDateField}
                                    rules={{ required: !!watch(expirationDateField) }}
                                    render={({ field: { value, onChange } }) => (
                                        <RenderDatePicker
                                            selected={value}
                                            onChange={(value => {
                                                const date = dateUtils.formatDatePickerDateWithEndOfTheDay(value);
                                                onChange(date)
                                            })}
                                            label={I18n.t("workshopLoyaltyProgram.workshopStopDateAccrualPeriod")}
                                            error={errors[spentPointsEndDateField]}
                                            minDate={moment(watch(expirationDateField))}
                                            required={!!watch(expirationDateField)}
                                            showClearIcon={true}
                                        />
                                    )}
                                />
                            </div>}
                    </div>
                </>
                <div className={styles.ButtonSection}>
                    <Button
                        className="main-btn"
                        style={{ width: "14.7rem" }}
                        type="submit"
                    >
                        <Translate value={"workshopLoyaltyProgram.confirm"} />
                    </Button>
                    <Button
                        className="main-btn"
                        style={{ width: "14.7rem" }}
                        onClick={() => props.setEditMode(false)}
                    >
                        <Translate value={"workshopLoyaltyProgram.cancel"} />
                    </Button>
                </div>
            </div>
        </form>
    )
}
