/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import Steps, {Step} from 'rc-steps';
import React, {Component} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {formValueSelector, getFormValues} from 'redux-form';
import {orderActions} from 'shared/actions/order.actions';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {DURATION_UNITS} from 'shared/components/form/duration/RenderDuration';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {orderConstants} from 'shared/constants/order.constants';
import {userService} from 'shared/services/user.service';
import {history} from 'shared/utils/history';
import {isEmptyArray} from 'shared/utils/utils';
import {ValuationsSubsection} from '../../../workshop/valuations/Valuations';
import WorkshopInactiveStatusAlert from '../../utils/WorkshopInactiveStatusAlert';
import {setAdditionalCost, setLpgService} from '../utils/utils';
import ValuationInquiryResponseFormPage1 from './valuation-inquiry-response-form/page-1/ValuationInquiryResponseFormPage1';
import ValuationInquiryResponseFormPage2 from './valuation-inquiry-response-form/page-2/ValuationInquiryResponseFormPage2';
import ValuationInquiryResponseFormPage3 from './valuation-inquiry-response-form/page-3/ValuationInquiryResponseFormPage3';
import './valuation-inquiry-response.css';
import {ValuationInquirySummary} from './valuation-inquiry-summary/ValuationInquirySummary';
import moment from 'moment';

class ValuationInquiryResponse extends Component {
  valuationInquiryResponseVariants;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      confirmDraftModalOpen: false,
      valuationDraft: null,
      shouldFetchValuationInquiry: true,
      pageTitle: '',
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.renderPagesForLpgOnly = this.renderPagesForLpgOnly.bind(this);
    this.renderPagesForServicesOnly =
      this.renderPagesForServicesOnly.bind(this);
    this.renderPagesForMixed = this.renderPagesForMixed.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.setPageTitle = this.setPageTitle.bind(this);

    this.valuationInquiryResponseVariants = new Map();
    this.valuationInquiryResponseVariants.set('LPG_ONLY', {
      render: this.renderPagesForLpgOnly,
      totalPages: 2,
    });
    this.valuationInquiryResponseVariants.set('SERVICES_ONLY', {
      render: this.renderPagesForServicesOnly,
      totalPages: 2,
    });
    this.valuationInquiryResponseVariants.set('MIXED', {
      render: this.renderPagesForMixed,
      totalPages: 3,
    });
  }

  componentDidMount() {
    const {params} = this.props.match;
    this.forceUpdate();
    this.props.findAllServiceCategories();
    this.props.findLpgServiceCategoryId();
    this.props.findAllPartCategories();
    if (this.props.valuationInquiry)
      this.props.findValuationDraftByUserId(
        userService.getUserId(),
        this.props.valuationInquiry.id
      );
    if (this.checkShouldFetchValuationInquiry())
      this.setState({shouldFetchValuationInquiry: false});
    this.props.findValuationByValuationInquiryNumber(
      params.origin,
      params.inquiryNumber,
      userService.getUserId()
    );
  }

  checkShouldFetchValuationInquiry() {
    const {valuationInquiry} = this.props;
    const {params} = this.props.match;
    return (
      valuationInquiry &&
      valuationInquiry.inquiryNumber.origin === params.origin &&
      valuationInquiry.inquiryNumber.number === params.inquiryNumber
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {match, loggedWorkshopId, valuationInquiry} = this.props;
    if (this.isDraftLoaded(prevProps))
      this.setState({confirmDraftModalOpen: true});
    if (loggedWorkshopId && this.state.shouldFetchValuationInquiry) {
      this.props.findValuationInquiryByInquiryNumberAndWorkshopId(
        match.params.origin,
        match.params.inquiryNumber,
        loggedWorkshopId
      );
      this.setState({shouldFetchValuationInquiry: false});
    }
    if (!prevProps.valuationInquiry && valuationInquiry && valuationInquiry.id)
      this.props.findValuationDraftByUserId(
        userService.getUserId(),
        valuationInquiry.id
      );
  }

  componentWillUnmount() {
    this.props.resetValuationInquiryResponseForm();
    this.props.clearValuationInquiry();
    this.props.clearValuationInquiryResponseFailMsg();
    this.props.clearValuation();
  }

  setPageTitle(pageTitle) {
    this.setState({pageTitle});
  }

  isDraftLoaded(prevProps) {
    return (
      prevProps.valuationDraft !== this.props.valuationDraft &&
      this.props.valuationDraft
    );
  }

  nextPage() {
    this.setState({page: this.state.page + 1});
  }

  previousPage() {
    this.setState({page: this.state.page - 1});
  }

  handleSubmit(values) {
    const preparedValuation = this.prepareValuationBeforeSend(values);
    const {valuation, createValuation, updateValuation, onCreateSuccess} =
      this.props;
    createValuation(
      preparedValuation,
      onCreateSuccess(ValuationsSubsection.WAITING_VALUATIONS)
    );
  }

  prepareValuationBeforeSend(values) {
    const valuation = {...values};
    const {
      valuationInquiry,
      lpgServiceCategoryId,
      serviceCategories,
      currency,
    } = this.props;
    valuation.valuationInquiryId = valuationInquiry.id;
    valuation.expiryDate = moment(valuation.expiryDate)
      .set('hour', 23)
      .toISOString();
    valuation.proposedDate = valuation.proposedDate && moment(valuation.proposedDate)
      .set('hour', 23)
      .toISOString();
    setAdditionalCost(valuation, currency);
    if (valuationInquiry.valuationInquiryType !== 'SERVICES_ONLY')
      setLpgService(
        valuation,
        serviceCategories,
        lpgServiceCategoryId,
        currency
      );
    return valuation;
  }

  cancel() {
    history.push('/workshop/valuations');
  }

  generatePdf() {
    const {values} = this.props;
    const valuation = this.prepareValuationBeforeSend(values);
    this.props.generateValuationPdf(valuation);
  }

  createDraft() {
    const {values, valuationInquiry} = this.props;
    this.props.createValuationDraft(values, valuationInquiry.id);
  }

  renderSteps(stepsCount) {
    return (
      <div>
        <Steps
          className='valuation-inquiry-response-steps'
          labelPlacement='vertical'
          current={this.state.page - 1}>
          {[...new Array(stepsCount)].map((object, index) => (
            <Step key={index} />
          ))}
        </Steps>
        <Steps
          className='valuation-inquiry-response-steps-mobile'
          direction='vertical'
          labelPlacement='horizontal'
          current={this.state.page - 1}>
          {[...new Array(stepsCount)].map((object, index) => (
            <Step key={index} />
          ))}
        </Steps>
      </div>
    );
  }

  resolveInitialValues() {
    const {valuationDraft} = this.state;
    const {initialValues, valuation, lpgServiceCategoryId} = this.props;
    if (valuationDraft) return valuationDraft;
    else return initialValues;
  }

  renderPagesForLpgOnly() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <div>
        {page === 1 && (
          <ValuationInquiryResponseFormPage2
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 2 && (
          <ValuationInquiryResponseFormPage3
            previousPage={this.previousPage}
            onSubmit={this.handleSubmit}
            handleCancel={this.cancel}
            handleGeneratePdf={this.generatePdf}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}
      </div>
    );
  }

  renderPagesForServicesOnly() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <div>
        {page === 1 && (
          <ValuationInquiryResponseFormPage1
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 2 && (
          <ValuationInquiryResponseFormPage3
            previousPage={this.previousPage}
            onSubmit={this.handleSubmit}
            handleCancel={this.cancel}
            handleGeneratePdf={this.generatePdf}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}
      </div>
    );
  }

  renderPagesForMixed() {
    const {page} = this.state;
    const resolvedInitialValues = this.resolveInitialValues();

    return (
      <div>
        {page === 1 && (
          <ValuationInquiryResponseFormPage1
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 2 && (
          <ValuationInquiryResponseFormPage2
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            handleCancel={this.cancel}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}

        {page === 3 && (
          <ValuationInquiryResponseFormPage3
            previousPage={this.previousPage}
            onSubmit={this.handleSubmit}
            handleCancel={this.cancel}
            handleGeneratePdf={this.generatePdf}
            initialValues={resolvedInitialValues}
            handleCreateDraft={this.createDraft}
            currency={this.props.currency}
            setPageTitle={this.setPageTitle}
          />
        )}
      </div>
    );
  }

  renderConfirmDraftModal() {
    const {deleteValuationDraftByUserId, valuationDraft, valuationInquiry} =
      this.props;

    return (
      <ConfirmModal
        title='valuationInquiryResponse.existingDraftQuestion'
        isOpen={
          this.state.confirmDraftModalOpen && !this.state.confirmUpdateModalOpen
        }
        notShowAgainText='valuationInquiryResponse.deleteDraftCheckboxLabel'
        action={notShowAgain => {
          if (notShowAgain)
            deleteValuationDraftByUserId(
              userService.getUserId(),
              valuationInquiry.id
            );
          this.setState({
            confirmDraftModalOpen: false,
            valuationDraft: valuationDraft,
          });
        }}
        handleCancel={notShowAgain => {
          if (notShowAgain)
            deleteValuationDraftByUserId(
              userService.getUserId(),
              valuationInquiry.id
            );
          this.setState({confirmDraftModalOpen: false, valuationDraft: null});
        }}
      />
    );
  }

  renderContent() {
    const {valuationInquiry, valuationInquiryResponseFailMsg, valuation} =
      this.props;
    if (valuationInquiry)
      return (
        <div className='py-4'>
          {this.renderConfirmDraftModal()}
          <ValuationInquirySummary valuationInquiry={valuationInquiry} />
          {this.valuationInquiryResponseVariants
            .get(valuationInquiry.valuationInquiryType)
            .render()}
          {this.renderSteps(
            this.valuationInquiryResponseVariants.get(
              valuationInquiry.valuationInquiryType
            ).totalPages
          )}
        </div>
      );
    else if (valuationInquiryResponseFailMsg)
      return <Translate value={valuationInquiryResponseFailMsg} />;
  }

  render() {
    const {isFetching, shouldShowWorkshopStatusAlert} = this.props;
    const {pageTitle} = this.state;

    return (
      <ContentWrapper
        title={pageTitle}
        contentClass='valuation-inquiry-response-content'
        containerClass='valuation-inquiry-container'>
        <Loadable
          active={isFetching || this.state.shouldFetchValuationInquiry}
          spinner>
          <div className='valuation-inquiry-response-form-wrapper'>
            {shouldShowWorkshopStatusAlert ? (
              <WorkshopInactiveStatusAlert show={true} />
            ) : (
              this.renderContent()
            )}
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

ValuationInquiryResponse.propTypes = {
  onCreateSuccess: PropTypes.func.isRequired,
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
};

const selector = formValueSelector('valuationInquiryResponseForm');

export function prepareServiceFields(
  valuationInquiry,
  categories,
  lpgServiceCategoryId,
  currency
) {
  const services = categories
    ? [
        ...new Set(
          categories.reduce(
            (acc, category) => acc.concat(category.services),
            []
          )
        ),
      ]
    : [];
  return valuationInquiry
    ? valuationInquiry.servicesIds
        .map(serviceId => services.find(service => service.id === serviceId))
        .filter(
          service =>
            service && service.serviceCategoryId !== lpgServiceCategoryId
        )
        .map(service => {
          const serviceCategory = categories.find(
            category => category.id === service.serviceCategoryId
          );
          return {
            serviceCategoryName: serviceCategory.name,
            serviceCategoryId: serviceCategory.id,
            serviceName: service.name,
            serviceId: service.id,
            serviceCost: {value: null, currency: currency},
          };
        })
    : [];
}

function mapStateToProps(state) {
  const {i18n, order, service, workshop} = state;
  const loggedWorkshopId = workshop.data ? workshop.data.workshopId : null;
  const currency =
    workshop.data && workshop.data.workshopDetails
      ? workshop.data.workshopDetails.currency
      : null;
  const {
    valuationInquiry,
    valuationDraft,
    isFetching,
    valuationInquiryResponseFailMsg,
    valuation,
  } = order;
  const {lpgServiceCategoryId} = service;
  const serviceCategories = service.categories;
  const values = getFormValues('valuationInquiryResponseForm')(state);
  const currentServices = selector(state, 'services');
  const initialValues = {
    services: !isEmptyArray(currentServices)
      ? currentServices
      : prepareServiceFields(
          valuationInquiry,
          serviceCategories,
          lpgServiceCategoryId,
          currency
        ),
    durationUnit: DURATION_UNITS.HOURS,
  };

  return {
    i18n,
    valuationInquiry,
    serviceCategories,
    lpgServiceCategoryId,
    isFetching,
    valuationInquiryResponseFailMsg,
    values,
    initialValues,
    valuationDraft,
    loggedWorkshopId,
    valuation,
    currency,
  };
}

const mapDispatchToProps = {
  resetValuationInquiryResponseForm:
    orderActions.resetValuationInquiryResponseForm,
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  findLpgServiceCategoryId: serviceCategoryActions.findLpgServiceCategoryId,
  findAllPartCategories: partCategoryActions.findAllPartCategories,
  createValuation: orderActions.createValuation,
  generateValuationPdf: orderActions.generateValuationPdf,
  findValuationDraftByUserId: orderActions.findValuationDraftByUserId,
  createValuationDraft: orderActions.createValuationDraft,
  deleteValuationDraftByUserId: orderActions.deleteValuationDraftByUserId,
  findValuationInquiryByInquiryNumberAndWorkshopId:
    orderActions.findValuationInquiryByInquiryNumberAndWorkshopId,
  clearValuationInquiry: () => ({
    type: orderConstants.CLEAR_VALUATION_INQUIRY,
  }),
  clearValuationInquiryResponseFailMsg: () => ({
    type: orderConstants.CLEAR_VALUATION_INQUIRY_RESPONSE_FAIL_MSG,
  }),
  clearValuation: () => ({type: orderConstants.CLEAR_VALUATION}),
  findValuationByValuationInquiryNumber:
    orderActions.findValuationByValuationInquiryNumber,
};

const connectedValuationInquiryResponse = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiryResponse);
export {connectedValuationInquiryResponse as ValuationInquiryResponse};
