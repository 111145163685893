/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Dropdown} from 'primereact/dropdown';
import * as React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {countryActions} from 'shared/actions/country.actions';
import {mapCountriesToLabelValueObjects} from 'shared/components/form/country-field/utils';
import './statute-modal.css';

class ChooseCountryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {chosenCountry: null};
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(countryActions.findAll());
  }

  handleOnChange(event) {
    this.setState({chosenCountry: event.value});
  }

  onSave() {
    this.props.createStatuteWithCountry(this.state.chosenCountry);
    this.setState({chosenCountry: null});
  }

  onCancel() {
    this.props.handleCancel();
    this.setState({chosenCountry: null});
  }

  resolveCountryOptions() {
    const {countries} = this.props;
    return mapCountriesToLabelValueObjects(countries);
  }

  render() {
    const {isOpen} = this.props;
    const fixedBtn = {width: '7rem'};
    document.querySelector('body').style.overflowY = 'none';

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={this.props.handleCancel}
          style={{border: 'none'}}>
          <ModalHeader toggle={this.props.handleCancel}>
            <Translate value={'statutes.chooseCountryModalLabel'} />
          </ModalHeader>
          <ModalBody>
          <div className='text-sm-left text-center choose-country'>
              <Dropdown
                value={this.state.chosenCountry}
                options={this.resolveCountryOptions()}
                onChange={this.handleOnChange}
                placeholder={I18n.t('statutes.chooseCountryPlaceholder')}
              />
            </div>
            <div className='text-sm-right text-center mt-3'>
              <Button
                onClick={this.onSave}
                id='confirm-btn'
                className='main-btn'
                style={fixedBtn}
                disabled={!this.state.chosenCountry}>
                <Translate value='statutes.chooseCountrySaveBtn' />
              </Button>
              <Button
                onClick={this.onCancel}
                id='cancel-btn'
                style={fixedBtn}
                className='main-btn'>
                <Translate value='statutes.chooseCountryCancelBtn' />
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({country}) {
  const {countries} = country;
  return {countries};
}

export default connect(mapStateToProps)(ChooseCountryModal);
