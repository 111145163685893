import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Button} from 'reactstrap';
import RenderDatePicker from 'shared/components/form/date-picker/hook-form-version/RenderDatePicker';
import {RenderField} from 'shared/components/form/text-field/hook-form-version/RenderField';
import styles from './PeriodicInspectionsEdit.module.css';
import CheckBoxWithTextArea from 'shared/components/form/checkbox-with-textarea/CheckBoxWithTextArea';
import {useDispatch} from 'react-redux';
import {warrantyActions} from 'shared/actions/warranty.actions';
import {I18n} from 'react-redux-i18n';
import {ReqexpValidationEnum} from 'shared/utils/utils';
import {dateUtils} from 'shared/utils/date.utils';

const PeriodicInspectionsEdit = ({onSave, onCancel, inspectionDraft}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm();
  const dispatch = useDispatch();

  const inspectionDateField = 'inspectionDate';
  const mileageField = 'mileage';
  const nextInspectionDateField = 'nextInspectionDate';
  const workshopWorkerField = 'workshopWorker';
  const workshopNameField = 'workshopName';
  const addressField = 'address';
  const phoneNumberField = 'phoneNumber';
  const nipField = 'nip';
  const regonField = 'regon';
  const workshopWorkerEmailField = 'workshopWorkerEmail';

  const onSubmit = values => {
    const inspection = {...inspectionDraft, ...values};
    inspection.nextInspectionDate = dateUtils.getDateFromInput(inspection.nextInspectionDate);
    inspection.inspectionDate = dateUtils.getDateFromInput(inspection.inspectionDate);
    console.log(inspection)
    dispatch(warrantyActions.fillPeriodicInspection(inspection));

    onSave();
  };

  useEffect(() => {
    if (inspectionDraft) {
      // 1st & 3rd section controls
      setValue(inspectionDateField, inspectionDraft.inspectionDate);
      setValue(mileageField, inspectionDraft.mileage);
      setValue(nextInspectionDateField, inspectionDraft.nextInspectionDate);
      setValue(workshopWorkerField, inspectionDraft.workshopWorker);
      setValue(workshopNameField, inspectionDraft.workshopName);
      setValue(addressField, inspectionDraft.address);
      setValue(phoneNumberField, inspectionDraft.phoneNumber);
      setValue(nipField, inspectionDraft.nip);
      setValue(regonField, inspectionDraft.regon);
      setValue(workshopWorkerEmailField, inspectionDraft.workshopWorkerEmail);

      // 2nd section controls
      setValue('tankInspection', inspectionDraft.tankInspection);
      setValue(
        'tankInspectionDescription',
        inspectionDraft.tankInspectionDescription ?? ''
      );
      setValue('injectionInspection', inspectionDraft.injectionInspection);
      setValue(
        'injectionInspectionDescription',
        inspectionDraft.injectionInspectionDescription ?? ''
      );
      setValue('hermeticInspection', inspectionDraft.hermeticInspection);
      setValue(
        'hermeticInspectionDescription',
        inspectionDraft.hermeticInspectionDescription ?? ''
      );
      setValue(
        'stagControllerInspection',
        inspectionDraft.stagControllerInspection
      );
      setValue(
        'stagControllerInspectionDescription',
        inspectionDraft.stagControllerInspectionDescription ?? ''
      );
      setValue('liquidPhaseFilter', inspectionDraft.liquidPhaseFilter);
      setValue(
        'liquidPhaseFilterDescription',
        inspectionDraft.liquidPhaseFilterDescription ?? ''
      );
      setValue('gasPhaseFilter', inspectionDraft.gasPhaseFilter);
      setValue(
        'gasPhaseFilterDescription',
        inspectionDraft.gasPhaseFilterDescription ?? ''
      );
    }
  }, [inspectionDraft]);

  return (
    <div className={styles.Container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.FirstPart}>
          <Controller
            control={control}
            name={inspectionDateField}
            render={({field: {value, onChange}}) => (
              <RenderDatePicker
                selected={value}
                onChange={onChange}
                error={errors[inspectionDateField]}
                label={I18n.t('warranty.sectionVII.inspectionDate')}
                showClearIcon
              />
            )}
          />

          <RenderField {...register(mileageField, {pattern: ReqexpValidationEnum.ONLY_NUMBERS})} 
            error={errors[mileageField]} 
            label={I18n.t('warranty.sectionVII.millage')} />

          <Controller
            control={control}
            name={nextInspectionDateField}
            render={({field: {value, onChange}}) => (
              <RenderDatePicker
                selected={value}
                onChange={onChange}
                error={errors[nextInspectionDateField]}
                label={I18n.t('warranty.sectionVII.nextInspectionDate')}
                showClearIcon
              />
            )}
          />
        </div>

        <div className={styles.SecondPart}>
        {inspectionDraft.inspectionIndex === 0 && 
          <CheckBoxWithTextArea
            control={control}
            checkName={'tankInspection'}
            textAreaName={'tankInspectionDescription'}
            setValue={setValue}
            error = {errors['tankInspectionDescription']}
            initValue={inspectionDraft.tankInspection}
            label={I18n.t('warranty.sectionVII.tankInspection')}
          />}
          <CheckBoxWithTextArea
            control={control}
            checkName={'injectionInspection'}
            textAreaName={'injectionInspectionDescription'}
            setValue={setValue}
            error = {errors['injectionInspectionDescription']}
            initValue={inspectionDraft.injectionInspection}
            label={I18n.t('warranty.sectionVII.injectionInspection')}
          />
          <CheckBoxWithTextArea
            control={control}
            checkName={'hermeticInspection'}
            textAreaName={'hermeticInspectionDescription'}
            setValue={setValue}
            error = {errors['hermeticInspectionDescription']}
            initValue={inspectionDraft.hermeticInspection}
            label={I18n.t('warranty.sectionVII.hermeticInspection')}
          />
          <CheckBoxWithTextArea
            control={control}
            checkName={'stagControllerInspection'}
            textAreaName={'stagControllerInspectionDescription'}
            setValue={setValue}
            error = {errors['stagControllerInspectionDescription']}
            initValue={inspectionDraft.stagControllerInspection}
            label={I18n.t('warranty.sectionVII.stagControllerInspection')}
          />
          {inspectionDraft.inspectionIndex !== 0 && 
          <CheckBoxWithTextArea
            control={control}
            checkName={'liquidPhaseFilter'}
            textAreaName={'liquidPhaseFilterDescription'}
            setValue={setValue}
            error = {errors['liquidPhaseFilterDescription']}
            initValue={inspectionDraft.liquidPhaseFilter}
            label={I18n.t('warranty.sectionVII.liquidPhaseFilter')}
          />}
          {inspectionDraft.inspectionIndex !== 0 && 
          <CheckBoxWithTextArea
            control={control}
            checkName={'gasPhaseFilter'}
            textAreaName={'gasPhaseFilterDescription'}
            setValue={setValue}
            error = {errors['gasPhaseFilterDescription']}
            initValue={inspectionDraft.gasPhaseFilter}
            label={I18n.t('warranty.sectionVII.gasPhaseFilter')}
          />}
        </div>

        <div className={styles.ThirdPart}>
          <RenderField
            {...register(workshopWorkerField,  {maxLength: 60} )}
            error={errors[workshopWorkerField]} 
            label={I18n.t('warranty.sectionVII.workshopWorker')}
          />
          <RenderField {...register(workshopNameField, { maxLength: 255 })} 
            label={I18n.t('warranty.sectionVII.workshopName')} 
            error={errors[workshopNameField]} 
          />
          <RenderField {...register(addressField, { maxLength: 255 })} 
            error={errors[addressField]} 
            label={I18n.t('warranty.sectionVII.address')}
          />
          <RenderField {...register(phoneNumberField, { maxLength: 20,
            pattern: ReqexpValidationEnum.PHONE_NUMBER })} 
            error ={errors[phoneNumberField]} 
            label={I18n.t('warranty.sectionVII.phoneNumber')} 
          />
          <RenderField {...register(nipField, { 
            pattern: ReqexpValidationEnum.ONLY_NUMBERS,
            maxLength: 10})}
            error={errors[nipField]} 
            label={I18n.t('warranty.sectionVII.nip')} 
          />
          <RenderField {...register(regonField, { 
            pattern: ReqexpValidationEnum.ONLY_NUMBERS,
            maxLength: 9})} 
            error={errors[regonField]} 
            label={I18n.t('warranty.sectionVII.regon')}
          />
          <RenderField {...register(workshopWorkerEmailField, { 
            maxLength: 60,
            pattern: ReqexpValidationEnum.EMAIl})}
            error ={errors[workshopWorkerEmailField]} 
            label={I18n.t('warranty.sectionVII.workshopWorkerEmail')}
          />
        </div>

        <div className={styles.ButtonBox}>
          <Button className='btn main-btn' type='submit'>
            {I18n.t('warranty.sectionVII.submit')}
          </Button>
          <Button className='btn main-btn' onClick={onCancel}>
            {I18n.t('warranty.sectionVII.cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PeriodicInspectionsEdit;
