/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button, Input} from 'reactstrap';
import {carActions} from 'shared/actions/car.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {userService} from 'shared/services/user.service';
import {becameObsolete} from 'shared/utils/sync';
import {rowsQuantityToDisplay} from 'shared/utils/utils';
import CarCreateForm from './car-form/CarCreateForm';
import CarEditForm from './car-form/CarEditForm';
import {Link} from 'react-router-dom';
import {warrantyActions} from 'shared/actions/warranty.actions';

class Cars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      globalFilter: '',
      rows: 10,
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
    this.showDetails = this.showDetails.bind(this);
    this.openCreateCar = this.openCreateCar.bind(this);
    this.createCar = this.createCar.bind(this);
    this.updateCar = this.updateCar.bind(this);
  }

  componentDidMount() {
    this.props.findCarsByUserId(userService.getUserId(), true);
    this.props.findCarBrands();
    this.props.findFuelTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      becameObsolete(
        prevProps.userCarsDataSyncState,
        this.props.userCarsDataSyncState
      )
    )
      this.props.findCarsByUserId(userService.getUserId(), true);
  }

  onRowsChange(e) {
    this.setState({rows: e.value});
    this.dataTable.onPageChange({first: 0, rows: this.state.rows});
  }

  renderRowsDropdown() {
    return (
      <Dropdown
        options={rowsQuantityToDisplay}
        value={this.state.rows}
        style={{height: '100%'}}
        onChange={e => this.onRowsChange(e)}
      />
    );
  }

  renderCreateCarBtn() {
    return (
      <Button
        id='create-car-btn'
        className='main-btn'
        onClick={this.openCreateCar}>
        <Translate value={'car.createCarBtn'} />
      </Button>
    );
  }

  openCreateCar() {
    this.props.showUpdateDataSideBar(
      <CarCreateForm onSubmit={this.createCar} />,
      I18n.t('car.carCreateFormTitle')
    );
  }

  createCar(values) {
    this.props.createCar(this.prepareValuesBeforeSend(values));
  }

  prepareValuesBeforeSend(values) {
    values.model = values.model !== 'other' ? values.model : values.modelOther;
    values.userId = userService.getUserId();
    return values;
  }

  renderSearchInput() {
    return (
      <div className='col-sm-4 col-6 px-1 order-3'>
        <Input
          type='search'
          placeholder={I18n.t('car.searchPlaceholder')}
          className='global-filter'
          onInput={e => this.setState({globalFilter: e.target.value})}
        />
      </div>
    );
  }

  resolveFuelType(fuelType) {
    return fuelType ? I18n.t('fuelType.' + fuelType.toLowerCase()) : fuelType;
  }

  showDetails(event) {
    this.props.findCarById(event.data.carId);
    setTimeout(
      () =>
        this.props.showUpdateDataSideBar(
          <CarEditForm onSubmit={this.updateCar} />,
          I18n.t('car.carEditFormTitle')
        ),
      10
    );
  }

  updateCar(values) {
    const {updateCar, userCar} = this.props;
    updateCar(userCar.carId, this.prepareValuesBeforeSend(values));
  }

  renderCarsTable() {
    const header = (
      <div className='row'>
        <div className='col-sm-1 col-1 mr-auto order-1' />
        {this.renderSearchInput()}
      </div>
    );
    const tableData = this.props.userCars
      ? this.props.userCars.map((userCar, index) => ({
          index: index + 1,
          carId: userCar.carId,
          name: userCar.name,
          registrationNumber: userCar.registrationNumber,
          fuelType: this.resolveFuelType(userCar.fuelType),
          vin: userCar.vin,
        }))
      : [];

    return (
      <DataTable
        value={tableData}
        paginator={true}
        rows={this.state.rows}
        className='data-table-padding-class'
        ref={this.setDatatableRef}
        header={header}
        responsive={true}
        globalFilter={this.state.globalFilter}
        paginatorRight={this.renderCreateCarBtn()}
        onRowClick={this.showDetails}
        emptyMessage={I18n.t('car.emptyPlaceholder')}
        paginatorLeft={this.renderRowsDropdown()}>
        <Column
          field='index'
          className='lp-col'
          header={I18n.t('car.lp')}
          sortable={true}
        />
        <Column field='name' header={I18n.t('car.nameLabel')} sortable={true} />
        <Column
          field='registrationNumber'
          header={I18n.t('car.registrationNumberLabel')}
          sortable={true}
        />
        <Column
          field='fuelType'
          header={I18n.t('car.fuelTypeLabel')}
          sortable={true}
        />
        <Column
          headerStyle={{textAlign: 'center'}}
          bodyStyle={{textAlign: 'center'}}
          field=''
          body={rowData =>
            this.props.warranties.some(
              warranty => warranty.vin === rowData.vin
            ) ? (
              <Link
                to={`/user/warranty/${
                  this.props.warranties.find(
                    warranty => warranty.vin === rowData.vin
                  ).id
                }`}>
                <Button className='main-btn' style={{whiteSpace: 'normal !important'}}>
                  {I18n.t('warranty.warrantyBookButton')}
                </Button>
              </Link>
            ) : (
              <>{I18n.t('warranty.noData')}</>
            )
          }
        />
      </DataTable>
    );
  }

  render() {
    return (
      <ContentWrapper title='sideBarNav.cars'>
        <Loadable
          active={this.props.isFetching && this.props.isWarrantyFetching}
          spinner>
          {this.renderCarsTable()}
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, car, warrantyBook} = state;
  const {isFetching, userCars, userCar, userCarsDataSyncState} = car;
  const warranties = warrantyBook.warranties;
  const isWarrantyFetching = warrantyBook.isFetching;
  return {
    i18n,
    isFetching,
    userCars,
    userCar,
    userCarsDataSyncState,
    warranties,
    isWarrantyFetching,
  };
}

const mapDispatchToProps = {
  getWarrantyBookByVIN: warrantyActions.getWarrantyBookByVIN,
  findCarsByUserId: carActions.findCarsByUserId,
  findCarById: carActions.findCarById,
  findCarBrands: carActions.findCarBrands,
  findFuelTypes: carActions.findFuelTypes,
  createCar: carActions.createCar,
  updateCar: carActions.updateCar,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

const connectedCars = connect(mapStateToProps, mapDispatchToProps)(Cars);
export {connectedCars as Cars};
