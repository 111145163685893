/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, change} from 'redux-form';
import RenderField from '../text-field/RenderField';
import {VoivodeshipsDropdown} from '../../../../sign-up/VoivodeshipsDropdown';
import {I18n} from 'react-redux-i18n';
import PropTypes from 'prop-types';

class StateField extends React.Component {
  fieldProps;

  constructor(props) {
    super(props);
    this.fieldProps = {
      name: 'state',
      required: true,
      icon: 'fa fa-map',
      labelKey: 'signup.voivodeshipLabel',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.formValues &&
      prevProps.formValues.country &&
      prevProps.formValues.country !== this.props.formValues.country
    )
      this.props.dispatch(change(this.props.formName, 'state', null));
  }

  renderStateInput() {
    const {formValues, disabled, inputAlwaysBottom} = this.props;
    if (formValues && (!formValues.country || formValues.country !== 'POLAND'))
      return (
        <Field
          {...this.fieldProps}
          inputDisabled={disabled}
          component={RenderField}
          label={I18n.t('state.stateLabel')}
          inputAlwaysBottom={inputAlwaysBottom}
        />
      );
    else
      return (
        <Field
          component={VoivodeshipsDropdown}
          disabled={disabled}
          {...this.fieldProps}
          inputAlwaysBottom={inputAlwaysBottom}
        />
      );
  }

  render() {
    return <Fragment>{this.renderStateInput()}</Fragment>;
  }
}

StateField.propTypes = {
  formName: PropTypes.string.isRequired,
};

function mapStateToProps(state, componentProps) {
  return {
    i18n: state.i18n,
    formValues: getFormValues(componentProps.formName)(state),
  };
}

export default connect(mapStateToProps)(StateField);
