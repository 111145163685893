/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {carConstants} from '../constants/car.constants';
import {SyncState} from '../utils/sync';

export function carReducer(state = {}, action) {
  switch (action.type) {
    case carConstants.CAR_REQUEST_START:
      return {...state, isFetching: true};
    case carConstants.CAR_REQUEST_END:
      return {...state, isFetching: false};
    case carConstants.LOAD_FUEL_TYPES:
      return {...state, fuelTypes: action.payload.data};
    case carConstants.LOAD_CAR_BRANDS:
      return {...state, carBrands: action.payload.data};
    case carConstants.LOAD_USER_CARS:
      return {
        ...state,
        userCars: action.payload.data,
        userCarsDataSyncState: SyncState.UP_TO_DATE,
      };
    case carConstants.LOAD_USER_CAR:
      return {...state, userCar: action.payload.data};
    case carConstants.REFRESH_USER_CARS_DATA:
      return {...state, userCarsDataSyncState: SyncState.OBSOLETE};
    default:
      return state;
  }
}
