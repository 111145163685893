/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2023 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { Fragment } from "react";
import { GenericModal } from "../shared/components/modal/GenericModal";
import { Button } from "reactstrap";
import "./home-page.css";
import { Translate } from "react-redux-i18n";

export class LoginWarningPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }

  renderContent() {
    return (
      <Fragment>
        <div className="text-sm-right text-center mt-3">
          <div className="text-center">
            Aby dołączyć do Programu lojalnościowego STAG najpierw ZAREJESTRUJ
            SIĘ jako warsztat.
          </div>
          <div className="text-center mt-3">
            Do rejestracji KONIECZNIE użyj adresu email, którego używasz w
            systemie e - homologacji AC SA.
          </div>
          <div className="text-center mt-3">
            Jeśli użyjesz innego adresu email, punkty nie będą się naliczały.
          </div>
          <div className="text-center my-3">
            Po zarejestrowaniu się do Programu, będziesz mógł logować się do
            swojego indywidualnego konta.
          </div>
          <Button
            id="propose-appointment-date-modal"
            className="m-1 main-btn"
            onClick={() => this.setState({ isOpen: false })}
          >
            <Translate value="order.confirmBtn" />
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <GenericModal
        content={this.renderContent()}
        isOpen={this.state.isOpen}
        title="WAŻNE!"
      />
    );
  }
}

export default LoginWarningPopup;
