/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const statuteConstants = {
  STATUTE_REQUEST_START: 'STATUTE_REQUEST_START',
  STATUTE_REQUEST_END: 'STATUTE_REQUEST_END',

  REFRESH_STATUTES_DATA: 'REFRESH_STATUTES_DATA',

  LOAD_STATUTES: 'LOAD_STATUTES',
  LOAD_STATUTE: 'LOAD_STATUTE',

  LOYALTY_STATUTE_REQUEST_START: 'LOYALTY_STATUTE_REQUEST_START',
  LOYALTY_STATUTE_REQUEST_END: 'LOYALTY_STATUTE_REQUEST_END',
  LOYALTY_STATUTE: 'LOYALTY_STATUTE',
  LOYALTY_STATUTE_MODAL_OPEN: 'LOYALTY_STATUTE_MODAL_OPEN',

  UPDATE_LOYALTY_STATUTE: 'UPDATE_LOYALTY_STATUTE'
};
