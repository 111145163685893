/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {getMimeTypeFromFileName} from '../file-upload/utils/file-utils';
import PropTypes from 'prop-types';
import documentIcon from '../../../../assets/images/document-icon.png';

class FileDownload extends React.Component {
  render() {
    const {attachment} = this.props;

    return (
      <div className='my-3'>
        <span>
          <img
            src={documentIcon}
            width={60}
            height={50}
            alt=''
            className='mr-1'
          />
          <a
            href={`data:${getMimeTypeFromFileName(attachment.name)};base64,${
              attachment.content
            }`}
            target='_blank'
            style={{color: '#007bff'}}>
            {attachment.name}
          </a>
        </span>
      </div>
    );
  }
}

FileDownload.propTypes = {
  attachment: PropTypes.object.isRequired,
};

export default FileDownload;
