/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {messageActions} from './message.actions';
import {orderRatingService} from '../services/order-rating.service';
import {orderRatingConstants} from '../constants/order-rating.constants';

export const orderRatingActions = {
  createOrderRating,
  findAllOwnedForTargetCustomer,
  clearAll,
  existsOwnedForOrder,
};

function createOrderRating(orderId, rating, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_START});

    return orderRatingService.createOrderRating(orderId, rating).then(
      response => {
        dispatch(messageActions.successMessage('orderRating.createSuccessMsg'));
        onSuccess();
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('orderRating.createErrorMsg'));
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
        return Promise.reject(error);
      }
    );
  };
}

function findAllOwnedForTargetCustomer(customerId) {
  return dispatch => {
    dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_START});

    return orderRatingService.findAllOwnedForTargetCustomer(customerId).then(
      orderRatings => {
        dispatch({
          type: orderRatingConstants.LOAD_ORDER_RATINGS,
          payload: orderRatings,
        });
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('orderRating.fetchErrorMsg'));
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
      }
    );
  };
}

function clearAll() {
  return dispatch => dispatch({type: orderRatingConstants.CLEAR_ALL});
}

function existsOwnedForOrder(orderId) {
  return dispatch => {
    dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_START});

    return orderRatingService.existsOwnedForOrder(orderId).then(
      existsOwnedForOrder => {
        dispatch({
          type: orderRatingConstants.LOAD_EXISTS_OWNED_FOR_ORDER,
          payload: existsOwnedForOrder,
        });
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('orderRating.fetchErrorMsg'));
        dispatch({type: orderRatingConstants.ORDER_RATING_REQUEST_END});
      }
    );
  };
}
