/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';

export default class SubmitButton extends React.Component {
  render() {
    const {disabled} = this.props;
    return (
      <div className='row mt-3'>
        <div
          className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0'
          style={{whiteSpace: 'nowrap'}}
        />
        <div className='col-lg-9 col-12'>
          <div id={Math.random()} className='text-right'>
            <Button
              size='sm'
              className='main-btn'
              type='submit'
              disabled={disabled}>
              <Translate value='buttons.saveBtn' />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
  disabled: false,
};
