/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {Field} from 'redux-form';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {ConfirmModal} from 'shared/components/modal/ConfirmModal';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';

export class ServiceCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {confirmDeleteModalOpen: false};
  }

  renderConfirmDeleteModal() {
    return (
      <ConfirmModal
        title='confirmModal.delete'
        isOpen={this.state.confirmDeleteModalOpen}
        action={() =>
          this.props.handleDelete(this.props.selectedServiceCategoryId)
        }
        handleCancel={() => this.setState({confirmDeleteModalOpen: false})}
      />
    );
  }

  renderDeleteSection() {
    return (
      <div
        className='text-sm-right text-center'
        style={{display: 'inline-block'}}>
        {this.renderConfirmDeleteModal()}
        <Button
          id='service-edit-form-delete-btn'
          className='btn-danger'
          onClick={() => this.setState({confirmDeleteModalOpen: true})}>
          <Translate value={'services.deleteBtn'} />
        </Button>
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      invalid,
      handleDelete,
      hasServices,
      editable,
      privateServicesRestriction,
    } = this.props;

    return (
      <div className='service-edit-form px-5 pb-2'>
        <form onSubmit={handleSubmit}>
          {privateServicesRestriction && (
            <Alert color='warning' className='mt-5 text-center'>
              <Translate value='services.newWorkshopCategoryTip' />
            </Alert>
          )}
          <Field
            name='name'
            type='text'
            component={renderField}
            required={true}
            label={I18n.t('services.nameLabel')}
            icon='fa fa-briefcase'
            inputDisabled={!editable}
          />
          <Field
            name='description'
            type='text'
            component={RenderTextArea}
            required={false}
            label={I18n.t('services.descriptionLabel')}
            icon='fa fa-info'
            inputDisabled={!editable}
          />

          {editable && (
            <div className='text-sm-right text-center mt-2'>
              <Button
                id='service-edit-form-submit-btn'
                className='main-btn'
                style={fixedBtn}
                type='submit'
                disabled={invalid}>
                <Translate value={'services.saveBtn'} />
              </Button>
              <Button
                id='service-edit-form-cancel-btn'
                className='main-btn'
                style={fixedBtn}
                onClick={() =>
                  this.props.dispatch({type: updateDataSideBarConstants.HIDE})
                }>
                <Translate value={'services.cancelBtn'} />
              </Button>
              {handleDelete && !hasServices && this.renderDeleteSection()}
            </div>
          )}
        </form>
      </div>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};
