/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateRequiredFieldsWithMaxLength} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.firstName = validateRequiredFieldsWithMaxLength(values.firstName, 20);
  errors.lastName = validateRequiredFieldsWithMaxLength(values.lastName, 20);
  return errors;
};
