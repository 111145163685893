/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {validate} from './validators';
import ValuationInquiryFormSchemaPage3 from './ValuationInquiryFormSchemaPage3';

const ValuationInquiryFormPage3 = reduxForm({
  form: 'valuationInquiryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage3);

function mapStateToProps(state) {
  const {i18n} = state;
  const title = 'valuationInquiry.addressDataTitle';

  return {i18n, title};
}

export default connect(mapStateToProps)(ValuationInquiryFormPage3);
