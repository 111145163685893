/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import moment from 'moment';

export class ValuationModel {
  services;
  valuationInquiryId;
  additionalInfo;
  additionalCost;
  paymentTypes;
  attachment;
  workshopWorkerId;
  expiryDate;
  proposedDate;

  constructor(formValues) {
    this.services = formValues.services;
    this.valuationInquiryId = formValues.valuationInquiryId;
    this.additionalInfo = formValues.additionalInfo;
    this.paymentTypes = formValues.paymentTypes;
    this.attachment = formValues.attachment;
    this.durationValue = formValues.durationValue;
    this.durationUnit = formValues.durationUnit;
    this.workshopWorkerId = formValues.workshopWorkerId;
    this.additionalCost = formValues.additionalCost;
    this.expiryDate = moment(formValues.expiryDate, 'YYYY-MM-DD').toISOString();
    this.proposedDate = formValues.proposedDate? moment(
      formValues.proposedDate,
      'YYYY-MM-DD'
    ).toISOString() : null
  }
}
