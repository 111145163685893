/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import CodeScannerBox from './CodeScannerBox';
import styles from './CodeScanner.module.css';

export const CodeScanner = () => {
  return (
    <div className={styles.Container}>
      <CodeScannerBox />
    </div>
  );
};
