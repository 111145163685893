/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';
import {pdfRequestConfig} from '../utils/pdf/pdf-utils';

export const orderService = {
  createValuationInquiry,
  findAllValuationInquiries,
  findValuationInquiryById,
  createValuation,
  createValuationForDirectClient,
  findAllWaitingValuationInquiriesByWorkshopId,
  findAllNoResponseValuationInquiriesByWorkshopId,
  findAllValuations,
  generatePdf,
  generatePdfById,
  findValuationById,
  createDraft,
  findDraftByUserId,
  deleteDraftByUserId,
  findValuationInquiryByInquiryNumberAndWorkshopId,
  findValuationByValuationInquiryNumber,
  findAllValuationsByInquiryNumber,
  findValuationByValuationNumber,
  confirmOrder,
  cancelOrder,
  chooseValuation,
  calculateUserLocalTimeOffset,
  findAllOrdersByUserId,
  findOrderById,
  proposeAppointmentDate,
  confirmAppointmentDate,
  completeOrder,
  getOrderMinCancelDate,
};

function createValuationInquiry(values, shouldCreateCar) {
  return axiosService
    .post(
      `/order/valuation-inquiries?shouldCreateCar=${shouldCreateCar}`,
      values
    )
    .then(response => {
      return response;
    });
}

function findAllValuationInquiries(search = '') {
  const url = search
    ? `/order/valuation-inquiries?search=${search}`
    : `/order/valuation-inquiries`;
  return axiosService.get(url).then(response => {
    return response;
  });
}

function findValuationInquiryById(valuationInquiryId) {
  return axiosService
    .get(`/order/valuation-inquiries/${valuationInquiryId}`)
    .then(response => {
      return response;
    });
}

function createValuation(values) {
  return axiosService.post('/order/valuations', values).then(response => {
    return response;
  });
}

function createValuationForDirectClient(values) {
  return axiosService
    .post('/order/valuations/direct-clients', values)
    .then(response => {
      return response;
    });
}

function findAllWaitingValuationInquiriesByWorkshopId(workshopId) {
  return axiosService
    .get(`/order/valuation-inquiries/filter/waiting?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}

function findAllNoResponseValuationInquiriesByWorkshopId(workshopId) {
  return axiosService
    .get(
      `/order/valuation-inquiries/filter/no-response?workshopId=${workshopId}`
    )
    .then(response => {
      return response;
    });
}

function findAllValuations(search = '') {
  const url = search
    ? `/order/valuations?search=${search}`
    : `/order/valuations`;
  return axiosService.get(url).then(response => {
    return response;
  });
}

function generatePdf(values, resourcePath) {
  const userTimeOffset = calculateUserLocalTimeOffset();
  return axiosService
    .post(
      `/order/${resourcePath}/pdf?userTimeOffset=${userTimeOffset}`,
      values,
      pdfRequestConfig
    )
    .then(response => {
      return response;
    });
}

function generatePdfById(objectId, resourcePath) {
  const userTimeOffset = calculateUserLocalTimeOffset();
  return axiosService
    .get(
      `/order/${resourcePath}/pdf/${objectId}?userTimeOffset=${userTimeOffset}`,
      pdfRequestConfig
    )
    .then(response => {
      return response;
    });
}

function findValuationById(valuationId) {
  return axiosService.get(`/order/valuations/${valuationId}`).then(response => {
    return response;
  });
}

function createDraft(values, resourcePath, pathSuffix = '') {
  return axiosService
    .post(`/order/${resourcePath}/drafts${pathSuffix}`, values)
    .then(response => {
      return response;
    });
}

function findDraftByUserId(userId, resourcePath, pathSuffix = '') {
  return axiosService
    .get(`/order/${resourcePath}/drafts${pathSuffix}?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function deleteDraftByUserId(userId, resourcePath, pathSuffix = '') {
  return axiosService
    .delete(`/order/${resourcePath}/drafts${pathSuffix}?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function findValuationInquiryByInquiryNumberAndWorkshopId(
  origin,
  inquiryNumber,
  workshopId
) {
  return axiosService
    .get(
      `/order/valuation-inquiries/${origin}/${inquiryNumber}?workshopId=${workshopId}`
    )
    .then(response => {
      return response;
    });
}

function findValuationByValuationInquiryNumber(origin, inquiryNumber) {
  return axiosService
    .get(
      `/order/valuations/filter/inquiryNumber?origin=${origin}&number=${inquiryNumber}`
    )
    .then(response => {
      return response;
    });
}

function findAllValuationsByInquiryNumber(origin, inquiryNumber) {
  return axiosService
    .get(`/order/valuations/${origin}/${inquiryNumber}`)
    .then(response => {
      return response;
    });
}

function findValuationByValuationNumber(valuationNumber) {
  const {number, inquiryNumberDto} = valuationNumber;
  return axiosService
    .get(
      `/order/valuations/${inquiryNumberDto.origin}/${inquiryNumberDto.number}/${number}`
    )
    .then(response => {
      return response;
    });
}

function calculateUserLocalTimeOffset() {
  let currentDate = new Date();
  return (currentDate.getTimezoneOffset() / 60) * -1;
}

function calculateLocalTimeOffsetFromDate(date) {
  let currentDate = new Date(date);
  return (currentDate.getTimezoneOffset() / 60) * -1;
}

function confirmOrder(orderId) {
  return axiosService
    .post(`/order/orders/${orderId}/confirm`)
    .then(response => {
      return response;
    });
}

function cancelOrder(orderId) {
  return axiosService.put(`/order/orders/${orderId}/cancel`).then(response => {
    return response;
  });
}

function chooseValuation(valuationId, body) {
  return axiosService
    .put(`/order/valuations/${valuationId}/choose`, body)
    .then(response => {
      return response;
    });
}

function findAllOrdersByUserId(userId, search = '') {
  return axiosService
    .get(`/order/orders?userId=${userId}${search ? '&search=' + search : ''}`)
    .then(response => {
      return response;
    });
}

function findOrderById(orderId) {
  return axiosService.get(`/order/orders/${orderId}`).then(response => {
    return response;
  });
}

function proposeAppointmentDate(orderId, appointmentDateProposal) {
  return axiosService
    .post(`/order/orders/${orderId}/propose-appointment-date`, {
      appointmentDateProposal,
      dateOffset: calculateLocalTimeOffsetFromDate(appointmentDateProposal),
    })
    .then(response => {
      return response;
    });
}

function confirmAppointmentDate(orderId) {
  return axiosService
    .post(`/order/orders/${orderId}/confirm-appointment-date`)
    .then(response => {
      return response;
    });
}

function completeOrder(orderId) {
  return axiosService
    .post(`/order/orders/${orderId}/complete`)
    .then(response => {
      return response;
    });
}

function getOrderMinCancelDate(orderId) {
  return axiosService
    .get(`/order/orders/${orderId}/min-cancel-date`)
    .then(response => {
      return response;
    });
}
