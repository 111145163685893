import React from 'react';
import {Translate} from 'react-redux-i18n';
import styles from './EmptyFieldPlaceholder.module.css';

const EmptyFieldPlaceholder = ({content}) => {
  return (
    <div className={styles.EmptyFieldPlaceholder}>
      <Translate value={content} />
    </div>
  );
};

export default EmptyFieldPlaceholder;