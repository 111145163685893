/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const mapCarsToLabelValueObjects = cars => {
  return cars ? cars.map(car => ({label: car.name, value: car})) : [];
};

export const mapWorkshopsToLabelValueObjects = workshops => {
  return workshops
    ? workshops.map(workshop => ({
        label: workshop.workshopName,
        value: workshop,
      }))
    : [];
};
