import React, { useEffect, useState } from 'react'
import Loadable from 'react-loading-overlay';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import { useDispatch, useSelector } from 'react-redux';
import { statuteActions } from 'shared/actions/statute.actions';
import { apiUrl } from '../../../../environment';
import './Statute.css';

export const Statute = () => {
    const [documentIsFetching, setDocumentIsFetching] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const dispatch = useDispatch()
    const registartionFetching = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);
    const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
    const isFetching = useSelector(state => state.statute.isFetching);

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const onDocumentLoad = ({ numPages }) => {
        setNumPages(numPages);
        setTimeout(() => setDocumentIsFetching(false), 1000);
    }

    useEffect(() => {
        setPageNumber(1);
        dispatch(statuteActions.findLoyaltyProgramStatutes());
    }, [dispatch]);

    return (
        <ContentWrapper title='workshopLoyaltyProgram.statute' scrollY>
            {!isFetching ?
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '1rem' }} className="statute-wrapper">
                    {!loyaltyProgramStatute && <div style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '3rem' }}>
                        <b><Translate value={"workshopLoyaltyProgram.editorStatuteEmptyPlaceholder"} /></b>
                    </div>}
                    {loyaltyProgramStatute &&
                        <>
                            <Loadable active={documentIsFetching || registartionFetching} spinner>
                                <Document
                                    file={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}
                                    onLoadSuccess={(e) => onDocumentLoad(e)}
                                    loading={<div style={{ fontFamily: 'Montserrat-Medium' }}><Translate value={"workshopLoyaltyProgram.pdfLoading"} /></div>}
                                >
                                    <Page scale={windowSize?.innerWidth > 1187 ? 1.4 : (windowSize.innerWidth * 1.4) / 1187} pageNumber={pageNumber} />
                                </Document>
                            </Loadable>
                            <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', margin: '0 1rem 2rem 1rem' }}>
                                    <Translate className='mr-1 read-only-label' value='statutes.page' />
                                    {pageNumber}/{numPages}
                                    <div style={{flexGrow: 2}}>
                                        <Button
                                            id='page-minus'
                                            className='main-btn'
                                            disabled={pageNumber === 1}
                                            onClick={() =>
                                                setPageNumber(pageNumber - 1)
                                            }>
                                            <i className='fa fa-arrow-left' />
                                        </Button>
                                        <Button
                                            id='page-plus'
                                            className='main-btn'
                                            disabled={pageNumber === numPages}
                                            onClick={() =>
                                                setPageNumber(pageNumber + 1)
                                            }>
                                            <i className='fa fa-arrow-right' />
                                        </Button>
                                    </div>
                                    <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
                                        <Button id='download-statute' className='main-btn'>
                                            <Translate value='statutes.download' />
                                        </Button>
                                    </a>
                                </div>
                        </>}
                </div>
                :
                <Loadable active={isFetching}>
                    <div style={{ height: 150 }}></div>
                </Loadable>}
        </ContentWrapper>
    )
}
