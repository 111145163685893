/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {orderService} from '../services/order.service';
import {messageActions} from './message.actions';
import {initialize} from 'redux-form';
import {getErrorMessage, searchCriteriaToString} from '../utils/utils';
import {history} from '../utils/history';
import {orderConstants} from '../constants/order.constants';
import {serviceService} from '../services/service.service';
import {fileUtils} from '../utils/file.utils';
import {workshopService} from '../services/workshop.service';
import {operation, SearchCriteriaModel} from '../model/SearchCriteriaModel';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';

export const orderActions = {
  createValuationInquiry,
  resetValuationInquiryForm,
  resetValuationInquiryResponseForm,
  findValuationInquiryById,
  createValuation,
  findAllValuationInquiryServicesByIds,
  resetValuationDirectClientsForm,
  createValuationForDirectClient,
  findAllWaitingValuationInquiriesByWorkshopId,
  findAllNoResponseValuationInquiriesByWorkshopId,
  findAllValuations,
  generateValuationInquiryPdf,
  generateValuationPdf,
  generateValuationDirectClientsPdf,
  generateValuationInquiryPdfById,
  generateValuationPdfById,
  findValuationById,
  createValuationInquiryDraft,
  findValuationInquiryDraftByUserId,
  deleteValuationInquiryDraftByUserId,
  createValuationDraft,
  findValuationDraftByUserId,
  deleteValuationDraftByUserId,
  createValuationDirectClientDraft,
  findValuationDirectClientDraftByUserId,
  deleteValuationDirectClientDraftByUserId,
  findValuationInquiryByInquiryNumberAndWorkshopId,
  findValuationByValuationInquiryNumber,
  findAllValuationInquiries,
  findAllValuationsByInquiryNumber,
  findValuationByValuationNumber,
  findAllValuationsByInquiryNumberWithWorkshopInfos,
  confirmOrder,
  cancelOrder,
  chooseValuation,
  findAllOrdersForRealizationByUserId,
  findAllOrdersInProgressByUserId,
  findAllFinishedOrdersByUserId,
  findAllArchivedOrdersByUserId,
  findAllOrdersByUserId,
  findOrderById,
  findOrderByIdWithRelatedValuation,
  proposeAppointmentDate,
  confirmAppointmentDate,
  completeOrder,
  getOrderMinCancelDate,
};

function createValuationInquiry(values, shouldCreateCar, isValuationPublic) {
  return dispatch => {
    orderService.createValuationInquiry(values, shouldCreateCar).then(
      valuationInquiry => {
        if (isValuationPublic) {
          setTimeout(
            () =>
              dispatch(
                messageActions.successMessage(
                  'valuationInquiry.publicValuationInquiryCreateSuccessMsg'
                )
              ),
            1000
          );
        } else {
          dispatch(
            messageActions.successMessage(
              'valuationInquiry.valuationInquiryCreateSuccessMsg'
            )
          );
        }

        dispatch(resetValuationInquiryForm());
        history.push('/');
      },
      error => {
        if (error.response) {
          let responseText = JSON.parse(error.response.request.responseText);
          if (
            shouldCreateCar &&
            responseText &&
            responseText.message.includes('createCar')
          ) {
            dispatch(
              messageActions.errorMessage('car.createCarObjectExistsMsg')
            );
          } else {
            dispatch(
              messageActions.errorMessage(
                getErrorMessage(
                  error,
                  'valuationInquiry',
                  'valuationInquiryCreateErrorMsg'
                )
              )
            );
          }
        } else {
          dispatch(
            messageActions.errorMessage(
              getErrorMessage(
                error,
                'valuationInquiry',
                'valuationInquiryCreateErrorMsg'
              )
            )
          );
        }
      }
    );
  };
}

function resetValuationInquiryForm() {
  return initialize('valuationInquiryForm', {});
}

function findAllWaitingValuationInquiriesByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.findAllWaitingValuationInquiriesByWorkshopId(workshopId).then(
      valuationsInquiries => {
        dispatch({
          type: orderConstants.LOAD_VALUATION_INQUIRIES,
          payload: valuationsInquiries,
        });
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('waitingValuations.fetchError'));
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findAllNoResponseValuationInquiriesByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService
      .findAllNoResponseValuationInquiriesByWorkshopId(workshopId)
      .then(
        valuationsInquiries => {
          dispatch({
            type: orderConstants.LOAD_VALUATION_INQUIRIES,
            payload: valuationsInquiries,
          });
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        },
        error => {
          dispatch(messageActions.errorMessage('waitingValuations.fetchError'));
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        }
      );
  };
}

function findAllValuationInquiries(searchCriteria = []) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService
      .findAllValuationInquiries(searchCriteriaToString(searchCriteria))
      .then(
        valuationsInquiries => {
          dispatch({
            type: orderConstants.LOAD_VALUATION_INQUIRIES,
            payload: valuationsInquiries,
          });
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        },
        error => {
          dispatch(messageActions.errorMessage('waitingValuations.fetchError'));
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        }
      );
  };
}

function resetValuationInquiryResponseForm() {
  return initialize('valuationInquiryResponseForm', {});
}

function findValuationInquiryById(valuationInquiryId) {
  return dispatch => {
    orderService
      .findValuationInquiryById(valuationInquiryId)
      .then(valuationInquiry => {
        dispatch({
          type: orderConstants.LOAD_VALUATION_INQUIRY,
          payload: valuationInquiry,
        });
      });
  };
}

function createValuation(values, onCreateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.createValuation(values).then(
      valuation => {
        dispatch(
          messageActions.successMessage(
            'valuationInquiryResponse.valuationCreateSuccessMsg'
          )
        );
        dispatch(resetValuationInquiryResponseForm());
        onCreateSuccess();
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(
              error,
              'valuationInquiryResponse',
              'valuationCreateErrorMsg'
            )
          )
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findAllValuationInquiryServicesByIds(servicesIds = []) {
  if (servicesIds.length === 0)
    return dispatch => {
      dispatch(
        messageActions.errorMessage('valuationInquiryDetails.noServices')
      );
    };
  else
    return dispatch => {
      serviceService
        .findAllServices(servicesIdsToQueryString(servicesIds))
        .then(
          services => {
            dispatch({
              type: orderConstants.LOAD_VALUATION_INQUIRY_SERVICES,
              payload: services,
            });
          },
          error => {
            dispatch(
              messageActions.errorMessage('valuationInquiryDetails.noServices')
            );
          }
        );
    };
}

const servicesIdsToQueryString = servicesIds => {
  let queryString = '?';
  servicesIds.forEach((id, index) => {
    queryString = queryString.concat(
      `serviceIds=${id}${index + 1 === servicesIds.length ? '' : '&'}`
    );
  });
  return queryString;
};

function resetValuationDirectClientsForm() {
  return initialize('valuationDirectClientsForm', {});
}

function createValuationForDirectClient(values, onCreateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.createValuationForDirectClient(values).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'valuationDirectClients.valuationCreateSuccessMsg'
          )
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        onCreateSuccess();
        dispatch(resetValuationDirectClientsForm());
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'valuationDirectClients.valuationCreateErrorMsg'
          )
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findAllValuations(searchCriteria = []) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.findAllValuations(searchCriteriaToString(searchCriteria)).then(
      valuations => {
        dispatch({type: orderConstants.LOAD_VALUATIONS, payload: valuations});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('preparedValuations.fetchError'));
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function generatePdf(values, resourcePath, messageGroupKey) {
  return dispatch => {
    orderService.generatePdf(values, resourcePath).then(
      res => {
        dispatch(
          messageActions.successMessage(
            `${messageGroupKey}.generatePdfSuccessMsg`
          )
        );
        fileUtils.openPdf(res);
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, messageGroupKey, 'generatePdfErrorMsg')
          )
        );
      }
    );
  };
}

function generateValuationInquiryPdf(values) {
  return generatePdf(values, 'valuation-inquiries', 'valuationInquiry');
}

function generateValuationPdf(values) {
  return generatePdf(values, 'valuations', 'valuationInquiryResponse');
}

function generateValuationDirectClientsPdf(values) {
  return generatePdf(
    values,
    'valuations/direct-clients',
    'valuationDirectClients'
  );
}

function generatePdfById(objectId, resourcePath, messageGroupKey) {
  return dispatch => {
    orderService.generatePdfById(objectId, resourcePath).then(
      res => {
        dispatch(
          messageActions.successMessage(
            `${messageGroupKey}.generatePdfSuccessMsg`
          )
        );
        fileUtils.openPdf(res);
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, messageGroupKey, 'generatePdfErrorMsg')
          )
        );
      }
    );
  };
}

function generateValuationInquiryPdfById(objectId) {
  return generatePdfById(objectId, 'valuation-inquiries', 'valuationInquiry');
}

function generateValuationPdfById(objectId) {
  return generatePdfById(objectId, 'valuations', 'valuationInquiryResponse');
}

function findValuationById(valuationId, onError = () => {}) {
  return dispatch => {
    orderService.findValuationById(valuationId).then(
      valuation => {
        dispatch({type: orderConstants.LOAD_VALUATION, payload: valuation});
      },
      error => {
        dispatch(messageActions.errorMessage('valuations.fetchOneError'));
        onError();
      }
    );
  };
}

function createDraft(values, resourcePath, messageGroupKey, pathSuffix = '') {
  return dispatch => {
    orderService.createDraft(values, resourcePath, pathSuffix).then(
      res => {
        dispatch(
          messageActions.successMessage(
            `${messageGroupKey}.createDraftSuccessMsg`
          )
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, messageGroupKey, 'createDraftErrorMsg')
          )
        );
      }
    );
  };
}

function createValuationInquiryDraft(values) {
  return createDraft(values, 'valuation-inquiries', 'valuationInquiry');
}

function createValuationDraft(values, keyId) {
  return createDraft(
    values,
    'valuations',
    'valuationInquiryResponse',
    `/${keyId}`
  );
}

function createValuationDirectClientDraft(values) {
  return createDraft(
    values,
    'valuations/direct-clients',
    'valuationDirectClients'
  );
}

function findDraftByUserId(userId, resourcePath, actionType, pathSuffix = '') {
  return dispatch => {
    orderService
      .findDraftByUserId(userId, resourcePath, pathSuffix)
      .then(valuationInquiryDraft => {
        dispatch({type: actionType, payload: valuationInquiryDraft});
      });
  };
}

function findValuationInquiryDraftByUserId(userId) {
  return findDraftByUserId(
    userId,
    'valuation-inquiries',
    orderConstants.LOAD_VALUATION_INQUIRY_DRAFT
  );
}

function findValuationDraftByUserId(userId, keyId) {
  return findDraftByUserId(
    userId,
    'valuations',
    orderConstants.LOAD_VALUATION_DRAFT,
    `/${keyId}`
  );
}

function findValuationDirectClientDraftByUserId(userId) {
  return findDraftByUserId(
    userId,
    'valuations/direct-clients',
    orderConstants.LOAD_VALUATION_DIRECT_CLIENT_DRAFT
  );
}

function deleteDraftByUserId(
  userId,
  resourcePath,
  messageGroupKey,
  pathSuffix = ''
) {
  return dispatch => {
    orderService.deleteDraftByUserId(userId, resourcePath, pathSuffix).then(
      res => {
        dispatch(
          messageActions.successMessage(
            `${messageGroupKey}.deleteDraftSuccessMsg`
          )
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, messageGroupKey, 'deleteDraftErrorMsg')
          )
        );
      }
    );
  };
}

function deleteValuationInquiryDraftByUserId(userId) {
  return deleteDraftByUserId(userId, 'valuation-inquiries', 'valuationInquiry');
}

function deleteValuationDraftByUserId(userId, keyId) {
  return deleteDraftByUserId(
    userId,
    'valuations',
    'valuationInquiryResponse',
    `/${keyId}`
  );
}

function deleteValuationDirectClientDraftByUserId(userId) {
  return deleteDraftByUserId(
    userId,
    'valuations/direct-clients',
    'valuationDirectClients'
  );
}

function findValuationInquiryByInquiryNumberAndWorkshopId(
  origin,
  inquiryNumber,
  workshopId
) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService
      .findValuationInquiryByInquiryNumberAndWorkshopId(
        origin,
        inquiryNumber,
        workshopId
      )
      .then(
        response => {
          dispatch({
            type: orderConstants.LOAD_VALUATION_INQUIRY,
            payload: response,
          });
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        },
        error => {
          dispatch({
            type: orderConstants.GET_VALUATION_INQUIRY_RESPONSE_FAIL_MSG,
            payload: getErrorMessage(
              error,
              'valuationInquiryResponse',
              'fetchError'
            ),
          });
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        }
      );
  };
}

function findValuationByValuationInquiryNumber(origin, inquiryNumber) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService
      .findValuationByValuationInquiryNumber(origin, inquiryNumber)
      .then(
        response => {
          dispatch({type: orderConstants.LOAD_VALUATION, payload: response});
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        },
        error => {
          dispatch({type: orderConstants.ORDER_REQUEST_END});
        }
      );
  };
}

function findAllValuationsByInquiryNumber(origin, inquiryNumber) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.findAllValuationsByInquiryNumber(origin, inquiryNumber).then(
      response => {
        dispatch({type: orderConstants.LOAD_VALUATIONS, payload: response});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('inquiriesHistory.valuationsFetchError')
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findValuationByValuationNumber(valuationNumber) {
  return dispatch => {
    orderService.findValuationByValuationNumber(valuationNumber).then(
      response => {
        dispatch({type: orderConstants.LOAD_VALUATION, payload: response});
      },
      error => {
        dispatch(
          messageActions.errorMessage('inquiriesHistory.valuationFetchError')
        );
      }
    );
  };
}

function findAllValuationsByInquiryNumberWithWorkshopInfos(
  origin,
  inquiryNumber
) {
  return dispatch => {
    orderService
      .findAllValuationsByInquiryNumber(origin, inquiryNumber)
      .then(valuations =>
        fetchValuationsWithWorkshopInfo(valuations, dispatch)
      );
  };
}

async function fetchValuationsWithWorkshopInfo(valuations, dispatch) {
  try {
    const userWorkshopIds = valuations.data.map(val => val.senderUserId);
    const workshops = await workshopService.findAllByWorkshopUserIds(
      userWorkshopIds
    );
    valuations.data.forEach(
      val => (val.workshopInfo = workshops.data[val.senderUserId])
    );
    dispatch({type: orderConstants.LOAD_VALUATIONS, payload: valuations});
  } catch (error) {
    dispatch(
      messageActions.errorMessage('inquiriesHistory.valuationsFetchError')
    );
  }
}

function confirmOrder(orderId, onUpdateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.confirmOrder(orderId).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'valuationInquiryResponse.updateSuccessMsg'
          )
        );
        dispatch({type: orderConstants.REFRESH_ORDERS_DATA});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
        onUpdateSuccess();
      },
      error => {
        dispatch(
          messageActions.errorMessage('valuationInquiryResponse.updateErrorMsg')
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function cancelOrder(orderId, onUpdateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.cancelOrder(orderId).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'valuationInquiryResponse.updateSuccessMsg'
          )
        );
        dispatch({type: orderConstants.REFRESH_ORDERS_DATA});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
        onUpdateSuccess();
      },
      error => {
        dispatch(
          messageActions.errorMessage('valuationInquiryResponse.updateErrorMsg')
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function chooseValuation(valuationId, body, onUpdateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.chooseValuation(valuationId, body).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'valuationInquiryResponse.chooseSuccessMsg'
          )
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        onUpdateSuccess();
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'valuationInquiryResponse', 'updateErrorMsg')
          )
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findAllOrdersForRealizationByUserId(userId) {
  const searchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'NEW'),
  ];
  return findAllOrdersByUserId(userId, [searchCriteria]);
}

function findAllOrdersInProgressByUserId(userId) {
  const searchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'CONFIRMED'),
  ];
  return findAllOrdersByUserId(userId, [searchCriteria]);
}

function findAllFinishedOrdersByUserId(userId) {
  const completedStatusSearchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'COMPLETED'),
  ];
  return findAllOrdersByUserId(userId, [completedStatusSearchCriteria]);
}

function findAllArchivedOrdersByUserId(userId) {
  const expiredStatusSearchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'EXPIRED'),
  ];
  const canceledStatusSearchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, 'CANCELED'),
  ];
  return findAllOrdersByUserId(userId, [
    expiredStatusSearchCriteria,
    canceledStatusSearchCriteria,
  ]);
}

function findAllOrdersByUserId(userId, searchCriteria) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    return Promise.all(
      searchCriteria.map(criteria =>
        orderService.findAllOrdersByUserId(
          userId,
          searchCriteriaToString(criteria)
        )
      )
    )
      .then(responses => {
        let combinedOrders = [];
        responses.forEach(response =>
          response.data.forEach(order => combinedOrders.push(order))
        );

        dispatch({type: orderConstants.LOAD_ORDERS, payload: combinedOrders});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      })
      .catch(error => {
        dispatch(messageActions.errorMessage('order.fetchError'));
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      });
  };
}

function findOrderById(orderId, onError = () => {}) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});

    orderService.findOrderById(orderId).then(
      order => {
        dispatch({type: orderConstants.LOAD_ORDER, payload: order});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('order.fetchError'));
        onError();
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function findOrderByIdWithRelatedValuation(orderId, onError = () => {}) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});

    orderService.findOrderById(orderId).then(
      response => {
        dispatch({type: orderConstants.LOAD_ORDER, payload: response});
        findValuationById(response.data.valuationId, onError)(dispatch);
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('order.fetchError'));
        onError();
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function proposeAppointmentDate(orderId, appointmentDateProposal) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});

    return orderService
      .proposeAppointmentDate(orderId, appointmentDateProposal)
      .then(
        response => {
          dispatch(
            messageActions.successMessage(
              'order.proposeAppointmentDateSuccessMsg'
            )
          );
          dispatch({type: orderConstants.REFRESH_ORDERS_DATA});
          dispatch({type: orderConstants.ORDER_REQUEST_END});
          dispatch({type: updateDataSideBarConstants.HIDE});
        },
        error => {
          dispatch(
            messageActions.errorMessage('order.proposeAppointmentDateErrorMsg')
          );
          dispatch({type: orderConstants.ORDER_REQUEST_END});
          return Promise.reject(error);
        }
      );
  };
}

function confirmAppointmentDate(orderId) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});

    return orderService.confirmAppointmentDate(orderId).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'order.confirmAppointmentDateSuccessMsg'
          )
        );
        dispatch({type: orderConstants.REFRESH_ORDERS_DATA});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
      },
      error => {
        dispatch(
          messageActions.errorMessage('order.confirmAppointmentDateErrorMsg')
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        return Promise.reject(error);
      }
    );
  };
}

function completeOrder(orderId, onUpdateSuccess) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});
    orderService.completeOrder(orderId).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'valuationInquiryResponse.updateSuccessMsg'
          )
        );
        dispatch({type: orderConstants.REFRESH_ORDERS_DATA});
        dispatch({type: orderConstants.ORDER_REQUEST_END});
        dispatch({type: updateDataSideBarConstants.HIDE});
        onUpdateSuccess();
      },
      error => {
        dispatch(
          messageActions.errorMessage('valuationInquiryResponse.updateErrorMsg')
        );
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}

function getOrderMinCancelDate(orderId) {
  return dispatch => {
    dispatch({type: orderConstants.ORDER_REQUEST_START});

    orderService.getOrderMinCancelDate(orderId).then(
      response => {
        dispatch({
          type: orderConstants.LOAD_ORDER_MIN_CANCEL_DATE,
          payload: response,
        });
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('order.fetchError'));
        dispatch({type: orderConstants.ORDER_REQUEST_END});
      }
    );
  };
}
