/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import ValuationInquiryFormSchemaPage3 from '../../../../../valuation-inquiry/valuation-inquiry-form/page-3/ValuationInquiryFormSchemaPage3';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {validate} from '../../../../../valuation-inquiry/valuation-inquiry-form/page-3/validators';

const ValuationDirectClientsFormPage3 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage3);

function mapStateToProps(state) {
  const {i18n} = state;
  const title = 'valuationDirectClients.addressDataTitle';

  return {i18n, title};
}

export default connect(mapStateToProps)(ValuationDirectClientsFormPage3);
