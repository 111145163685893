/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import TextInput from 'shared/components/input/text-input/TextInput';
import {errorColor} from '../../../utils/style.utils';
import './date-time-picker.css';

export class DatePickerInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TextInput
        onClick={this.props.onClick}
        value={this.props.value}
        disabled={this.props.disabled}
        readOnly={true}
        hasErrors={!!this.props.error}
      />
    );
  }
}

class RenderDateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const newDate = (date || moment().startOf('hour')).second(0).millisecond(0);
    this.props.input.onChange(newDate.format());
  }

  render() {
    const {
      input,
      label,
      icon,
      required,
      disabled,
      minDate,
      maxDate,
      meta: {touched, error},
    } = this.props;

    return (
      <div>
        <div className='mt-3 row'>
          <div className='col-12'>
            <span
              className='field-label'
              style={error ? {color: errorColor} : null}>
              {label}
              {required && <span style={{color: errorColor}}>*</span>}
            </span>
          </div>
          <div className='col-12 p-inputgroup date-time-picker-container'>
            <DatePicker
              customInput={<DatePickerInput error={error} />}
              minDate={moment(minDate)}
              maxDate={moment(maxDate)}
              showTimeSelect
              dateFormat='YYYY-MM-DD HH:mm'
              timeFormat='HH:mm'
              timeIntervals={15}
              timeCaption={I18n.t('datePicker.time')}
              minTime={moment('06:00', 'HH:mm')}
              maxTime={moment('18:00', 'HH:mm')}
              selected={moment(input.value)}
              onChange={this.handleChange}
              disabled={disabled}
            />
  
          </div>
        </div>
        <div id='text-area-error-msg'>
          {error && (
            <span className='error-info'>
              {I18n.t(error.key, error.params)}
            </span>
          )}
        </div>
      </div>
    );
  }
}

RenderDateTimePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  meta: PropTypes.shape({touched: PropTypes.bool, error: PropTypes.object}),
};

RenderDateTimePicker.defaultProps = {
  required: false,
  disabled: false,
  minDate: moment().format(),
  maxDate: moment().add(1, 'years').format(),
};

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n};
}

export default connect(mapStateToProps)(RenderDateTimePicker);
