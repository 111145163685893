/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {messageConstants} from '../constants/message.constants';

export function messageReducer(state = {}, action) {
  switch (action.type) {
    case messageConstants.ERROR_MESSAGE:
      return {type: 'error', message: action.message};
    case messageConstants.SUCCESS_MESSAGE:
      return {type: 'success', message: action.message};
    case messageConstants.CLEAR_MESSAGE:
      return {};
    case messageConstants.CUSTOM_MESSAGE:
      // {type: 'success', message: 'message', visibilityDurationSeconds: 10000, shouldUserClose: true}
      return action.payload;
    default:
      return state;
  }
}
