/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {partCategoryActions} from 'shared/actions/part-category.actions';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import 'shared/styles/subsection-tiles.css';
import {ValuationInquiry} from '../../../valuation-inquiry/ValuationInquiry';
import InquiriesInProgress from './inquiries-in-progress/InquiriesInProgress';
import InquiriesHistory from './inquiry-history/InquiriesHistory';
import PreparedValuations from './prepared-valuations/PreparedValuations';

export const ValuationsSubsection = {
  NEW_VALUATION: {
    key: 'NEW_VALUATION',
    divId: 'new-valuation-tile',
    translateValue: 'valuations.newValuationBtn',
  },
  INQUIRIES_IN_PROGRESS: {
    key: 'INQUIRIES_IN_PROGRESS',
    divId: 'inquiries-in-progress-tile',
    translateValue: 'valuations.inquiriesInProgressBtn',
  },
  PREPARED_VALUATIONS: {
    key: 'PREPARED_VALUATIONS',
    divId: 'prepared-valuations-tile',
    translateValue: 'valuations.preparedValuationsBtn',
  },
  INQUIRIES_HISTORY: {
    key: 'INQUIRIES_HISTORY',
    divId: 'inquiries-history-tile',
    translateValue: 'valuations.inquiriesHistoryBtn',
  },
};

class Valuations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valuationsSubsectionKeyActive: ValuationsSubsection.NEW_VALUATION.key,
      isFetching: true,
    };

    this.isActive = this.isActive.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  componentDidMount() {
    this.props.findAllServiceCategories();
    this.props.findLpgServiceCategoryId();
    this.props.findAllPartCategories();
    setTimeout(() => this.setState({isFetching: false}), 500);
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(
          ValuationsSubsection.NEW_VALUATION,
          'fa-question-circle'
        )}
        {this.renderTile(
          ValuationsSubsection.INQUIRIES_IN_PROGRESS,
          'fa-clock-o'
        )}
        {this.renderTile(ValuationsSubsection.PREPARED_VALUATIONS, 'fa-paste')}
        {this.renderTile(
          ValuationsSubsection.INQUIRIES_HISTORY,
          'fa-book',
          false
        )}
      </div>
    );
  }

  renderTile(valuationsSubsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        id={valuationsSubsection.divId}
        className={
          commonClasses + spacingClasses + this.isActive(valuationsSubsection)
        }
        onClick={e => this.setActive(valuationsSubsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={valuationsSubsection.translateValue} />
      </div>
    );
  }

  isActive(valuationsSubsection) {
    return this.state.valuationsSubsectionKeyActive === valuationsSubsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(valuationsSubsection) {
    this.setState({valuationsSubsectionKeyActive: valuationsSubsection.key});
  }

  renderSubsection() {
    switch (this.state.valuationsSubsectionKeyActive) {
      case ValuationsSubsection.NEW_VALUATION.key:
        return <ValuationInquiry isFetching={this.state.isFetching} />;
      case ValuationsSubsection.INQUIRIES_IN_PROGRESS.key:
        return <InquiriesInProgress />;
      case ValuationsSubsection.PREPARED_VALUATIONS.key:
        return <PreparedValuations />;
      case ValuationsSubsection.INQUIRIES_HISTORY.key:
        return <InquiriesHistory />;
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        {this.renderSubsection()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const mapDispatchToProps = {
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  findLpgServiceCategoryId: serviceCategoryActions.findLpgServiceCategoryId,
  findAllPartCategories: partCategoryActions.findAllPartCategories,
};

const connectedValuations = connect(
  mapStateToProps,
  mapDispatchToProps
)(Valuations);
export {connectedValuations as Valuations};
