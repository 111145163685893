/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {composeServicesInCategories} from 'shared/hoc/data-table-core/data-table-core-hoc-utils';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {getComponentDisplayName} from 'shared/hoc/utils/utils.hoc';
import {
  sortNumbersAsc,
  sortNumbersDesc,
} from 'shared/utils/sort-utils/sort.utils';

export const withWorkshopValuationsTableCore = WrappedComponent => {
  const WrappedComponentWithDataTableCore = withDataTableCore(WrappedComponent);

  class ValuationsTableCore extends React.Component {
    constructor(props) {
      super(props);
      this.renderServicesCol = this.renderServicesCol.bind(this);
    }

    renderOriginCol(data) {
      data.origin = I18n.t(
        `valuations.${data.inquiryNumber.origin.toLowerCase()}Origin`
      );
      return data.origin;
    }

    renderCarDataCol(data) {
      data.carData = `${data.carBrand} ${data.carModel} ${data.carProductionYear}`;
      return data.carData;
    }

    renderUserDetailsCol(data) {
      if (!!data.userFirstName && !!data.userLastName)
        data.userDetails = `${data.userFirstName} ${data.userLastName}`;
      else data.userDetails = '-';
      return data.userDetails;
    }

    renderServicesCol(data) {
      const {serviceCategories} = this.props;
      const {servicesIds} = data;
      const composedServicesInCategories = composeServicesInCategories(
        servicesIds,
        serviceCategories
      );
      return composedServicesInCategories.map(cat => (
        <div key={cat.serviceCategoryName}>
          <span style={{fontWeight: 'bold'}}>{cat.serviceCategoryName}: </span>
          {cat.services.map(s => s.name).join(', ')}
        </div>
      ));
    }

    sortByInquiryNumber(valuationInquiries, event) {
      let values = [...valuationInquiries];
      values.sort((data1, data2) =>
        event.order === 1
          ? sortNumbersAsc(
              data1.inquiryNumber.number,
              data2.inquiryNumber.number
            )
          : sortNumbersDesc(
              data1.inquiryNumber.number,
              data2.inquiryNumber.number
            )
      );

      return values;
    }

    render() {
      return (
        <WrappedComponentWithDataTableCore
          {...this.props}
          renderOriginCol={this.renderOriginCol}
          renderCarDataCol={this.renderCarDataCol}
          renderServicesCol={this.renderServicesCol}
          renderUserDetailsCol={this.renderUserDetailsCol}
          sortByInquiryNumber={this.sortByInquiryNumber}
        />
      );
    }
  }

  const mapStateToProps = ({service}) => {
    const {categories} = service;
    return {serviceCategories: categories};
  };

  const connectedComponent = connect(mapStateToProps)(ValuationsTableCore);
  connectedComponent.displayName = `WithValuationsTableCore(${getComponentDisplayName(
    WrappedComponent
  )})`;
  return connectedComponent;
};
