/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Field} from 'redux-form';
import {I18n, Translate} from 'react-redux-i18n';
import {RenderTextArea} from '../../form/text-area/RenderTextArea';
import {RenderRating} from '../../form/rating/RenderRating';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';

export class RatingForm extends React.Component {
  render() {
    const {handleSubmit, handleCancel, invalid} = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name='value'
          component={RenderRating}
          required={true}
          label={I18n.t('rating.valueLabel')}
        />
        <Field
          name='comment'
          component={RenderTextArea}
          required={false}
          label={I18n.t('rating.commentLabel')}
          type='text'
        />

        <div className='text-sm-right text-center mt-3'>
          <Button
            id='rating-form-submit-btn'
            className='m-1 main-btn'
            type='submit'
            disabled={invalid}>
            <Translate value='rating.submitBtn' />
          </Button>
          <Button
            id='rating-form-cancel-btn'
            className='m-1 main-btn'
            onClick={handleCancel}>
            <Translate value='rating.cancelBtn' />
          </Button>
        </div>
      </form>
    );
  }
}

RatingForm.propType = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
};
