/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect} from 'react';
import {RenderDropdown} from '../../dropdown/hook-form-version/RenderDropdown';
import {I18n} from 'react-redux-i18n';
import {useDispatch, useSelector} from 'react-redux';
import {countryActions} from 'shared/actions/country.actions';
import '../country-field.css';
import PropTypes from 'prop-types';
import {mapCountriesToLabelValueObjects} from '../utils';

export const CountryField = props => {
  const {register, watch, errors} = props;
  const field = 'country';
  const dispatch = useDispatch();

  const {countries} = useSelector(state => state.country);

  useEffect(() => {
    dispatch(countryActions.findAll());
  }, []);

  const mappedCountries = mapCountriesToLabelValueObjects(countries);

  return (
    <RenderDropdown
      options={mappedCountries}
      placeholder={I18n.t('renderField.searchPlaceholder')}
      label={I18n.t('country.countryLabel')}
      {...register(`${field}`, {required: true})}
      value={watch(`${field}`)}
      error={errors[`${field}`]}
      required
    />
  );
};

CountryField.propTypes = {
  register: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
};

CountryField.defaultProps = {
  required: true,
};
