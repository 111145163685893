/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {
  change,
  FieldArray,
  FormSection,
  getFormValues,
  reduxForm,
} from 'redux-form';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {resolveCurrencyOptions} from '../utils/utils';
import './distributors-form.css';
import AccessSection from './sections/access-section/AccessSection';
import AddressDataSection from './sections/address-data-section/AddressDataSection';
import DistributorDetailsSection from './sections/distributor-details-section/DistributorDetailsSection';
import FirstSection from './sections/first-section/FirstSection';
import OtherSection from './sections/other-section/OtherSection';
import UserAuthInfoSection from './sections/user-auth-info-section/UserAuthInfoSection';
import {asyncValidate, validate} from './validators/validators';

class CreateDistributorForm extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, form} = this.props;
    if (
      formValues &&
      formValues.userDto &&
      (!prevProps.formValues.userDto ||
        formValues.userDto.email !== prevProps.formValues.userDto.email)
    )
      this.props.dispatch(
        change(
          'createDistributorForm',
          'details.contactEmailAddress',
          formValues.userDto.email
        )
      );
    if (this.props.shouldInitializeNestedPhonePrefixes(prevProps, formValues))
      this.props.initializePhonePrefixesByCountry(
        form,
        formValues.country,
        true
      );
  }

  render() {
    const {handleSubmit, invalid, handleCancel, countries, formValues} =
      this.props;
    const currencyOptions = formValues
      ? resolveCurrencyOptions(countries, formValues.country)
      : null;
    return (
      <form className='distributor-form' onSubmit={handleSubmit}>
        <FirstSection />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.basicDataHeader' />
        </h3>
        <FormSection name='userDto'>
          <UserAuthInfoSection />
        </FormSection>

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.addressDataHeader' />
        </h3>
        <FormSection name='address'>
          <AddressDataSection />
        </FormSection>

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.detailsDataHeader' />
        </h3>
        <FormSection name='details'>
          <DistributorDetailsSection />
        </FormSection>

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.accessHeader' />
        </h3>
        <FieldArray name='userDto.privileges' component={AccessSection} />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='distributors.otherDataHeader' />
        </h3>
        <FormSection name='details'>
          <OtherSection currencyOptions={currencyOptions} />
        </FormSection>

        <div className='row mt-3 justify-content-end'>
          <div className='col-lg-9 col-12'>
            <div id='distributor-buttons' className='text-right'>
              <Button type='submit' disabled={invalid} className='main-btn'>
                <Translate value='distributors.submitCreateBtn' />
              </Button>
              <Button onClick={handleCancel} className='main-btn'>
                <Translate value='distributors.cancelBtn' />
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CreateDistributorForm = reduxForm({
  form: 'createDistributorForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['userDto.email', 'details.contactEmailAddress'],
})(CreateDistributorForm);

function mapStateToProps(state) {
  const {i18n, country} = state;
  const {countries} = country;
  return {
    i18n,
    countries,
    formValues: getFormValues('createDistributorForm')(state),
  };
}

//export for tests
export const CreateDistributorFormConnectedWithRedux = connect(mapStateToProps)(
  CreateDistributorForm
);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(CreateDistributorForm)
);
