/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import defaultAvatar from '../../../assets/images/default-avatar.png';
import * as React from 'react';
import {Collapse} from 'reactstrap';
import './user-collapse.css';
import {userActions} from '../../actions/user.actions';
import {userService} from '../../services/user.service';
import {Translate} from 'react-redux-i18n';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {SubscriptionInfo} from '../subscription/subscription-info/SubscriptionInfo';

class UserCollapse extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      collapse: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(userActions.loadLoggedUserAvatar());
    this.props.dispatch(userActions.getUserDataById(userService.getUserId()));
  }

  toggle() {
    this.setState(prevState => {
      return {
        collapse: !prevState.collapse,
      };
    });
  }

  render() {
    const {dispatch, user} = this.props;

    return (
      <div className='user-collapse'>
        <span id='span-toggle' onClick={this.toggle}>
          {user && user.loggedUserAvatar ? (
            <img
              src={user.loggedUserAvatar}
              alt='Avatar'
              className='user-avatar'
            />
          ) : (
            <i className='fa fa-4x fa-user' style={{color: '#ffffff'}} />
          )}
          <div>
            <span className='username px-1'>
              {userService.getUserInfo().user_name}
            </span>
            <i
              className='fa fa-chevron-down'
              style={{fontSize: '12px', color: '#ffffff'}}
            />
          </div>
        </span>
        <Collapse isOpen={this.state.collapse}>
          <ul className='user-collapse-ul shadow py-2'>
            {/* {userService.isWorkshop() && (
              <li>
                <SubscriptionInfo />
              </li>
            )} */}
            <li className='clickable' onClick={this.toggle}>
              <NavLink
                to={`/${userService.getRoleName()}/profile`}
                activeClassName='user-collapse-active'>
                <i className='fa fa-user' />
                <Translate value='userProfile.profile' />
              </NavLink>
            </li>
            <li
              id='logout'
              className='clickable'
              onClick={() => dispatch(userActions.logout())}>
              <i className='fa fa-power-off' />{' '}
              <Translate value='userCollapse.logout' />
            </li>
          </ul>
        </Collapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;

  return {user};
}

const connectedUserCollapse = connect(mapStateToProps, null, null, {
  pure: false,
})(UserCollapse);
export {connectedUserCollapse as UserCollapse};
