/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

/*global google*/
import {WorkshopStatusEnum} from 'layout/admin/workshops/utils/utils';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import {statuteActions} from 'shared/actions/statute.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import normalizeNumber from 'shared/components/form/normalizer/number-normalizer';
import renderField from 'shared/components/form/text-field/RenderField';
import {getOrEmptyArray, isEmptyArray} from 'shared/utils/utils';
import {AcceptStatute} from './AcceptStatute';
import RenderGmap from './RenderGmap';
import {validate} from './validators';

class ValuationInquiryFormPage4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {mapKey: 0};
    this.reloadWorkshops = this.reloadWorkshops.bind(this);
  }

  componentDidMount() {
    const {
      findAllStatutesByCountry,
      userCountry,
      directWorkshop,
      setPageTitle,
    } = this.props;

    setPageTitle('valuationInquiry.workshopsDataTitle');

    if (!!directWorkshop) this.initializeDirectWorkshopData();
    else this.fetchBasePosition();

    findAllStatutesByCountry(userCountry);
  }

  componentDidUpdate(prevProps, prevState) {
    const {isFetchingWorkshop, workshops, dispatch} = this.props;
    if (prevProps.isFetchingWorkshop && !isFetchingWorkshop) {
      this.setState({mapKey: Math.random()});
      dispatch(
        change(
          'valuationInquiryForm',
          'workshopsIds',
          workshops.map(w => w.workshopId)
        )
      );
    }
  }

  initializeDirectWorkshopData() {
    const {dispatch, directWorkshop} = this.props;
    this.props.setDirectWorkshop(directWorkshop);
    dispatch(
      change(
        'valuationInquiryForm',
        'latitude',
        directWorkshop.address.latitude.toString()
      )
    );
    dispatch(
      change(
        'valuationInquiryForm',
        'longitude',
        directWorkshop.address.longitude.toString()
      )
    );
    dispatch(change('valuationInquiryForm', 'workshopsDistanceRadius', '1'));
    dispatch(
      change(
        'valuationInquiryForm',
        'workshopsDistanceLocation',
        directWorkshop.workshopName
      )
    );
    dispatch(
      change('valuationInquiryForm', 'workshopsIds', [
        directWorkshop.workshopId,
      ])
    );
    this.setState({mapKey: Math.random()});
  }

  fetchBasePosition() {
    const {dispatch, userCity, userVoivodeship, userCountry} = this.props;
    new google.maps.Geocoder().geocode(
      {address: userCity + ', ' + userVoivodeship + ', ' + userCountry},
      (results, status) => {
        const isLocationPresent =
          !isEmptyArray(results) &&
          results[0] &&
          results[0].geometry &&
          results[0].geometry.location;
        const locationLat = isLocationPresent
          ? results[0].geometry.location.lat().toString()
          : '';
        const locationLng = isLocationPresent
          ? results[0].geometry.location.lng().toString()
          : '';
        dispatch(change('valuationInquiryForm', 'latitude', locationLat));
        dispatch(change('valuationInquiryForm', 'longitude', locationLng));
        this.reloadWorkshops();
      }
    );
  }

  renderChangeDistanceRadiusBtn(additionalClasses) {
    const {workshopsDistanceRadius, directWorkshop} = this.props;
    return (
      <Button
        className={
          'main-btn valuation-inquiry-form-radius-btn ' + additionalClasses
        }
        disabled={!workshopsDistanceRadius || !!directWorkshop}
        onClick={this.reloadWorkshops}>
        <Translate value='valuationInquiry.changeDistanceRadiusBtn' />
      </Button>
    );
  }

  reloadWorkshops() {
    const {
      basePositionLat,
      basePositionLng,
      workshopsDistanceRadius,
      userCountry,
    } = this.props;
    this.props.findWorkshopsByArea(
      basePositionLat,
      basePositionLng,
      workshopsDistanceRadius,
      userCountry
    );
  }

  renderAlert(value, color, param = '') {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <Alert className='mb-0' color={color}>
            <Translate value={'valuationInquiry.' + value} /> {param}
          </Alert>
        </div>
      </div>
    );
  }

  workshopsAsMarkersInfo() {
    const {workshops} = this.props;
    return workshops
      ? workshops.map(w => ({
          position: {
            lat: parseFloat(w.address.latitude),
            lng: parseFloat(w.address.longitude),
          },
          title: w.workshopName,
          icon: 'icon62.png',
          averageRating: w.averageRating,
        }))
      : [];
  }

  render() {
    const {
      previousPage,
      handleSubmit,
      handleCancel,
      invalid,
      basePositionLat,
      basePositionLng,
      workshopsDistanceRadius,
      validStatute,
      workshops,
      handleGeneratePdf,
      handleCreateDraft,
      directWorkshop,
    } = this.props;
    const isBasePositionPresent = !!(basePositionLat && basePositionLng);
    const fixedBtn = {width: '7rem'};
    const workshopsCount = getOrEmptyArray(workshops).length;

    return (
      <form className='valuation-inquiry-form' onSubmit={handleSubmit}>
        {!directWorkshop && (
          <Field
            name='workshopsDistanceRadius'
            component={renderField}
            required={true}
            normalize={normalizeNumber}
            label={I18n.t('valuationInquiry.workshopsDistanceRadiusLabel')}
            icon='fa fa-globe'
            type='text'
            suffixAddon={this.renderChangeDistanceRadiusBtn(
              'd-sm-block d-none ml-3 mr-0'
            )}
            id='workshop-distance-radius-input'
            inputDisabled={!!directWorkshop}
          />
        )}
        <Field
          name='latitude'
          component={props => <div className='d-none' />}
        />

        <Field
          name='longitude'
          component={props => <div className='d-none' />}
        />

        {this.renderChangeDistanceRadiusBtn('mx-auto mt-3 d-sm-none d-block')}

        {isBasePositionPresent &&
          !directWorkshop &&
          this.renderAlert('otherBasePositionTip', 'warning')}
        {!isBasePositionPresent &&
          this.renderAlert('basePositionFetchError', 'danger')}
        {workshopsCount > 0 &&
          !directWorkshop &&
          this.renderAlert('workshopsFoundTip', 'success', workshopsCount)}
        {workshopsCount === 0 &&
          this.renderAlert('workshopsNotFoundError', 'danger')}

        <Loadable active={this.props.isFetchingWorkshop} spinner>
          <RenderGmap
            formName={'valuationInquiryForm'}
            key={this.state.mapKey}
            circleRadiusKm={workshopsDistanceRadius}
            onFormFilled={this.reloadWorkshops}
            additionalMarkersInfo={this.workshopsAsMarkersInfo()}
            searchBoxPlaceholderKey='valuationInquiry.basePositionSearchBoxPlaceholder'
            searchEnabled={!directWorkshop}
          />
        </Loadable>

        <div className='accept-statute-container'>
          <Field
            name='acceptStatute'
            component={AcceptStatute}
            statute={validStatute}
          />
        </div>

        <FieldArray name='workshopsIds' component={props => <div />} />

        <div className='text-sm-left text-center mt-3'>
          <Button className='main-btn' onClick={previousPage}>
            <Translate value='valuationInquiry.previousPageBtn' />
          </Button>
        </div>

        <div className='text-sm-right text-center mt-3'>
          {handleGeneratePdf && (
            <Button
              className='main-btn'
              onClick={handleGeneratePdf}
              disabled={invalid}>
              <Translate value='valuationInquiry.generatePdfBtn' />
            </Button>
          )}
          {handleCreateDraft && (
            <Button className='main-btn' onClick={handleCreateDraft}>
              <Translate value='valuationInquiry.createDraftBtn' />
            </Button>
          )}
          <Button className='main-btn' type='submit' disabled={invalid}>
            <Translate value='valuationInquiry.sendBtn' />
          </Button>
          <Button className='main-btn' onClick={handleCancel}>
            <Translate value='valuationInquiry.cancelBtn' />
          </Button>
        </div>
      </form>
    );
  }
}

ValuationInquiryFormPage4 = reduxForm({
  form: 'valuationInquiryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormPage4);

const selector = formValueSelector('valuationInquiryForm');

function getFilteredWorkshops(state) {
  const workshops = state.workshop.workshops;
  const services = selector(state, 'valuationInquiryServices');
  const servicesIds = getOrEmptyArray(services).map(s => s.serviceId);
  return getOrEmptyArray(workshops)
    .filter(workshop => workshopContainsAllServices(workshop, servicesIds))
    .filter(workshop => workshop.status === WorkshopStatusEnum.ACTIVE);
}

function workshopContainsAllServices(workshop, servicesIds) {
  const workshopServicesIds = !isEmptyArray(workshop.services)
    ? workshop.services.map(service => service.id)
    : [];
  return servicesIds.every(
    serviceId => workshopServicesIds.indexOf(serviceId) >= 0
  );
}

function mapStateToProps(state) {
  const {i18n, workshop, statute} = state;
  const {statutes} = statute;
  const workshops = getFilteredWorkshops(state);
  const isFetchingWorkshop = workshop.isFetching;
  const userCity = selector(state, 'userCity');
  const userCountry = selector(state, 'country');
  const userVoivodeship = selector(state, 'state');
  const basePositionLat = selector(state, 'latitude');
  const basePositionLng = selector(state, 'longitude');
  const workshopsDistanceRadius = selector(state, 'workshopsDistanceRadius');
  const validStatute = getOrEmptyArray(statutes).find(
    s => s.status === 'VALID'
  );

  return {
    i18n,
    userCity,
    userVoivodeship,
    basePositionLat,
    basePositionLng,
    workshopsDistanceRadius,
    workshops,
    isFetchingWorkshop,
    validStatute,
    userCountry,
  };
}

const mapDispatchToProps = {
  findWorkshopsByArea: workshopActions.findWorkshopsByArea,
  findAllStatutesByCountry: statuteActions.findAllStatutesByCountry,
  setDirectWorkshop: workshopActions.setDirectWorkshop,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationInquiryFormPage4);
