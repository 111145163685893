/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateRequiredFields,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.email = validateEmail(values.email);
  errors.password = validatePassword(values.password);
  errors.confirmPassword = validateConfirmPassword({
    password: values.password,
    confirmPassword: values.confirmPassword,
  });
  errors.country = validateRequiredFields(values.country);
  return errors;
};
