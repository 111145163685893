/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const distributorConstants = {
  DISTRIBUTOR_REQUEST_START: 'DISTRIBUTOR_REQUEST_START',
  DISTRIBUTOR_REQUEST_END: 'DISTRIBUTOR_REQUEST_END',
  LOAD_SELECTED_DISTRIBUTOR_DATA: 'LOAD_DISTRIBUTOR_DATA',
  LOAD_DISTRIBUTORS_DATA: 'LOAD_DISTRIBUTORS_DATA',
  REFRESH_DISTRIBUTORS_DATA: 'REFRESH_DISTRIBUTORS_DATA',
  CLEAR_SELECTED_DISTRIBUTOR_DATA: 'CLEAR_SELECTED_DISTRIBUTOR_DATA',
};
