/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {orderRatingConstants} from '../constants/order-rating.constants';

export function orderRatingReducer(state = {}, action) {
  switch (action.type) {
    case orderRatingConstants.ORDER_RATING_REQUEST_START:
      return {...state, isFetching: true};
    case orderRatingConstants.ORDER_RATING_REQUEST_END:
      return {...state, isFetching: false};
    case orderRatingConstants.LOAD_ORDER_RATINGS:
      return {...state, orderRatings: action.payload.data};
    case orderRatingConstants.LOAD_EXISTS_OWNED_FOR_ORDER:
      return {...state, existingOwnedRatingForOrder: action.payload.data};
    case orderRatingConstants.CLEAR_ALL:
      return {isFetching: false};
    default:
      return state;
  }
}
