/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Component} from 'react';
import './side-bar.css';
import {SideBarNavContent} from './SideBarNavContent';

export class SideBarNav extends Component {
  render() {
    return (
      <div className='side-bar-container'>
        <div className='side-bar-nav'>
          <SideBarNavContent />
        </div>
      </div>
    );
  }
}
