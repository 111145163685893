/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {userConstants} from '../constants/user.constants';
import {workshopService} from '../services/workshop.service';
import {messageActions} from './message.actions';
import {userService} from '../services/user.service';
import {workshopConstants} from '../constants/workshop.constants';
import {
  getErrorMessage,
  indexArray,
  searchCriteriaToString,
} from '../utils/utils';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';
import {serviceConstants} from '../constants/service.constants';
import {operation, SearchCriteriaModel} from '../model/SearchCriteriaModel';
import {WorkshopStatusEnum} from 'layout/admin/workshops/utils/utils';
import {initialize} from 'redux-form';

export const workshopActions = {
  createWorkshop,
  createNonPartnerWorkshop,
  findWorkshopByUserId,
  updateWorkshopByUserId,
  deleteWorkshop,
  getAllServicesForWorkshop,
  updateWorkshopServices,
  findWorkshopsByArea,
  findAllWorkshops,
  fetchWorkshops,
  sendInvite,
  acceptInvite,
  findAllWorkshopsByStatus,
  findAllDeletedWorkshops,
  findDeletedWorkshopById,
  findNeededPartsInquiriesByWorkshopId,
  findAllNeededPartsInquiries,
  createNeededPartsInquiry,
  resetNeededPartsInquiryForm,
  changeNeededPartsInquiryStatus,
  updateWorkshopAddress,
  updateWorkshopDetails,
  findAllWorkshopExternalCalendars,
  findWorkshopExternalCalendarById,
  createWorkshopExternalCalendar,
  updateWorkshopExternalCalendar,
  deleteWorkshopExternalCalendar,
  findWorkingDaysByWorkshopId,
  findWorkingDaysByWorkshopUserId,
  updateWorkingDaysByWorkshopId,
  setDirectWorkshop,
  findWorkshopCalendarEvents,
  updateCalendarEventByDelta,
  moveCalendarEvent,
  resizeCalendarEvent,
  updateCalendarEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  getWorkshopWorkload,
  addNewBarcode,
  getAllBarcodes,
  getValidBarcodes,
};

function createWorkshop(data, onSuccessCallback = null) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.createWorkshop(data).then(
      () => {
        if (onSuccessCallback) onSuccessCallback();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(messageActions.successMessage('createWorkshop.successMsg'));
        dispatch({type: workshopConstants.REFRESH_WORKSHOPS_DATA});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'createWorkshop', 'errorMsg')
          )
        );
      }
    );
  };
}

function createNonPartnerWorkshop(data, onSuccessCallback = () => {}) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.createNonPartnerWorkshop(data).then(
      () => {
        onSuccessCallback();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(messageActions.successMessage('createWorkshop.successMsg'));
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'createWorkshop', 'errorMsg')
          )
        );
      }
    );
  };
}

function findAllWorkshopsByStatus(status) {
  if (status === WorkshopStatusEnum.DELETED) return findAllDeletedWorkshops();
  const searchCriteria = [
    new SearchCriteriaModel('status', operation.EQUAL, status),
  ];
  return findAllWorkshops(searchCriteria);
}

// request method for ADMIN and DISTRIBUTOR roles
function findAllWorkshops(searchCriteria = []) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    Promise.all([
      userService.findAllByParams({roleNames: [userConstants.ROLE_WORKSHOP]}),
      // TODO sprawdzić co się dziej e na backendzie i czy user może się do tego odwoływać
      workshopService.findAllWorkshops(searchCriteriaToString(searchCriteria)),
    ]).then(
      ([userRes, workshopRes]) => {
        workshopRes.data = joinData(workshopRes.data, userRes.data);
        dispatch({
          type: workshopConstants.LOAD_WORKSHOPS_DATA,
          payload: workshopRes,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(messageActions.errorMessage('workshops.fetchError'));
      }
    );
  };
}

// request method for rest of roles
function fetchWorkshops(searchCriteria = []) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    Promise.resolve(
      workshopService.findAllWorkshops(searchCriteriaToString(searchCriteria))
    ).then(
      workshopRes => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOPS_DATA,
          payload: workshopRes,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(messageActions.errorMessage('workshops.fetchError'));
      }
    );
  };
}

const joinData = (workshops, users) => {
  workshops.forEach(workshop => {
    workshop.email = findUserEmailByUserId(users, workshop.userId);
  });
  return indexArray(workshops);
};

const findUserEmailByUserId = (users, userId) => {
  let email = '';
  users.some((user, index, array) => {
    if (user.id === userId) {
      email = user.email;
      array.splice(index, 1);
      return true;
    }
  });
  return email;
};

function findWorkshopByUserId(
  userId,
  actionType = workshopConstants.LOAD_WORKSHOP_DATA
) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.findWorkshopByUserId(userId).then(
      response => {
        dispatch({type: actionType, payload: response});
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(messageActions.errorMessage('workshopData.errorMsg'));
      }
    );
  };
}

function updateWorkshopByUserId(userId, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.updateWorkshopByUserId(userId, data).then(
      response => {
        dispatch(
          messageActions.successMessage('selectedWorkshop.successUpdateMsg')
        );
        dispatch({type: workshopConstants.CLEAR_SELECTED_WORKSHOP_DATA});
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_DATA,
          payload: response,
        });
        dispatch({
          type: workshopConstants.LOAD_LOGGED_WORKSHOP_DATA,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch({type: workshopConstants.REFRESH_WORKSHOPS_DATA});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'selectedWorkshop', 'errorUpdateMsg')
          )
        );
      }
    );
  };
}

function deleteWorkshop(workshopId, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.deleteWorkshop(workshopId, data).then(
      response => {
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch(
          messageActions.successMessage('selectedWorkshop.successDeleteMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch({type: workshopConstants.REFRESH_WORKSHOPS_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage('selectedWorkshop.errorDeleteMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function getAllServicesForWorkshop(workshopId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.getAllServicesForWorkshop(workshopId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_SERVICES,
          payload: response,
        });
        dispatch({
          type: serviceConstants.LOAD_SELECTED_SERVICES,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopServices.fetchError'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function updateWorkshopServices(workshopId, data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.updateWorkshopServices(workshopId, data).then(
      response => {
        dispatch({type: workshopConstants.CLEAR_SERVICES});
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_SERVICES,
          payload: response,
        });
        dispatch({
          type: serviceConstants.LOAD_SELECTED_SERVICES,
          payload: response,
        });
        onSuccess();
        dispatch(
          messageActions.successMessage('workshopServices.updateSuccessMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopServices.updateErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findWorkshopsByArea(
  basePositionLat,
  basePositionLng,
  radiusKm,
  country
) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService
      .findWorkshopsByArea(basePositionLat, basePositionLng, radiusKm, country)
      .then(
        response => {
          dispatch({
            type: workshopConstants.LOAD_WORKSHOPS_DATA,
            payload: response,
          });
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        },
        error => {
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
          dispatch(messageActions.errorMessage('workshopData.fetchError'));
        }
      );
  };
}

function setDirectWorkshop(workshop) {
  const workshopData = {data: [workshop]};
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    dispatch({
      type: workshopConstants.LOAD_WORKSHOPS_DATA,
      payload: workshopData,
    });
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
  };
}

function acceptInvite(token, onSuccessCallback) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.acceptInvite(token).then(
      response => {
        dispatch({
          type: workshopConstants.ACCEPT_INVITE_MESSAGE,
          payload: {message: 'success'},
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        onSuccessCallback();
      },
      error => {
        dispatch({
          type: workshopConstants.ACCEPT_INVITE_MESSAGE,
          payload: {message: 'error'},
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function sendInvite(workshopId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.sendInvite(workshopId).then(
      () => {
        dispatch(
          messageActions.successMessage('selectedWorkshop.sendInviteSuccessMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(
          messageActions.errorMessage('selectedWorkshop.sendInviteErrorMsg')
        );
      }
    );
  };
}

function findAllDeletedWorkshops() {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    Promise.all([
      userService.findAllByParams({roleNames: [userConstants.ROLE_WORKSHOP]}),
      workshopService.findAllDeletedWorkshops(),
    ]).then(
      ([userRes, workshopRes]) => {
        workshopRes.data = joinData(workshopRes.data, userRes.data);
        dispatch({
          type: workshopConstants.LOAD_WORKSHOPS_DATA,
          payload: workshopRes,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshops.fetchError'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findDeletedWorkshopById(workshopId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.findDeletedWorkshopById(workshopId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_DATA,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopData.errorMsg'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function updateWorkshopAddress(workshopId, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.updateWorkshopAddress(workshopId, data).then(
      response => {
        dispatch(
          messageActions.successMessage('workshopProfile.successUpdateMsg')
        );
        dispatch({
          type: workshopConstants.SET_UPDATED_ADDRESS,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'workshopProfile', 'errorUpdateMsg')
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function updateWorkshopDetails(workshopId, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.updateWorkshopDetails(workshopId, data).then(
      response => {
        dispatch(
          messageActions.successMessage('workshopProfile.successUpdateMsg')
        );
        dispatch({
          type: workshopConstants.SET_UPDATED_DETAILS,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopProfile.errorUpdateMsg'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function resetNeededPartsInquiryForm() {
  return initialize('neededPartsInquiryForm', {});
}

function createNeededPartsInquiry(values) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.createNeededPartsInquiry(values).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'neededPartsInquiry.inquiryCreateSuccess'
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        dispatch(resetNeededPartsInquiryForm());
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'neededPartsInquiry.inquiryCreateErrorMsg'
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findNeededPartsInquiriesByWorkshopId(workshopId) {
  const searchCriteria = [
    new SearchCriteriaModel('id', operation.EQUAL, workshopId, 'workshop'),
  ];
  return findAllNeededPartsInquiries(searchCriteria);
}

function findAllNeededPartsInquiries(searchCriteria = []) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService
      .findNeededPartsInquiriesByParams(searchCriteriaToString(searchCriteria))
      .then(
        neededPartsInquiries => {
          dispatch({
            type: workshopConstants.LOAD_NEEDED_PARTS_INQUIRIES,
            payload: neededPartsInquiries,
          });
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        },
        error => {
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
          dispatch(
            messageActions.errorMessage('neededPartsInquiry.fetchError')
          );
        }
      );
  };
}

function changeNeededPartsInquiryStatus(neededPartsInquiryId, newStatus) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService
      .changeNeededPartsInquiryStatus(neededPartsInquiryId, newStatus)
      .then(
        response => {
          dispatch(
            messageActions.successMessage(
              'neededPartsInquiry.statusChangeSuccess'
            )
          );
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
          dispatch({type: workshopConstants.REFRESH_NEEDED_PARTS});
        },
        error => {
          dispatch(
            messageActions.errorMessage(
              'neededPartsInquiry.statusChangeErrorMsg'
            )
          );
          dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
        }
      );
  };
}

function findAllWorkshopExternalCalendars(workshopUserId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.findAllWorkshopExternalCalendars(workshopUserId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_EXTERNAL_CALENDARS,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopExternalCalendars.fetchErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findWorkshopExternalCalendarById(id) {
  return dispatch => {
    workshopService.findWorkshopExternalCalendarById(id).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_EXTERNAL_CALENDAR,
          payload: response,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopExternalCalendars.fetchSingleErrorMsg'
          )
        );
      }
    );
  };
}

function createWorkshopExternalCalendar(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.createWorkshopExternalCalendar(data).then(
      response => {
        dispatch({
          type: workshopConstants.REFRESH_WORKSHOP_EXTERNAL_CALENDARS,
        });
        dispatch(
          messageActions.successMessage(
            'workshopExternalCalendars.createSuccessMsg'
          )
        );
        onSuccess();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(
              error,
              'workshopExternalCalendars',
              'createErrorMsg'
            )
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function updateWorkshopExternalCalendar(id, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.updateWorkshopExternalCalendar(id, data).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_EXTERNAL_CALENDAR,
          payload: response,
        });
        dispatch({
          type: workshopConstants.REFRESH_WORKSHOP_EXTERNAL_CALENDARS,
        });
        dispatch(
          messageActions.successMessage(
            'workshopExternalCalendars.updateSuccessMsg'
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(
              error,
              'workshopExternalCalendars',
              'updateErrorMsg'
            )
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function deleteWorkshopExternalCalendar(id, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.deleteWorkshopExternalCalendar(id).then(
      response => {
        dispatch({type: workshopConstants.CLEAR_WORKSHOP_EXTERNAL_CALENDAR});
        dispatch({
          type: workshopConstants.REFRESH_WORKSHOP_EXTERNAL_CALENDARS,
        });
        dispatch(
          messageActions.successMessage(
            'workshopExternalCalendars.deleteSuccessMsg'
          )
        );
        onSuccess();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            'workshopExternalCalendars.deleteErrorMsg'
          )
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findWorkingDaysByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.findWorkingDaysByWorkshopId(workshopId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_WORKING_DAYS,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopWorkingDays.fetchErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findWorkingDaysByWorkshopUserId(workshopUserId) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.findWorkingDaysByWorkshopUserId(workshopUserId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_WORKING_DAYS,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopWorkingDays.fetchErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function updateWorkingDaysByWorkshopId(workshopId, data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.updateWorkingDaysByWorkshopId(workshopId, data).then(
      response => {
        dispatch({type: workshopConstants.REFRESH_WORKSHOP_WORKING_DAYS});
        dispatch(
          messageActions.successMessage('workshopWorkingDays.updateSuccessMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopWorkingDays.updateErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function findWorkshopCalendarEvents(workshopId) {
  return dispatch => {
    workshopService.findWorkshopCalendarEvents(workshopId).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_CALENDAR_EVENTS,
          payload: response,
        });
      },
      error => {
        //Do nothing
      }
    );
  };
}

function moveCalendarEvent(eventId, deltaInMinutes, onError = () => {}) {
  return updateCalendarEventByDelta(
    eventId,
    {updateType: 'MOVE', deltaInMinutes: deltaInMinutes},
    onError
  );
}

function resizeCalendarEvent(eventId, deltaInMinutes, onError = () => {}) {
  return updateCalendarEventByDelta(
    eventId,
    {updateType: 'RESIZE', deltaInMinutes: deltaInMinutes},
    onError
  );
}

function updateCalendarEventByDelta(eventId, data, onError = () => {}) {
  return dispatch => {
    workshopService.updateCalendarEventByDelta(eventId, data).then(
      response => {
        dispatch({
          type: workshopConstants.REPLACE_CALENDAR_EVENT,
          payload: response,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopCalendarEvent.updateErrorMsg')
        );
        onError();
      }
    );
  };
}

function updateCalendarEvent(eventId, data, callback) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.updateCalendarEvent(eventId, data).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'workshopCalendarEvent.updateSuccessMsg'
          )
        );
        if (callback) callback();
        dispatch({type: workshopConstants.REFRESH_WORKSHOP_CALENDAR_EVENTS});
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopCalendarEvent.updateErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function createCalendarEvent(data, onSuccess) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.createCalendarEvent(data).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'workshopCalendarEvent.createSuccessMsg'
          )
        );
        dispatch({type: workshopConstants.REFRESH_WORKSHOP_CALENDAR_EVENTS});
        onSuccess();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopCalendarEvent.createErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function deleteCalendarEvent(eventId, onSuccess) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.deleteCalendarEvent(eventId).then(
      response => {
        dispatch(
          messageActions.successMessage(
            'workshopCalendarEvent.deleteSuccessMsg'
          )
        );
        dispatch({type: workshopConstants.REFRESH_WORKSHOP_CALENDAR_EVENTS});
        onSuccess();
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopCalendarEvent.deleteErrorMsg')
        );
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function getWorkshopWorkload(workshopUserId, start, end) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});

    workshopService.getWorkshopWorkload({workshopUserId, start, end}).then(
      response => {
        dispatch({
          type: workshopConstants.LOAD_WORKSHOP_WORKLOAD,
          payload: response,
        });
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorkload.fetchErrorMsg'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function addNewBarcode(data) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.createBarcode(data).then(
      response => {
        if (response.data) {
          const [BOX, CONTROLLER, INJECTOR] = response.data;
          if (
            [BOX?.result, CONTROLLER?.result, INJECTOR?.result].some(
              value => value === 'USER_DUPLICATE'
            )
          ) {
            dispatch({
              type: userConstants.USER_BARCODE_INFO,
              payload: {
                BOX: {
                  result: BOX?.result,
                  code: BOX?.barcodeValue,
                  barcodeType: 'BOX',
                },
                CONTROLLER: {
                  result: CONTROLLER?.result,
                  code: CONTROLLER?.barcodeValue,
                  barcodeType: 'CONTROLLER',
                },
                INJECTOR: {
                  result: INJECTOR?.result,
                  code: INJECTOR?.barcodeValue,
                  barcodeType: 'INJECTOR',
                },
                showForm: true,
              },
            });
          } else {
            dispatch({
              type: userConstants.USER_BARCODE_INFO,
              payload: {
                BOX: {
                  result: BOX?.result,
                  code: BOX?.barcodeValue,
                  barcodeType: 'BOX',
                },
                CONTROLLER: {
                  result: CONTROLLER?.result,
                  code: CONTROLLER?.barcodeValue,
                  barcodeType: 'CONTROLLER',
                },
                INJECTOR: {
                  result: INJECTOR?.result,
                  code: INJECTOR?.barcodeValue,
                  barcodeType: 'INJECTOR',
                },
                showForm: false,
              },
            });
            dispatch({
              type: workshopConstants.POTENTIAL_SUSPICIOUS_WORKSHOP_CLEAR,
            });
            setTimeout(
              () =>
                dispatch({
                  type: userConstants.USER_BARCODE_INFO_CLEAR,
                }),
              6000
            );
          }
        }
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorkload.fetchErrorMsg'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function getAllBarcodes(params) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.getBarcodes(params).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopConstants.WORKSHOP_LOAD_BARCODES,
            payload: response.data,
          });
        }
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('barcodes.fetchError'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}

function getValidBarcodes(params) {
  return dispatch => {
    dispatch({type: workshopConstants.WORKSHOP_REQUEST_START});
    workshopService.getValidBarcodes(params).then(
      response => {
        if (response.data) {
          dispatch({
            type: workshopConstants.WORKSHOP_LOAD_AC_BARCODES,
            payload: response.data,
          });
        }
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('barcodes.fetchError'));
        dispatch({type: workshopConstants.WORKSHOP_REQUEST_END});
      }
    );
  };
}
