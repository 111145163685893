/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';

export const mapCountriesToLabelValueObjects = countries => {
  return countries
    ? Object.keys(countries).map(country => ({
        label: I18n.t(`country.${country.toLowerCase()}`),
        value: country,
      }))
    : [];
};
