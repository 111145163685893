/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import {Alert, Button} from 'reactstrap';
import {change, Field} from 'redux-form';
import {FadeAndSlide} from 'shared/components/animation/fade-and-slide/FadeAndSlide';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {userService} from 'shared/services/user.service';
import {history} from 'shared/utils/history';
import {getOrEmptyArray, getOrEmptyObj, isEmptyArray} from 'shared/utils/utils';
import '../valuation-inquiry-form.css';

export class RenderServiceSection extends React.Component {
  navigateToServices() {
    history.push('/workshop/services');
  }

  renderNoServicesAlert() {
    return (
      <Alert color='warning' className='text-center mt-3'>
        <div>
          <Translate value='valuationInquiry.noServicesAlert' />
        </div>
        <Button
          className='m-1 main-btn'
          size='sm'
          onClick={this.navigateToServices}>
          <Translate value='valuationInquiry.goToServicesBtn' />
        </Button>
      </Alert>
    );
  }

  isCategoryDepleted(category) {
    const {valuationForm} = this.props;
    const {values} = getOrEmptyObj(valuationForm);
    const {valuationInquiryServices} = getOrEmptyObj(values);
    const categoryServices = getOrEmptyArray(category.services);
    const servicesAlreadyChosenFromCurrentCategory = valuationInquiryServices
      ? valuationInquiryServices.filter(
          service =>
            service.serviceCategoryId === category.id &&
            service.serviceId !== null
        )
      : [];
    return (
      categoryServices.length ===
      servicesAlreadyChosenFromCurrentCategory.length
    );
  }

  areAllCategoriesDepleted() {
    const {categories} = this.props;
    const allCategories = categories ? categories : [];
    let depletedIndex = 0;
    for (let i = 0; i < allCategories.length; i++) {
      if (this.isCategoryDepleted(allCategories[i])) {
        depletedIndex = depletedIndex + 1;
      }
    }
    return depletedIndex === allCategories.length;
  }

  renderServiceCategoryIdSelect(service, index) {
    const {categories, dispatch, fields, isValuationPublic} = this.props;
    const availableCategories = categories
      ? categories.filter(category => !this.isCategoryDepleted(category))
      : categories;
    const selectedCategoryId = fields.get(index).serviceCategoryId;
    const selectedCategory = categories
      ? categories.filter(category => category.id === selectedCategoryId)
      : [];
    const availableOptions =
      !isEmptyArray(selectedCategory) &&
      availableCategories.indexOf(selectedCategory[0]) < 0
        ? availableCategories.concat(selectedCategory[0])
        : availableCategories;
    const options = availableOptions
      ? availableOptions.map(category => ({
          label: category.name,
          value: category.id,
          description: category.description,
        }))
      : [];

    return (
      <div>
        {!isValuationPublic &&
          userService.isWorkshop() &&
          options.length === 0 &&
          this.renderNoServicesAlert()}
        <div className='row'>
          <div className='col'>
            <Field
              name={`${service}.serviceCategoryId`}
              component={RenderDropdown}
              options={options}
              placeholder={I18n.t(
                'valuationInquiry.serviceCategoryIdPlaceholder'
              )}
              label={I18n.t('valuationInquiry.serviceCategoryIdLabel')}
              icon='fa fa-folder'
              required={true}
              itemTemplate={this.itemTemplate}
              onChange={() =>
                dispatch(
                  change(
                    'valuationInquiryForm',
                    'valuationInquiryServices[' + index + ']' + 'serviceId',
                    ''
                  )
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }

  itemTemplate(option) {
    if (!option.description) return option.label;
    else {
      const optionId = 'option-' + option.value;
      return (
        <div className='ui-helper-clearfix'>
          <span
            id={optionId}
            data-tip={option.description}
            data-for={optionId}
            style={{margin: '.5em .25em 0 0'}}>
            {option.label}
          </span>
          <ReactTooltip
            id={optionId}
            className='form-tooltip'
            effect='solid'
            globalEventOff='click'
            place='top'
          />
        </div>
      );
    }
  }

  removeChosenServicesFromAvailableOptions(allOptions) {
    const {valuationForm} = this.props;
    const {values} = getOrEmptyObj(valuationForm);
    const {valuationInquiryServices} = getOrEmptyObj(values);
    const servicesToDisable = getOrEmptyArray(valuationInquiryServices);
    let updatedAvailableOptions = [];
    let availableOptions = allOptions;
    for (let i = 0; i < servicesToDisable.length; i++) {
      updatedAvailableOptions = availableOptions.filter(
        service => service.value !== servicesToDisable[i].serviceId
      );
      availableOptions = updatedAvailableOptions;
    }
    return availableOptions;
  }

  renderServiceIdSelect(service, index) {
    const {categories, fields} = this.props;
    const selectedCategoryId = fields.get(index).serviceCategoryId;
    const selectedCategory = categories
      ? categories.filter(category => category.id === selectedCategoryId)
      : [];
    const selectedCategoryServices = !isEmptyArray(selectedCategory)
      ? selectedCategory[0].services
      : [];
    const allOptions = selectedCategoryServices
      ? selectedCategoryServices.map(service => ({
          label: service.name,
          value: service.id,
          description: service.description,
        }))
      : [];
    let availableOptions =
      this.removeChosenServicesFromAvailableOptions(allOptions);
    const currentlySelectedServiceId = fields.get(index).serviceId;
    const currentlySelectedService = selectedCategoryServices
      .filter(service => service.id === currentlySelectedServiceId)
      .map(service => ({
        label: service.name,
        value: service.id,
        description: service.description,
      }));
    availableOptions = availableOptions.concat(currentlySelectedService);

    return (
      <div className='row'>
        <div className='col'>
          <Field
            name={`${service}.serviceId`}
            component={RenderDropdown}
            options={availableOptions}
            placeholder={I18n.t('valuationInquiry.serviceIdPlaceholder')}
            label={I18n.t('valuationInquiry.serviceIdLabel')}
            itemTemplate={this.itemTemplate}
            icon='fa fa-suitcase'
            required={true}
            disabled={!selectedCategoryId}
          />
        </div>
      </div>
    );
  }

  renderAddServiceSectionBtn(fields, index) {
    return (
      <Button
        className='add-service-section-btn'
        disabled={
          !fields.get(index).serviceId || this.areAllCategoriesDepleted()
        }
        onClick={() => fields.push({})}>
        <Translate value='valuationInquiry.addServiceSectionBtn' />
        <i className='fa fa-plus-circle' />
      </Button>
    );
  }

  renderDeleteServiceSectionBtn(fields, index) {
    return (
      <div className='col-sm-auto px-sm-0 order-sm-1 order-0 text-center'>
        <Button
          className='delete-service-section-btn'
          onClick={() => fields.remove(index)}>
          <i className='fa fa-trash' />
          <Translate value='services.deleteBtn' />
        </Button>
      </div>
    );
  }

  render() {
    const {fields} = this.props;
    return (
      <div className='service-section'>
        <TransitionGroup>
          {fields.map((service, index) => (
            <FadeAndSlide key={index}>
              <div className='service-section-item'>
                {index !== 0 && (
                  <hr style={{marginBottom: 0, borderTopColor: '#CC0000'}} />
                )}
                {this.renderServiceCategoryIdSelect(service, index)}
                {this.renderServiceIdSelect(service, index)}
                <div className='row justify-content-sm-end justify-content-center mt-3 px-sm-3'>
                  {index === fields.length - 1 &&
                    this.renderAddServiceSectionBtn(fields, index)}
                  {index > 0 &&
                    this.renderDeleteServiceSectionBtn(fields, index)}
                </div>
              </div>
            </FadeAndSlide>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}
