/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const workshopLoyaltyProgramService = {
  getWorkshopLoyaltyProgramNotifications,
  updateWorkshopLoyaltyProgramNotification,
  getWorkshopLoyaltyProgramBarNotificationsToShow,
  getWorkshopLoyaltyProgramModalNotificationsToShow,
  markModalNotificationAsRead,
  registerInWorkshopLoyaltyProgram,
  getWorkshopLoyaltyProgramRegistrationData,
  getWorkshopList,
  updateWorkshopDataInWorkshopLoyaltyProgram,
  getScoredProductNames,
  getScoredProductTypes,
  createScoredProduct,
  scoredProductList,
  deleteScoredProduct,
  updateScoredProduct,
  getWorkshopLoyaltyProgramPointsData,
  getLoyaltyConfig,
  createLoyaltyConfig,
  updateLoyaltyConfig,
  workshopLoyaltyProgramHistory,
  createPrizeProduct,
  prizeProductList,
  updatePrizeProduct,
  deletePrizeProduct,
  buyPrizeProducts,
  getWorkshopsWithHistory,
  lockLoyaltyParticipation,
  unlockLoyaltyParticipation,
  loyaltyParticipationLockHistory,
};

function getWorkshopLoyaltyProgramNotifications() {
  return axiosService
    .get('/workshop-loyalty/editable-notifications')
    .then(response => {
      return response;
    });
}

function updateWorkshopLoyaltyProgramNotification(identifier, data) {
  return axiosService
    .put(
      `/workshop-loyalty/editable-notifications/notification/${identifier}`,
      data
    )
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramBarNotificationsToShow(
  userId,
  maxNotificationAgeMillis
) {
  return axiosService
    .get(
      `notification/bar-notifications?userId=${userId}&maxNotificationAgeMilliseconds=${maxNotificationAgeMillis}`
    )
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramModalNotificationsToShow(userId) {
  return axiosService
    .get(`notification/modal-notifications?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function markModalNotificationAsRead(notificationId, userId) {
  return axiosService
    .put(`notification/modal-notifications/${notificationId}?userId=${userId}`)
    .then(response => {
      return response;
    });
}

function registerInWorkshopLoyaltyProgram(data) {
  return axiosService
    .post(`/workshop-loyalty/workshop-loyalty`, data)
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramRegistrationData(workshopId) {
  return axiosService
    .get(`/workshop-loyalty/workshop-loyalty/workshop/${workshopId}`)
    .then(response => {
      return response;
    });
}

function getWorkshopList(params, searchPhrase) {
  return axiosService
    .get(
      `/workshop-loyalty/workshop-loyalty/workshop/search?searchPhrase=${searchPhrase}`,
      {params}
    )
    .then(response => {
      return response;
    });
}

function getWorkshopsWithHistory() {
  return axiosService
    .get(`/workshop-loyalty/workshop-loyalty/workshop/all/history`)
    .then(response => {
      return response;
    });
}

function updateWorkshopDataInWorkshopLoyaltyProgram(workshopId, data) {
  return axiosService
    .put(`/workshop-loyalty/workshop-loyalty/workshop/${workshopId}`, data)
    .then(response => {
      return response;
    });
}

function getScoredProductNames(productTypeId) {
  return axiosService
    .get(`/workshop-loyalty/homologation/type/${productTypeId}/search`)
    .then(response => {
      return response;
    });
}

function getScoredProductTypes() {
  return axiosService
    .get(`/workshop-loyalty/product-type/search`)
    .then(response => {
      return response;
    });
}

function createScoredProduct(data) {
  return axiosService.post(`/workshop-loyalty/product`, data).then(response => {
    return response;
  });
}

function scoredProductList(params) {
  return axiosService
    .get(`/workshop-loyalty/product`, {params})
    .then(response => {
      return response;
    });
}

function deleteScoredProduct(id) {
  return axiosService
    .delete(`/workshop-loyalty/product/${id}`)
    .then(response => {
      return response;
    });
}

function updateScoredProduct(id, product) {
  return axiosService
    .put(`/workshop-loyalty/product/${id}`, product)
    .then(response => {
      return response;
    });
}

function getWorkshopLoyaltyProgramPointsData(workshopId) {
  return axiosService
    .get(
      `/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/loyalty-points`
    )
    .then(response => {
      return response;
    });
}

function getLoyaltyConfig() {
  return axiosService.get('/workshop-loyalty/loyalty-config').then(response => {
    return response;
  });
}

function createLoyaltyConfig(data) {
  return axiosService
    .post('/workshop-loyalty/loyalty-config', data)
    .then(response => {
      return response;
    });
}

function updateLoyaltyConfig(data) {
  return axiosService
    .put('/workshop-loyalty/loyalty-config', data)
    .then(response => {
      return response;
    });
}

function workshopLoyaltyProgramHistory(params, id) {
  return axiosService
    .get(`/workshop-loyalty/workshop-loyalty/workshop/${id}/history`, {params})
    .then(response => {
      return response;
    });
}
function createPrizeProduct(data) {
  return axiosService
    .post(`/workshop-loyalty/prize-product`, data)
    .then(response => {
      return response;
    });
}

function prizeProductList(params) {
  return axiosService
    .get(`/workshop-loyalty/prize-product`, {params})
    .then(response => {
      return response;
    });
}

function deletePrizeProduct(id) {
  return axiosService
    .delete(`/workshop-loyalty/prize-product/${id}`)
    .then(response => {
      return response;
    });
}

function updatePrizeProduct(id, product) {
  return axiosService
    .put(`/workshop-loyalty/prize-product/${id}`, product)
    .then(response => {
      return response;
    });
}

function buyPrizeProducts(workshopId, data) {
  return axiosService
    .put(
      `/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/buy-prize-products `,
      data
    )
    .then(response => {
      return response;
    });
}

function lockLoyaltyParticipation(workshopId) {
  return axiosService
    .put(
      `/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/lock-loyalty-participation`
    )
    .then(response => {
      return response;
    });
}

function unlockLoyaltyParticipation(workshopId) {
  return axiosService
    .put(
      `/workshop-loyalty/workshop-loyalty/workshop/${workshopId}/unlock-loyalty-participation`
    )
    .then(response => {
      return response;
    });
}

function loyaltyParticipationLockHistory(params) {
  return axiosService
    .get(
      `/workshop-loyalty/workshop-loyalty/workshop/all/loyalty-participation-lock-history`,
      {params}
    )
    .then(response => {
      return response;
    });
}
