/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

const getPointsString = (points) => {
  const pointsString = points.toString();
  const lastChar = pointsString.substring(pointsString.length - 1);
  if (points === 1) {
    return 'points1';
  } else if(points === 0) {
    return 'points3';
  } else if (
    (points > 1 && points < 5) ||
    (['2', '3', '4'].includes(lastChar))
  ) {
    return 'points2';
  } else {
    return 'points3';
  }
};

export const stringUtils = {
  getPointsString,
}
