/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {userNotificationConstants} from '../constants/user-notification.constants';
import {getErrorMessage} from '../utils/utils';
import {messageActions} from './message.actions';
import {userNotificationService} from '../services/user-notification.service';

export const userNotificationActions = {
  findRecent,
  countUnread,
  readAll,
  deleteAll,
  deleteById,
};

function requestStart() {
  return {type: userNotificationConstants.REQUEST_START};
}

function requestEnd() {
  return {type: userNotificationConstants.REQUEST_END};
}

function findRecent(userId, onSuccessCallback) {
  return dispatch => {
    dispatch(requestStart());
    userNotificationService.findRecent(userId).then(
      notifications => {
        dispatch({
          type: userNotificationConstants.LOAD_RECENT_NOTIFICATIONS,
          payload: notifications,
        });
        onSuccessCallback();
        dispatch(requestEnd());
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'userNotifications', 'findRecentErrorMsg')
          )
        );
        dispatch(requestEnd());
      }
    );
  };
}

function countUnread(userId, onSuccessCallback) {
  return dispatch => {
    userNotificationService.countUnread(userId).then(
      count => {
        dispatch({
          type: userNotificationConstants.LOAD_UNREAD_NOTIFICATIONS_COUNT,
          payload: count,
        });
        onSuccessCallback();
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'userNotifications', 'countUnreadErrorMsg')
          )
        );
      }
    );
  };
}

function readAll(userId) {
  return dispatch => {
    userNotificationService.readAll(userId).then(
      count => {
        dispatch({
          type: userNotificationConstants.LOAD_UNREAD_NOTIFICATIONS_COUNT,
          payload: 0,
        });
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'userNotifications', 'readAllErrorMsg')
          )
        );
      }
    );
  };
}

function deleteAll(userId) {
  return dispatch => {
    userNotificationService.deleteAll(userId).then(
      count => {
        dispatch({
          type: userNotificationConstants.LOAD_UNREAD_NOTIFICATIONS_COUNT,
          payload: 0,
        });
        dispatch({
          type: userNotificationConstants.LOAD_RECENT_NOTIFICATIONS,
          payload: [],
        });
        dispatch(
          messageActions.successMessage('userNotifications.deleteAllSuccessMsg')
        );
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'userNotifications', 'deleteAllErrorMsg')
          )
        );
      }
    );
  };
}

function deleteById(userId, notificationId, onSuccessCallback) {
  return dispatch => {
    userNotificationService.deleteById(userId, notificationId).then(
      response => {
        dispatch(
          messageActions.successMessage('userNotifications.deleteSuccessMsg')
        );
        onSuccessCallback();
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'userNotifications', 'deleteErrorMsg')
          )
        );
      }
    );
  };
}
