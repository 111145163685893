/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Rating} from 'primereact/rating';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {
  DATE_FORMAT_SHORT,
  dateUtils,
  YEAR_FORMAT,
} from '../../../utils/date.utils';
import {sortByOrderNumber} from '../../../utils/sort-utils/sort.utils';
import {getOrEmptyArray} from '../../../utils/utils';

class OrdersTable extends Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.renderCustomerRating = this.renderCustomerRating.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  prepareOrdersToDisplay(orders) {
    return getOrEmptyArray(orders).map(order => ({
      ...order,
      statusTranslated: I18n.t(`order.${order.status.toLowerCase()}Status`),
      appointmentDateFormatted: this.props.notConfirmedOrders
        ? dateUtils.formatDate(order.appointmentDate) &&
          dateUtils.formatDate(order.appointmentDate, YEAR_FORMAT)
        : dateUtils.formatDate(order.appointmentDate, DATE_FORMAT_SHORT),
      createdDateFormatted: dateUtils.formatDate(
        order.createdDate,
        DATE_FORMAT_SHORT
      ),
    }));
  }

  renderCustomerRating(rowData) {
    const orderRatings = !!rowData.customerRatings
      ? rowData.customerRatings
      : [];
    const average =
      orderRatings && orderRatings.length > 0
        ? orderRatings.map(r => r.value).reduce((a, b) => a + b, 0) /
          orderRatings.length
        : 0;

    return (
      <div className='customer-order-rating'>
        <Rating value={average} readonly={true} cancel={false} />
        <span className='rating-average'>{average.toFixed(1)}</span>
        <span className='ratings-count-info'>
          {`(${I18n.t('valuationInquiryDetails.orderRatings')} - ${
            orderRatings && orderRatings.length
          })`}
        </span>
      </div>
    );
  }

  renderCustomerDetails(data) {
    if (!!data.customerFirstName && data.customerLastName)
      data.customerDetails = `${data.customerFirstName} ${data.customerLastName}`;
    else data.customerDetails = '-';
    return data.customerDetails;
  }

  getAppointmentDateLabel(data) {
    return this.props.notConfirmedOrders ? (
      <Translate value={'orderDetails.appointmentDateProposal'} />
    ) : (
      <Translate value={'orderDetails.appointmentDate'} />
    );
  }

  render() {
    const {orders, onRowClick, showCustomerRating, showCustomerDetails} =
      this.props;
    const ordersToDisplay = this.prepareOrdersToDisplay(orders);

    return (
      <DataTable
        value={ordersToDisplay}
        header={this.props.header}
        rows={this.props.rows}
        ref={this.setDatatableRef}
        globalFilter={this.props.globalFilter}
        paginator={true}
        responsive={true}
        className='p-3'
        emptyMessage={I18n.t('order.emptyPlaceholder')}
        onRowClick={onRowClick}
        paginatorLeft={this.props.renderRowsDropdown()}
      >
        <Column
          field='orderNumber'
          header={I18n.t('order.orderNumber')}
          sortable='custom'
          sortFunction={event => sortByOrderNumber(ordersToDisplay, event)}
        />
        {showCustomerDetails && (
          <Column
            field='customerDetails'
            header={I18n.t('waitingValuations.userDetails')}
            sortable={true}
            body={this.renderCustomerDetails}
          />
        )}
        <Column
          field='statusTranslated'
          header={I18n.t('order.status')}
          sortable={true}
        />
        <Column
          field='appointmentDateFormatted'
          header={this.getAppointmentDateLabel()}
          sortable={true}
        />
        <Column
          field='createdDateFormatted'
          header={I18n.t('order.createdDate')}
          sortable={true}
        />
        {showCustomerRating && (
          <Column
            field='rating'
            header={I18n.t('orderRating.createOrderCustomerRatingModalTitle')}
            body={this.renderCustomerRating}
          />
        )}
      </DataTable>
    );
  }
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
  onRowClick: PropTypes.func,
  showCustomerRating: PropTypes.bool,
  showCustomerDetails: PropTypes.bool,
  notConfirmedOrders: PropTypes.bool,
};

OrdersTable.defaultProps = {
  orders: [],
  onRowClick: () => {},
  showCustomerRating: false,
  showCustomerDetails: false,
  notConfirmedOrders: false,
};

export {OrdersTable as OrdersTableCore};

export default withDataTableCore(OrdersTable);
