/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button, Input} from 'reactstrap';
import {serviceCategoryActions} from 'shared/actions/service-category.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';
import {isEmptyArray} from 'shared/utils/utils';
import ServiceCategoryCreateForm from './service-category/service-category-form/ServiceCategoryCreateForm';
import {ServiceCategory} from './service-category/ServiceCategory';
import './services.css';
import {
  applyCategoriesFilter,
  applyServicesFilter,
} from './utils/service.filter';

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {globalFilter: ''};
    this.openCreateCategory = this.openCreateCategory.bind(this);
    this.createCategory = this.createCategory.bind(this);
  }

  componentDidMount() {
    this.props.findAllServiceCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      becameObsolete(
        prevProps.serviceCategoriesDataSyncState,
        this.props.serviceCategoriesDataSyncState
      )
    )
      this.props.findAllServiceCategories();
  }

  renderSearchInput() {
    return (
      <div className='col-xl-4 col-lg-3 col-sm-4 col-12 pl-1 pr-0'>
        <Input
          id='filter-input'
          type='search'
          placeholder={I18n.t('services.searchPlaceholder')}
          onInput={e => this.setState({globalFilter: e.target.value})}
          className='global-filter'
        />
      </div>
    );
  }

  renderHeaderButtons() {
    const {isEditable, canCreate, handleSave} = this.props;

    return (
      <Fragment>
        {isEditable && (
          <div className='col-xl-2 col-lg-3 col-sm-4 col-12 px-1'>
            <Button
              id='create-category-btn'
              className='main-btn w-100'
              onClick={this.openCreateCategory}>
              <Translate value={'services.createCategory'} />
            </Button>
          </div>
        )}
        {handleSave && (
          <Fragment>
            <div className='col-xl-2 col-lg-3 col-sm-4 col-12 px-1'>
              <Button
                id='save-services-btn'
                className='main-btn w-100'
                onClick={handleSave}>
                <Translate value={'workshopServices.saveBtn'} />
              </Button>
            </div>
            <div className='col-xl-2 col-lg-3 col-sm-4 col-12 px-1'>
              <Button
                id='clear-services-btn'
                className='main-btn w-100'
                onClick={this.props.clearSelectedServices}>
                <Translate value={'workshopServices.cancelBtn'} />
              </Button>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderHeader() {
    return (
      <div className='mx-3'>
        <div className='row pb-3 justify-content-end'>
          {this.renderHeaderButtons()}
          {this.renderSearchInput()}
        </div>
      </div>
    );
  }

  openCreateCategory() {
    const form = (
      <ServiceCategoryCreateForm
        onSubmit={this.createCategory}
        privateServicesRestriction={this.props.privateServicesRestriction}
      />
    );
    this.props.showUpdateDataSideBar(
      form,
      I18n.t('services.createCategoryFormTitle')
    );
  }

  createCategory(values) {
    this.props.createServiceCategory(values);
  }

  renderCategories() {
    const {categories} = this.props;
    return !isEmptyArray(categories)
      ? this.renderCategoriesContent(categories)
      : this.renderInfoAlert('services.categoryListEmptyPlaceholder');
  }

  renderInfoAlert(messageKey) {
    return (
      <Alert color='info'>
        <i className='mr-2 fa fa-info-circle' />
        <Translate value={messageKey} />
      </Alert>
    );
  }

  renderCategoriesContent(categories) {
    const {
      handleSelect,
      isEditable,
      editingRestricted,
      isServiceManager,
      privateServicesRestriction,
    } = this.props;
    const phrase = this.state.globalFilter;
    const filteredCategories = applyCategoriesFilter(categories, phrase).map(
      (category, i) => (
        <ServiceCategory
          category={{
            ...category,
            services: applyServicesFilter(category.services, phrase),
          }}
          key={i}
          filterActive={!!phrase}
          handleSelect={handleSelect}
          isEditable={isEditable && !category.locked}
          editingRestricted={editingRestricted}
          isServiceManager={isServiceManager}
          privateServicesRestriction={privateServicesRestriction}
        />
      )
    );
    return !isEmptyArray(filteredCategories)
      ? filteredCategories
      : this.renderInfoAlert('services.noSearchResultsPlaceholder');
  }

  render() {
    return (
      <ContentWrapper title='sideBarNav.services'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='p-3'>
            {this.renderHeader()}
            {this.renderCategories()}
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {service} = state;
  const {isFetching, categories, serviceCategoriesDataSyncState} = service;

  return {isFetching, categories, serviceCategoriesDataSyncState};
}

const mapDispatchToProps = {
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  createServiceCategory: serviceCategoryActions.createServiceCategory,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

Services.defaultProps = {
  isEditable: true,
  editingRestricted: false,
  isServiceManager: true,
  privateServicesRestriction: false,
};

const connectedServices = connect(
  mapStateToProps,
  mapDispatchToProps
)(Services);
export {connectedServices as Services};
