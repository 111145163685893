/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Collapse} from 'reactstrap';
import {connect} from 'react-redux';
import {languageActions} from '../../../actions/language.actions';
import {isEmptyArray} from '../../../utils/utils';
import {setLocale} from 'react-redux-i18n';
import './language-select.css';
import {selectedLanguageKey} from '../../../utils/i18n';
import moment from 'moment';

class LanguageSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isOpen: false};

    this.toggle = this.toggle.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  toggle() {
    const currentIsOpenState = this.state.isOpen;
    if (currentIsOpenState) {
      document.removeEventListener('click', this.handleDocumentClick, true);
      this.setState({isOpen: false});
    } else {
      this.findAllLanguages();
      document.addEventListener('click', this.handleDocumentClick, true);
      this.setState({isOpen: true});
    }
  }

  findAllLanguages() {
    this.props.findAllLanguages();
  }

  handleDocumentClick(e) {
    const container = this.wrapper;
    if (e.target !== container && !container.contains(e.target)) this.toggle();
  }

  chooseLanguage(language) {
    const languageSymbol = language.symbol;
    this.props.chooseLanguage(languageSymbol);
    moment.locale(languageSymbol);
    localStorage.setItem(selectedLanguageKey, languageSymbol);
  }

  render() {
    const {languages, isFetchingLanguages, locale} = this.props;

    return (
      <div className='language-select' ref={ref => (this.wrapper = ref)}>
        <div id='language-select-toggle' onClick={this.toggle}>
          <i
            className={`fa fa-globe fa-3x clickable ${
              this.state.isOpen && !isFetchingLanguages ? 'active' : ''
            }`}
          />
          <div>{locale ? locale.toUpperCase() : ''}</div>
        </div>
        <Collapse
          isOpen={this.state.isOpen && !isFetchingLanguages}
          className='language-select-collapse'>
          <ul className='language-select-ul shadow'>
            {!isEmptyArray(languages) &&
              languages.map((language, index) => (
                <li
                  className='p-2 clickable'
                  key={index}
                  onClick={() => this.chooseLanguage(language)}>
                  {language.label}{' '}
                  {language.symbol === locale && <i className='fa fa-check' />}
                </li>
              ))}
          </ul>
        </Collapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {language, i18n} = state;
  const {languages, isFetchingLanguages} = language;
  const {locale} = i18n;

  return {languages, isFetchingLanguages, locale};
}

const mapDispatchToProps = {
  findAllLanguages: languageActions.findAllLanguages,
  chooseLanguage: setLocale,
};

const connectedLanguageSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelect);
export {connectedLanguageSelect as LanguageSelect};
