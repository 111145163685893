/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateRequiredFieldsWithMaxLength} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.label = validateRequiredFieldsWithMaxLength(values.label, 30);
  errors.url = validateRequiredFieldsWithMaxLength(values.url, 600);
  errors.color = validateRequiredFieldsWithMaxLength(values.color, 7);
  return errors;
};
