/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {getOrEmptyObj} from '../../../../utils/utils';

export const prefixData = [{label: '+48 PL', value: '+48', language: 'PL'}];

export const getDefaultPhonePrefix = i18n => {
  if (i18n && i18n.locale) {
    const userCurrentLanguage = i18n.locale.toUpperCase();
    const defaultPrefix = prefixData.find(
      prefix => prefix.language === userCurrentLanguage
    );

    return defaultPrefix ? defaultPrefix.value : '';
  }
};

export const getPhonePrefixByCountry = (country, countries) => {
  const countryInfo = getOrEmptyObj(countries[country]);
  return getOrEmptyObj(countryInfo.phoneNumberPrefix);
};
