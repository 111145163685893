/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {serviceConstants} from '../constants/service.constants';
import {serviceService} from '../services/service.service';
import {messageActions} from './message.actions';
import {getErrorMessage} from '../utils/utils';
import {reset} from 'redux-form';
import {updateDataSideBarConstants} from '../constants/update-data-side-bar.constants';

export const serviceActions = {
  findServiceById,
  updateService,
  createService,
  deleteService,
  acceptService,
};

function findServiceById(serviceId) {
  return dispatch => {
    serviceService.findServiceById(serviceId).then(service => {
      dispatch({type: serviceConstants.LOAD_SERVICE, payload: service});
    });
  };
}

function updateService(serviceId, values) {
  return dispatch => {
    serviceService.updateService(serviceId, values).then(
      service => {
        dispatch(
          messageActions.successMessage('services.serviceUpdateSuccessMsg')
        );
        dispatch({type: serviceConstants.LOAD_SERVICE, payload: service});
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'serviceUpdateErrorMsg')
          )
        );
      }
    );
  };
}

function createService(values) {
  return dispatch => {
    serviceService.createService(values).then(
      service => {
        dispatch(
          messageActions.successMessage('services.serviceCreateSuccessMsg')
        );
        dispatch(reset('serviceCreateForm'));
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'serviceCreateErrorMsg')
          )
        );
      }
    );
  };
}

function acceptService(serviceId) {
  return dispatch => {
    serviceService.acceptService(serviceId).then(
      service => {
        dispatch(
          messageActions.successMessage('services.serviceUpdateSuccessMsg')
        );
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'serviceUpdateErrorMsg')
          )
        );
      }
    );
  };
}

function deleteService(serviceId) {
  return dispatch => {
    serviceService.deleteService(serviceId).then(
      () => {
        dispatch(
          messageActions.successMessage('services.serviceDeleteSuccessMsg')
        );
        dispatch({type: updateDataSideBarConstants.HIDE});
        dispatch({type: serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA});
      },
      error => {
        dispatch(
          messageActions.errorMessage(
            getErrorMessage(error, 'services', 'serviceDeleteErrorMsg')
          )
        );
      }
    );
  };
}
