/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

/*global google*/
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues} from 'redux-form';
import {RenderGmap} from 'shared/components/form/gmap/RenderGmap';
import {workshopConstants} from 'shared/constants/workshop.constants';

class WorkshopMap extends RenderGmap {
  componentDidUpdate(prevProps) {
    const {workshopMap, formValues, refreshMap} = this.props;
    if (
      this.state.isFirstCenter &&
      formValues &&
      formValues.latitude &&
      formValues.longitude
    ) {
      super.centerOnMarkerOnInit(this.state.bounds);
      this.setState({isFirstCenter: false});
    } else if (prevProps.workshopMap !== workshopMap) {
      super.initAutocomplete();
      super.centerOnMarkerOnInit(this.state.bounds);
      refreshMap();
    }
  }

  render() {
    return super.render();
  }
}

WorkshopMap.propType = {
  formName: PropTypes.string,
  mapMinHeight: PropTypes.number,
  inputDisabled: PropTypes.bool,
};

WorkshopMap.defaultProps = {
  mapMinHeight: 320,
};

const mapStateToProps = (state, componentProps) => {
  return {
    formValues: componentProps.formName
      ? getFormValues(componentProps.formName)(state)
      : componentProps.formValues,
    i18n: state.i18n,
    workshopMap: state.workshop.workshopMap,
  };
};

const mapDispatchToProps = {
  refreshMap: () => ({type: workshopConstants.REFRESH_ADDRESS_MAP}),
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopMap);
