/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import moment from 'moment';
import {I18n} from 'react-redux-i18n';
import DatePicker from 'react-datepicker';
import {getOrEmptyArray, getOrEmptyObj} from '../../../utils/utils';
import PropTypes from 'prop-types';
import {workshopActions} from '../../../actions/workshop.actions';
import {connect} from 'react-redux';
import './appointment-datepicker.css';

class AppointmentDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFirstLoad: true,
    };

    this.isWorkshopWorkingDay = this.isWorkshopWorkingDay.bind(this);
    this.getWorkshopWorkingDay = this.getWorkshopWorkingDay.bind(this);
    this.onAppointmentDateChange = this.onAppointmentDateChange.bind(this);
    this.applyWorkloadOnHours = this.applyWorkloadOnHours.bind(this);
    this.applyWorkloadOnDay = this.applyWorkloadOnDay.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const {
      appointmentDate,
      getWorkshopWorkload,
      workshopUserId,
      workshopWorkload,
      findWorkingDaysByWorkshopUserId,
    } = this.props;

    if (this.state.isFirstLoad) findWorkingDaysByWorkshopUserId(workshopUserId);

    if (
      appointmentDate !== prevProps.appointmentDate ||
      this.state.isFirstLoad
    ) {
      const baseDate = appointmentDate ? moment(appointmentDate) : moment();
      const startDate = moment(baseDate).startOf('month');
      const endDate = moment(startDate).add(1, 'month');

      getWorkshopWorkload(workshopUserId, startDate.format(), endDate.format());
      this.setState({isFirstLoad: false});
    }

    if (workshopWorkload !== prevProps.workshopWorkload) {
      this.applyWorkloadOnHours();
    }
  }

  isWorkshopWorkingDay(date) {
    const isWorkingDay =
      getOrEmptyArray(this.props.workshopWorkingDays)
        .map(wd => wd.dayOfWeekNumber)
        .indexOf(date.isoWeekday()) >= 0;
    const isNotFreeDay =
      getOrEmptyArray(this.props.freeDays).indexOf(date.format('YYYY-MM-DD')) <
      0;
    return isWorkingDay && isNotFreeDay;
  }

  getWorkshopWorkingDay(date) {
    if (date) {
      return getOrEmptyArray(this.props.workshopWorkingDays).filter(
        wd => wd.dayOfWeekNumber === moment(date).isoWeekday()
      )[0];
    }
  }

  onAppointmentDateChange(date) {
    const {onAppointmentDateChange} = this.props;
    const workshopWorkingDay = this.getWorkshopWorkingDay(date);

    if (workshopWorkingDay) {
      const startTime = moment(date)
        .hour(workshopWorkingDay.startTime.split(':')[0])
        .minute(workshopWorkingDay.startTime.split(':')[1])
        .second(0);
      const endTime = moment(date)
        .hour(workshopWorkingDay.endTime.split(':')[0])
        .minute(workshopWorkingDay.endTime.split(':')[1])
        .second(0)
        .subtract(15, 'minutes');

      if (date.isBefore(startTime) || date.isAfter(endTime)) {
        onAppointmentDateChange(startTime.format());
      } else onAppointmentDateChange(date.format());
    }
  }

  applyWorkloadOnHours() {
    const day = moment(this.props.appointmentDate).format('YYYY-MM-DD');
    const workloadHours = getOrEmptyObj(
      getOrEmptyArray(this.props.workshopWorkload).filter(
        wi => wi.day === day
      )[0]
    ).hours;
    const times = document.querySelectorAll(
      '.react-datepicker__time-list-item'
    );

    if (times && times.forEach) {
      times.forEach(value => {
        value.classList.remove(
          'low-workload',
          'medium-workload',
          'high-workload'
        );
        const hour = value.textContent;
        const workload = getOrEmptyObj(
          getOrEmptyArray(workloadHours).filter(wh => wh.hour === hour)[0]
        ).workload;
        if (workload) value.classList.add(`${workload.toLowerCase()}-workload`);
      });
    }
  }

  applyWorkloadOnDay(date) {
    const workload = getOrEmptyObj(
      getOrEmptyArray(this.props.workshopWorkload).filter(
        wi => wi.day === date.format('YYYY-MM-DD')
      )[0]
    ).workload;
    const isWorkshopWorkingDay = this.isWorkshopWorkingDay(date);
    const isAfterMinDate = date.isAfter(this.props.minAppointmentDate);
    return workload && isWorkshopWorkingDay && isAfterMinDate
      ? `${workload.toLowerCase()}-workload`
      : '';
  }

  render() {
    const workshopWorkingDay = this.getWorkshopWorkingDay(
      this.props.appointmentDate
    );
    let additionalProps = {};
    if (!workshopWorkingDay) additionalProps = {includeTimes: []};

    return (
      <DatePicker
        inline
        minDate={moment(this.props.minAppointmentDate)}
        filterDate={this.isWorkshopWorkingDay}
        showTimeSelect
        timeFormat='HH:mm'
        timeIntervals={15}
        timeCaption={I18n.t('datePicker.time')}
        minTime={
          workshopWorkingDay
            ? moment(workshopWorkingDay.startTime, 'HH:mm')
            : null
        }
        maxTime={
          workshopWorkingDay
            ? moment(workshopWorkingDay.endTime, 'HH:mm').subtract(
                15,
                'minutes'
              )
            : null
        }
        selected={
          this.props.appointmentDate ? moment(this.props.appointmentDate) : null
        }
        onChange={this.onAppointmentDateChange}
        dayClassName={this.applyWorkloadOnDay}
        {...additionalProps}
      />
    );
  }
}

AppointmentDatePicker.propTypes = {
  appointmentDate: PropTypes.string,
  onAppointmentDateChange: PropTypes.func,
  minAppointmentDate: PropTypes.string,
  workshopUserId: PropTypes.string,
};

AppointmentDatePicker.defaultProps = {
  onAppointmentDateChange: () => {},
  minAppointmentDate: moment()
    .add(1, 'days')
    .hour(8)
    .minute(0)
    .second(0)
    .format(),
};

function mapStateToProps({workshop}) {
  const {workshopWorkload} = workshop;

  const workshopWorkingDays = getOrEmptyObj(
    workshop.workshopWorkingDays
  ).workingDays;
  const freeDays = getOrEmptyObj(workshop.workshopWorkingDays).freeDays;

  return {workshopWorkload, workshopWorkingDays, freeDays};
}

const mapDispatchToProps = {
  findWorkingDaysByWorkshopUserId:
    workshopActions.findWorkingDaysByWorkshopUserId,
  getWorkshopWorkload: workshopActions.getWorkshopWorkload,
};

const connectedAppointmentDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentDatePicker);
export {connectedAppointmentDatePicker as AppointmentDatePicker};
