/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością

 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {userConstants} from '../constants/user.constants';
import {SyncState} from '../utils/sync';

export function userReducer(
  state = {
    isFetching: false,
    isAuthenticated: localStorage.getItem(userConstants.ACCESS_TOKEN_KEY)
      ? true
      : false,
    infoBarcode: null,
  },
  action
) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {isFetching: true, isAuthenticated: false};
    case userConstants.LOGIN_SUCCESS:
      return {isFetching: false, isAuthenticated: true};
    case userConstants.LOGIN_FAILURE:
      return {isFetching: false, isAuthenticated: false};
    case userConstants.LOGOUT:
      return {isFetching: false, isAuthenticated: false};
    case userConstants.REQUEST_START:
      return {...state, isFetching: true};
    case userConstants.REQUEST_END:
      return {...state, isFetching: false};
    case userConstants.LOAD_PROFILE_DATA:
      return {
        ...state,
        data: action.payload.data,
        usersData: syncUsersData(state.usersData, action.payload.data),
      };
    case userConstants.CHANGE_PROFILE_STATUS:
      return {
        ...state,
        data: action.payload.data,
        usersData: syncUsersData(state.usersData, action.payload.data),
        usersDataSyncState: SyncState.OBSOLETE,
      };
    case userConstants.LOAD_USERS_DATA:
      return {
        ...state,
        usersData: action.payload.data,
        usersDataSyncState: SyncState.UP_TO_DATE,
      };
    case userConstants.REFRESH_USERS_DATA:
      return {...state, usersDataSyncState: SyncState.OBSOLETE};
    case userConstants.LOGGED_USER_AVATAR_LOADED:
      return {...state, loggedUserAvatar: action.payload};
    case userConstants.CLEAR_SELECTED_USER:
      return {...state, data: null};

    case userConstants.USER_BARCODE_INFO:
      return {...state, infoBarcode: action.payload};

    case userConstants.USER_BARCODE_INFO_CLEAR:
      return {...state, infoBarcode: null};

    case userConstants.REPORT_ISSUE_SUCCESS:
      return {...state, reportIssueSuccess: true};

    case userConstants.REPORT_ISSUE_CLEAR:
      return {...state, reportIssueSuccess: null};

    default:
      return state;
  }
}

function syncUsersData(usersData, loadedProfileData) {
  return usersData
    ? usersData.map(userData => {
        if (userData.id === loadedProfileData.id) {
          userData.firstName = loadedProfileData.firstName;
          userData.lastName = loadedProfileData.lastName;
        }
        return userData;
      })
    : [];
}
