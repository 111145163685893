/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {subscriptionActions} from 'shared/actions/subscription.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import SubscriptionPaymentsTable from 'shared/components/subscription/subscription-payments-table/SubscriptionPaymentsTable';

class SubscriptionPayments extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    const {findAllSubscriptionPayments} = this.props;
    findAllSubscriptionPayments();
  }

  render() {
    const {subscriptionPayments, isFetchingSubscriptionPayment} = this.props;

    return (
      <ContentWrapper title='subscription.paymentsBtn'>
        <Loadable active={isFetchingSubscriptionPayment} spinner>
          <SubscriptionPaymentsTable
            subscriptionPayments={subscriptionPayments}
            showUserLabel={true}
            showActions={false}
            showTitle={false}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, subscription} = state;

  const {subscriptionPayments, isFetchingSubscriptionPayment} = subscription;

  return {
    i18n,
    subscriptionPayments,
    isFetchingSubscriptionPayment,
  };
}

const mapDispatchToProps = {
  findAllSubscriptionPayments: subscriptionActions.findAllSubscriptionPayments,
};

const connectedSubscriptionPayments = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPayments);
export {connectedSubscriptionPayments as SubscriptionPayments};
