/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {history} from 'shared/utils/history';
import './accept-invite.css';

const timeOutInMilliSec = 30000;

class AcceptInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {invitationToken: null, timeout: null};
    this.onClickContinueButton = this.onClickContinueButton.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentWillMount() {
    let searchParams = new URLSearchParams(this.props.location.search);
    this.setState({invitationToken: searchParams.get('invitationToken')});
    setTimeout(
      () => this.props.acceptInvite(this.state.invitationToken, this.onSuccess),
      500
    );
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  onSuccess() {
    this.setState({
      timeout: setTimeout(
        () => this.onClickContinueButton(),
        timeOutInMilliSec
      ),
    });
  }

  onClickContinueButton() {
    history.push('/workshop/valuations');
  }

  render() {
    const {isFetching, acceptInviteMessageKey} = this.props;

    if (!acceptInviteMessageKey) return null;

    return (
      <ContentWrapper
        title={`acceptInvite.${acceptInviteMessageKey}Title`}
        containerClass='workshop-sign-up-container text-center'>
        <Loadable active={isFetching} spinner>
          <div className='accept-invite-text'>
            <Translate
              value={`acceptInvite.${acceptInviteMessageKey}Content`}
            />
          </div>
          <Button
            onClick={this.onClickContinueButton}
            className='main-btn mb-3'>
            <Translate value={'acceptInvite.continueBtn'} />
          </Button>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {workshop} = state;
  const {isFetching, acceptInviteMessageKey} = workshop;
  const {i18n} = state;

  return {isFetching, i18n, acceptInviteMessageKey};
}

const mapDispatchToProps = {
  acceptInvite: workshopActions.acceptInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);
