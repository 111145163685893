/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {
  sortByCustomField,
  sortByInquiryNumber,
} from 'shared/utils/sort-utils/sort.utils';
import {ValuationInquiryDetails} from '../../../workshop/valuations/valuation-inquiry-details/ValuationInquiryDetails';
import ValuationsByInquiry from '../valuations-by-inquiry/ValuationsByInquiry';

class PreparedValuations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
    this.showValuationInquiryDetails =
      this.showValuationInquiryDetails.bind(this);
    this.expandValuationsByInquiry = this.expandValuationsByInquiry.bind(this);
  }

  componentDidMount() {
    this.props.findAllValuationInquiries();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  showValuationInquiryDetails(event) {
    this.props.findValuationInquiryById(event.data.id);
    const details = (
      <ValuationInquiryDetails shouldShowWorkshopStatusAlert={false} />
    );
    const title =
      I18n.t('valuationInquiryDetails.detailsHeader') +
      ' ' +
      event.data.inquiryNumber.inquiryNumberString;
    this.props.showUpdateDataSideBar(details, title);
  }

  rowExpansionTemplate(data) {
    const origin = data.inquiryNumber.origin;
    const inquiryNumber = data.inquiryNumber.number;
    return (
      <ValuationsByInquiry origin={origin} inquiryNumber={inquiryNumber} />
    );
  }

  expandValuationsByInquiry(e) {
    let recentExpandedIndex = e.data.length - 1;
    this.setState({expanded: [e.data[recentExpandedIndex]]});
  }

  render() {
    const {
      valuationInquiries,
      isFetching,
      header,
      rows,
      globalFilter,
      renderDateCol,
      renderRowsDropdown,
    } = this.props;
    const valuationInquiriesWithResponse = valuationInquiries
      ? valuationInquiries.filter(vi => vi.hasValuations === true).reverse()
      : [];

    return (
      <ContentWrapper title='valuations.preparedValuationsBtn'>
        <Loadable active={isFetching} spinner>
          <DataTable
            value={valuationInquiriesWithResponse}
            paginator={true}
            rows={rows}
            header={header}
            className='expandable-datatable p-3'
            responsive={true}
            globalFilter={globalFilter}
            onRowClick={this.showValuationInquiryDetails}
            emptyMessage={I18n.t('inquiriesHistory.emptyPlaceholder')}
            expandedRows={this.state.expanded}
            onRowToggle={this.expandValuationsByInquiry}
            ref={this.setDatatableRef}
            rowExpansionTemplate={this.rowExpansionTemplate}
            paginatorLeft={renderRowsDropdown()}>
            <Column expander={true} className='expander-col' />
            <Column
              field='inquiryNumber.inquiryNumberString'
              sortable='custom'
              sortFunction={event =>
                sortByInquiryNumber(valuationInquiriesWithResponse, event)
              }
              header={I18n.t('inquiriesHistory.inquiryNumberHeader')}
            />
            <Column
              field='sendDate'
              header={I18n.t('inquiriesHistory.sendDateHeader')}
              body={renderDateCol}
              sortable='custom'
              sortFunction={e =>
                sortByCustomField(e, valuationInquiriesWithResponse, 'sendDate')
              }
            />
          </DataTable>
        </Loadable>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({order}) => {
  const {isFetching, valuationInquiries} = order;
  return {isFetching, valuationInquiries};
};

const mapDispatchToProps = {
  findAllValuationInquiries: orderActions.findAllValuationInquiries,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findValuationInquiryById: orderActions.findValuationInquiryById,
};

//For testing
export const ConnectedPreparedValuationsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreparedValuations);

const PreparedValuationsWithDataTableCore = withDataTableCore(
  ConnectedPreparedValuationsWithRedux
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreparedValuationsWithDataTableCore);
