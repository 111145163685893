/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {I18n} from 'react-redux-i18n';
import {getOrEmptyArray, getOrEmptyObj, isEmptyArray} from 'shared/utils/utils';

export const ValuationInquiryStatusEnum = {
  NEW: 'NEW',
  NO_RESPONSE: 'NO_RESPONSE',
  RESPONDED: 'RESPONDED',
  EXTENDED_PERIOD: 'EXTENDED_PERIOD',
};

export const getGuaranteePeriods = () => {
  return [
    {label: I18n.t('valuationInquiryResponse.noGuaranteeLabel'), value: 0},
    {
      label: '6 ' + I18n.t('valuationInquiryResponse.monthShortLabel'),
      value: 6,
    },
    {
      label: '12 ' + I18n.t('valuationInquiryResponse.monthShortLabel'),
      value: 12,
    },
    {
      label: '24 ' + I18n.t('valuationInquiryResponse.monthShortLabel'),
      value: 24,
    },
  ];
};

export const setLpgService = (
  formValues,
  serviceCategories,
  lpgServiceCategoryId,
  currency
) => {
  const lpgCategory =
    !isEmptyArray(serviceCategories) && lpgServiceCategoryId
      ? serviceCategories.find(
          serviceCategory => serviceCategory.id === lpgServiceCategoryId
        )
      : null;
  const lpgService = lpgCategory
    ? {
        serviceCategoryId: lpgCategory.id,
        serviceId: getOrEmptyObj(getOrEmptyArray(lpgCategory.services)[0]).id,
        serviceCost: {value: formValues.lpgTotalCost, currency: currency},
        guaranteePeriodMonths: formValues.lpgGuaranteePeriodMonths,
        params: [
          {
            id: 'lpgDriver',
            value: resolveLpgPartName(formValues, 'lpgDriver'),
          },
          {
            id: 'lpgBgSwitch',
            value: resolveLpgPartName(formValues, 'lpgBgSwitch'),
          },
          {
            id: 'lpgReducer',
            value: resolveLpgPartName(formValues, 'lpgReducer'),
          },
          {
            id: 'lpgInjectors',
            value: resolveLpgPartName(formValues, 'lpgInjectors'),
          },
          {id: 'lpgTank', value: resolveLpgPartName(formValues, 'lpgTank')},
          {
            id: 'lpgRefuelingValve',
            value: resolveLpgPartName(formValues, 'lpgRefuelingValve'),
          },
          {
            id: 'lpgAdditionalInspection',
            value: formValues.lpgAdditionalInspection ? 'true' : 'false',
          },
        ],
      }
    : null;
  if (lpgService) {
    formValues.services = [...getOrEmptyArray(formValues.services), lpgService];
    delete formValues.lpgTotalCost;
    delete formValues.lpgDriver;
    delete formValues.lpgBgSwitch;
    delete formValues.lpgReducer;
    delete formValues.lpgInjectors;
    delete formValues.lpgTank;
    delete formValues.lpgRefuelingValve;
    delete formValues.lpgDriverOther;
    delete formValues.lpgBgSwitchOther;
    delete formValues.lpgReducerOther;
    delete formValues.lpgInjectorsOther;
    delete formValues.lpgTankOther;
    delete formValues.lpgRefuelingValveOther;
    delete formValues.lpgAdditionalInspection;
    delete formValues.lpgGuaranteePeriodMonths;
  }
};

const resolveLpgPartName = (formValues, partCategoryKey) => {
  return formValues[partCategoryKey] === 'other' &&
    formValues[`${partCategoryKey}Other`]
    ? formValues[`${partCategoryKey}Other`]
    : formValues[partCategoryKey];
};

export const overwriteDuplicates = (currentServices, services) => {
  if (currentServices) {
    currentServices.forEach(cs => {
      services.find((s, index) => {
        if (s.serviceId === cs.serviceId) {
          services.splice(index, 1);
          services.push(cs);
          return true;
        }
        return false;
      });
    });
  }
};

export const setAdditionalCost = (valuation, currency) => {
  const hasAdditionalCostValue =
    valuation.additionalCost && valuation.additionalCost.value;
  if (hasAdditionalCostValue) valuation.additionalCost.currency = currency;
  else delete valuation.additionalCost;
};

export const valuationToFormValues = (
  valuation,
  lpgServiceCategoryId,
  services
) => {
  prepareLpgFields(valuation, lpgServiceCategoryId);
  valuation.services.forEach(service => {
    if (service.serviceCategoryId !== lpgServiceCategoryId) {
      const serviceWithName = services.find(
        s =>
          s.serviceId === service.serviceId &&
          s.serviceCategoryId === service.serviceCategoryId
      );
      service.serviceName = serviceWithName.serviceName;
      service.serviceCategoryName = serviceWithName.serviceCategoryName;
    }
  });
  return valuation;
};

const prepareLpgFields = (valuation, lpgServiceCategoryId) => {
  valuation.services.find((service, index, array) => {
    if (service.serviceCategoryId === lpgServiceCategoryId) {
      array.splice(index, 1);
      service.params.forEach(param => (valuation[param.id] = param.value));
      valuation.lpgGuaranteePeriodMonths = service.guaranteePeriodMonths;
      valuation.lpgTotalCost = service.serviceCost.value;
      return service;
    }
  });
};
