/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { I18n } from "react-redux-i18n";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Input } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { rowsQuantityToDisplay } from 'shared/utils/utils';
import { dateUtils, DATE_SHORT_FORMAT } from 'shared/utils/date.utils';
import { RenderDropdown } from "shared/components/form/dropdown/hook-form-version/RenderDropdown";
import { RadioButton } from 'primereact/radiobutton';
import { Translate } from 'react-redux-i18n';
import styles from './WorkshopLoyaltyProgramHistoryList.module.css';

export const PointsType = {
    ALL: 'ALL',
    GATHERED: 'GATHERED',
    SPENT: 'SPENT',
};

export const WorkshopLoyaltyProgramHistoryList = () => {
    const dispatch = useDispatch();

    const workshops = useSelector(state => state.workshopLoyaltyProgram.workshopList);
    const workshopListFetching = useSelector(state => state.workshopLoyaltyProgram.workshopListFetching);

    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState();
    const [sortDirection, setSortDirection] = useState();
    const [first, setFirst] = useState(0)
    const [globalFilter, setGlobalFilter] = useState('');
    const [mappedHistory, setMappedHistory] = useState([]);
    const workshopHistory = useSelector(state => state.workshopLoyaltyProgram.history);
    const workshopHistoryFetching = useSelector(state => state.workshopLoyaltyProgram.historyFetching);
    const selectedWorkshop = useSelector(state => state.workshopLoyaltyProgram.selectedWorkshop);
    const selectedPointsType = useSelector(state => state.workshopLoyaltyProgram.selectedPointsType);

    const pointsTypeFilter = [
        { value: PointsType.ALL, name: "workshopLoyaltyProgram.historyPoints.allPoints" },
        { value: PointsType.GATHERED, name: "workshopLoyaltyProgram.historyPoints.earnedPoints" },
        { value: PointsType.SPENT, name: "workshopLoyaltyProgram.historyPoints.spentPoints" },
    ];

    useEffect(() => {
        dispatch(workshopLoyaltyProgramActions.getWorkshopList({
            page: 0,
            size: 100000,
        }, ""));
    }, []);

    useEffect(() => {
        if (selectedWorkshop) {
            let params = {
                page: page - 1,
                size: pageLimit,
                sort: (sortField && sortDirection) && `${sortField},${sortDirection}`,
                filter: selectedPointsType
            };
            if (globalFilter) {
                params = {
                    ...params,
                    search: globalFilter,
                }
            }
            dispatch(workshopLoyaltyProgramActions.workshopLoyaltyProgramHistory(params, selectedWorkshop));
        }
    }, [page, pageLimit, sortField, sortDirection, globalFilter, selectedWorkshop, selectedPointsType]);

    const mapSortField = (label) => {
        switch (label) {
            case 'eventDate':
                return 'date'
            case 'totalPointsBeforeEvent':
                return 'totalPointsBefore'
            case 'totalEventPoints':
                return 'points'
            case 'totalPointsAfterEvent':
                return 'totalPointsAfter'
            default:
                break;
        }
    }

    useEffect(() => {
        if (workshopHistory.content?.length > 0) {
            const workshopHistoryTableData = workshopHistory.content.map((history, index) => {
                let valueString = '';
                let descriptionString = '';
                let productNameString = '';
                let productTypeString = '';
                let productCount = '';
                if (history.eventProducts?.length > 0) {
                    history.eventProducts.forEach((item, index) => {
                        valueString += item.value;
                        descriptionString += item.description;
                        productNameString += item.productName;
                        productTypeString += item.productType;
                        productCount += item.count;
                        if (index < history.eventProducts.length - 1) {
                            valueString += '\n';
                            descriptionString += '\n';
                            productNameString += '\n';
                            productTypeString += '\n';
                            productCount += '\n';
                        }
                    })
                }
                return {
                    ...history,
                    valueString: valueString,
                    productNameString: productNameString,
                    productTypeString: productTypeString,
                    totalEventPoints: ((history.totalPointsBeforeEvent - history.totalEventPoints) === history.totalPointsAfterEvent) ? `-${history.totalEventPoints}` : `+${history.totalEventPoints}`,
                    descriptionString: descriptionString,
                    lastPointsExchangeDate: dateUtils.formatDate(history.lastPointsExchangeDate, 'YYYY-MM-DD'),
                    productCount
                }
            });
            setMappedHistory(workshopHistoryTableData);
        }
        else {
            setMappedHistory([]);
        }
    }, [workshopHistory]);

    return (
        <ContentWrapper title='workshopLoyaltyProgram.historyPoints.header' scrollY>
            <Loadable active={workshopHistoryFetching} spinner>
                <div className='col' style={{minWidth: 936}}>
                    <DataTable
                        value={mappedHistory}
                        paginator={false}
                        rows={pageLimit}
                        className='data-table-padding-class'
                        responsive={true}
                        emptyMessage={I18n.t('workshopLoyaltyProgram.history.noHistory')}
                        onSort={e => {
                            setPage(1);
                            setFirst(0);
                            setSortField(mapSortField(e.sortField));
                            setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
                        }}
                        header={
                            <div className={styles.Header}>
                                <Loadable active={workshopListFetching} spinner>
                                    <div className={styles.FilterContainer}>
                                        <div className={styles.Dropdown}>
                                            <b><Translate value={"workshopLoyaltyProgram.historyPoints.selectWorkshop"} /></b>
                                            <RenderDropdown
                                                options={workshops.content?.map(workshop => { return { label: workshop.workshopName, value: workshop.workshopId } })}
                                                onChange={e => dispatch(workshopLoyaltyProgramActions.setSelectedWorkshop(e.value))}
                                                value={selectedWorkshop}
                                                label={I18n.t("workshopLoyaltyProgram.historyPoints.selectWorkshop")}
                                            />
                                        </div>
                                        <div className={styles.RadioButtons}>
                                            <b><Translate value={"workshopLoyaltyProgram.historyPoints.filterBy"} /></b>
                                            {pointsTypeFilter.map((option) => (
                                                <div style={{ marginTop: '1rem' }} key={option.value}>
                                                    <RadioButton value={option.value} name={option.name} onChange={e => dispatch(workshopLoyaltyProgramActions.setSelectedPointsType(e.value))} checked={option.value === selectedPointsType} />
                                                    <span style={{ marginLeft: 9, fontWeight: 400 }}><Translate value={option.name} /></span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Loadable>
                                <div className={styles.SearchInput}>
                                    <Input
                                        type='search'
                                        placeholder={I18n.t('workshops.searchPlaceholder')}
                                        onInput={e => {
                                            setFirst(0);
                                            setPage(1);
                                            setSortField(mapSortField(e.sortField));
                                            setGlobalFilter(e.target.value);
                                        }}
                                        className='global-filter'
                                    />
                                </div>
                            </div>
                        }
                    >
                        <Column
                            field='totalPointsBeforeEvent'
                            header={I18n.t('workshopLoyaltyProgram.historyPoints.pointsBeforePayment')}
                            sortable={true}
                            style={{ width: '10%' }}
                        />
                        <Column
                            field='eventDate'
                            header={I18n.t('workshopLoyaltyProgram.historyPoints.date')}
                            body={(rowData) => dateUtils.formatDate(rowData.eventDate, DATE_SHORT_FORMAT)}
                            sortable={true}
                            style={{ width: '15%' }}
                        />
                        <Column
                            field='totalEventPoints'
                            header={I18n.t('workshopLoyaltyProgram.historyPoints.pointsAmmount')}
                            sortable={true}
                            style={{ width: '10%' }}
                        />
                        <Column
                            field='productCount'
                            header={I18n.t('workshopLoyaltyProgram.workshops.count')}
                            style={{whiteSpace: 'pre-wrap', width: '7%' }}
                        />
                        <Column
                            field='productNameString'
                            header={I18n.t('workshopLoyaltyProgram.historyPoints.product')}
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                        <Column
                            field='totalPointsAfterEvent'
                            header={I18n.t('workshopLoyaltyProgram.historyPoints.pointsAfterPayment')}
                            sortable={true}
                            style={{ width: '10%' }}
                        />
                    </DataTable>
                    <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
                        <Paginator
                            first={first}
                            rows={pageLimit}
                            totalRecords={workshopHistory.totalElements}
                            pageLinkSize={5}
                            onPageChange={(e) => {
                                setPage(e.page + 1)
                                setFirst(e.first)
                            }}
                            leftContent={
                                <Dropdown
                                    options={rowsQuantityToDisplay}
                                    className='data-table-rows-dropdown'
                                    value={pageLimit}
                                    onChange={e => { setPage(1), setPageLimit(e.value) }}
                                />
                            }
                        ></Paginator>
                    </div>
                </div>
            </Loadable>
        </ContentWrapper>
    )
}
