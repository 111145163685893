/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button, UncontrolledTooltip} from 'reactstrap';
import {dateUtils} from 'shared/utils/date.utils';
import {sortByCustomField} from 'shared/utils/sort-utils/sort.utils';
import {indexArray} from 'shared/utils/utils';
import {promotionConstants} from '../../constants/promotion.constants';
import ContentWrapper from '../content-wrapper/ContentWrapper';
import {cutPrefixFromBase64String} from '../form/file-upload/utils/file-utils';
import PromotionsForm from './promotions-form/PromotionsForm';

export class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {addSectionIsOpen: false};

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.createPromotion = this.createPromotion.bind(this);
    this.updatePromotion = this.updatePromotion.bind(this);
    this.onClickPromotionDetails = this.onClickPromotionDetails.bind(this);
    this.renderActionsCol = this.renderActionsCol.bind(this);
    this.closeAddSection = this.closeAddSection.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  onClickPromotionDetails(rowData) {
    const targetClassName = rowData.originalEvent.target.className || '';
    if (
      targetClassName.indexOf('decision-btn') >= 0 ||
      targetClassName.indexOf('fa') >= 0
    )
      return;

    this.props.findPromotionById(rowData.data.id);
    const form = (
      <PromotionsForm
        onSubmit={values => this.updatePromotion(values, rowData)}
        isWorkshopNameVisible={this.props.isWorkshopNameVisible}
      />
    );
    this.props.showUpdateDataSideBar(
      form,
      I18n.t('promotions.promotionDetailsTitle')
    );
  }

  createPromotion(values) {
    const {loggedWorkshopId} = this.props;
    values.workshopId = loggedWorkshopId;
    this.props.createPromotion(values, this.closeAddSection);
  }

  closeAddSection() {
    this.setState({addSectionIsOpen: false});
  }

  updatePromotion(values, rowData) {
    const valuesToSend = {...values};
    if (valuesToSend.image !== null)
      valuesToSend.image = cutPrefixFromBase64String(valuesToSend.image);
    this.props.updatePromotion(rowData.data.id, valuesToSend);
  }

  renderStatusCol(data) {
    data.statusValue = I18n.t(`promotions.${data.status}`);
    return data.statusValue;
  }

  renderActivePeriodCol(data) {
    data.activePeriodValue = dateUtils.formatTimePeriod(
      data.startDate,
      data.endDate
    );
    return data.activePeriodValue;
  }

  acceptPromotion(id) {
    this.props.changePromotionStatus(id, promotionConstants.STATUS_ACCEPTED);
  }

  rejectPromotion(id) {
    this.props.changePromotionStatus(
      id,
      promotionConstants.STATUS_NOT_ACCEPTED
    );
  }

  renderActionButtons(data) {
    return (
      <div>
        <Button
          id={'accept-btn-' + data.id}
          className='main-btn decision-btn'
          onClick={() => this.acceptPromotion(data.id)}>
          <i className='fa fa-check' />
        </Button>
        <UncontrolledTooltip
          placement='top'
          target={'accept-btn-' + data.id}
          innerClassName='custom-tooltip'>
          <Translate value='promotions.acceptBtnTooltip' />
        </UncontrolledTooltip>

        <Button
          id={'reject-btn-' + data.id}
          className='btn-danger decision-btn'
          onClick={() => this.rejectPromotion(data.id)}>
          <i className='fa fa-remove' />
        </Button>
        <UncontrolledTooltip
          placement='top'
          target={'reject-btn-' + data.id}
          innerClassName='custom-tooltip'>
          <Translate value='promotions.rejectBtnTooltip' />
        </UncontrolledTooltip>
      </div>
    );
  }

  renderActionsCol(data) {
    return data.status === promotionConstants.STATUS_NEW ? (
      this.renderActionButtons(data)
    ) : (
      <div />
    );
  }

  noCreationConsentAlert() {
    return (
      <Alert color='warning' className='text-center'>
        <Translate value='promotions.noPromotionCreationConsentAlert' />
      </Alert>
    );
  }

  createPromotionBtn() {
    return (
      <Button
        id='create-promotion-btn'
        className='main-btn data-table-button'
        onClick={() => this.setState({addSectionIsOpen: true})}>
        <Translate value={'promotions.addBtn'} />
      </Button>
    );
  }

  render() {
    const {
      isFetching,
      promotions,
      isWorkshopNameVisible,
      isActionsVisible,
      promotionCreationConsent,
      headerBuilder,
      isPromotionsCreator,
      title,
      renderRowsDropdown,
    } = this.props;

    if (this.state.addSectionIsOpen && promotionCreationConsent)
      return (
        <PromotionsForm
          onSubmit={this.createPromotion}
          newPromotion={true}
          handleCancel={() => this.setState({addSectionIsOpen: false})}
        />
      );
    else
      return (
        <ContentWrapper title={title}>
          <Loadable active={isFetching} spinner>
            <div className='col'>
              {!promotionCreationConsent &&
                isPromotionsCreator &&
                this.noCreationConsentAlert()}

              <DataTable
                value={indexArray(promotions)}
                ref={this.setDatatableRef}
                paginator={true}
                rows={this.props.rows}
                globalFilter={this.props.globalFilter}
                header={headerBuilder()}
                className='data-table-padding-class'
                responsive={true}
                emptyMessage={I18n.t('promotions.emptyPromotionsPlaceholder')}
                onRowClick={this.onClickPromotionDetails}
                paginatorLeft={renderRowsDropdown()}
                paginatorRight={
                  promotionCreationConsent && this.createPromotionBtn()
                }>
                <Column
                  field='index'
                  className='lp-col'
                  header={I18n.t('promotions.lp')}
                  sortable={true}
                />

                {isWorkshopNameVisible && (
                  <Column
                    field='workshopName'
                    header={I18n.t('promotions.workshopName')}
                    sortable={true}
                  />
                )}

                <Column
                  field='createdDate'
                  header={I18n.t('promotions.createdDate')}
                  sortable={true}
                  body={this.props.renderDateCol}
                />

                <Column
                  field='title'
                  header={I18n.t('promotions.title')}
                  sortable={true}
                />

                <Column
                  field='activePeriod'
                  header={I18n.t('promotions.activePeriod')}
                  sortable='custom'
                  sortFunction={e =>
                    sortByCustomField(e, promotions, 'startDate')
                  }
                  body={this.renderActivePeriodCol}
                />

                <Column
                  field='status'
                  header={I18n.t('promotions.status')}
                  sortable={true}
                  body={this.renderStatusCol}
                />

                {isActionsVisible && (
                  <Column
                    header={I18n.t('promotions.actions')}
                    body={this.renderActionsCol}
                  />
                )}
              </DataTable>
            </div>
          </Loadable>
        </ContentWrapper>
      );
  }
}

Promotions.defaultProps = {
  isWorkshopNameVisible: false,
  title: 'sideBarNav.promotions',
  isActionsVisible: false,
  isPromotionsCreator: false,
};
