/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from 'reactstrap';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import renderField from 'shared/components/form/text-field/RenderField';
import {asyncValidate, validate} from './validators';

class CreateCarProposalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {carNameTooltipOpen: false};
    this.toggleCarNameTooltip = this.toggleCarNameTooltip.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {isOpen} = this.props;
    if (!prevProps.isOpen && isOpen) this.generateCarName();
  }

  generateCarName() {
    const {carBrandFieldValue, carModelFieldValue, dispatch} = this.props;
    if (carBrandFieldValue && carModelFieldValue) {
      const model =
        carModelFieldValue !== 'other' ? ' ' + carModelFieldValue : '';
      dispatch(
        change('createCarProposalForm', 'carName', carBrandFieldValue + model)
      );
    }
  }

  toggleCarNameTooltip() {
    this.setState({carNameTooltipOpen: !this.state.carNameTooltipOpen});
  }

  render() {
    const {isOpen, declineCreateCar, acceptCreateCar, handleSubmit, invalid} =
      this.props;

    return (
      <form>
        <Modal isOpen={isOpen} style={{border: 'none', marginTop: '12rem'}}>
          <ModalHeader>
            <Translate value='valuationInquiry.createCarProposalTitle' />
          </ModalHeader>
          <ModalBody>
            <div id='carNameTooltip'>
              <Field
                name='carName'
                type='text'
                component={renderField}
                required={false}
                label={I18n.t('valuationInquiry.carNameLabel')}
                icon='fa fa-car'
              />
              <Tooltip
                innerClassName='form-tooltip'
                placement='top'
                isOpen={this.state.carNameTooltipOpen}
                target='carNameTooltip'
                toggle={this.toggleCarNameTooltip}>
                <Translate value='valuationInquiry.carNameTooltip' />
              </Tooltip>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={invalid}
              className='main-btn'
              onClick={handleSubmit(acceptCreateCar)}
              id='accept-btn'>
              <Translate value='valuationInquiry.createCarProposalAccept' />
            </Button>
            <Button
              onClick={declineCreateCar}
              className='main-btn'
              id='decline-btn'>
              <Translate value='valuationInquiry.createCarProposalDecline' />
            </Button>
          </ModalFooter>
        </Modal>
      </form>
    );
  }
}

CreateCarProposalModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  acceptCreateCar: PropTypes.func.isRequired,
  declineCreateCar: PropTypes.func.isRequired,
};

const CreateCarProposalModalWithReduxForm = reduxForm({
  form: 'createCarProposalForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
  asyncValidate,
})(CreateCarProposalModal);

const valuationInquiryFormSelector = formValueSelector('valuationInquiryForm');

function mapStateToProps(state) {
  const carNameFieldValue = valuationInquiryFormSelector(state, 'carName');
  const carBrandFieldValue = valuationInquiryFormSelector(state, 'carBrand');
  const carModelFieldValue = valuationInquiryFormSelector(state, 'carModel');
  return {carNameFieldValue, carBrandFieldValue, carModelFieldValue};
}

export default connect(mapStateToProps)(CreateCarProposalModalWithReduxForm);
