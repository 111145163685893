/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate} from 'react-redux-i18n';
import {Alert, Button, Card, CardBody} from 'reactstrap';
import {LoyaltyProgramList} from 'shared/components/loyalty-program/loyalty-program-list/LoyaltyProgramList';
import {GenericModal} from 'shared/components/modal/GenericModal';
import {AppointmentDatePicker} from 'shared/components/order/appointment-date-picker/AppointmentDatePicker';
import {isEmptyArray} from 'shared/utils/utils';

export class BeforeChooseValuationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLoyaltyProgramId: null,
      appointmentDate: null,
    };

    this.renderConfirmChooseValuationAlert =
      this.renderConfirmChooseValuationAlert.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderCalendar = this.renderCalendar.bind(this);
    this.renderLoyaltyPrograms = this.renderLoyaltyPrograms.bind(this);
    this.onClickLoyaltyProgram = this.onClickLoyaltyProgram.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  renderConfirmChooseValuationAlert() {
    const {showConfirmChooseValuationAlert} = this.props;
    return (
      showConfirmChooseValuationAlert && (
        <Alert className='text-center' color='warning'>
          <h5 className='alert-heading'>
            <Translate value='alert.warning' />
          </h5>
          <Translate value='beforeChooseValuation.confirmChooseValuation' />
        </Alert>
      )
    );
  }

  renderCalendar() {
    return (
      <Card className='mb-3'>
        <h6 className='text-center mt-3'>
          <Translate value='beforeChooseValuation.calendarTitle' />
        </h6>
        <p className='text-center' style={{fontSize: 'small'}}>
          <Translate value='beforeChooseValuation.appointmentDateInfo' />
        </p>
        <CardBody className='p-2'>
          <div className='row justify-content-center'>
            <div className='col-auto'>
              <AppointmentDatePicker
                appointmentDate={this.state.appointmentDate}
                onAppointmentDateChange={date =>
                  this.setState({appointmentDate: date})
                }
                workshopUserId={this.props.workshopUserId}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  onClickLoyaltyProgram(loyaltyProgramId) {
    this.setState({activeLoyaltyProgramId: loyaltyProgramId});
  }

  renderLoyaltyPrograms() {
    const {loyaltyProgramAccount} = this.props;

    return (
      <Card>
        <h6 className='text-center mt-3'>
          <Translate value='beforeChooseValuation.achievedLoyaltyProgramsTitle' />
        </h6>
        <CardBody className='p-2'>
          {loyaltyProgramAccount &&
            !isEmptyArray(loyaltyProgramAccount.achievedLoyaltyPrograms) && (
              <Fragment>
                <p className='text-center m-0' style={{fontSize: 'small'}}>
                  <Translate
                    className='text-center'
                    value='beforeChooseValuation.achievedLoyaltyProgramsOnlyOneTip'
                  />
                </p>
                <p className='text-center m-0' style={{fontSize: 'small'}}>
                  <Translate value='beforeChooseValuation.achievedLoyaltyProgramsCollectedPointsTip' />
                  <b> {loyaltyProgramAccount.collectedPoints}</b>
                </p>
              </Fragment>
            )}
          <LoyaltyProgramList
            loyaltyPrograms={loyaltyProgramAccount.achievedLoyaltyPrograms}
            emptyMessageKey='beforeChooseValuation.noAchievedLoyaltyPrograms'
            onClickLoyaltyProgram={this.onClickLoyaltyProgram}
          />
        </CardBody>
      </Card>
    );
  }

  onConfirm() {
    this.props.handleConfirm(this.state);
  }

  renderContent() {
    const {handleCancel} = this.props;

    return (
      <Fragment>
        {this.renderConfirmChooseValuationAlert()}

        {/** //	USER_DATE_CHOOSE_BLOCK this.renderCalendar()*/}
        {this.renderLoyaltyPrograms()}

        <div className='text-sm-right text-center mt-3'>
          <Button
            id='confirm-before-choose-valuation-modal'
            className='main-btn'
            onClick={this.onConfirm}
            /*USER_DATE_CHOOSE_BLOCK
            disabled={this.state.appointmentDate === null}
            */
          >
            <Translate value='beforeChooseValuation.confirmBtn' />
          </Button>
          <Button
            id='cancel-before-choose-valuation-modal'
            className='main-btn'
            onClick={handleCancel}>
            <Translate value='beforeChooseValuation.cancelBtn' />
          </Button>
        </div>
      </Fragment>
    );
  }

  render() {
    const {isOpen} = this.props;

    return (
      <Fragment>
        <GenericModal
          content={this.renderContent()}
          isOpen={isOpen}
          title='valuationDetails.beforeChooseValuationModalTitle'
        />
      </Fragment>
    );
  }
}

BeforeChooseValuationModal.propTypes = {
  isOpen: PropTypes.bool,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  showConfirmChooseValuationAlert: PropTypes.bool,
  loyaltyProgramAccount: PropTypes.object,
  workshopUserId: PropTypes.string,
};

BeforeChooseValuationModal.defaultProps = {
  loyaltyProgramAccount: {},
};
