/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {CreateOrderCustomerRatingModal} from 'layout/workshop/order-rating/CreateOrderCustomerRatingModal';
import WorkshopInactiveStatusAlert from 'layout/workshop/utils/WorkshopInactiveStatusAlert';
import RenderValuationServicesDetails from 'layout/workshop/valuations/valuation-details/render-valuations-details/render-services-details/RenderValuationServicesDetails';
import {RenderValuationSummaryDetails} from 'layout/workshop/valuations/valuation-details/render-valuations-details/render-summary-details/RenderValuationSummaryDetails';
import RenderValuationWorkshopInfoDetails from 'layout/workshop/valuations/valuation-details/render-valuations-details/render-workshop-info-details/RenderValuationWorkshopInfoDetails';
import RenderValuationInquiryDetails from 'layout/workshop/valuations/valuation-inquiry-details/render-valuation-inquiry-details/RenderValuationInquiryDetails';
import {mapToDetailsObject} from 'layout/workshop/valuations/valuation-inquiry-details/render-valuation-inquiry-details/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate, I18n} from 'react-redux-i18n';
import {Alert, Button, UncontrolledTooltip} from 'reactstrap';
import {orderActions} from '../../../actions/order.actions';
import {partCategoryActions} from '../../../actions/part-category.actions';
import {serviceCategoryActions} from '../../../actions/service-category.actions';
import {orderConstants} from '../../../constants/order.constants';
import {updateDataSideBarConstants} from '../../../constants/update-data-side-bar.constants';
import {userService} from '../../../services/user.service';
import {DATE_FORMAT_SHORT, dateUtils} from '../../../utils/date.utils';
import {ConfirmModal} from '../../modal/ConfirmModal';
import {ProposeAppointmentDateModal} from '../propose-appointment-date-modal/ProposeAppointmentDateModal';
import WarrantyModal from '../../../../warranty/warranty-modal/WarrantyModal';

class OrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmCancelModalOpen: false,
    };

    this.confirmOrder = this.confirmOrder.bind(this);
    this.completeOrder = this.completeOrder.bind(this);
    this.openCreateWarrantyBookModal =
      this.openCreateWarrantyBookModal.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
    this.load = this.load.bind(this);
    this.proposeAppointmentDate = this.proposeAppointmentDate.bind(this);
    this.confirmAppointmentDate = this.confirmAppointmentDate.bind(this);
  }

  componentDidMount() {
    this.loadServicesAndParts();
    this.load();
  }

  componentDidUpdate(prevProps) {
    const {orderId, activeOrder} = this.props;
    if (prevProps.orderId !== orderId) this.load();
  }

  loadServicesAndParts() {
    this.props.findAllServiceCategories();
    this.props.findLpgServiceCategoryId();
    this.props.findAllPartCategories();
  }

  componentWillUnmount() {
    this.props.clearActiveOrder();
    this.props.clearActiveOrderRelatedValuation();
  }

  load() {
    const {
      findOrderByIdWithRelatedValuation,
      orderId,
      onFetchError,
      getOrderMinCancelDate,
    } = this.props;

    findOrderByIdWithRelatedValuation(orderId, onFetchError);
    getOrderMinCancelDate(orderId);
  }

  confirmOrder() {
    const {activeOrder} = this.props;
    if (activeOrder) this.props.confirmOrder(activeOrder.id, this.load);
  }

  completeOrder() {
    const {activeOrder} = this.props;
    if (activeOrder) this.props.completeOrder(activeOrder.id, this.load);
  }
  openCreateWarrantyBookModal() {
    const {
      activeOrderRelatedValuationInquiry,
      activeOrder,
      lpgServiceCategoryId,
      activeOrderRelatedValuation,
    } = this.props;
    const {
      carBrand,
      carEngineCapacityCm3,
      carModel,
      carProductionYear,
      carEnginePowerKw,
    } = activeOrderRelatedValuationInquiry;
    const lpgServiceCategoryParams = activeOrderRelatedValuation.services.find(
      service => service.serviceCategoryId === lpgServiceCategoryId
    ).params;
    const {vendorName} = activeOrder;
    const transferObject = {
      carBrand,
      carEngineCapacityCm3,
      carEnginePowerKw,
      carModel,
      carProductionYear,
      lpgServiceCategoryParams,
      vendorName,
    };
    this.props.hideUpdateDataSideBar();
    this.props.showUpdateDataSideBar(
      <WarrantyModal wannahCreateStep transferObject={transferObject} />,
      I18n.t('warranty.wannahCreateWarrantyBook')
    );
  }

  cancelOrder() {
    const {activeOrder} = this.props;
    if (activeOrder) this.props.cancelOrder(activeOrder.id, this.load);
  }

  shouldShowCustomerRatings() {
    const {activeOrderRelatedValuationInquiry} = this.props;
    return (
      userService.isWorkshop() &&
      activeOrderRelatedValuationInquiry &&
      activeOrderRelatedValuationInquiry.senderUserId
    );
  }

  renderCarDetails() {
    const {activeOrderRelatedValuationInquiry} = this.props;
    return (
      <div className='row'>
        <div className='col-12'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'carDataTitle'}
            detailsData={
              activeOrderRelatedValuationInquiry &&
              mapToDetailsObject('car', activeOrderRelatedValuationInquiry)
            }
          />
        </div>
      </div>
    );
  }

  renderUserDetails() {
    const {activeOrderRelatedValuationInquiry} = this.props;
    return (
      <div className='row'>
        <div className='col-12'>
          <RenderValuationInquiryDetails
            headerTitleDetails={'userDataTitle'}
            detailsData={
              activeOrderRelatedValuationInquiry &&
              mapToDetailsObject('user', activeOrderRelatedValuationInquiry)
            }
            customerUserId={
              this.shouldShowCustomerRatings() &&
              activeOrderRelatedValuationInquiry.senderUserId
            }
          />
        </div>
      </div>
    );
  }

  renderProposeAppointmentDateModal() {
    const {activeOrder, activeOrderRelatedValuation} = this.props;
    const show =
      this.orderExists(activeOrder) &&
      (activeOrder.status === 'NEW' || activeOrder.status === 'CONFIRMED');
    return (
      show && (
        <ProposeAppointmentDateModal
          action={this.proposeAppointmentDate}
          currentAppointmentDate={activeOrder.appointmentDate}
          workshopUserId={activeOrderRelatedValuation.senderUserId}
          orderStatus={activeOrder.status}
        />
      )
    );
  }

  orderExists(order) {
    return order && order.id;
  }

  proposeAppointmentDate({appointmentDate}) {
    const {proposeAppointmentDate, activeOrder} = this.props;
    return proposeAppointmentDate(activeOrder.id, appointmentDate);
  }

  renderConfirmAppointmentDateButton() {
    const {activeOrder} = this.props;
    const show =
      this.orderExists(activeOrder) &&
      userService.isUser() &&
      activeOrder.status === 'NEW' &&
      !activeOrder.appointmentDateConsensus;
    return (
      show && (
        <Button
          id='confirm-appointment-date'
          className='main-btn'
          onClick={this.confirmAppointmentDate}
        >
          <Translate value='orderDetails.confirmAppointmentDateBtn' />
        </Button>
      )
    );
  }

  confirmAppointmentDate() {
    const {confirmAppointmentDate, activeOrder} = this.props;
    return confirmAppointmentDate(activeOrder.id);
  }

  renderCancelOrderButton() {
    const {activeOrder, orderMinCancelDate} = this.props;
    const show =
      this.orderExists(activeOrder) &&
      (activeOrder.status === 'NEW' || activeOrder.status === 'CONFIRMED');
    const blockCancel =
      activeOrder.status === 'CONFIRMED' &&
      orderMinCancelDate &&
      moment().isAfter(moment(orderMinCancelDate));

    return (
      show && (
        <Fragment>
          <Button
            id='cancel-order'
            className='main-btn'
            disabled={blockCancel}
            onClick={() => this.setState({confirmCancelModalOpen: true})}
          >
            <Translate value='orderDetails.cancelOrderBtn' />
          </Button>
          {blockCancel && (
            <UncontrolledTooltip
              placement='top'
              target='cancel-order'
              innerClassName='custom-tooltip'
            >
              <Translate value='orderDetails.minCancelDateExceeded' />:{' '}
              {dateUtils.formatDate(orderMinCancelDate)}
            </UncontrolledTooltip>
          )}
        </Fragment>
      )
    );
  }

  renderConfirmCancelModal() {
    return (
      <ConfirmModal
        title='confirmModal.cancel'
        isOpen={this.state.confirmCancelModalOpen}
        action={this.cancelOrder}
        handleCancel={() => this.setState({confirmCancelModalOpen: false})}
      />
    );
  }

  renderConfirmOrderButton() {
    const {activeOrder} = this.props;
    const show =
      this.orderExists(activeOrder) &&
      userService.isWorkshop() &&
      activeOrder.status === 'NEW' &&
      activeOrder.appointmentDateConsensus;
    return (
      show && (
        <Button
          id='confirm-order'
          className='main-btn'
          onClick={this.confirmOrder}
        >
          <Translate value='orderDetails.confirmOrderBtn' />
        </Button>
      )
    );
  }

  renderCompleteOrderButton() {
    const {activeOrder} = this.props;
    const show =
      this.orderExists(activeOrder) &&
      userService.isWorkshop() &&
      activeOrder.status === 'CONFIRMED';
    return (
      show && (
        <Button
          id='complete-order'
          className='main-btn'
          onClick={this.completeOrder}
        >
          <Translate value='orderDetails.completeOrderBtn' />
        </Button>
      )
    );
  }
  renderWarrantyBookButton() {
    const {activeOrder, activeOrderRelatedValuation, lpgServiceCategoryId} =
      this.props;

    const show =
      userService.isWorkshop() &&
      this.orderExists(activeOrder) &&
      activeOrder.status === 'COMPLETED' &&
      activeOrderRelatedValuation.services.some(
        service => service.serviceCategoryId === lpgServiceCategoryId
      );

    return (
      show && (
        <Button
          id='warranty-book'
          className='main-btn'
          onClick={() => this.openCreateWarrantyBookModal()}
        >
          <Translate value='orderDetails.warrantyBookBtn' />
        </Button>
      )
    );
  }
  renderCreateOrderCustomerRatingModal() {
    const {activeOrder} = this.props;
    const show =
      userService.isWorkshop() &&
      this.orderExists(activeOrder) &&
      activeOrder.status === 'COMPLETED' &&
      activeOrder.userIdsWhoRated.indexOf(userService.getUserId()) < 0;

    return (
      show && (
        <CreateOrderCustomerRatingModal
          orderId={activeOrder.id}
          onCreateOrderRatingSuccess={this.load}
        />
      )
    );
  }

  renderExitBtn() {
    return (
      <Button
        id='exit'
        className='main-btn'
        onClick={this.props.hideUpdateDataSideBar}
      >
        <Translate value='orderDetails.exit' />
      </Button>
    );
  }

  renderButtons() {
    console.log(this.props);
    return (
      <div
        className='row py-3 text-sm-right text-center'
        style={{display: 'flex', justifyContent: 'flex-end', marginRight: 3}}
      >
        {userService.isWorkshop() && this.renderProposeAppointmentDateModal()}
        {this.renderConfirmAppointmentDateButton()}
        {this.renderCancelOrderButton()}
        {/* USER_DATE_CHOOSE_BLOCK this.renderConfirmOrderButton()*/}
        {this.renderCompleteOrderButton()}
        {this.renderWarrantyBookButton()}
        {this.renderCreateOrderCustomerRatingModal()}
        {this.renderExitBtn()}
      </div>
    );
  }

  renderLoyaltyProgramIncludedTip(activeOrder) {
    return (
      activeOrder.includedLoyaltyProgram && (
        <Alert
          id='loyalty-program-included-tip'
          className='text-center m-3'
          color='info'
        >
          <i className='fa fa-star' />
          &nbsp;
          <Translate value='orderDetails.loyaltyProgramIncludedTip' />
          &nbsp;
          <b>{activeOrder.includedLoyaltyProgram.title}</b>
        </Alert>
      )
    );
  }

  renderContent() {
    const {
      activeOrder,
      shouldShowWorkshopStatusAlert,
      activeOrderRelatedValuation,
      showWorkshopDetails,
      showUserDetails,
    } = this.props;

    return (
      activeOrder &&
      activeOrderRelatedValuation && (
        <div className='m-auto' style={{width: '90%'}}>
          <WorkshopInactiveStatusAlert show={shouldShowWorkshopStatusAlert} />

          <RenderValuationServicesDetails
            valuation={activeOrderRelatedValuation}
            isValuation={false}
          />
          {this.renderCarDetails()}
          {showUserDetails && this.renderUserDetails()}
          {showWorkshopDetails && (
            <RenderValuationWorkshopInfoDetails
              valuation={activeOrderRelatedValuation}
            />
          )}
          <RenderValuationSummaryDetails
            valuation={activeOrderRelatedValuation}
          />

          {this.renderLoyaltyProgramIncludedTip(activeOrder)}

          <div className='m-auto'>
            {!shouldShowWorkshopStatusAlert && this.renderButtons()}
          </div>
        </div>
      )
    );
  }

  renderConfirmAppointmentDate(ord) {
    if (ord && ord.status === 'NEW') return null;

    return (
      <>
        <Translate value={'orderDetails.appointmentDate'} />
        {ord &&
          ` ${dateUtils.formatDate(ord.appointmentDate, DATE_FORMAT_SHORT)}`}
      </>
    );
  }

  render() {
    const {activeOrder, isFetching} = this.props;

    return (
      <div>
        <Fragment>
          <Loadable active={isFetching} spinner>
            <h5 className='text-center py-3 mb-0 read-only-label'>
              {/*USER_DATE_CHOOSE_BLOCK
              {activeOrder && activeOrder.status !== 'NEW' ? (
                <Translate value={'orderDetails.appointmentDate'} />
              ) : (
                <Translate value={'orderDetails.appointmentDateProposal'} />
              )}
              */}
              {this.renderConfirmAppointmentDate(activeOrder)}
            </h5>
            {this.renderConfirmCancelModal()}
            {this.renderContent()}
          </Loadable>
        </Fragment>
      </div>
    );
  }
}

OrderDetails.propTypes = {
  orderId: PropTypes.string.isRequired,
  shouldShowWorkshopStatusAlert: PropTypes.bool,
  findOrderByIdWithRelatedValuation: PropTypes.func.isRequired,
  confirmOrder: PropTypes.func.isRequired,
  clearActiveOrder: PropTypes.func.isRequired,
  clearActiveOrderRelatedValuation: PropTypes.func.isRequired,
  onFetchError: PropTypes.func,
  showWorkshopDetails: PropTypes.bool,
  showUserDetails: PropTypes.bool,
};

OrderDetails.defaultProps = {
  onFetchError: () => {},
  showWorkshopDetails: false,
  showUserDetails: false,
};

function mapStateToProps(state) {
  const {order, service, part} = state;
  const activeOrder = order.order;
  const activeOrderRelatedValuation = order.valuation;
  const activeOrderRelatedValuationInquiry = order.valuationInquiry;
  const isFetching = order.isFetching || service.isFetching || part.isFetching;
  const categories = order.categories;
  const {orderMinCancelDate} = order;
  const lpgServiceCategoryId = service.lpgServiceCategoryId;

  return {
    isFetching,
    activeOrder,
    activeOrderRelatedValuation,
    activeOrderRelatedValuationInquiry,
    orderMinCancelDate,
    lpgServiceCategoryId,
  };
}

const mapDispatchToProps = {
  findOrderByIdWithRelatedValuation:
    orderActions.findOrderByIdWithRelatedValuation,
  confirmOrder: orderActions.confirmOrder,
  completeOrder: orderActions.completeOrder,
  cancelOrder: orderActions.cancelOrder,
  proposeAppointmentDate: orderActions.proposeAppointmentDate,
  confirmAppointmentDate: orderActions.confirmAppointmentDate,
  clearActiveOrder: () => ({type: orderConstants.CLEAR_ORDER}),
  clearActiveOrderRelatedValuation: () => ({
    type: orderConstants.CLEAR_VALUATION,
  }),
  findAllServiceCategories: serviceCategoryActions.findAllServiceCategories,
  findLpgServiceCategoryId: serviceCategoryActions.findLpgServiceCategoryId,
  findAllPartCategories: partCategoryActions.findAllPartCategories,
  getOrderMinCancelDate: orderActions.getOrderMinCancelDate,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedOrderDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetails);
export {connectedOrderDetails as OrderDetails};
