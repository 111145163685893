/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const subscriptionService = {
  createSubscription,
  getSubscriptionEndDate,
  findAllSubscriptionPaymentsByUserId,
  cancelSubscriptionPayment,
  findAllSubscriptionPayments,
};

export const SUBSCRIPTION_API_BASE_PATH = '/subscription/subscriptions';

function createSubscription(data) {
  return axiosService
    .post(`${SUBSCRIPTION_API_BASE_PATH}`, data)
    .then(response => {
      return response;
    });
}

function getSubscriptionEndDate(userId) {
  return axiosService
    .get(`${SUBSCRIPTION_API_BASE_PATH}/${userId}/end-date`)
    .then(response => {
      return response;
    });
}

function findAllSubscriptionPaymentsByUserId(userId) {
  return axiosService
    .get(`${SUBSCRIPTION_API_BASE_PATH}/${userId}/payments`)
    .then(response => {
      return response;
    });
}

function cancelSubscriptionPayment(userId, subscriptionPaymentId) {
  return axiosService
    .post(
      `${SUBSCRIPTION_API_BASE_PATH}/${userId}/payments/${subscriptionPaymentId}/cancel`
    )
    .then(response => {
      return response;
    });
}

function findAllSubscriptionPayments() {
  return axiosService
    .get(`${SUBSCRIPTION_API_BASE_PATH}/payments`)
    .then(response => {
      return response;
    });
}
