/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {dateUtils, DATE_FORMAT_SHORT} from '../../../utils/date.utils';
import {getOrEmptyArray, indexArray} from '../../../utils/utils';

class SubscriptionPlansTable extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  prepareToDisplay(items) {
    return getOrEmptyArray(indexArray(items)).map(item => ({
      ...item,
      periodFormatted: I18n.t(`subscriptionPlan.period${item.period}`),
      priceFormatted: `${item.price.value} ${item.price.currency}`,
      countryFormatted: I18n.t('country.' + item.country.toLowerCase()),
      statusFormatted: I18n.t(`subscriptionPlan.status${item.status}`),
      activityStartDateFormatted: dateUtils.formatDate(
        item.activityStartDate,
        DATE_FORMAT_SHORT
      ),
      activityEndDateFormatted: dateUtils.formatDate(
        item.activityEndDate,
        DATE_FORMAT_SHORT
      ),
    }));
  }

  renderCreateBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={() => this.props.onCreateClick()}>
        <Translate value='subscriptionPlan.createBtn' />
      </Button>
    );
  }

  render() {
    const {subscriptionPlans, headerBuilder, onRowClick, renderRowsDropdown} =
      this.props;

    return (
      <div className='p-3'>
        <DataTable
          value={this.prepareToDisplay(subscriptionPlans)}
          header={headerBuilder()}
          rows={this.props.rows}
          globalFilter={this.props.globalFilter}
          paginator={true}
          responsive={true}
          className='data-table-padding-class'
          emptyMessage={I18n.t('subscriptionPlan.emptyPlaceholder')}
          onRowClick={onRowClick}
          paginatorLeft={renderRowsDropdown()}
          paginatorRight={this.renderCreateBtn()}
          ref={this.setDatatableRef}>
          <Column
            field='index'
            className='lp-col'
            header={I18n.t('subscriptionPlan.lp')}
            sortable={true}
          />

          <Column
            field='label'
            header={I18n.t('subscriptionPlan.label')}
            sortable={true}
          />

          <Column
            field='periodFormatted'
            header={I18n.t('subscriptionPlan.period')}
            sortable={true}
          />

          <Column
            field='priceFormatted'
            header={I18n.t('subscriptionPlan.price')}
            sortable={true}
          />

          <Column
            field='countryFormatted'
            header={I18n.t('subscriptionPlan.country')}
            sortable={true}
          />

          <Column
            field='statusFormatted'
            header={I18n.t('subscriptionPlan.status')}
            sortable={true}
          />

          <Column
            field='activityStartDateFormatted'
            header={I18n.t('subscriptionPlan.activityStartDate')}
            sortable={true}
          />

          <Column
            field='activityEndDateFormatted'
            header={I18n.t('subscriptionPlan.activityEndDate')}
            sortable={true}
          />
        </DataTable>
      </div>
    );
  }
}

SubscriptionPlansTable.propTypes = {
  subscriptionPlans: PropTypes.array,
  onRowClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

SubscriptionPlansTable.defaultProps = {
  subscriptionPlans: [],
  onRowClick: () => {},
  onCreateClick: () => {},
};

export {SubscriptionPlansTable as SubscriptionPlansTableCore};

export default withDataTableCore(SubscriptionPlansTable);
