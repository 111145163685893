/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {InputText} from 'primereact/inputtext';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import {Tooltip} from 'reactstrap';
import {errorColor} from '../../../utils/style.utils';
import '../../input/text-input/text-input.css';
import './text-field.css';

export default class RenderField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {tooltipOpen: false, filled: false};
    this.toggle = this.toggle.bind(this);
    this.removeDotsFromInputNameAndJoin =
      this.removeDotsFromInputNameAndJoin.bind(this);
  }

  componentDidMount() {
    const {input} = this.props;
    if (input && input.value && input.value.toString().length > 0)
      this.setState({filled: true});
  }

  componentDidUpdate(prevProps) {
    this.checkFilled(prevProps);
  }

  checkFilled(prevProps) {
    const input = this.props.input;
    const prevValueLength = prevProps.input.value.toString().length;

    if (
      input &&
      input.value &&
      prevProps.input &&
      input.value !== prevProps.input.value
    )
      this.setState({filled: true});
    else if (
      input &&
      (!input.value || input.value.toString().length === 0) &&
      prevValueLength > 0
    )
      this.setState({filled: false});
  }

  toggle() {
    this.setState({tooltipOpen: !this.state.tooltipOpen});
  }

  renderTooltip() {
    const {
      meta: {active},
      withTooltip,
      tooltipContent,
      tooltipClassName,
    } = this.props;
    if (withTooltip)
      return (
        <Tooltip
          innerClassName={`custom-tooltip ${
            tooltipClassName ? tooltipClassName : ''
          }`}
          placement='top'
          isOpen={active || this.state.tooltipOpen}
          target={this.removeDotsFromInputNameAndJoin()}
          toggle={this.toggle}>
          <Translate value={tooltipContent} />
        </Tooltip>
      );
  }

  renderAdditionalInfoInfoIcon() {
    const {additionalInfoContent} = this.props;
    return (
      <span>
        <span
          id='additional-info-icon'
          data-tip=''
          data-for='additional-info-icon'
          style={{margin: '.5em .25em 0 -1.5em'}}>
          <i className='fa fa-info-circle mr-1' style={{color: '#2A3FAB'}} />
        </span>
        <ReactTooltip
          id='additional-info-icon'
          className='form-tooltip-short'
          effect='solid'
          globalEventOff='click'
          place='top'
          getContent={additionalInfoContent}
        />
      </span>
    );
  }

  removeDotsFromInputNameAndJoin() {
    return this.props.withTooltip
      ? this.props.input.name.split('.').join('')
      : this.props.input.name;
  }

  render() {
    const {
      input,
      label,
      type,
      meta: {touched, error},
      required,
      inputDisabled,
      suffixAddon,
      inputReadOnly,
      additionalInfoContent,
      hasErrors,
      prefixIcon,
    } = this.props;
    const inputId = this.removeDotsFromInputNameAndJoin();

    return (
      <div className='row' style={{marginTop: '2.5rem'}}>
        <div className='col-12'>
          <div className='flex'>
            {additionalInfoContent && this.renderAdditionalInfoInfoIcon()}
            {prefixIcon && (
              <img
                src={prefixIcon}
                alt='prefixIcon'
                style={{height: '24px', width: '24px'}}
              />
            )}
            <div className='text-input-container p-float-label'>
              <InputText
                id={inputId}
                {...input}
                type={type}
                valid={!error && input ? 'true' : undefined}
                className={
                  'w-100' + (this.state.filled ? ' ui-state-filled-hack' : '')
                }
                disabled={inputDisabled}
                readOnly={inputReadOnly}
                style={
                  touched && error ? {borderBottomColor: errorColor} : null
                }
              />
              <label
                htmlFor={inputId}
                style={
                  (touched && error) || hasErrors ? {color: errorColor} : null
                }>
                {label}
                {required && <span style={{color: errorColor}}>*</span>}
              </label>
            </div>
            {suffixAddon}
            {this.renderTooltip()}
          </div>
        </div>
        <div id='error-msg' className='col-12'>
          {touched && error ? (
            <span className='error-info'>
              {I18n.t(error.key, error.params)}
            </span>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}
