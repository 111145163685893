/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {SyncState} from '../utils/sync';
import {statisticConstants} from '../constants/statistic.constants';

export function statisticReducer(state = {}, action) {
  switch (action.type) {
    case statisticConstants.STATISTIC_REQUEST_START:
      return {...state, isFetching: true};
    case statisticConstants.STATISTIC_REQUEST_END:
      return {...state, isFetching: false};
    case statisticConstants.LOAD_STATISTICS_DATA:
      return {
        ...state,
        statistics: action.payload.data.statistics,
        totalCount: action.payload.data.totalCount,
      };
    case statisticConstants.CLEAR_STATISTICS_DATA:
      return {...state, statistics: [], totalCount: 0};
    case statisticConstants.LOAD_CITIES_DATA:
      return {...state, cities: action.payload.data};
    case statisticConstants.LOAD_STATES_DATA:
      return {...state, states: action.payload.data};
    case statisticConstants.LOAD_WORKSHOP_OPTIONS:
      return {...state, workshops: action.payload.data};
    default:
      return state;
  }
}
