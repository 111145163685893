/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export class ValuationInquirySummaryModel {
  carBrand;
  carModel;
  carProductionYear;
  carEngineCapacityCm3;
  carEnginePowerKw;
  carFuelTypeName;
  userFirstName;
  userLastName;
  userCity;
  userVoivodeship;
  userEmail;
  userPhoneNumber;
  userRemarks;

  constructor(formValues, fuelTypes) {
    this.carBrand = formValues.carBrand;
    this.carModel = formValues.carModelOther
      ? formValues.carModelOther
      : formValues.carModel;
    this.carProductionYear = formValues.carProductionYear;
    this.carEngineCapacityCm3 = formValues.carEngineCapacityCm3;
    this.carEnginePowerKw = formValues.carEnginePowerKw;
    this.carFuelTypeName = fuelTypes.find(fuel => {
      return fuel.fuelTypeId === formValues.carFuelTypeId;
    }).name;
    this.userFirstName = formValues.userFirstName;
    this.userLastName = formValues.userLastName;
    this.userCity = formValues.userCity;
    this.userVoivodeship = formValues.userVoivodeship;
    this.userEmail = formValues.userEmail;
    this.userPhoneNumber = formValues.userPhoneNumber;
    this.userRemarks = formValues.userRemarks;
  }

  equals(values) {
    return values
      ? Object.keys(values).every(key => {
          return values[key] === this[key];
        })
      : false;
  }
}
