/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';

export class GenericModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {title, color, isOpen, content} = this.props;
    const headerStyle = {backgroundColor: color, color: 'white'};
    document.querySelector('body').style.overflowY = 'auto';

    return (
      <div>
        <Modal isOpen={isOpen} style={{border: 'none'}}>
          <ModalHeader style={headerStyle}>
            <Translate value={title} />
          </ModalHeader>
          <ModalBody>{content}</ModalBody>
        </Modal>
      </div>
    );
  }
}

GenericModal.propType = {
  title: PropTypes.string,
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  content: PropTypes.any,
};

GenericModal.defaultProps = {
  title: '',
  color: '#22BAA0',
  isOpen: false,
};
