/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {serviceCategoryService} from 'shared/services/service-category.service';
import {validate} from './service-category-form-validators';
import {ServiceCategoryForm} from './ServiceCategoryForm';

export const asyncValidate = values =>
  serviceCategoryService.existsByName(values.name).then(response => {
    if (response.data)
      return {
        name: {key: 'services.serviceCategoryExistsError', params: {}},
      };
  });

const ServiceCategoryCreateForm = reduxForm({
  form: 'serviceCategoryCreateForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
})(ServiceCategoryForm);

function mapStateToProps(state) {
  const {i18n} = state;
  return {i18n, editable: true};
}

export default connect(mapStateToProps)(ServiceCategoryCreateForm);
