/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {getOrEmptyArray} from '../../../utils/utils';

export function applyLanguageValuesFilter(languageValues, phrase) {
  return getOrEmptyArray(languageValues).filter(languageValue =>
    applyLanguageValueFilter(languageValue, phrase)
  );
}

export function applyLanguageValueFilter(languageValue, phrase) {
  return (
    !canBeFiltered(languageValue, phrase) ||
    matchPhrase(languageValue.key, phrase) ||
    matchPhrase(languageValue.value, phrase)
  );
}

function canBeFiltered(languageValue, phrase) {
  return languageValue && languageValue.key && languageValue.value && phrase;
}

function matchPhrase(value, phrase) {
  return value && value.toLowerCase().indexOf(phrase.toLowerCase()) !== -1;
}
