/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import RenderService from './RenderService';
import RenderOtherServices from './RenderOtherServices';

class RenderValuationServicesDetails extends React.Component {
  renderDetails(service) {
    service.serviceName = this.getServiceName(service);
    service.categoryName = this.getCategoryName(service);
    service.serviceDescription = this.getServiceDescription(service);
    return (
      <div className='col-12' key={service.serviceId}>
        <RenderService
          service={service}
          isLpgService={this.isLpgService(service)}
          isValuation = {this.props.isValuation}
        />
      </div>
    );
  }

  getServiceName(service) {
    const {categories} = this.props;
    return categories
      .find(cat => cat.id === service.serviceCategoryId)
      .services.find(s => s.id === service.serviceId).name;
  }

  getServiceDescription(service) {
    const {categories} = this.props;
    return categories
      .find(cat => cat.id === service.serviceCategoryId)
      .services.find(s => s.id === service.serviceId).description;
  }

  getCategoryName(service) {
    const {categories} = this.props;
    return categories.find(cat => cat.id === service.serviceCategoryId).name;
  }

  isLpgService(service) {
    const {lpgServiceCategoryId} = this.props;
    return service.serviceCategoryId === lpgServiceCategoryId;
  }

  renderOtherServices() {
    const {valuation} = this.props;
    if (valuation && valuation.valuationInquiryId) {
      return (
        <div className='col-12' key={'other-services'}>
          <RenderOtherServices
            valuationInquiryId={valuation.valuationInquiryId}
          />
        </div>
      );
    }
  }

  render() {
    const {valuation} = this.props;
    return (
      <Fragment>
        <div className='row pt-3'>
          {valuation &&
            valuation.services.map(service => this.renderDetails(service))}
          {valuation && this.renderOtherServices()}
        </div>
      </Fragment>
    );
  }
}

RenderValuationServicesDetails.propsTypes = {
  valuation: PropTypes.object,
};

const mapStateToProps = ({service}) => {
  const {categories, lpgServiceCategoryId} = service;

  return {categories, lpgServiceCategoryId};
};

export default connect(mapStateToProps)(RenderValuationServicesDetails);
