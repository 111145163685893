/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { RenderField } from "../../../../../shared/components/form/text-field/hook-form-version/RenderField";
import { I18n } from "react-redux-i18n";
import { useForm } from "react-hook-form";
import { ReqexpValidationEnum } from "../../../../../shared/utils/utils";
import { updateDataSideBarConstants } from "../../../../../shared/constants/update-data-side-bar.constants";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "primereact/checkbox";
import { Translate } from 'react-redux-i18n';
import { apiUrl } from '../../../../../environment';
import Loadable from 'react-loading-overlay';
import {workshopLoyaltyProgramActions} from "shared/actions/workshopLoyaltyProgram.actions";

const WorkshopRegisterDataForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    setValue,
    handleSubmit,
    register,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const [isStatuteAccepted, setIsStatuteAccepted] = useState(false);
  const workshopData = useSelector(state => state.workshop.data);
  const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);

  const workshopNameField = "workshopName";
  const workshopWorkerField = "workshopWorker";
  const cityField = "city";
  const postalCodeField = "postalCode";
  const streetAddressField = "streetAddress";
  const emailField = "email";
  const phoneNumberField = "phoneNumber";
  const nipField = "nip";
  const statuteAcceptedField = "statuteAccepted";

  useImperativeHandle(ref, () => ({
    onSave: handleSubmit((data) => onSubmit(data)),
  }));

  useEffect(() => {
    if (workshopData) {
      setValue(workshopNameField, workshopData.workshopDetails.workshopName);
      setValue(streetAddressField, workshopData.address.streetAddress);
      setValue(postalCodeField, workshopData.address.postalCode);
      setValue(cityField, workshopData.address.city);
      if (workshopData.workshopDetails) {
        workshopData.workshopDetails.phoneNumber?.phoneNumber ? 
          setValue(phoneNumberField, `${workshopData.workshopDetails.phoneNumber.phoneNumberPrefix}${workshopData.workshopDetails.phoneNumber.phoneNumber}`) 
          : setValue(phoneNumberField, "");
        workshopData.workshopDetails.ownerName ? setValue(workshopWorkerField, workshopData.workshopDetails.ownerName) : setValue(workshopWorkerField, "");
        workshopData.workshopDetails.nip ? setValue(nipField, workshopData.workshopDetails.nip) : setValue(nipField, "");
      }
      if (workshopData.email) {
        setValue(emailField, workshopData.email);
      }
    }
  }, [workshopData]);

  const onSubmit = (data) => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    let objectToSave = {
      workshopName: data.workshopName,
      workshopWorker: data.workshopWorker,
      address: {
          city: data.city,
          postalCode: data.postalCode,
          streetAddress: data.streetAddress,
      },
      email: data.email,
      phoneNumber: data.phoneNumber,
      nip: data.nip,
      statuteAccepted: true,
      workshopId: workshopData.workshopId,
    }
    dispatch(workshopLoyaltyProgramActions.registerInWorkshopLoyaltyProgram(objectToSave));
  }

  return (
    <>
      <RenderField
        {...register(workshopNameField, { maxLength: 60, required: true })}
        label={I18n.t("workshopLoyaltyProgram.register.workshopName")}
        value={watch(workshopNameField)}
        error={errors[workshopNameField]}
        type="text"
      />
      <RenderField
        {...register(workshopWorkerField, {
          maxLength: 255,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.representant")}
        error={errors[workshopWorkerField]}
        value={watch(workshopWorkerField)}
        type="text"
      />
      <RenderField
        {...register(cityField, {
          maxLength: 60,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.city")}
        error={errors[cityField]}
        value={watch(cityField)}
        type="text"
      />
      <RenderField
        {...register(streetAddressField, {
          maxLength: 255,
          required: true
        })}
        label={I18n.t("workshopLoyaltyProgram.register.address")}
        error={errors[streetAddressField]}
        value={watch(streetAddressField)}
        type="text"
      />
      <RenderField
        {...register(emailField, {
          maxLength: 60,
          pattern: ReqexpValidationEnum.EMAIl,
          required: true
        })}
        validateOnBlur={true}
        label={I18n.t("workshopLoyaltyProgram.register.email")}
        error={errors[emailField]}
        value={watch(emailField)}
        type="text"
      />
      <RenderField
        {...register(phoneNumberField, {
          maxLength: 20,
          pattern: ReqexpValidationEnum.PHONE_NUMBER,
          required: true
        })}
        validateOnBlur={true}
        label={I18n.t("workshopLoyaltyProgram.register.phone")}
        value={watch(phoneNumberField)}
        error={errors[phoneNumberField]}
        type="text"
      />
      <RenderField
        {...register(nipField, {
          pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          maxLength: 10,
          required: true
        })}
        error={errors[nipField]}
        label={I18n.t("workshopLoyaltyProgram.register.nip")}
        value={watch(nipField)}
        type="text"
      />
      <div style={{ marginTop: '1rem' }}>
        <Checkbox
          {...register(statuteAcceptedField, {
            validate: {
              isTrue: v => v === true
            }
          })}
          classname='mr-1'
          onChange={(event) => {
            setValue(statuteAcceptedField, event.checked);
            setIsStatuteAccepted(event.checked);
            (errors[statuteAcceptedField] && event.checked) ? clearErrors(statuteAcceptedField) : null;
          }}
          checked={isStatuteAccepted}
          value={isStatuteAccepted}
          required
        />
        <span style={{ paddingLeft: 9 }}>{I18n.t('workshopLoyaltyProgram.register.statuteAccepted')}</span>
        {loyaltyProgramStatute &&
          <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
            <span style={{ paddingLeft: 4 }}>{I18n.t('workshopLoyaltyProgram.register.statute')}</span>
          </a>}
        <div id="error-msg" className="col-12" style={{ minHeight: 24, paddingLeft: 0 }}>
          {errors[statuteAcceptedField] &&
            <span className="error-info">
              <Translate value={"workshopLoyaltyProgram.register.acceptation"} />
            </span>
          }
        </div>
      </div>
    </>
  )
});

export default WorkshopRegisterDataForm;
