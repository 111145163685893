/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {dateUtils} from 'shared/utils/date.utils';
import {isEmptyArray} from 'shared/utils/utils';
import './promotions-viewer.css';

export default class PromotionsViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availablePages: [0],
      activePage: 0,
    };
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    if (this.props.promotions) this.setAvailablePages();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.promotions && this.props.promotions)
      this.setAvailablePages();
  }

  setAvailablePages() {
    const {promotions} = this.props;
    let availablePages = [];
    promotions.forEach((promo, index) => availablePages.push(index));
    this.setState({availablePages});
  }

  isLastPage() {
    const {availablePages, activePage} = this.state;
    const lastAvailablePage = availablePages[availablePages.length - 1];
    return activePage === lastAvailablePage;
  }

  nextPage() {
    this.setState({activePage: this.state.activePage + 1});
  }

  prevPage() {
    this.setState({activePage: this.state.activePage - 1});
  }

  renderPageButtons() {
    return (
      <div className='page-buttons'>
        <Button
          className='main-btn page-btn'
          onClick={this.prevPage}
          disabled={this.state.activePage === 0}>
          <i className='fa fa-chevron-left' />
        </Button>
        <Button
          className='main-btn page-btn right-btn'
          onClick={this.nextPage}
          disabled={this.isLastPage()}>
          <i className='fa fa-chevron-right' />
        </Button>
      </div>
    );
  }

  renderPromotionContent() {
    const {promotions, closeViewer} = this.props;
    const visiblePromotion = promotions[this.state.activePage];
    return (
      <Fragment>
        <ModalHeader className='promotion-header' toggle={closeViewer}>
          <h2 className='promotion-title'>{visiblePromotion.title}</h2>
        </ModalHeader>
        <ModalBody className='promotion-content'>
          <div className='promotion-image-container'>
            {visiblePromotion.image && (
              <img
                src={`data:image/png;base64,${visiblePromotion.image}`}
                alt='image'
                className='promotion-image'
              />
            )}
          </div>
          <h5 className='promotion-description'>
            {visiblePromotion.description}
          </h5>
          <p className='promotion-end-date'>
            {`${I18n.t('promotions.activeEndDateLabel')} ${dateUtils.formatDate(
              visiblePromotion.endDate,
              'DD.MM.YYYY'
            )}`}
          </p>
          {this.renderPageButtons()}
        </ModalBody>
      </Fragment>
    );
  }

  render() {
    const {isOpen, promotions} = this.props;
    if (isEmptyArray(promotions)) return null;

    return (
      <Modal isOpen={isOpen} className='promotion-container'>
        {this.renderPromotionContent()}
      </Modal>
    );
  }
}

PromotionsViewer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  promotions: PropTypes.array.isRequired,
  closeViewer: PropTypes.func,
};
