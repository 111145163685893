import {
  validateByLengthOnly,
  validateEmail,
  validateNumberWithRange,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
  validatePhoneNumberWithoutPrefixField,
} from "shared/components/form/common-validators/common-validators";

export const technicalDetailsValidator = (data) => {
  const {
    reducerName,
    reducerSerialNumbers,
    tankName,
    tankSerialNumber,
    stagControlSystemName,
    stagControlSystemSerialNumber,
    injectionRailName,
    injectionRailSerialNumbers,
    pressureTemperatureSensorName,
    pressureTemperatureSensorBarcode,
    pressureTemperatureSensorDate,
    bgSwitch,
    gasLevelIndicatorType,
    gasLevelIndicatorDate,
    workshopWorker,
    workshopName,
    address,
    phoneNumber,
    nip,
    regon,
    workshopWorkerEmail,
    date,
  } = data;
  const errors = {};
  errors.reducerName = validateRequiredFields(reducerName);
  errors.reducerSerialNumbers = validateRequiredFieldsWithMaxLength(reducerSerialNumbers, 255);
  errors.tankName = validateRequiredFields(tankName);
  errors.tankSerialNumber = validateRequiredFieldsWithMaxLength(tankSerialNumber, 255);
  errors.stagControlSystemName = validateRequiredFields(stagControlSystemName);
  errors.stagControlSystemSerialNumber = validateRequiredFieldsWithMaxLength(
    stagControlSystemSerialNumber,
    255
  );
  errors.injectionRailName = validateRequiredFieldsWithMaxLength(injectionRailName);
  errors.injectionRailSerialNumbers = validateRequiredFieldsWithMaxLength(
    injectionRailSerialNumbers,
    255
  );
  errors.pressureTemperatureSensorName = validateRequiredFieldsWithMaxLength(
    pressureTemperatureSensorName
  );
  errors.pressureTemperatureSensorBarcode = validateRequiredFieldsWithMaxLength(
    pressureTemperatureSensorBarcode,
    255
  );
  errors.bgSwitch = validateRequiredFields(bgSwitch);
  errors.gasLevelIndicatorType = validateRequiredFieldsWithMaxLength(
    gasLevelIndicatorType,
    255
  );
  errors.pressureTemperatureSensorDate = validateRequiredFields(pressureTemperatureSensorDate);
  errors.gasLevelIndicatorDate = validateRequiredFields(gasLevelIndicatorDate);
  errors.workshopWorker = validateRequiredFieldsWithMaxLength(workshopWorker, 255);
  errors.workshopName = validateRequiredFieldsWithMaxLength(workshopName, 255);
  errors.address = validateRequiredFieldsWithMaxLength(address, 255);
  errors.phoneNumber = validatePhoneNumberWithoutPrefixField(phoneNumber);
  errors.nip = validateRequiredFieldsWithMaxLength(nip, 10);
  errors.regon = validateRequiredFieldsWithMaxLength(regon, 9);
  errors.workshopWorkerEmail = validateEmail(workshopWorkerEmail);
  errors.date = validateRequiredFields(date);
  const hasErrors = !Object.values(errors).every((x) => x === undefined);
  return { errors, hasErrors };
};

export const descriptionValidator = (data) => {
  const errors = {};
  errors.description = validateByLengthOnly(data, 10000);
  const hasErrors = !!errors.description;
  return { errors, hasErrors };
};

export const notesValidator = (data) => {
  const errors = {};
  errors.notes = validateByLengthOnly(data, 10000);
  const hasErrors = !!errors.notes;
  return { errors, hasErrors };
};

export const carDetailsValidator = (data) => {
  const {
    brand,
    model,
    registrationNumber,
    productionYear,
    gasInstallationDate,
    engineCapacityCm3,
    enginePowerKw,
    mileage,
  } = data;
  const errors = {};
  errors.brand = validateRequiredFields(brand);
  errors.model = validateRequiredFields(model);
  errors.registrationNumber = validateRequiredFieldsWithMaxLength(registrationNumber, 60);
  errors.productionYear = validateRequiredFields(productionYear);
  errors.gasInstallationDate = validateRequiredFields(gasInstallationDate);
  errors.engineCapacityCm3 = validateNumberWithRange(engineCapacityCm3, 1, 20000);
  errors.mileage = validateRequiredFieldsWithMaxLength(mileage, 9);
  errors.enginePowerKw = validateNumberWithRange(enginePowerKw, 1, 9999);
  const hasErrors = !Object.values(errors).every((x) => x == undefined);
  return { errors, hasErrors };
};

export const warrantyNumberValidator = (data) =>{
  const errors = {};
  errors.warrantyNumber = validateRequiredFieldsWithMaxLength(data, 255);
  const hasErrors = !!errors.warrantyNumber;
  return { errors, hasErrors };
};

export const additionalAssemblyValidator = (data) => {
  const {
    workshopWorker,
    workshopName,
    address,
    phoneNumber,
    nip,
    regon,
    workshopWorkerEmail,
    additionalInspectionDescription,
  } = data;
  const errors = {};
  errors.workshopName = validateByLengthOnly(workshopName, 60);
  errors.workshopWorker = validateByLengthOnly(workshopWorker, 60);
  errors.address = validateByLengthOnly(address, 60);
  errors.nip = validateByLengthOnly(nip, 10);
  errors.phoneNumber = validatePhoneNumberWithoutPrefixField(phoneNumber, false);
  errors.regon = validateByLengthOnly(regon, 9);
  errors.workshopWorkerEmail= validateEmail(workshopWorkerEmail, false);
  errors.additionalInspectionDescription = validateByLengthOnly(
    additionalInspectionDescription,
    10000
  );

  const hasErrors = !Object.values(errors).every((x) => x === undefined);
  return { errors, hasErrors };
};
