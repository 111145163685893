/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {settingsActions} from 'shared/actions/settings.actions';
import {Settings} from 'shared/components/settings/Settings';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {becameObsolete} from 'shared/utils/sync';

class SettingsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.settingsDataSyncState,
        this.props.settingsDataSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.findAllSystemSettings();
  }

  update(toUpdate) {
    this.props.updateSystemSettings(toUpdate);
  }

  render() {
    return <Settings {...this.props} update={this.update} />;
  }
}

function mapStateToProps(state) {
  const {settings} = state;
  const {isFetching, loadedSettings, settingsDataSyncState} = settings;

  return {isFetching, loadedSettings, settingsDataSyncState};
}

const mapDispatchToProps = {
  findAllSystemSettings: settingsActions.findAllSystemSettings,
  updateSystemSettings: settingsActions.updateSystemSettings,
};

export const ConnectedSettingsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsWrapper);

export default withDataTableCore(ConnectedSettingsWithRedux, false);
