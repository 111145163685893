/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import * as React from 'react';
import {I18n} from 'react-redux-i18n';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {getOrEmptyArray, indexArray} from '../../../utils/utils';

class LanguageSyncLogsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  prepareLogsToDisplay(logs) {
    return getOrEmptyArray(indexArray(logs)).map(log => ({
      ...log,
      operationTranslated: I18n.t(
        `languageSyncLogs.${log.operation}OperationLabel`
      ),
    }));
  }

  getRowClassName(log) {
    switch (log.operation) {
      case 'add':
        return {'green-row': true};
      case 'remove':
        return {'red-row': true};
      case 'replace':
        return {'orange-row': true};
      default:
        return {};
    }
  }

  render() {
    const {logs, headerBuilder} = this.props;

    return (
      <DataTable
        value={this.prepareLogsToDisplay(logs)}
        paginator={true}
        rows={this.props.rows}
        globalFilter={this.props.globalFilter}
        header={headerBuilder()}
        className='p-3'
        responsive={true}
        emptyMessage={I18n.t(
          'languageSyncLogs.emptyLanguageSyncLogsPlaceholder'
        )}
        rowClassName={this.getRowClassName}>
        <Column
          field='index'
          className='lp-col'
          header={I18n.t('languageSyncLogs.lp')}
          sortable={true}
        />
        <Column
          field='path'
          header={I18n.t('languageSyncLogs.path')}
          sortable={true}
        />
        <Column
          field='operationTranslated'
          header={I18n.t('languageSyncLogs.operation')}
          sortable={true}
        />
      </DataTable>
    );
  }
}

LanguageSyncLogsTable.propTypes = {
  logs: PropTypes.array,
};

LanguageSyncLogsTable.defaultProps = {
  logs: [],
};

export {LanguageSyncLogsTable as LanguageSyncLogsTableCore};

export default withDataTableCore(LanguageSyncLogsTable);
