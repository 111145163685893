/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {subscriptionPlanActions} from 'shared/actions/subscription-plan.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import SubscriptionPlanCreateForm from 'shared/components/subscription/subscription-plan-form/SubscriptionPlanCreateForm';
import SubscriptionPlanEditForm from 'shared/components/subscription/subscription-plan-form/SubscriptionPlanEditForm';
import SubscriptionPlansTable from 'shared/components/subscription/subscription-plans-table/SubscriptionPlansTable';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';

class SubscriptionPlans extends React.Component {
  constructor(props) {
    super(props);

    this.showDetails = this.showDetails.bind(this);
    this.showCreate = this.showCreate.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.subscriptionPlansSyncState,
        this.props.subscriptionPlansSyncState
      )
    )
      this.load();
  }

  load() {
    const {findAllSubscriptionPlans} = this.props;
    findAllSubscriptionPlans();
  }

  showDetails(item) {
    if (item.data.status === 'INACTIVE') return;
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <SubscriptionPlanEditForm
        subscriptionPlanId={item.data.id}
        handleCancel={hideUpdateDataSideBar}
        onActivateSuccess={hideUpdateDataSideBar}
        onDeleteSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('subscriptionPlan.editFormTitle'));
  }

  showCreate() {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <SubscriptionPlanCreateForm
        handleCancel={hideUpdateDataSideBar}
        onCreateSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('subscriptionPlan.createFormTitle'));
  }

  render() {
    const {isFetching, subscriptionPlans} = this.props;

    return (
      <ContentWrapper title='subscription.plansBtn'>
        <Loadable active={isFetching} spinner>
          <SubscriptionPlansTable
            subscriptionPlans={subscriptionPlans}
            onRowClick={this.showDetails}
            onCreateClick={this.showCreate}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, subscriptionPlan} = state;

  const {isFetching, subscriptionPlans, subscriptionPlansSyncState} =
    subscriptionPlan;

  return {
    i18n,
    isFetching,
    subscriptionPlans,
    subscriptionPlansSyncState,
  };
}

const mapDispatchToProps = {
  findAllSubscriptionPlans: subscriptionPlanActions.findAllSubscriptionPlans,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedSubscriptionPlans = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlans);
export {connectedSubscriptionPlans as SubscriptionPlans};
