/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const partService = {
  findAllPartsByCategoryId,
  createPart,
  findPartById,
  updatePart,
  existsByNameAndPartCategoryId,
  deletePart,
};

function findAllPartsByCategoryId(categoryId) {
  return axiosService
    .get(`/workshop/parts/category/${categoryId}`)
    .then(response => {
      return response;
    });
}

function createPart(data) {
  return axiosService.post('/workshop/parts', data).then(response => {
    return response;
  });
}

function findPartById(partId) {
  return axiosService.get(`/workshop/parts/${partId}`).then(response => {
    return response;
  });
}

function updatePart(partId, data) {
  return axiosService.put(`/workshop/parts/${partId}`, data).then(response => {
    return response;
  });
}

function existsByNameAndPartCategoryId(name, partCategoryId) {
  return axiosService.get(
    `/workshop/parts/exists?name=${name}&partCategoryId=${partCategoryId}`
  );
}

function deletePart(partId) {
  return axiosService.delete(`/workshop/parts/${partId}`).then(response => {
    return response;
  });
}
