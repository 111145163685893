/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';

const pdfRequestConfig = {
  responseType: 'blob',
  headers: {
    'Request-Date': `${moment().format()}`,
  },
};

export {pdfRequestConfig};
