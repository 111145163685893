/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {workshopWorkerConstants} from '../constants/workshop-worker.constants';
import {workshopWorkerService} from '../services/workshop-worker.service';
import {messageActions} from './message.actions';

export const workshopWorkerActions = {
  findAllWorkersByWorkshopId,
  findWorkerById,
  updateWorker,
  deleteWorker,
  createWorker,
};

function findAllWorkersByWorkshopId(workshopId) {
  return dispatch => {
    dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START});

    workshopWorkerService.findAllWorkersByWorkshopId(workshopId).then(
      response => {
        dispatch({
          type: workshopWorkerConstants.LOAD_WORKSHOP_WORKERS,
          payload: response,
        });
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorker.fetchError'));
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      }
    );
  };
}

function findWorkerById(workshopWorkerId) {
  return dispatch => {
    dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START});

    workshopWorkerService.findWorkerById(workshopWorkerId).then(
      response => {
        dispatch({
          type: workshopWorkerConstants.LOAD_WORKSHOP_WORKER,
          payload: response,
        });
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      },
      error => {
        dispatch(
          messageActions.errorMessage('workshopWorker.fetchSingleError')
        );
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      }
    );
  };
}

function updateWorker(workshopWorkerId, data) {
  return dispatch => {
    dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START});

    workshopWorkerService.updateWorker(workshopWorkerId, data).then(
      response => {
        dispatch({
          type: workshopWorkerConstants.LOAD_WORKSHOP_WORKER,
          payload: response,
        });
        dispatch({type: workshopWorkerConstants.REFRESH_WORKSHOP_WORKERS});
        dispatch(
          messageActions.successMessage('workshopWorker.updateSuccessMsg')
        );
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorker.updateErrorMsg'));
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      }
    );
  };
}

function deleteWorker(workshopWorkerId, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START});

    workshopWorkerService.deleteWorker(workshopWorkerId).then(
      response => {
        dispatch({type: workshopWorkerConstants.CLEAR_WORKER});
        dispatch({type: workshopWorkerConstants.REFRESH_WORKSHOP_WORKERS});
        dispatch(
          messageActions.successMessage('workshopWorker.deleteSuccessMsg')
        );
        onSuccess();
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorker.deleteErrorMsg'));
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      }
    );
  };
}

function createWorker(data, onSuccess = () => {}) {
  return dispatch => {
    dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START});

    workshopWorkerService.createWorker(data).then(
      response => {
        dispatch({type: workshopWorkerConstants.REFRESH_WORKSHOP_WORKERS});
        dispatch(
          messageActions.successMessage('workshopWorker.createSuccessMsg')
        );
        onSuccess();
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      },
      error => {
        dispatch(messageActions.errorMessage('workshopWorker.createErrorMsg'));
        dispatch({type: workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END});
      }
    );
  };
}
