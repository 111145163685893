/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Transition from 'react-transition-group/Transition';

const duration = 200;

export function FadeAndSlide({children, in: inProp}) {
  const defaultStyle = {
    transition: `${duration}ms ease-in`,
    transitionProperty: 'opacity, transform',
  };

  const transitionStyles = {
    entering: {opacity: 0, transform: 'translateY(-10%)'},
    entered: {opacity: 1},
    exiting: {opacity: 0, transform: 'translateY(-10%)'},
  };

  return (
    <Transition in={inProp} timeout={{enter: 0, exit: duration}}>
      {status => {
        if (status === 'exited') return null;
        const currentStyles = transitionStyles[status];
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        });
      }}
    </Transition>
  );
}
