/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Users as UsersView} from '../../admin/users/Users';

export const userTypes = {
  WEB: ['ROLE_USER'],
  MOBILE: ['ROLE_THIN_USER'],
};

export default class Users extends React.Component {
  render() {
    return <UsersView userTypes={userTypes} />;
  }
}
