/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Translate} from 'react-redux-i18n';
import {apiUrl} from '../../../environment';

export class AcceptStatute extends React.Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.state = {checked: false};
  }

  onClickHandler() {
    this.setState(prevState => {
      this.props.input.onChange(!prevState.checked);
      return {checked: !prevState.checked};
    });
  }

  renderLink() {
    const {statute} = this.props;
    const text = (
      <Translate
        className='w-100'
        value='valuationInquiry.acceptStatuteCheckbox'
      />
    );
    return (
      <div className='checkbox-label'>
        <span style={{color: '#CC0000'}}> * </span>
        {statute ? (
          <a href={apiUrl + '/workshop/statutes/' + statute.id + '/file'}>
            {text}
          </a>
        ) : (
          text
        )}
      </div>
    );
  }

  render() {
    return (
      <div className='terms-text accept-statute-content'>
        <input
          type='checkbox'
          onClick={this.onClickHandler}
          checked={this.props.input.value === true}
        />
        {this.renderLink()}
      </div>
    );
  }
}
