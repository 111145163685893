/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {workshopWorkerActions} from 'shared/actions/workshop-worker.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import WorkerCreateForm from 'shared/components/workers/worker-form/WorkerCreateForm';
import WorkerEditForm from 'shared/components/workers/worker-form/WorkerEditForm';
import WorkersTable from 'shared/components/workers/workers-table/WorkersTable';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {becameObsolete} from 'shared/utils/sync';

class Workers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFirstLoad: true,
    };

    this.load = this.load.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.showCreate = this.showCreate.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId, workshopWorkersSyncState} = this.props;
    const shouldLoad =
      (loggedWorkshopId && this.state.isFirstLoad) ||
      becameObsolete(
        prevProps.workshopWorkersSyncState,
        workshopWorkersSyncState
      );

    if (shouldLoad) {
      this.setState({isFirstLoad: false});
      this.load();
    }
  }

  load() {
    const {findAllWorkersByWorkshopId, loggedWorkshopId} = this.props;
    findAllWorkersByWorkshopId(loggedWorkshopId);
  }

  showDetails(item) {
    const {showUpdateDataSideBar, hideUpdateDataSideBar} = this.props;
    const details = (
      <WorkerEditForm
        workerId={item.data.id}
        handleCancel={hideUpdateDataSideBar}
        onDeleteSuccess={hideUpdateDataSideBar}
      />
    );
    showUpdateDataSideBar(details, I18n.t('worker.editFormTitle'));
  }

  showCreate() {
    const {showUpdateDataSideBar, hideUpdateDataSideBar, loggedWorkshopId} =
      this.props;
    const details = (
      <WorkerCreateForm
        handleCancel={hideUpdateDataSideBar}
        onCreateSuccess={hideUpdateDataSideBar}
        loggedWorkshopId={loggedWorkshopId}
      />
    );
    showUpdateDataSideBar(details, I18n.t('worker.createFormTitle'));
  }

  render() {
    const {isFetching, workshopWorkers} = this.props;

    return (
      <ContentWrapper title='sideBarNav.workers'>
        <Loadable active={isFetching} spinner>
          <WorkersTable
            workers={workshopWorkers}
            onRowClick={this.showDetails}
            onCreateClick={this.showCreate}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps({workshopWorker, workshop}) {
  const {isFetching, workshopWorkers, workshopWorkersSyncState} =
    workshopWorker;

  const {data} = workshop;
  const loggedWorkshopId = data ? data.workshopId : null;

  return {
    isFetching,
    workshopWorkers,
    workshopWorkersSyncState,
    loggedWorkshopId,
  };
}

const mapDispatchToProps = {
  findAllWorkersByWorkshopId: workshopWorkerActions.findAllWorkersByWorkshopId,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  hideUpdateDataSideBar: () => ({type: updateDataSideBarConstants.HIDE}),
};

const connectedWorkers = connect(mapStateToProps, mapDispatchToProps)(Workers);
export {connectedWorkers as Workers};
