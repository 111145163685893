/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {dateUtils} from 'shared/utils/date.utils';
import {sortNumbersAsc} from 'shared/utils/sort-utils/sort.utils';
import {getOrEmptyArray} from 'shared/utils/utils';
import {withWorkshopValuationsTableCore} from '../utils/ValuationsCoreHoc';
import {ValuationDetails} from '../valuation-details/ValuationDetails';

class PreparedValuations extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.showDetails = this.showDetails.bind(this);
    this.sortByValuationNumber = this.sortByValuationNumber.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchValuationsAction !== this.props.fetchValuationsAction)
      this.fetchData();

    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  fetchData() {
    this.props.fetchValuationsAction
      ? this.props.fetchValuationsAction()
      : this.props.findAllValuationsForWorkshop();
  }

  showDetails(event) {
    this.props.findValuationById(event.data.id);
    const details = (
      <ValuationDetails
        shouldShowWorkshopStatusAlert={this.props.shouldShowWorkshopStatusAlert}
        shouldShowWorkshopInfoSection={false}
        refreshParent={() => this.fetchData()}
      />
    );
    const title =
      I18n.t('valuationDetails.detailsHeader') +
      ' ' +
      event.data.valuationNumber.valuationNumberString;
    setTimeout(() => this.props.showUpdateDataSideBar(details, title), 10);
  }

  sortByValuationNumber(event) {
    return event.order === 1
      ? this.props.valuations
      : [...this.props.valuations].reverse();
  }

  render() {
    return (
      <ContentWrapper title='valuations.preparedValuationsBtn'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='col'>
            <DataTable
              ref={this.setDatatableRef}
              value={this.props.valuations}
              paginator={true}
              rows={this.props.rows}
              header={this.props.header}
              globalFilter={this.props.globalFilter}
              className='p-3'
              responsive={true}
              onRowClick={this.showDetails}
              emptyMessage={I18n.t('preparedValuations.emptyPlaceholder')}
              paginatorLeft={this.props.renderRowsDropdown()}>
              <Column
                field='valuationNumber.valuationNumberString'
                sortable='custom'
                sortFunction={this.sortByValuationNumber}
                header={I18n.t('preparedValuations.valuationNumberHeader')}
              />
              <Column
                field='userDetails'
                sortable={true}
                header={I18n.t('waitingValuations.userDetails')}
                body={this.props.renderUserDetailsCol}
              />
              <Column
                field='carData'
                header={I18n.t('preparedValuations.carData')}
                sortable={true}
              />
              <Column
                field='origin'
                header={I18n.t('preparedValuations.origin')}
                body={this.props.renderOriginCol}
                sortable={true}
              />
              <Column
                field='sendDateFormatted'
                header={I18n.t('preparedValuations.sendDate')}
                sortable={true}
              />
            </DataTable>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

PreparedValuations.propTypes = {
  shouldShowWorkshopStatusAlert: PropTypes.bool.isRequired,
  fetchValuationsAction: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, order} = state;
  const valuations = prepareValuationsToDisplay(order.valuations);
  return {i18n, valuations};
}

const prepareValuationsToDisplay = valuations => {
  return getOrEmptyArray(valuations)
    .sort((data1, data2) => sortAsc(data1, data2))
    .map(valuation => ({
      ...valuation,
      sendDateFormatted: dateUtils.formatDate(valuation.sendDate),
    }));
};

const sortAsc = (data1, data2) => {
  return (
    sortNumbersAsc(
      data1.valuationNumber.inquiryNumberDto.number,
      data2.valuationNumber.inquiryNumberDto.number
    ) ||
    sortNumbersAsc(
      data1.valuationNumber.number,
      data2.valuationNumber.number
    ) ||
    sortNumbersAsc(
      data1.valuationNumber.inquiryNumberDto.origin,
      data2.valuationNumber.inquiryNumberDto.origin
    )
  );
};

const mapDispatchToProps = {
  findAllValuationsForWorkshop: orderActions.findAllValuations,
  findValuationById: orderActions.findValuationById,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

//export for testing purpose
export const ConnectedPreparedValuationsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreparedValuations);

export default withWorkshopValuationsTableCore(
  ConnectedPreparedValuationsWithRedux
);
