/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {subscriptionConstants} from '../constants/subscription.constants';
import {SyncState} from '../utils/sync';

export function subscriptionReducer(state = {}, action) {
  switch (action.type) {
    case subscriptionConstants.SUBSCRIPTION_REQUEST_START:
      return {...state, isFetchingSubscription: true};

    case subscriptionConstants.SUBSCRIPTION_REQUEST_END:
      return {...state, isFetchingSubscription: false};

    case subscriptionConstants.LOAD_SUBSCRIPTION_END_DATE:
      return {
        ...state,
        subscriptionEndDate: action.payload.data,
        subscriptionSyncState: SyncState.UP_TO_DATE,
      };

    case subscriptionConstants.REFRESH_SUBSCRIPTION:
      return {...state, subscriptionSyncState: SyncState.OBSOLETE};

    case subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_START:
      return {...state, isFetchingSubscriptionPayment: true};

    case subscriptionConstants.SUBSCRIPTION_PAYMENT_REQUEST_END:
      return {...state, isFetchingSubscriptionPayment: false};

    case subscriptionConstants.LOAD_SUBSCRIPTION_PAYMENTS:
      return {
        ...state,
        subscriptionPayments: action.payload.data,
        subscriptionPaymentsSyncState: SyncState.UP_TO_DATE,
      };

    case subscriptionConstants.REFRESH_SUBSCRIPTION_PAYMENTS:
      return {...state, subscriptionPaymentsSyncState: SyncState.OBSOLETE};

    default:
      return state;
  }
}
