/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from 'react';
import styles from './BarcodesDetails.module.css';
import {Translate} from 'react-redux-i18n';
import {dateUtils} from 'shared/utils/date.utils';

const ACBarcodesDetails = props => {
  const {data} = props;

  return (
    <div className={styles.Container}>
      <>
        <p className={styles.Entry}>
          <Translate value={'barcodes.scanDate'} />:{' '}
          <span className={styles.EntryData}>
            {dateUtils.formatDate(data.scannedDate).split(' ')[0]}
          </span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'barcodes.createdDate'} />:{' '}
          <span className={styles.EntryData}>
            {dateUtils.formatDate(data.createdDate).split(' ')[0]}
          </span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'barcodes.barcode'} />:{' '}
          <span className={styles.EntryData}>{data.barcode}</span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'barcodes.barcodeType'} />:{' '}
          <span className={styles.EntryData}>{'AC'}</span>
        </p>
      </>
    </div>
  );
};

export default ACBarcodesDetails;
