/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import moment from 'moment';
import React from 'react';
import {connect} from 'react-redux';
import {loyaltyProgramActions} from 'shared/actions/loyalty-program.actions';
import {workshopActions} from 'shared/actions/workshop.actions';
import {loyaltyProgramConstants} from 'shared/constants/loyalty-program.constants';
import {serviceConstants} from 'shared/constants/service.constants';
import {workshopConstants} from 'shared/constants/workshop.constants';
import {Services} from '../../admin/services/Services';

export class WorkshopServices extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.clearSelectedServices = this.clearSelectedServices.bind(this);
    this.updateLoyaltyProgramPoints =
      this.updateLoyaltyProgramPoints.bind(this);
    this.fetchLoyaltyProgramPoints = this.fetchLoyaltyProgramPoints.bind(this);
    this.state = {isFirstFetch: true};
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentDidUpdate() {
    if (this.props.loggedWorkshopId && this.state.isFirstFetch) {
      this.setState({isFirstFetch: false});
      this.props.dispatch(
        workshopActions.getAllServicesForWorkshop(this.props.loggedWorkshopId)
      );
      this.fetchLoyaltyProgramPoints();
    }
  }

  componentWillUnmount() {
    this.props.dispatch({type: workshopConstants.CLEAR_SERVICES});
    this.props.dispatch({type: loyaltyProgramConstants.CLEAR_ALL});
  }

  fetchLoyaltyProgramPoints() {
    const {dispatch, loggedWorkshopId} = this.props;
    dispatch(
      loyaltyProgramActions.findLoyaltyProgramPointsByWorkshopId(
        loggedWorkshopId
      )
    );
  }

  handleSelect(event) {
    const {dispatch} = this.props;
    dispatch({
      type: event.checked
        ? workshopConstants.ADD_SERVICE
        : workshopConstants.DELETE_SERVICE,
      payload: event.value,
    });
  }

  handleSave() {
    const bodyData = {
      servicesToAdd: this.props.servicesToAdd,
      servicesToDelete: this.props.servicesToDelete,
    };
    this.props.dispatch(
      workshopActions.updateWorkshopServices(
        this.props.data.workshopId,
        bodyData,
        this.updateLoyaltyProgramPoints
      )
    );
  }

  updateLoyaltyProgramPoints() {
    const {dispatch, loggedWorkshopId, servicesLoyaltyProgramPoints} =
      this.props;
    dispatch(
      loyaltyProgramActions.updateLoyaltyProgramPoints(
        loggedWorkshopId,
        servicesLoyaltyProgramPoints,
        this.fetchLoyaltyProgramPoints
      )
    );
  }

  clearSelectedServices() {
    this.props.dispatch({
      type: serviceConstants.CLEAR_SELECTED_SERVICES,
      payload: this.props.services,
    });
    this.props.dispatch({type: workshopConstants.CLEAR_SERVICES});
    this.fetchLoyaltyProgramPoints();
  }

  render() {
    const {subscriptionEndDate} = this.props;
    const hasSubscription =
      subscriptionEndDate && moment(subscriptionEndDate).isAfter(moment());
    return (
      <Services
        isEditable={hasSubscription}
        handleSelect={this.handleSelect}
        handleSave={this.handleSave}
        clearSelectedServices={this.clearSelectedServices}
        editingRestricted={true}
        isServiceManager={false}
        privateServicesRestriction={true}
      />
    );
  }
}

function mapStateToProps(state) {
  const {i18n, workshop, loyaltyProgram, subscription} = state;
  const {data, servicesToAdd, servicesToDelete, services} = workshop;
  const {subscriptionEndDate} = subscription;
  const loggedWorkshopId = data ? data.workshopId : null;
  const {servicesLoyaltyProgramPoints} = loyaltyProgram;

  return {
    i18n,
    data,
    servicesToAdd,
    servicesToDelete,
    services,
    loggedWorkshopId,
    servicesLoyaltyProgramPoints,
    subscriptionEndDate,
  };
}

export default connect(mapStateToProps)(WorkshopServices);
