/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, reduxForm} from 'redux-form';
import CountryField from 'shared/components/form/country-field/CountryField';
import renderField from 'shared/components/form/text-field/RenderField';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {asyncValidate} from '../../../../sign-up/SignUpValidators';
import {validate} from './validators';

class CreateAdminForm extends React.Component {
  render() {
    const {handleSubmit, invalid} = this.props;

    return (
      <div className='add-admin-form px-sm-5 px-sm-0 pb-5'>
        <form onSubmit={handleSubmit}>
          <CountryField />
          <Field
            name='email'
            type='text'
            component={renderField}
            label={I18n.t('createAdminForm.emailLabel')}
            icon='fa fa-envelope'
          />
          <Field
            name='password'
            type='password'
            component={renderField}
            label={I18n.t('createAdminForm.passwordLabel')}
            icon='fa fa-unlock-alt'
          />
          <Field
            name='confirmPassword'
            type='password'
            component={renderField}
            label={I18n.t('createAdminForm.repeatPasswordLabel')}
            icon='fa fa-unlock-alt'
          />

          <div className='text-right mt-3'>
            <Button
              id='submit-add-admin-btn'
              type='submit'
              className='main-btn'
              disabled={invalid}>
              <Translate value={'createAdminForm.saveBtn'} />
            </Button>
            <Button
              id='cancel-user-edit-btn'
              className='main-btn'
              onClick={() =>
                this.props.dispatch({type: updateDataSideBarConstants.HIDE})
              }>
              <Translate value='createAdminForm.cancelBtn' />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

CreateAdminForm = reduxForm({
  form: 'createAdminForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
})(CreateAdminForm);

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

export default connect(mapStateToProps)(CreateAdminForm);
