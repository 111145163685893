/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {promotionActions} from 'shared/actions/promotion.actions';
import PromotionsViewer from 'shared/components/promotions/promotions-viewer/PromotionsViewer';
import {withRedirectForLoggedUser} from 'shared/hoc/redirect-logged-user/redirectLoggedUserHoc';
import {workshopService} from 'shared/services/workshop.service';
import {history} from 'shared/utils/history';
import {isEmptyArray} from 'shared/utils/utils';
import './valuation-inquiry.css';
import {ValuationInquiry} from './ValuationInquiry';

export class WorkshopValuationInquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      directWorkshop: null,
      promotionViewerOpen: false,
    };
  }

  componentDidMount() {
    const {findAllPromotionsByWorkshopId, ...props} = this.props;
    const workshopId = this.props.match.params.workshopId
      ? this.props.match.params.workshopId
      : null;
    workshopService
      .canGetWorkshopValuationInquiry(workshopId)
      .then(response => {
        if (response && response.data && response.data.result === true) {
          this.setState({directWorkshop: response.data.workshopInfo});
          findAllPromotionsByWorkshopId(workshopId, true);
        } else {
          return Promise.reject();
        }
      })
      .catch(() => {
        history.push('/');
      });
  }

  componentDidUpdate(prevProps) {
    const {promotions} = this.props;
    if (prevProps.promotions !== promotions && !isEmptyArray(promotions))
      this.setState({promotionViewerOpen: true});
  }

  render() {
    const {directWorkshop, promotionViewerOpen} = this.state;
    if (!directWorkshop) return null;

    return (
      <div className='root-panel py-5'>
        <PromotionsViewer
          isOpen={promotionViewerOpen}
          promotions={this.props.promotions}
          closeViewer={() => this.setState({promotionViewerOpen: false})}
        />
        <ValuationInquiry
          isValuationPublic={true}
          directWorkshop={directWorkshop}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {promotion} = state;
  const {promotions} = promotion;

  return {promotions};
}

const mapDispatchToProps = {
  findAllPromotionsByWorkshopId: promotionActions.findAllPromotionsByWorkshopId,
};

const ConnectedWorkshopValuationInquiry = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkshopValuationInquiry);

export default withRedirectForLoggedUser(
  ConnectedWorkshopValuationInquiry,
  '/valuations',
  true
);
