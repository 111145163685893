/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {orderRatingActions} from 'shared/actions/order-rating.actions';
import {RatingListModal} from 'shared/components/rating/rating-list-modal/RatingListModal';

class OrderCustomerRatingListModal extends React.Component {
  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
  }

  onOpen() {
    const {findAllOwnedForTargetCustomer, customerId} = this.props;

    if (findAllOwnedForTargetCustomer && customerId)
      findAllOwnedForTargetCustomer(customerId);
  }

  render() {
    const {orderRatings} = this.props;

    return (
      <RatingListModal
        titleLabelKey='orderRating.orderCustomerRatingListModalTitle'
        openBtnLabelKey='orderRating.orderCustomerRatingListModalOpenBtn'
        ratings={orderRatings}
        onOpen={this.onOpen}
      />
    );
  }
}

OrderCustomerRatingListModal.propType = {
  customerId: PropTypes.string.isRequired,
  findAllOwnedForTargetCustomer: PropTypes.func.isRequired,
};

function mapStateToProps({orderRating}) {
  const {orderRatings} = orderRating;

  return {orderRatings};
}

const mapDispatchToProps = {
  findAllOwnedForTargetCustomer:
    orderRatingActions.findAllOwnedForTargetCustomer,
};

const connectedOrderCustomerRatingListModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCustomerRatingListModal);
export {connectedOrderCustomerRatingListModal as OrderCustomerRatingListModal};
