/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import {
  validateCity,
  validateConfirmPassword,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
  validatePhoneNumber,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';
import {userService} from 'shared/services/user.service';
import ValidationMessageEnum from 'shared/utils/utils';

export const validate = values => {
  const errors = {};
  errors.firstName = validateFirstName(values.firstName);
  errors.lastName = validateLastName(values.lastName);
  errors.city = validateCity(values.city);
  errors.phoneNumber = validatePhoneNumber(values.phoneNumber, true);
  errors.email = validateEmail(values.email);
  errors.password = validatePassword(values.password);
  errors.confirmPassword = validateConfirmPassword({
    password: values.password,
    confirmPassword: values.confirmPassword,
  });
  errors.country = validateRequiredFields(values.country);
  errors.state = validateRequiredFieldsWithMaxLength(values.state, 255);
  if (!values.captchaToken) errors.captchaToken = 'error';

  if (!values.acceptTerms)
    errors.acceptTerms = {key: ValidationMessageEnum.REQUIRED, params: {}};

  return errors;
};

export const asyncValidate = values => userService.exist(values);
