/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Button, Collapse} from 'reactstrap';
import {Field, reduxForm} from 'redux-form';
import renderField from '../text-field/RenderField';
import {I18n, Translate} from 'react-redux-i18n';
import {validate} from './validators';

class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenCollapse: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({isOpenCollapse: !this.state.isOpenCollapse});
  }

  render() {
    const {handleSubmit, invalid} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className='text-sm-right text-center mt-3'>
          <Button
            id='user-pass-change-btn'
            onClick={this.toggle}
            className='main-btn'
            size='sm'>
            <Translate value={'changePassword.changePassword'} />
          </Button>
        </div>
        <Collapse isOpen={this.state.isOpenCollapse}>
          <Field
            name='currentPassword'
            type='password'
            component={renderField}
            label={I18n.t('changePassword.currentPasswordLabel')}
            icon='fa fa-unlock-alt'
          />
          <Field
            name='newPassword'
            type='password'
            component={renderField}
            label={I18n.t('changePassword.newPasswordLabel')}
            icon='fa fa-unlock-alt'
          />
          <Field
            name='confirmPassword'
            type='password'
            component={renderField}
            label={I18n.t('changePassword.repeatPasswordLabel')}
            icon='fa fa-unlock-alt'
          />
          <div className='row mt-3'>
            <div
              className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0'
              style={{whiteSpace: 'nowrap'}}
            />
            <div className='col-lg-9 col-12'>
              <div id='workshop-form-save-btn' className='text-right'>
                <Button
                  size='sm'
                  className='main-btn'
                  type='submit'
                  disabled={invalid}>
                  <Translate value='changePassword.saveBtn' />
                </Button>
              </div>
            </div>
          </div>
        </Collapse>
      </form>
    );
  }
}

export default reduxForm({
  form: 'changePasswordForm',
  validate,
  enableReinitialize: true,
})(ChangePasswordForm);
