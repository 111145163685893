import React, {useState} from 'react';
import styles from './BarcodesDetails.module.css';
import {Collapse} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import {dateUtils} from 'shared/utils/date.utils';

const BarcodesDetails = props => {
  const {data} = props;

  const [clientDataOpen, setClientDataOpen] = useState(true);
  const [carDataOpen, setCarDataOpen] = useState(true);
  const [workshopDataOpen, setWorkshopDataOpen] = useState(true);
  const [barCodeDetailsDataOpen, setBarCodeDetailsDataOpen] = useState(true);
  const role = data.creator.role;

  return (
    <div className={styles.Container}>
      {role === 'ROLE_USER' && (
        <>
          <div className={styles.ColapsableSection}>
            <button
              className={clientDataOpen ? styles.ToggleRotate : styles.Toggle}
              onClick={() => setClientDataOpen(!clientDataOpen)}>
              <i
                className='fa fa-angle-down'
                style={{fontSize: '30px', color: '#2c5c9a'}}
              />
            </button>
            <p className={styles.Title}>
              <Translate value={'barcodes.clientData'} />
            </p>
          </div>
          <Collapse isOpen={clientDataOpen} className={styles.ColapseBox}>
            <p className={styles.Entry}>
              <Translate value={'userProfileForm.firstNameLabel'} />:{' '}
              <span className={styles.EntryData}>{data.creator.firstName}</span>
            </p>
            <p className={styles.Entry}>
              <Translate value={'userProfileForm.lastNameLabel'} />:{' '}
              <span className={styles.EntryData}>{data.creator.lastName}</span>
            </p>
            <p className={styles.Entry}>
              <Translate value={'userProfileForm.emailLabel'} />{' '}
              <span className={styles.EntryData}>{data.creator.email}</span>
            </p>
          </Collapse>
        </>
      )}

      <div className={styles.ColapsableSection}>
        <button
          className={workshopDataOpen ? styles.ToggleRotate : styles.Toggle}
          onClick={() => setWorkshopDataOpen(!workshopDataOpen)}>
          <i
            className='fa fa-angle-down'
            style={{fontSize: '30px', color: '#2c5c9a'}}
          />
        </button>
        <p className={styles.Title}>
          <Translate value={'barcodes.workshopData'} />
        </p>
      </div>
      <Collapse isOpen={workshopDataOpen} className={styles.ColapseBox}>
        <p className={styles.Entry}>
          <Translate value={'workshopData.workshopName'} />:{' '}
          <span className={styles.EntryData}>{data.workshopName}</span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'workshopData.streetAddress'} />:{' '}
          <span className={styles.EntryData}>
            {`${data.workshopAddress} ${data.workshopZipcode} ${data.workshopCity}`}
          </span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'workshopData.voivodeship'} />:{' '}
          <span className={styles.EntryData}>{data.workshopState}</span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'workshopData.country'} />:{' '}
          <span className={styles.EntryData}>{data.workshopCountry}</span>
        </p>
        {data.workshopEmail && (
          <p className={styles.Entry}>
            <Translate value={'userProfileForm.emailLabel'} />{' '}
            <span className={styles.EntryData}>{data.workshopEmail}</span>
          </p>
        )}
        {data.workshopPhoneNumber && (
          <p className={styles.Entry}>
            <Translate value={'workshopProfile.mobilePhoneNumber'} />{' '}
            <span className={styles.EntryData}>{data.workshopPhoneNumber}</span>
          </p>
        )}
      </Collapse>

      {data.carBrand && (
        <>
          <div className={styles.ColapsableSection}>
            <button
              className={carDataOpen ? styles.ToggleRotate : styles.Toggle}
              onClick={() => setCarDataOpen(!carDataOpen)}>
              <i
                className='fa fa-angle-down'
                style={{fontSize: '30px', color: '#2c5c9a'}}
              />
            </button>
            <p className={styles.Title}>
              <Translate value={'barcodes.carData'} />
            </p>
          </div>
          <Collapse isOpen={carDataOpen} className={styles.ColapseBox}>
            {(role === 'ROLE_USER' || role === 'ROLE_WORKSHOP') && (
              <>
                <p className={styles.Entry}>
                  <Translate value={'car.brandLabel'} />:{' '}
                  <span className={styles.EntryData}>{data.carBrand}</span>
                </p>
                <p className={styles.Entry}>
                  <Translate value={'car.modelLabel'} />:{' '}
                  <span className={styles.EntryData}>{data.carModel}</span>
                </p>
                <p className={styles.Entry}>
                  <Translate value={'car.productionYearLabel'} />:{' '}
                  <span className={styles.EntryData}>
                    {data.carProductionYear}
                  </span>
                </p>
                <p className={styles.Entry}>
                  <Translate value={'car.engineCapacityCm3Label'} />:{' '}
                  <span className={styles.EntryData}>
                    {data.carEngineCapacityCm3}
                  </span>
                </p>
              </>
            )}
          </Collapse>
        </>
      )}

      <div className={styles.ColapsableSection}>
        <button
          className={
            barCodeDetailsDataOpen ? styles.ToggleRotate : styles.Toggle
          }
          onClick={() => setBarCodeDetailsDataOpen(!barCodeDetailsDataOpen)}>
          <i
            className='fa fa-angle-down'
            style={{fontSize: '30px', color: '#2c5c9a'}}
          />
        </button>
        <p className={styles.Title}>
          <Translate value={'barcodes.detailsSecondary'} />
        </p>
      </div>
      <Collapse isOpen={barCodeDetailsDataOpen} className={styles.ColapseBox}>
        <p className={styles.Entry}>
          <Translate value={'barcodes.barcode'} />:{' '}
          <span className={styles.EntryData}>{data.barcode}</span>
        </p>
        <p className={styles.Entry}>
          <Translate value={'barcodes.scanDate'} />:{' '}
          <span className={styles.EntryData}>
            {dateUtils.formatDate(data.createdDate).split(' ')[0]}
          </span>
        </p>
        {data.occurrenceCount && (
          <p className={styles.Entry}>
            <Translate value={'barcodes.scansQuantity'} />:{' '}
            <span className={styles.EntryData}>{data.occurrenceCount}</span>
          </p>
        )}
        {data.barcodeType && (
          <p className={styles.Entry}>
            <Translate value={'barcodes.codeType'} />:{' '}
            <span className={styles.EntryData}>{data.barcodeType}</span>
          </p>
        )}
        <p className={styles.EntryCenter}>
          <Translate value={'barcodes.status'} />:{' '}
          <span
            className={data.isSuspicious ? styles.RedDot : styles.GreenDot}
          />
        </p>
      </Collapse>
    </div>
  );
};

export default BarcodesDetails;
