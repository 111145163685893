/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useState} from 'react';
import BarcodesTable from 'shared/components/barcodes/BarcodesTable';
import ACBarcodesTable from 'shared/components/barcodes/ACBarcodesTable';
import Loadable from 'react-loading-overlay';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import {useSelector} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import styles from './BarcodesContainer.module.css';

const BarcodesContainer = () => {
  const isDataFetching = useSelector(state => state.workshop.isFetching);
  const [acCodesEnable, setAcCodesEnable] = useState(false);

  return (
    <>
      <div className={styles.Container}>
        <div
          className={`subsection-tile shadow col-sm col-12 mr-sm-3 mb-sm-0 mb-3 ${
            !acCodesEnable && 'subsection-tile-active'
          }`}
          onClick={() => setAcCodesEnable(!acCodesEnable)}>
          <i className={'fa fa-barcode fa-3x'} />
          <Translate value={'barcodes.barcodes'} />
        </div>

        <div
          className={`subsection-tile shadow col-sm col-12 ${
            acCodesEnable && 'subsection-tile-active'
          }`}
          onClick={() => setAcCodesEnable(!acCodesEnable)}>
          <i className={'fa fa-barcode fa-3x'} />
          <Translate value={'barcodes.acCodes'} />
        </div>
      </div>

      {!acCodesEnable && (
        <ContentWrapper title='sideBarNav.barcodes'>
          <Loadable active={isDataFetching} spinner>
            <BarcodesTable />
          </Loadable>
        </ContentWrapper>
      )}
      {acCodesEnable && (
        <ContentWrapper title='barcodes.acCodes'>
          <Loadable active={isDataFetching} spinner>
            <ACBarcodesTable />
          </Loadable>
        </ContentWrapper>
      )}
    </>
  );
};

export default BarcodesContainer;
