/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {LoyaltyProgramForm} from './LoyaltyProgramForm';
import {loyaltyProgramActions} from '../../../actions/loyalty-program.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {validate} from './loyalty-program-form-validators';

export const LoyaltyProgramFormReduxFormWrapper = reduxForm({
  form: 'loyaltyProgramCreateForm',
  validate,
  enableReinitialize: true,
})(LoyaltyProgramForm);

class LoyaltyProgramCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createLoyaltyProgram, onCreateSuccess, workshopId} = this.props;
    createLoyaltyProgram({...data, workshopId}, onCreateSuccess);
  }

  render() {
    const {isFetchingLoyaltyProgram} = this.props;

    return (
      <Loadable active={isFetchingLoyaltyProgram} spinner>
        <LoyaltyProgramFormReduxFormWrapper
          {...this.props}
          onSubmit={this.create}
        />
      </Loadable>
    );
  }
}

LoyaltyProgramCreateForm.propTypes = {
  workshopId: PropTypes.string.isRequired,
  createLoyaltyProgram: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, loyaltyProgram} = state;
  const {isFetchingLoyaltyProgram} = loyaltyProgram;
  const titleKey = 'loyaltyProgram.createFormTitle';

  return {i18n, isFetchingLoyaltyProgram, titleKey};
}

const mapDispatchToProps = {
  createLoyaltyProgram: loyaltyProgramActions.createLoyaltyProgram,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltyProgramCreateForm);
