/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredFields,
  validateRequiredFieldsWithMaxLength,
} from 'shared/components/form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.partCategory = validateRequiredFields(values.partCategory);
  errors.partName = validateRequiredFieldsWithMaxLength(values.partName, 255);
  errors.partDescription = validateByLengthOnly(values.partDescription, 255);

  return errors;
};
