/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button, Collapse} from 'reactstrap';
import {serviceStatus} from 'shared/constants/service.constants';
import ServiceCategoryEditForm from '../service-category-form/ServiceCategoryEditForm';
import ServiceCreateForm from '../service-list/service-form/ServiceCreateForm';
import './service-details-category.css';

class ServiceCategoryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {createServiceOpen: false};
    this.toggleCreateService = this.toggleCreateService.bind(this);
  }

  toggleCreateService() {
    this.setState({createServiceOpen: !this.state.createServiceOpen});
  }

  renderToggleCreateServiceBtn() {
    return (
      <div className='service-edit-form px-sm-5 px-sm-0 pb-5'>
        <div className='text-sm-right text-center'>
          <Button
            id='toggle-create-service-btn'
            className='main-btn'
            onClick={this.toggleCreateService}>
            <Translate value={'services.createServiceFormTitle'} />
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {
      onCategoryEdit,
      onServiceCreate,
      editingRestricted,
      categoryStatus,
      privateServicesRestriction,
    } = this.props;
    const editable =
      !editingRestricted || categoryStatus === serviceStatus.PENDING;

    return (
      <div>
        <ServiceCategoryEditForm
          onSubmit={onCategoryEdit}
          editable={editable}
          privateServicesRestriction={privateServicesRestriction}
        />

        {!this.state.createServiceOpen && this.renderToggleCreateServiceBtn()}

        <Collapse isOpen={this.state.createServiceOpen}>
          <ServiceCreateForm
            titleKey='services.createServiceFormTitle'
            onSubmit={onServiceCreate}
            handleCancel={this.toggleCreateService}
            privateServicesRestriction={privateServicesRestriction}
          />
        </Collapse>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {service} = state;
  const categoryStatus = service.category ? service.category.status : null;

  return {categoryStatus};
}

export default connect(mapStateToProps)(ServiceCategoryDetails);
