/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const subscriptionPlanConstants = {
  SUBSCRIPTION_PLAN_REQUEST_START: 'SUBSCRIPTION_PLAN_REQUEST_START',
  SUBSCRIPTION_PLAN_REQUEST_END: 'SUBSCRIPTION_PLAN_REQUEST_END',

  LOAD_SUBSCRIPTION_PLANS: 'LOAD_SUBSCRIPTION_PLANS',
  REFRESH_SUBSCRIPTION_PLANS: 'REFRESH_SUBSCRIPTION_PLANS',

  LOAD_SUBSCRIPTION_PLAN: 'LOAD_SUBSCRIPTION_PLAN',
  CLEAR_SUBSCRIPTION_PLAN: 'CLEAR_SUBSCRIPTION_PLAN',
};
