/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {GenericModal} from '../../modal/GenericModal';
import {Fragment} from 'react';
import {Button} from 'reactstrap';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import RatingCreateForm from '../rating-form/RatingCreateForm';

export class RatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isOpen: false};

    this.performAction = this.performAction.bind(this);
  }

  performAction(values) {
    const {action} = this.props;
    action(values).then(() => this.setState({isOpen: false}));
  }

  render() {
    const {titleLabelKey, openBtnLabelKey} = this.props;
    const content = (
      <RatingCreateForm
        onSubmit={this.performAction}
        handleCancel={() => this.setState({isOpen: false})}
      />
    );

    return (
      <Fragment>
        <GenericModal
          content={content}
          title={titleLabelKey}
          isOpen={this.state.isOpen}
        />

        <Button
          id='open-rate-modal'
          className='main-btn'
          onClick={() => this.setState({isOpen: true})}>
          <Translate value={openBtnLabelKey} />
        </Button>
      </Fragment>
    );
  }
}

RatingModal.propType = {
  titleLabelKey: PropTypes.string,
  openBtnLabelKey: PropTypes.string,
  action: PropTypes.func.isRequired,
};
