/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {getOrEmptyObj, isEmptyArray} from 'shared/utils/utils';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {CarForm} from './CarForm';
import {validate} from './car-form-validators';
import {userService} from 'shared/services/user.service';
import {carService} from 'shared/services/car.service';
import {carActions} from 'shared/actions/car.actions';

export const asyncValidate = values =>
  carService
    .carExistsForUser(values.name, userService.getUserId(), values.carId)
    .then(response => {
      if (response.data)
        return {name: {key: 'car.nameExistsForUserError', params: {}}};
    });

const formName = 'carEditForm';

const CarEditForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
  asyncValidate,
  asyncBlurFields: ['name'],
})(CarForm);

const selector = formValueSelector(formName);

function getInitialValues(userCar) {
  const initialValues = Object.assign({}, getOrEmptyObj(userCar));
  if (initialValues.usingModelOther) {
    initialValues.modelOther = initialValues.model;
    initialValues.model = 'other';
  }
  return initialValues;
}

function mapStateToProps(state) {
  const {i18n, car} = state;
  const initialValues = getInitialValues(car.userCar);
  const selectedCarId = getOrEmptyObj(car.userCar).carId;
  const brandFieldValue = selector(state, 'brand');
  const modelFieldValue = selector(state, 'model');
  const fuelTypeFieldValue = selector(state, 'fuelType');
  const {carBrands, fuelTypes} = car;

  return {
    i18n,
    formName,
    initialValues,
    selectedCarId,
    brandFieldValue,
    modelFieldValue,
    carBrands,
    fuelTypes,
    fuelTypeFieldValue,
  };
}

const mapDispatchToProps = {
  handleCancel: () => ({type: updateDataSideBarConstants.HIDE}),
  handleDelete: carActions.deleteCar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarEditForm);
