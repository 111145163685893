/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Card, CardBody} from 'reactstrap';
import {distributorActions} from 'shared/actions/distributor.actions';
import {userActions} from 'shared/actions/user.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import ChangePasswordForm from 'shared/components/form/change-password-form/ChangePasswordForm';
import {distributorConstants} from 'shared/constants/distributor.constants';
import {userService} from 'shared/services/user.service';
import UserAvatar from '../../user/user-profile/avatar/UserAvatar';
import './distributor-profile.css';
import DistributorAddressForm from './forms/DistributorAddressForm';
import DistributorDetailsForm from './forms/DistributorDetailsForm';
import DistributorOtherSectionForm from './forms/DistributorOtherSectionForm';

class DistributorProfile extends React.Component {
  constructor(props) {
    super(props);
    this.submitChangePasswordForm = this.submitChangePasswordForm.bind(this);
    this.submitChangeAddressForm = this.submitChangeAddressForm.bind(this);
    this.submitChangeDetailsForm = this.submitChangeDetailsForm.bind(this);
    this.submitChangeOtherSectionForm =
      this.submitChangeOtherSectionForm.bind(this);
  }

  componentDidMount() {
    this.props.loadDistributorData(userService.getUserId());
    this.props.getUserDataById(userService.getUserId());
  }

  componentWillUnmount() {
    this.props.clearSelectedDistributor();
  }

  submitChangePasswordForm(data) {
    this.props.changePassword(userService.getUserId(), data);
  }

  submitChangeAddressForm(data) {
    this.props.updateAddress(data, userService.getUserId());
  }

  submitChangeDetailsForm(data) {
    this.props.updateDetails(data, userService.getUserId());
  }

  submitChangeOtherSectionForm(data) {
    this.props.updateOtherSection(data, userService.getUserId());
  }

  render() {
    return (
      <ContentWrapper
        title='userProfile.profile'
        containerClass='user-profile-container'>
        <Loadable active={this.props.isFetching} spinner>
          <div className='distributor-profile'>
            <div>
              <Card className='root-panel'>
                <CardBody>
                  <UserAvatar />
                  <ChangePasswordForm
                    onSubmit={this.submitChangePasswordForm}
                  />
                </CardBody>
              </Card>
            </div>
            <div className='distributor-profile-card'>
              <Card className='root-panel'>
                <CardBody>
                  <DistributorDetailsForm
                    onSubmit={this.submitChangeDetailsForm}
                  />
                </CardBody>
              </Card>
            </div>
            <div className='distributor-profile-card'>
              <Card className='root-panel'>
                <CardBody>
                  <DistributorAddressForm
                    onSubmit={this.submitChangeAddressForm}
                  />
                </CardBody>
              </Card>
            </div>
            <div className='distributor-profile-card'>
              <Card className='root-panel'>
                <CardBody>
                  <DistributorOtherSectionForm
                    onSubmit={this.submitChangeOtherSectionForm}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = ({distributor}) => {
  const {isFetching, data} = distributor;
  return {isFetching, data};
};

const mapDispatchToProps = {
  loadDistributorData: distributorActions.loadDistributorData,
  updateAddress: distributorActions.updateAddress,
  updateDetails: distributorActions.updateDetails,
  updateOtherSection: distributorActions.updateOtherSection,
  changePassword: userActions.changePassword,
  getUserDataById: userActions.getUserDataById,
  clearSelectedDistributor: () => ({
    type: distributorConstants.CLEAR_SELECTED_DISTRIBUTOR_DATA,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorProfile);
