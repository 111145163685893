/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {indexArray} from '../../../utils/utils';

class WorkersTable extends React.Component {
  constructor(props) {
    super(props);
    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  renderCreateBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={() => this.props.onCreateClick()}>
        <Translate value='worker.createBtn' />
      </Button>
    );
  }

  render() {
    const {workers, headerBuilder, onRowClick, renderRowsDropdown} = this.props;

    return (
      <div className='p-3'>
        <DataTable
          value={indexArray(workers)}
          header={headerBuilder()}
          rows={this.props.rows}
          globalFilter={this.props.globalFilter}
          paginator={true}
          responsive={true}
          className='data-table-padding-class'
          emptyMessage={I18n.t('worker.emptyPlaceholder')}
          onRowClick={onRowClick}
          paginatorLeft={renderRowsDropdown()}
          paginatorRight={this.renderCreateBtn()}
          ref={this.setDatatableRef}>
          <Column
            field='index'
            className='lp-col'
            header={I18n.t('worker.lp')}
            sortable={true}
          />

          <Column
            field='firstName'
            header={I18n.t('worker.firstName')}
            sortable={true}
          />

          <Column
            field='lastName'
            header={I18n.t('worker.lastName')}
            sortable={true}
          />
        </DataTable>
      </div>
    );
  }
}

WorkersTable.propTypes = {
  workers: PropTypes.array,
  onRowClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

WorkersTable.defaultProps = {
  workers: [],
  onRowClick: () => {},
  onCreateClick: () => {},
};

export {WorkersTable as WorkersTableCore};

export default withDataTableCore(WorkersTable);
