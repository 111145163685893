/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {isEmptyArray} from '../../../utils/utils';
import {Alert} from 'reactstrap';
import {LoyaltyProgramInfo} from '../loyalty-program-info/LoyaltyProgramInfo';

export class LoyaltyProgramList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeLoyaltyProgramId: null,
    };

    this.onClickLoyaltyProgram = this.onClickLoyaltyProgram.bind(this);
  }

  renderEmptyMsg() {
    return (
      <Alert className='text-center' color='info'>
        <Translate value={this.props.emptyMessageKey} />
      </Alert>
    );
  }

  onClickLoyaltyProgram(loyaltyProgramId) {
    const newActiveLoyaltyProgramId =
      this.state.activeLoyaltyProgramId === loyaltyProgramId
        ? null
        : loyaltyProgramId;
    this.setState({activeLoyaltyProgramId: newActiveLoyaltyProgramId});
    this.props.onClickLoyaltyProgram(newActiveLoyaltyProgramId);
  }

  render() {
    const {loyaltyPrograms} = this.props;
    return (
      <div style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}>
        {isEmptyArray(loyaltyPrograms)
          ? this.renderEmptyMsg()
          : loyaltyPrograms.map((loyaltyProgram, index) => (
              <LoyaltyProgramInfo
                loyaltyProgram={loyaltyProgram}
                key={index}
                onClick={this.onClickLoyaltyProgram}
                active={this.state.activeLoyaltyProgramId === loyaltyProgram.id}
              />
            ))}
      </div>
    );
  }
}

LoyaltyProgramList.propTypes = {
  loyaltyPrograms: PropTypes.array,
  emptyMessageKey: PropTypes.string,
  onClickLoyaltyProgram: PropTypes.func,
};

LoyaltyProgramList.defaultProps = {
  loyaltyPrograms: [],
  onClickLoyaltyProgram: () => {},
};
