/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import React from "react";
import { Translate } from "react-redux-i18n";
import styles from "../WarrantyInstalationData.module.css";
import EmptyFieldPlaceholder from "../../empty-field-placeholder/EmptyFieldPlaceholder";
import { useSelector } from "react-redux";
import {dateUtils} from 'shared/utils/date.utils';

const TechnicalDataGasInstallation = () => {
  const technicalDetails = useSelector(state => state.warrantyBook.initializedWarranty.technicalDetails)

  const renderRow = (labels) => {
    return (
      technicalDetails && (
        <div className={styles.Row}>
          {labels.map((label) => (
            <div className={styles.LabelContainer} key={label.label}>
              <Translate value={label.label} className={styles.LabelName} />
              <span className={styles.LabelValue}>{label.value}</span>
            </div>
          ))}
        </div>
      )
    );
  };

  return (
    <div className={styles.FirstSegmentCollapsedContainer}>
      {technicalDetails && Object.values(technicalDetails).every((x) => !x) ? (
        <EmptyFieldPlaceholder content={"warranty.noData"} />
      ) : (
        <>
          <div className={styles.Column} style={{ width: "100%" }}>
            {renderRow([
              {
                label: "warranty.sectionII.lpgReducerLabel",
                value: technicalDetails.reducerName,
              },
              {
                label: "warranty.sectionII.serialNumbers1",
                value: technicalDetails.reducerSerialNumbers && technicalDetails.reducerSerialNumbers.join(', '),
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.lpgTankLabel",
                value: technicalDetails.tankName,
              },
              {
                label: "warranty.sectionII.serialNumber2",
                value: technicalDetails.tankSerialNumber,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.lpgDriverLabel",
                value: technicalDetails.stagControlSystemName,
              },
              {
                label: "warranty.sectionII.serialNumber3",
                value: technicalDetails.stagControlSystemSerialNumber,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.lpgInjectorsLabel",
                value: technicalDetails.injectionRailName,
              },
              {
                label: "warranty.sectionII.serialNumbers4",
                value: technicalDetails.injectionRailSerialNumbers && technicalDetails.injectionRailSerialNumbers.join(', '),
              },
            ])}
            <div className={styles.Row}>
              <div className={styles.LabelContainer}>
                <Translate
                  value={"warranty.sectionII.lpgTemperatureAndPressureSensor"}
                  className={styles.LabelName}
                />
                <span className={styles.LabelValue}>
                  {technicalDetails.pressureTemperatureSensorName}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "45%",
                }}
              >
                <div className={styles.LabelContainer}>
                  <Translate
                    value={"warranty.sectionII.serialNumber5"}
                    className={styles.LabelName}
                  />
                  <span className={styles.LabelValue}>
                    {technicalDetails.pressureTemperatureSensorBarcode}
                  </span>
                </div>
                <div className={styles.LabelContainer}>
                  <Translate
                    value={"warranty.sectionII.pressureTemperatureSensorDateField"}
                    className={styles.LabelName}
                  />
                  <span className={styles.LabelValue}>
                    {technicalDetails.pressureTemperatureSensorDate?
                      dateUtils.formatDate(technicalDetails.pressureTemperatureSensorDate, 'YYYY-MM-DD'): ''}
                  </span>
                </div>
              </div>
            </div>
            {renderRow([
              {
                label: "warranty.sectionII.lpgBgSwitchLabel",
                value: technicalDetails.bgSwitch,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.lpgGazLevelType",
                value: technicalDetails.gasLevelIndicatorType,
              },
              {
                label: "warranty.sectionII.gasLevelIndicatorDateField",
                value: technicalDetails.gasLevelIndicatorDate?
                  dateUtils.formatDate(technicalDetails.gasLevelIndicatorDate, 'YYYY-MM-DD'): ''
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.workshopWorker",
                value: technicalDetails.workshopWorker,
              },
              {
                label: "warranty.sectionII.firmName",
                value: technicalDetails.workshopName,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.address",
                value: technicalDetails.address,
              },
              {
                label: "warranty.sectionII.phone",
                value: technicalDetails.phoneNumber,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.nipLabel",
                value: technicalDetails.nip,
              },
              {
                label: "warranty.sectionII.regonLabel",
                value: technicalDetails.regon,
              },
            ])}
            {renderRow([
              {
                label: "warranty.sectionII.email",
                value: technicalDetails.workshopWorkerEmail,
              },
              {
                label: "warranty.sectionII.date",
                value: technicalDetails.date?
                  dateUtils.formatDate(technicalDetails.date, 'YYYY-MM-DD'): ''
              },
            ])}
          </div>
        </>
      )}
    </div>
  );
};

export default TechnicalDataGasInstallation;
