/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {
  validateByLengthOnly,
  validateRequiredNumberWithRange,
} from '../../form/common-validators/common-validators';

export const validate = values => {
  let errors = {};
  errors.value = validateRequiredNumberWithRange(values.value, 1, 5);
  errors.comment = validateByLengthOnly(values.comment, 255);
  return errors;
};
