/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {I18n} from 'react-redux-i18n';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {getOrEmptyArray, indexArray} from '../../../utils/utils';
import {LoyaltyProgramPresentationTable} from '../loyalty-program-table/LoyaltyProgramPresentationTable';

class LoyaltyProgramAccountTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedAccounts: [],
    };

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };

    this.expandAccountDetails = this.expandAccountDetails.bind(this);
    this.accountDetailsTemplate = this.accountDetailsTemplate.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  expandAccountDetails(e) {
    let recentExpandedIndex = e.data.length - 1;
    this.setState({expandedAccounts: [e.data[recentExpandedIndex]]});
  }

  accountDetailsTemplate(data) {
    const achievedLoyaltyProgramsIds = getOrEmptyArray(
      data.achievedLoyaltyPrograms
    ).map(lp => lp.id);
    const loyaltyPrograms = getOrEmptyArray(data.allLoyaltyPrograms)
      .map(lp => ({
        isAchieved: achievedLoyaltyProgramsIds.indexOf(lp.id) >= 0,
        ...lp,
      }))
      .sort((lp1, lp2) => lp1.points > lp2.points);

    return (
      <LoyaltyProgramPresentationTable
        title={this.props.innerLoyaltyProgramTableTitle}
        loyaltyPrograms={loyaltyPrograms}
      />
    );
  }

  render() {
    const {
      loyaltyProgramAccounts,
      globalFilter,
      renderRowsDropdown,
      header,
      rows,
    } = this.props;
    return (
      <DataTable
        value={indexArray(loyaltyProgramAccounts)}
        header={header}
        rows={rows}
        globalFilter={globalFilter}
        paginator={true}
        responsive={true}
        className='expandable-datatable p-3'
        emptyMessage={I18n.t('loyaltyProgramAccount.emptyPlaceholder')}
        expandedRows={this.state.expandedAccounts}
        onRowToggle={this.expandAccountDetails}
        rowExpansionTemplate={this.accountDetailsTemplate}
        paginatorLeft={renderRowsDropdown()}
        ref={this.setDatatableRef}>
        <Column expander={true} className='expander-col' />

        <Column
          field='index'
          className='lp-col'
          header={I18n.t('loyaltyProgramAccount.lp')}
          sortable={true}
        />

        <Column
          field='workshopName'
          header={I18n.t('loyaltyProgramAccount.workshopName')}
          sortable={true}
        />

        <Column
          field='collectedPoints'
          header={I18n.t('loyaltyProgramAccount.points')}
          sortable={true}
        />
      </DataTable>
    );
  }
}

LoyaltyProgramAccountTable.propTypes = {
  loyaltyProgramAccounts: PropTypes.array,
  innerLoyaltyProgramTableTitle: PropTypes.string,
};

LoyaltyProgramAccountTable.defaultProps = {
  loyaltyProgramAccounts: [],
};

export {LoyaltyProgramAccountTable as LoyaltyProgramAccountTableCore};

export default withDataTableCore(LoyaltyProgramAccountTable);
