/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import {isEmptyArray} from '../../../utils/utils';
import {RatingInfo} from '../rating-info/RatingInfo';
import {Translate} from 'react-redux-i18n';

export class RatingList extends React.Component {
  constructor(props) {
    super(props);
  }

  renderEmptyMsg() {
    return <Translate value='rating.noRatingsMsg' />;
  }

  render() {
    const {ratings} = this.props;
    return (
      <div style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}>
        {isEmptyArray(ratings)
          ? this.renderEmptyMsg()
          : ratings.map((rating, index) => (
              <RatingInfo rating={rating} key={index} />
            ))}
      </div>
    );
  }
}

RatingList.propType = {
  ratings: PropTypes.array,
};

RatingList.defaultProps = {
  ratings: [],
};
