/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {loyaltyProgramActions} from 'shared/actions/loyalty-program.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import LoyaltyProgramAccountTable from 'shared/components/loyalty-program/loyalty-program-account-table/LoyaltyProgramAccountTable';
import {userService} from 'shared/services/user.service';

class UserLoyaltyPrograms extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.findLoyaltyProgramAccountsByParams(userService.getUserId());
  }

  render() {
    return (
      <ContentWrapper title='sideBarNav.loyaltyPrograms'>
        <Loadable active={this.props.isFetchingLoyaltyProgramAccounts} spinner>
          <LoyaltyProgramAccountTable
            loyaltyProgramAccounts={this.props.loyaltyProgramAccounts}
          />
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  const {loyaltyProgram} = state;

  const {isFetchingLoyaltyProgramAccounts, loyaltyProgramAccounts} =
    loyaltyProgram;

  return {
    isFetchingLoyaltyProgramAccounts,
    loyaltyProgramAccounts,
  };
}

const mapDispatchToProps = {
  findLoyaltyProgramAccountsByParams:
    loyaltyProgramActions.findLoyaltyProgramAccountsByParams,
};

const connectedUserLoyaltyPrograms = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLoyaltyPrograms);
export {connectedUserLoyaltyPrograms as UserLoyaltyPrograms};
