/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {CalendarEventForm} from './CalendarEventForm';
import React from 'react';
import PropTypes from 'prop-types';
import {validate} from './calendar-event-form-form-validators';
import Loadable from 'react-loading-overlay';
import {getOrEmptyArray} from '../../../utils/utils';
import moment from 'moment';
import {workshopActions} from '../../../actions/workshop.actions';

export const CalendarEventFormReduxFormWrapper = reduxForm({
  form: 'calendarEventCreateForm',
  validate,
  enableReinitialize: true,
})(CalendarEventForm);

class CalendarEventCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createCalendarEvent, workshopId} = this.props;
    createCalendarEvent({workshopId, ...data}, this.props.onCreateSuccess);
  }

  render() {
    const initialValues = {
      start: moment().startOf('hour').second(0).millisecond(0).format(),
      end: moment()
        .add(1, 'hour')
        .startOf('hour')
        .second(0)
        .millisecond(0)
        .format(),
    };

    return (
      <Loadable active={this.props.isFetching} spinner>
        <CalendarEventFormReduxFormWrapper
          {...this.props}
          initialValues={initialValues}
          onSubmit={this.create}
          minDate={null}
          maxDate={null}
        />
      </Loadable>
    );
  }
}

CalendarEventCreateForm.propType = {
  onCreateSuccess: PropTypes.func,
};

CalendarEventCreateForm.defaultProps = {
  onCreateSuccess: () => {},
};

function mapStateToProps(state) {
  const {i18n, workshop, workshopWorker} = state;
  const {isFetching, data} = workshop;
  const workshopWorkers = getOrEmptyArray(workshopWorker.workshopWorkers);
  const {workshopId} = data;

  return {i18n, isFetching, workshopWorkers, workshopId};
}

const mapDispatchToProps = {
  createCalendarEvent: workshopActions.createCalendarEvent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarEventCreateForm);
