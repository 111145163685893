/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, useState } from 'react';
import Loadable from 'react-loading-overlay';
import ContentWrapper from "../../../../shared/components/content-wrapper/ContentWrapper";
import { DataTable } from "primereact/datatable";
import { Translate, I18n } from "react-redux-i18n";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from 'primereact/components/paginator/Paginator';
import { Input } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import { rowsQuantityToDisplay } from 'shared/utils/utils';
import { dateUtils, DATE_SHORT_FORMAT } from 'shared/utils/date.utils';
import { statuteActions } from 'shared/actions/statute.actions';
import WorkshopLoyaltyProgramModal from '../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal';
import { updateDataSideBarConstants } from 'shared/constants/update-data-side-bar.constants';
import { Button } from "reactstrap";
import styles from './WorkshopList.module.css';
import { WorkshopParticipationBlockHistory } from './WorkshopParticipationBlockHistory';
import WorkshopListSelectedWorkshop from './workshop-list-selected-workshop/WorkshopListSelectedWorkshop';

export const WorkshopList = () => {
    const dispatch = useDispatch();

    const workshops = useSelector(state => state.workshopLoyaltyProgram.workshopList);
    const workshopListFetching = useSelector(state => state.workshopLoyaltyProgram.workshopListFetching);
    const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
    const statuteFetching = useSelector(state => state.statute.isFetching);

    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [sortField, setSortField] = useState();
    const [sortDirection, setSortDirection] = useState();
    const [first, setFirst] = useState(0)
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
		dispatch(workshopLoyaltyProgramActions.getWorkshopList({
            page: page - 1,
            size: pageLimit,
            sort: (sortField && sortDirection) && `${sortField},${sortDirection}`
        }, globalFilter));
    }, [page, pageLimit, sortField, sortDirection, globalFilter]);

    useEffect(() => {
        dispatch(statuteActions.findLoyaltyProgramStatutes());
    }, []);

    const onRowClick = (event) => {
        dispatch(workshopLoyaltyProgramActions.getWorkshopLoyaltyProgramRegistrationData(event.data.workshopId));
        dispatch({
          type: updateDataSideBarConstants.SHOW,
          variableComponent: <WorkshopListSelectedWorkshop/>,
          title: event.data.email,
        });
    }

    return (
        <>
        <ContentWrapper title='workshopLoyaltyProgram.workshops.header' scrollY>
            <Loadable active={workshopListFetching || statuteFetching} spinner>
                <div className='col' style={{minWidth: 976}}>
                    <DataTable
                        value={workshops.content}
                        paginator={false}
                        rows={pageLimit}
                        className='data-table-padding-class'
                        responsive={true}
                        emptyMessage={I18n.t('workshopLoyaltyProgram.workshops.noWorkshops')}
                        onSort={e => {
                            setPage(1);
                            setFirst(0);
                            setSortField(e.sortField);
                            setSortDirection(sortDirection => sortDirection == 'asc' ? 'desc' : 'asc');
                        }}
                        onRowClick={onRowClick}
                        header={
                            <div style={{
                                width: 320, position: 'relative',
                                float: 'right'
                            }}>
                                <Input
                                    type='search'
                                    placeholder={I18n.t('workshops.searchPlaceholder')}
                                    onInput={e => {
                                        setFirst(0);
                                        setPage(1);
                                        setSortField(e.sortField);
                                        setGlobalFilter(e.target.value);
                                    }}
                                    className='global-filter'
                                />
                            </div>
                        }
                    >
                        <Column
                            field='registrationDate'
                            header={I18n.t('workshopLoyaltyProgram.workshops.registartionDate')}
                            body={(rowData) => dateUtils.formatDate(rowData.registrationDate, DATE_SHORT_FORMAT)}
                            sortable={true}
                        />
                        <Column
                            field='workshopName'
                            header={I18n.t('workshopLoyaltyProgram.workshops.workshopName')}
                            sortable={true}
                        />
                        <Column
                            field='email'
                            header={I18n.t('workshopLoyaltyProgram.workshops.email')}
                            sortable={true}
                        />
                        <Column
                            field='acceptedStatuteId'
                            header={I18n.t('workshopLoyaltyProgram.workshops.statuteAcceptation')}
                            body={(rowData)=>{return (loyaltyProgramStatute && loyaltyProgramStatute.id===rowData.acceptedStatuteId)? I18n.t('workshopLoyaltyProgram.workshops.yes'): I18n.t('workshopLoyaltyProgram.workshops.no')}}
                            sortable={true}
                        />
                        <Column
                            field='statuteAcceptanceDate'
                            header={I18n.t('workshopLoyaltyProgram.workshops.newStatuteDate')}
                            sortable={true}
                            body={(rowData) => dateUtils.formatDate(rowData.statuteAcceptanceDate, DATE_SHORT_FORMAT)}
                        />
                        <Column
                            field='loyaltyParticipationBlocked'
                            header={I18n.t('workshopLoyaltyProgram.workshops.toggleStatus')}
                            sortable={true}
                            body={(rowData) =>
                                    <Button
                                        className={styles.Btn}
                                        onClick={event => {
                                          event.stopPropagation()
                                          dispatch({
                                            type: updateDataSideBarConstants.SHOW,
                                            variableComponent: (
                                                <WorkshopLoyaltyProgramModal
                                                    toggleWorkshopStatus
                                                    workshop={rowData}
                                                    params={{
                                                            page: page - 1,
                                                            size: pageLimit,
                                                            sort: (sortField && sortDirection) && `${sortField},${sortDirection}`
                                                    }}
                                                    search={globalFilter}
                                                />
                                            ),
                                            title: rowData.loyaltyParticipationBlocked ? I18n.t("workshopLoyaltyProgram.workshops.unblockWorkshop") : I18n.t("workshopLoyaltyProgram.workshops.blockWorkshop"),
                                        })}}>
                                        {rowData.loyaltyParticipationBlocked ?
                                            <Translate value={"workshopLoyaltyProgram.workshops.unblock"} /> :
                                            <Translate value={"workshopLoyaltyProgram.workshops.block"} />}

                                    </Button>
                            }
                        />
                    </DataTable>
                    <div style={{ paddingBottom: '2rem', paddingLeft: 30, paddingRight: 32 }}>
                        <Paginator
                            first={first}
                            rows={pageLimit}
                            totalRecords={workshops.totalElements}
                            pageLinkSize={5}
                            onPageChange={(e) => {
                                setPage(e.page + 1)
                                setFirst(e.first)
                            }}
                            leftContent={
                                <Dropdown
                                    options={rowsQuantityToDisplay}
                                    className='data-table-rows-dropdown'
                                    value={pageLimit}
                                    onChange={e => { setPage(1), setPageLimit(e.value) }}
                                />
                            }
                        ></Paginator>
                    </div>
                </div>
            </Loadable>
        </ContentWrapper>
        <WorkshopParticipationBlockHistory/>
        </>
    )
}
