/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const operation = {
  EQUAL: ':',
  NOT_EQUAL: '!',
};

const JOIN = '@';

export class SearchCriteriaModel {
  key;
  operation;
  value;
  relationshipEntity;

  constructor(key, operation, value, relationshipEntity = null) {
    this.key = key;
    this.operation = operation;
    this.value = value;
    this.relationshipEntity = relationshipEntity;
  }

  toString() {
    if (this.relationshipEntity)
      return `${this.relationshipEntity}${JOIN}${this.key}${this.operation}${this.value}`;
    else return `${this.key}${this.operation}${this.value}`;
  }
}
