/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { RenderField } from "../../../../../shared/components/form/text-field/hook-form-version/RenderField";
import { I18n } from "react-redux-i18n";
import { useForm } from "react-hook-form";
import { ReqexpValidationEnum } from "../../../../../shared/utils/utils";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import { useDispatch, useSelector } from "react-redux";
import { workshopLoyaltyProgramActions } from "shared/actions/workshopLoyaltyProgram.actions";
import { RenderDropdown } from "shared/components/form/dropdown/hook-form-version/RenderDropdown";
import Loadable from 'react-loading-overlay';
import WorkshopLoyaltyProgramModal from "../../workshop-loyalty-program-modal/WorkshopLoyaltyProgramModal";

const PrizeProductsModalForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const [productTypeId, setProductTypeId] = useState(null);
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const productNames = useSelector(state => state.workshopLoyaltyProgram.productNames);
  const productTypes = useSelector(state => state.workshopLoyaltyProgram.productTypes);
  const productNamesFetching = useSelector(state => state.workshopLoyaltyProgram.productNamesFetching);
  const productTypesFetching = useSelector(state => state.workshopLoyaltyProgram.productTypesFetching);

  const homologationProductIdField = "homologationProductId";
  const productTypeIdField = "productTypeId";
  const descriptionField = "description";
  const productValueField = "value";
  const productTypeField = "productType";
  const productNameField = "productName";
  const { product, isNewProduct, pageSize, setPage } = props;

  useEffect(() => {
    if (product && productTypes) {
      if (product.homologationProductId && product.productType.id) {
        setValue(productTypeIdField, product.productType.id);
        setValue(homologationProductIdField, product.homologationProductId.toString());
      } else {
        setValue(productTypeField, product.productTypeName);
        setValue(productNameField, product.productName);
      }
      setValue(descriptionField, product.description);
      setValue(productValueField, product.value);
      setValue('id', product.id);
    }
  }, [product, productTypes]);

  useEffect(() => {
    dispatch(workshopLoyaltyProgramActions.getScoredProductTypes());
  }, []);

  useEffect(() => {
    if (productTypeId) {
      dispatch(workshopLoyaltyProgramActions.getScoredProductNames(productTypeId));
      product ? firstTimeRender ? setValue(homologationProductIdField, product.homologationProductId.toString()) : setValue(homologationProductIdField, null) : setValue(homologationProductIdField, null);
      setFirstTimeRender(false);
    }
  }, [productTypeId]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.productTypeId) {
        setProductTypeId(value.productTypeId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useImperativeHandle(ref, () => ({
    onSave: handleSubmit((data) => onSubmit(data)),
  }));

  const onSubmit = (data) => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    if (props.product) {
      dispatch({
        type: updateDataSideBarConstants.SHOW,
        variableComponent: (
          <WorkshopLoyaltyProgramModal 
            updatePrizeProduct 
            product={data} 
            pageSize={pageSize} 
            setPage={setPage} />
        ),
        title: I18n.t("workshopLoyaltyProgram.prizeProducts.wannahEditProduct"),
      })
    } else {
      dispatch(workshopLoyaltyProgramActions.createPrizeProduct(data, pageSize));
      setPage();
    }
  }

  return (
    <Loadable active={productNamesFetching || productTypesFetching} spinner>
      {isNewProduct || (product && !product.productTypeId && !product.homologationProductId) ?
        <>
          <RenderField
            {...register(productTypeField, {
              required: true,
              maxLength: 255,
            })}
            label={I18n.t("workshopLoyaltyProgram.prizeProducts.productType")}
            error={errors[productTypeField]}
            value={watch(productTypeField)}
            type="text"
            required
          />
          <RenderField
            {...register(productNameField, {
              required: true,
              maxLength: 255,
            })}
            label={I18n.t("workshopLoyaltyProgram.prizeProducts.productName")}
            error={errors[productNameField]}
            value={watch(productNameField)}
            type="text"
            required
          />
        </> :
        <>
          <RenderDropdown
            {...register(productTypeIdField, { required: true })}
            options={productTypes.map(type => { return { label: type.name, value: type.id } })}
            value={watch(productTypeIdField)}
            label={I18n.t("workshopLoyaltyProgram.prizeProducts.productType")}
            error={errors[productTypeIdField]}
            required
          />
          <RenderDropdown
            {...register(homologationProductIdField, { required: true })}
            options={productNames.map(name => { return { label: name.homologationProductFullName, value: name.homologationProductId } })}
            value={watch(homologationProductIdField)}
            disabled={!watch(productTypeIdField)}
            label={I18n.t("workshopLoyaltyProgram.prizeProducts.productName")}
            error={errors[homologationProductIdField]}
            required
          />
        </>}
      <RenderField
        {...register(descriptionField, { required: true, maxLength: 255 })}
        value={watch(descriptionField)}
        error={errors[descriptionField]}
        label={I18n.t("workshopLoyaltyProgram.prizeProducts.shortDescription")}
        required
      />
      <RenderField
        {...register(productValueField, { required: true, pattern: ReqexpValidationEnum.ONLY_NUMBERS, min: 0 })}
        value={watch(productValueField)}
        error={errors[productValueField]}
        label={I18n.t("workshopLoyaltyProgram.prizeProducts.productValue")}
        required
      />
    </Loadable >
  )
});

export default PrizeProductsModalForm;
