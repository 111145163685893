import React from 'react';
import CollapseSection from 'shared/components/collapse-section/CollapseSection';
import styles from './PeriodicInspections.module.css';
import {I18n} from 'react-redux-i18n';
import {useSelector, useDispatch} from 'react-redux';
import PeriodicInspectionEntry from './periodic-inspection-entry/PeriodicInspectionEntry';

const PeriodicInspections = props => {
  const isWarrantyFetching = useSelector(
    state => state.warrantyBook.isFetching
  );

  const periodicInspections = useSelector(
    state => state.warrantyBook.periodicInspections
  );
  return (
    <div className={styles.Container}>
      {periodicInspections.sort((a,b) => a.inspectionIndex - b.inspectionIndex).map(inspection => (
        <CollapseSection
          title={I18n.t(
            `warranty.sectionVII.periodicInspection${inspection.inspectionIndex}`
          )}
          key={inspection.inspectionIndex}
          defaultState={!!inspection.inspectionDate}
          withBorder>
          <PeriodicInspectionEntry {...inspection} />
        </CollapseSection>
      ))}
    </div>
  );
};

export default PeriodicInspections;
