/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {RadioButton} from 'primereact/radiobutton';
import PropTypes from 'prop-types';
import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button, Tooltip} from 'reactstrap';
import {change, Field, untouch} from 'redux-form';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import formatDoubleNumber from 'shared/components/form/formatter/DoubleNumberFormatter';
import normalizeDoubleNumber from 'shared/components/form/normalizer/DoubleNumberNormalizer';
import normalizeNumber from 'shared/components/form/normalizer/number-normalizer';
import renderField from 'shared/components/form/text-field/RenderField';
import {getFuelTypeFormOptions} from 'shared/utils/fuel-type/fuel-type.utils';
import {getOrNull, isEmptyArray, rangeArray} from 'shared/utils/utils';

export const CarDataTypes = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
};

export default class ValuationInquiryFormPageSchema2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      engineCapacityTooltipOpen: false,
      enginePowerTooltipOpen: false,
    };
    this.carDataTypeChange = this.carDataTypeChange.bind(this);
    this.fillExistingCarData = this.fillExistingCarData.bind(this);
    this.carBrandChanged = this.carBrandChanged.bind(this);
    this.toggleEngineCapacityTooltip =
      this.toggleEngineCapacityTooltip.bind(this);
    this.toggleEnginePowerTooltip = this.toggleEnginePowerTooltip.bind(this);
  }

  componentDidMount() {
    const {userCars, dispatch, setPageTitle} = this.props;
    setPageTitle('valuationInquiry.carDataTitle');

    if (isEmptyArray(userCars))
      dispatch(change('valuationInquiryForm', 'carDataType', CarDataTypes.NEW));

    if (
      this.props.carDataTypeValue === CarDataTypes.EXISTING &&
      this.hasOnlyOneCar() &&
      !this.props.loadedFromDraft
    )
      this.chooseFirstCar();
  }

  componentDidUpdate(prevProps) {
    if (
      this.changedToExistingCars(prevProps) &&
      this.hasOnlyOneCar() &&
      !this.props.loadedFromDraft
    )
      this.chooseFirstCar();
  }

  changedToExistingCars(prevProps) {
    return (
      prevProps.carDataTypeValue === CarDataTypes.NEW &&
      this.props.carDataTypeValue === CarDataTypes.EXISTING
    );
  }

  hasOnlyOneCar() {
    return Object.keys(this.userCars()).length === 1;
  }

  chooseFirstCar() {
    const {dispatch} = this.props;
    dispatch(
      change('valuationInquiryForm', 'carName', this.userCars()[0].value)
    );
    this.fillExistingCarData();
  }

  renderCarDataTypesRadioBtn() {
    return (
      <div className='row mt-3'>
        <div className='col-12 ml-4'>
          <div className='mb-2'>
            <RadioButton
              inputId='cdr-existing'
              value={CarDataTypes.EXISTING}
              onChange={this.carDataTypeChange}
              checked={this.props.carDataTypeValue === CarDataTypes.EXISTING}
              className='radio-button'
            />
            <label className='mb-0 radio-button-label' htmlFor='cdr-existing'>
              <Translate value='valuationInquiry.carDataTypeExistingLabel' />
            </label>
          </div>
          <div>
            <RadioButton
              inputId='cdr-new'
              value={CarDataTypes.NEW}
              onChange={this.carDataTypeChange}
              checked={this.props.carDataTypeValue === CarDataTypes.NEW}
              className='radio-button'
            />
            <label className='mb-0 radio-button-label' htmlFor='cdr-new'>
              <Translate value='valuationInquiry.carDataTypeNewLabel' />
            </label>
          </div>
        </div>
      </div>
    );
  }

  carDataTypeChange(e) {
    const {dispatch} = this.props;
    dispatch(change('valuationInquiryForm', 'carDataType', e.value));
    this.clearPageFields();
  }

  clearPageFields() {
    const {dispatch} = this.props;
    dispatch(change('valuationInquiryForm', 'carName', ''));
    dispatch(change('valuationInquiryForm', 'carBrand', ''));
    dispatch(change('valuationInquiryForm', 'carModel', ''));
    dispatch(change('valuationInquiryForm', 'carModelOther', ''));
    dispatch(change('valuationInquiryForm', 'carProductionYear', ''));
    dispatch(change('valuationInquiryForm', 'carEngineCapacityCm3', ''));
    dispatch(change('valuationInquiryForm', 'carEnginePowerKw', ''));
    dispatch(change('valuationInquiryForm', 'carFuelTypeId', ''));
    dispatch(
      untouch(
        'valuationInquiryForm',
        'carName',
        'carBrand',
        'carModel',
        'carModelOther',
        'carProductionYear',
        'carEngineCapacityCm3',
        'carEnginePowerKw',
        'carFuelTypeId'
      )
    );
  }

  toggleEngineCapacityTooltip() {
    this.setState({
      engineCapacityTooltipOpen: !this.state.engineCapacityTooltipOpen,
    });
  }

  toggleEnginePowerTooltip() {
    this.setState({
      enginePowerTooltipOpen: !this.state.enginePowerTooltipOpen,
    });
  }

  renderCarNameAsSelect() {
    return (
      <Field
        name='carName'
        component={RenderDropdown}
        options={this.userCars()}
        placeholder={I18n.t('valuationInquiry.carNamePlaceholder')}
        onChange={this.fillExistingCarData}
        label={I18n.t('valuationInquiry.carNameLabel')}
        icon='fa fa-car'
        required={true}
      />
    );
  }

  userCars() {
    const {userCars} = this.props;
    return userCars
      ? userCars.map(userCar => ({
          label: userCar.name,
          value: userCar.name,
        }))
      : [];
  }

  fillExistingCarData() {
    setTimeout(() => {
      const {fuelTypes, dispatch} = this.props;
      const selectedCar = this.getSelectedCar();
      const [fuelType] = selectedCar.fuelType
        ? fuelTypes.filter(fuelType => fuelType.name === selectedCar.fuelType)
        : '';
      dispatch(change('valuationInquiryForm', 'carBrand', selectedCar.brand));
      dispatch(change('valuationInquiryForm', 'carModel', selectedCar.model));
      dispatch(
        change(
          'valuationInquiryForm',
          'carProductionYear',
          selectedCar.productionYear
        )
      );
      dispatch(
        change(
          'valuationInquiryForm',
          'carEngineCapacityCm3',
          selectedCar.engineCapacityCm3
        )
      );
      dispatch(
        change(
          'valuationInquiryForm',
          'carEnginePowerKw',
          selectedCar.enginePowerKw
        )
      );
      dispatch(
        change(
          'valuationInquiryForm',
          'carFuelTypeId',
          fuelType ? fuelType.fuelTypeId : ''
        )
      );
      dispatch(
        change(
          'valuationInquiryForm',
          'additionalCarTankFuelTypeId',
          this.getAdditionalTankFuelTypeId(selectedCar)
        )
      );
    }, 100);
  }

  getAdditionalTankFuelTypeId(selectedCar) {
    return !isEmptyArray(selectedCar.carTanks) &&
      !isEmptyArray(selectedCar.carTanks.filter(t => !t.mainTank))
      ? getOrNull(selectedCar.carTanks.filter(t => !t.mainTank)[0]).fuelType
          .fuelTypeId
      : null;
  }

  getSelectedCar() {
    const {userCars, carNameFieldValue} = this.props;
    const carsMatching = userCars
      ? userCars.filter(userCar => userCar.name === carNameFieldValue)
      : [];
    return !isEmptyArray(carsMatching) ? carsMatching[0] : '';
  }

  renderCarModelOther() {
    return (
      <div>
        {this.renderCarModelOtherTip()}
        <Field
          name='carModelOther'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('valuationInquiry.carModelOtherLabel')}
          inputDisabled={this.props.carModelFieldValue !== 'other'}
        />
      </div>
    );
  }

  renderCarModelOtherTip() {
    return (
      <div className='row mt-3'>
        <div className='col-12'>
          <Alert className='mb-0 text-center' color='warning'>
            <Translate value='valuationInquiry.carModelOtherTip' />
          </Alert>
        </div>
      </div>
    );
  }

  shouldRenderNoEngineDataTip() {
    const {carEngineCapacityCm3FieldValue, carEnginePowerKwFieldValue} =
      this.props;
    return (
      this.isExistingCar() &&
      (!carEnginePowerKwFieldValue ||
        (!this.isFuelTypeElectricity() && !carEngineCapacityCm3FieldValue)) &&
      (!this.hasFieldBeenTouched('carEnginePowerKw') ||
        !this.hasFieldBeenTouched('carEngineCapacityCm3'))
    );
  }

  renderNoEngineDataTip() {
    return (
      <div className='row mt-3'>
        <div className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0' />
        <div className='col-lg-9 col-12'>
          <Alert className='mb-0 text-center' color='warning'>
            <Translate value='valuationInquiry.noEngineData' />
          </Alert>
        </div>
      </div>
    );
  }

  hasFieldBeenTouched(fieldName) {
    const {fields} = this.props;
    const field = fields ? fields[fieldName] : null;
    return field ? field.touched : false;
  }

  isNewCar() {
    return this.props.carDataTypeValue === CarDataTypes.NEW;
  }

  isExistingCar() {
    return this.props.carDataTypeValue === CarDataTypes.EXISTING;
  }

  years() {
    return Array.from(rangeArray(1970, new Date().getFullYear() + 1, 1))
      .reverse()
      .map(year => ({label: year.toString(), value: year}));
  }

  fuelTypes() {
    const {fuelTypes} = this.props;
    return getFuelTypeFormOptions(fuelTypes);
  }

  carBrands() {
    const {carBrands} = this.props;
    return carBrands
      ? carBrands.map(carBrand => ({
          label: carBrand.name,
          value: carBrand.name,
        }))
      : [];
  }

  carModels() {
    const {carBrands, carBrandFieldValue} = this.props;
    const selectedCarBrand = carBrands
      ? carBrands.filter(carBrand => carBrand.name === carBrandFieldValue)
      : [];
    const selectedCarBrandModels = !isEmptyArray(selectedCarBrand)
      ? selectedCarBrand[0].models
      : [];
    return selectedCarBrandModels
      .map(carModel => ({label: carModel.name, value: carModel.name}))
      .concat([{label: I18n.t('valuationInquiry.otherModel'), value: 'other'}]);
  }

  shouldShowFields() {
    return this.isNewCar() || (this.isExistingCar() && this.getSelectedCar());
  }

  carBrandChanged() {
    const {dispatch} = this.props;
    dispatch(change('valuationInquiryForm', 'carModel', ''));
    dispatch(change('valuationInquiryForm', 'carName', ''));
  }

  isFuelTypeElectricity() {
    const {carFuelTypeIdFieldValue} = this.props;
    const selectedFuelType = this.resolveFuelTypeById(carFuelTypeIdFieldValue);
    const selectedFuelTypeName = selectedFuelType ? selectedFuelType.name : '';
    return selectedFuelTypeName === 'ELECTRICITY';
  }

  fuelTypeChanged(e, value) {
    const {dispatch} = this.props;
    const selectedFuelType = this.resolveFuelTypeById(value);
    if (selectedFuelType && selectedFuelType.name === 'ELECTRICITY') {
      dispatch(change('valuationInquiryForm', 'carEngineCapacityCm3', ''));
    }
  }

  isCarEngineCapacityCm3Disabled() {
    return (
      (this.isExistingCar() && this.getSelectedCar().engineCapacityCm3) ||
      this.isFuelTypeElectricity()
    );
  }

  resolveFuelTypeById(carFuelTypeId) {
    const {fuelTypes} = this.props;
    return fuelTypes.find(fuelType => fuelType.fuelTypeId === carFuelTypeId);
  }

  render() {
    const {
      previousPage,
      handleSubmit,
      handleCancel,
      invalid,
      disableCarDataTypeSwitch,
      userCars,
      user,
      handleCreateDraft,
      additionalFields,
    } = this.props;

    const fixedBtn = {width: '7rem'};

    return (
      <form className='valuation-inquiry-form' onSubmit={handleSubmit}>
        <Field
          name='carDataType'
          component={props => <div className='d-none' />}
        />

        {!disableCarDataTypeSwitch &&
          !isEmptyArray(userCars) &&
          this.renderCarDataTypesRadioBtn()}

        {user.isAuthenticated &&
          this.isExistingCar() &&
          this.renderCarNameAsSelect()}

        {this.shouldShowFields() && (
          <div>
            <Field
              name='carBrand'
              component={RenderDropdown}
              options={this.carBrands()}
              required={true}
              placeholder={I18n.t('valuationInquiry.carBrandPlaceholder')}
              disabled={this.isExistingCar()}
              label={I18n.t('valuationInquiry.carBrandLabel')}
              icon='fa fa-cube'
              onChange={this.carBrandChanged}
            />
            <Field
              name='carModel'
              component={RenderDropdown}
              options={this.carModels()}
              required={true}
              placeholder={I18n.t('valuationInquiry.carModelPlaceholder')}
              disabled={!this.props.carBrandFieldValue || this.isExistingCar()}
              label={I18n.t('valuationInquiry.carModelLabel')}
              icon='fa fa-cubes'
            />

            {this.isNewCar() && this.renderCarModelOther()}

            <Field
              name='carProductionYear'
              component={RenderDropdown}
              options={this.years()}
              disabled={
                this.isExistingCar() && this.getSelectedCar().productionYear
              }
              placeholder={I18n.t(
                'valuationInquiry.carProductionYearPlaceholder'
              )}
              required={true}
              label={I18n.t('valuationInquiry.carProductionYearLabel')}
              icon='fa fa-calendar'
            />

            <Field
              name='carFuelTypeId'
              component={RenderDropdown}
              options={this.fuelTypes()}
              disabled={this.isExistingCar() && this.getSelectedCar().fuelType}
              placeholder={I18n.t('valuationInquiry.carFuelTypeIdPlaceholder')}
              label={I18n.t('valuationInquiry.carFuelTypeIdLabel')}
              icon='fa fa-tint'
              required={true}
              onChange={(e, value) => this.fuelTypeChanged(e, value)}
            />

            {this.shouldRenderNoEngineDataTip() && this.renderNoEngineDataTip()}

            {!this.isFuelTypeElectricity() && (
              <div id='engineCapacityTooltip'>
                <Field
                  name='carEngineCapacityCm3'
                  type='text'
                  component={renderField}
                  required={!this.isFuelTypeElectricity()}
                  icon='fa fa-cog'
                  inputDisabled={this.isCarEngineCapacityCm3Disabled()}
                  label={I18n.t('valuationInquiry.carEngineCapacityCm3Label')}
                  normalize={normalizeNumber}
                />
                <Tooltip
                  innerClassName='form-tooltip'
                  placement='top'
                  isOpen={this.state.engineCapacityTooltipOpen}
                  target='engineCapacityTooltip'
                  toggle={this.toggleEngineCapacityTooltip}>
                  <Translate value='valuationInquiry.engineCapacityTooltip' />
                </Tooltip>
              </div>
            )}

            <div id='enginePowerTooltip'>
              <Field
                name='carEnginePowerKw'
                type='text'
                component={renderField}
                required={true}
                icon='fa fa-bolt'
                inputDisabled={
                  this.isExistingCar() && !!this.getSelectedCar().enginePowerKw
                }
                label={I18n.t('valuationInquiry.carEnginePowerKwLabel')}
                normalize={normalizeDoubleNumber}
                format={formatDoubleNumber}
              />
              <Tooltip
                innerClassName='form-tooltip'
                placement='top'
                isOpen={this.state.enginePowerTooltipOpen}
                target='enginePowerTooltip'
                toggle={this.toggleEnginePowerTooltip}>
                <Translate value='valuationInquiry.enginePowerTooltip' />
              </Tooltip>
            </div>

            {additionalFields}
          </div>
        )}

        <div className='text-right mt-3'>
          <Button className='main-btn float-left' onClick={previousPage}>
            <Translate value='valuationInquiry.previousPageBtn' />
          </Button>
          <Button className='main-btn' type='submit' disabled={invalid}>
            <Translate value='valuationInquiry.nextPageBtn' />
          </Button>
        </div>

        <div className='text-sm-right text-center mt-3'>
          {handleCreateDraft && (
            <Button className='main-btn m-1' onClick={handleCreateDraft}>
              <Translate value='valuationInquiry.createDraftBtn' />
            </Button>
          )}
          <Button className='main-btn' onClick={handleCancel}>
            <Translate value='valuationInquiry.cancelBtn' />
          </Button>
        </div>
      </form>
    );
  }
}

ValuationInquiryFormPageSchema2.propTypes = {
  displayCarName: PropTypes.bool.isRequired,
  additionalFields: PropTypes.node,
};

ValuationInquiryFormPageSchema2.defaultProps = {
  displayCarName: true,
};
