/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Address, WorkshopDetails} from 'layout/admin/workshops/utils/models';
import {defaultClientId, defaultClientSecret} from '../../environment';
import {axiosService} from './axios.service';

export const workshopService = {
  createWorkshop,
  createNonPartnerWorkshop,
  findWorkshopByUserId,
  updateWorkshopByUserId,
  deleteWorkshop,
  getAllServicesForWorkshop,
  updateWorkshopServices,
  findWorkshopsByArea,
  findAllWorkshops,
  acceptInvite,
  sendInvite,
  findAllDeletedWorkshops,
  findDeletedWorkshopById,
  findNeededPartsInquiriesByParams,
  createNeededPartsInquiry,
  changeNeededPartsInquiryStatus,
  updateWorkshopAddress,
  updateWorkshopDetails,
  findAllByWorkshopUserIds,
  findAllWorkshopExternalCalendars,
  findWorkshopExternalCalendarById,
  createWorkshopExternalCalendar,
  updateWorkshopExternalCalendar,
  deleteWorkshopExternalCalendar,
  findWorkingDaysByWorkshopId,
  findWorkingDaysByWorkshopUserId,
  updateWorkingDaysByWorkshopId,
  canGetWorkshopValuationInquiry,
  findWorkshopCalendarEvents,
  updateCalendarEventByDelta,
  updateCalendarEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  getWorkshopWorkload,
  createBarcode,
  getBarcodes,
  reportBarcodeIssue,
  getValidBarcodes,
};

function createWorkshop(values) {
  return axiosService
    .post('/workshop/workshops', toCorrectJson(values))
    .then(response => {
      return response;
    });
}

function createNonPartnerWorkshop(values) {
  const clientAuth = btoa(defaultClientId + ':' + defaultClientSecret);
  return axiosService
    .post('/workshop/workshops/non-partner', toCorrectJson(values), {
      headers: {'client-auth': clientAuth},
    })
    .then(response => {
      return response;
    });
}

const toCorrectJson = values => {
  const address = new Address(values);
  const workshopDetails = new WorkshopDetails(values);
  Object.keys(address).forEach(key => {
    delete values[key];
  });
  Object.keys(workshopDetails).forEach(key => {
    delete values[key];
  });
  return {address: address, workshopDetails: workshopDetails, ...values};
};

function findAllWorkshops(search = '') {
  const url = search
    ? `/workshop/workshops?search=${search}`
    : '/workshop/workshops';
  return axiosService.get(url);
}

function findWorkshopByUserId(userId) {
  return axiosService
    .get(`/workshop/workshops/user/${userId}`)
    .then(response => {
      return response;
    });
}

function updateWorkshopByUserId(userId, data) {
  return axiosService
    .put(`/workshop/workshops/user/${userId}`, toCorrectJson(data))
    .then(response => {
      return response;
    });
}

function deleteWorkshop(workshopId, data) {
  return axiosService
    .delete(`/workshop/workshops/${workshopId}`, {data: {...data}})
    .then(response => {
      return response;
    });
}

function getAllServicesForWorkshop(workshopId) {
  return axiosService
    .get(`/workshop/workshops/${workshopId}/services`)
    .then(response => {
      return response;
    });
}

function updateWorkshopServices(workshopId, data) {
  return axiosService
    .put(`/workshop/workshops/${workshopId}/services`, data)
    .then(response => {
      return response;
    });
}

function findWorkshopsByArea(
  basePositionLat,
  basePositionLng,
  radiusKm,
  country
) {
  const endpoint =
    `/workshop/workshops/filter/area` +
    `?basePositionLat=${basePositionLat}&basePositionLng=${basePositionLng}&radiusKm=${radiusKm}&country=${country}`;
  return axiosService.get(endpoint).then(response => {
    return response;
  });
}

function acceptInvite(token) {
  return axiosService
    .post(`/workshop/workshops/invite/accept?invitationToken=${token}`, null, {
      responseType: 'text',
    })
    .then(response => {
      return response;
    });
}

function sendInvite(workshopId) {
  return axiosService
    .post(`/workshop/workshops/${workshopId}/invite`)
    .then(response => {
      return response;
    });
}

function findAllDeletedWorkshops() {
  return axiosService.get(`/workshop/workshops/deleted`);
}

function findDeletedWorkshopById(workshopId) {
  return axiosService
    .get(`/workshop/workshops/deleted/${workshopId}`)
    .then(response => {
      return response;
    });
}

function findNeededPartsInquiriesByParams(search) {
  return axiosService
    .get(`/workshop/needed-parts?search=${search}`)
    .then(response => {
      return response;
    });
}

function createNeededPartsInquiry(values) {
  return axiosService.post('/workshop/needed-parts', values).then(response => {
    return response;
  });
}

function changeNeededPartsInquiryStatus(neededPartsInquiryId, newStatus) {
  return axiosService
    .put(
      `/workshop/needed-parts/${neededPartsInquiryId}?newStatus=${newStatus}`
    )
    .then(response => {
      return response;
    });
}

function updateWorkshopAddress(workshopId, data) {
  return axiosService
    .put(`/workshop/workshops/${workshopId}/address`, data)
    .then(response => {
      return response;
    });
}

function updateWorkshopDetails(workshopId, data) {
  return axiosService
    .put(`/workshop/workshops/${workshopId}/details`, data)
    .then(response => {
      return response;
    });
}

function findAllByWorkshopUserIds(workshopUserIds) {
  return axiosService
    .get(
      `/workshop/workshops/filter/userIds${workshopUserIdsToQueryString(
        workshopUserIds
      )}`
    )
    .then(response => {
      return response;
    });
}

const workshopUserIdsToQueryString = workshopUserIds => {
  let queryString = '?';
  workshopUserIds.forEach((id, index) => {
    queryString = queryString.concat(
      `userIds=${id}${index + 1 === workshopUserIds.length ? '' : '&'}`
    );
  });
  return queryString;
};

function findAllWorkshopExternalCalendars(workshopUserId) {
  return axiosService
    .get(
      `/workshop/workshop-external-calendars?workshopUserId=${workshopUserId}`
    )
    .then(response => {
      return response;
    });
}

function findWorkshopExternalCalendarById(id) {
  return axiosService
    .get(`/workshop/workshop-external-calendars/${id}`)
    .then(response => {
      return response;
    });
}

function createWorkshopExternalCalendar(data) {
  return axiosService
    .post(`/workshop/workshop-external-calendars`, data)
    .then(response => {
      return response;
    });
}

function updateWorkshopExternalCalendar(id, data) {
  return axiosService
    .put(`/workshop/workshop-external-calendars/${id}`, data)
    .then(response => {
      return response;
    });
}

function deleteWorkshopExternalCalendar(id) {
  return axiosService
    .delete(`/workshop/workshop-external-calendars/${id}`)
    .then(response => {
      return response;
    });
}

function findWorkingDaysByWorkshopId(workshopId) {
  return axiosService
    .get(`/workshop/workshops/${workshopId}/working-days`)
    .then(response => {
      return response;
    });
}

function findWorkingDaysByWorkshopUserId(workshopUserId) {
  return axiosService
    .get(`/workshop/workshops/working-days?workshopUserId=${workshopUserId}`)
    .then(response => {
      return response;
    });
}

function updateWorkingDaysByWorkshopId(workshopId, data) {
  return axiosService
    .put(`/workshop/workshops/${workshopId}/working-days`, data)
    .then(response => {
      return response;
    });
}

function canGetWorkshopValuationInquiry(workshopId) {
  return axiosService
    .get(
      `/workshop/workshops/${workshopId}/workshop-valuation-inquiry-possibility`
    )
    .then(response => {
      return response;
    });
}

function findWorkshopCalendarEvents(workshopId) {
  return axiosService
    .get(`/workshop/workshop-calendars/events?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}

function updateCalendarEventByDelta(eventId, body) {
  return axiosService
    .put(`/workshop/workshop-calendars/events/${eventId}/delta`, body)
    .then(response => {
      return response;
    });
}

function updateCalendarEvent(eventId, body) {
  return axiosService
    .put(`/workshop/workshop-calendars/events/${eventId}`, body)
    .then(response => {
      return response;
    });
}

function createCalendarEvent(body) {
  return axiosService
    .post(`/workshop/workshop-calendars/events`, body)
    .then(response => {
      return response;
    });
}

function deleteCalendarEvent(eventId) {
  return axiosService
    .delete(`/workshop/workshop-calendars/events/${eventId}`)
    .then(response => {
      return response;
    });
}

function getWorkshopWorkload(params) {
  return axiosService
    .post(`/workshop/workshop-workloads`, params)
    .then(response => {
      return response;
    });
}

function createBarcode(body) {
  return axiosService.post(`/workshop/barcode`, body);
}

function getBarcodes(params) {
  return axiosService.get(`/workshop/barcode`, {params});
}

function getValidBarcodes(params) {
  return axiosService.get(`/workshop/barcode/valid`, {params});
}

function reportBarcodeIssue(body) {
  return axiosService.post(`/workshop/barcode/form`, body);
}
