/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import { useMemo } from "react";

const useWarrantyBookLocalStorageGetValue = (field, dependency) => {
  const parsedField = useMemo(
    () =>
      localStorage.getItem("warrantyBook") !== null
        ? field? JSON.parse(localStorage.getItem("warrantyBook"))[field] : JSON.parse(localStorage.getItem("warrantyBook"))
        : {},
    dependency ? [dependency] : []
  );
  return parsedField;
};

export default useWarrantyBookLocalStorageGetValue

