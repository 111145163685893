/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {LanguageForm} from './LanguageForm';
import {languageActions} from '../../../actions/language.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {validate} from './language-form-validators';

export const LanguageFormReduxFormWrapper = reduxForm({
  form: 'languageCreateForm',
  validate,
  enableReinitialize: true,
})(LanguageForm);

class LanguageCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createLanguage, onCreateSuccess} = this.props;
    createLanguage(data, onCreateSuccess);
  }

  render() {
    const {isFetchingLanguage} = this.props;

    return (
      <Loadable active={isFetchingLanguage} spinner>
        <LanguageFormReduxFormWrapper {...this.props} onSubmit={this.create} />
      </Loadable>
    );
  }
}

LanguageCreateForm.propTypes = {
  createLanguage: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, language} = state;
  const {isFetchingLanguage} = language;

  return {i18n, isFetchingLanguage};
}

const mapDispatchToProps = {
  createLanguage: languageActions.createLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageCreateForm);
