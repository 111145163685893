/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateByLengthOnly} from 'shared/components/form/common-validators/common-validators';
import ValidationMessageEnum, {hasArrayDuplicates} from 'shared/utils/utils';

export const validate = values => {
  let errors = {};
  errors.valuationInquiryServices = validateServices(
    values.valuationInquiryServices
  );
  errors.otherServices = validateByLengthOnly(values.otherServices, 255);
  return errors;
};

const validateServices = services => {
  if (!services || !services.length) return {_error: required};
  else {
    const serviceArrayErrors = [];
    services.forEach((service, index) => {
      const serviceErrors = {};
      if (!service || !service.serviceCategoryId) {
        serviceErrors.serviceCategoryId = required;
        serviceArrayErrors[index] = serviceErrors;
      }
      if (!service || !service.serviceId) {
        serviceErrors.serviceId = required;
        serviceArrayErrors[index] = serviceErrors;
      }
    });

    if (hasArrayDuplicates(services.map(service => service.serviceId)))
      return {
        _error: {key: ValidationMessageEnum.DUPLICATES_EXISTS, params: {}},
      };

    if (serviceArrayErrors.length) return serviceArrayErrors;
  }
};

const required = {key: ValidationMessageEnum.REQUIRED, params: {}};
