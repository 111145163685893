/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import * as React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {withDataTableCore} from '../../../hoc/data-table-core/DataTableCoreHoc';
import {indexArray} from '../../../utils/utils';

class LanguagesTable extends React.Component {
  constructor(props) {
    super(props);

    this.dataTable = null;
    this.setDatatableRef = element => {
      this.dataTable = element;
    };
    this.renderCreateBtn = this.renderCreateBtn.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.rows !== prevProps.rows)
      this.dataTable.onPageChange({first: 0, rows: this.props.rows});
  }

  renderCreateBtn() {
    return (
      <Button
        className='main-btn data-table-button'
        onClick={() => this.props.onCreateClick()}>
        <Translate value='languages.createBtn' />
      </Button>
    );
  }

  render() {
    const {languages, onRowClick, headerBuilder, renderRowsDropdown} =
      this.props;

    return (
      <DataTable
        value={indexArray(languages)}
        paginator={true}
        paginatorLeft={renderRowsDropdown()}
        rows={this.props.rows}
        globalFilter={this.props.globalFilter}
        header={headerBuilder()}
        className='data-table-padding-class'
        responsive={true}
        emptyMessage={I18n.t('languages.emptyLanguagesPlaceholder')}
        onRowClick={onRowClick}
        paginatorRight={this.renderCreateBtn()}
        ref={this.setDatatableRef}>
        <Column
          field='index'
          className='lp-col'
          header={I18n.t('languages.lp')}
          sortable={true}
        />
        <Column
          field='label'
          header={I18n.t('languages.label')}
          sortable={true}
        />
        <Column
          field='symbol'
          header={I18n.t('languages.symbol')}
          sortable={true}
        />
      </DataTable>
    );
  }
}

LanguagesTable.propTypes = {
  languages: PropTypes.array,
  onRowClick: PropTypes.func,
  onCreateClick: PropTypes.func,
};

LanguagesTable.defaultProps = {
  languages: [],
  onRowClick: () => {},
  onCreateClick: () => {},
};

export {LanguagesTable as LanguagesTableCore};

export default withDataTableCore(LanguagesTable);
