/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Collapse} from 'reactstrap';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';

class CollapsedLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isCollapsed: false};
    this.toggle = this.toggle.bind(this);
    this.checkActivePath = this.checkActivePath.bind(this);
  }

  toggle() {
    this.setState({isCollapsed: !this.state.isCollapsed});
  }

  renderPath(pathSuffix, link) {
    return (
      <li key={link.id}>
        <NavLink
          to={`${pathSuffix}${link.id}`}
          exact={true}
          activeClassName='active'>
          <div>{link.name}</div>
        </NavLink>
      </li>
    );
  }

  checkActivePath(pathSuffix) {
    return window.location.href.indexOf(pathSuffix) >= 0;
  }

  render() {
    const {links, pathSuffix, title, icon} = this.props;
    return (
      <div>
        <li>
          <a
            className={
              this.checkActivePath(pathSuffix)
                ? `active fa ${icon} fa-2x clickable`
                : `fa ${icon} fa-2x clickable`
            }
            onClick={this.toggle}>
            <p>
              <Translate value={title} />
              <i className='fa fa-caret-down d-block' />
            </p>
          </a>
        </li>
        <Collapse isOpen={this.state.isCollapsed}>
          <ul style={{backgroundColor: '#2b384e'}}>
            {links && links.map(link => this.renderPath(pathSuffix, link))}
          </ul>
        </Collapse>
      </div>
    );
  }
}

CollapsedLinks.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  pathSuffix: PropTypes.string.isRequired,
  links: PropTypes.array,
};

export default connect(null, null, null, {pure: false})(CollapsedLinks);
