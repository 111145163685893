/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {reduxForm, getFormValues} from 'redux-form';
import {connect} from 'react-redux';
import '../valuation-inquiry-response-form.css';
import {validate} from './validators';
import ValuationInquiryResponseFormSchemaPage2 from './ValuationInquiryResponseFormSchemaPage2';

const ValuationInquiryResponseFormPage2 = reduxForm({
  form: 'valuationInquiryResponseForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryResponseFormSchemaPage2);

function mapStateToProps(state) {
  const {i18n, part} = state;
  const partCategories = part.categories;
  const formValues = getFormValues('valuationInquiryResponseForm')(state);

  return {i18n, partCategories, formValues};
}

export default connect(mapStateToProps)(ValuationInquiryResponseFormPage2);
