/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from 'reactstrap';
import {workshopActions} from 'shared/actions/workshop.actions';
import {formatPhoneNumber} from 'shared/components/form/formatter/PhoneNumberFormatter';
import {workshopConstants} from 'shared/constants/workshop.constants';
import {formatAddress} from 'shared/utils/address-formatter/address-formater.utils';
import {RenderDetailsCore} from '../RenderDetailsCore';
import RenderGmap from './RenderGmap';

class RenderValuationWorkshopInfoDetails extends RenderDetailsCore {
  constructor(props) {
    super(props);

    this.state = {collapse: false, isFirstFetch: true};
  }

  componentDidMount() {
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.props.clearWorkshopData();
  }

  componentDidUpdate() {
    const {valuation} = this.props;
    if (this.state.isFirstFetch && valuation && valuation.senderUserId) {
      this.props.findWorkshopByUserId(valuation.senderUserId);
      this.setState({isFirstFetch: false});
    }
  }

  renderDetails() {
    const {selected} = this.props;
    return (
      <div className='col-12 mb-3'>
        <Translate
          value={'valuationDetails.workshopNameLabel'}
          className='mr-1 font-bold'
        />
        {selected.workshopDetails.workshopName}
        <br />

        <Translate
          value={'valuationDetails.emailLabel'}
          className='mr-1 font-bold'
        />
        {selected.email}
        <br />

        <Translate
          value={'valuationDetails.addressLabel'}
          className='mr-1 font-bold'
        />
        {formatAddress(selected.address)}
        <br />

        <Translate
          value={'valuationDetails.phoneNumberLabel'}
          className='mr-1 font-bold'
        />
        {this.phoneNumberToString(selected.workshopDetails.phoneNumber)}
        <br />

        <Translate
          value={'valuationDetails.mobilePhoneNumberLabel'}
          className='mr-1 font-bold'
        />
        {this.phoneNumberToString(selected.workshopDetails.mobilePhoneNumber)}
        <br />

        <Translate
          value={'valuationDetails.faxNumberLabel'}
          className='mr-1 font-bold'
        />
        {this.phoneNumberToString(selected.workshopDetails.faxNumber)}
        <br />
      </div>
    );
  }

  phoneNumberToString(phoneNumber) {
    return phoneNumber && phoneNumber.phoneNumber
      ? `${
          phoneNumber.phoneNumberPrefix && phoneNumber.phoneNumberPrefix
        } ${formatPhoneNumber(phoneNumber.phoneNumber)}`
      : `${I18n.t('valuationDetails.noData')}`;
  }

  render() {
    const {selected, isFetching} = this.props;
    return (
      <div className='details-border mt-1'>
        {super.renderHeader('workshopInfoHeader')}
        <Collapse isOpen={this.state.collapse}>
          <Loadable active={isFetching} spinner>
            <div className='row valuation-inquiry-details-content px-3'>
              {selected && this.renderDetails()}
              <RenderGmap workshopData={selected} />
            </div>
          </Loadable>
        </Collapse>
      </div>
    );
  }
}

RenderValuationWorkshopInfoDetails.propsTypes = {
  valuation: PropTypes.object,
};

const mapStateToProps = state => {
  const {i18n, workshop} = state;
  const {isFetching, selected} = workshop;

  return {i18n, isFetching, selected};
};

const mapDispatchToProps = {
  findWorkshopByUserId: workshopActions.findWorkshopByUserId,
  clearWorkshopData: () => ({
    type: workshopConstants.CLEAR_SELECTED_WORKSHOP_DATA,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderValuationWorkshopInfoDetails);
