/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {workshopWorkerConstants} from '../constants/workshop-worker.constants';
import {SyncState} from '../utils/sync';

export function workshopWorkerReducer(state = {}, action) {
  switch (action.type) {
    case workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_START:
      return {
        ...state,
        isFetching: true,
      };

    case workshopWorkerConstants.WORKSHOP_WORKER_REQUEST_END:
      return {
        ...state,
        isFetching: false,
      };

    case workshopWorkerConstants.LOAD_WORKSHOP_WORKERS:
      return {
        ...state,
        workshopWorkers: action.payload.data,
        workshopWorkersSyncState: SyncState.UP_TO_DATE,
      };

    case workshopWorkerConstants.REFRESH_WORKSHOP_WORKERS:
      return {
        ...state,
        workshopWorkersSyncState: SyncState.OBSOLETE,
      };

    case workshopWorkerConstants.LOAD_WORKSHOP_WORKER:
      return {
        ...state,
        activeWorker: action.payload.data,
      };

    case workshopWorkerConstants.CLEAR_WORKER:
      const {activeWorker, ...otherState} = state;
      return otherState;

    default:
      return state;
  }
}
