/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert, Button} from 'reactstrap';
import {change, Field, getFormValues, reduxForm} from 'redux-form';
import RenderCheckbox from 'shared/components/form/checkbox/RenderCheckbox';
import CountryField from 'shared/components/form/country-field/CountryField';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import RenderGmap from 'shared/components/form/gmap/RenderGmap';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import {getPhonePrefixByCountry} from 'shared/components/form/phone-number/utils/utils';
import StateField from 'shared/components/form/state-field/StateField';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {userService} from 'shared/services/user.service';
import {getOrDefault, languageOptions} from 'shared/utils/utils';
import {AcceptTerms} from '../../../../sign-up/AcceptTerms';
import {ReCaptcha} from '../../../../sign-up/ReCaptcha';
import {asyncValidate} from '../../../../sign-up/SignUpValidators';
import {
  getDefaultCurrency,
  resolveCurrencyOptions,
} from '../../distributors/utils/utils';
import {CreateWorkshopFormMode, workshopTypeOptions} from '../utils/utils';
import {validate} from './validators/validators';

class CreateWorkshopForm extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, initialValues, form, countries} = this.props;
    if (this.props.shouldInitializePhonePrefixes(prevProps, formValues))
      this.props.initializePhonePrefixesByCountry(form, formValues.country);
    if (this.shouldInitializeCurrency(prevProps)) this.setDefaultCurrency();
  }

  shouldInitializeCurrency(prevProps) {
    const {formValues, countries, initialValues} = this.props;
    return (
      countries &&
      formValues &&
      prevProps.formValues &&
      prevProps.formValues.country !== formValues.country
    );
  }

  setDefaultCurrency() {
    const {countries, form, formValues, dispatch} = this.props;
    dispatch(
      change(
        form,
        'currency',
        getDefaultCurrency(formValues.country, countries)
      )
    );
  }

  getFormMode() {
    return getOrDefault(this.props.mode, CreateWorkshopFormMode.ADMIN);
  }

  getAdminModeSpecificSection() {
    const {invalid, handleCancel} = this.props;
    const isAdminMode = this.getFormMode() === CreateWorkshopFormMode.ADMIN;

    return (
      isAdminMode && (
        <Fragment>
          <Field
            name='sendInvite'
            component={RenderCheckbox}
            label={I18n.t('createWorkshop.sendInvite')}
          />

          <div className='row mt-3'>
            <div
              className='col-lg-3 col-sm-12 my-lg-auto text-lg-right px-lg-0'
              style={{whiteSpace: 'nowrap'}}
            />
            <div className='col-lg-9 col-12'>
              <div id='workshop-form-save-btn' className='text-right'>
                <Button type='submit' disabled={invalid} className='main-btn'>
                  <Translate value='createWorkshop.submitBtn' />
                </Button>
                <Button onClick={handleCancel} className='main-btn'>
                  <Translate value='workshops.cancelBtn' />
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )
    );
  }

  getSignUpModeSpecificSection() {
    const {invalid, goBackHandler} = this.props;
    const isSignUpMode = this.getFormMode() === CreateWorkshopFormMode.SIGN_UP;

    return (
      isSignUpMode && (
        <Fragment>
          <Field name='acceptTerms' component={AcceptTerms} />

          <Field name='captchaToken' component={ReCaptcha} />

          <div className='row text-center mt-3'>
            <div className='col-sm-4 col-12 text-sm-left back-btn-col back-btn mt-sm-0 mt-3 order-sm-1 order-2'>
              <Button onClick={goBackHandler} className='main-btn'>
                <Translate value='signup.backBtn' />
              </Button>
            </div>
            <div
              className='col-sm-4 col-12 order-sm-2 order-1 text-sm-right'
              style={{marginLeft: 'auto'}}>
              <Button type='submit' className='main-btn'>
                <Translate value='signup.signupBtn' />
              </Button>
            </div>
          </div>
        </Fragment>
      )
    );
  }

  render() {
    const {handleSubmit, invalid, countries, formValues, disableRootPanel} =
      this.props;
    const currencyOptions = formValues
      ? resolveCurrencyOptions(countries, formValues.country)
      : null;
    const rootPanelClasses = disableRootPanel ? '' : 'm-3 root-panel';

    return (
      <form
        className={'workshop-form ' + rootPanelClasses}
        onSubmit={handleSubmit}>
        <Field
          name='language'
          component={RenderDropdown}
          options={languageOptions}
          placeholder={I18n.t('createWorkshop.languagePlaceholder')}
          label={I18n.t('createWorkshop.languageLabel')}
          icon='fa fa-language'
          required={true}
        />
        <CountryField disabled={userService.isDistributor()} />
        {this.getFormMode() === CreateWorkshopFormMode.ADMIN && (
          <Field
            name='type'
            component={RenderDropdown}
            options={workshopTypeOptions()}
            placeholder={I18n.t('createWorkshop.typePlaceholder')}
            label={I18n.t('createWorkshop.typeLabel')}
            icon='fa fa-info'
            required={true}
          />
        )}

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='createWorkshop.basicData' />
        </h3>
        <Field
          name='email'
          type='email'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.emailLabel')}
          icon='fa fa-at'
        />
        <Field
          name='password'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.passwordLabel')}
          icon='fa fa-unlock-alt'
        />
        <Field
          name='confirmPassword'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.confirmPasswordLabel')}
          icon='fa fa-unlock-alt'
        />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='createWorkshop.addressData' />
        </h3>

        <Field
          name='workshopName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.workshopNameLabel')}
          icon='fa fa-briefcase'
        />
        <Field
          name='ownerName'
          type='text'
          component={renderField}
          required={true}
          withTooltip={true}
          tooltipContent={I18n.t('createWorkshop.ownerNameTooltip')}
          label={I18n.t('createWorkshop.ownerNameLabel')}
          icon='fa fa-user'
        />
        <Alert color='warning' className='text-center mt-3'>
          <Translate value='createWorkshop.mapTip' />
        </Alert>
        <RenderGmap formName={'createWorkshopForm'} />
        <StateField formName={'createWorkshopForm'} required={true} />
        <Field
          name='city'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.cityLabel')}
          icon='fa fa-building'
        />
        <Field
          name='postalCode'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.postalCodeLabel')}
          icon='fa fa-inbox'
        />
        <Field
          name='postalCity'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.postalCityLabel')}
          icon='fa fa-envelope'
        />
        <Field
          name='streetAddress'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.streetAddressLabel')}
          icon='fa fa-address-card'
        />
        <Field
          name='latitude'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.latitudeLabel')}
          icon='fa fa-globe'
        />
        <Field
          name='longitude'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('createWorkshop.longitudeLabel')}
          icon='fa fa-globe'
        />

        <h3 className='text-center mt-3 mb-3'>
          <Translate value='createWorkshop.companyData' />
        </h3>

        <Field
          name='phoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('createWorkshop.phoneNumberLabel')}
          format={formatPhoneNumberField}
        />
        <Field
          name='mobilePhoneNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('createWorkshop.mobilePhoneNumber')}
          format={formatPhoneNumberField}
        />
        <Field
          name='faxNumber'
          type='text'
          component={PhoneNumber}
          required={false}
          label={I18n.t('createWorkshop.faxLabel')}
          icon='fa fa-fax'
          format={formatPhoneNumberField}
        />
        <Field
          name='nip'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('createWorkshop.nipLabel')}
          icon='fa fa-info'
        />
        <Field
          name='regon'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('createWorkshop.regonLabel')}
          icon='fa fa-info'
        />
        <Field
          name='krs'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('createWorkshop.krsLabel')}
          icon='fa fa-info'
        />
        <Field
          name='website'
          type='text'
          component={renderField}
          required={false}
          label={I18n.t('createWorkshop.websiteLabel')}
          icon='fa fa-info'
        />
        <Field
          name='currency'
          component={RenderDropdown}
          options={currencyOptions}
          icon='fa fa-money'
          label={I18n.t('distributors.currencyLabel')}
          placeholder={I18n.t('distributors.currencyPlaceholder')}
          disabled={!currencyOptions}
        />
        <Field
          name='description'
          type='text'
          component={RenderTextArea}
          required={false}
          label={I18n.t('createWorkshop.descriptionLabel')}
          icon='fa fa-info'
        />

        {this.getAdminModeSpecificSection()}
        {this.getSignUpModeSpecificSection()}
      </form>
    );
  }
}

const fixedBtn = {
  width: '7rem',
};

CreateWorkshopForm = reduxForm({
  form: 'createWorkshopForm',
  validate,
  asyncBlurFields: ['email'],
  touchOnChange: true,
  enableReinitialize: true,
})(CreateWorkshopForm);

function setInitialValues(countries, locale) {
  const shouldInitialize = userService.isDistributor() && countries && locale;
  const userCountry = userService.getUserCountry();
  return {
    language: locale,
    country: shouldInitialize ? userCountry : '',
    currency: shouldInitialize
      ? getDefaultCurrency(userCountry, countries)
      : '',
    phoneNumber: {
      phoneNumberPrefix: shouldInitialize
        ? getPhonePrefixByCountry(userCountry, countries)
        : '',
    },
    faxNumber: {
      phoneNumberPrefix: shouldInitialize
        ? getPhonePrefixByCountry(userCountry, countries)
        : '',
    },
    mobilePhoneNumber: {
      phoneNumberPrefix: shouldInitialize
        ? getPhonePrefixByCountry(userCountry, countries)
        : '',
    },
  };
}

function mapStateToProps(state) {
  const {i18n, country} = state;

  const {countries} = country;

  return {
    i18n,
    initialValues: setInitialValues(countries, i18n.locale),
    countries,
    formValues: getFormValues('createWorkshopForm')(state),
  };
}

//export for tests
export const CreateWorkshopFormConnectedWithRedux =
  connect(mapStateToProps)(CreateWorkshopForm);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(CreateWorkshopForm)
);
