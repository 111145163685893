/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {countryConstants} from '../constants/country.constants';

export function countryReducer(state = {isFetching: false}, action) {
  switch (action.type) {
    case countryConstants.COUNTRY_REQUEST_START:
      return {...state, isFetching: true};
    case countryConstants.COUNTRY_REQUEST_END:
      return {...state, isFetching: false};
    case countryConstants.LOAD_COUNTRIES:
      return {...state, countries: action.payload.data};
    default:
      return state;
  }
}
