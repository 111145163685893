/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Fragment} from 'react';
import {I18n} from 'react-redux-i18n';
import {Field} from 'redux-form';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import formatDoubleNumber from 'shared/components/form/formatter/DoubleNumberFormatter';
import normalizeDoubleNumber from 'shared/components/form/normalizer/DoubleNumberNormalizer';
import renderField from 'shared/components/form/text-field/RenderField';

export default class OtherSection extends React.Component {
  render() {
    const {currencyOptions} = this.props;
    return (
      <Fragment>
        <Field
          name='trader'
          type='text'
          component={renderField}
          label={I18n.t('distributors.traderLabel')}
          icon='fa fa-briefcase'
        />
        <Field
          name='currency'
          component={RenderDropdown}
          options={currencyOptions}
          icon='fa fa-money'
          label={I18n.t('distributors.currencyLabel')}
          placeholder={I18n.t('distributors.currencyPlaceholder')}
          disabled={!currencyOptions}
        />
        <Field
          name='taxValueInPercentage'
          type=''
          component={renderField}
          normalize={normalizeDoubleNumber}
          format={formatDoubleNumber}
          label={I18n.t('distributors.taxValueInPercentageLabel')}
          icon='fa fa-percent'
        />
      </Fragment>
    );
  }
}
