/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {getDecimalSeparator} from '../../../utils/i18n';

const formatDoubleNumber = value =>
  !value && value != 0
    ? ''
    : value.toString().replace('.', getDecimalSeparator());

export default formatDoubleNumber;
