/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {validateRequiredNumberWithRange} from 'shared/components/form/common-validators/common-validators';
import ValidationMessageEnum from 'shared/utils/utils';

export const validate = values => {
  let errors = {};
  errors.services = validateServices(values.services);
  return errors;
};

const validateServices = services => {
  if (!services || !services.length) return {_error: required};
  else {
    const serviceArrayErrors = [];
    services.forEach((service, index) => {
      const serviceErrors = {serviceCost: {}};
      if (!service || !service.serviceCost || !service.serviceCost.currency) {
        serviceErrors.serviceCost.value = required;
        serviceArrayErrors[index] = serviceErrors;
      } else {
        serviceErrors.serviceCost.value = validateRequiredNumberWithRange(
          service.serviceCost.value,
          0.01,
          99999.99
        );
        if (serviceErrors.serviceCost.value)
          serviceArrayErrors[index] = serviceErrors;
      }
      if (
        !(service.guaranteePeriodMonths || service.guaranteePeriodMonths === 0)
      ) {
        serviceErrors.guaranteePeriodMonths = required;
        serviceArrayErrors[index] = serviceErrors;
      }
    });

    if (serviceArrayErrors.length) return serviceArrayErrors;
  }
};

const required = {key: ValidationMessageEnum.REQUIRED, params: {}};
