/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {operationType, serviceConstants} from '../constants/service.constants';
import {SyncState} from '../utils/sync';
import store from '../../store';
import {removeMatchItem} from '../utils/utils';

export function serviceReducer(state = {}, action) {
  switch (action.type) {
    case serviceConstants.SERVICE_REQUEST_START:
      return {...state, isFetching: true};
    case serviceConstants.SERVICE_REQUEST_END:
      return {...state, isFetching: false};
    case serviceConstants.LOAD_SERVICE_CATEGORIES:
      return {
        ...state,
        categories: action.payload.data,
        serviceCategoriesDataSyncState: SyncState.UP_TO_DATE,
      };
    case serviceConstants.LOAD_SERVICE_CATEGORY:
      return {...state, category: action.payload.data};
    case serviceConstants.LOAD_SERVICE:
      return {...state, service: action.payload.data};
    case serviceConstants.REFRESH_SERVICE_CATEGORIES_DATA:
      return {...state, serviceCategoriesDataSyncState: SyncState.OBSOLETE};
    case serviceConstants.LOAD_SELECTED_SERVICES:
      return {...state, selectedServices: [...action.payload.data]};
    case serviceConstants.UPDATE_SELECTED_SERVICES:
      return {
        ...state,
        selectedServices:
          action.payload.operationType === operationType.ADD
            ? [...state.selectedServices, action.payload.service]
            : removeMatchItem(state.selectedServices, action.payload.service),
      };
    case serviceConstants.CLEAR_SELECTED_SERVICES:
      return {...state, selectedServices: [...action.payload]};
    case serviceConstants.LOAD_LPG_SERVICE_CATEGORY_ID:
      return {...state, lpgServiceCategoryId: action.payload.data};
    case serviceConstants.LOAD_WORKSHOP_SERVICE_CATEGORIES:
      return {...state, workshopServiceCategories: action.payload.data};
    default:
      return state;
  }
}
