/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {workshopLoyaltyProgramConstants} from "../constants/workshopLoyaltyProgram.constants";

export function workshopLoyaltyProgramReducer(
  state = {
    isFetching: false,
    registartionFetching: false,
    notifications: [],
    barNotificationsToShow: [],
    isFetchingNotificationsToShow: false,
    modalNotificationsToShow: [],
    isFetchingModalNotificationsToShow: false,
    workshopListFetching: false,
    workshopList: [],
    registrationData: null,
    workshopsParticipationBlockHistory: [],
    workshopsParticipationBlockHistoryFetching: false, 
    productNamesFetching: null,
    producTypesFetching: null,
    productNames: [],
    productTypes: [],
    scoredProducts: [],
    scoredProductsFetching: false,
    prizeProducts: [],
    prizeProductsFetching: false,
    pointsData: null,
    loyaltyConfigFetching: false,
    loyaltyConfig: null,
    history: [],
    historyFetching: null,
    isUpdatingWorkshop: false,
    activeWorkshopLoyaltyProgramTile: null,
    isMarkingModalNotificationAsRead: false,
    isBuyingProducts: false,
    workshopsWithHistory: [],
    workshopsWithHistoryFetching: false,
    loyaltyProgramPointsHistory: [],
    loyaltyProgramPointsHistoryFetching: false,
    selectedWorkshop: null,
    selectedPointsType: 'ALL',
    selectedDateType: 'ALL',
    selectedBlockType: 'ALL'
  },
  action
) {
  switch (action.type) {
    case workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_START:
      return {...state, isFetching: true};
    case workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_REQUEST_END:
      return {...state, isFetching: false};
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_NOTIFICATIONS:
      return {...state, notifications: action.payload};
    case workshopLoyaltyProgramConstants.UPDATE_LOYALTY_PROGRAM_NOTIFICATION:
      return {...state, isFetching: false};
    case workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW_START:
      return {...state, isFetchingNotificationsToShow: true};
    case workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW_END:
      return {...state, isFetchingNotificationsToShow: false};
    case workshopLoyaltyProgramConstants.GET_BAR_NOTIFICATIONS_TO_SHOW:
      return {...state, barNotificationsToShow: action.payload};
    case workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW_START:
      return {...state, isFetchingModalNotificationsToShow: true};
    case workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW:
      return {...state, modalNotificationsToShow: action.payload};
    case workshopLoyaltyProgramConstants.GET_MODAL_NOTIFICATIONS_TO_SHOW_END:
      return {...state, isFetchingModalNotificationsToShow: false};
    case workshopLoyaltyProgramConstants.ADD_TO_MODAL_NOTIFICATIONS_TO_SHOW:
      const newModalNotifications = [...state.modalNotificationsToShow, action.payload];
      return {...state, modalNotificationsToShow: newModalNotifications};
    case workshopLoyaltyProgramConstants.DELETE_FROM_MODAL_NOTIFICATIONS_TO_SHOW:
      const updatedModalNotifications = state.modalNotificationsToShow.filter(item => item.identifier !== action.payload.identifier);
      return {...state, modalNotificationsToShow: updatedModalNotifications};
    case workshopLoyaltyProgramConstants.REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_START:
      return {...state, registartionFetching: true};
    case workshopLoyaltyProgramConstants.REGISTER_IN_WORKSHOP_LOYALTY_PROGRAM_REQUEST_END:
      return {...state, registartionFetching: false};
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA:
      return {...state, registartionFetching: false, isUpdatingWorkshop: false, registrationData: action.payload}
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START:
      return {...state, registartionFetching: true }
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END:
      return {...state, registartionFetching: false}
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LIST_REQUEST_START:
      return {...state, workshopListFetching: true}
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LIST:
      return {...state, workshopList: action.payload}
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LIST_REQUEST_END:
      return {...state, workshopListFetching: false}
    case workshopLoyaltyProgramConstants.GET_WORKSHOPS_WITH_HISTORY_REQUEST_START:
      return {...state, workshopsWithHistoryFetching: true};
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOPS_WITH_HISTORY:
      return {...state, workshopsWithHistory: action.payload};
    case workshopLoyaltyProgramConstants.GET_WORKSHOPS_WITH_HISTORY_REQUEST_END:
      return {...state, workshopsWithHistoryFetching: false};
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_START:
      return {...state, isUpdatingWorkshop: true};
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_REGISTRATION_DATA_REQUEST_END:
      return {...state, isUpdatingWorkshop: false};
    case workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_NAMES_REQUEST_START:
      return {...state, productNamesFetching: true}
    case workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_NAMES_REQUEST_END:
      return {...state, productNamesFetching: false}
    case workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_NAMES:
      return {...state, productNames: action.payload.data}
    case workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_TYPES_REQUEST_START:
      return {...state, productTypesFetching: true}
    case workshopLoyaltyProgramConstants.GET_SCORED_PRODUCT_TYPES_REQUEST_END:
      return {...state, productTypesFetching: false}
    case workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_TYPES:
      return {...state, productTypes: action.payload.data}
    case workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_START:
      return {...state, scoredProductsFetching: true}
    case workshopLoyaltyProgramConstants.SCORED_PRODUCTS_REQUEST_END:
      return {...state, scoredProductsFetching: false}
    case workshopLoyaltyProgramConstants.UPDATE_SCORED_PRODUCT_LIST:
      return {...state, scoredProducts: action.payload}
    case workshopLoyaltyProgramConstants.GET_WORKSHOP_LOYALTY_PROGRAM_POINTS_DATA:
      return {...state, pointsData: action.payload};
    case workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_START:
      return {...state, loyaltyConfigFetching: true};
    case workshopLoyaltyProgramConstants.LOYALTY_CONFIG_REQUEST_END:
      return {...state, loyaltyConfigFetching: false};
    case workshopLoyaltyProgramConstants.UPDATE_LOYALTY_CONFIG:
      return {...state, loyaltyConfig: action.payload.data};
    case workshopLoyaltyProgramConstants.WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_START:
      return {...state, historyFetching: true};
    case workshopLoyaltyProgramConstants.WORHSHOP_LOYALTY_PROGRAM_HISTORY_REQUEST_END:
      return {...state, historyFetching: false};
    case workshopLoyaltyProgramConstants.UPDATE_WORHSHOP_LOYALTY_PROGRAM_HISTORY:
      return {...state, history: action.payload};
    case workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_START:
      return {...state, prizeProductsFetching: true}
    case workshopLoyaltyProgramConstants.PRIZE_PRODUCT_REQUEST_END:
      return {...state, prizeProductsFetching: false}
    case workshopLoyaltyProgramConstants.UPDATE_PRIZE_PRODUCT_LIST:
      return {...state, prizeProducts: action.payload}
    case workshopLoyaltyProgramConstants.SET_ACTIVE_WORKSHOP_LOYALTY_PROGRAM_TILE:
      return {...state, activeWorkshopLoyaltyProgramTile: action.payload};
    case workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ_START:
      return {...state, isMarkingModalNotificationAsRead: true};
    case workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ:
      return {...state, isMarkingModalNotificationAsRead: false};
    case workshopLoyaltyProgramConstants.MARK_MODAL_NOTIFICATION_AS_READ_END:
      return {...state, isMarkingModalNotificationAsRead: false};
    case workshopLoyaltyProgramConstants.BUY_PRIZE_PRODUCTS_START:
      return {...state, isBuyingProducts: true};
    case workshopLoyaltyProgramConstants.BUY_PRIZE_PRODUCTS_END:
      return {...state, isBuyingProducts: false};
    case workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_START:
      return {...state, loyaltyProgramPointsHistoryFetching: true} 
    case workshopLoyaltyProgramConstants.WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY_REQUEST_END:
      return {...state, loyaltyProgramPointsHistoryFetching: false} 
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_LOYALTY_PROGRAM_POINTS_HISTORY:
      return {...state, loyaltyProgramPointsHistory: action.payload} 
    case workshopLoyaltyProgramConstants.WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_START:
      return {...state, workshopsParticipationBlockHistoryFetching: true} 
    case workshopLoyaltyProgramConstants.WORKSHOP_PARTICIPATION_BLOCK_HISTORY_REQUEST_END:
      return {...state, workshopsParticipationBlockHistoryFetching: false} 
    case workshopLoyaltyProgramConstants.UPDATE_WORKSHOP_PARTICIPATION_BLOCK_HISTORY:
      return {...state, workshopsParticipationBlockHistory: action.payload} 
    case workshopLoyaltyProgramConstants.SET_SELECTED_WORKSHOP:
      return {...state, selectedWorkshop: action.payload} 
    case workshopLoyaltyProgramConstants.SET_SELECTED_POINTS_TYPE:
      return {...state, selectedPointsType: action.payload} 
    case workshopLoyaltyProgramConstants.SET_SELECTED_DATE_TYPE:
      return {...state, selectedDateType: action.payload};
    case workshopLoyaltyProgramConstants.SET_SELECTED_BLOCK_TYPE:
      return {...state, selectedBlockType: action.payload};
    default:
      return state;
  }
}
