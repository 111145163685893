/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {workshopActions} from 'shared/actions/workshop.actions';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import WorkingHoursTable from 'shared/components/working-hours/working-hours-table/WorkingHoursTable';
import {workshopConstants} from 'shared/constants/workshop.constants';
import {becameObsolete} from 'shared/utils/sync';
import {getOrEmptyObj} from 'shared/utils/utils';

class WorkingHoursSettings extends React.Component {
  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
    this.isOpenChanged = this.isOpenChanged.bind(this);
    this.startTimeChanged = this.startTimeChanged.bind(this);
    this.endTimeChanged = this.endTimeChanged.bind(this);
    this.onSave = this.onSave.bind(this);
    this.replaceWorkingDays = this.replaceWorkingDays.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      becameObsolete(
        prevProps.workshopWorkingDaysSyncState,
        this.props.workshopWorkingDaysSyncState
      )
    )
      this.load();
  }

  load() {
    this.props.findWorkingDaysByWorkshopId(this.props.loggedWorkshopId);
  }

  isOpenChanged(dayOfWeek, isOpen) {
    let modifiedWorkshopWorkingDays = [...this.props.workshopWorkingDays];

    if (isOpen) {
      modifiedWorkshopWorkingDays.push({
        dayOfWeek: dayOfWeek,
        startTime: '08:00:00',
        endTime: '16:00:00',
      });
    } else {
      modifiedWorkshopWorkingDays = modifiedWorkshopWorkingDays.filter(
        workingDay => workingDay.dayOfWeek !== dayOfWeek
      );
    }

    this.replaceWorkingDays(modifiedWorkshopWorkingDays);
  }

  startTimeChanged(dayOfWeek, startTime) {
    const modifiedWorkshopWorkingDays = [...this.props.workshopWorkingDays].map(
      workingDay =>
        workingDay.dayOfWeek === dayOfWeek
          ? {...workingDay, startTime: startTime}
          : workingDay
    );

    this.replaceWorkingDays(modifiedWorkshopWorkingDays);
  }

  endTimeChanged(dayOfWeek, endTime) {
    const modifiedWorkshopWorkingDays = [...this.props.workshopWorkingDays].map(
      workingDay =>
        workingDay.dayOfWeek === dayOfWeek
          ? {...workingDay, endTime: endTime}
          : workingDay
    );

    this.replaceWorkingDays(modifiedWorkshopWorkingDays);
  }

  replaceWorkingDays(workingDays) {
    this.props.replaceWorkingDays({
      workingDays: workingDays,
      freeDays: this.props.freeDays,
    });
  }

  onSave() {
    this.props.updateWorkingDaysByWorkshopId(
      this.props.loggedWorkshopId,
      this.props.workshopWorkingDays
    );
  }

  render() {
    const {isFetching, workshopWorkingDays} = this.props;

    return (
      <ContentWrapper title='settings.workingHoursSettingsBtn'>
        <Loadable active={isFetching} spinner>
          <div className='col py-3'>
            <WorkingHoursTable
              workingDays={workshopWorkingDays}
              isOpenChanged={this.isOpenChanged}
              startTimeChanged={this.startTimeChanged}
              endTimeChanged={this.endTimeChanged}
              onSaveClick={this.onSave}
            />
          </div>
        </Loadable>
      </ContentWrapper>
    );
  }
}

function mapStateToProps({workshop}) {
  const {data, isFetching, workshopWorkingDaysSyncState} = workshop;

  const loggedWorkshopId = data ? data.workshopId : null;
  const workshopWorkingDays = getOrEmptyObj(
    workshop.workshopWorkingDays
  ).workingDays;
  const freeDays = getOrEmptyObj(workshop.workshopWorkingDays).freeDays;

  return {
    loggedWorkshopId,
    isFetching,
    workshopWorkingDays,
    workshopWorkingDaysSyncState,
    freeDays,
  };
}

const mapDispatchToProps = {
  findWorkingDaysByWorkshopId: workshopActions.findWorkingDaysByWorkshopId,
  updateWorkingDaysByWorkshopId: workshopActions.updateWorkingDaysByWorkshopId,
  replaceWorkingDays: replacement => ({
    type: workshopConstants.LOAD_WORKSHOP_WORKING_DAYS,
    payload: {data: replacement},
  }),
};

const connectedWorkingHoursSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkingHoursSettings);
export {connectedWorkingHoursSettings as WorkingHoursSettings};
