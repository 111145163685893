/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import {Collapse} from 'reactstrap';
import {connect} from 'react-redux';
import './user-notifications.css';
import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import {userService} from '../../services/user.service';
import {userNotificationActions} from '../../actions/user-notification.action';
import {isEmptyArray} from '../../utils/utils';
import {UserNotification} from './UserNotification';
import {Translate} from 'react-redux-i18n';
import {userNotificationsRefreshIntervalMillis} from '../../../environment';

class UserNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
    this.toggle = this.toggle.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    this.props.countUnread(userService.getUserId(), () => {
      if (this.state.isOpen) this.findRecentAndReadAll();
    });
    this.countUnreadInterval = setInterval(
      () => this.countUnread(),
      userNotificationsRefreshIntervalMillis
    );
  }

  componentWillUnmount() {
    clearInterval(this.countUnreadInterval);
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  countUnread() {
    const {countUnread} = this.props;
    countUnread(userService.getUserId(), () => {
      if (this.state.isOpen) this.findRecentAndReadAll();
    });
  }

  toggle() {
    const currentIsOpenState = this.state.isOpen;
    if (currentIsOpenState) {
      document.removeEventListener('click', this.handleDocumentClick, true);
      this.setState({isOpen: false});
    } else {
      this.findRecentAndReadAll();
      document.addEventListener('click', this.handleDocumentClick, true);
      this.setState({isOpen: true});
    }
  }

  deleteById(notificationId) {
    this.props.deleteById(userService.getUserId(), notificationId, () =>
      this.findRecentAndReadAll()
    );
  }

  findRecentAndReadAll() {
    this.props.findRecent(userService.getUserId(), () =>
      this.props.readAll(userService.getUserId())
    );
  }

  handleDocumentClick(e) {
    const container = this.wrapper;
    if (e.target !== container && !container.contains(e.target)) this.toggle();
  }

  render() {
    const {
      unreadNotificationsCount,
      recentNotifications,
      isFetching,
      deleteAll,
    } = this.props;

    return (
      <div className='user-notifications align-horizontal' ref={ref => (this.wrapper = ref)}>
        <div id='user-notifications-toggle' onClick={this.toggle}>
          <i
            className={`fa fa-bell fa-3x clickable ${
              this.state.isOpen && !isFetching ? 'active' : ''
            }`}
          />
          <NotificationBadge
            count={unreadNotificationsCount}
            effect={Effect.SCALE}
            containerStyle={badgeContainerStyle}
            style={badgeStyle}
          />
        </div>
        <Collapse
          isOpen={this.state.isOpen && !isFetching}
          className='user-notifications-collapse'>
          <ul className='user-notifications-ul shadow'>
            {!isEmptyArray(recentNotifications) &&
              recentNotifications.map((notification, index) => (
                <li key={index}>
                  <UserNotification
                    userNotification={notification}
                    deleteNotification={() => this.deleteById(notification.id)}
                  />
                </li>
              ))}
            {isEmptyArray(recentNotifications) && (
              <li className='text-center'>
                <Translate value='userNotifications.noRecent' />
              </li>
            )}
          </ul>
        </Collapse>
      </div>
    );
  }
}

const badgeContainerStyle = {
  position: 'absolute',
  top: 0,
  left: '40px',
  height: 0,
  width: 0,
};

const badgeStyle = {
  backgroundColor: '#2A3FAB',
  borderRadius: 50,
  fontSize: 18,
};

function mapStateToProps(state) {
  const {userNotification} = state;
  const {unreadNotificationsCount, recentNotifications, isFetching} =
    userNotification;

  return {unreadNotificationsCount, recentNotifications, isFetching};
}

const mapDispatchToProps = {
  countUnread: userNotificationActions.countUnread,
  findRecent: userNotificationActions.findRecent,
  readAll: userNotificationActions.readAll,
  deleteById: userNotificationActions.deleteById,
};

const connectedUserNotifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotifications);
export {connectedUserNotifications as UserNotifications};
