/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const messageConstants = {
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
  CUSTOM_MESSAGE: 'CUSTOM_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
};
