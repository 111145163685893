/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import PropTypes from 'prop-types';
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {orderActions} from 'shared/actions/order.actions';
import {orderConstants} from 'shared/constants/order.constants';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {getOrEmptyArray, isEmptyArray} from 'shared/utils/utils';
import {ValuationDetails} from '../../../workshop/valuations/valuation-details/ValuationDetails';
import {withValuationTableCore} from '../utils/ValuationsTableCoreHoc';

class ValuationsByInquiry extends React.Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
  }

  componentDidMount() {
    const {origin, inquiryNumber} = this.props;
    this.props.findAllValuationsByInquiryNumber(origin, inquiryNumber);
  }

  componentWillUnmount() {
    this.props.clearValuations();
  }

  sortByChosen(valuations) {
    return [
      ...getOrEmptyArray(valuations).sort((v1, v2) =>
        v2.chosen.toString().localeCompare(v1.chosen.toString())
      ),
    ];
  }

  getRowClassName(data) {
    if (data) return {'green-row': data.chosen};
  }

  onRowClick(event) {
    this.props.findValuationByValuationNumber(event.data.valuationNumber);
    const details = (
      <ValuationDetails
        shouldShowWorkshopStatusAlert={false}
        shouldShowCreateAnotherValuationBtn={false}
        shouldShowWorkshopInfoSection={true}
      />
    );
    const title =
      I18n.t('valuationDetails.detailsHeader') +
      ' ' +
      event.data.valuationNumber.valuationNumberString;
    this.props.showUpdateDataSideBar(details, title);
  }

  renderValuations() {
    return (
      <DataTable
        value={this.sortByChosen(this.props.valuations)}
        className='expanded-datatable'
        style={{fontFamily: 'Lato'}}
        responsive={true}
        onRowClick={this.onRowClick}
        emptyMessage={I18n.t('inquiriesHistory.emptyPlaceholder')}
        rowClassName={this.getRowClassName}>
        <Column
          field='valuationNumber.valuationNumberString'
          header={I18n.t('inquiriesHistory.valuationNumberHeader')}
        />

        <Column
          field='workshopInfoString'
          header={I18n.t('inquiriesHistory.workshopInfoHeader')}
          body={this.props.renderWorkshopInfoCol}
          style={{width: '30%'}}
        />

        <Column
          field='workshopAvgRating'
          header={I18n.t('inquiriesHistory.workshopAvgRatingHeader')}
          body={this.props.renderWorkshopAvgRatingCol}
        />

        <Column
          field='sendDate'
          header={I18n.t('inquiriesHistory.sendDateHeader')}
          body={this.props.renderDateCol}
        />

        <Column
          field='totalCostString'
          header={I18n.t('inquiriesHistory.totalCostHeader')}
          body={this.props.renderTotalCostCol}
        />
      </DataTable>
    );
  }

  render() {
    const {valuations} = this.props;

    return (
      <Fragment>
        {!isEmptyArray(valuations) ? this.renderValuations() : <div />}
      </Fragment>
    );
  }
}

ValuationsByInquiry.propTypes = {
  origin: PropTypes.string.isRequired,
  inquiryNumber: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  const {i18n, order} = state;
  const {valuations} = order;
  return {i18n, valuations};
};

const mapDispatchToProps = {
  findAllValuationsByInquiryNumber:
    orderActions.findAllValuationsByInquiryNumberWithWorkshopInfos,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
  findValuationByValuationNumber: orderActions.findValuationByValuationNumber,
  clearValuations: () => ({type: orderConstants.CLEAR_VALUATIONS}),
};

//For testing
export const ConnectedValuationsByInquiryWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValuationsByInquiry);

export default withDataTableCore(
  withValuationTableCore(ConnectedValuationsByInquiryWithRedux)
);
