/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, useState} from 'react';
import {InputNumber} from 'primereact/inputnumber';
import './CartQuantityInput.css';
import { useSelector } from 'react-redux';

const CartQuantityInput = props => {
  const [value, setValue] = useState(0);
  const isBuyingProducts = useSelector(state => state.workshopLoyaltyProgram.isBuyingProducts);
  const prizeProductsList = useSelector(
    state => state.workshopLoyaltyProgram.prizeProducts.content
  );

  useEffect(() => {
    if (!value) setValue(0);
    if (value >= 10000) setValue(9999);
  }, [value]);
  useEffect(() => {
    if (!isBuyingProducts) setValue(0);
  }, [isBuyingProducts]);

  const onChange = newValue => {
    setValue(newValue);
    if (!newValue) newValue = 0;
    if (newValue >= 10000) newValue = 9999;
    props.onChange(newValue);
  };

  useEffect(() => {
    if(props.selectedProducts.length > 0){
      props.selectedProducts.map(product => {
        const selectedProduct = props.selectedProducts.find(item => item.UUID === props.inputId)
        if(selectedProduct){
          setValue(selectedProduct.count)
        } else {
          setValue(0)
        }
      })
    }
  },[prizeProductsList])

  return (
    <div className={'cartQuantityInputWrapper'}>
      <div
        className={`button minus ${value === 0 ? 'disabled' : ''}`}
        onClick={() => (value > 0 ? onChange(value - 1) : {})}
      >
        -
      </div>
      <InputNumber
        value={value}
        onValueChange={e => onChange(e.value)}
        mode='decimal'
        inputId={props.inputId}
      />
      <div className={'button plus'} onClick={() => onChange(value + 1)}>
        +
      </div>
    </div>
  );
};

export default CartQuantityInput;
