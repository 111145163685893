/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {useEffect, forwardRef, useImperativeHandle, useState} from 'react';
import {RenderField} from "../../../../../shared/components/form/text-field/hook-form-version/RenderField";
import {I18n, Translate} from "react-redux-i18n";
import {Controller, useForm} from "react-hook-form";
import {ReqexpValidationEnum} from "../../../../../shared/utils/utils";
import {updateDataSideBarConstants} from "../../../../../shared/constants/update-data-side-bar.constants";
import {useDispatch} from "react-redux";
import styles from './EditNotificationDataForm.module.css';
import {Checkbox} from "primereact/checkbox";
import {errorColor} from "../../../../../shared/utils/style.utils";
import {RadioButton} from "primereact/radiobutton";
import {workshopLoyaltyProgramActions} from "../../../../../shared/actions/workshopLoyaltyProgram.actions";
import {
  workshopLoyaltyProgramNotificationsConstants
} from "../../../../../shared/constants/workshopLoyaltyProgramNotifications.constants";

const EditNotificationDataForm = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const {notification} = props;
  const {
    setValue,
    handleSubmit,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange", criteriaMode: "all" });
  const [shouldDisableLifetime, setShouldDisableLifetime] = useState(false);
  const [shouldShowBarTextField, setShouldShowBarTextField] = useState(false);
  const [shouldShowEmailTextField, setShouldShowEmailTextField] = useState(false);
  const [shouldShowPointsDeadlineDaysAmount, setShouldShowPointsDeadlineDaysAmount] = useState(false);
  const [shouldShowBarCharacteristicFields, setShouldShowBarCharacteristicFields] = useState(false);
  const [shouldShowModalCharacteristicFields, setShouldShowModalCharacteristicFields] = useState(false);

  const titleField = "title";
  const typesField = "types";
  const systemNotificationTextField = "systemNotificationText";
  const emailNotificationTextField = "emailNotificationText";
  const modalNotificationTextField = "modalNotificationText";
  const modalNotificationHeaderField = "modalNotificationHeader";
  const shouldUserCloseField = "shouldUserClose";
  const visibilityDurationSecondsField = "visibilityDurationSeconds";
  const severityField = "severity";
  const pointDeadlineDaysAmountField = "pointsDeadlineDaysAmount";

  useEffect(() => {
    if(notification) {
      setValue(titleField, notification.title);
      setValue(typesField, notification.types);
      setValue(systemNotificationTextField, notification.systemNotificationText);
      setValue(emailNotificationTextField, notification.emailNotificationText);
      setValue(modalNotificationTextField, notification.modalNotificationText);
      setValue(modalNotificationHeaderField, notification.modalNotificationHeader);
      setValue(shouldUserCloseField, notification.shouldUserClose);
      setValue(visibilityDurationSecondsField, notification.visibilityDurationSeconds);
      setValue(severityField, notification.severity);
      if(notification.types.includes('email')) {
        setShouldShowEmailTextField(true);
      }
      if(notification.types.includes('user')) { // the same text for user and bar notification type
        setShouldShowBarTextField(true);
      }
      if(notification.types.includes('bar')) {
        setShouldShowBarCharacteristicFields(true);
      }
      if(notification.types.includes('modal')) {
        setShouldShowModalCharacteristicFields(true);
      }
      if(notification.shouldUserClose) setShouldDisableLifetime(true);
      if(notification.identifier === workshopLoyaltyProgramNotificationsConstants.POINTS_DEADLINE_CLOSE_WARNING) {
        setShouldShowPointsDeadlineDaysAmount(true);
        setValue(pointDeadlineDaysAmountField, notification.pointsDeadlineDaysAmount);
      }
    }
  }, [notification]);

  useEffect(() => {
    if(shouldDisableLifetime) {
      const currentDuration = getValues(visibilityDurationSecondsField);
      setValue(visibilityDurationSecondsField, currentDuration, {shouldValidate: true});
    }
  }, [shouldDisableLifetime]);

  useImperativeHandle(ref, () => ({
    onSave: handleSubmit((data) => onSubmit(data)),
  }));

  const onSubmit = (data) => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    let objectToSave = {
      ...data,
      identifier: notification.identifier,
      systemNotificationText: shouldShowBarTextField ? data.systemNotificationText : notification.systemNotificationText,
      emailNotificationText: shouldShowEmailTextField ? data.emailNotificationText : notification.emailNotificationText,
      modalNotificationText: shouldShowModalCharacteristicFields ? data.modalNotificationText : notification.modalNotificationText,
      modalNotificationHeader: shouldShowModalCharacteristicFields ? data.modalNotificationHeader : notification.modalNotificationHeader,
      ...(shouldShowPointsDeadlineDaysAmount ? {pointsDeadlineDaysAmount: data.pointsDeadlineDaysAmount} : {}),
    }
    dispatch(workshopLoyaltyProgramActions.updateNotification(notification.identifier, objectToSave));
  }

  const notificationOptions = [
    {
      label: I18n.t("workshopLoyaltyProgram.notifications.types.email"),
      value: "email",
      inputId: "emailId",
    },
    {
      label: I18n.t("workshopLoyaltyProgram.notifications.types.modal"),
      value: "modal",
      inputId: "modalId",
    },
    {
      label: I18n.t("workshopLoyaltyProgram.notifications.types.user"),
      value: "user",
      inputId: "userId",
    },
    {
      label: I18n.t("workshopLoyaltyProgram.notifications.types.bar"),
      value: "bar",
      inputId: "barId",
    }
  ];

  const severities = [
    { value: "success", name: "success", color: "#1ea97c" },
    { value: "info", name: "info", color: "#696cff" },
    { value: "warn", name: "warn", color: "#cc8925" },
    { value: "error", name: "error", color: "#ff5757" },
  ];

  return (
    <>
      <Controller
        control={control}
        name={titleField}
        render={({field: { onChange, value }}) => (
          <RenderField
            label={I18n.t("workshopLoyaltyProgram.notifications.name")}
            error={errors[titleField]}
            value={value}
            type="text"
            required
            disabled
            onChange={onChange}
          />
        )}
        rules={{ required: true }}
      />
      <Controller
        control={control}
        name={typesField}
        defaultValue={[]}
        render={({ field: { onChange, value }}) => (
          <>
            <label
              style={{ color: errors[typesField] && errorColor }}
              className="field-label"
            >
              {I18n.t("workshopLoyaltyProgram.notifications.type")}
              <span style={{ color: errorColor }}>*</span>
            </label>
            <div className={styles.RowContainer}>
              {notificationOptions.map((option) => (
                <div className={styles.Checkbox} key={option.inputId}>
                  <Checkbox
                    inputId={option.inputId}
                    value={option.value}
                    checked={value.some((existingValue) => existingValue === option.value)}
                    onChange={(event) => {
                      const checked = value.includes(event.target.value);
                      switch(event.target.value) {
                        case "email":
                          setShouldShowEmailTextField(!checked);
                          break;
                        case "modal":
                          setShouldShowModalCharacteristicFields(!checked);
                          break;
                        case "user":
                          setShouldShowBarTextField(!checked);
                          if(checked) setShouldShowBarCharacteristicFields(false);
                          break;
                        case "bar":
                          setShouldShowBarCharacteristicFields(!checked);
                          break;
                        default:
                          break;
                      }
                      if (!checked) {
                        onChange([ ...value, event.target.value ]);
                      } else {
                        let newValues = value.filter((value) => value !== event.target.value);
                        if(event.target.value === "user") {
                          newValues = value.filter((value) => value !== event.target.value && value !== "bar");
                        }
                        onChange(newValues);
                      }
                    }}
                    disabled={option.value === "bar" && !value.includes("user")}
                  />
                  <label htmlFor={option.inputId} className={`p-checkbox-label ${styles.CheckboxLabel}`}>{option.label}</label>
                </div>
              ))}
            </div>
            <div id="error-msg" className="col-12" style={{ minHeight: 24, paddingLeft: 0 }}>
              {errors[typesField] && errors[typesField].type === "validate" && (
                <span className="error-info">
                  <Translate value={"workshopLoyaltyProgram.notifications.typesRequired"} />
                </span>
              )}
            </div>
          </>
        )}
        rules={{
          validate: value => {
            return value && (value.includes("email") || value.includes("user") || value.includes("modal"));
          }
        }}
      />
      <Controller
        control={control}
        name={emailNotificationTextField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowEmailTextField && <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.textEmail")}
              error={errors[emailNotificationTextField]}
              value={value}
              type="text"
              required
              onChange={onChange}
            />}
          </>
        )}
        rules={{ required: shouldShowEmailTextField }}
      />
      <Controller
        control={control}
        name={modalNotificationTextField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowModalCharacteristicFields && <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.textModal")}
              error={errors[modalNotificationTextField]}
              value={value}
              type="text"
              required
              onChange={onChange}
            />}
          </>
        )}
        rules={{ required: shouldShowModalCharacteristicFields }}
      />
      <Controller
        control={control}
        name={modalNotificationHeaderField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowModalCharacteristicFields && <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.titleModal")}
              error={errors[modalNotificationHeaderField]}
              value={value}
              type="text"
              required
              onChange={onChange}
            />}
          </>
        )}
        rules={{ required: shouldShowModalCharacteristicFields }}
      />
      <Controller
        control={control}
        name={systemNotificationTextField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowBarTextField && <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.textUser")}
              error={errors[systemNotificationTextField]}
              value={value}
              type="text"
              required
              onChange={onChange}
            />}
          </>
        )}
        rules={{ required: shouldShowBarTextField }}
      />
      {shouldShowPointsDeadlineDaysAmount && (
        <Controller
          control={control}
          name={pointDeadlineDaysAmountField}
          render={({field: { onChange, value }}) => (
            <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.pointsDeadlineDaysAmount")}
              error={errors[pointDeadlineDaysAmountField]}
              value={value}
              type="text"
              required={shouldShowPointsDeadlineDaysAmount}
              onChange={onChange}
            />
          )}
          rules={{
            required: shouldShowPointsDeadlineDaysAmount,
            pattern: ReqexpValidationEnum.ONLY_NUMBERS,
          }}
        />
      )}
      <Controller
        control={control}
        name={visibilityDurationSecondsField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowBarCharacteristicFields && <RenderField
              label={I18n.t("workshopLoyaltyProgram.notifications.lifetime")}
              error={errors[visibilityDurationSecondsField]}
              value={value}
              type="text"
              required={!shouldDisableLifetime}
              disabled={shouldDisableLifetime}
              onChange={onChange}
            />}
          </>
        )}
        rules={{
          required: !shouldDisableLifetime,
          pattern: ReqexpValidationEnum.ONLY_NUMBERS,
        }}
      />
      <Controller
        control={control}
        name={shouldUserCloseField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowBarCharacteristicFields &&
              <div className={styles.Checkbox}>
                <Checkbox
                  inputId={"shouldUserCloseId"}
                  checked={value}
                  onChange={(event) => {
                      onChange(event.checked);
                      setShouldDisableLifetime(event.checked);
                  }}
                />
                <label htmlFor={"shouldUserCloseId"} className={`p-checkbox-label ${styles.CheckboxLabel}`}>
                  {I18n.t("workshopLoyaltyProgram.notifications.shouldUserClose")}
                </label>
              </div>}
          </>
        )}
      />
      <Controller
        control={control}
        name={severityField}
        render={({field: { onChange, value }}) => (
          <>
            {shouldShowBarCharacteristicFields &&
              <>
                <label
                  style={{ color: errors[severityField] && errorColor }}
                  className={`field-label ${styles.SeverityLabel}`}
                >
                  {I18n.t("workshopLoyaltyProgram.notifications.severity")}
                  <span style={{ color: errorColor }}>*</span>
                </label>
                <div className={styles.RowContainer}>
                  {severities.map((option) => (
                    <div className={styles.RadioButton} key={option.value}>
                      <RadioButton value={option.value} name={option.name} onChange={(e) => onChange(e.value)} checked={value === option.value}/>
                      <div className={styles.ColorBox} style={{backgroundColor: option.color}}/>
                    </div>
                  ))}
                </div>
              </>}
          </>
        )}
      />
    </>
  )
});

export default EditNotificationDataForm;
