/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const promotionConstants = {
  PROMOTION_REQUEST_START: 'PROMOTION_REQUEST_START',
  PROMOTION_REQUEST_END: 'PROMOTION_REQUEST_END',

  LOAD_PROMOTIONS: 'LOAD_PROMOTIONS',
  LOAD_PROMOTION: 'LOAD_PROMOTION',
  CLEAR_PROMOTION_DATA: 'CLEAR_PROMOTION_DATA',

  REFRESH_PROMOTION_DATA: 'REFRESH_PROMOTION_DATA',

  LOAD_CREATION_CONSENT: 'LOAD_CREATION_CONSENT',

  STATUS_NEW: 'NEW',
  STATUS_ACCEPTED: 'ACCEPTED',
  STATUS_NOT_ACCEPTED: 'NOT_ACCEPTED',
  STATUS_ACTIVE: 'ACTIVE',
  STATUS_EXPIRED: 'EXPIRED',
  STATUS_DELETED: 'DELETED',
};
