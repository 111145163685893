/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm, formValueSelector} from 'redux-form';
import {WorkerForm} from './WorkerForm';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {workshopWorkerActions} from '../../../actions/workshop-worker.actions';
import {validate} from './worker-form-validators';

const formName = 'workerCreateForm';

export const WorkerFormReduxFormWrapper = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
})(WorkerForm);

const selector = formValueSelector(formName);

class WorkerCreateForm extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  create(data) {
    const {createWorker, onCreateSuccess, loggedWorkshopId} = this.props;
    createWorker({workshopId: loggedWorkshopId, ...data}, onCreateSuccess);
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <WorkerFormReduxFormWrapper {...this.props} onSubmit={this.create} />
      </Loadable>
    );
  }
}

WorkerCreateForm.propTypes = {
  createWorker: PropTypes.func.isRequired,
  onCreateSuccess: PropTypes.func,
  loggedWorkshopId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const {i18n, workshopWorker} = state;

  const {isFetching} = workshopWorker;
  const avatarFieldValue = selector(state, 'avatar');

  return {
    i18n,
    isFetching,
    formName,
    avatarFieldValue,
  };
}

const mapDispatchToProps = {
  createWorker: workshopWorkerActions.createWorker,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerCreateForm);
