import React from "react";
import { Translate } from "react-redux-i18n";
import { Button } from "reactstrap";
import { updateDataSideBarConstants } from "shared/constants/update-data-side-bar.constants";
import styles from "./WorkshopLoyaltyProgramModal.module.css";
import { useDispatch, } from "react-redux";
import { I18n } from "react-redux-i18n";
import { FileUpload } from 'primereact/fileupload';
import './modal.css';
import { statuteActions } from 'shared/actions/statute.actions';
import { v4 as uuidv4 } from 'uuid';
import ScoredProductsModal from '../scored-products/scored-products-modal/ScoredProductsModal';
import PrizeProductsModal from '../prize-products/prize-products-modal/PrizeProductsModal';
import { workshopLoyaltyProgramActions } from "../../../../shared/actions/workshopLoyaltyProgram.actions";

const WorkshopLoyaltyProgramModal = (props) => {
  const {
    leaveStep,
    setConfirmedNavigation,
    setEditMode,
    cancelStep,
    addStatute,
    statuteActualization,
    saveLoyaltyProgram,
    editPointAccuralPeriodStep,
    setEditorState,
    createScoredProduct,
    updateScoredProduct,
    deleteScoredProduct,
    createPrizeProduct,
    updatePrizeProduct,
    deletePrizeProduct,
    savePointsAccuralPeriodForm,
    product,
    tooShortAccuralPeriodStep,
    isNewProduct,
    toggleWorkshopStatus,
    workshop,
    params,
    search,
    pageSize,
    setPage,
  } = props;

  const dispatch = useDispatch();

  const onSaveLoyaltyProgram = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    saveLoyaltyProgram();
    setEditMode(false);
  };

  const onSavePointsAccuralPeriod = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    savePointsAccuralPeriodForm();
    setEditMode(false);
  }

  const onLeaveWithDataSave = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    saveLoyaltyProgram();
    setConfirmedNavigation(true);
  };

  const onLeaveWithoutDataSave = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    setConfirmedNavigation(true);
  };

  const upload = (e) => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    const formData = new FormData();
    formData.append('file', e.files[0], `${uuidv4()}.pdf`)
    dispatch(statuteActions.uploadNewLoyaltyProgramStatute(formData))
  }

  const onCancelEditMode = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    setEditMode(false);
    setEditorState();
  }

  const pointsAccrualPeriodCancelEditMode = () => {
    dispatch({
      type: updateDataSideBarConstants.HIDE,
    });
    setEditMode(false);
    setEditorState();
  }

  return (
    <div className={styles.Container} style={{ padding: "25px" }}>
      {cancelStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("workshopLoyaltyProgram.leaveStepDescription")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("workshopLoyaltyProgram.leaveStepQuestion")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSaveLoyaltyProgram}>
              <Translate value={"workshopLoyaltyProgram.confirm"} />
            </Button>
            <Button className="main-btn" onClick={onCancelEditMode}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
        </>
      )}

      {leaveStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("workshopLoyaltyProgram.leaveStepDescription")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("workshopLoyaltyProgram.leaveStepQuestion")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onLeaveWithDataSave}>
              <Translate value={"workshopLoyaltyProgram.confirm"} />
            </Button>
            <Button className="main-btn" onClick={onLeaveWithoutDataSave}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
        </>
      )}
      {
        (addStatute || statuteActualization) && (
          <>
            <p className={styles.paragraph}>
              {addStatute ? I18n.t("workshopLoyaltyProgram.wannahAddStatute") : I18n.t("workshopLoyaltyProgram.wannahChangeStatute")}
            </p>
            <p className={styles.paragraphBold}>
              {I18n.t("workshopLoyaltyProgram.noWayChanging")}
            </p>
            <div className={styles.buttonPanel}>
              <div className='upload-btn'>
                <FileUpload
                  onSelect={(e) => upload(e)}
                  headerStyle={{ fontSize: 15 }}
                  key={uuidv4()}
                  mode='basic'
                  name='file'
                  accept='.pdf'
                  auto={true}
                  url='not-used'
                  chooseLabel={addStatute ? I18n.t("workshopLoyaltyProgram.addNew") : I18n.t("workshopLoyaltyProgram.actualization")}
                />
              </div>
              <Button className="main-btn" onClick={() => dispatch({
                type: updateDataSideBarConstants.HIDE,
              })}>
                <Translate value={"workshopLoyaltyProgram.cancel"} />
              </Button>
            </div>
          </>
        )
      }
      {editPointAccuralPeriodStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("workshopLoyaltyProgram.onEditMessageSetPointsAccrualPeriodLongDescription")}
          </p>
          <p className={styles.paragraphBold}>
            {I18n.t("workshopLoyaltyProgram.leaveStepQuestion")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={onSavePointsAccuralPeriod}>
              <Translate value={"workshopLoyaltyProgram.confirm"} />
            </Button>
            <Button className="main-btn" onClick={pointsAccrualPeriodCancelEditMode}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
        </>
      )}
      {tooShortAccuralPeriodStep && (
        <>
          <p className={styles.paragraph}>
            {I18n.t("workshopLoyaltyProgram.wrongPointsAccrualPeriod")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={() => dispatch({
              type: updateDataSideBarConstants.HIDE,
            })}>
              <Translate value={"workshopLoyaltyProgram.iunderstand"} />
            </Button>
          </div>
        </>
      )}
      {
        createScoredProduct && <>
          <p className={styles.paragraph}>
            {I18n.t("workshopLoyaltyProgram.scoredProducts.wannahCreateNewProductDescription")}
          </p>
          <div className={styles.buttonPanel}>
            <Button className="main-btn" onClick={() => {
              dispatch({
                type: updateDataSideBarConstants.SHOW,
                variableComponent: (
                  <ScoredProductsModal
                    pageSize={pageSize}
                    setPage={setPage} />
                ),
                title: I18n.t("workshopLoyaltyProgram.scoredProducts.addScoredProduct"),
              })
            }
            }>
              <Translate value={"workshopLoyaltyProgram.scoredProducts.addProduct"} />
            </Button>
            <Button className="main-btn" onClick={() => dispatch({
              type: updateDataSideBarConstants.HIDE,
            })}>
              <Translate value={"workshopLoyaltyProgram.cancel"} />
            </Button>
          </div>
        </>
      }
      {updateScoredProduct && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.scoredProducts.wannahEditProductDescription")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            dispatch(workshopLoyaltyProgramActions.updateScoredProduct(product, pageSize));
            setPage();
          }}>
            <Translate value={"workshopLoyaltyProgram.scoredProducts.editProduct"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>
      }
      {deleteScoredProduct && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.scoredProducts.wannahDeleteProductDescription")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            dispatch(workshopLoyaltyProgramActions.deleteScoredProduct(product.id, pageSize));
            dispatch({
              type: updateDataSideBarConstants.HIDE,
            })
          }
          }>
            <Translate value={"workshopLoyaltyProgram.delete"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>
      }
      {createPrizeProduct && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.prizeProducts.wannahCreateNewProductDescription")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            dispatch({
              type: updateDataSideBarConstants.SHOW,
              variableComponent: (
                isNewProduct ? <PrizeProductsModal
                  isNewProduct
                  pageSize={pageSize}
                  setPage={setPage} />
                  :
                  <PrizeProductsModal pageSize={pageSize} setPage={setPage} />
              ),
              title: I18n.t("workshopLoyaltyProgram.prizeProducts.addProductPrize"),
            })
          }
          }>
            <Translate value={"workshopLoyaltyProgram.prizeProducts.addPrizeProduct"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>
      }
      {updatePrizeProduct && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.prizeProducts.wannahEditProductDescription")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            setPage();
            dispatch(workshopLoyaltyProgramActions.updatePrizeProduct(product, pageSize))
          }}>
            <Translate value={"workshopLoyaltyProgram.prizeProducts.editProduct"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>
      }
      {deletePrizeProduct && <>
        <p className={styles.paragraph}>
          {I18n.t("workshopLoyaltyProgram.prizeProducts.wannahDeleteProductDescription")}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            setPage();
            dispatch(workshopLoyaltyProgramActions.deletePrizeProduct(product.id, pageSize));
            dispatch({
              type: updateDataSideBarConstants.HIDE,
            })
          }
          }>
            <Translate value={"workshopLoyaltyProgram.delete"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </>
      }
      {toggleWorkshopStatus && <>
        <p className={styles.paragraph}>
          {I18n.t(`workshopLoyaltyProgram.workshops.${workshop.loyaltyParticipationBlocked ? 'areYouSureUnblock' : 'areYouSureBlock'}`)}
          {'  '}
          {workshop.workshopName}
          {'?'}
        </p>
        <div className={styles.buttonPanel}>
          <Button className="main-btn" onClick={() => {
            dispatch(workshopLoyaltyProgramActions.toggleWorkshopStatus(workshop, params, search));
            dispatch({
              type: updateDataSideBarConstants.HIDE,
            })
          }
          }>
            <Translate value={"workshopLoyaltyProgram.notifications.yes"} />
          </Button>
          <Button className="main-btn" onClick={() => dispatch({
            type: updateDataSideBarConstants.HIDE,
          })}>
            <Translate value={"workshopLoyaltyProgram.notifications.no"} />
          </Button>
        </div>
      </>
      }
    </div>
  );
};

export default WorkshopLoyaltyProgramModal;
