/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export class UserNotification extends React.Component {
  render() {
    const {userNotification} = this.props;
    return (
      <div className='user-notification row'>
        <div className='col-1 user-notification-icon-container pl-2'>
          <i className='fa fa-2x fa-info-circle user-notification-icon' />
        </div>
        <div className='col-10 user-notification-content-container'>
          <div className='row user-notification-content-row'>
            <div className='col-12 user-notification-content'>
              {userNotification.content}
            </div>
            <div id='body-col' className='col-12 user-notification-content'>
              {userNotification.body}
            </div>
          </div>
          <div className='row'>
            <div id='date-col' className='col-12 user-notification-date'>
              {moment(userNotification.date).format('LLL')}
            </div>
          </div>
        </div>
        <div className='col-1 user-notification-icon-container'>
          <i
            className='fa fa-2x fa-trash-o user-notification-icon'
            style={{color: '#9a9a9a', cursor: 'pointer'}}
            onClick={this.props.deleteNotification}
          />
        </div>
      </div>
    );
  }
}

UserNotification.propTypes = {
  userNotification: PropTypes.object.isRequired,
  deleteNotification: PropTypes.func.isRequired,
};
