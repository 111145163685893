/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {userService} from '../../services/user.service';
import {history} from '../../../shared/utils/history';

export class ProtectedRouteBasedOnRoles extends React.Component {
  render() {
    const {component: Component, isAuthenticated, roles} = this.props;
    return isAuthenticated &&
      roles.some(role => {
        return userService.isRole(role);
      }) ? (
      <Component />
    ) : (
      <Redirect to='/access-denied' />
    );
  }
}

export const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          history.push('/home')
        );
      }}
    />
  );
};
