/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2022 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {createRef, useState} from 'react';
import Loadable from 'react-loading-overlay';
import {useDispatch, useSelector} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import ContentWrapper from 'shared/components/content-wrapper/ContentWrapper';
import './workshop-loyalty-program-profile-container.css';
import WorkshopLoyaltyProgramProfileEditForm
  from "./workshop-loyalty-program-profile-edit-form/WorkshopLoyaltyProgramProfileEditForm";
import {ConfirmModal} from "../../../../shared/components/modal/ConfirmModal";
import {apiUrl} from "../../../../environment";
import {history} from "../../../../shared/utils/history";
import {DATE_SHORT_FORMAT, dateUtils} from "../../../../shared/utils/date.utils";
import {workshopLoyaltyProgramActions} from "../../../../shared/actions/workshopLoyaltyProgram.actions";
import {stringUtils} from "../../../../shared/utils/string.utils";

const WorkshopLoyaltyProgramProfileContainer = () => {
  const formRef = createRef();
  const [isEdit, setIsEdit] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const dispatch = useDispatch();

  const workshopLoyaltyProgramData = useSelector(state => state.workshopLoyaltyProgram.registrationData);
  const workshopLoyaltyProgramPointsData = useSelector(state => state.workshopLoyaltyProgram.pointsData);
  const loyaltyProgramStatute = useSelector(state => state.statute.loyaltyProgramStatute);
  const isFetchingWorkshopLoyaltyProgramData = useSelector(state => state.workshopLoyaltyProgram.registartionFetching);
  const isUpdatingWorkshopLoyaltyProgramData = useSelector(state => state.workshopLoyaltyProgram.isUpdatingWorkshop);

  const onSave = () => {
    formRef.current.onSave();
  };

  const onLeaveWithoutDataSave = () => {
    setShowCancelModal(true);
  };

  const renderConfirmCancelModal = () => {
    return (
      <ConfirmModal
        title={'workshopLoyaltyProgram.register.onLeaveSaveMessage'}
        bodyText={'workshopLoyaltyProgram.register.leaveSaveStepDescription'}
        isOpen={showCancelModal}
        action={() => {
          setShowCancelModal(false);
          setIsEdit(false);
        }}
        handleCancel={() => setShowCancelModal(false)}
      />
    );
  }

  const renderEditForm = () => {
    return (
      <div>
        <WorkshopLoyaltyProgramProfileEditForm
          ref={formRef}
          workshopLoyaltyProgramData={workshopLoyaltyProgramData}
          onSuccess={() => setIsEdit(false)}/>
        <div className={'button-panel'}>
          <Button className="main-btn" onClick={() => onSave()}>
            <Translate value={"workshopLoyaltyProgram.confirm"} />
          </Button>
          <Button className="main-btn" onClick={() => onLeaveWithoutDataSave()}>
            <Translate value={"workshopLoyaltyProgram.cancel"} />
          </Button>
        </div>
      </div>
    );
  }

  const getDataFromKey = (key, data) => {
    if(data[key] || data[key] === 0) {
      if(key === 'lastPointsProcessDate') {
        return dateUtils.formatDate(data[key], DATE_SHORT_FORMAT);
      }
      else return data[key];
    }
    return `${I18n.t('userProfileData.noData')}`;
  }

  const renderDetailsRow = (key, data) => {
    const translationCore = key.translationCore ? key.translationCore : 'workshopLoyaltyProgram.register';
    return (
      <div className='row user-data-container' key={key.field}>
        <div className='col-md-6 col-12 text-md-right user-data-label'>
          <Translate value={`${translationCore}.${key.translation}`} />:
        </div>
        <div className='col-md-6 col-12 mb-2 pl-md-0'>
          {getDataFromKey(key.field, data)}
        </div>
      </div>
    );
  }

  const renderWorkshopLoyaltyProgramData = () => {
    const pointsDataToDisplay = [
      {
        field: 'totalPoints',
        translation: 'yourPoints',
        translationCore: 'workshopLoyaltyProgram'
      },
      {
        field: 'lastPointsProcessDate',
        translation: 'pointsByDate',
        translationCore: 'workshopLoyaltyProgram'
      },
    ];
    const detailsDataToDisplay = [
      {
        field:'workshopName',
        translation: 'workshopName'
      },
      {
        field: 'workshopWorker',
        translation: 'representant'
      },
      {
        field: 'phoneNumber',
        translation: 'phone'
      },
      {
        field: 'nip',
        translation: 'nip'
      },
    ];
    const addressDataToDisplay = [
      {
        field: 'city',
        translation: 'city'
      },
      {
        field: 'streetAddress',
        translation: 'address'
      },
      {
        field: 'postalCode',
        translation: 'postalCode'
      },
    ];

    const installationPoints =
      !!workshopLoyaltyProgramPointsData &&
        (workshopLoyaltyProgramPointsData.lastInstallationPoints || workshopLoyaltyProgramPointsData.lastInstallationPoints === 0)
          ? workshopLoyaltyProgramPointsData.lastInstallationPoints
          : 0;

    return (
      <div>
        {!!workshopLoyaltyProgramPointsData && (
          <div className='details-section'>
            <div className={'points-container'}>
              <div className='workshop-details-section-title'>
                <Translate value='workshopLoyaltyProgram.yourPoints' />
              </div>
              {pointsDataToDisplay.map(key =>
                renderDetailsRow(key, workshopLoyaltyProgramPointsData)
              )}
              <div className={'user-data-container text-md-center'}>
                <Translate value='workshopLoyaltyProgram.lastInstallationPoints.message1' />
                {` ${installationPoints} `}
                <Translate value={`workshopLoyaltyProgram.lastInstallationPoints.${stringUtils.getPointsString(installationPoints)}`} />
                {` `}
                <Translate value='workshopLoyaltyProgram.lastInstallationPoints.message2' />
              </div>
              {workshopLoyaltyProgramPointsData?.pointsSpentEndDate &&
                <div className={'user-data-container text-md-center'} style={{ marginTop: 8 }}>
                  <Translate value='workshopLoyaltyProgram.canUsePointsUntil' />
                  {` ${workshopLoyaltyProgramPointsData?.pointsSpentEndDate} `}
                </div>}
            </div>
            <div className='workshop-profile-buttons'>
              <Button id='download-statute-btn' className='main-btn' onClick={() => {
                history.push('/workshop/workshop-loyalty-program');
                dispatch(workshopLoyaltyProgramActions.setActiveWorkshopLoyaltyProgramTile('ProductsForPoints'));
              }}>
                <Translate value='workshopLoyaltyProgram.changePointsForProducts' />
              </Button>
            </div>
          </div>
        )}
        {!!workshopLoyaltyProgramData && (
          <div className='details-section-with-border'>
            <div className='workshop-details-section-title'>
              <Translate value='workshopProfile.companyData' />
            </div>
            {detailsDataToDisplay.map(key =>
              renderDetailsRow(key, workshopLoyaltyProgramData)
            )}
          </div>
        )}
        {!!workshopLoyaltyProgramData && (
          <div className='details-section-with-border'>
            <div className='workshop-details-section-title'>
              <Translate value='workshopProfile.addressData' />
            </div>
            {addressDataToDisplay.map(key =>
              renderDetailsRow(key, workshopLoyaltyProgramData.address)
            )}
          </div>
        )}
      </div>
    );
  }

  const renderDetails = () => {
    return (
      <div>
        {renderWorkshopLoyaltyProgramData()}
        <div className='workshop-profile-buttons'>
          {loyaltyProgramStatute && (
            <a href={apiUrl + '/workshop-loyalty/loyalty-statute/' + loyaltyProgramStatute.id + '/file'}>
              <Button id='download-statute-btn' className='main-btn'>
                <Translate value='workshopLoyaltyProgram.downloadStatute' />
              </Button>
            </a>
          )}
          <Button
            id='edit-workshop-btn'
            className='main-btn'
            onClick={() => setIsEdit(true)}>
            <Translate value='userProfile.editData' />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <ContentWrapper
      title='workshopLoyaltyProgram.workshopLoyaltyProgram'
      containerClass='user-profile-container container-with-flex'
      contentClass='content-with-flex'>
      <Loadable active={isFetchingWorkshopLoyaltyProgramData || isUpdatingWorkshopLoyaltyProgramData} spinner>
        <div className='profile-data'>
          {isEdit ? renderEditForm() : renderDetails()}
        </div>
      </Loadable>
      {renderConfirmCancelModal()}
    </ContentWrapper>
  )
}

export default WorkshopLoyaltyProgramProfileContainer;
