/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import PropTypes from 'prop-types';
import * as React from 'react';
import {Fragment} from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import {orderRatingActions} from 'shared/actions/order-rating.actions';
import RatingCreateForm from 'shared/components/rating/rating-form/RatingCreateForm';
import {history} from 'shared/utils/history';
import 'url-search-params-polyfill';
import './create-order-vendor-rating.css';

class CreateOrderVendorRating extends React.Component {
  constructor(props) {
    super(props);

    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.props.existsOwnedForOrder(this.getOrderId());
  }

  componentWillUnmount() {
    this.props.clearAll();
  }

  create(values) {
    const {createOrderRating} = this.props;
    createOrderRating(this.getOrderId(), values, this.goHome);
  }

  getOrderId() {
    return new URLSearchParams(this.props.location.search).get('orderId');
  }

  goHome() {
    history.push('/home');
  }

  renderExistingOwnedRatingForOrderInfo() {
    return (
      <Fragment>
        <Translate value='orderRating.existingOwnedRatingForOrder' />
        <div className='text-sm-right text-center mt-3'>
          <Button
            id='go-home-btn'
            color='secondary'
            className='m-1 fixed-width-btn'
            onClick={this.goHome}>
            <Translate value='orderRating.backBtn' />
          </Button>
        </div>
      </Fragment>
    );
  }

  existsOwnedForOrderCheckFailed() {
    const {isFetching, existingOwnedRatingForOrder} = this.props;
    return !isFetching && existingOwnedRatingForOrder == null;
  }

  render() {
    const {isFetching, existingOwnedRatingForOrder} = this.props;

    return (
      <div className='create-order-vendor-rating-container'>
        <Loadable active={isFetching} spinner>
          <Card>
            <CardHeader className='py-2'>
              <h4 style={{margin: 0}}>
                <b>
                  <Translate value='orderRating.createOrderVendorRatingTitle' />
                </b>
              </h4>
            </CardHeader>
            <CardBody className='text-center'>
              {existingOwnedRatingForOrder === false && (
                <RatingCreateForm
                  onSubmit={this.create}
                  handleCancel={this.goHome}
                />
              )}
              {existingOwnedRatingForOrder === true &&
                this.renderExistingOwnedRatingForOrderInfo()}
              {this.existsOwnedForOrderCheckFailed() && (
                <Translate value='orderRating.existingOwnedRatingForOrderCheckFailed' />
              )}
            </CardBody>
          </Card>
        </Loadable>
      </div>
    );
  }
}

CreateOrderVendorRating.propType = {
  createOrderRating: PropTypes.func.isRequired,
  existsOwnedForOrder: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {i18n, orderRating} = state;
  const {isFetching, existingOwnedRatingForOrder} = orderRating;

  return {i18n, isFetching, existingOwnedRatingForOrder};
}

const mapDispatchToProps = {
  createOrderRating: orderRatingActions.createOrderRating,
  existsOwnedForOrder: orderRatingActions.existsOwnedForOrder,
  clearAll: orderRatingActions.clearAll,
};

const connectedCreateOrderVendorRating = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrderVendorRating);
export {connectedCreateOrderVendorRating as CreateOrderVendorRating};
