/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

const normalizeDoubleNumber = value =>
  !value
    ? value
    : value
        .replace(/[^\d\,\.]/g, '')
        .replace(/\,/g, '.')
        .replace(/(\..*)\./g, '$1');

export default normalizeDoubleNumber;
