/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import {Alert} from 'reactstrap';
import {Field} from 'redux-form';
import RenderCurrency from 'shared/components/form/currency/RenderCurrency';
import RenderDropdown from 'shared/components/form/dropdown/RenderDropdown';
import {RenderTextArea} from 'shared/components/form/text-area/RenderTextArea';
import renderField from 'shared/components/form/text-field/RenderField';
import {getGuaranteePeriods} from '../../../utils/utils';

export class RenderServiceSection extends React.Component {
  renderServiceCategoryLabel(service, index) {
    return (
      <div className='row'>
        <div className='col'>
          <Field
            name={`${service}.serviceCategoryName`}
            type='text'
            component={renderField}
            inputReadOnly={true}
            label={I18n.t('valuationInquiryResponse.serviceCategoryNameLabel')}
            icon='fa fa-folder'
          />
          <Field
            name={`${service}.serviceCategoryId`}
            component={props => <div className='d-none' />}
          />
        </div>
      </div>
    );
  }

  renderServiceLabel(service, index) {
    return (
      <div className='row'>
        <div className='col'>
          <Field
            name={`${service}.serviceName`}
            type='text'
            component={renderField}
            inputReadOnly={true}
            label={I18n.t('valuationInquiryResponse.serviceNameLabel')}
            icon='fa fa-suitcase'
          />
          <Field
            name={`${service}.serviceId`}
            component={props => <div className='d-none' />}
          />
        </div>
      </div>
    );
  }

  renderPrice(service, index) {
    return (
      <div className='row'>
        <div className='col'>
          <Field
            name={`${service}.serviceCost.value`}
            component={RenderCurrency}
            required={true}
            changeCurrencyInfo={true}
            label={I18n.t('valuationInquiryResponse.serviceEstimatedCostLabel')}
            icon='fa fa-money'
            suffix={this.props.currency}
          />
        </div>
      </div>
    );
  }

  renderGuaranteeSection(service) {
    return (
      <Field
        name={`${service}.guaranteePeriodMonths`}
        component={RenderDropdown}
        options={getGuaranteePeriods()}
        placeholder={I18n.t(
          'valuationInquiryResponse.guaranteePeriodMonthsPlaceholder'
        )}
        required={true}
        label={I18n.t('valuationInquiryResponse.guaranteePeriodMonthsLabel')}
      />
    );
  }

  renderOtherServicesLabel() {
    const {valuationInquiry} = this.props;

    return (
      <div className='row'>
        <div className='col'>
          <hr style={{marginBottom: 0}} />
          <Field
            input={{value: valuationInquiry.otherServices}}
            type='text'
            component={RenderTextArea}
            inputReadOnly={true}
            label={I18n.t('valuationInquiryResponse.otherServicesLabel')}
            icon='fa fa-suitcase'
          />
        </div>
      </div>
    );
  }

  render() {
    const {fields, meta, valuationInquiry} = this.props;
    const {error} = meta;

    return (
      <div>
        <div className='service-section'>
          {error && (
            <Alert className='mb-0 text-center' color='danger'>
              <Translate value={error.key} />
            </Alert>
          )}
          {fields.map((service, index) => (
            <div className='service-section-item' key={index}>
              {index !== 0 && <hr style={{marginBottom: 0}} />}
              {this.renderServiceCategoryLabel(service, index)}
              {this.renderServiceLabel(service, index)}
              {this.renderGuaranteeSection(service)}
              {this.renderPrice(service, index)}
            </div>
          ))}
        </div>
        <div className='other-service-section'>
          {valuationInquiry &&
            valuationInquiry.otherServices &&
            this.renderOtherServicesLabel()}
        </div>
      </div>
    );
  }
}
