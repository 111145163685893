/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {axiosService} from './axios.service';

export const workshopWorkerService = {
  findAllWorkersByWorkshopId,
  findWorkerById,
  updateWorker,
  deleteWorker,
  createWorker,
};

export const WORKSHOP_WORKER_API_BASE_PATH = '/workshop/workshop-workers';

function findAllWorkersByWorkshopId(workshopId) {
  return axiosService
    .get(`${WORKSHOP_WORKER_API_BASE_PATH}?workshopId=${workshopId}`)
    .then(response => {
      return response;
    });
}

function findWorkerById(workshopWorkerId) {
  return axiosService
    .get(`${WORKSHOP_WORKER_API_BASE_PATH}/${workshopWorkerId}`)
    .then(response => {
      return response;
    });
}

function updateWorker(workshopWorkerId, data) {
  return axiosService
    .put(`${WORKSHOP_WORKER_API_BASE_PATH}/${workshopWorkerId}`, data)
    .then(response => {
      return response;
    });
}

function deleteWorker(workshopWorkerId) {
  return axiosService
    .delete(`${WORKSHOP_WORKER_API_BASE_PATH}/${workshopWorkerId}`)
    .then(response => {
      return response;
    });
}

function createWorker(data) {
  return axiosService
    .post(`${WORKSHOP_WORKER_API_BASE_PATH}`, data)
    .then(response => {
      return response;
    });
}
