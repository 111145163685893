/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {Button} from 'reactstrap';
import {Field, getFormValues, reduxForm} from 'redux-form';
import CountryField from 'shared/components/form/country-field/CountryField';
import {formatPhoneNumberField} from 'shared/components/form/formatter/PhoneNumberFormatter';
import PhoneNumber from 'shared/components/form/phone-number/PhoneNumber';
import StateField from 'shared/components/form/state-field/StateField';
import renderField from 'shared/components/form/text-field/RenderField';
import {withPhoneNumberPrefixHoc} from 'shared/hoc/init-phone-number-prefix/PhoneNumberPrefixHoc';
import {AcceptTerms} from './AcceptTerms';
import {ReCaptcha} from './ReCaptcha';
import {asyncValidate, validate} from './SignUpValidators';

class SignUpFormValidation extends React.Component {
  componentDidUpdate(prevProps) {
    const {formValues, form} = this.props;
    if (this.props.shouldInitializePhonePrefix(prevProps, formValues)) {
      this.props.initializePhonePrefixByCountry(form, formValues.country);
    }
  }

  render() {
    const {handleSubmit, invalid, goBackHandler} = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name='firstName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('signup.firstNameLabel')}
          icon='fa fa-user'
        />
        <Field
          name='lastName'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('signup.lastNameLabel')}
          icon='fa fa-user'
        />
        <CountryField />
        <StateField formName='signUpFormValidation' />
        <Field
          name='city'
          type='text'
          component={renderField}
          required={true}
          label={I18n.t('signup.cityLabel')}
          icon='fa fa-building-o'
        />
        <Field
          name='phoneNumber'
          component={PhoneNumber}
          required={true}
          label={I18n.t('signup.phoneNumberLabel')}
          format={formatPhoneNumberField}
        />
        <Field
          name='email'
          type='email'
          component={renderField}
          required={true}
          label={I18n.t('signup.emailLabel')}
          icon='fa fa-at'
        />
        <Field
          name='password'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('signup.passwordLabel')}
          icon='fa fa-unlock-alt'
        />
        <Field
          name='confirmPassword'
          type='password'
          component={renderField}
          required={true}
          label={I18n.t('signup.repeatPasswordLabel')}
          icon='fa fa-unlock-alt'
        />
        <Field name='acceptTerms' component={AcceptTerms} />
        <Field name='captchaToken' component={ReCaptcha} />
        <div className='row mt-3'>
          <div className='col-12 text-center'>
            <Button
              className='login-btn px-5 py-1 mb-1'
              type='submit'
              disabled={invalid}>
              <Translate value='signup.signupBtn' />
            </Button>
          </div>
          <div className='col-12 mb-4'>
            <Button onClick={goBackHandler} className='main-btn'>
              <Translate value='signup.backBtn' />
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

SignUpFormValidation = reduxForm({
  form: 'signUpFormValidation',
  validate,
  asyncBlurFields: ['email'],
})(SignUpFormValidation);

function mapStateToProps(state) {
  const {i18n} = state;
  const formValues = getFormValues('signUpFormValidation')(state);
  return {i18n, formValues};
}

//export for tests
export const SignUpFormValidationConnectedWithRedux =
  connect(mapStateToProps)(SignUpFormValidation);

export default connect(mapStateToProps)(
  withPhoneNumberPrefixHoc(SignUpFormValidation)
);
