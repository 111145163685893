/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import ValuationInquiryFormSchemaPage1 from '../../../../../valuation-inquiry/valuation-inquiry-form/page-1/ValuationInquiryFormSchemaPage1';
import {validate} from '../../../../../valuation-inquiry/valuation-inquiry-form/page-1/validators';

const ValuationDirectClientsPage1 = reduxForm({
  form: 'valuationDirectClientsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ValuationInquiryFormSchemaPage1);

function mapStateToProps(state) {
  const {i18n, service, form} = state;
  const categories = service.workshopServiceCategories;
  const valuationForm = form.valuationDirectClientsForm;

  return {i18n, categories, valuationForm};
}

export default connect(mapStateToProps)(ValuationDirectClientsPage1);
