/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import './header.css';
import {slide as Menu} from 'react-burger-menu';
import {SideBarNavContent} from '../sidebar-nav/SideBarNavContent';
import {UserCollapse} from '../user-collapse/UserCollapse';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {UserNotifications} from '../user-notifications/UserNotifications';
import {SettingsIcon} from '../settings/SettingsIcon';
import {userService} from '../../services/user.service';
import {LanguageSelect} from '../languages/language-select/LanguageSelect';
import {getOrEmptyObj} from '../../utils/utils';
import {logo} from '../../utils/icons';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isMenuOpen: false,
    };
  }

  toggle() {
    this.setState((prevState, props) => {
      return {isMenuOpen: !prevState.isMenuOpen};
    });
  }

  shouldShowSettings() {
    return userService.isAdmin();
    // return userService.isAdmin() || userService.isWorkshop();
  }

  renderLoginButton() {
    return (
      <div className='col-auto'>
        <NavLink to='/home' activeClassName='active'>
          <div className='login-logout'>
            <i className='fa fa-3x fa-sign-in' />
            <div className='login-label'>
              <Translate value='header.login' />
            </div>
          </div>
        </NavLink>
      </div>
    );
  }

  renderRegisterButton() {
    return null;

    return (
      <div className='col-auto'>
        <NavLink to='/sign-up' activeClassName='active'>
          <div className='login-logout'>
            <i className='fa fa-3x fa-user-plus' />
            <div className='login-label'>
              <Translate value='signup.signupBtn' />
            </div>
          </div>
        </NavLink>
      </div>
    );
  }

  render() {
    const {dispatch, isAuthenticated, location} = this.props;

    return (
      <div className='nav-header bottom-shadow'>
        <div className="logo">
          <div className='logo-content logo-margin'>
            <img src={logo} alt='logo' />
          </div>
        </div>
        <div className={isAuthenticated ? 'menu-toggler' : 'hidden'}>
          <Menu isOpen={this.state.isMenuOpen}>
            <SideBarNavContent />
          </Menu>
        </div>
        <div className='wrapper'>
          {!isAuthenticated && (
            <div className='row justify-content-end pt-2'>
              <div className='col-auto'>
                <LanguageSelect />
              </div>
              {getOrEmptyObj(location).pathname !== '/home'
                ? this.renderLoginButton()
                : this.renderRegisterButton()}
            </div>
          )}

          {isAuthenticated && (
            <div className='row justify-content-end pt-2'>
              <div className='col-auto'>
                <UserNotifications />
              </div>
              {this.shouldShowSettings() && (
                <div className='col-auto'>
                  <SettingsIcon />
                </div>
              )}
              <div className='col-auto'>
                <LanguageSelect />
              </div>
              <div className='col-auto user-data'>
                <UserCollapse dispatch={dispatch} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state;
  const {isAuthenticated} = user;

  return {isAuthenticated};
}

const connectedHeader = connect(mapStateToProps, null, null, {pure: false})(
  Header
);
export {connectedHeader as Header};
