/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import 'shared/styles/subsection-tiles.css';
import {CalendarSettings} from './calendar-settings/CalendarSettings';
import Settings from './general-settings/Settings';
import {WorkingHoursSettings} from './working-hours-settings/WorkingHoursSettings';

export const SettingsSubsection = {
  GENERAL_SETTINGS: {
    key: 'GENERAL_SETTINGS',
    divId: 'general-settings-tile',
    translateValue: 'settings.generalSettingsBtn',
  },
  CALENDAR_SETTINGS: {
    key: 'CALENDAR_SETTINGS',
    divId: 'calendar-settings-tile',
    translateValue: 'settings.calendarSettingsBtn',
  },
  WORKING_HOURS_SETTINGS: {
    key: 'WORKING_HOURS_SETTINGS',
    divId: 'working-hours-settings-tile',
    translateValue: 'settings.workingHoursSettingsBtn',
  },
};

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subsectionKeyActive: SettingsSubsection.GENERAL_SETTINGS.key,
    };

    this.isActive = this.isActive.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(SettingsSubsection.GENERAL_SETTINGS, 'fa-cog')}
        {this.renderTile(SettingsSubsection.CALENDAR_SETTINGS, 'fa-calendar')}
        {this.renderTile(
          SettingsSubsection.WORKING_HOURS_SETTINGS,
          'fa-clock-o',
          false
        )}
      </div>
    );
  }

  renderTile(subsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        id={subsection.divId}
        className={commonClasses + spacingClasses + this.isActive(subsection)}
        onClick={e => this.setActive(subsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={subsection.translateValue} />
      </div>
    );
  }

  isActive(subsection) {
    return this.state.subsectionKeyActive === subsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(subsection) {
    this.setState({subsectionKeyActive: subsection.key});
  }

  renderSubsection() {
    switch (this.state.subsectionKeyActive) {
      case SettingsSubsection.GENERAL_SETTINGS.key:
        return <Settings rootPanel={false} />;
      case SettingsSubsection.CALENDAR_SETTINGS.key:
        return <CalendarSettings />;
      case SettingsSubsection.WORKING_HOURS_SETTINGS.key:
        return <WorkingHoursSettings />;
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        {this.renderSubsection()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const mapDispatchToProps = {};

const connectedSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
export {connectedSettingsContainer as SettingsContainer};
