/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {promotionConstants} from '../constants/promotion.constants';
import {SyncState} from '../utils/sync';

export function promotionReducer(state = {}, action) {
  switch (action.type) {
    case promotionConstants.PROMOTION_REQUEST_START:
      return {...state, isFetching: true};
    case promotionConstants.PROMOTION_REQUEST_END:
      return {...state, isFetching: false};
    case promotionConstants.LOAD_PROMOTION:
      return {...state, selectedPromotion: action.payload.data};
    case promotionConstants.CLEAR_PROMOTION_DATA:
      return {...state, selectedPromotion: null};
    case promotionConstants.LOAD_PROMOTIONS:
      return {
        ...state,
        promotions: action.payload.data,
        promotionDataSyncState: SyncState.UP_TO_DATE,
      };
    case promotionConstants.REFRESH_PROMOTION_DATA:
      return {...state, promotionDataSyncState: SyncState.OBSOLETE};
    case promotionConstants.LOAD_CREATION_CONSENT:
      return {...state, promotionCreationConsent: action.payload.data};
    default:
      return state;
  }
}
