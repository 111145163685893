/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {SubscriptionPlans} from './subscription-plans/SubscriptionPlans';
import {SubscriptionPayments} from './subscription-payments/SubscriptionPayments';

export const SubscriptionsSubsection = {
  PLANS: {
    key: 'PLANS',
    divId: 'plans-tile',
    translateValue: 'subscription.plansBtn',
  },
  PAYMENTS: {
    key: 'PAYMENTS',
    divId: 'payments-tile',
    translateValue: 'subscription.paymentsBtn',
  },
};

class SubscriptionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subsectionKeyActive: SubscriptionsSubsection.PLANS.key,
    };

    this.isActive = this.isActive.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  renderTiles() {
    return (
      <div className='row subsection-tiles-container'>
        {this.renderTile(SubscriptionsSubsection.PLANS, 'fa-columns')}
        {this.renderTile(
          SubscriptionsSubsection.PAYMENTS,
          'fa-credit-card',
          false
        )}
      </div>
    );
  }

  renderTile(subsection, iconName, spacingClassesApply = true) {
    const commonClasses = 'subsection-tile shadow col-sm col-12 ';
    const spacingClasses = spacingClassesApply ? 'mr-sm-3 mb-sm-0 mb-3 ' : '';

    return (
      <div
        id={subsection.divId}
        className={commonClasses + spacingClasses + this.isActive(subsection)}
        onClick={e => this.setActive(subsection)}>
        <i className={'fa fa-3x ' + iconName} />
        <Translate value={subsection.translateValue} />
      </div>
    );
  }

  isActive(subsection) {
    return this.state.subsectionKeyActive === subsection.key
      ? 'subsection-tile-active '
      : '';
  }

  setActive(subsection) {
    this.setState({subsectionKeyActive: subsection.key});
  }

  renderSubsection() {
    switch (this.state.subsectionKeyActive) {
      case SubscriptionsSubsection.PLANS.key:
        return <SubscriptionPlans />;
      case SubscriptionsSubsection.PAYMENTS.key:
        return <SubscriptionPayments />;
    }
  }

  render() {
    return (
      <div>
        {this.renderTiles()}
        {this.renderSubsection()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n} = state;

  return {i18n};
}

const mapDispatchToProps = {};

const connectedSubscriptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsContainer);
export {connectedSubscriptionsContainer as SubscriptionsContainer};
