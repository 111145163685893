/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2019 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {CalendarForm} from './CalendarForm';
import {workshopActions} from '../../../actions/workshop.actions';
import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loading-overlay';
import {validate} from './calendar-form-validators';
import {workshopConstants} from '../../../constants/workshop.constants';

export const CalendarFormReduxFormWrapper = reduxForm({
  form: 'calendarEditForm',
  validate,
  enableReinitialize: true,
})(CalendarForm);

class CalendarEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
  }

  componentDidMount() {
    const {findWorkshopExternalCalendarById, workshopExternalCalendarId} =
      this.props;
    findWorkshopExternalCalendarById(workshopExternalCalendarId);
  }

  componentWillUnmount() {
    this.props.clearCalendar();
  }

  update(data) {
    const {updateWorkshopExternalCalendar, workshopExternalCalendarId} =
      this.props;
    updateWorkshopExternalCalendar(workshopExternalCalendarId, data);
  }

  delete() {
    const {
      deleteWorkshopExternalCalendar,
      workshopExternalCalendarId,
      onDeleteSuccess,
    } = this.props;
    deleteWorkshopExternalCalendar(workshopExternalCalendarId, onDeleteSuccess);
  }

  render() {
    const {isFetching} = this.props;

    return (
      <Loadable active={isFetching} spinner>
        <CalendarFormReduxFormWrapper
          {...this.props}
          onSubmit={this.update}
          handleDelete={this.delete}
        />
      </Loadable>
    );
  }
}

CalendarEditForm.propTypes = {
  workshopExternalCalendarId: PropTypes.string.isRequired,
  findWorkshopExternalCalendarById: PropTypes.func.isRequired,
  updateWorkshopExternalCalendar: PropTypes.func.isRequired,
  deleteWorkshopExternalCalendar: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
};

function mapStateToProps(state) {
  const {i18n, workshop} = state;
  const initialValues = workshop.workshopExternalCalendar || {};
  const {isFetching} = workshop;

  return {i18n, initialValues, isFetching};
}

const mapDispatchToProps = {
  findWorkshopExternalCalendarById:
    workshopActions.findWorkshopExternalCalendarById,
  clearCalendar: () => ({
    type: workshopConstants.CLEAR_WORKSHOP_EXTERNAL_CALENDAR,
  }),
  updateWorkshopExternalCalendar:
    workshopActions.updateWorkshopExternalCalendar,
  deleteWorkshopExternalCalendar:
    workshopActions.deleteWorkshopExternalCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEditForm);
