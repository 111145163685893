/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2021 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/
import { useState } from "react";

const useWarrantyBookLocalStorageSetValue = (field) => {
  const [storedValue, setStoredValue] = useState(
    localStorage.getItem("warrantyBook") !== null
      ? JSON.parse(localStorage.getItem("warrantyBook"))[field]
      : {}
  );
  return [storedValue, setStoredValue];
};

export default useWarrantyBookLocalStorageSetValue;
