/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

export const countryConstants = {
  COUNTRY_REQUEST_START: 'COUNTRY_REQUEST_START',
  COUNTRY_REQUEST_END: 'COUNTRY_REQUEST_END',
  LOAD_COUNTRIES: 'LOAD_COUNTRIES',
};
