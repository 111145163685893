/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import * as React from 'react';
import Loadable from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {Card, CardBody, CardHeader} from 'reactstrap';
import {userActions} from 'shared/actions/user.actions';
import 'url-search-params-polyfill';
import './user-activation.css';

class UserActivation extends React.Component {
  componentWillMount() {
    let searchParams = new URLSearchParams(this.props.location.search);
    const activationToken = searchParams.get('token');
    setTimeout(() => this.props.activate(activationToken), 500);
  }

  render() {
    const {isFetching} = this.props;
    return (
      <div className='user-activation-container'>
        <Loadable active={isFetching} spinner>
          <Card>
            <CardHeader className='py-2'>
              <h4 style={{margin: 0}}>
                <b>
                  <Translate value='userActivation.activationTitle' />
                </b>
              </h4>
            </CardHeader>
            <CardBody className='text-center'>
              <Translate value='userActivation.activationText' />
            </CardBody>
          </Card>
        </Loadable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {i18n, user} = state;
  const {isFetching} = user;
  return {i18n, isFetching};
}

const mapDispatchToProps = {
  activate: userActions.activate,
};

const connectedUserActivation = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserActivation);
export {connectedUserActivation as UserActivation};
