/*********************************************************************************************************************************
 * Autorskie Prawa Majątkowe - Moose Spółka z ograniczoną odpowiedzialnością
 *
 * Copyright 2018 Moose Spółka z ograniczoną odpowiedzialnością
 ********************************************************************************************************************************/

import React from 'react';
import {connect} from 'react-redux';
import {promotionActions} from 'shared/actions/promotion.actions';
import {Promotions} from 'shared/components/promotions/Promotions';
import {updateDataSideBarConstants} from 'shared/constants/update-data-side-bar.constants';
import {withDataTableCore} from 'shared/hoc/data-table-core/DataTableCoreHoc';
import {becameObsolete} from 'shared/utils/sync';

class PromotionsWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    if (this.props.loggedWorkshopId != null) this.load();
  }

  load() {
    const {
      loggedWorkshopId,
      findAllPromotionsByWorkshopId,
      hasCreationConsent,
    } = this.props;

    findAllPromotionsByWorkshopId(loggedWorkshopId, false);
    hasCreationConsent(loggedWorkshopId);
  }

  componentDidUpdate(prevProps) {
    const {loggedWorkshopId, promotionDataSyncState} = this.props;

    if (
      prevProps.loggedWorkshopId !== loggedWorkshopId ||
      becameObsolete(prevProps.promotionDataSyncState, promotionDataSyncState)
    )
      this.load();
  }

  render() {
    return <Promotions {...this.props} />;
  }
}

function mapStateToProps(state) {
  const {promotion, workshop} = state;
  const {
    promotions,
    promotionDataSyncState,
    isFetching,
    promotionCreationConsent,
  } = promotion;
  const {data} = workshop;
  const loggedWorkshopId = data ? data.workshopId : null;
  const isPromotionsCreator = true;
  const title = 'promotions.promotionList';

  return {
    promotions,
    isFetching,
    loggedWorkshopId,
    promotionDataSyncState,
    promotionCreationConsent,
    isPromotionsCreator,
    title,
  };
}

const mapDispatchToProps = {
  findAllPromotionsByWorkshopId: promotionActions.findAllPromotionsByWorkshopId,
  createPromotion: promotionActions.createPromotion,
  updatePromotion: promotionActions.updatePromotion,
  findPromotionById: promotionActions.findPromotionById,
  hasCreationConsent: promotionActions.hasCreationConsent,
  showUpdateDataSideBar: (variableComponent, title) => ({
    type: updateDataSideBarConstants.SHOW,
    variableComponent: variableComponent,
    title: title,
  }),
};

export const ConnectedPromotionsWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsWrapper);

export default withDataTableCore(ConnectedPromotionsWithRedux);
